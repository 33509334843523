// import { Menu } from '@mui/icons-material';
import {
  Box,
  Button,
  // DialogActions,
  FormControl,
  FormHelperText,
  Grid,
  // InputLabel,
  // InputLabel,
  MenuItem,
  // Paper,
  Select,
  TextField,
  Typography
} from '@mui/material';
// import { textAlign } from '@mui/system';
import React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
// import { useEffect } from 'react';
import { gridSpacing } from 'store/constant';

const EmployeeFilterForm = ({
  errors,
  //   handleBlur,
  handleChange,
  // handleSubmit,
  // touched,
  values,
  setFieldValue,
  // setTouched,
  resetForm,
  // filterObj,
  // handleCloseFilter,
  setFilterObj,
  permissionsList
}) => {
  const orgType = useSelector((state) => state.customization.orgType);
  // console.log('🚀 ~ errors:', errors);
  // console.log('🚀 ~ values:', values);
  // useEffect(() => {
  //   if (filterObj) {
  //     // console.log('🚀 ~ useEffect ~ filterObj:', filterObj);
  //     setFieldValue('orgName', filterObj?.orgName ? filterObj?.orgName : '');
  //     setFieldValue('status', filterObj?.status ? filterObj?.status : '');
  //     setFieldValue('orgTypes', filterObj?.orgTypes ? filterObj?.orgTypes : '');
  //   }
  // }, [filterObj, setFieldValue]);
  useEffect(() => {
    if (orgType) setFieldValue('orgTypes', orgType);
  }, [orgType]);

  let Filters;
  try {
    if (permissionsList && permissionsList.view) {
      ({ filters: Filters } = permissionsList.view);
    } else {
      Filters = [];
      // throw new Error('permissionsList or permissionsList.View is undefined');
      // console.log('permissionsList or permissionsList.View is undefined');
    }
  } catch (error) {
    // console.log('Error destructuring Filters:', error.message);
    Filters = []; // or some default value if needed
  }
  const transformedPermissionsFilters =
    Filters && Array.isArray(Filters) && Filters.length
      ? Filters?.reduce((acc, item) => {
          acc[item.id] = item;
          return acc;
        }, {})
      : [];

  // console.log('Filters Employee permissionsList', Filters, transformedPermissionsFilters);

  return (
    <>
      <Grid container spacing={gridSpacing} sx={{ marginBottom: '20px', alignItems: 'center', padding: '0px 20px' }}>
        {transformedPermissionsFilters['empName'] && transformedPermissionsFilters['empName']?.show && (
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className="select-machine-id inv-number select-machine"
            // className="select-job-enterprise select_separate2"
            alignItems={'center'}
          >
            <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
              {transformedPermissionsFilters['empName']?.label}{' '}
            </Typography>
            <FormControl fullWidth variant="outlined">
              <TextField
                // label="Organization Name"
                placeholder={`Search ${transformedPermissionsFilters['empName']?.label}`}
                variant="outlined"
                type="text"
                fullWidth
                name="name"
                value={values?.name}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
        )}

        {transformedPermissionsFilters['empStatus'] && transformedPermissionsFilters['empStatus']?.show && (
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className="select-status-filter"

            // select-job-enterprise"
            // className="select-job-enterprise select_separate2"
            // className="select-job-enterprise "
          >
            <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
              {transformedPermissionsFilters['empStatus']?.label}
            </Typography>
            <FormControl fullWidth variant="outlined" placeholder="Select Status">
              <Select
                value={values?.status}
                onChange={handleChange}
                name="status"
                inputProps={{
                  'aria-label': 'Without label'
                }}
              >
                <MenuItem value="All">All</MenuItem>
                {/* <MenuItem value="">Select Status</MenuItem> */}
                <MenuItem value="true">Active</MenuItem>
                <MenuItem value="false">Inactive</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}

        {transformedPermissionsFilters['empEmail'] && transformedPermissionsFilters['empEmail']?.show && (
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className="select-machine-id inv-number select-machine"
            // className="select-job-enterprise select_separate2"
            alignItems={'center'}
          >
            <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
              {transformedPermissionsFilters['empEmail']?.label}
            </Typography>
            <FormControl fullWidth variant="outlined">
              <TextField
                // label="Organization Name"
                placeholder={`Search ${transformedPermissionsFilters['empEmail']?.label}`}
                variant="outlined"
                type="email"
                fullWidth
                name="email"
                value={values?.email}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
        )}
        {transformedPermissionsFilters['empTitle'] && transformedPermissionsFilters['empTitle']?.show && (
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className="select-machine-id inv-number select-machine"
            // className="select-job-enterprise select_separate2"
            alignItems={'center'}
          >
            <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
              {transformedPermissionsFilters['empTitle']?.label}
            </Typography>
            <FormControl fullWidth variant="outlined">
              <TextField
                // label="Organization Name"
                placeholder={`Search ${transformedPermissionsFilters['empTitle']?.label}`}
                variant="outlined"
                type="text"
                fullWidth
                name="title"
                value={values?.title}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
        )}
        {orgType && (orgType === 'Enterprise' || orgType === 'Super Admin') && (
          <>
            {orgType && orgType === 'Enterprise' && (
              <>
                {/* RFID */}
                {(transformedPermissionsFilters['empRrfid'] || transformedPermissionsFilters['empRrfid']?.show) && (
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    className="select-machine-id inv-number select-machine"
                    // className="select-job-enterprise select_separate2"
                    alignItems={'center'}
                  >
                    <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
                      {transformedPermissionsFilters['empRrfid']?.label}
                    </Typography>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        // label="Organization Name"
                        placeholder={`Search ${transformedPermissionsFilters['empRrfid']?.label}`}
                        variant="outlined"
                        type="text"
                        fullWidth
                        name="rfid"
                        value={values?.rfid}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Grid>
                )}
                {/* Auth Pin */}
                {(transformedPermissionsFilters['empAuthPin'] || transformedPermissionsFilters['empAuthPin']?.show) && (
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    className="select-machine-id inv-number select-machine"
                    // className="select-job-enterprise select_separate2"
                    alignItems={'center'}
                  >
                    <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
                      {transformedPermissionsFilters['empAuthPin']?.label}
                    </Typography>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        // label="Organization Name"
                        placeholder={`Search ${transformedPermissionsFilters['empAuthPin']?.label}`}
                        variant="outlined"
                        type="number"
                        fullWidth
                        name="authPin"
                        value={values?.authPin}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Grid>
                )}
              </>
            )}

            {orgType && orgType === 'Super Admin' && (
              <>
                {/* Installer RFID */}
                {(transformedPermissionsFilters['empInstallerRfid'] || transformedPermissionsFilters['empInstallerRfid']?.show) && (
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    className="select-machine-id inv-number select-machine"
                    // className="select-job-enterprise select_separate2"
                    alignItems={'center'}
                  >
                    <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
                      {transformedPermissionsFilters['empInstallerRfid']?.label}
                    </Typography>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        // label="Organization Name"
                        placeholder={`Search ${transformedPermissionsFilters['empInstallerRfid']?.label}`}
                        variant="outlined"
                        type="text"
                        fullWidth
                        name="installerRfid"
                        value={values?.installerRfid}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Grid>
                )}
                {/* Installer Auth Pin */}
                {(transformedPermissionsFilters['empInstallerAuthPin'] || transformedPermissionsFilters['empInstallerAuthPin']?.show) && (
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    className="select-machine-id inv-number select-machine"
                    // className="select-job-enterprise select_separate2"
                    alignItems={'center'}
                  >
                    <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
                      {transformedPermissionsFilters['empInstallerAuthPin']?.label}
                    </Typography>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        // label="Organization Name"
                        placeholder={`Search ${transformedPermissionsFilters['empInstallerAuthPin']?.label}`}
                        variant="outlined"
                        type="number"
                        fullWidth
                        name="installerAuthPin"
                        value={values?.installerAuthPin}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Grid>
                )}
              </>
            )}
          </>
        )}
        {transformedPermissionsFilters['empViewScope'] && transformedPermissionsFilters['empViewScope']?.show && (
          <Grid item xs={12} sm={12} md={4} lg={4} className="select-status-filter">
            <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
              {transformedPermissionsFilters['empViewScope']?.label}
            </Typography>
            <FormControl fullWidth variant="outlined">
              <Select
                value={values?.showAdmin}
                onChange={handleChange}
                name="showAdmin"
                inputProps={{
                  'aria-label': 'Without label'
                }}
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="Employee">Only Employees</MenuItem>
                <MenuItem value="Admin">Only Admin</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>

      <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'right', paddingBottom: '20px', paddingRight: '20px' }}>
        <Button
          variant="outlined"
          type="reset"
          color="error"
          onClick={() => {
            resetForm();
            setFilterObj({
              status: '',
              name: '',
              email: '',
              title: '',
              rfid: '',
              authPin: '',
              showAdmin: ''
            });
            // handleCloseFilter();
          }}
        >
          Clear all
        </Button>
        <div style={{ marginLeft: '10px' }}>
          {errors?.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors?.submit}</FormHelperText>
            </Box>
          )}
          <Box sx={{ m: 0, position: 'relative' }}>
            <Button
              // fullWidth
              variant="contained"
              type="submit"
              style={{
                color: '#fff'
              }}
              color="primary"
            >
              Search
            </Button>
          </Box>
        </div>
      </div>
    </>
  );
};

export default EmployeeFilterForm;
