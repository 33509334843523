import { Box, Card, CardContent, CardHeader, Divider, Grid, Paper, Skeleton, Typography } from '@mui/material';
import React from 'react';

const ModifyPermissionSkeleton = () => {
  return (
    <>
      {[1, 2].map((j) => (
        <Paper variant="outlined" sx={{ mb: 2 }} key={j}>
          <CardHeader title={<Skeleton variant="rectangular" width={100} height={20} sx={{ fontSize: '1rem' }} />} />

          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              {[1, 2, 3, 4, 5, 6].map((i) => (
                <Grid item xs={12} sm={6} md={6} lg={4} xl={4} key={i}>
                  <Card variant="outlined">
                    <CardContent
                      sx={{
                        p: { xs: 1, sm: 2 }
                      }}
                    >
                      <Box sx={{ display: 'flex', flexDirection: 'column', paddingBottom: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography variant="h6" sx={{ marginRight: 1 }}>
                            ID:
                          </Typography>
                          <Skeleton variant="text" sx={{ fontSize: '1rem', width: 100 }} />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography variant="body1" sx={{ marginRight: 1 }}>
                            Label:
                          </Typography>
                          <Skeleton variant="text" sx={{ fontSize: '1rem', width: 200 }} />
                        </Box>
                      </Box>
                      {/* <FormControlLabel control={<Switch checked={true} />} label="Show" /> */}
                      <Skeleton variant="rounded" width={150} height={15} sx={{ fontSize: '1rem' }} />
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Paper>
      ))}
    </>
  );
};

export default ModifyPermissionSkeleton;
