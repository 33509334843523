// assets
import { IconKey } from '@tabler/icons';
import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
//  import DomainAddSharpIcon from '@mui/icons-material/DomainAddSharpIcon';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import SettingsIcon from '@mui/icons-material/Settings';
import SellIcon from '@mui/icons-material/Sell';
import SocialDistanceOutlinedIcon from '@mui/icons-material/SocialDistanceOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import KeyboardCommandKeyIcon from '@mui/icons-material/KeyboardCommandKey';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import BookOnlineOutlinedIcon from '@mui/icons-material/BookOnlineOutlined';
// constant

const icons = {
  IconKey,
  PrecisionManufacturingOutlinedIcon,
  BusinessOutlinedIcon,
  PeopleOutlineOutlinedIcon,
  PersonAddAltIcon,
  GroupAddOutlinedIcon,
  StorefrontOutlinedIcon,
  CorporateFareOutlinedIcon,
  LocationOnOutlinedIcon,
  WorkOutlineOutlinedIcon,
  LocalActivityIcon,
  RequestQuoteIcon,
  SettingsIcon,
  SellIcon,
  NotificationsIcon,
  SocialDistanceOutlinedIcon,
  BadgeOutlinedIcon,
  SupportAgentOutlinedIcon,
  SubscriptionsIcon,
  QuizOutlinedIcon,
  KeyboardCommandKeyIcon,
  ConfirmationNumberOutlinedIcon,
  BookOnlineOutlinedIcon
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

/** FUTURE USE */
// const pages = {
//   id: 'pages',
//   title: 'Pages',
//   caption: 'Pages Caption',
//   type: 'group',
//   children: [
//     {
//       id: 'authentication',
//       title: 'Authentication',
//       type: 'collapse',
//       icon: icons.IconKey,

//       children: [
//         {
//           id: 'login3',
//           title: 'Login',
//           type: 'item',
//           url: '/pages/login/login3',
//           target: true
//         },
//         {
//           id: 'register3',
//           title: 'Register',
//           type: 'item',
//           url: '/pages/register/register3',
//           target: true
//         }
//       ]
//     }
//   ]
// };
/** FUTURE USE */

const pages = {
  id: 'pages',
  // title: 'Pages',
  // caption: 'Pages Caption',
  type: 'group',
  children: [
    {
      id: 'machines',
      title: 'Machines',
      type: 'item',
      icon: icons.PrecisionManufacturingOutlinedIcon,
      breadcrumbs: true,
      url: '/dashboard/machines',
      checkId: 'Machines'
    },
    {
      id: 'organizations',
      title: 'Organizations',
      type: 'item',
      icon: icons.CorporateFareOutlinedIcon,
      breadcrumbs: true,
      url: '/dashboard/organizations',
      checkId: 'Organizations'
    },

    {
      id: 'location',
      title: 'Locations',
      type: 'item',
      icon: icons.LocationOnOutlinedIcon,
      breadcrumbs: true,
      url: '/dashboard/location',
      checkId: 'Locations'
    },
    {
      id: 'ticket-reason',
      title: 'Ticket Categories',
      type: 'item',
      icon: icons.BookOnlineOutlinedIcon,
      breadcrumbs: true,
      url: '/dashboard/ticket-reason',
      checkId: 'Ticket_Reasons'
    },
    {
      id: 'tickets',
      title: 'Machine Tickets',
      type: 'item',
      icon: icons.LocalActivityIcon,
      breadcrumbs: true,
      url: '/dashboard/tickets',
      checkId: 'Tickets'
    },
    {
      id: 'employee',
      title: 'Employees',
      type: 'item',
      icon: icons.BadgeOutlinedIcon,
      breadcrumbs: true,
      url: '/dashboard/employees',
      checkId: 'Employees'
    },
    {
      id: 'job',
      title: 'Jobs',
      type: 'item',
      icon: icons.WorkOutlineOutlinedIcon,
      breadcrumbs: true,
      url: '/dashboard/job',
      checkId: 'Jobs'
    },

    {
      id: 'invoices-super-admin',
      title: 'Invoices',
      type: 'item',
      icon: icons.RequestQuoteIcon,
      breadcrumbs: true,
      url: '/dashboard/invoices',
      checkId: 'Invoices'
    },
    {
      id: 'super-admin-settings',
      title: 'Settings',
      type: 'item',
      icon: icons.SettingsIcon,
      breadcrumbs: true,
      url: '/dashboard/settings',
      checkId: 'Settings'
    },
    {
      id: 'super-admin-subscriptions',
      title: 'Subscriptions',
      type: 'item',
      icon: icons.SubscriptionsIcon,
      breadcrumbs: true,
      url: '/dashboard/subscriptions',
      checkId: 'Subscriptions'
    },
    {
      id: 'get-support',
      title: 'Get Support',
      type: 'item',
      icon: icons.SupportAgentOutlinedIcon,
      breadcrumbs: true,
      url: '/dashboard/get-support',
      checkId: 'Get_Support'
    },
    {
      id: 'super-admin-notifications',
      title: 'Notifications',
      type: 'itemModal',
      icon: icons.NotificationsIcon,
      breadcrumbs: true,
      url: '',
      checkId: 'Notifications'
    },
    {
      id: 'super-admin-faqs',
      title: 'FAQs',
      type: 'item',
      icon: icons.QuizOutlinedIcon,
      breadcrumbs: true,
      url: '/dashboard/faq/super-admin',
      checkId: 'FAQs'
    },
    {
      id: 'support-ticket',
      title: 'Support Tickets',
      type: 'item',
      icon: icons.ConfirmationNumberOutlinedIcon,
      breadcrumbs: true,
      url: '/dashboard/support-ticket',
      checkId: 'Support_Tickets'
    },
    {
      id: 'super-admin-control-panel',
      title: 'Control Panel',
      type: 'item',
      icon: icons.KeyboardCommandKeyIcon,
      breadcrumbs: true,
      url: '/dashboard/control-panel',
      checkId: 'Control_Panel'
    }
  ]
};

export default pages;
