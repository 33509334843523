/* eslint-disable */
import * as React from 'react';
// import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Box,
  Button,
  DialogContent,
  DialogContentText,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Skeleton,
  Typography,
  useTheme
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// import { gridSpacing } from 'store/constant';
import { useNavigate } from 'react-router';
import { useGetMachineById } from 'hooks/useMachineHooks';
import styled from '@emotion/styled';
import { padding } from '@mui/system';
import { Form } from 'formik';
import { useGetAllOrganizationList, useGetOrgById } from 'hooks/useOrganizationHooks';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { Add, Remove } from '@mui/icons-material';

export default function ContactUsDialogForm({
  open,
  setOpen,
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  touched,
  values,
  setFieldValue,
  btnName
}) {
  // const [open, setOpen] = React.useState(false);
  /*
  const handleClickOpen = () => {
    setOpen(true);
  };*/
  const theme = useTheme();
  // const { data: machineDetails, isLoading: isLoadingMachine } = useGetMachineById({
  //   id: open.machineId
  // });
  const isLoadingMachine = false; // For Demo purposes

  //   console.log('machineDetails - MachineModalDialog - Machine Lease : ', machineDetails, open);

  const { data: orgList, isLoading: orgListIsLoading } = useGetAllOrganizationList({ onlyBuyers: true });
  const { data: orgListById, isLoading: orgListByIdIsLoading } = useGetOrgById({ id: values.organization });

  const exceptThisSymbols = ['e', 'E', '+', '-', '#', '%', '^'];

  const handleClose = () => {
    setOpen((state) => !state);
  };
  const navigate = useNavigate();

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.h4,
    // textAlign: 'center',
    // color: theme.palette.text.secondary,
    // height: 60,
    // lineHeight: '60px',
    padding: '10px'
  }));

  const handleChangeLocation = (event) => {
    const [value, tax] = event.target.value.split('@##@');
    // console.log('event.target.value.split()', event.target.value.split('@##@'));
    setFieldValue('location', value);
    setFieldValue('location_tax', tax);
  };

  const handleChangeOrg = (event) => {
    setFieldValue('organization', event.target.value);
    setFieldValue('location', '');
    setFieldValue('location_tax', '');
  };

  const locationsArray =
    orgListById && orgListById?.data && orgListById?.data?.locations && orgListById?.data?.locations?.length > 0
      ? orgListById?.data?.locations?.map((location) => {
          return {
            id: location?.details?.id || '',
            name: location?.details?.name || '',
            taxAmount: location?.details?.taxAmount || '',
            isPrimary: location?.isPrimary || ''
          };
        })
      : [];

  // console.log('locationsArray', locationsArray);

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'md'} // Set this to "xs" to make the dialog smaller
        onClose={handleClose}
        open={open}
      >
        <DialogTitle
          id="alert-dialog-title"
          variant="span"
          scroll={'papper'}
          style={{
            textTransform: 'capitalize',
            fontSize: '20px' // Adjust the font size for the title
          }}
        >
          Custom Plan (Contact Us)
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500]
          })}
        >
          <CloseIcon color="error" />
        </IconButton>
        <Divider />
        <DialogContent
          dividers
          className="choose_option_modal"
          style={{
            padding: '8px 16px', // Reduce padding to make the dialog more compact
            boxShadow: 'unset',
            border: 'unset'
          }}
        >
          {/* style={{ display: 'flex', alignItems: 'center' }} */}
          {/* style={{ fontWeight: 'bold' }} */}
          {isLoadingMachine ? (
            <>
              <p>Loading....</p>
            </>
          ) : (
            <>
              <Form noValidate onSubmit={handleSubmit}>
                <Grid container spacing={2.5} style={{ paddingTop: '30px' }}>
                  <Grid
                    item
                    lg={4}
                    md={4}
                    sm={4}
                    xs={12}
                    className="select-machine-id inv-number select-machine"
                    // className="select-job-enterprise select_separate2"
                    alignItems={'center'}
                  >
                    <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
                      Approx. Machine Specific Users *
                    </Typography>
                    <FormControl fullWidth variant="outlined" error={Boolean(touched.aproxMachineUser && errors.aproxMachineUser)}>
                      <OutlinedInput
                        id="outlined-adornment-service-price"
                        type="number"
                        value={values?.aproxMachineUser}
                        name="aproxMachineUser"
                        onBlur={handleBlur}
                        // onChange={handleChangePrice}
                        onChange={handleChange}
                        label={'Approximate Machine Specific Users'}
                        placeholder="0"
                        autoComplete="off"
                        onPaste={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                        onCopy={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                        onKeyDown={(e) => {
                          exceptThisSymbols.includes(e.key) && e.preventDefault();
                        }}
                        endAdornment={
                          <>
                            <IconButton
                              onClick={() => {
                                // Increment value by 1
                                const newValue = Math.max(1, values?.aproxMachineUser + 1); // Ensure no negative or zero value
                                setFieldValue('aproxMachineUser', newValue);
                              }}
                              aria-label="increment"
                            >
                              <Add color="success" />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                // Decrement value by 1
                                const newValue = Math.max(1, values?.aproxMachineUser - 1); // Ensure no negative or zero value
                                setFieldValue('aproxMachineUser', newValue);
                              }}
                              aria-label="decrement"
                            >
                              <Remove color="error" />
                            </IconButton>
                          </>
                        }
                      />
                      {touched.aproxMachineUser && errors.aproxMachineUser && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-email-login"
                          sx={{
                            fontSize: '13.8px'
                          }}
                        >
                          {errors.aproxMachineUser}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    lg={4}
                    md={4}
                    sm={4}
                    xs={12}
                    className="select-machine-id inv-number select-machine"
                    // className="select-job-enterprise select_separate2"
                    alignItems={'center'}
                  >
                    <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
                      Approx. Global Users *
                    </Typography>
                    <FormControl fullWidth variant="outlined" error={Boolean(touched.aproxGlobalUser && errors.aproxGlobalUser)}>
                      <OutlinedInput
                        id="outlined-adornment-service-price"
                        type="number"
                        value={values?.aproxGlobalUser}
                        name="aproxGlobalUser"
                        onBlur={handleBlur}
                        // onChange={handleChangePrice}
                        onChange={handleChange}
                        label={'Approximate Global Users'}
                        placeholder="0"
                        autoComplete="off"
                        onPaste={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                        onCopy={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                        onKeyDown={(e) => {
                          exceptThisSymbols.includes(e.key) && e.preventDefault();
                        }}
                        endAdornment={
                          <>
                            <IconButton
                              onClick={() => {
                                // Increment value by 1
                                const newValue = Math.max(1, values?.aproxGlobalUser + 1); // Ensure no negative or zero value
                                setFieldValue('aproxGlobalUser', newValue);
                              }}
                              aria-label="increment"
                            >
                              <Add color="success" />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                // Decrement value by 1
                                const newValue = Math.max(1, values?.aproxGlobalUser - 1); // Ensure no negative or zero value
                                setFieldValue('aproxGlobalUser', newValue);
                              }}
                              aria-label="decrement"
                            >
                              <Remove color="error" />
                            </IconButton>
                          </>
                        }
                      />
                      {touched.aproxGlobalUser && errors.aproxGlobalUser && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-email-login"
                          sx={{
                            fontSize: '13.8px'
                          }}
                        >
                          {errors.aproxGlobalUser}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    lg={4}
                    md={4}
                    sm={4}
                    xs={12}
                    className="select-machine-id inv-number select-machine"
                    // className="select-job-enterprise select_separate2"
                    alignItems={'center'}
                  >
                    <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
                      Expected No. of Advertisements *
                    </Typography>
                    <FormControl fullWidth variant="outlined" error={Boolean(touched.noOfAds && errors.noOfAds)}>
                      <OutlinedInput
                        id="outlined-adornment-service-price"
                        type="number"
                        value={values?.noOfAds}
                        name="noOfAds"
                        onBlur={handleBlur}
                        // onChange={handleChangePrice}
                        onChange={handleChange}
                        label={'Expected No. of Advertisements'}
                        placeholder="0"
                        autoComplete="off"
                        onPaste={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                        onCopy={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                        onKeyDown={(e) => {
                          exceptThisSymbols.includes(e.key) && e.preventDefault();
                        }}
                        endAdornment={
                          <>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center'
                                // border: '1px solid #ccc', // You can change the color to your preference
                                // borderRadius: '8px', // Add border-radius for rounded corners
                                // padding: '4px' // Padding for spacing around the icons
                              }}
                            >
                              <IconButton
                                onClick={() => {
                                  // Increment value by 1
                                  const newValue = Math.max(1, values?.noOfAds + 1); // Ensure no negative or zero value
                                  setFieldValue('noOfAds', newValue);
                                }}
                                aria-label="increment"
                              >
                                <Add color="success" />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  // Decrement value by 1
                                  const newValue = Math.max(0, values?.noOfAds - 1); // Ensure no negative or zero value
                                  setFieldValue('noOfAds', newValue);
                                }}
                                aria-label="decrement"
                              >
                                <Remove color="error" />
                              </IconButton>
                            </div>
                          </>
                        }
                      />
                      {touched.noOfAds && errors.noOfAds && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-email-login"
                          sx={{
                            fontSize: '13.8px'
                          }}
                        >
                          {errors.noOfAds}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    {errors.submit && (
                      <Box sx={{ mt: 3 }}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Box>
                    )}
                    <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                      <Box sx={{ display: 'inline-block' }}>
                        <AnimateButton>
                          <Button
                            disableElevation
                            disabled={isLoadingMachine}
                            size="large"
                            type="submit"
                            variant="contained"
                            color="secondary"
                            style={{
                              color: 'white',
                              minWidth: '200px',
                              margin: '0px auto'
                            }}
                          >
                            {isLoadingMachine ? (
                              <CircularProgress
                                sx={{
                                  color: 'white',
                                  height: 20,
                                  width: 20
                                }}
                              />
                            ) : (
                              `${btnName}`
                            )}
                          </Button>
                        </AnimateButton>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
          {/* display: 'flex', alignItems: 'center', */}
          {/* style={{ textAlign: 'center' }} */}
          {/* <Grid container spacing={2} sx={{ width: '50%' }} style={{ paddingTop: '50px' }}> */}
          {/* <Grid item xs={12} sm={4} md={4} lg={4}>
              <Button
                disableElevation
                size="large"
                type="button"
                variant="contained"
                color="secondary"
                onClick={() => {
                  navigate(urlAddBatch);
                }}
                style={{ padding: '6px 12px', fontSize: '14px', color: 'white', backgroundColor: 'rgb(111, 186, 85)' }} // Adjust button padding and font size
              >
                Submit
              </Button>
            </Grid> */}
          {/* </Grid> */}
        </DialogContent>
      </Dialog>
    </>
  );
}
