import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { Grid, Card, CardContent, Typography, Switch, FormControlLabel, CircularProgress, IconButton, Box } from '@mui/material';
// import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
// import AddNewTableHeader from './add-new-table-header';
import { useSavePermissionData } from 'hooks/useControlPanelHooks';
import ModifyPermissionSection from './ModifyPermissionSection';

function ModifyPermission({
  addPermissionList = [],
  editPermissionList = [],
  filtersPermissionList = [],
  modalPermissionList = [],
  tableHeadersPermissionList = [],
  showAdd = false,
  showEdit = false,
  showView = false,
  showModal = false,
  isLoadingPermissionList = false,
  screen,
  userType,
  orgType,
  permissionList
}) {
  const [addTableHeader, setAddTableHeader] = useState(false);
  const [editableData, setEditableData] = useState(null);

  const { mutate: savePermissionData, isLoading: isSaving } = useSavePermissionData();

  const initialValues = {
    showAdd,
    showEdit,
    showView,
    showModal,
    add: addPermissionList,
    edit: editPermissionList,
    filters: filtersPermissionList,
    tableHeaders: tableHeadersPermissionList,
    modal: modalPermissionList,
    newTableHeader: {
      id: '',
      label: '',
      changeState: false,
      sortField: false
    }
  };
  /*console.log(
    'UserPermissionControl ~ addPermissionList',
    addPermissionList,
    ' | permissionList : ',
    permissionList,
    ' | initialValues : ',
    initialValues
  );*/

  const renderPermissionCards = (sectionName, values, arrayHelpers) => {
    // console.log('UserPermissionControl ~ arrayHelpers', arrayHelpers);
    return (
      <Grid
        container
        spacing={{
          xs: 1, // Reduced spacing for smaller devices
          sm: 2,
          md: 3,
          lg: 4,
          xl: 4
        }}
      >
        {values[sectionName].map((item, index) => (
          <Grid item xs={12} sm={6} md={6} lg={4} xl={4} key={index}>
            <Card variant="outlined">
              <CardContent
                sx={{
                  p: { xs: 1, sm: 2 }
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="h6">ID: {item.id}</Typography>
                  <Typography variant="body1">Label: {item.label}</Typography>
                </Box>
                {sectionName === 'filters' && (
                  <>
                    <FormControlLabel
                      disabled={isSaving}
                      control={<Switch checked={item.show} onChange={() => arrayHelpers.replace(index, { ...item, show: !item.show })} />}
                      label="Show"
                    />
                  </>
                )}
                {sectionName === 'modal' && (
                  <>
                    <FormControlLabel
                      disabled={isSaving}
                      control={<Switch checked={item.show} onChange={() => arrayHelpers.replace(index, { ...item, show: !item.show })} />}
                      label="Show"
                    />
                  </>
                )}
                {sectionName === 'add' || sectionName === 'edit' ? (
                  <>
                    <FormControlLabel
                      disabled={isSaving}
                      control={<Switch checked={item.show} onChange={() => arrayHelpers.replace(index, { ...item, show: !item.show })} />}
                      label="Show"
                    />
                    <FormControlLabel
                      disabled={isSaving}
                      control={
                        <Switch
                          checked={item.isRequired}
                          onChange={() => arrayHelpers.replace(index, { ...item, isRequired: !item.isRequired })}
                        />
                      }
                      label="Required"
                    />
                  </>
                ) : null}
                {sectionName === 'edit' ? (
                  <FormControlLabel
                    disabled={isSaving}
                    control={
                      <Switch checked={item.isStatic} onChange={() => arrayHelpers.replace(index, { ...item, isStatic: !item.isStatic })} />
                    }
                    label="Static"
                  />
                ) : null}
                {sectionName === 'tableHeaders' ? (
                  <>
                    <FormControlLabel
                      disabled={isSaving}
                      control={
                        <Switch
                          checked={item.changeState}
                          onChange={() => arrayHelpers.replace(index, { ...item, changeState: !item.changeState })}
                        />
                      }
                      label="Change State"
                    />
                    <FormControlLabel
                      disabled={isSaving}
                      control={
                        <Switch
                          checked={item.sortField}
                          onChange={() => arrayHelpers.replace(index, { ...item, sortField: !item.sortField })}
                        />
                      }
                      label="Sort Field"
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <IconButton
                        disabled={isSaving}
                        color="info"
                        onClick={() => {
                          setEditableData(item);
                          setAddTableHeader(true);
                        }}
                        title="Edit"
                      >
                        <DriveFileRenameOutlineIcon />
                      </IconButton>
                      <IconButton disabled={isSaving} color="error" onClick={() => arrayHelpers.remove(index)} title="Remove">
                        <RemoveCircleIcon />
                      </IconButton>
                    </Box>
                  </>
                ) : null}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };

  if (isLoadingPermissionList) {
    return (
      <Grid container justifyContent="center">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          const requestData = {
            userType,
            orgType,
            screen,
            showView: values.showView,
            view: {
              filters: values.filters,
              tableHeaders: values.tableHeaders
            },
            showAdd: values.showAdd,
            add: values.showAdd ? values.add : [],
            showEdit: values.showEdit,
            edit: values.showEdit ? values.edit : [],
            showModal: values.showModal,
            modal: values.showModal ? values.modal : []
          };

          savePermissionData(requestData);
        }}
      >
        {({ values, setFieldValue, setValues }) => {
          return (
            <Form>
              <ModifyPermissionSection
                initialValues={initialValues}
                renderPermissionCards={renderPermissionCards}
                setAddTableHeader={setAddTableHeader}
                editableData={editableData}
                setEditableData={setEditableData}
                setFieldValue={setFieldValue}
                values={values}
                addTableHeader={addTableHeader}
                setValues={setValues}
                permissionList={permissionList}
                isLoadingPermissionList={isLoadingPermissionList}
                isSaving={isSaving}
              />
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

ModifyPermission.propTypes = {
  addPermissionList: PropTypes.array,
  editPermissionList: PropTypes.array,
  filtersPermissionList: PropTypes.array,
  modalPermissionList: PropTypes.array,
  tableHeadersPermissionList: PropTypes.array,
  showAdd: PropTypes.bool,
  showEdit: PropTypes.bool,
  showView: PropTypes.bool,
  isLoadingPermissionList: PropTypes.bool,
  screen: PropTypes.string,
  userType: PropTypes.string,
  orgType: PropTypes.string,
  showModal: PropTypes.bool
};
export default ModifyPermission;
