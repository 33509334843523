// import axiosInstance from 'utils/axiosInstance';
// import setAuthToken from 'store/helper/setAuthToken';
import axiosInstance from 'utils/axiosInstance';
import { setToast, toastConfig } from 'utils/commonUtil';
import { endpoints } from 'endpoints/endpoints';
import { SET_ORG_TYPE, SET_USER_TYPE } from './actions';
// import { useQueryClient } from '@tanstack/react-query';

export const userLoginHelper = (data) => {
  return {
    type: 'SET_USER_AUTH',
    payload: data
  };
};

const userLogoutHelper = (data) => {
  return {
    type: 'DELETE_USERS_DATA',
    payload: data
  };
};

export const userLogin = (loginCredentials, history, setIsLoading) => {
  return async (dispatch) => {
    try {
      setIsLoading(true);

      /**
       * when login api work on this code
       *
       */
      await axiosInstance
        .post(`${endpoints.users}/login`, loginCredentials)
        .then((res) => {
          // console.log('🚀 ~ .then ~ res:', res);
          if (res.status === 200) {
            setIsLoading(false);

            localStorage.setItem('userJwtToken', res?.data?.data?.token);
            dispatch(userLoginHelper(res?.data?.data?.user));
            localStorage.setItem('userData', JSON.stringify(res?.data?.data?.user));
            localStorage.setItem('userOrgId', JSON.stringify(res?.data?.data?.user?.id));
            // localStorage.setItem('userOrgName', JSON.stringify(res?.data?.data?.user?.organizationName));
            const organizationTypes = res?.data?.data?.user?.organizationTypes;
            const userTypes = res?.data?.data?.user?.types;
            // console.log('userLogin ~ organizationTypes : ', organizationTypes, ' | userTypes : ', userTypes);
            localStorage.setItem(
              'orgType',
              organizationTypes && Array.isArray(organizationTypes) && organizationTypes.length > 0 ? organizationTypes[0] : null
            );
            localStorage.setItem('userType', userTypes && Array.isArray(userTypes) && userTypes.length > 0 ? userTypes[0] : null);

            toastConfig.type = 'success';
            setToast(toastConfig, res?.data?.message);
            history('/dashboard/default');
          }
        })
        .catch((res) => {
          // console.log('🚀 ~ return ~ resLogin:', res);
          setIsLoading(false);
          toastConfig.type = 'error';
          setToast(toastConfig, res?.response?.data?.error);
        });
      /**
       * when login api work on this code
       *
       */
    } catch (err) {
      if (err.response) {
        console.log('🚀 ~ return ~ err.response:', err.response);
        const message = { message: err.response.data.message };
        dispatch({
          type: 'SET_LOGIN_ERRORS',
          payload: message
        });
      } else {
        const message = { message: err };

        return message;
      }
    }
  };
};

export const userLogout = (history) => {
  // const queryClient = useQueryClient();
  return (dispatch) => {
    localStorage.removeItem('userJwtToken');
    localStorage.removeItem('userData');
    localStorage.removeItem('userOrgId');
    // localStorage.removeItem('userOrgName');
    localStorage.removeItem('userType');
    localStorage.removeItem('orgType');
    dispatch({ type: SET_ORG_TYPE, orgType: '' });
    dispatch({ type: SET_USER_TYPE, userType: '' });
    // setAuthToken(false);
    dispatch(userLogoutHelper({}));
    history('/');
    // queryClient.clear();
  };
};
