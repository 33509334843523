import { Formik } from 'formik';
// import { useGetLocationId, useLocationUpdate } from 'hooks/useLocationHooks';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import FormView from 'ui-component/cards/Skeleton/FormView';
import BreadcrumbsForPage from 'ui-component/extended/BreadcrumbsForPage';
import * as Yup from 'yup';
import 'yup-phone';
import ResellerLocationForm from '../form';

const ResellerEditLocation = () => {
  let { id } = useParams();
  const { data: locationDetails } = useGetLocationId({
    id: id.slice(1)
  });
  const { mutate: updateLocation, isPending: isLoadingUpdateLocation } = useLocationUpdate();

  const [isLoading, setLoading] = useState(true);
  const [locationLatLng, setLocationLatLng] = useState({
    lat: '',
    lng: '',
    label: ''
  });
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <>
      {isLoading ? (
        <FormView />
      ) : (
        <>
          <BreadcrumbsForPage name={`Edit Location`} obj={{ title: 'All Locations', title2: '', url: '/dashboard/enterprise/location' }} />
          <MainCard>
            <Formik
              initialValues={{
                location_name: '',

                location: '',
                placeId: '',
                latitude: '',
                longitude: '',
                tax: '',
                submit: null
              }}
              validationSchema={Yup.object().shape({
                location_name: Yup.string()
                  .max(50, 'Location Name must be at most 50 characters')
                  .required('Location Name is required')
                  .trim(),

                location: Yup.string().required('Address is required'),
                tax: Yup.number()
                  .positive('Tax must contain a positive number')
                  .max(100, 'Maximum allowed value is 100')
                  .test('is-decimal', 'Tax must have at most two decimal places', (value) => /^\d+(\.\d{1,2})?$/.test(value))
                  .when('areSeatsLimited', {
                    is: 'yes',
                    then: Yup.number().required().typeError('Tax must be a number'),
                    otherwise: Yup.number().notRequired().typeError('Tax must be a number')
                  })
                  .required('Tax is required')
              })}
              onSubmit={async (value) => {
                if (!value.location || !value.placeId) {
                  toastConfig.type = 'error';
                  setToast(toastConfig, 'Oops! Enter your full address');
                  return;
                }
                const submitData = {
                  id: id.slice(1),
                  name: value?.location_name.trim(),
                  address: {
                    fullAddress: value?.location.trim(),
                    placeId: value?.placeId,
                    coOrdinates: {
                      latitude: value?.latitude,
                      longitude: value?.longitude
                    }
                  },
                  taxAmount: value?.tax
                };

                updateLocation(submitData);
              }}
            >
              {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
                <ResellerLocationForm
                  btnName="Update"
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting}
                  touched={touched}
                  values={values}
                  setFieldValue={setFieldValue}
                  locationDetails={locationDetails}
                  isLoadingLocation={isLoadingUpdateLocation}
                  locationLatLng={locationLatLng}
                  setLocationLatLng={setLocationLatLng}
                  id={id}
                />
              )}
            </Formik>
          </MainCard>
        </>
      )}
    </>
  );
};

export default ResellerEditLocation;
