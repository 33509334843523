import { Box, Button, Chip, Divider, Grid, Paper, Skeleton, Typography } from '@mui/material';
import React from 'react';

const EmployeeMachineListSkeleton = () => {
  return (
    <>
      {/* <Grid container spacing={4}> */}
      {[1, 2, 3].map((i) => (
        <Grid item xs={12} sm={6} lg={4} key={i}>
          <Paper
            elevation={3}
            sx={{
              padding: '24px',
              height: '100%',
              position: 'relative' // Set the Paper component to relative positioning
            }}
          >
            {/* <FiberManualRecordIcon
            sx={{
              fontSize: 20,
              // color: '#6fd74b',
              color: statusColors[machine?.status || ''],
              position: 'absolute', // Set the icon to absolute positioning
              top: 5, // Adjust the distance from the top
              right: 5 // Adjust the distance from the right
            }}
          /> */}
            {/* Machine Header */}
            <Box
              sx={{
                backgroundColor: '#bafba4',
                color: '#009939',
                fontWeight: 'bold',
                fontSize: '1rem',
                padding: '8px 16px',
                borderRadius: '8px',
                marginBottom: '16px',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              Machine ID: <Skeleton variant="text" sx={{ fontSize: '1rem', width: 150 }} />
            </Box>

            {/* Nickname */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                Nickname: {'  '}
              </Typography>
              {'  '}
              <Skeleton variant="text" sx={{ fontSize: '1rem', width: 100 }} />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                Serial No. :
              </Typography>
              <Skeleton variant="text" sx={{ fontSize: '1rem', width: 100 }} />
            </Box>

            {/* Location */}
            <Typography variant="body2" sx={{ marginTop: '8px', fontWeight: 500 }}>
              <Skeleton variant="text" sx={{ fontSize: '1rem', width: 200 }} />
            </Typography>
            <Typography variant="body2" sx={{ marginTop: '0px' }}>
              <Skeleton variant="text" sx={{ fontSize: '1rem', width: 200 }} />
            </Typography>

            {/* Subscription Details */}
            <div style={{ height: '100%' }}>
              {/* Subscription Badge & Price */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  marginTop: '16px'
                }}
              >
                <Chip
                  label={<Skeleton variant="text" sx={{ fontSize: '1rem', width: 60 }} />}
                  sx={{
                    backgroundColor: '#6fd74b',
                    color: '#fff',
                    fontWeight: 'bold'
                  }}
                />
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    <Skeleton variant="rectangular" sx={{ fontSize: '1rem', width: 60, height: 30 }} />
                  </Typography>
                </Box>
              </Box>

              {/* Subscription Limits */}
              <Box sx={{ marginTop: '8px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="body2">
                    <strong>Machine Specific User(s): </strong>
                  </Typography>
                  <Skeleton variant="text" sx={{ fontSize: '1rem', width: 100 }} />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="body2">
                    <strong> Global User(s): </strong>
                  </Typography>
                  <Skeleton variant="text" sx={{ fontSize: '1rem', width: 100 }} />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="body2">
                    <strong> Advertisements: </strong>
                  </Typography>
                  <Skeleton variant="text" sx={{ fontSize: '1rem', width: 100 }} />
                </Box>
              </Box>
              <Divider sx={{ mt: '10px' }} />
              {/* Currently Added */}
              <Box sx={{ marginTop: '8px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="body2">
                    ✅ <strong> Added Machine Specific User(s): </strong>
                  </Typography>
                  <Skeleton variant="text" sx={{ fontSize: '1rem', width: 100 }} />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="body2">
                    ✅ <strong> Added Advertisements: </strong>
                  </Typography>
                  <Skeleton variant="text" sx={{ fontSize: '1rem', width: 100 }} />
                </Box>
              </Box>

              {/* Upgrade Button */}
              <Button
                fullWidth
                variant="contained"
                color={'primary'}
                sx={{
                  marginTop: '16px',
                  color: 'white',
                  '&:hover': { backgroundColor: '#16a34a' }
                }}
              >
                <Skeleton variant="text" sx={{ fontSize: '1rem', width: 100 }} />
              </Button>
            </div>
          </Paper>
        </Grid>
      ))}
      {/* </Grid> */}
    </>
  );
};

export default EmployeeMachineListSkeleton;
