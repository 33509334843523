// eslint-disable-next-line no-restricted-imports
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { Autocomplete, Box, Button, FormControl, Grid, Paper, TextField, Typography } from '@mui/material';

import React from 'react';

import { gridSpacing } from 'store/constant';

import { useState } from 'react';
import { useEffect } from 'react';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useGetAllMachineJobList } from 'hooks/useMachineHooks';
import { useGetOrganizationNewAllList } from 'hooks/useOrganizationNewHooks';
import { useGetLocationAllNewList } from 'hooks/useLocationHooks';
const JobFilterForm = ({ values, resetForm, setFilterObj, jobFilters, setFieldValue, handleChange, touched, errors }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [orgsearchTerm, setOrgSearchTerm] = useState('');
  const [locationSearchTerm, setLocationSearchTerm] = useState('');
  const { data: allmachineList, isLoading: machineListLoading, refetch: refetch } = useGetAllMachineJobList(searchTerm);
  const { data: allOrgList, isLoading: orgListLoading, refetch: refetchOrg } = useGetOrganizationNewAllList(orgsearchTerm);
  const { data: allLocationLists, isLoading: locationListOrg, refetch: refetchLocation } = useGetLocationAllNewList(locationSearchTerm);

  const [machineData, setMachineData] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  useEffect(() => {
    const machineData = allmachineList?.data?.map((item) => ({
      id: item?.id,
      nickName: item?.nickName,
      serialNumber: item?.serialNumber,
      machineName: item?.machineName
    }));

    setMachineData(machineData);
  }, [allmachineList]);

  useEffect(() => {
    const orgData = allOrgList?.data?.map((item) => ({ label: item?.name, id: item?.id }));
    setOrgData(orgData);
  }, [allOrgList]);
  useEffect(() => {
    const locationData = allLocationLists?.data?.map((item) => ({
      label: item?.name,
      id: item?.id,
      address: item?.address?.fullAddress
    }));
    setLocationData(locationData);
  }, [allLocationLists]);

  const handleDateChange = (newValue, fieldName) => {
    if (dayjs(newValue).isValid()) {
      setFieldValue(fieldName, newValue);
    } else if (!newValue) {
      // If cleared manually, set to null
      setFieldValue(fieldName, null);
    }
  };

  const handelKeyUpMachine = (e) => {
    // console.log('handelKeyUpMachine', e, e.target.value);
    if (e.target.value) setSearchTerm(e.target.value);
  };
  const handleSearchChange = (event, newInputValue) => {
    setSearchTerm(newInputValue);
    refetch();
  };
  const handleSearchOrgChange = (event, newInputValue) => {
    setOrgSearchTerm(newInputValue);
    refetchOrg();
  };
  const handelKeyUpOrganization = (e) => {
    if (e.target.value) setOrgSearchTerm(e.target.value);
  };

  const handleSearchLocationChange = (event, newInputValue) => {
    setLocationSearchTerm(newInputValue);
    refetchLocation();
  };
  const handelKeyUpLocation = (e) => {
    if (e.target.value) setLocationSearchTerm(e.target.value);
  };

  const sowField = (filter, index) => {
    if (filter?.show)
      if (filter?.id == 'machineId')
        return (
          <Grid key={index} item xs={12} sm={12} md={4} lg={4} className="select-job-enterprise select-job-enterprise-type">
            <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
              Machine
            </Typography>
            <Autocomplete
              // multiple
              // disableClearable
              options={machineData || []}
              value={values?.machineId || null} // Ensure default is an array
              getOptionLabel={(option) => (option.id ? `${option?.machineName}` : '')}
              onInputChange={handleSearchChange}
              // isOptionEqualToValue={(option, value) => option.id === value?.id}
              onChange={(event, newValue) => {
                setFieldValue('machineId', newValue);
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  const { onDelete, ...tagProps } = getTagProps({ index }); // Extract onDelete for manual use
                  return (
                    <span
                      key={option}
                      {...tagProps}
                      style={{
                        backgroundColor: '#6fd74b',
                        color: '#fff',
                        padding: '2px 8px',
                        borderRadius: '4px',
                        marginRight: '5px',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      {option?.machineName}
                      <button
                        onClick={onDelete} // Call the delete function for this tag
                        style={{
                          marginLeft: '5px',
                          background: 'none',
                          border: 'none',
                          color: '#fff',
                          cursor: 'pointer'
                        }}
                      >
                        ✖
                      </button>
                    </span>
                  );
                })
              }
              renderInput={(params) => (
                <TextField
                  onKeyUp={handelKeyUpMachine}
                  placeholder="Search and Select Machine"
                  {...params}
                  variant="outlined"
                  // label="Types"
                  // placeholder="Select types"
                  InputLabelProps={
                    {
                      // shrink: true
                    }
                  }
                />
              )}
              loading={machineListLoading}
              PaperComponent={(props) => (
                <Paper
                  {...props}
                  style={{
                    // maxHeight: 200, // Adjust as needed
                    overflowY: 'auto'
                  }}
                />
              )}
            />
          </Grid>
        );
      else if (filter?.id == 'employeeName')
        return (
          <Grid
            key={index}
            container
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className="select-machine-id inv-number select-machine"
            // className="select-job-enterprise select_separate2"
            alignItems={'center'}
          >
            <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
              {/* {transformedPermissionsFilters['empName']?.label}{' '} */}
              Employee Name
            </Typography>
            <FormControl fullWidth variant="outlined">
              <TextField
                placeholder="Search Name"
                // label="Organization Name"
                // placeholder={`Search ${transformedPermissionsFilters['empName']?.label}`}
                variant="outlined"
                type="text"
                fullWidth
                name="name"
                value={values?.name}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
        );
      else if (filter?.id == 'referenceNo')
        return (
          <Grid
            key={index}
            container
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className="select-machine-id inv-number select-machine "
            // className="select-job-enterprise select_separate2"
            alignItems={'center'}
          >
            <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
              {/* {transformedPermissionsFilters['empName']?.label}{' '} */}
              Reference No.
            </Typography>
            <FormControl fullWidth variant="outlined">
              <TextField
                placeholder="Search Reference No."
                // label="Organization Name"
                // placeholder={`Search ${transformedPermissionsFilters['empName']?.label}`}
                variant="outlined"
                type="text"
                fullWidth
                name="referenceNo"
                value={values?.referenceNo}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
        );
      else if (filter?.id == 'dateRange')
        return (
          <Grid
            key={index}
            container
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className="select-machine-id inv-number select-machine "
            alignItems={'center'}
          >
            <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
              Date Range
            </Typography>
            <FormControl fullWidth className="form_monthrange  ">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container spacing={2}>
                  <Grid item xs={6} className="date_range_machine_ticket_line">
                    <DatePicker
                      format="DD/MM/YYYY"
                      value={values.startDate ? dayjs(values.startDate) : null}
                      onChange={(newValue) => handleDateChange(newValue, 'startDate')}
                      maxDate={dayjs()}
                      slotProps={{
                        textField: {
                          helperText: touched.startDate && errors.startDate,
                          error: touched.startDate && Boolean(errors.startDate),
                          readOnly: true
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DatePicker
                      format="DD/MM/YYYY"
                      value={values.endDate ? dayjs(values.endDate) : null}
                      onChange={(newValue) => handleDateChange(newValue, 'endDate')}
                      minDate={values.endDate || dayjs()}
                      slotProps={{
                        textField: {
                          helperText: touched.endDate && errors.endDate,
                          error: touched.endDate && Boolean(errors.endDate),
                          readOnly: true // Disables manual input
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </LocalizationProvider>
            </FormControl>
          </Grid>
        );
      else if (filter?.id == 'orgId')
        return (
          <Grid key={index} item xs={12} sm={12} md={4} lg={4} className="select-job-enterprise select-job-enterprise-type">
            <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
              Organization Name
            </Typography>
            <Autocomplete
              // multiple
              // disableClearable
              options={orgData || []}
              value={values?.organization || null} // Ensure default is an array
              getOptionLabel={(option) => option.label || ''}
              onInputChange={handleSearchOrgChange}
              onChange={(event, newValue) => handleChange({ target: { name: 'organization', value: newValue } })}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  const { onDelete, ...tagProps } = getTagProps({ index }); // Extract onDelete for manual use
                  return (
                    <span
                      key={option}
                      {...tagProps}
                      style={{
                        backgroundColor: '#6fd74b',
                        color: '#fff',
                        padding: '2px 8px',
                        borderRadius: '4px',
                        marginRight: '5px',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      {option}
                      <button
                        onClick={onDelete} // Call the delete function for this tag
                        style={{
                          marginLeft: '5px',
                          background: 'none',
                          border: 'none',
                          color: '#fff',
                          cursor: 'pointer'
                        }}
                      >
                        ✖
                      </button>
                    </span>
                  );
                })
              }
              renderInput={(params) => (
                <TextField
                  onKeyUp={handelKeyUpOrganization}
                  placeholder="Search and Select Organization"
                  {...params}
                  variant="outlined"
                  // label="Types"
                  // placeholder="Select types"
                  InputLabelProps={
                    {
                      // shrink: true
                    }
                  }
                />
              )}
              loading={orgListLoading}
              PaperComponent={(props) => (
                <Paper
                  {...props}
                  style={{
                    // maxHeight: 200, // Adjust as needed
                    overflowY: 'auto'
                  }}
                />
              )}
            />
          </Grid>
        );
      else if (filter?.id == 'location')
        return (
          <Grid key={index} item xs={12} sm={12} md={4} lg={4} className="select-job-enterprise select-job-enterprise-type">
            <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
              Location Name
            </Typography>
            <Autocomplete
              // multiple
              // disableClearable
              options={locationData || []}
              value={values?.location || null} // Ensure default is an array
              // getOptionLabel={(option) => option.label || ''}
              getOptionLabel={(option) => `${option.label} (${option.address || ''}) `}
              onInputChange={handleSearchLocationChange}
              onChange={(event, newValue) => handleChange({ target: { name: 'location', value: newValue } })}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  const { onDelete, ...tagProps } = getTagProps({ index }); // Extract onDelete for manual use
                  return (
                    <span
                      key={option}
                      {...tagProps}
                      style={{
                        backgroundColor: '#6fd74b',
                        color: '#fff',
                        padding: '2px 8px',
                        borderRadius: '4px',
                        marginRight: '5px',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      {option}
                      <button
                        onClick={onDelete} // Call the delete function for this tag
                        style={{
                          marginLeft: '5px',
                          background: 'none',
                          border: 'none',
                          color: '#fff',
                          cursor: 'pointer'
                        }}
                      >
                        ✖
                      </button>
                    </span>
                  );
                })
              }
              renderInput={(params) => (
                <TextField
                  onKeyUp={handelKeyUpLocation}
                  placeholder="Search and Select Location"
                  {...params}
                  variant="outlined"
                  // label="Types"
                  // placeholder="Select types"
                  InputLabelProps={
                    {
                      // shrink: true
                    }
                  }
                />
              )}
              loading={locationListOrg}
              PaperComponent={(props) => (
                <Paper
                  {...props}
                  style={{
                    // maxHeight: 200, // Adjust as needed
                    overflowY: 'auto'
                  }}
                />
              )}
            />
          </Grid>
        );
  };
  return (
    <>
      <Grid key={'jobFilter'} container spacing={gridSpacing} sx={{ marginBottom: '20px', alignItems: 'center', padding: '0px 20px' }}>
        {jobFilters?.map((filter, index) => sowField(filter, index))}
      </Grid>
      <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'right', paddingBottom: '20px', paddingRight: '20px' }}>
        <Button
          variant="outlined"
          type="reset"
          color="error"
          onClick={() => {
            resetForm();
            setFilterObj({
              machineId: '',
              startDate: '',
              endDate: '',
              name: '',
              referenceNo: '',
              organization: '',
              location: ''
            });
          }}
        >
          Clear all
        </Button>
        <div style={{ marginLeft: '10px' }}>
          <Box sx={{ m: 0, position: 'relative' }}>
            <Button
              // fullWidth
              variant="contained"
              type="submit"
              style={{
                color: '#fff'
              }}
              color="primary"
              //   disabled={isLoadingUpdateInvoiceStatus}
            >
              Search
            </Button>
          </Box>
        </div>
      </div>
    </>
  );
};

export default JobFilterForm;
