/* eslint-disable */
import { Formik } from 'formik';
import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import EmployeeForm from '../form';
import BreadcrumbsForPage from 'ui-component/extended/BreadcrumbsForPage';
import * as Yup from 'yup';
import 'yup-phone';
import { useSelector } from 'react-redux';
import { Alert, AlertTitle } from '@mui/material';
import { Lock } from '@mui/icons-material';
import { useEmployeeCreate, useGetPermissionsEmployeeList, useGetSubcriptionsLimit } from 'hooks/useEmployeesHooks';
import { setToast, toastConfig } from 'utils/commonUtil';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import OrganizationFormView from 'ui-component/cards/Skeleton/OrganizationFormView';
import EmployeeSubcriptionForm from '../form/EmployeeSubcriptionForm';
import EmployeeSubcriptionMachineDetailsForm from '../form/EmployeeSubcriptionMachineDetailsForm';
const EmployeeSubcriptionMachineDetails = () => {
  const navigator = useNavigate();
  const orgType = useSelector((state) => state.customization.orgType);
  const { mutate: addEmployee, isPending: isLoadingAddEmployee } = useEmployeeCreate();
  const { mutate: checkEmployeeAdd, isPending: isLoadingCheckAdd } = useGetSubcriptionsLimit();

  const organizationType = useSelector((state) => state.customization.orgType);
  const usersType = useSelector((state) => state.customization.userType);
  // {Yup.object().shape({
  //   prefix: Yup.string().required('Prefix is required'),
  //   firstName: Yup.string()
  //     .max(50, 'First Name must be at most 50 characters')
  //     .required('First Name is required')
  //     .trim()
  //     .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')
  //     .matches(/^[a-zA-Z\s]*$/, 'First Name should only contain letters and spaces'),
  //   lastName: Yup.string().max(50, 'Last Name must be at most 50 characters').required('Last Name is required').trim(),
  //   title: Yup.string().required('Title is required'),
  //   email: Yup.string().email('Invalid email').required('Email is required'),
  //   phone: Yup.string()
  //     .phone('IN', true, 'Invalid phone number') // Use yup-phone if necessary
  //     .required('Phone number is required'),
  //   address: Yup.string().max(255, 'Address can be at most 255 characters'),
  //   rfid: Yup.string().required('RFID is required'),
  //   authPin: Yup.string().required('Auth Pin is required')
  // })}

  const validationSchema = Yup.object().shape({
    prefix: Yup.string().required('Prefix is required'),
    firstName: Yup.string().max(20, 'First name cannot be longer than 20 characters').required('First name is required'),
    lastName: Yup.string().max(20, 'Last name cannot be longer than 20 characters').required('Last name is required'),
    title: Yup.string().max(20, 'Title cannot be longer than 20 characters').required('Title is required'),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    phone: Yup.string()
      .matches(/^[0-9]+$/, 'Phone number should be numeric')
      .required('Phone number is required'),
    address: Yup.string().max(150, 'Address cannot be longer than 150 characters').required('Address is required'),
    rfid:
      orgType === 'Enterprice'
        ? Yup.string()
            .matches(/^[a-zA-Z0-9]+$/, 'RFID should be alphanumeric')
            .required('RFID is required')
        : Yup.string().notRequired(),
    authPin:
      orgType === 'Enterprice'
        ? Yup.string()
            .matches(/^[a-zA-Z0-9]+$/, 'Auth Pin should be alphanumeric')
            .required('Auth Pin is required')
        : Yup.string().notRequired(),
    installerRfid:
      orgType === 'Super Admin'
        ? Yup.string()
            .matches(/^[a-zA-Z0-9]+$/, 'Installer RFID should be alphanumeric')
            .required('Installer RFID is required')
        : Yup.string().notRequired(),
    installerAuthPin:
      orgType === 'Super Admin'
        ? Yup.string()
            .matches(/^[a-zA-Z0-9]+$/, 'Installer Auth Pin should be alphanumeric')
            .required('Installer Auth Pin is required')
        : Yup.string().notRequired(),
    profileImage: Yup.mixed().notRequired(), // Profile image is not mandatory
    isAdmin: Yup.boolean().required('isAdmin is required'),
    assign_org: Yup.array()
      .of(Yup.string())
      .min(1, 'At least one organization must be assigned')
      .required('At least one organization must be assigned'),
    // assignMachine: Yup.array().of(Yup.object()).min('At least one machine must be assigned').required('Assigned machines must be assigned')
    assign_machine: Yup.array()
      .of(Yup.object())
      .min(1, 'At least one Assign Machine must be assigned')
      .required('At least one Assign Machine must be assigned'),
    // Machine User Type validation (dynamic validation based on initialValues)
    machineUserType: Yup.object().test('validate-machine-user-type', 'Machine User Type cannot be empty', function (value) {
      // Check each entry in machineUserType
      // const errors = [];
      const errors = {};
      console.log('value | machineUserType : ', value, this);
      for (let key in value) {
        if (!value[key].machineTypeUser || value[key].machineTypeUser.trim() === '') {
          // this.createError({ path: `machineUserType.${key}.machineTypeUser`, message: 'Machine User Type cannot be empty' });
          // this.setFieldError(`machineUserType.${key}.machineTypeUser`, 'Machine User Type cannot be empty');
          /*errors.push({
            path: `machineUserType.${key}.machineTypeUser`,
            message: 'Machine User Type cannot be empty'
          });*/
          errors[key] = {
            machineTypeUser: 'Please Select Machine User Type'
          };
        }
      }
      // If errors are found, return them all at once
      /*if (errors.length > 0) {
        return this.createError({
          path: 'machineUserType',
          message: 'One or more machine user types are empty',
          params: { errors }
        });
      }*/
      // If there are errors, return them in the appropriate format
      if (Object.keys(errors).length > 0) {
        return this.createError({
          path: 'machineUserType',
          message: JSON.stringify(errors),
          params: { errors }
        });
      }
      return true; // If all entries pass validation
    })
  });

  function extractIds(data) {
    return data && Array.isArray(data) && data.length ? data.map((item) => item.id) : null;
  }

  const addEmployeeHandler = (submitData, resetForm, navigator) => {
    addEmployee(submitData, {
      onSuccess: (res) => {
        console.log('res - submitData - Employee : ', res);
        if (res?.status === 200) {
          toastConfig.type = 'success';
          setToast(toastConfig, res?.data.message);
          resetForm({});
          navigator('/dashboard/employees');
        } else {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        }
      },
      onError: (error) => {
        toastConfig.type = 'error';
        setToast(toastConfig, error?.response?.data?.error);
      }
    });
  };

  return (
    <>
      <BreadcrumbsForPage
        name="Machine Details"
        obj={{
          title: 'All Employees',
          title2: 'Add Employee',
          url: '/dashboard/employees'
        }}
      />
      <MainCard>
        <Formik
          initialValues={{
            prefix: '',
            firstName: '',
            lastName: '',
            title: '',
            email: '',
            phone: '',
            address: '',
            rfid: '',
            authPin: '',
            installerRfid: '',
            installerAuthPin: '',
            // documents: [],
            profileImage: '',
            isAdmin: false,
            // assignOrg: [],
            // assignMachine: [],
            assign_org: [],
            assign_machine: [],
            allocate_machine: [],
            machineUserType: {},
            createdByOrgType: orgType,
            hasGlobalAccess: false,
            org_type: []
            // submit: null,
            // user_type: ''
          }}
          validationSchema={validationSchema}
          onSubmit={async (value, { resetForm }) => {
            const organizationType =
              value?.org_type && Array.isArray(value?.org_type) && value?.org_type?.length
                ? value?.org_type.map((o) => {
                    return o.toString().replaceAll(' ', '');
                  })
                : '';
            const submitData = {
              isAdmin: value?.isAdmin ?? null,
              createdByOrgType: value?.createdByOrgType || null,
              prefix: value?.prefix || null,
              firstName: value?.firstName || null,
              lastName: value?.lastName || null,
              title: value?.title || null,
              email: value?.email || null,
              phone: value?.phone || null,
              address: value?.address || null,
              authenticationInfo:
                value?.rfid || value?.authPin
                  ? {
                      rfidCode: value?.rfid || null,
                      authPIN: value?.authPin || null
                    }
                  : null,
              saAuthenticationInfo:
                value?.installerRfid || value?.installerAuthPin
                  ? {
                      rfidCode: value?.installerRfid || null,
                      authPIN: value?.installerAuthPin || null
                    }
                  : null,
              image:
                value?.profileImage && Array.isArray(value?.profileImage) && value?.profileImage.length ? value?.profileImage[0] : null,
              assignedOrganizations: extractIds(value?.assign_org) || [],
              assignedMachines: extractIds(value?.assign_machine) || [],
              // allotedMachines: transformMachineUserType(value?.machineUserType) || [],
              allocatedMachines: extractIds(value?.allocate_machine) || [],
              hasGlobalAccess: value?.hasGlobalAccess || null,
              organizationTypes: organizationType || null
            };

            console.log(
              '🚀 ~ onSubmit={ ~ submitData | Employee :',
              submitData,
              value /*
              value?.machineUserType,
              ' | allotedMachines : ',
              transformMachineUserType(value?.machineUserType)*/
            );

            const checkEmployeAddPayload = {
              allocatedMachines: !value?.hasGlobalAccess ? extractIds(value?.allocate_machine) || [] : null,
              hasGlobalAccess: value?.hasGlobalAccess ?? null
            };
            console.log(
              'checkEmployeAdd - Employee : ',
              checkEmployeAddPayload,
              submitData.createdByOrgType,
              value?.hasGlobalAccess,
              value?.allocate_machine
            );
            if (value.createdByOrgType === 'Enterprise') {
              checkEmployeeAdd(checkEmployeAddPayload, {
                onSuccess: (res) => {
                  console.log('res - checkEmployeeAdd - Employee : ', res, checkEmployeAddPayload);
                  if (res?.status == 200) {
                    // toastConfig.type = 'success';
                    // setToast(toastConfig, res?.data.message);
                    addEmployeeHandler(submitData, resetForm, navigator);
                    // resetForm({});
                    // navigator('/dashboard/employees');

                    // setOpenDialog(true);
                  } else {
                    // toastConfig.type = 'error';
                    // setToast(toastConfig, res?.response?.data?.error);
                    if (res?.response?.status === 402 || res?.response?.status === 403) {
                      setOpenDialog((prev) => ({ ...prev, show: true, status: res?.response?.status }));
                    }
                  }
                },
                onError: (error) => {
                  // toastConfig.type = 'error';
                  // setToast(toastConfig, error?.response?.data?.error);
                  console.log('checkEmployeeAdd ~ error : ', error);
                }
              });
            } else {
              addEmployeeHandler(submitData, resetForm, navigator);
            }

            /*addEmployee(submitData, {
              onSuccess: (res) => {
                console.log('res - submitData - Employee : ', res);
                if (res?.status == 200) {
                  toastConfig.type = 'success';
                  setToast(toastConfig, res?.data.message);
                  resetForm({});
                  navigator('/dashboard/employees');
                } else {
                  toastConfig.type = 'error';
                  setToast(toastConfig, error?.response?.data?.error);
                }
              },
              onError: (error) => {
                toastConfig.type = 'error';
                setToast(toastConfig, error?.response?.data?.error);
              }
            });*/
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, setValues, setTouched }) => (
            <>
              <EmployeeSubcriptionMachineDetailsForm
                touched={touched}
                handleBlur={handleBlur}
                handleChange={handleChange}
                errors={errors}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
                values={values}
                setFieldValue={setFieldValue}
                setValues={setValues}
                setTouched={setTouched}
              />
            </>
          )}
        </Formik>
      </MainCard>
    </>
  );
};

export default EmployeeSubcriptionMachineDetails;
