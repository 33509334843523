import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { endpoints } from 'endpoints/endpoints';
import { useNavigate } from 'react-router';
import axiosInstance from 'utils/axiosInstance';
import { setToast, toastConfig } from 'utils/commonUtil';

// add
export const UseFaqAdd = async (data) => {
  const res = await axiosInstance.post(`${endpoints.faqs}/save`, data);
  return res;
};

export const useGetSuperAdminFaqList = ({ limit, sortOrder, sortField, page, status, question }) => {
  // const organizationType = localStorage.getItem('orgType');
  // const usersType = localStorage.getItem('userType');
  return useQuery({
    queryKey: ['getSuperAdminFaqData', limit, sortOrder, sortField, page, status, question],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.faqs}/${page}/${limit}`, {
          filters: {
            // enabled: status ? status : null,
            // status: values?.status === 'All' ? '' : values?.status === 'true' ? true : false,
            enabled: status === 'true' ? true : status === 'false' ? false : null,
            question: question || null
          },
          sort: {
            sortField: sortField,
            sortOrder: sortOrder
          }
        })

        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    staleTime: 0,
    enabled: Boolean(localStorage.getItem('userJwtToken')),
    // enabled: Boolean(limit) && Boolean(sortField),
    // refetchOnMount: false, // Avoid unnecessary refetching on component mount
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

// Permission List

export const useGetPermissionsFaqList = ({ organizationType, usersType }) => {
  return useQuery({
    queryKey: ['getPermissionsFaq', organizationType, usersType],
    queryFn: async () => {
      return await axiosInstance

        .get(`${endpoints.permissionsScreen}/${usersType}/${organizationType}/FAQs`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          toastConfig.type = 'error';
          setToast(toastConfig, e.response.data.e);
        });
    },
    enabled: Boolean(organizationType === 'SuperAdmin') && Boolean(usersType),
    staleTime: 0,
    onSuccess: ({ data }) => {
      return data;
    }
    // refetchInterval: 30000
  });
};

// Get BY Id details
export const useGetFaqDetailsId = ({ id }) => {
  return useQuery({
    queryKey: ['getFaqDetailsById', id],
    queryFn: async () => {
      return await axiosInstance
        .get(`${endpoints.faqs}/${id}`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          toastConfig.type = 'error';
          setToast(toastConfig, e.response.data.e);
          console.log(e);
        });
    },
    enabled: Boolean(id),
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

// edit Faq
export const useFaqUpdate = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: async (data) => {
      return await axiosInstance

        .put(`${endpoints.faqs}/${data.id}`, data)
        .then((res) => {
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data.message);
            navigate('/dashboard/faq/super-admin');
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },

    onSuccess: ({ data }) => {
      queryClient.refetchQueries({ queryKey: ['getFaqDetailsById'], type: 'active' });

      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};

// Status Update
export const useSuperAdminFaqStatusUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => {
      return await axiosInstance
        .patch(`${endpoints.faqs}/${data?.id}`, { status: data?.status })
        .then((res) => {
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data.message);
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },
    onSuccess: ({ data }) => {
      queryClient.refetchQueries({
        queryKey: ['getSuperAdminFaqData'],
        type: 'active'
      });
      return data;
    },
    onError: ({ error }) => {
      return error;
    }
  });
};

// // all List

export const useGetFaqAllList = () => {
  return useQuery({
    queryKey: ['getFaqAllListData'],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.faqs}`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onSuccess: ({ data }) => {
      return data;
    }
  });
};
