const initialState = {
  route: 'Machines',
  user: 'POC',
  org: 'Super Admin',
  routeList: [
    'Machines',
    'Organizations',
    'Locations',
    'Employees',
    'Ticket_Reasons',
    'Tickets',
    'Jobs',
    'Invoices',
    'Subscriptions',
    'Get_Support',
    'Notifications',
    'Settings',
    'FAQs',
    'Support_Tickets'
    // 'Control_Panel'
  ],
  userTypes: ['POC', 'Admin', 'Employee', 'Public User'],
  // orgTypes: ['Super_Admin', 'Enterprise', 'Recycler', 'Service_Provider', 'Reseller', 'Retailer']
  orgTypes: ['Super Admin', 'Enterprise', 'Recycler', 'Service Provider', 'Reseller', 'Retailer']
};
const controlPanelReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ROUTE':
      return {
        ...state,
        route: action.payload
      };
    case 'SET_USER':
      return {
        ...state,
        user: action.payload
      };
    case 'SET_ORG':
      return {
        ...state,
        org: action.payload
      };
    case 'SET_ROUTE_LIST':
      return {
        ...state,
        routeList: action.payload
      };
    case 'SET_USER_TYPES':
      return {
        ...state,
        userTypes: action.payload
      };
    case 'SET_ORG_TYPES':
      return {
        ...state,
        orgTypes: action.payload
      };

    default:
      return state;
  }
};

export default controlPanelReducer;
