import React from 'react';
import { Form, Formik } from 'formik';
import EmployeeFilterForm from './EmployeeFilterForm';
import { useSelector } from 'react-redux';

const EmployeeFilter = ({ setFilterObj, filterObj, isExistingFilter, permissionsList }) => {
  const orgType = useSelector((state) => state.customization.orgType);
  return (
    <Formik
      initialValues={{
        status: 'All',
        name: '',
        email: '',
        title: '',
        rfid: '',
        authPin: '',
        showAdmin: 'All',
        installerRfid: '',
        installerAuthPin: '',
        orgType: ''
      }}
      onSubmit={(values) => {
        // console.log('Submitted values:', values);
        setFilterObj((prev) => ({
          ...prev,
          status: values?.status === 'All' ? null : values?.status,
          name: values?.name ?? null,
          email: values?.email ?? null,
          title: values?.title ?? null,
          rfid: orgType === 'Enterprise' ? values?.rfid : null,
          authPin: orgType === 'Enterprise' ? values?.authPin : null,
          showAdmin: values?.showAdmin === 'All' ? null : values?.showAdmin,
          installerRfid: orgType === 'Super Admin' ? values?.installerRfid : null,
          installerAuthPin: orgType === 'Super Admin' ? values?.installerAuthPin : null
        }));
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, setTouched, resetForm }) => (
        <Form>
          <EmployeeFilterForm
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            touched={touched}
            values={values}
            setFieldValue={setFieldValue}
            setTouched={setTouched}
            resetForm={resetForm}
            filterObj={filterObj}
            setFilterObj={setFilterObj}
            isExistingFilter={isExistingFilter}
            permissionsList={permissionsList}
          />
        </Form>
      )}
    </Formik>
  );
};

export default EmployeeFilter;
