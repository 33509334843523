/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  // Stack,
  // Chip,
  // Typography,
  IconButton,
  Skeleton,
  CircularProgress,
  Button,
  Typography,
  Chip,
  Avatar
  // CircularProgress
  // Collapse
  // Tab
  // Stack,
  // Button,
  // CircularProgress,
  // Chip
  // Tooltip
  // Pagination
} from '@mui/material';

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
// import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
// import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';

// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { styled } from '@mui/material/styles';
import MainCard from 'ui-component/cards/MainCard';
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// routeing
import { useLocation, useNavigate } from 'react-router-dom';
// import Breadcrumbs from 'ui-component/extended/Breadcrumbs';

import BreadcrumbsForPage from 'ui-component/extended/BreadcrumbsForPage';

import SkeletonTableView from 'ui-component/cards/Skeleton/TableView';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Stack } from '@mui/system';
import VisibilityIcon from '@mui/icons-material/Visibility';
// import { useRetailerStatusUpdate } from 'hooks/useRetailerHooks';
import moment from 'moment';
// import RecyclerFilter from 'ui-component/organization-filter';
import {
  useGetOrganizationNewList
  // useOrganizationNewStatusUpdate
} from 'hooks/useOrganizationNewHooks';
import EmployeeModalDialog from 'ui-component/employee-filter/EmployeeModalDialog';
import { useSelector } from 'react-redux';
import { useEmployeeNewStatusUpdate, useGetEmployeeNewList, useGetPermissionsEmployeeList } from 'hooks/useEmployeesHooks';
import EmployeeFilter from 'ui-component/employee-filter';
import { getEmployeeFullName, getValueFromPath, setToast, toastConfig, truncatedText } from 'utils/commonUtil';
import { TooltipStatus } from 'ui-component/fields/tooltip.status';
import CustomTableCellWithLightbox from 'ui-component/employee-filter/CustomTableCellWithLightbox';

// import { useGetOrgList } from 'hooks/useOrganizationHooks';

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  margin: '0 auto',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626'
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959'
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343'
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c'
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff'
  }
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

function EnhancedTableHead({
  // classes,
  order,
  orderBy,
  onRequestSort,
  headersObj
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const setOrderByProps = (property) => {
    setOrderBy(property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* {columns?.map((headCell) => ( */}
        {headersObj?.map((headCell) => {
          return (
            <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false} align="center">
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                disabled={orderBy !== headCell.id}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip enterTouchDelay={0} {...props} classes={{ popper: className }} arrow />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9'
    }
  })
);
/*
function getValueFromArrayPath(obj, pathArray) {
  return Array.isArray(pathArray) && pathArray.length > 1
    ? pathArray.reduce((acc, part) => {
        // Check for bracket notation (e.g., ['data'])
        const matches = part.match(/^(\w+)\['([^']+)'\]$/);
        if (matches) {
          const key = matches[2]; // Extract key inside quotes
          return acc && acc[matches[1]] ? acc[matches[1]][key] : undefined;
        }

        // Check for array index (e.g., [0])
        const arrayMatches = part.match(/^(\w+)\[(\d+)\]$/);
        if (arrayMatches) {
          const key = arrayMatches[1]; // Extract object key
          const index = parseInt(arrayMatches[2], 10); // Extract array index
          return acc && acc[key] && Array.isArray(acc[key]) ? acc[key][index] : undefined;
        }

        // Otherwise, access the property directly
        return acc && acc[part];
      }, obj)
    : '';
}
// Function to get value from an object based on dot notation string
function getValueFromPath(obj, path) {
  // console.log('getValueFromPath : ', path);
  const pathToArray = path.split('.');
  // const finalData = pathToArray.length > 1 ? pathToArray.reduce((acc, part) => acc && acc[part], obj) : obj[path];
  const finalData = pathToArray.length > 1 ? getValueFromArrayPath(obj, pathToArray) : obj[path];
  // if (path === 'softwareVersions')
  // console.log(pathToArray, ' finalData : ', finalData, ' | Array.isArray(finalData) : ', Array.isArray(finalData));
  return Array.isArray(finalData) ? (typeof data === 'object' ? new Set(finalData)?.join(', ') : finalData?.join(', ')) : finalData;
}*/

const isValidImage = (url) => {
  return url && (url.startsWith('http') || url.startsWith('https'));
};

const getAvatarContent = (imageURL, row) => {
  // Check if imageURL is valid, if not fallback to initials
  if (isValidImage(imageURL)) {
    return <Avatar src={imageURL} sx={{ border: '1px solid black', backgroundColor: '#f8fafc !important' }} />;
  } else {
    const initials = row ? `${row.firstName.charAt(0)}${row.lastName.charAt(0)}` : 'NA';
    return <Avatar sx={{ fontWeight: 'bold', border: '1px solid black', backgroundColor: '#f8fafc !important' }}>{initials}</Avatar>;
  }
};

const TableRowStateChange = ({ dynamicValue, header, stateChangeTbl, row }) => {
  const { mutate: updatedStatus, isPending: isLoadingStatus } = useEmployeeNewStatusUpdate(stateChangeTbl);
  // console.log('TableRowStateChange ~ dynamicValue : ', dynamicValue, header);
  return (
    <TableCell align="center">
      {header.changeState === true ? (
        <TooltipStatus
          title={`Are you sure you want to ${!dynamicValue ? 'activate' : 'deactivate'} the ${header?.label || ''}?`}
          label={dynamicValue ? 'Active' : 'Inactive'}
          backgroundColor={dynamicValue ? '#6fd74b' : '#f44336'}
          onStatusUpdate={() => {
            updatedStatus({
              id: row?.id,
              status: !dynamicValue
            });
          }}
          isLoading={isLoadingStatus}
        />
      ) : (
        <Chip
          label={dynamicValue ? 'Active' : 'Inactive'}
          //color={row?.enabled ? 'success' : 'error'}
          style={{
            color: '#fff',
            backgroundColor: dynamicValue === true ? '#6fd74b' : '#f44336'
          }}
          size="small"
        />
      )}
    </TableCell>
  );
};
function CollapsibleRow({ row, headersObj, setOpenViewModal, openViewModal, permissionsList, stateChangeTbl }) {
  // const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  // const userData = JSON.parse(localStorage.getItem('userData'));

  let dynamicValue = '';
  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={row?.id}>
      {headersObj &&
        headersObj.length > 0 &&
        headersObj?.map((header, index) => {
          dynamicValue = getValueFromPath(row, header.id);
          // console.log('Employee ~ dyanmicHeader ID : Index :', index, header.label, header.id, ' dynamicValue : ', dynamicValue);
          return (
            <React.Fragment key={header.id}>
              {header.id === 'actionUpdate' ? (
                <TableCell align="center">
                  {permissionsList?.showEdit && (
                    <>
                      <HtmlTooltip title={'Edit' + ' ' + getEmployeeFullName(row)}>
                        <IconButton onClick={() => navigate(`/dashboard/employees/edit-${row?.id}`, { state: permissionsList })}>
                          <ModeEditOutlinedIcon />
                        </IconButton>
                      </HtmlTooltip>
                    </>
                  )}

                  {permissionsList?.showView && (
                    <>
                      <HtmlTooltip title={'View' + ' ' + getEmployeeFullName(row)}>
                        <IconButton onClick={() => setOpenViewModal((state) => ({ ...state, open: !state.open, empId: row?.id }))}>
                          <VisibilityIcon />
                        </IconButton>
                      </HtmlTooltip>
                    </>
                  )}
                </TableCell>
              ) : header?.id === 'isConfirmed' ? (
                <>
                  <TableCell align="center">
                    {' '}
                    <Chip
                      label={dynamicValue ? 'Verified' : 'Unverified'}
                      style={{
                        color: '#fff',
                        backgroundColor: dynamicValue === true ? '#006747' : '#FCA311'
                      }}
                      size="small"
                    />
                  </TableCell>
                </>
              ) : header.changeState ? (
                <TableRowStateChange row={row} stateChangeTbl={stateChangeTbl} dynamicValue={dynamicValue} header={header} />
              ) : header.id === 'createdAt' ? (
                <TableCell align="center">
                  <Tooltip title={moment(row?.createdAt).format('Do MMMM YYYY')} disableInteractive>
                    <span>{moment(row?.createdAt).format('Do MMMM YYYY')}</span>
                  </Tooltip>
                </TableCell>
              ) : header.id === 'image.url' ? (
                <>
                  <TableCell align="center">
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Tooltip title={row && row.firstName && row.lastName ? `${row?.firstName} ${row?.lastName}` : header.label}>
                        {/* <CustomTableCellWithLightbox imageURL={dynamicValue} row={row} /> */}
                        {getAvatarContent(dynamicValue, row)}
                      </Tooltip>
                    </div>
                  </TableCell>
                </>
              ) : header.id === 'hasGlobalAccess' ? (
                <>
                  <TableCell align="center">
                    <Chip
                      label={dynamicValue ? 'Yes' : 'No'}
                      //color={row?.enabled ? 'success' : 'error'}
                      style={{
                        color: '#fff',
                        backgroundColor: dynamicValue === true ? '#6fd74b' : '#f44336'
                      }}
                      size="small"
                    />
                  </TableCell>
                </>
              ) : (
                <TableCell align="center">
                  <Tooltip title={dynamicValue || ''} disableInteractive>
                    <span>{truncatedText(dynamicValue) || 'N.A.'}</span>
                  </Tooltip>
                </TableCell>
              )}
            </React.Fragment>
          );
        })}
    </TableRow>
  );
}

/*CollapsibleRow.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    phone: PropTypes.number,
    address: PropTypes.shape({
      details: PropTypes.string
    }),
    contact: PropTypes.shape({
      primary: PropTypes.shape({
        employeePhone: PropTypes.number,
        employeeName: PropTypes.string,
        employeeEmail: PropTypes.string
      }),
      secondary: PropTypes.shape({
        employeePhone: PropTypes.string,
        employeeName: PropTypes.string,
        employeeEmail: PropTypes.string
      })
    })
  })
};*/

const EmployeeList = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('createdAt');
  const [stateChangeTbl, setStateChangeTbl] = useState('');
  const [isLoading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(0);
  // const [firstPageVal, setFirstPageVal] = useState('');
  // const [lastPageVal, setLastPageVal] = useState('');
  // const userData = JSON.parse(localStorage.getItem('userData'));
  const [openFilterModal, setOpenFilterModal] = useState(true);
  const [isOpenOnPageFilter, setIsOpenOnPageFilter] = useState(false);
  const [openViewModal, setOpenViewModal] = useState({ open: false, empId: null });
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const viewEmpID = queryParams?.get('viewEmpID');

  const [filterObj, setFilterObj] = useState({
    name: '',
    status: '',
    showAdmin: '',
    email: '',
    title: '',
    installerAuthPin: '',
    installerRfid: '',
    authPin: '',
    rfid: ''
  });
  // console.log('Employee ~ filterObj : ', filterObj);
  // const [openFilterModal, setOpenFilterModal] = useState(true);
  const { data: allOrgList, isLoading: isAllOrgDataLoading } = useGetEmployeeNewList({
    limit: rowsPerPage,
    sortOrder: order,
    sortField: orderBy,
    page: currentPage ? currentPage : previousPage,
    name: filterObj.name || '',
    status: filterObj.status || '',
    onlyAdmin: filterObj.showAdmin || '',
    email: filterObj.email || '',
    title: filterObj.title || '',
    installerRfidPin: filterObj.installerAuthPin || '',
    installerRfid: filterObj.installerRfid || '',
    rfidPin: filterObj.authPin || '',
    rfid: filterObj.rfid || ''
  });
  // console.log('Employee ~ allOrgList_1 ', allOrgList);

  const organizationType = useSelector((state) => state.customization.orgType);
  const usersType = useSelector((state) => state.customization.userType);
  const { data: permissionsList, isLoading: isLoadingPermissionsList } = useGetPermissionsEmployeeList({
    organizationType: organizationType !== 'Super Admin' ? organizationType.replaceAll(' ', '') : 'SuperAdmin',
    usersType: usersType === 'Main POC' ? 'POC' : usersType.replaceAll(' ', '')
  });

  // console.log('permissionsList Employee : ', permissionsList);

  const [headers, setHeaders] = useState([]);
  useEffect(() => {
    if (permissionsList) {
      let tableHeaders = permissionsList?.view?.tableHeaders;
      const actionObj = {
        id: 'actionUpdate',
        label: 'Action'
      };
      if (tableHeaders && Array.isArray(tableHeaders) && tableHeaders.length) setHeaders([...tableHeaders, actionObj]);
      // console.log('Employee Table Headers ', [...tableHeaders, actionObj], ' | API : ', tableHeaders);
    }
  }, [permissionsList]);

  useEffect(() => {
    // Find the first headCell where sortField is true and set the orderBy
    if (headers) {
      const firstSortableHeadCell = headers.find((headCell) => headCell.sortField);
      const firstStateChangeableHeadCell = headers.find((headCell) => headCell.changeState);
      if (firstSortableHeadCell) {
        setOrderBy(firstSortableHeadCell.id || 'createdAt');
      } //setStateChangeTbl
      if (firstStateChangeableHeadCell) {
        setStateChangeTbl(firstStateChangeableHeadCell.id);
      }
    }
  }, [headers, setOrderBy, setStateChangeTbl]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // setFirstPageVal(allOrgList?.data?.first);
    // setLastPageVal(allOrgList?.data?.last);
    setCurrentPage(newPage + 1);
    setPreviousPage(currentPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      {isLoading ? (
        <>
          <SkeletonTableView />
        </>
      ) : (
        <>
          <BreadcrumbsForPage name="All Employees" obj={{ title: 'All Employees', title2: '', url: '' }} />

          <MainCard
            title=""
            isButton={permissionsList?.showAdd ? true : false} // Show/Hide Add Button
            isEnable={permissionsList?.showAdd ? true : false} // Show/Hide Add Icon
            url="/dashboard/employees/add" // Add Url
            FilterComponents={EmployeeFilter} // Filter Components
            isFilter={
              permissionsList === null
                ? false
                : permissionsList?.view?.filters === undefined
                ? true
                : permissionsList?.view?.filters?.length > 0
                ? true
                : false
            } // Show/Hide Filter Icon
            openFilterAllModal={openFilterModal}
            setOpenFilterModal={setOpenFilterModal}
            setFilterObj={setFilterObj}
            // isEmployeeFilter={false}
            isOnPageFilter={
              permissionsList === null
                ? false
                : permissionsList?.view?.filters === undefined
                ? true
                : permissionsList?.view?.filters?.length > 0
                ? true
                : false
            } // Show/Hide On Page Filter
            isOpenOnPageFilter={isOpenOnPageFilter}
            setIsOpenOnPageFilter={setIsOpenOnPageFilter}
            permissionsList={permissionsList}
            isLoadingPermissionsList={isLoadingPermissionsList}
            filterObj={filterObj}
          >
            <TableContainer>
              {isAllOrgDataLoading ? (
                <TableBody>
                  {[1, 2, 3, 4].map((i) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                      <TableCell align="center">
                        {' '}
                        <Skeleton variant="text" width={200} />
                      </TableCell>
                      <TableCell align="center">
                        {' '}
                        <Skeleton variant="text" width={200} />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton variant="text" width={200} />
                      </TableCell>
                      <TableCell align="center">
                        {' '}
                        <Skeleton variant="text" width={200} />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton variant="text" width={200} />
                      </TableCell>{' '}
                      <TableCell align="center">
                        <Skeleton variant="text" width={200} />
                      </TableCell>{' '}
                      <TableCell align="center">
                        <Skeleton variant="text" width={200} />
                      </TableCell>
                      <TableCell align="center">
                        <IconButton>
                          <Skeleton variant="circular" width={40} height={40} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : allOrgList?.data?.employees === undefined || allOrgList?.data?.employees.length == 0 ? (
                <StyledGridOverlay>
                  <svg style={{ flexShrink: 0 }} width="240" height="200" viewBox="0 0 184 152" aria-hidden focusable="false">
                    <g fill="none" fillRule="evenodd">
                      <g transform="translate(24 31.67)">
                        <ellipse className="ant-empty-img-5" cx="67.797" cy="106.89" rx="67.797" ry="12.668" />
                        <path
                          className="ant-empty-img-1"
                          d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                        />
                        <path
                          className="ant-empty-img-2"
                          d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                        />
                        <path
                          className="ant-empty-img-3"
                          d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                        />
                      </g>
                      <path
                        className="ant-empty-img-3"
                        d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                      />
                      <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                        <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                        <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                      </g>
                    </g>
                  </svg>
                  <Box sx={{ mt: 1 }}>No data found!</Box>
                </StyledGridOverlay>
              ) : (
                <Table stickyHeader aria-label="sticky table" size="small">
                  <EnhancedTableHead headersObj={headers} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
                  <TableBody>
                    {stableSort(allOrgList?.data?.employees, getComparator(order, orderBy))?.map((row, i) => {
                      return (
                        <CollapsibleRow
                          headersObj={headers}
                          row={row}
                          key={i}
                          setOpenViewModal={setOpenViewModal}
                          openViewModal={openViewModal}
                          permissionsList={permissionsList}
                          stateChangeTbl={stateChangeTbl}
                        />
                      );
                    })}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={allOrgList?.data?.rowPerPage}
              component="div"
              // rowsPerPageOptions={[20, 50, 100]}

              count={allOrgList?.data?.totalCount ? allOrgList?.data?.totalCount : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              // getItemAriaLabel={getItemAriaLabel}
            />
          </MainCard>
          {/* {openFilterModal && (
            <OrganizationFilter
              open={openFilterModal}
              handleCloseFilter={() => {
                setOpenFilterModal(false);
              }}
              setFilterObj={setFilterObj}
              filterObj={filterObj}
            />
          )} */}

          {openViewModal && openViewModal?.open && <EmployeeModalDialog open={openViewModal} setOpen={setOpenViewModal} />}
        </>
      )}
    </>
  );
};
EnhancedTableHead.propTypes = {
  order: PropTypes.string,
  orderBy: PropTypes.string,
  onRequestSort: PropTypes.any
};
export default EmployeeList;
