// material-ui
/* eslint-disable no-unused-vars */
import { useTheme, styled } from '@mui/material/styles';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Skeleton,
  Stack,
  Typography
} from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
// assets
import { IconBrandTelegram, IconBuildingStore, IconMailbox, IconPhoto } from '@tabler/icons';
import User1 from 'assets/images/users/user-round.svg';
import { useGetNotificationList } from 'hooks/useNotificationHooks';
import { useState } from 'react';
import { useEffect } from 'react';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  padding: 16,
  '&:hover': {
    background: theme.palette.primary.light
  },
  '& .MuiListItem-root': {
    padding: 0
  }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //
const HtmlTooltip = styled(({ className, ...props }) => <Tooltip enterTouchDelay={0} {...props} classes={{ popper: className }} arrow />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9'
    }
  })
);
const NotificationList = ({ notificationData, isAllNotificationLoader }) => {
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  const theme = useTheme();

  const chipSX = {
    height: 24,
    padding: '0 6px'
  };
  const chipErrorSX = {
    ...chipSX,
    color: theme.palette.orange.dark,
    backgroundColor: theme.palette.orange.light,
    marginRight: '5px'
  };

  const chipWarningSX = {
    ...chipSX,
    color: theme.palette.warning.dark,
    backgroundColor: theme.palette.warning.light
  };

  const chipSuccessSX = {
    ...chipSX,
    color: theme.palette.success.dark,
    backgroundColor: theme.palette.success.light,
    height: 28
  };

  return (
    <List
      sx={{
        width: '100%',
        // maxWidth: 330,
        py: 0,
        borderRadius: '10px',
        [theme.breakpoints.down('md')]: {
          // maxWidth: 300
        },
        '& .MuiListItemSecondaryAction-root': {
          top: 22
        },
        '& .MuiDivider-root': {
          my: 0
        },
        '& .list-container': {
          pl: 7
        }
      }}
    >
      {isAllNotificationLoader ? (
        <>
          <ListItemWrapper>
            <ListItem alignItems="center">
              <ListItemAvatar>
                <Skeleton variant="circular" width={40} height={40} />
              </ListItemAvatar>
              <ListItemText
                primary={<Skeleton variant="text" width={180} height={24} />}
                secondary={<Skeleton variant="text" width={100} height={18} />}
              />

              <ListItemSecondaryAction>
                <Grid container justifyContent="flex-end">
                  <Grid item xs={12}>
                    <Typography variant="caption" display="block" gutterBottom>
                      <FiberManualRecordIcon
                        sx={{
                          fontSize: 15,
                          color: '#d3d3d3',
                          animation: 'blink 1s infinite',
                          '@keyframes blink': {
                            '0%': { opacity: 1 },
                            '50%': { opacity: 0 },
                            '100%': { opacity: 1 }
                          }
                        }}
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </ListItemSecondaryAction>
            </ListItem>
            <Grid container direction="column" className="list-container">
              <Grid item xs={12}>
                <Stack spacing={1} sx={{ alignItems: 'left' }}>
                  <Stack direction="row" spacing={1}>
                    <Grid item xs={12} sm={12} md={3} lg={3} className="select-job-enterprise select-job-enterprise-type">
                      <Skeleton variant="rounded" width={100} height={20} />
                    </Grid>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
            {/* <Divider /> */}
          </ListItemWrapper>
        </>
      ) : (
        <>
          {notificationData?.notifications?.map((item) => (
            <>
              <ListItemWrapper key={item.id}>
                <ListItem alignItems="center">
                  <ListItemAvatar>
                    <Avatar alt="Notification Image" src={item?.image} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <HtmlTooltip title={item?.title}>
                        <Typography variant="subtitle1">
                          {item?.title?.length > 40 ? `${item.title.slice(0, 40)}...` : item?.title}
                        </Typography>
                      </HtmlTooltip>
                    }
                    secondary={
                      <Typography variant="caption" display="block">
                        {item?.timeAgo}
                      </Typography>
                    }
                  />

                  <ListItemSecondaryAction>
                    <Grid container justifyContent="flex-end">
                      <Grid item xs={12}>
                        <Typography variant="caption" display="block" gutterBottom>
                          {item?.unread && (
                            <FiberManualRecordIcon
                              sx={{
                                fontSize: 15,
                                color: '#6fd74b',
                                marginTop: '40px',
                                animation: 'blink 1s infinite',
                                '@keyframes blink': {
                                  '0%': { opacity: 1 },
                                  '50%': { opacity: 0 },
                                  '100%': { opacity: 1 }
                                }
                              }}
                            />
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </ListItemSecondaryAction>
                </ListItem>

                <Grid
                  container
                  direction="column"
                  className="list-container"
                  style={{
                    marginBottom: '10px'
                  }}
                >
                  <Grid item xs={12}>
                    <Stack spacing={1} sx={{ alignItems: 'left' }}>
                      <Stack direction="row" spacing={1}>
                        <Chip
                          label={item?.tag}
                          sx={{
                            backgroundColor: item?.tagColor?.bgColor,
                            color: item?.tagColor?.primaryColor,
                            borderColor: item?.tagColor?.primaryColor,
                            borderWidth: '1px'
                          }}
                        />
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
                <Divider />
              </ListItemWrapper>
            </>
          ))}
        </>
      )}

      {/* {isAllNotificationList ? (
        <>
          {' '}
          <ListItemWrapper>
            <ListItem alignItems="center">
              <ListItemAvatar>
                <Skeleton variant="circular" width={40} height={40} />
              </ListItemAvatar>
              <ListItemText
                primary={
                
                  <Skeleton variant="text" width={180} height={24} />
                }
                secondary={<Skeleton variant="text" width={100} height={18} />}
              />

              <ListItemSecondaryAction>
                <Grid container justifyContent="flex-end">
                  <Grid item xs={12}>
                    <Typography variant="caption" display="block" gutterBottom>
                      <FiberManualRecordIcon
                        sx={{
                          fontSize: 15,
                          color: '#d3d3d3',
                          animation: 'blink 1s infinite',
                          '@keyframes blink': {
                            '0%': { opacity: 1 },
                            '50%': { opacity: 0 },
                            '100%': { opacity: 1 }
                          }
                        }}
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </ListItemSecondaryAction>
            </ListItem>
            <Grid container direction="column" className="list-container">
              <Grid item xs={12}>
                <Stack spacing={1} sx={{ alignItems: 'left' }}>
                  <Stack direction="row" spacing={1}>
                    <Grid item xs={12} sm={12} md={3} lg={3} className="select-job-enterprise select-job-enterprise-type">
                      <Skeleton variant="rounded" width={100} height={20} />
                    </Grid>
                   
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
            <Divider />
          </ListItemWrapper>
        </>
      ) : (
        <>
          {allNotificationList?.pages?.map((page) =>
            page?.data?.notifications?.map((item) => (
              <ListItemWrapper key={item.id}>
                <ListItem alignItems="center">
                  <ListItemAvatar>
                    <Avatar alt="Notification Image" src={item?.image} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <HtmlTooltip title={item?.title}>
                        <Typography variant="subtitle1">
                          {item?.title?.length > 28 ? `${item.title.slice(0, 28)}...` : item?.title}
                        </Typography>
                      </HtmlTooltip>
                    }
                    secondary={
                      <Typography variant="caption" display="block">
                        {item?.timeAgo}
                      </Typography>
                    }
                  />

                  <ListItemSecondaryAction>
                    <Grid container justifyContent="flex-end">
                      <Grid item xs={12}>
                        <Typography variant="caption" display="block" gutterBottom>
                          {item?.unread && (
                            <FiberManualRecordIcon
                              sx={{
                                fontSize: 15,
                                color: '#6fd74b',
                                animation: 'blink 1s infinite',
                                '@keyframes blink': {
                                  '0%': { opacity: 1 },
                                  '50%': { opacity: 0 },
                                  '100%': { opacity: 1 }
                                }
                              }}
                            />
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </ListItemSecondaryAction>
                </ListItem>
              
                <Grid
                  container
                  direction="column"
                  className="list-container"
                  style={{
                    marginBottom: '10px'
                  }}
                >
                  <Grid item xs={12}>
                    <Stack spacing={1} sx={{ alignItems: 'left' }}>
                      <Stack direction="row" spacing={1}>
                        <Chip
                          label={item?.tag}
                         
                          sx={{
                            backgroundColor: item?.tagColor?.bgColor,
                            color: item?.tagColor?.primaryColor,
                            borderColor: item?.tagColor?.primaryColor,
                            borderWidth: '1px'
                          }}
                        />
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
                <Divider />
              </ListItemWrapper>
            ))
          )}
        </>
      )} */}
    </List>
  );
};

export default NotificationList;
