/* eslint-disable */
import * as React from 'react';
// import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Box,
  Button,
  DialogContent,
  DialogContentText,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Typography,
  useTheme
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// import { gridSpacing } from 'store/constant';
import { useNavigate } from 'react-router';
import { useGetMachineById } from 'hooks/useMachineHooks';
import styled from '@emotion/styled';
import { padding } from '@mui/system';
import { Form } from 'formik';
import { useGetAllOrganizationList, useGetOrgById } from 'hooks/useOrganizationHooks';
import AnimateButton from 'ui-component/extended/AnimateButton';

export default function MachineLeaseDialogForm({
  open,
  setOpen,
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  touched,
  values,
  setFieldValue,
  btnName
}) {
  // const [open, setOpen] = React.useState(false);
  /*
  const handleClickOpen = () => {
    setOpen(true);
  };*/
  const theme = useTheme();
  // const { data: machineDetails, isLoading: isLoadingMachine } = useGetMachineById({
  //   id: open.machineId
  // });
  const isLoadingMachine = false; // For Demo purposes

  //   console.log('machineDetails - MachineModalDialog - Machine Lease : ', machineDetails, open);

  const { data: orgList, isLoading: orgListIsLoading } = useGetAllOrganizationList();
  const { data: orgListById, isLoading: orgListByIdIsLoading } = useGetOrgById({ id: values.organization });

  const handleClose = () => {
    setOpen((state) => !state);
  };
  const navigate = useNavigate();

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.h4,
    // textAlign: 'center',
    // color: theme.palette.text.secondary,
    // height: 60,
    // lineHeight: '60px',
    padding: '10px'
  }));

  const handleChangeLocation = (event) => {
    const [value, tax] = event.target.value.split('@##@');
    // console.log('event.target.value.split()', event.target.value.split('@##@'));
    setFieldValue('location', value);
    setFieldValue('location_tax', tax);
  };

  const handleChangeOrg = (event) => {
    setFieldValue('organization', event.target.value);
    setFieldValue('location', '');
    setFieldValue('location_tax', '');
  };

  const locationsArray =
    orgListById && orgListById?.data && orgListById?.data?.locations && orgListById?.data?.locations?.length > 0
      ? orgListById?.data?.locations?.map((location) => {
          return {
            id: location?.details?.id || '',
            name: location?.details?.name || '',
            taxAmount: location?.details?.taxAmount || '',
            isPrimary: location?.isPrimary || ''
          };
        })
      : [];

  // console.log('locationsArray', locationsArray);

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'md'} // Set this to "xs" to make the dialog smaller
        onClose={handleClose}
        open={open}
      >
        <DialogTitle
          id="alert-dialog-title"
          variant="span"
          scroll={'papper'}
          style={{
            textTransform: 'capitalize',
            fontSize: '20px' // Adjust the font size for the title
          }}
        >
          Machine Lease
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500]
          })}
        >
          <CloseIcon color="error" />
        </IconButton>
        <Divider />
        <DialogContent
          dividers
          className="choose_option_modal"
          style={{
            padding: '8px 16px', // Reduce padding to make the dialog more compact
            boxShadow: 'unset',
            border: 'unset'
          }}
        >
          {/* style={{ display: 'flex', alignItems: 'center' }} */}
          {/* style={{ fontWeight: 'bold' }} */}
          {isLoadingMachine ? (
            <>
              <p>Loading....</p>
            </>
          ) : (
            <>
              <Form noValidate onSubmit={handleSubmit}>
                <Grid container spacing={2.5} style={{ paddingTop: '30px' }}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.organization && errors.organization)}
                      sx={{ ...theme.typography.customInput }}
                    >
                      <InputLabel id="demo-simple-select-label_organization">Select Organization *</InputLabel>
                      <Select
                        labelId="demo-simple-select-label_organization"
                        id="demo-simple-select"
                        value={values?.organization}
                        name="organization"
                        label="Select Organization"
                        // onChange={handleChange}
                        onChange={handleChangeOrg}
                      >
                        {!!orgList?.data &&
                          orgList?.data?.length > 0 &&
                          orgList?.data?.map((val, index) => (
                            <MenuItem value={val?.id} key={index}>
                              {val?.name}
                            </MenuItem>
                          ))}
                      </Select>

                      {touched.organization && errors.organization && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-first-name"
                          sx={{
                            fontSize: '13.8px'
                          }}
                        >
                          {errors.organization}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <FormControl fullWidth error={Boolean(touched.location && errors.location)} sx={{ ...theme.typography.customInput }}>
                      <InputLabel id="demo-simple-select-label_location">Select Location *</InputLabel>
                      <Select
                        labelId="demo-simple-select-label_location"
                        id="demo-simple-select"
                        value={`${values?.location}@##@${values?.location_tax}`}
                        name="location"
                        label="Location"
                        // onChange={handleChange}
                        onChange={handleChangeLocation}
                      >
                        {/* <MenuItem value={`Loc 1@##@10`}>Loc 1 (Tax - 10%)</MenuItem>
                      <MenuItem value={`Loc 2@##@20`}>Loc 2 (Tax - 20%)</MenuItem>
                      <MenuItem value={`Loc 3@##@30`}>Loc 3 (Tax - 30%)</MenuItem> */}
                        {/* locationsArray */}
                        {!!locationsArray &&
                          locationsArray?.length > 0 &&
                          locationsArray?.map((val, index) => (
                            <MenuItem value={`${val?.id}@##@${val?.taxAmount}`} key={index}>
                              {val?.name} (Tax : {val?.taxAmount}%)
                            </MenuItem>
                          ))}
                      </Select>

                      {touched.location && errors.location ? (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-first-name"
                          sx={{
                            fontSize: '13.8px'
                          }}
                        >
                          {errors.location}
                        </FormHelperText>
                      ) : values?.location ? (
                        <>
                          {/* <Typography
                          sx={{
                            position: 'absolute',
                            bottom: { xs: -18, sm: -24 },
                            right: { xs: 4, sm: 5 },
                            fontSize: { xs: '14px', sm: '14px' },
                            color: 'gray'
                          }}
                        >
                          Tax - 10%
                        </Typography> */}
                        </>
                      ) : null}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    {errors.submit && (
                      <Box sx={{ mt: 3 }}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Box>
                    )}
                    <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                      <Box sx={{ display: 'inline-block' }}>
                        <AnimateButton>
                          <Button
                            disableElevation
                            disabled={isLoadingMachine}
                            size="large"
                            type="submit"
                            variant="contained"
                            color="secondary"
                            style={{
                              color: 'white',
                              minWidth: '200px',
                              margin: '0px auto'
                            }}
                          >
                            {isLoadingMachine ? (
                              <CircularProgress
                                sx={{
                                  color: 'white',
                                  height: 20,
                                  width: 20
                                }}
                              />
                            ) : (
                              `${btnName} Machine Lease`
                            )}
                          </Button>
                        </AnimateButton>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
          {/* display: 'flex', alignItems: 'center', */}
          {/* style={{ textAlign: 'center' }} */}
          <Grid container spacing={2} sx={{ width: '50%' }} style={{ paddingTop: '50px' }}>
            {/* <Grid item xs={12} sm={4} md={4} lg={4}>
              <Button
                disableElevation
                size="large"
                type="button"
                variant="contained"
                color="secondary"
                onClick={() => {
                  navigate(urlAddBatch);
                }}
                style={{ padding: '6px 12px', fontSize: '14px', color: 'white', backgroundColor: 'rgb(111, 186, 85)' }} // Adjust button padding and font size
              >
                Submit
              </Button>
            </Grid> */}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
