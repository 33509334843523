import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  // DialogContentText,
  // DialogContentText,
  DialogTitle,
  // FormControl,
  // FormHelperText,
  Grid,
  IconButton,
  // InputLabel,
  Skeleton,
  // TextField,
  Typography
  // useTheme
} from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { gridSpacing } from 'store/constant';
import { useGetOrganizationAssignedMachines } from 'hooks/useOrganizationNewHooks';
import ViewTicketSkeleton from 'ui-component/cards/Skeleton/ViewTicketSkeleton';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { useState } from 'react';

import DiscontinueService from './DiscontinueService';
const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  margin: '0 auto',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626'
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959'
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343'
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c'
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff'
  }
}));
const AssignedMachineDialog = ({ openmodal, setOpenModal, isDeleteLoading, details, refetchDetails }) => {
  details = {
    ...details,
    id: details?.id
  };
  const { data: stopservicing, isLoading: isLoadingStopServicing } = useGetOrganizationAssignedMachines({
    id: details?.id
  });

  const handleClose = () => {
    setOpenModal(false);
  };
  const userData = JSON.parse(localStorage.getItem('userData'));

  const orgType = useSelector((state) => state.customization.orgType);

  const [openNotesModal, setOpenNotesModal] = useState(false);

  return (
    <>
      <Dialog
        open={openmodal}
        fullScreen={false}
        fullWidth={false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        //   className="dlte_file_mdlnw machine-settings"
      >
        <DialogTitle
          id="alert-dialog-title"
          variant="span"
          style={{
            textTransform: 'capitalize'
          }}
        >
          {isLoadingStopServicing ? <Skeleton variant="text" width={200} /> : <>Confirmation!</>}

          {isLoadingStopServicing ? (
            <>
              <Skeleton
                variant="circular"
                width={20}
                height={20}
                sx={(theme) => ({
                  position: 'absolute',
                  right: 10,
                  top: 15,
                  color: theme.palette.grey[500]
                })}
              />
            </>
          ) : (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={(theme) => ({
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme.palette.grey[500]
              })}
            >
              <CloseIcon color="error" />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent dividers>
          {isLoadingStopServicing ? (
            <ViewTicketSkeleton />
          ) : (
            <>
              <Grid container spacing={gridSpacing}>
                <Grid item xs={12} className="support_ticket_view_new">
                  <Typography component="p" variant="h5" sx={{ color: '#000' }}>
                    {`You will no longer provide services as ${orgType} to the machines listed below from ${userData?.organization?.name}. Please specify a reason for discontinuation and select "Yes" to proceed.`}
                  </Typography>

                  <AssignedMachineSection title="Assigned Machines" assignedMachines={stopservicing || []} />
                  {/* <Grid item lg={12} sm={12} md={12}>
                  <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginTop: '10px' }}>
                    <div style={{ background: '#6fd74b', color: '#fff', padding: '10px', fontSize: '16px' }}>
                      <Typography
                        component="p"
                        variant="h4"
                        sx={{ fontWeight: '600', marginRight: '4px', minWidth: '200px', color: '#fff' }}
                      >
                        Notes
                      </Typography>
                    </div>

                    <Formik
                      initialValues={{ notes: '' }}
                      validationSchema={validationSchema}
                      onSubmit={(values) => {
                        const submitData = {
                          notes: values?.notes.trim()
                        };
                        console.log('🚀 ~ AssignedMachineDialog ~ submitData:', submitData);
                      }}
                    >
                      {({ errors, touched, handleChange, values, handleSubmit, handleBlur }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                          <FormControl fullWidth error={Boolean(touched.reason && errors.reason)} sx={{ ...theme.typography.customInput }}>
                            <TextField
                              variant="outlined"
                              type="text"
                              fullWidth
                              name="notes"
                              value={values?.notes}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={Boolean(touched.notes && errors.notes)}
                              multiline={2}
                            />
                            {touched.notes && errors.notes && (
                              <FormHelperText
                                error
                                id="standard-weight-helper-text-email-login"
                                sx={{
                                  fontSize: '13.8px'
                                }}
                              >
                                {errors.notes}
                              </FormHelperText>
                            )}
                          </FormControl>
                          <DialogActions>
                            <Box sx={{ m: 1, position: 'relative' }}>
                              <Button
                                fullWidth
                                variant="contained"
                                type="submit"
                                style={{ color: '#fff' }}
                                color="primary"
                                disabled={isDeleteLoading}
                              >
                                Yes
                                {isDeleteLoading && (
                                  <CircularProgress
                                    size={24}
                                    sx={{
                                      color: '#16B1FF',
                                      position: 'absolute',
                                      top: '50%',
                                      left: '50%',
                                      marginTop: '-12px',
                                      marginLeft: '-12px'
                                    }}
                                  />
                                )}
                              </Button>
                            </Box>
                            <Button variant="outlined" type="button" color="error" onClick={handleClose}>
                              Cancel
                            </Button>
                          </DialogActions>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </Grid> */}
                </Grid>
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Box sx={{ m: 1, position: 'relative' }}>
            <Button
              fullWidth
              variant="contained"
              type="submit"
              onClick={() => {
                setOpenNotesModal(!openNotesModal);
                setOpenModal(false);
              }}
              style={{
                color: '#fff'
              }}
              color="primary"
              disabled={isDeleteLoading}
            >
              Yes
              {isDeleteLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: '#16B1FF',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px'
                  }}
                />
              )}
            </Button>
          </Box>
          <Button variant="outlined" type="reset" color="error" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {openNotesModal && (
        <DiscontinueService open={openNotesModal} setOpen={setOpenNotesModal} serviceId={details?.id} refetchDetails={refetchDetails} />
      )}
    </>
  );
};

const AssignedMachineSection = ({ title, assignedMachines }) => (
  <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginTop: '15px' }}>
    <div style={{ background: '#6fd74b', padding: '10px 15px' }}>
      <Typography component="h4" variant="h3" sx={{ color: '#fff' }}>
        {title}
      </Typography>
    </div>

    <Grid container spacing={2} sx={{ padding: '10px 15px' }}>
      {assignedMachines?.length == 0 ? (
        <StyledGridOverlay>
          <svg style={{ flexShrink: 0 }} width="240" height="220" viewBox="0 0 184 152" aria-hidden focusable="false">
            <g fill="none" fillRule="evenodd">
              <g transform="translate(24 31.67)">
                <ellipse className="ant-empty-img-5" cx="67.797" cy="106.89" rx="67.797" ry="12.668" />
                <path
                  className="ant-empty-img-1"
                  d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                />
                <path
                  className="ant-empty-img-2"
                  d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                />
                <path
                  className="ant-empty-img-3"
                  d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                />
              </g>
              <path
                className="ant-empty-img-3"
                d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
              />
              <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
              </g>
            </g>
          </svg>
          <Box sx={{ mt: 1 }}>No data found!</Box>
        </StyledGridOverlay>
      ) : (
        assignedMachines?.map((item, index) => (
          <Grid item lg={12} sm={12} md={12} key={index}>
            <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginBottom: '10px' }}>
              <div style={{ background: '#6fd74b', color: '#fff', padding: '10px', fontSize: '16px' }}>
                <Typography component="p" variant="h4" sx={{ fontWeight: '600', marginRight: '4px', minWidth: '200px', color: '#fff' }}>
                  {item?.machineName}
                </Typography>
              </div>
              <div style={{ padding: '10px 15px' }}>
                {[
                  { label: 'Machine Name', value: item?.machineName || 'N.A' },
                  { label: 'Machine Location', value: item?.machineLoc || 'N.A' }
                ].map(({ label, value }, i) => (
                  <div
                    key={i}
                    style={{
                      marginBottom: '10px',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                    className="dflex_srvc_location"
                  >
                    <Typography component="p" variant="h4" sx={{ fontWeight: '600', marginRight: '4px', minWidth: '200px' }}>
                      {label}:
                    </Typography>
                    <span style={{ fontSize: '15px' }}>{value}</span>
                  </div>
                ))}
              </div>
            </div>
          </Grid>
        ))
      )}
    </Grid>
  </div>
);

export default AssignedMachineDialog;
