import React from 'react';
import { Skeleton, Grid, FormControl } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import { gridSpacing } from 'store/constant';

const FormBodyView = () => {
  const theme = useTheme();

  return (
    <>
      <Grid item xs={12}>
        <Grid container spacing={gridSpacing}>
          {[1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
            <Grid item lg={4} md={4} sm={6} xs={12} key={i}>
              <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                <Skeleton
                  variant="text"
                  sx={{
                    width: {
                      xs: '70%',
                      sm: '60%',
                      md: '50%'
                    },
                    height: 20
                  }}
                />
                <Skeleton
                  variant="rectangular"
                  sx={{
                    width: '100%',
                    height: {
                      xs: 40,
                      sm: 50,
                      md: 60
                    },
                    borderRadius: 1
                  }}
                />
              </FormControl>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default FormBodyView;
