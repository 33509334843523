import React from 'react';
import { Form, Formik } from 'formik';
// import * as Yup from 'yup';

import LocationFilterForm from './LocationFilterForm';

const LocationFilter = ({ setFilterObj, filterObj, isExistingFilter, permissionsList }) => {
  const locFilters = permissionsList?.view?.filters ?? [];

  const initials = {
    location_name: '',
    location_status: 'All'
  };

  // const validationSchema = Yup.object().shape({
  //   location_name: Yup.string().notRequired(),
  //   location_status: Yup.boolean().notRequired()
  // });

  return (
    <>
      <Formik
        initialValues={initials}
        // validationSchema={validationSchema}
        onSubmit={(value) => {
          // console.log('Location filter form submit value', value);

          setFilterObj({
            location_name: value?.location_name || null,
            location_status: value?.location_status === 'All' ? '' : value?.location_status
            // location_status: value?.location_status === 'All' ? '' : value?.location_status === 'true' ? true : false
          });

          // handleCloseFilter();
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, setTouched, resetForm }) => (
          <Form>
            <LocationFilterForm
              errors={errors}
              handleBlur={handleBlur}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              touched={touched}
              values={values}
              setFieldValue={setFieldValue}
              setTouched={setTouched}
              resetForm={resetForm}
              filterObj={filterObj}
              setFilterObj={setFilterObj}
              isExistingFilter={isExistingFilter}
              permissionsList={permissionsList}
              locFilters={locFilters}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default LocationFilter;
