import React from 'react';

// import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import TicketReasonFilterForm from './TicketReasonFilterForm';
const TicketReason = ({ setFilterObj, filterObj, isExistingFilter }) => {
  return (
    <>
      <Formik
        initialValues={{
          ticketreason: '',
          ticketstatus: 'All'
        }}
        // validationSchema={Yup.object().shape({})}
        onSubmit={async (value) => {
          // console.log('dsd', value);

          setFilterObj({
            ticketreason: value?.ticketreason || null,
            ticketstatus: value?.ticketstatus === 'All' ? '' : value?.ticketstatus
          });

          // handleCloseFilter();
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, setTouched, resetForm }) => (
          <Form>
            <TicketReasonFilterForm
              errors={errors}
              handleBlur={handleBlur}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              touched={touched}
              values={values}
              setFieldValue={setFieldValue}
              setTouched={setTouched}
              resetForm={resetForm}
              filterObj={filterObj}
              // handleCloseFilter={handleCloseFilter}
              setFilterObj={setFilterObj}
              isExistingFilter={isExistingFilter}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default TicketReason;
