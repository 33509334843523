import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Divider, Typography } from '@mui/material';
import * as Yup from 'yup'; // Import Yup for validation
import { useSelector, useDispatch } from 'react-redux';
import { useGetPermissionData } from 'hooks/useControlPanelHooks';
import SearchPermisionForm from 'ui-component/search-permission-form';
import ModifyPermission from './modify-permission';
import ModifyPermissionSkeleton from 'ui-component/cards/Skeleton/ModifyPermissionSkeleton';
import InfoIcon from '@mui/icons-material/Info';

function UserPermissionControl({ routeList, userTypes, orgTypes }) {
  const { user, org, route } = useSelector((state) => state.controlPanelReducer); // Access Redux state
  const dispatch = useDispatch();

  const { data: permissionList, isLoading: isLoadingPermissionList } = useGetPermissionData({
    orgType: org ? org.replaceAll(' ', '') : org,
    userType: user ? user.replaceAll(' ', '') : user,
    screen: route
  });

  const validationSchema = Yup.object().shape({
    user: Yup.string().required('User Type is required'),
    org: Yup.string().required('Organization Type is required'),
    screen: Yup.string().required('Screen Type is required')
  });

  const handleFormSubmit = (values) => {
    dispatch({ type: 'SET_USER', payload: values?.user });
    dispatch({ type: 'SET_ORG', payload: values?.org });
    dispatch({ type: 'SET_ROUTE', payload: values?.screen });
  };

  return (
    <Fragment>
      <Formik
        initialValues={{
          user: user || '',
          org: org || '',
          screen: route || ''
        }}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {(args) => {
          const { values } = args; // Extract the values from Formik context

          return (
            <>
              <SearchPermisionForm
                {...args}
                showUserField={true}
                showOrgaizationField={true}
                showScreenField={true}
                routeList={routeList}
                orgTypes={orgTypes}
                userTypes={userTypes}
              />

              <Divider sx={{ my: 2 }} />

              {user !== values.user || org !== values.org || route !== values.screen ? (
                <Typography variant="body1" color="warring" display="flex" justifyContent="center" fontWeight="600" paddingTop="50px">
                  <InfoIcon color="primary" />{' '}
                  <span style={{ paddingTop: '4px', paddingLeft: '5px' }}>Please hit Search Button to View the Permission Data.</span>
                </Typography>
              ) : isLoadingPermissionList ? (
                <ModifyPermissionSkeleton />
              ) : (
                <ModifyPermission
                  addPermissionList={permissionList?.add || []}
                  editPermissionList={permissionList?.edit || []}
                  filtersPermissionList={permissionList?.view?.filters || []}
                  modalPermissionList={permissionList?.modal || []}
                  tableHeadersPermissionList={permissionList?.view?.tableHeaders || []}
                  showAdd={permissionList?.showAdd || false}
                  showEdit={permissionList?.showEdit || false}
                  showView={permissionList?.showView || false}
                  showModal={permissionList?.showModal || false}
                  showFilter={permissionList?.showFilter || false}
                  isLoadingPermissionList={isLoadingPermissionList}
                  orgType={org ? org.replaceAll(' ', '') : org}
                  userType={user ? user.replaceAll(' ', '') : user}
                  screen={route}
                  permissionList={permissionList}
                />
              )}
            </>
          );
        }}
      </Formik>
    </Fragment>
  );
}

// Prop Validation
UserPermissionControl.propTypes = {
  routeList: PropTypes.arrayOf(PropTypes.string),
  userTypes: PropTypes.arrayOf(PropTypes.string),
  orgTypes: PropTypes.arrayOf(PropTypes.string)
};

// Default Props
UserPermissionControl.defaultProps = {
  routeList: [],
  userTypes: [],
  orgTypes: []
};

export default UserPermissionControl;
