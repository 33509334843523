import React from 'react';
// import { Accordion, AccordionDetails, AccordionSummary, Dialog, DialogTitle, IconButton, Typography } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';

import { Form, Formik } from 'formik';
// import * as Yup from 'yup';

import OrganizationFilterForm from './OrganisationFilterForm';
const OrganizationFilter = ({ setFilterObj, filterObj, isExistingFilter, permissionsList }) => {
  const orgFilters = permissionsList?.view?.filters ?? [];
  // const orgFilters = [
  //   {
  //     id: 'orgName',
  //     label: 'Organization Name',
  //     type: 'TextField',
  //     isRequired: false,
  //     show: true,
  //     isStatic: true
  //   },
  //   {
  //     id: 'status',
  //     label: 'Status',
  //     type: 'SingleDropdown',
  //     isRequired: true,
  //     show: false,
  //     isStatic: true
  //   },
  //   {
  //     id: 'types',
  //     label: 'Organization Types',
  //     type: 'MultiDropdown',
  //     isRequired: true,
  //     show: true,
  //     isStatic: true
  //   }
  // ];

  const initials = {
    // status: '',
    status: 'All',
    orgName: '',
    types: [] // Empty array for MultiDropdown
  };

  // const validationSchema = Yup.object(
  //   orgFilters.reduce((schema, filter) => {
  //     if (filter?.show == true && filter.isStatic == false) {
  //       if (filter?.isRequired) {
  //         if (filter?.type === 'TextField' || filter?.type === 'SingleDropdown') {
  //           schema[filter.id] = Yup.string().required(`${filter.label} is required`);
  //         } else if (filter?.type === 'MultiDropdown') {
  //           schema[filter.id] = Yup.array().min(1, `At least one ${filter.label} must be selected`).required(`${filter.label} is required`);
  //         }
  //       }
  //     }
  //     return schema;
  //   }, {})
  // );

  return (
    <>
      <Formik
        initialValues={initials}
        // validationSchema={validationSchema}
        onSubmit={(value) => {
          // console.log('dsd value', value);

          setFilterObj({
            status: value?.status === 'All' ? '' : value?.status,
            // status: value?.status === 'All' ? '' : value?.status === 'true' ? true : false,
            orgName: value?.orgName || null,
            orgTypes: value?.types || null
          });

          // handleCloseFilter();
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, setTouched, resetForm }) => (
          <Form>
            <OrganizationFilterForm
              errors={errors}
              handleBlur={handleBlur}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              touched={touched}
              values={values}
              setFieldValue={setFieldValue}
              setTouched={setTouched}
              resetForm={resetForm}
              filterObj={filterObj}
              setFilterObj={setFilterObj}
              isExistingFilter={isExistingFilter}
              permissionsList={permissionsList}
              orgFilters={orgFilters}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default OrganizationFilter;
