import { Form, Formik } from 'formik';
import React from 'react';
import JobFilterForm from './JobFilterForm';
import * as Yup from 'yup';

import dayjs from 'dayjs';
const JobFilter = ({ setFilterObj, filterObj, isExistingFilter, permissionsList }) => {
  const jobFilters = permissionsList?.view?.filters ?? [];
  const validationSchema = Yup.object().shape(
    {
      startDate: Yup.string()
        // .nullable()
        .notRequired()
        .when(['endDate'], {
          is: (dep_1, dep_2) => dep_1 || dep_2,
          then: Yup.string().required('From Date is required'),
          otherwise: Yup.string().notRequired()
        }),

      endDate: Yup.string()
        // .nullable()
        .notRequired()
        .when(['startDate'], {
          is: (dep_1, dep_2) => dep_1 || dep_2,
          then: Yup.string().required('To Date is required'),
          otherwise: Yup.string().notRequired()
        })
    },
    ['endDate', 'startDate']
  );
  return (
    <>
      <Formik
        initialValues={{
          machineId: [],
          // orgId: '',
          name: '',
          referenceNo: '',
          startDate: '',
          endDate: '',
          organization: '',
          location: ''
          // dateRange: null
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          // console.log('🚀 ~ onSubmit={ ~ values:', values);
          // const formattedDueDate = values?.duedate ? dayjs(values?.duedate).format('YYYY-MM-DD') : '';
          setFilterObj({
            machineId: values?.machineId?.id || null,
            // orgId: values?.orgId?.id || null,
            name: values?.name,
            referenceNo: values?.referenceNo,
            startDate: values?.startDate ? dayjs(values?.startDate).format('DD/MM/YYYY') : '',
            endDate: values?.endDate ? dayjs(values?.endDate).format('DD/MM/YYYY') : '',
            organization: values?.organization?.id || '',
            location: values?.location?.id || ''
            // dateRange: formattedDueDate
          });
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, setTouched, resetForm }) => (
          <Form>
            <JobFilterForm
              errors={errors}
              handleBlur={handleBlur}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              touched={touched}
              values={values}
              setFieldValue={setFieldValue}
              setTouched={setTouched}
              resetForm={resetForm}
              filterObj={filterObj}
              setFilterObj={setFilterObj}
              isExistingFilter={isExistingFilter}
              permissionsList={permissionsList}
              jobFilters={jobFilters}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default JobFilter;
