import { Formik } from 'formik';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';

import BreadcrumbsForPage from 'ui-component/extended/BreadcrumbsForPage';
import TicketsForm from '../form';
import * as Yup from 'yup';
// import { setToast, toastConfig } from 'utils/commonUtil';
// import { useAddRetailerTicket } from 'hooks/useRetailerUserHooks';
import { useMutation } from '@tanstack/react-query';
// import { useAddTicket } from 'hooks/useTickets';
import { useMachineTicketAdd } from 'hooks/useMachineTicketHooks';
import { setToast, toastConfig } from 'utils/commonUtil';
import { useLocation, useNavigate } from 'react-router-dom';
import FormView from 'ui-component/cards/Skeleton/FormView';
const TicketAdd = () => {
  const [isLoading, setLoading] = useState(true);
  const { mutate: addMachineTicket, isPending: isLoadingMachineTicket } = useMutation({
    mutationFn: useMachineTicketAdd
  });
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const machineIdFromQuery = queryParams.get('machineId') || '';
  return (
    <>
      {isLoading ? (
        <FormView />
      ) : (
        <>
          <BreadcrumbsForPage
            name="Add Machine Ticket"
            obj={{ title: 'All Machine Tickets', title2: 'Add Machine Ticket', url: '/dashboard/tickets' }}
          />
          <MainCard>
            <Formik
              initialValues={{
                subject: '',

                reason: '',
                machine: machineIdFromQuery,
                notes: '',
                priority: '',
                files: [],
                submit: null
              }}
              validationSchema={Yup.object().shape({
                subject: Yup.string().required('Subject is required '),
                reason: Yup.string().required('Please select a Category '),
                machine: Yup.string().required('Please select a machine '),
                priority: Yup.string().required('Please select a priority ')
              })}
              onSubmit={async (value) => {
                const data = {
                  machine: value?.machine.trim(),
                  subject: value?.subject,
                  notes: value?.notes,
                  ticketCategory: value?.reason,
                  priority: value?.priority,
                  files: value?.files
                };

                addMachineTicket(data, {
                  onSuccess: (res) => {
                    if (res?.status == 200) {
                      toastConfig.type = 'success';
                      setToast(toastConfig, res?.data.message);
                      navigate('/dashboard/tickets');

                      resetForm({});
                    } else {
                      toastConfig.type = 'error';
                      setToast(toastConfig, error?.response?.data?.error);
                    }
                  },
                  onError: (error) => {
                    toastConfig.type = 'error';
                    setToast(toastConfig, error?.response?.data?.error);
                  }
                });
              }}
            >
              {({
                errors,
                setFieldValue,
                isSubmitting,
                handleBlur,
                handleSubmit,
                handleChange,
                touched,
                values,
                setValues,
                setTouched
              }) => (
                <TicketsForm
                  btnName="Add"
                  errors={errors}
                  setFieldValue={setFieldValue}
                  isSubmitting={isSubmitting}
                  handleBlur={handleBlur}
                  handleSubmit={handleSubmit}
                  handleChange={handleChange}
                  touched={touched}
                  values={values}
                  setValues={setValues}
                  setTouched={setTouched}
                  // ticketDetails={ticketDetails}
                  isLoadingMachineTicket={isLoadingMachineTicket}
                />
              )}
            </Formik>
          </MainCard>
        </>
      )}
    </>
  );
};

export default TicketAdd;
