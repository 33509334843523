/* eslint-disable */
import { Formik } from 'formik';
import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import EmployeeForm from '../form';
import BreadcrumbsForPage from 'ui-component/extended/BreadcrumbsForPage';
import * as Yup from 'yup';
import 'yup-phone';
import { useSelector } from 'react-redux';
import { Alert, AlertTitle } from '@mui/material';
import { Lock } from '@mui/icons-material';
import { useEmployeeCreate, useGetPermissionsEmployeeList, useGetSubcriptionsLimit } from 'hooks/useEmployeesHooks';
import { setToast, toastConfig } from 'utils/commonUtil';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import OrganizationFormView from 'ui-component/cards/Skeleton/OrganizationFormView';
import { useRef } from 'react';
const EmployeeAdd = () => {
  const navigator = useNavigate();
  const orgType = useSelector((state) => state.customization.orgType);
  const { mutate: addEmployee, isPending: isLoadingAddEmployee } = useEmployeeCreate();
  const { mutate: checkEmployeeAdd, isPending: isLoadingCheckAdd } = useGetSubcriptionsLimit();

  const organizationType = useSelector((state) => state.customization.orgType);
  const usersType = useSelector((state) => state.customization.userType);
  const { data: permissionsList, isLoading: isLoadingPermissionsList } = useGetPermissionsEmployeeList({
    organizationType: organizationType !== 'Super Admin' ? organizationType.replaceAll(' ', '') : 'SuperAdmin',
    usersType: usersType === 'Main POC' ? 'POC' : usersType.replaceAll(' ', '')
  });

  const [openDialog, setOpenDialog] = useState({ show: false, status: 0, isLocalUser: false, machineObj: null });

  const permissionsAdd = permissionsList?.add;
  const permissionsAddObj = permissionsList?.add;
  const permissionsShowAdd = permissionsList?.showAdd;

  if (permissionsAddObj && Array.isArray(permissionsAddObj) && permissionsAddObj.length) {
    /*if (permissionsAddObj[permissionsAddObj.length - 1].id !== 'machineUserType') {
      const idsSet = new Set(
        permissionsAddObj.map((item) => {
          if (item.show) return item.id;
        })
      );
      if (idsSet.has('empAssignMachines'))
        permissionsAddObj.push({
          id: 'machineUserType',
          isRequired: false,
          label: '',
          show: true,
          _id: 'hgdvhavdgahs554555'
        });
    }*/
    if (permissionsAddObj[permissionsAddObj.length - 1].id !== 'hasGlobalAccess') {
      const idsSet = new Set(
        permissionsAddObj.map((item) => {
          if (item.show) return item.id;
        })
      );
      // console.log('Employee ~ hasGlobalAccess - idsSet', idsSet);
      // if (idsSet.has('empAssignMachines')) {
      // permissionsAddObj.push({
      //   id: 'hasGlobalAccess',
      //   isRequired: false,
      //   label: 'Make This Employee "Global User"',
      //   show: true,
      //   _id: 'hgdvhavdgahs554555'
      // });
      // }
    }
  }

  // console.log('location : Machine Add n : ', permissionsAdd, permissionsShowAdd, ' | permissionsAddObj : ', permissionsAddObj);

  const validationSchemaOld = Yup.object().shape({
    prefix: Yup.string().required('Prefix is required'),
    firstName: Yup.string().max(20, 'First name cannot be longer than 20 characters').required('First name is required'),
    lastName: Yup.string().max(20, 'Last name cannot be longer than 20 characters').required('Last name is required'),
    title: Yup.string().max(20, 'Title cannot be longer than 20 characters').required('Title is required'),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    phone: Yup.string()
      .matches(/^[0-9]+$/, 'Phone number should be numeric')
      .required('Phone number is required'),
    address: Yup.string().max(150, 'Address cannot be longer than 150 characters').required('Address is required'),
    rfid:
      orgType === 'Enterprice'
        ? Yup.string()
            .matches(/^[a-zA-Z0-9]+$/, 'RFID should be alphanumeric')
            .required('RFID is required')
        : Yup.string().notRequired(),
    authPin:
      orgType === 'Enterprice'
        ? Yup.string()
            .matches(/^[a-zA-Z0-9]+$/, 'Auth Pin should be alphanumeric')
            .required('Auth Pin is required')
        : Yup.string().notRequired(),
    installerRfid:
      orgType === 'Super Admin'
        ? Yup.string()
            .matches(/^[a-zA-Z0-9]+$/, 'Installer RFID should be alphanumeric')
            .required('Installer RFID is required')
        : Yup.string().notRequired(),
    installerAuthPin:
      orgType === 'Super Admin'
        ? Yup.string()
            .matches(/^[a-zA-Z0-9]+$/, 'Installer Auth Pin should be alphanumeric')
            .required('Installer Auth Pin is required')
        : Yup.string().notRequired(),
    profileImage: Yup.mixed().notRequired(), // Profile image is not mandatory
    isAdmin: Yup.boolean().required('isAdmin is required'),
    assign_org: Yup.array()
      .of(Yup.string())
      .min(1, 'At least one organization must be assigned')
      .required('At least one organization must be assigned'),
    // assignMachine: Yup.array().of(Yup.object()).min('At least one machine must be assigned').required('Assigned machines must be assigned')
    assign_machine: Yup.array()
      .of(Yup.object())
      .min(1, 'At least one Assign Machine must be assigned')
      .required('At least one Assign Machine must be assigned'),
    // Machine User Type validation (dynamic validation based on initialValues)
    machineUserType: Yup.object().test('validate-machine-user-type', 'Machine User Type cannot be empty', function (value) {
      // Check each entry in machineUserType
      // const errors = [];
      const errors = {};
      // console.log('value | machineUserType : ', value, this);
      for (let key in value) {
        if (!value[key].machineTypeUser || value[key].machineTypeUser.trim() === '') {
          // this.createError({ path: `machineUserType.${key}.machineTypeUser`, message: 'Machine User Type cannot be empty' });
          // this.setFieldError(`machineUserType.${key}.machineTypeUser`, 'Machine User Type cannot be empty');
          /*errors.push({
            path: `machineUserType.${key}.machineTypeUser`,
            message: 'Machine User Type cannot be empty'
          });*/
          errors[key] = {
            machineTypeUser: 'Please Select Machine User Type'
          };
        }
      }
      // If errors are found, return them all at once
      /*if (errors.length > 0) {
        return this.createError({
          path: 'machineUserType',
          message: 'One or more machine user types are empty',
          params: { errors }
        });
      }*/
      // If there are errors, return them in the appropriate format
      if (Object.keys(errors).length > 0) {
        return this.createError({
          path: 'machineUserType',
          message: JSON.stringify(errors),
          params: { errors }
        });
      }
      return true; // If all entries pass validation
    })
  });

  // Create dynamic input refs based on permissionsEditObj
  // const inputRefs = useRef({});

  const keyMapping = {
    empPrefix: 'prefix',
    empFirstName: 'firstName',
    empLastName: 'lastName',
    empTitle: 'title',
    empEmail: 'email',
    empPhone: 'phone',
    empAddress: 'address',
    empRfid: 'rfid',
    empAuthPin: 'authPin',
    empInstallerRfid: 'installerRfid',
    empInstallerAuthPin: 'installerAuthPin',
    profileImage: 'profileImage',
    empIsAdmin: 'isAdmin',
    empAssignOrg: 'assign_org',
    empAssignMachines: 'assign_machine',
    machineUserType: 'machineUserType',
    hasGlobalAccess: 'hasGlobalAccess',
    empOrgType: 'org_type'
  };
  const phoneRegexMain = RegExp(/^\d{3}-\d{3}-\d{4}$/);
  // const phoneRegexMain = RegExp(/^((\+1)|1)? ?\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})( ?(ext\.? ?|x)(\d*))?$/);
  const validationSchema = Yup.object().shape(
    permissionsAddObj?.reduce((acc, field) => {
      const { id, label, isRequired, show } = field;
      // console.log('field | validationSchema | Employee : ', field);
      // Only apply validation if 'show' is true
      if (show) {
        let validationRule;
        const errorMessage = `${label} is required`;
        const labelMsg = label.replace(/\bSelect\b\s*/, '');

        // Define validation rules based on field ID
        switch (id) {
          case 'empPrefix':
          case 'empFirstName':
          case 'empLastName':
          case 'empTitle':
            validationRule = Yup.string().max(100, `${labelMsg} cannot be longer than 100 characters`);
            if (isRequired) validationRule = validationRule.required(errorMessage);
            break;
          case 'empAddress':
            validationRule = Yup.string().max(150, `${labelMsg} cannot be longer than 150 characters`);
            if (isRequired) validationRule = validationRule.required(errorMessage);
            break;
          case 'empEmail':
            validationRule = Yup.string().email(`Invalid ${labelMsg} format`);
            if (isRequired) validationRule = validationRule.required(errorMessage);
            break;
          case 'empPhone':
            validationRule =
              //   Yup.string().matches(
              //   /^(?!-)(?!.*-$)[0-9-]+$/,
              //   `${labelMsg} should be numeric and may contain hyphens, but not at the start or end`
              // );
              Yup.string().matches(phoneRegexMain, 'Employee Phone is not valid').trim();
            // if (isRequired) validationRule = validationRule.required(errorMessage);
            break;

          case 'empRfid':
            // if (orgType === 'Enterprice') {
            validationRule = Yup.string().matches(/^[a-zA-Z0-9-]+$/, `${labelMsg} should be alphanumeric and may contain hyphens`);
            if (isRequired) validationRule = validationRule.required(errorMessage);
            // }
            break;

          case 'empAuthPin':
            // if (orgType === 'Enterprice') {
            // validationRule = Yup.string().matches(/^[a-zA-Z0-9]+$/, `${labelMsg} should be alphanumeric`);
            validationRule = Yup.number()
              .integer(`${labelMsg} must be an integer`)
              .min(0, `${labelMsg} cannot be negative`)
              .typeError(`${labelMsg} must be a valid number`);
            if (isRequired) validationRule = validationRule.required(errorMessage);
            // }
            break;
          case 'empInstallerRfid':
            // if (orgType === 'Super Admin') {
            validationRule = Yup.string().matches(/^[a-zA-Z0-9-]+$/, `${labelMsg} should be alphanumeric and may contain hyphens`);
            if (isRequired) validationRule = validationRule.required(errorMessage);
            // }
            break;
          case 'empInstallerAuthPin':
            // if (orgType === 'Super Admin') {
            // validationRule = Yup.string().matches(/^[a-zA-Z0-9]+$/, `${labelMsg} should be alphanumeric`);
            validationRule = Yup.number()
              .integer(`${labelMsg} must be an integer`)
              .min(0, `${labelMsg} cannot be negative`)
              .typeError(`${labelMsg} must be a valid number`);
            if (isRequired) validationRule = validationRule.required(errorMessage);
            // }
            break;

          case 'empOrgType':
            validationRule = Yup.array().of(Yup.mixed());
            // if (isRequired) validationRule = validationRule.min(1, `Must have at least one ${label}`).required(errorMessage);
            if (isRequired)
              validationRule = validationRule.min(1, `Please select at least one Organization Type to Add Employee`).required(errorMessage);
            break;
          case 'empAssignOrg':
            validationRule = Yup.array().of(Yup.object());
            if (isRequired) validationRule = validationRule.min(1, `Must have at least one ${label}`).required(errorMessage);
            break;

          case 'empAssignMachines':
            validationRule = Yup.array().of(Yup.object());
            if (isRequired) validationRule = validationRule.min(1, `Must have at least one ${label}`).required(errorMessage);
            break;
          case 'empAllocateMachine':
            validationRule = Yup.array().of(Yup.object());
            if (isRequired) validationRule = validationRule.min(1, `Must have at least one ${label}`).required(errorMessage);
            break;

          case 'machineUserType':
            validationRule = Yup.object().test('validate-machine-user-type', 'Machine User Type cannot be empty', function (value) {
              const errors = {};
              for (let key in value) {
                if (!value[key].machineTypeUser || value[key].machineTypeUser.trim() === '') {
                  errors[key] = {
                    machineTypeUser: 'Please Select Machine User Type'
                  };
                }
              }
              if (Object.keys(errors).length > 0) {
                return this.createError({
                  path: 'machineUserType',
                  message: JSON.stringify(errors),
                  params: { errors }
                });
              }
              return true;
            });
            break;
          case 'hasGlobalAccess':
            validationRule = Yup.boolean().oneOf([true, false], 'Must select Yes or No');
            break;

          default:
            validationRule = Yup.string().optional(); // Default case for any other field
        }

        // Add validation rule to accumulator
        acc[keyMapping[id]] = validationRule;
        // if (keyMapping[id]) inputRefs.current[keyMapping[id]] = inputRefs.current[keyMapping[id]] || { current: null };
      }

      return acc;
    }, {})
  );
  function transformMachineUserType(machineUserType) {
    // debugger;
    if (machineUserType) {
      let machineUserTypeobj = Object.keys(machineUserType).map((machineId) => {
        const machineData = machineUserType[machineId];
        const type = machineData.isLocal ? 'LocalUser' : machineData.isGlobal ? 'GlobalUser' : 'UnknownUser'; // Optional: Handle case where both are false

        return {
          machine: machineId,
          type: type
        };
      });
      // return machineUserTypeobj && Array.isArray(machineUserTypeobj) && machineUserTypeobj.length > 0 ? machineUserTypeobj : null;
      return machineUserTypeobj;
    } else {
      return null;
    }
  }

  function extractIds(data) {
    return data && Array.isArray(data) && data.length ? data.map((item) => item.id) : null;
  }

  const addEmployeeHandler = (submitData, resetForm, navigator) => {
    addEmployee(submitData, {
      onSuccess: (res) => {
        // console.log('res - submitData - Employee : ', res);
        if (res?.status === 200) {
          toastConfig.type = 'success';
          setToast(toastConfig, res?.data.message);
          resetForm({});
          navigator('/dashboard/employees');
        } else {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        }
      },
      onError: (error) => {
        toastConfig.type = 'error';
        setToast(toastConfig, error?.response?.data?.error);
      }
    });
  };

  return (
    <>
      <BreadcrumbsForPage
        name="Add Employee"
        obj={{
          title: 'All Employees',
          title2: 'Add Employee',
          url: '/dashboard/employees'
        }}
      />
      <MainCard>
        <Formik
          initialValues={{
            prefix: '',
            firstName: '',
            lastName: '',
            title: '',
            email: '',
            phone: '',
            address: '',
            rfid: '',
            authPin: '',
            installerRfid: '',
            installerAuthPin: '',
            // documents: [],
            profileImage: '',
            isAdmin: false,
            // assignOrg: [],
            // assignMachine: [],
            assign_org: [],
            assign_machine: [],
            allocate_machine: [],
            machineUserType: {},
            createdByOrgType: orgType,
            hasGlobalAccess: false,
            org_type: []
            // submit: null,
            // user_type: ''
          }}
          validationSchema={validationSchema}
          onSubmit={async (value, { resetForm }) => {
            const organizationType =
              value?.org_type && Array.isArray(value?.org_type) && value?.org_type?.length
                ? value?.org_type.map((o) => {
                    return o.toString().replaceAll(' ', '');
                  })
                : '';
            const submitData = {
              isAdmin: value?.isAdmin ?? null,
              createdByOrgType: value?.createdByOrgType ? value?.createdByOrgType?.replaceAll(' ', '') : '' || null,
              prefix: value?.prefix || null,
              firstName: value?.firstName || null,
              lastName: value?.lastName || null,
              title: value?.title || null,
              email: value?.email || null,
              phone: value?.phone || null,
              address: value?.address || null,
              authenticationInfo:
                value?.rfid || value?.authPin
                  ? {
                      rfidCode: value?.rfid || null,
                      authPIN: value?.authPin || null
                    }
                  : null,
              saAuthenticationInfo:
                value?.installerRfid || value?.installerAuthPin
                  ? {
                      rfidCode: value?.installerRfid || null,
                      authPIN: value?.installerAuthPin || null
                    }
                  : null,
              image:
                value?.profileImage && Array.isArray(value?.profileImage) && value?.profileImage.length ? value?.profileImage[0] : null,
              assignedOrganizations: extractIds(value?.assign_org) || [],
              assignedMachines: extractIds(value?.assign_machine) || [],
              // allotedMachines: transformMachineUserType(value?.machineUserType) || [],
              allocatedMachines: extractIds(value?.allocate_machine) || [],
              hasGlobalAccess: value?.hasGlobalAccess ?? null,
              organizationTypes: organizationType || null
            };

            /*console.log(
              '🚀 ~ onSubmit={ ~ submitData | Employee :',
              submitData,
              value,
              value?.machineUserType,
              ' | allotedMachines : ',
              transformMachineUserType(value?.machineUserType)
            );*/

            const checkEmployeAddPayload = {
              allocatedMachines: !value?.hasGlobalAccess ? extractIds(value?.allocate_machine) || [] : null,
              hasGlobalAccess: value?.hasGlobalAccess ?? null
            };
            /*console.log(
              'checkEmployeAdd - Employee : ',
              checkEmployeAddPayload,
              submitData.createdByOrgType,
              value?.hasGlobalAccess,
              value?.allocate_machine
            );*/
            if (value.createdByOrgType === 'Enterprise') {
              checkEmployeeAdd(checkEmployeAddPayload, {
                onSuccess: (res) => {
                  // console.log('res - checkEmployeeAdd - Employee : ', res, checkEmployeAddPayload);
                  if (res?.status == 200) {
                    // toastConfig.type = 'success';
                    // setToast(toastConfig, res?.data.message);

                    // resetForm({});
                    // navigator('/dashboard/employees');

                    // addEmployeeHandler(submitData, resetForm, navigator);
                    // setOpenDialog(true);
                    //TESTING
                    setOpenDialog((prev) => ({ ...prev, show: true, status: 402 }));
                  } else {
                    // toastConfig.type = 'error';
                    // setToast(toastConfig, res?.response?.data?.error);
                    if (res?.response?.status === 402 || res?.response?.status === 403) {
                      setOpenDialog((prev) => ({ ...prev, show: true, status: res?.response?.status }));
                    }
                  }
                },
                onError: (error) => {
                  // toastConfig.type = 'error';
                  // setToast(toastConfig, error?.response?.data?.error);
                  console.log('checkEmployeeAdd ~ error : ', error);
                }
              });
            } else {
              addEmployeeHandler(submitData, resetForm, navigator);
            }

            /*addEmployee(submitData, {
              onSuccess: (res) => {
                console.log('res - submitData - Employee : ', res);
                if (res?.status == 200) {
                  toastConfig.type = 'success';
                  setToast(toastConfig, res?.data.message);
                  resetForm({});
                  navigator('/dashboard/employees');
                } else {
                  toastConfig.type = 'error';
                  setToast(toastConfig, error?.response?.data?.error);
                }
              },
              onError: (error) => {
                toastConfig.type = 'error';
                setToast(toastConfig, error?.response?.data?.error);
              }
            });*/
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, setValues, setTouched }) => (
            <>
              {permissionsShowAdd ? (
                <EmployeeForm
                  btnName={'Add'}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  errors={errors}
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting}
                  values={values}
                  setFieldValue={setFieldValue}
                  setValues={setValues}
                  setTouched={setTouched}
                  permissionsAddEdit={permissionsAdd}
                  isAdd={true}
                  isLoadingAddEmployee={isLoadingAddEmployee}
                  openDialog={openDialog}
                  setOpenDialog={setOpenDialog}
                  // inputRefs={inputRefs}
                />
              ) : permissionsShowAdd === undefined ? (
                <>
                  <OrganizationFormView />
                </>
              ) : (
                <>
                  <Alert variant="outlined" severity="error" icon={<Lock />}>
                    <AlertTitle>Permission Denied</AlertTitle>
                    Creating new Employee is not allowed based on your access level.
                  </Alert>
                </>
              )}
            </>
          )}
        </Formik>
      </MainCard>
    </>
  );
};

export default EmployeeAdd;
