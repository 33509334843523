import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { endpoints } from 'endpoints/endpoints';
import axiosInstance from 'utils/axiosInstance';
import { setToast, toastConfig } from 'utils/commonUtil';

export const useGetJobList = ({
  limit,
  sortOrder,
  sortField,
  page,
  machineId,
  name,
  referenceNo,
  startDate,
  endDate,
  organization,
  location
}) => {
  return useQuery({
    queryKey: ['getJobData', limit, sortOrder, sortField, page, machineId, name, referenceNo, startDate, endDate, organization, location],

    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.jobs}/${page}/${limit}`, {
          filters: {
            employeeName: name || null,
            machine: machineId || null,
            ticketNo: referenceNo || null, /// will be reference no
            jobDate: {
              from: startDate ? startDate : null,
              to: endDate ? endDate : null
            },
            organization: organization || null,
            location: location || null
          },
          sort: {
            sortField: sortField,
            sortOrder: sortOrder
          }
        })

        .then((res) => {
          if (res?.status == 200) {
            return res?.data.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    enabled: Boolean(localStorage.getItem('userJwtToken')),
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

///find By Id

export const useGetJobId = ({ id }) => {
  return useQuery({
    queryKey: ['getJobById', id],
    queryFn: async () => {
      return await axiosInstance
        .get(`${endpoints.jobs}/${id}`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          toastConfig.type = 'error';
          setToast(toastConfig, e.response.data.e);
          console.log(e);
        });
    },
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

// job details update api
export const useGetJobUpdateList = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      // console.log('🚀 ~ mutationFn: ~ data:', data);
      return await axiosInstance
        .put(
          // `${endpoints.jobs}/${data?.jobId}/drives/${data?.driveId}`,
          `${endpoints.drives}/${data?.driveId}`,
          {
            selectedMake: data?.selectedMake || null,
            selectedModel: data?.selectedModel || null,
            selectedSerialNo: data?.selectedSerialNo || null
          }
        )
        .then((res) => {
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data.message);
            toastConfig.type = 'info';
            setToast(toastConfig, 'We are currently regenerating the new download URL. Please wait a few minutes!');
          } else {
            toastConfig.type = 'success';
            setToast(toastConfig, error?.response?.data?.error);
          }
          return res;
        })

        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },
    staleTime: 0,

    onSuccess: ({ data }) => {
      queryClient.refetchQueries({ queryKey: ['getJobById'], type: 'active' });
      queryClient.refetchQueries({ queryKey: ['getJobUrlPdf'], type: 'active' });
      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};

////Get all Jobs while clicking machine  Id

// export const useGetJobMachineList = ({ enterprise, machineId }) => {
//   return useQuery({
//     queryKey: ['getJobMachineListData', enterprise, machineId],
//     queryFn: async () => {
//       return await axiosInstance
//         .post(`${endpoints.jobs}`, {
//           enterprise: enterprise,
//           machineId: machineId
//         })

//         .then((res) => {
//           if (res?.status == 200) {
//             return res?.data.data;
//           } else {
//             return [];
//           }
//         })
//         .catch((e) => {
//           console.log(e);
//         });
//     },
//     onSuccess: ({ data }) => {
//       return data;
//     }
//   });
// };

//machineId Job List

///Permission List

export const useGetPermissionsJobList = ({ organizationType, usersType }) => {
  return useQuery({
    queryKey: ['getPermissionsJob', organizationType, usersType],
    queryFn: async () => {
      return await axiosInstance

        .get(`${endpoints.permissionsScreen}/${usersType}/${organizationType}/Jobs`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          toastConfig.type = 'error';
          setToast(toastConfig, e.response.data.e);
        });
    },
    enabled: Boolean(organizationType) && Boolean(usersType),
    staleTime: 0,
    onSuccess: ({ data }) => {
      return data;
    }
    // refetchInterval: 30000
  });
};

// Download Crush Report

export const useGetJobUrlPdf = ({ id }) => {
  // const queryClient = useQueryClient();
  return useQuery({
    queryKey: ['getJobUrlPdf', id],
    queryFn: async () => {
      return await axiosInstance
        .get(`${endpoints.jobs}/${id}/crush-report`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          toastConfig.type = 'error';
          setToast(toastConfig, e.response.data.e);
          console.log(e);
        });
    },
    enabled: Boolean(id)
    // onSuccess: ({ data }) => {
    //   queryClient.refetchQueries({
    //     queryKey: ['getJobById'],
    //     type: 'active'
    //   });
    //   return data;
    // }
  });
};

export const useUserDetailsUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      return await axiosInstance
        .put(`${endpoints.jobs}/${data.id}`, data)
        .then((res) => {
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data.message);
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },
    onSuccess: ({ data }) => {
      queryClient.refetchQueries({ queryKey: ['getJobById'], type: 'active' });
      queryClient.refetchQueries({ queryKey: ['getJobUrlPdf'], type: 'active' });
      // queryClient.refetchQueries('getMachineData');
      // queryClient.refetchQueries('getLocationDetailsById');
      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};
