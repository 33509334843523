import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  // Stack,
  // Chip,
  // Typography,
  IconButton,
  Box,
  Skeleton,
  Chip
  // Skeleton,
  // CircularProgress,
  // Button,
  // Typography,
  // Chip,
  // CircularProgress
  // Collapse
  // Tab
  // Stack,
  // Button,
  // CircularProgress,
  // Chip
  // Tooltip
  // Pagination
} from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
// import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
// import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
// import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
// import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';

// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { styled } from '@mui/material/styles';
import MainCard from 'ui-component/cards/MainCard';
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// routeing
// import { useNavigate } from 'react-router-dom';
// import Breadcrumbs from 'ui-component/extended/Breadcrumbs';

import BreadcrumbsForPage from 'ui-component/extended/BreadcrumbsForPage';

import SkeletonTableView from 'ui-component/cards/Skeleton/TableView';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// import { Box, Stack } from '@mui/system';
// import { useRetailerStatusUpdate } from 'hooks/useRetailerHooks';
// import moment from 'moment';
// import RecyclerFilter from 'ui-component/organization-filter';
// import { useGetOrganizationNewList, useOrganizationNewStatusUpdate } from 'hooks/useOrganizationNewHooks';
import { TooltipStatus } from 'ui-component/fields/tooltip.status';
import { useGetPermissionsFaqList, useGetSuperAdminFaqList, useSuperAdminFaqStatusUpdate } from 'hooks/useSuperAdminFaqHooks';
import { useSelector } from 'react-redux';
// import ViewSupport from './view-support';

// import { useGetOrgList } from 'hooks/useOrganizationHooks';

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  margin: '0 auto',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626'
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959'
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343'
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c'
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff'
  }
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

function EnhancedTableHead({
  // classes,
  order,
  orderBy,
  onRequestSort,
  headersObj
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headersObj?.map((headCell) => (
          <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false} align="center">
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              disabled={orderBy !== headCell.id}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
const HtmlTooltip = styled(({ className, ...props }) => <Tooltip enterTouchDelay={0} {...props} classes={{ popper: className }} arrow />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9'
    }
  })
);

function CollapsibleRow({ row, permissionsList, headersObj }) {
  // console.log('🚀 ~ CollapsibleRow ~ headersObj:', headersObj);
  // console.log('🚀 ~ CollapsibleRow ~ row:', row);
  const { mutate: updatedStatus, isPending: isLoadingStatus } = useSuperAdminFaqStatusUpdate();
  const navigate = useNavigate();
  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
      {headersObj &&
        headersObj.length > 0 &&
        headersObj?.map((header) => {
          return (
            <React.Fragment key={header.id}>
              {header.id === 'actionUpdate' ? (
                <TableCell align="center">
                  {permissionsList?.showEdit && (
                    <>
                      <HtmlTooltip title={'Edit' + '  ' + row?.question}>
                        <IconButton onClick={() => navigate(`/dashboard/faq/super-admin/edit-${row?.id}`)}>
                          <ModeEditOutlinedIcon />
                        </IconButton>
                      </HtmlTooltip>
                    </>
                  )}
                </TableCell>
              ) : header.id === 'createdAt' ? (
                <TableCell align="center">{row.createdAt ? moment(row.createdAt).format('Do MMMM YYYY') : 'N.A.'}</TableCell>
              ) : header.id === 'question' ? (
                <TableCell align="center">
                  {row?.question && row?.question?.length > 50 ? (
                    <HtmlTooltip title={row?.question}>
                      <span>{`${row?.question?.slice(0, 50)}...`}</span>
                    </HtmlTooltip>
                  ) : (
                    <span>{row?.question || 'N.A.'}</span>
                  )}
                </TableCell>
              ) : header.id === 'answer' ? (
                <TableCell align="center">
                  {row?.answer && row?.answer?.length > 50 ? (
                    <HtmlTooltip title={row?.answer}>
                      <span>{`${row?.answer?.slice(0, 50)}...`}</span>
                    </HtmlTooltip>
                  ) : (
                    <span>{row?.answer || 'N.A.'}</span>
                  )}
                </TableCell>
              ) : header.id === 'enabled' ? (
                <TableCell align="center">
                  {header?.changeState ? (
                    <TooltipStatus
                      title={`Are you sure want to ${row?.enabled ? 'deactivate' : 'activate'} the ${row?.question}?`}
                      label={row?.enabled ? 'Active' : 'Inactive'}
                      backgroundColor={row?.enabled ? '#6fd74b' : '#f44336'}
                      onStatusUpdate={() => {
                        console.log('alert');
                        updatedStatus({
                          id: row?.id,
                          status: !row?.enabled
                        });
                      }}
                      isLoading={isLoadingStatus}
                    />
                  ) : (
                    <Chip
                      // label={row.status === 'on' ? 'Active' : 'Inactive'}
                      // color={row.status === 'on' ? 'success' : 'error'}
                      label={row?.enabled === true ? 'Active' : 'Inactive'}
                      color={row?.enabled === true ? 'success' : 'error'}
                      style={{
                        color: '#fff'
                      }}
                      size="small"
                    />
                  )}
                </TableCell>
              ) : (
                <TableCell align="center">{row[header.id] || 'N.A.'}</TableCell>
              )}
            </React.Fragment>
          );
        })}
    </TableRow>
  );
}

// CollapsibleRow.propTypes = {
//   row: PropTypes.shape({
//     id: PropTypes.string,
//     supportType: PropTypes.string,
//     subject: PropTypes.string,
//     message: PropTypes.string
//   }).isRequired
// };

const SuperAdminFaq = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('createdAt');
  const [isLoading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  // const [openViewOrganization, setOpenViewOrganization] = useState(false);
  //   const [allOrgDetails, setAllOrgDetails] = useState({});
  const [previousPage, setPreviousPage] = useState(0);

  const [openFilterModal, setOpenFilterModal] = useState(true);
  const [filterObj, setFilterObj] = useState({
    status: '',
    question: ''
  });
  const organizationType = useSelector((state) => state.customization.orgType);

  const usersType = useSelector((state) => state.customization.userType);

  const { data: permissionsList, isLoading: isLoadingPermissionsList } = useGetPermissionsFaqList({
    organizationType: organizationType !== 'Super Admin' ? organizationType : 'SuperAdmin',
    // usersType: usersType === 'Main POC' ? 'POC' : 'POC'
    usersType: usersType === 'Main POC' || usersType === 'Secondary POC' ? 'POC' : usersType
  });
  // console.log('🚀 ~ SuperAdminFaq ~ permissionsList:', permissionsList);
  const { data: allFaqList, isLoading: isAllFaqDataLoading } = useGetSuperAdminFaqList({
    limit: rowsPerPage,
    sortOrder: order,
    sortField: orderBy,
    page: currentPage ? currentPage : previousPage,
    question: filterObj?.question || '',

    status: filterObj?.status || ''
  });
  // console.log('🚀 ~ SuperAdminFaq ~ allFaqList:', allFaqList);
  const [headers, setHeaders] = useState([]);
  useEffect(() => {
    if (permissionsList) {
      let tableHeaders = permissionsList?.view?.tableHeaders;
      const actionObj = {
        id: 'actionUpdate',
        label: 'Action'
      };
      // setHeaders([...tableHeaders, actionObj]);
      if (tableHeaders && Array.isArray(tableHeaders) && tableHeaders.length) setHeaders([...tableHeaders, actionObj]);
    }
  }, [permissionsList]);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // setFirstPageVal(allOrgList?.data?.first);
    // setLastPageVal(allOrgList?.data?.last);
    setCurrentPage(newPage + 1);
    setPreviousPage(currentPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      {isLoading && isLoadingPermissionsList ? (
        <>
          <SkeletonTableView />
        </>
      ) : (
        <>
          <BreadcrumbsForPage name="All FAQs" obj={{ title: 'All FAQs', title2: '', url: '' }} />

          <MainCard
            title=""
            isButton={true}
            url="/dashboard/faq/super-admin/add"
            // isFilter={true}
            isFilter={
              permissionsList === null
                ? false
                : permissionsList?.view?.filters === undefined
                ? true
                : permissionsList?.view?.filters?.length > 0
                ? true
                : false
            }
            openFilterAllModal={openFilterModal}
            setOpenFilterModal={setOpenFilterModal}
            isEnable={true}
            setFilterObj={setFilterObj}
            isFaqFilter={
              permissionsList === null
                ? false
                : permissionsList?.view?.filters === undefined
                ? true
                : permissionsList?.view?.filters?.length > 0
                ? true
                : false
            }
            filterObj={filterObj}
            isLoadingPermissionsList={isLoadingPermissionsList}
            permissionsList={permissionsList}
          >
            <TableContainer>
              {isAllFaqDataLoading ? (
                <TableBody>
                  {[1, 2, 3, 4].map((i) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                      <TableCell align="center">
                        {' '}
                        <Skeleton variant="text" width={200} />
                      </TableCell>
                      <TableCell align="center">
                        {' '}
                        <Skeleton variant="text" width={200} />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton variant="text" width={200} />
                      </TableCell>
                      <TableCell align="center">
                        {' '}
                        <Skeleton variant="text" width={200} />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton variant="text" width={200} />
                      </TableCell>{' '}
                      <TableCell align="center">
                        <Skeleton variant="text" width={200} />
                      </TableCell>{' '}
                      <TableCell align="center">
                        <Skeleton variant="text" width={200} />
                      </TableCell>
                      <TableCell align="center">
                        <IconButton>
                          <Skeleton variant="circular" width={40} height={40} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : allFaqList?.data?.faqs === undefined || allFaqList?.data?.faqs.length == 0 ? (
                <StyledGridOverlay>
                  <svg style={{ flexShrink: 0 }} width="240" height="200" viewBox="0 0 184 152" aria-hidden focusable="false">
                    <g fill="none" fillRule="evenodd">
                      <g transform="translate(24 31.67)">
                        <ellipse className="ant-empty-img-5" cx="67.797" cy="106.89" rx="67.797" ry="12.668" />
                        <path
                          className="ant-empty-img-1"
                          d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                        />
                        <path
                          className="ant-empty-img-2"
                          d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                        />
                        <path
                          className="ant-empty-img-3"
                          d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                        />
                      </g>
                      <path
                        className="ant-empty-img-3"
                        d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                      />
                      <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                        <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                        <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                      </g>
                    </g>
                  </svg>
                  <Box sx={{ mt: 1 }}>No data found!</Box>
                </StyledGridOverlay>
              ) : (
                <Table stickyHeader aria-label="sticky table" size="small">
                  <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} headersObj={headers} />
                  <TableBody>
                    {stableSort(allFaqList?.data?.faqs, getComparator(order, orderBy))?.map((row, i) => {
                      return <CollapsibleRow row={row} key={i} permissionsList={permissionsList} headersObj={headers} />;
                    })}
                  </TableBody>
                </Table>
              )}
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={allFaqList?.data?.rowPerPage}
              component="div"
              count={allFaqList?.data?.totalCount ? allFaqList?.data?.totalCount : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              // getItemAriaLabel={getItemAriaLabel}
            />
            {/* <ViewSupport open={openViewOrganization} setOpen={setOpenViewOrganization} details={allOrgDetails} />  */}
          </MainCard>
        </>
      )}
    </>
  );
};
EnhancedTableHead.propTypes = {
  order: PropTypes.string,
  orderBy: PropTypes.string,
  onRequestSort: PropTypes.any
};
export default SuperAdminFaq;
