import { Box, Button, CircularProgress, FormControl, FormHelperText, Grid, IconButton, OutlinedInput, Typography } from '@mui/material';
import { Field, FieldArray, Form } from 'formik';
import React from 'react';
import { gridSpacing } from 'store/constant';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useDeleteRow, useGetAllRow } from 'hooks/useSettingsHooks';
import { useEffect } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import DeleteDialog from 'ui-component/delete-dialog';
import { setToast, toastConfig } from 'utils/commonUtil';
import SettingsView from 'ui-component/cards/Skeleton/SettingsView';

const SuperAdminSettingsForm = ({ values, touched, errors, handleChange, setFieldValue, isLoadingRow }) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [rowId, setRowId] = useState('');

  const exceptThisSymbolsValue = ['e', 'E', '+', '-', '#', '%', '^', '.'];
  const { data: rowList, isPending: isRowListLoading } = useGetAllRow({
    isActive: true
  });
  const queryClient = useQueryClient();
  const { mutate: deleteRow, isPending: isDeleteRowLoading } = useMutation({
    mutationFn: useDeleteRow
  });

  useEffect(() => {
    if (rowList) {
      setFieldValue(
        'ranges',
        rowList?.length > 0
          ? rowList
          : [
              {
                rowValue: 20
              },
              {
                rowValue: 50
              }
            ]
      );
    }
  }, [rowList, setFieldValue]);
  return (
    <>
      {isRowListLoading ? (
        <>
          <SettingsView />
        </>
      ) : (
        <>
          <Form>
            <Grid container spacing={gridSpacing} sx={{ alignContent: 'center' }}>
              <Grid item xs={12}>
                <Typography component="h4" variant="h2" sx={{ mb: 2 }}>
                  Rows per page
                </Typography>
                <Grid container spacing={gridSpacing}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography component="h5" variant="h4" sx={{ mb: 2 }}>
                      This option defines the number of records shown in each list, including different roles like Enterprise, Retailer, and
                      Recycler in the admin panel.
                    </Typography>
                    <Grid container className="settings-row-page">
                      <FieldArray name="ranges">
                        {({ push, remove }) => (
                          <>
                            {values.ranges.map((range, index) => (
                              <Box
                                key={index}
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  // gap: 1,
                                  my: 1,
                                  mr: 1.5
                                }}
                              >
                                <FormControl
                                  fullWidth
                                  error={touched.ranges?.[index]?.rowValue && Boolean(errors.ranges?.[index]?.rowValue)}
                                >
                                  <Field
                                    as={OutlinedInput}
                                    name={`ranges[${index}].rowValue`}
                                    onChange={handleChange}
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    value={values.ranges[index].rowValue || ''}
                                    onKeyDown={(e) => {
                                      exceptThisSymbolsValue.includes(e.key) && e.preventDefault();
                                    }}
                                  />

                                  {Array.isArray(errors.ranges) && (
                                    <>
                                      {touched.ranges?.[index]?.rowValue && errors.ranges?.[index]?.rowValue && (
                                        <FormHelperText error sx={{ fontSize: '13.8px' }}>
                                          {errors.ranges?.[index]?.rowValue}
                                        </FormHelperText>
                                      )}
                                    </>
                                  )}
                                </FormControl>

                                <IconButton
                                  onClick={() => {
                                    if (values.ranges[index]?.id) {
                                      setOpenDeleteDialog(!openDeleteDialog);
                                      setRowId(values.ranges[index]?.id);
                                    } else {
                                      remove(index);
                                    }
                                  }}
                                  // onClick={() => remove(index)}
                                  color="error"
                                  disabled={values.ranges.length === 1 || index < 2} // Disable remove for first two values
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                            ))}

                            <div>
                              <IconButton
                                onClick={() => push({ rowValue: '' })} // Add a new empty value
                                color="primary"
                                className="settings-addbtn"
                              >
                                <AddIcon />
                              </IconButton>
                            </div>
                          </>
                        )}
                      </FieldArray>
                    </Grid>
                    {!Array.isArray(errors.ranges) && (
                      <>
                        {touched.ranges && errors.ranges && (
                          <FormHelperText error sx={{ fontSize: '13.8px', top: '-28px', position: 'relative', left: '21px' }}>
                            {errors.ranges}
                          </FormHelperText>
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              {errors.submit && (
                <Box sx={{ mt: 3 }}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}

              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                <Box sx={{ display: 'inline-block' }}>
                  <AnimateButton>
                    <Button
                      disableElevation
                      size="large"
                      type="submit"
                      variant="contained"
                      color="secondary"
                      style={{
                        color: 'white',
                        minWidth: '200px',
                        margin: '0px auto'
                      }}
                    >
                      {isLoadingRow ? (
                        <>
                          <CircularProgress
                            sx={{
                              color: 'white',
                              height: 20,
                              width: 20
                            }}
                          />
                        </>
                      ) : (
                        `Save`
                      )}
                    </Button>
                  </AnimateButton>
                </Box>
              </Box>
            </Grid>
          </Form>
        </>
      )}

      <DeleteDialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        title={'Confirmation !'}
        subTitle={'Are you sure to delete the rows per page?'}
        handleSubmit={() => {
          deleteRow(rowId, {
            onSuccess: (res) => {
              if (res?.status == 200) {
                toastConfig.type = 'success';
                setToast(toastConfig, res?.data.message);
                queryClient.refetchQueries({
                  queryKey: ['getallRow'],
                  type: 'active'
                });
              } else {
                toastConfig.type = 'error';
                setToast(toastConfig, 'Something went wrong!');
              }
            },
            onError: (error) => {
              toastConfig.type = 'error';
              setToast(toastConfig, error?.response?.data?.error);
            }
          });
        }}
        isDeleteLoading={isDeleteRowLoading}
      />
    </>
  );
};

export default SuperAdminSettingsForm;
