/* eslint-disable */
import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, DialogContentText, Typography, Divider } from '@mui/material';
// import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import { useTheme } from '@mui/material/styles';

export const MachineTypeConfirmationDialog = ({ open, onClose, onChangeType }) => {
  const theme = useTheme();
  return (
    <Dialog
      open={open.open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
          // Prevent closing on backdrop click or escape key press
          return;
        }
        onClose();
      }}
      disableEscapeKeyDown
      maxWidth="md"
      fullWidth={false}
    >
      <DialogTitle sx={{ padding: '9px 15px' }}>
        <Typography component="div" variant="h3" sx={{ display: 'flex', alignItems: 'center' }}>
          <InfoIcon sx={{ color: theme.palette.primary.dark, marginRight: 1 }} />
          Confirmation
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ padding: '8px 15px' }}>
        <Typography component="div" variant="subtitle1">
          Selected Buyer Organization does not have Type '{open.machineType}'.
          <br /> Do you want to change the Organization Type or Cancel the Process ?
        </Typography>
        {/* <p>
          Selected Buyer Organization does not have Type 'Enterprise(E)'.
          <br /> Do you want to change the Type or Cancel the Process
        </p> */}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="outlined" onClick={onClose} sx={{ color: 'red' }}>
          Cancel
        </Button>
        <Button variant="outlined" onClick={onChangeType} sx={{ color: theme.palette.primary.dark }}>
          Change Type
        </Button>
      </DialogActions>
    </Dialog>
    // <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
    //   <DialogTitle>Are you sure?</DialogTitle>
    //   <DialogContent>
    //     {/* <DialogContentText id="alert-dialog-description"> */}
    //     Selected Buyer Organization does not have type 'Enterprise(E)'.
    //     <br /> Do you want to change the Type or Cancel the Process
    //     {/* </DialogContentText> */}
    //   </DialogContent>
    //   <DialogActions>
    //     <Button onClick={onClose} >Disagree</Button>
    //     <Button onClick={onClose} autoFocus>
    //       Agree
    //     </Button>
    //   </DialogActions>
    // </Dialog>
  );
};
