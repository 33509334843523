import React from 'react';
import PropTypes from 'prop-types'; // Import PropTypes for validation
import { Formik } from 'formik';
import { Fragment } from 'react';
import SearchPermisionForm from 'ui-component/search-permission-form';
import ModifyPermission from './modify-permission';
import { Divider } from '@mui/material';
import * as Yup from 'yup'; // Import Yup for validation
import { useSelector, useDispatch } from 'react-redux';
import { useGetScreenData } from 'hooks/useControlPanelHooks';

function ScreenMasterControl({ routeList }) {
  // Access the current screen from Redux state
  const { route } = useSelector((state) => state.controlPanelReducer);

  // Fetch the screen-related data using a custom hook
  const { data: screenListList, isLoading: isLoadingScreenList } = useGetScreenData({
    screen: route // Fetch data based on the current screen
  });

  const dispatch = useDispatch();

  // Initial values for Formik form
  const initialValues = {
    screen: route || '' // Use the current screen from Redux, or default to an empty string
  };

  // Validation schema for the screen field
  const validationSchema = Yup.object().shape({
    screen: Yup.string().required('Screen Type is required') // Screen is mandatory
  });

  // Handle form submission
  const handleFormSubmit = (values) => {
    dispatch({ type: 'SET_ROUTE', payload: values?.screen }); // Update the screen value in Redux
  };

  return (
    <Fragment>
      {/* Formik wrapper for form handling */}
      <Formik
        enableReinitialize={true} // Reinitialize form values when `initialValues` change
        initialValues={initialValues} // Set initial values
        validationSchema={validationSchema} // Attach validation schema
        onSubmit={handleFormSubmit} // Handle form submission
      >
        {(args) => (
          <SearchPermisionForm
            {...args} // Spread Formik's props
            showScreenField={true} // Show the screen field in the form
            routeList={routeList} // Pass available screen routes as options
          />
        )}
      </Formik>

      {/* Divider for visual separation */}
      <Divider sx={{ my: 2 }} />

      {/* ModifyPermission component handles screen-specific permissions */}
      <ModifyPermission
        screen={route} // Pass the current screen value
        addScreenList={screenListList?.data?.data?.add || []} // Pass Add options
        editScreenList={screenListList?.data?.data?.edit || []} // Pass Edit options
        filtersScreenList={screenListList?.data?.data?.filters || []} // Pass Filter options
        modalScreenList={screenListList?.data?.data?.modal || []} // Pass Modal options
        isLoadingScreenList={isLoadingScreenList} // Indicate if data is loading
      />
    </Fragment>
  );
}

// Prop validation
ScreenMasterControl.propTypes = {
  routeList: PropTypes.arrayOf(PropTypes.string).isRequired // Expect an array of strings for routeList
};

export default ScreenMasterControl;
