import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

import { Formik } from 'formik';
import * as Yup from 'yup';
// import { useParams } from 'react-router-dom';
import ReportTicketModalForm from './form';
// import { useMutation } from '@tanstack/react-query';
import { useGetReportReason } from 'hooks/useGetSupportTicket';
import { setToast, toastConfig } from 'utils/commonUtil';
import { useQueryClient } from '@tanstack/react-query';
// import { useState } from 'react';

const ReportTicketModal = ({ open, setOpen, supportDetailsId, supportDetails, refetchSupportDetails }) => {
  console.log('🚀 ~ ReportTicketModal ~ supportDetails:', supportDetails);

  const handleClose = () => {
    setOpen(false);
  };
  const queryClient = useQueryClient();
  const { mutate: addreportReason, isPending: isLoadingReportReason } = useGetReportReason();

  const validationSchema = Yup.object({
    reopenReason: Yup.string().test('no-whitespace', 'Reopen reason cannot be empty', (value) => value && value.trim().length > 0)
  });
  return (
    <>
      <Dialog
        open={open}
        fullWidth={true}
        fullScreen={false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // maxWidth="sm"
      >
        <DialogTitle
          id="alert-dialog-title"
          variant="span"
          style={{
            textTransform: 'capitalize'
          }}
        >
          Reopen Reason Details
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500]
          })}
        >
          <CloseIcon color="error" />
        </IconButton>

        <DialogContent dividers>
          <Formik
            initialValues={{
              reopenReason: '',
              status: 'Reopened'
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              const submitData = {
                reopenReason: values?.reopenReason,
                id: supportDetailsId,
                status: values?.status
              };

              addreportReason(submitData, {
                onSuccess: (res) => {
                  if (res?.status == 200) {
                    toastConfig.type = 'success';

                    refetchSupportDetails();
                    setOpen(false);
                    queryClient.refetchQueries({ queryKey: ['getSupportTicketListData'], type: 'active' });
                  } else {
                    toastConfig.type = 'error';
                    setOpen(false);
                  }
                },
                onError: (error) => {
                  toastConfig.type = 'error';
                  setToast(toastConfig, error?.response?.data?.error);
                }
              });
            }}
          >
            {({ errors, touched, handleChange, values, handleSubmit, setFieldValue, handleBlur }) => (
              <>
                <ReportTicketModalForm
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  values={values}
                  handleSubmit={handleSubmit}
                  setFieldValue={setFieldValue}
                  handleClose={handleClose}
                  handleBlur={handleBlur}
                  isLoadingReportReason={isLoadingReportReason}
                />
              </>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ReportTicketModal;
