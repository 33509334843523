/* eslint-disable react/prop-types */
/* eslint-disable */
import React from 'react';
import { Typography, Box, Grid, Divider, Button } from '@mui/material';
import { useGetSubcriptionForMachineList, useMachineSubscriptionUpdate } from 'hooks/useMachineHooks';
import { useState } from 'react';
import SubscriptionPlansCarousel from '../subcription/SubscriptionPlansCarousel';
import ContactUsDialog from '../subcription/ContactUsDialog';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AlertDialog from '../subcription/AlertDialog';
// import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';

function EmployeeSubcriptionMachineDetailsForm({ openMachineDetails, setOpenMachineDetails, onHandleClose, setLoadingChooseSubscription }) {
  const { data: subcriptionList, isLoading: isLoadingSubcriptionList } = useGetSubcriptionForMachineList({
    filters: { machineSubs: true }
  });
  const { mutate: updateMachineSubscription, isLoading: isLoadingUpdateMachineSubscription } = useMachineSubscriptionUpdate();
  // console.log(isLoadingUpdateMachineSubscription);
  const [openContactUs, setOpenContactUs] = useState(false);
  const [openAlertDialog, setOpenAlertDialog] = useState({ open: false, currentPrice: null, sellectedPrice: null });
  // console.log('Employee ~ PricingTableDialog ~ openDialog ~ ');
  const callChooseSubscription = (sub = '') => {
    // console.log('callInvoiceScreen | sub', sub, sub?.price?.amount, openMachineDetails?.machineObj?.subscription?.amount);
    /* navigate('/dashboard/machines/create-invoice', {
        state: {
          machineObj: value,
          subId: id,
          subObj: subcriptionList && subcriptionList.length > 0 ? subcriptionList.filter((sub) => sub.id === id) : null
        }
      });*/
    const payload = { id: openMachineDetails?.machineObj?.id || null, subId: sub?.id || null, customSubscription: null };
    if (openMachineDetails?.machineObj?.subscription?.amount) {
      /* Subscription Downgrade */
      if (parseFloat(sub?.price?.amount) < parseFloat(openMachineDetails?.machineObj?.subscription?.amount)) {
        setOpenAlertDialog((prev) => ({
          ...prev,
          open: true,
          currentPrice: openMachineDetails?.machineObj?.subscription?.amount,
          sellectedPrice: sub?.price?.amount,
          title: 'Subscription Downgrade Alert',
          subTitle: `You are downgrading your package from $${
            openMachineDetails?.machineObj?.subscription?.amount ? openMachineDetails?.machineObj?.subscription?.amount?.toFixed(2) : ''
          } to $${sub?.price?.amount ? sub?.price?.amount?.toFixed(2) : ''}`,
          icon: <SentimentSatisfiedIcon sx={{ color: '#f44336' }} />,
          payload,
          updateMachineSubscription,
          onHandleClose: onHandleClose
        }));
      } else {
        /* Subscription Upgrade */
        setLoadingChooseSubscription(true);
        /*setOpenAlertDialog((prev) => ({
          ...prev,
          open: true,
          currentPrice: openMachineDetails?.machineObj?.subscription?.amount,
          sellectedPrice: sub?.price?.amount,
          title: 'Subscription Upgrade',
          subTitle: `Congratulations! You are upgrading to a higher-tier plan (${
            openMachineDetails?.machineObj?.subscription?.type || ''
          }).`,
          icon: <SentimentVerySatisfiedIcon sx={{ color: '#4caf50' }} />
        }));*/
        updateMachineSubscription(payload, {
          onSuccess: (res) => {
            if (res?.status === 200) {
              // console.log('res - submitData - Employee : ', res);
              setTimeout(() => {
                setLoadingChooseSubscription(false);
                onHandleClose();
              }, 500);
            } else {
              setLoadingChooseSubscription(false);
            }
          },
          onError: (error) => {
            console.log('error - submitData - Employee : ', error);
            setLoadingChooseSubscription(false);
          }
        });
      }
    } else {
      /* New Subscription */
      setLoadingChooseSubscription(true);
      /*setOpenAlertDialog((prev) => ({
        ...prev,
        open: true,
        currentPrice: openMachineDetails?.machineObj?.subscription?.amount,
        sellectedPrice: sub?.price?.amount,
        title: 'New Subscription Plan',
        subTitle: `Thank you for selecting a new subscription plan (${sub?.title || ''}).`,
        icon: <SentimentVerySatisfiedIcon sx={{ color: '#4caf50' }} />
      }));*/
      updateMachineSubscription(payload, {
        onSuccess: (res) => {
          if (res?.status === 200) {
            // console.log('res - submitData - Employee : ', res);
            setTimeout(() => {
              setLoadingChooseSubscription(false);
              onHandleClose();
            }, 500);
          } else {
            setLoadingChooseSubscription(false);
          }
        },
        onError: (error) => {
          console.log('error - submitData - Employee : ', error);
          setLoadingChooseSubscription(false);
        }
      });
    }
  };

  const machineObj = openMachineDetails?.machineObj;
  const subId = openMachineDetails?.machineObj?.subscription?.id;
  // console.log('EmployeeSubcriptionMachineDetailsForm ~ openMachineDetails', openMachineDetails);
  return (
    <>
      {/* <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: 'bold', color: '#212121' }}>
        Machine Details
      </Typography> */}
      {openMachineDetails && !openMachineDetails?.isLocalUser && (
        <Button
          variant="outlined"
          onClick={() => setOpenMachineDetails((prev) => ({ ...prev, open: false, machineObj: null, isCurrentSubscription: false }))}
          startIcon={<ArrowBackIosNewIcon />}
          sx={{ mb: 2 }}
          disabled={false}
        >
          Back
        </Button>
      )}
      {machineObj ? (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} sx={{ height: '100%' }}>
              {/* Machine Information Section */}
              <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginBottom: '12px', height: '315px' }}>
                <div style={{ background: '#6fd74b', padding: '10px 15px' }}>
                  <Typography component="h4" variant="h3" sx={{ color: '#fff' }}>
                    Machine Information
                  </Typography>
                </div>
                <div style={{ padding: '10px 15px', height: '100%' }}>
                  <div style={{ display: 'flex', marginBottom: '10px' }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <Typography variant="body1" sx={{ marginTop: '0px', fontSize: '15px' }}>
                          <strong>Machine ID:</strong> {machineObj?.id}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Typography variant="body1" sx={{ marginTop: '0px', fontSize: '15px' }}>
                          <strong>Nickname:</strong> {machineObj?.nickname || 'N.A.'}
                        </Typography>
                        <Typography variant="body1" sx={{ marginTop: '0px', fontSize: '15px' }}>
                          <strong>Serial Number:</strong> {machineObj?.serialNumber || 'N.A.'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Typography variant="body1" sx={{ marginTop: '20px', fontSize: '15px' }}>
                          <strong>Location:</strong> <br />
                          <span style={{ fontWeight: 500 }}>{machineObj?.locationName}</span> <br />
                          {machineObj?.location}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
              {/* <Typography variant="h3" gutterBottom sx={{ mb: 2, fontWeight: 'bold', color: '#424242' }}>
            Machine Information
          </Typography> */}
              {/* <Box
            sx={{
              mt: 1,
              p: 3,
              bgcolor: 'linear-gradient(to right, #e8f5e9, #c8e6c9)',
              borderRadius: 3,
              // border: '1px solid #6fd74b',
              boxShadow: 1,
              height: '90%'
            }}
          ></Box> */}
            </Grid>
            {openMachineDetails?.isCurrentSubscription && machineObj?.subscription && (
              <Grid item xs={12} sm={6} sx={{ height: '100%' }}>
                {/* Current Subscription Section */}
                <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginBottom: '12px' }}>
                  <div style={{ background: '#6fd74b', padding: '10px 15px' }}>
                    <Typography component="h4" variant="h3" sx={{ color: '#fff' }}>
                      Current Subscription
                    </Typography>
                  </div>
                  <div style={{ padding: '10px 15px', height: '100%' }}>
                    <div style={{ display: 'flex', marginBottom: '10px' }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                          <Typography variant="h4" gutterBottom>
                            Plan: <span style={{ color: '#6fd74b', fontWeight: 'bold' }}>{machineObj?.subscription?.type}</span>
                          </Typography>
                          <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
                            {machineObj?.subscription?.price}
                            {/* 💲 */}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Box sx={{ marginTop: '8px' }}>
                            <Typography variant="body1" sx={{ marginTop: '10px' }}>
                              <strong>Machine Specific User(s): </strong> {machineObj?.subscription?.limits?.maxLocalUsers}
                            </Typography>
                            <Typography variant="body1" sx={{ marginTop: '10px' }}>
                              <strong> Global User(s): </strong> {machineObj?.subscription?.limits?.maxGlobalUsers}
                            </Typography>
                            <Typography variant="body1" sx={{ marginTop: '10px' }}>
                              <strong>Advertisements:</strong> {machineObj?.subscription?.limits?.maxAdvertisements}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Divider sx={{ mb: 3 }} />
                          {/* <Grid container className="separate_border1" spacing={3}>
                  <Grid item xs={12} sm={12} md={12} lg={12}> */}
                          <Typography variant="body1" sx={{ marginTop: '10px' }}>
                            <strong>Added Machine Specific User(s): </strong> {machineObj?.subscription?.current?.localUsersAdded}
                          </Typography>
                          <Typography variant="body1" sx={{ marginTop: '10px' }}>
                            <strong>Added Advertisements:</strong> {machineObj?.subscription?.current?.advertisementsAdded}
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>
                {/* <Box
              sx={{
                mt: 1,
                p: 3,
                bgcolor: 'linear-gradient(to right, #c8e6c9, #e8f5e9)',
                borderRadius: 3,
                //   borderLeft: '4px solid #388e3c',
                boxShadow: 1,
                height: { xs: '80%', md: '90%', lg: '90%' },
                mb: 2
              }}
            >
            </Box> */}
              </Grid>
            )}
          </Grid>
        </>
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} sx={{ height: '100%' }}>
              <p>loading.....</p>
            </Grid>
          </Grid>
        </>
      )}

      {/* Select New Subscription Section */}
      <Typography variant="h3" sx={{ mt: 6, mb: 1, fontWeight: 'bold', color: '#424242' }}>
        Select New Subscription
      </Typography>
      <Typography variant="caption" sx={{ mt: 0, mb: 4, fontWeight: 'bold', color: '#424242' }}>
        <span style={{ color: 'red' }}>**</span> Upgrading to a higher subscription plan will take effect immediately, while downgrading
        will apply from the next billing cycle.
      </Typography>
      <SubscriptionPlansCarousel
        subcriptionList={subcriptionList}
        isLoadingSubcriptionList={isLoadingSubcriptionList}
        callChooseSubscription={callChooseSubscription}
        openContactUs={openContactUs}
        setOpenContactUs={setOpenContactUs}
        subId={subId}
        isCurrentSubscription={openMachineDetails?.isCurrentSubscription}
      />
      {openContactUs && (
        <ContactUsDialog
          open={openContactUs}
          setOpen={setOpenContactUs}
          onHandleClose={onHandleClose}
          updateMachineSubscription={updateMachineSubscription}
          machineId={openMachineDetails?.machineObj?.id || null}
          setLoadingChooseSubscription={setLoadingChooseSubscription}
        />
      )}
      {/* {console.log('openAlertDialog : ', openAlertDialog)} */}
      {openAlertDialog.open && (
        <AlertDialog
          open={openAlertDialog.open}
          setOpen={setOpenAlertDialog}
          title={openAlertDialog.title || ''}
          subTitle={openAlertDialog.subTitle || ''}
          icon={openAlertDialog.icon}
          // moduleName={'File'}
          handleSubmit={() => null}
          isDeleteLoading={true}
          payload={openAlertDialog?.payload || null}
          updateMachineSubscription={openAlertDialog?.updateMachineSubscription || null}
          setOpenDialog={openAlertDialog?.setOpenDialog}
          onHandleClose={openAlertDialog?.onHandleClose}
          setLoadingChooseSubscription={setLoadingChooseSubscription}
        />
      )}
    </>
  );
}

export default EmployeeSubcriptionMachineDetailsForm;
