import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  //   FormControl,
  FormHelperText,
  Grid,
  //   InputLabel,
  //   MenuItem,
  //   OutlinedInput,
  Paper,
  //   Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/system';
import { Form } from 'formik';
import React from 'react';
// import { useEffect } from 'react';
import { gridSpacing } from 'store/constant';
// import DocumentUpload from 'ui-component/file-upload';
const options = ['Enterprise', 'Recycler', 'Retailer', 'Reseller', 'ServiceProvider'];

const OrganizationEditModalForm = ({
  errors,
  touched,
  //   handleChange,
  values,
  handleSubmit,
  setFieldValue,
  // isLoadingGetSupport,
  handleClose,
  // organizationNewDetails,
  // employeerelocation,
  employeerelocationData,
  // isEmployeeRelocation
  // initialValues,
  storeCurrentOrgType,
  handleBlur,
  isLoadingOrganizationNew,
  setFieldTouched
}) => {
  const theme = useTheme();
  function EnhancedTableEmployeeDetailsHead() {
    const org_columns = [
      { id: 'employee_name', label: 'Employee Name', align: 'center' },
      {
        id: 'org_types',
        label: 'Select Organization Types',
        // minWidth: 150,
        align: 'center'
        // format: (value) => value.toLocaleString('en-US')
      }
    ];

    return (
      <TableHead>
        <TableRow>
          {org_columns?.map((headCell) => (
            <TableCell key={headCell.id} align="center">
              <TableSortLabel className="hover-color-white">{headCell.label}</TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  return (
    <>
      <Form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={gridSpacing} sx={{ alignContent: 'center' }}>
          <Grid item xs={12}>
            <Grid container spacing={gridSpacing}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Table stickyHeader aria-label="sticky table" fullWidth sx={{ ...theme.typography.customInput }}>
                  <EnhancedTableEmployeeDetailsHead />
                  <TableBody>
                    {employeerelocationData?.data?.data?.map((itemEmp, index) => (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        {/* Employee Name */}
                        <TableCell
                          align="center"
                          sx={{
                            width: '50%',
                            padding: '16px'
                          }}
                        >
                          <Typography component={'p'} variant="h5" sx={{ fontWeight: '600', marginRight: '4px', minWidth: '200px' }}>
                            {itemEmp?.firstName} {itemEmp?.lastName}
                          </Typography>
                          <Typography component={'p'} variant="h5" sx={{ fontWeight: '500', marginRight: '4px', minWidth: '200px' }}>
                            <span
                              style={{
                                fontSize: '13px'
                              }}
                            >
                              {itemEmp?.email}
                            </span>
                          </Typography>
                          <Typography
                            component={'p'}
                            variant="h5"
                            sx={{ fontWeight: '500', marginRight: '4px', minWidth: '200px', fontSize: '13px' }}
                          >
                            Organization Types:{' '}
                            <span
                              style={{
                                fontSize: '13px'
                              }}
                            >
                              {/* {itemEmp?.organizationTypes?.map((i) => (
                                <>{i.join(', ')}</>
                              ))} */}
                              {/* {itemEmp?.organizationTypes?.join(', ') || values?.org_types[index]?.selectedOrgTypes?.join(', ')} */}
                              {itemEmp?.organizationTypes?.length ? itemEmp?.organizationTypes?.join(', ') : 'N.A.'}
                            </span>
                          </Typography>
                        </TableCell>

                        {/* Autocomplete with Formik */}
                        <TableCell
                          align="center"
                          sx={{
                            width: '50%',
                            padding: '16px'
                            //   borderBottom: '1px solid #e0e0e0'
                          }}
                          className="org-edit-model"
                        >
                          <Autocomplete
                            key={index}
                            multiple
                            options={storeCurrentOrgType || options}
                            value={values.org_types[index]?.selectedOrgTypes || []} // Ensure correct binding
                            onChange={(_, newValue) => {
                              setFieldValue(`org_types.${index}.selectedOrgTypes`, newValue || []);
                              setFieldValue(`org_types.${index}.id`, itemEmp?.id || '');
                            }}
                            onBlur={handleBlur}
                            renderTags={(value, getTagProps) =>
                              value.map((option, i) => {
                                const { onDelete, ...tagProps } = getTagProps({ index: i });

                                return (
                                  <span
                                    key={`${option}-${index}-${i}`} // Unique key combining option, row index, and tag index
                                    {...tagProps}
                                    style={{
                                      backgroundColor: '#6fd74b',
                                      color: '#fff',
                                      padding: '2px 8px',
                                      borderRadius: '4px',
                                      marginRight: '5px',
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}
                                  >
                                    {option}
                                    <button
                                      onClick={onDelete}
                                      style={{
                                        marginLeft: '5px',
                                        background: 'none',
                                        border: 'none',
                                        color: '#fff',
                                        cursor: 'pointer'
                                      }}
                                    >
                                      ✖
                                    </button>
                                  </span>
                                );
                              })
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                variant="outlined"
                                placeholder="Select Types"
                                InputLabelProps={{ shrink: true }}
                                sx={{
                                  minWidth: '250px',
                                  borderRadius: '8px',
                                  '& fieldset': { border: 'none' }
                                }}
                                // onBlur={handleBlur}
                                onBlur={() => {
                                  setFieldTouched('org_types');
                                }}
                              />
                            )}
                            PaperComponent={(props) => <Paper {...props} style={{ maxHeight: '200px', overflowY: 'auto' }} />}
                          />

                          {/* Formik Error Handling */}
                          {touched.org_types?.[index]?.selectedOrgTypes && Boolean(errors.org_types?.[index]?.selectedOrgTypes) && (
                            <FormHelperText error sx={{ fontSize: '13.8px', mt: 1 }}>
                              {errors.org_types?.[index]?.selectedOrgTypes}
                            </FormHelperText>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
              {/* </div> */}
            </Grid>
          </Grid>
        </Grid>
        <DialogActions>
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box sx={{ m: 1, position: 'relative' }}>
            <Button
              fullWidth
              variant="contained"
              type="submit"
              style={{
                color: '#fff'
              }}
              color="primary"
              disabled={isLoadingOrganizationNew}
            >
              Save
              {isLoadingOrganizationNew && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: '#16B1FF',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px'
                  }}
                />
              )}
            </Button>
          </Box>
          <Button variant="outlined" type="reset" color="error" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Form>
    </>
  );
};

export default OrganizationEditModalForm;
