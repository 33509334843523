import React from 'react';

const TicketEdit = () => {
  return (
    <>
      <div>TicketEdit</div>
    </>
  );
};

export default TicketEdit;
