import { Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import MainCard from 'ui-component/cards/MainCard';

import BreadcrumbsForPage from 'ui-component/extended/BreadcrumbsForPage';

import * as Yup from 'yup';
import 'yup-phone';

import OrganizationFormView from 'ui-component/cards/Skeleton/OrganizationFormView';
import OrganizationsNewForm from '../form';
import {
  useGetAllEmployeeListModal,
  useGetOrganizationNewId,
  useGetPermissionsOrganizationNewList,

  // useOrganizationNewNullCheck,
  useOrganizationNewUpdate
} from 'hooks/useOrganizationNewHooks';

import { useParams } from 'react-router-dom';
// import { setToast, toastConfig } from 'utils/commonUtil';
// import DeleteDialog from 'ui-component/delete-dialog';
// import ConfirmDialogOrg from 'ui-component/confirm-edit-dialog';
import { useSelector } from 'react-redux';
import { Alert, AlertTitle } from '@mui/material';
import { Lock } from '@mui/icons-material';
// import { toastConfig } from 'utils/commonUtil';
import OrganizationEditDialog from '../organization-new-edit-modal';
// import { toast } from 'react-toastify';
// import { toastConfig } from 'utils/commonUtil';
// import { setToast, toastConfig } from 'utils/commonUtil';

const OrganizationsNewEdit = () => {
  const [isLoading, setLoading] = useState(true);
  const [storeCurrentOrgType, setStoreCurrentOrgType] = useState([]);
  const [storeSubmitOrg, setStoreSubmitOrg] = useState({});
  const authPinRegex = /^[0-9]+$/;
  // const phoneRegexMain = RegExp(/^((\+1)|1)? ?\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})( ?(ext\.? ?|x)(\d*))?$/);
  const phoneRegexMain = RegExp(/^\d{3}-\d{3}-\d{4}$/);
  const organizationType = useSelector((state) => state.customization.orgType);
  const usersType = useSelector((state) => state.customization.userType);
  const {
    data: permissionsList
    // isLoading: isLoadingPermissionsList
  } = useGetPermissionsOrganizationNewList({
    organizationType:
      organizationType === 'Service Provider' ? 'ServiceProvider' : organizationType === 'Super Admin' ? 'SuperAdmin' : organizationType,
    // usersType: usersType === 'Main POC' ? 'POC' : 'POC'
    usersType: usersType === 'Main POC' || usersType === 'Secondary POC' ? 'POC' : usersType
  });

  // Access the state object

  let { id } = useParams();
  const { data: organizationNewDetails } = useGetOrganizationNewId({
    id: id.slice(1)
  });
  const [openEditModal, setOpenEditModal] = useState(false);

  const editPermissionsList = permissionsList?.edit || [];
  let buttonPermissions = [];
  let mainPOPermissions = [];
  let secondPOPermissions = [];
  let otherPOPermissions = [];
  editPermissionsList?.forEach((permission) => {
    if (permission?.id?.indexOf('Option') != -1) {
      buttonPermissions.push(permission);
    } else if (permission?.id?.indexOf('main') != -1) {
      mainPOPermissions.push(permission);
    } else if (permission?.id?.indexOf('secondary') != -1) {
      secondPOPermissions.push(permission);
    } else {
      otherPOPermissions.push(permission);
    }
  });

  const editPermissionsShowEdit = permissionsList?.showEdit;
  const { mutate: updateOrganizationNew, isPending: isLoadingOrganizationNew } = useOrganizationNewUpdate();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const initials = {
    associateEmpEmail: '',
    associateEmpPhone: '',
    associateEmpAdd: '',
    EmpFullAddress: '',
    EmpCity: '',
    EmpState: '',
    EmpCountry: '',
    EmpPostal_Code: '',
    SecondaryEmpEmail: '',
    SecondaryEmpPhone: '',
    SecondaryEmpAdd: '',
    OrgName: '',
    OrgWeb: '',
    isChecked: false,
    submit: null,
    title: '',
    firstName: '',
    lastName: '',
    designation: '',

    secondaryTitle: '',
    secondaryFirstName: '',
    secondaryLastName: '',
    secondaryDesignation: '',

    placeId: '',
    latitude: '',
    longitude: '',
    orgSelection: [],
    ///recycler//
    service_radius: '',
    serviceRadiusUnit: 'Miles',
    rfIdCodePrimary: '',
    // authPinPrimary: null,
    // authPinSecondary: null,
    authPinPrimary: '',
    authPinSecondary: '',
    rfIdCodeSecondary: '',
    orgPreviousSelection: [],

    // secondaryPOCId: ''
    locationPickUp: [],
    location: '',
    city: '',
    state: '',
    country: '',
    postalCode: '',
    tax: '',
    locationName: '',
    countryCode: ''
  };

  // Generate dynamic validation schema
  const dynamicalidationSchema = Yup.object().shape(
    editPermissionsList.slice(5, 27).reduce((schema, filter) => {
      if (filter?.show && filter?.isRequired && !filter?.isStatic) {
        switch (filter.id) {
          case 'mainPOCPrefix':
            schema['title'] = Yup.string().trim().required('Prefix is required');
            break;
          case 'mainPOCTitle':
            schema['designation'] = Yup.string()
              .max(50, 'Employee Title must be at most 50 characters')
              .trim()
              .required('Employee Title is required');
            break;
          case 'mainPOCFirstName':
            schema['firstName'] = Yup.string()
              .max(50, 'Employee First Name must be at most 50 characters')
              .trim()
              .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')
              .matches(/^[^!@#$%^&*()_+\-=\\/[\]{}:';"\\|,.<>\\?]*$/, 'Employee First Name should not include special characters')
              .required('Employee First Name is required');
            break;
          case 'mainPOCLastName':
            schema['lastName'] = Yup.string()
              .max(50, 'Employee Last Name must be at most 50 characters')
              .trim()
              .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')
              .matches(/^[^!@#$%^&*()_+\-=\\/[\]{}:';"\\|,.<>\\?]*$/, 'Employee Last Name should not include special characters')
              .required('Employee Last Name is required');
            break;
          case 'mainPOCEmail':
            schema['associateEmpEmail'] = Yup.string().email('Invalid email address').required('Employee Email is required');
            break;
          case 'mainPOCPhone':
            schema['associateEmpPhone'] = Yup.string().matches(phoneRegexMain, 'Employee Phone is not valid').trim();
            // .required('Employee Phone is required');
            break;
          // case 'mainPOCAddress':
          //   schema['associateEmpAdd'] = Yup.string()
          //     .max(150, 'Employee Address must be at most 150 characters')
          //     .trim()
          //     .required('Employee Address is required');
          //   break;
          case 'secondaryPOCPrefix':
            schema['secondaryTitle'] = Yup.string().trim().required('Prefix is required');
            break;
          case 'secondaryPOCTitle':
            schema['secondaryDesignation'] = Yup.string()
              .max(50, 'Employee Title must be at most 50 characters')
              .trim()
              .required('Employee Title is required');
            break;
          case 'secondaryPOCFirstName':
            schema['secondaryFirstName'] = Yup.string()
              .max(50, 'Employee First Name must be at most 50 characters')
              .trim()
              .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')
              .matches(/^[^!@#$%^&*()_+\-=\\/[\]{}:';"\\|,.<>\\?]*$/, 'Employee First Name should not include special characters')
              .required('Employee First Name is required');
            break;
          case 'secondaryPOCLastName':
            schema['secondaryLastName'] = Yup.string()
              .max(50, 'Employee Last Name must be at most 50 characters')
              .trim()
              .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')
              .matches(/^[^!@#$%^&*()_+\-=\\/[\]{}:';"\\|,.<>\\?]*$/, 'Employee Last Name should not include special characters')
              .required('Employee Last Name is required');
            break;
          case 'secondaryPOCEmail':
            schema['SecondaryEmpEmail'] = Yup.string().email('Invalid email address').required('Employee Email is required');
            break;
          case 'secondaryPOCPhone':
            schema['SecondaryEmpPhone'] = Yup.string().matches(phoneRegexMain, 'Employee Phone is not valid').trim();
            // .required('Employee Phone is required');
            break;
          // case 'secondaryPOCAddress':
          //   schema['SecondaryEmpAdd'] = Yup.string()
          //     .max(150, 'Employee Address must be at most 150 characters')
          //     .trim()
          //     .required('Employee Address is required');
          //   break;
          case 'mainPOCRFID':
            schema['rfIdCodePrimary'] = Yup.string().when('orgSelection', {
              is: (orgSelection) => orgSelection.includes('Enterprise') && filter.isRequired && !filter?.isStatic,
              then: Yup.string().required('RFID Code is required'),
              otherwise: Yup.string().nullable()
            });
            break;
          case 'mainPOCAuthPIN':
            schema['authPinPrimary'] = Yup.string()
              .matches(authPinRegex, 'Auth PIN must be a valid number')
              .trim()
              .required('Auth PIN is required');
            break;
          case 'secondaryPOCRFID':
            schema['rfIdCodeSecondary'] = Yup.string().when('orgSelection', {
              is: (orgSelection) => orgSelection.includes('Enterprise') && filter.isRequired && !filter?.isStatic,
              then: Yup.string().required('RFID Code is required'),
              otherwise: Yup.string().nullable()
            });
            break;
          case 'secondaryPOCAuthPIN':
            schema['authPinSecondary'] = Yup.string()
              .matches(authPinRegex, 'Auth PIN must be a valid number')
              .trim()
              .required('Auth PIN is required');
            break;
          case 'serviceRadius':
            schema['service_radius'] = Yup.number()
              // .transform((value, originalValue) => (originalValue === '' ? null : value))
              .min(0, 'Service Radius cannot be negative')
              .typeError('Service Radius must be a valid number')
              .when('orgSelection', {
                is: (orgSelection) =>
                  (orgSelection.includes('Recycler') || orgSelection.includes('Service Provider')) &&
                  filter.isRequired &&
                  !filter?.isStatic,
                then: Yup.number().required('Service Radius is required'),
                otherwise: Yup.number().nullable()
              });

            break;
          case 'orgName':
            schema['OrgName'] = Yup.string()
              .max(50, 'Organization Name must be at most 50 characters')
              .trim()
              .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')
              .matches(/^[a-zA-Z0-9 .]*$/, 'Organization Name can only include letters, numbers, spaces, and dots')
              .required('Organization Name is required');
            break;
          case 'website':
            schema['OrgWeb'] = Yup.string()
              .url('Please enter a valid Organization Website Address')
              .required('Website Address is required');
            break;
          case 'address':
            schema['locationPickUp'] = Yup.array().min(1, 'At least one location is required').required('Select Location is required');
            break;
          default:
            break;
        }
      }
      return schema;
    }, {})
  );
  const staticValidationSchema = Yup.object().shape(
    {
      orgSelection: Yup.array().min(1, 'At least one organization type must be selected').required('Organization selection is required'),

      // location: Yup.string().when(['city', 'state', 'country', 'postalCode', 'tax', 'locationName'], {
      //   is: (dep_1, dep_2, dep_3, dep_4, dep_5, dep_6) => dep_1 || dep_2 || dep_3 || dep_4 || dep_5 || dep_6,
      //   then: Yup.string().required('Location Address is required'),
      //   otherwise: Yup.string().notRequired()
      // }),
      location: Yup.string().when(['city', 'state', 'country', 'postalCode', 'tax', 'locationName'], {
        is: (dep_1, dep_2, dep_3, dep_4, dep_5, dep_6) => dep_1 || dep_2 || dep_3 || dep_4 || dep_5 || dep_6,
        then: Yup.string().required('Location Address is required'),
        otherwise: Yup.string().notRequired()
      }),
      city: Yup.string().when(['location', 'state', 'country', 'postalCode', 'tax', 'locationName'], {
        is: (dep_1, dep_2, dep_3, dep_4, dep_5, dep_6) => dep_1 || dep_2 || dep_3 || dep_4 || dep_5 || dep_6,
        then: Yup.string().required('City is required'),
        otherwise: Yup.string().notRequired()
      }),
      state: Yup.string().when(['location', 'city', 'country', 'postalCode', 'tax', 'locationName'], {
        is: (dep_1, dep_2, dep_3, dep_4, dep_5, dep_6) => dep_1 || dep_2 || dep_3 || dep_4 || dep_5 || dep_6,
        then: Yup.string().required('State is required'),
        otherwise: Yup.string().notRequired()
      }),
      country: Yup.string().when(['location', 'city', 'state', 'postalCode', 'tax', 'locationName'], {
        is: (dep_1, dep_2, dep_3, dep_4, dep_5, dep_6) => dep_1 || dep_2 || dep_3 || dep_4 || dep_5 || dep_6,
        then: Yup.string().required('Country is required'),
        otherwise: Yup.string().notRequired()
      }),
      postalCode: Yup.string().when(['location', 'city', 'state', 'country', 'tax', 'locationName'], {
        is: (dep_1, dep_2, dep_3, dep_4, dep_5, dep_6) => dep_1 || dep_2 || dep_3 || dep_4 || dep_5 || dep_6,
        then: Yup.string().max(10, 'Must be of 10 characters').required('Postal Code is required'),
        otherwise: Yup.string().notRequired()
      }),
      // tax: Yup.number().when(['location', 'city', 'state', 'country', 'postalCode', 'locationName'], {
      //   is: (dep_1, dep_2, dep_3, dep_4, dep_5, dep_6) => dep_1 || dep_2 || dep_3 || dep_4 || dep_5 || dep_6,
      //   then: Yup.number().required('Tax is required'),
      //   otherwise: Yup.number().notRequired()
      // }),

      tax: Yup.number().when(['location', 'city', 'state', 'country', 'postalCode', 'locationName'], {
        is: (...deps) => deps.some((dep) => Boolean(dep)), // Ensures at least one dependency is truthy
        then: Yup.number()
          .required('Tax is required')
          .min(0, 'Tax cannot be negative')
          .max(100, 'Tax cannot exceed 100')
          .test(
            'is-decimal',
            'Tax must have at most two decimal places',
            (value) => value === undefined || value === null || /^\d+(\.\d{1,2})?$/.test(value)
          )
          .typeError('Tax must be a number'),
        otherwise: Yup.number()
          .notRequired()
          .positive('Tax must contain a positive number')
          .max(100, 'Maximum allowed value is 100')
          .test(
            'is-decimal',
            'Tax must have at most two decimal places',
            (value) => value === undefined || value === null || /^\d+(\.\d{1,2})?$/.test(value)
          )
          .typeError('Tax must be a number')
      }),
      locationName: Yup.string().when(['location', 'city', 'state', 'country', 'postalCode', 'tax'], {
        is: (dep_1, dep_2, dep_3, dep_4, dep_5, dep_6) => dep_1 || dep_2 || dep_3 || dep_4 || dep_5 || dep_6,
        then: Yup.string()
          .required('Location Name is required')
          // .matches(/^[a-zA-Z0-9 ]*$/, 'Location Name must not contain special characters')
          .max(50, 'Location Name must be at least 50 characters long'),
        otherwise: Yup.string()
          // .matches(/^[a-zA-Z0-9 ]*$/, 'Location Name must not contain special characters')
          .max(50, 'Location Name must be at least 50 characters long')
          .notRequired()
      })
    },
    [
      ['location', 'city'],
      ['location', 'state'],
      ['location', 'country'],
      ['location', 'postalCode'],
      ['location', 'tax'],
      ['location', 'locationName'],
      ['city', 'state'],
      ['city', 'country'],
      ['city', 'postalCode'],
      ['city', 'tax'],
      ['city', 'locationName'],
      ['state', 'country'],
      ['state', 'postalCode'],
      ['state', 'tax'],
      ['state', 'locationName'],
      ['country', 'postalCode'],
      ['country', 'tax'],
      ['country', 'locationName'],
      ['postalCode', 'tax'],
      ['postalCode', 'locationName'],
      ['tax', 'locationName']
    ]
  );
  // Ensure `dynamicalidationSchema` is always a Yup object
  const validatedDynamicSchema = Object.keys(dynamicalidationSchema.fields).length ? dynamicalidationSchema : Yup.object().shape({});

  // Merge static and dynamic validation schemas
  const validationSchema = staticValidationSchema.concat(validatedDynamicSchema);

  const { mutate: employeerelocation, isPending: isEmployeeRelocation, data: employeerelocationData } = useGetAllEmployeeListModal();
  return (
    <>
      {isLoading ? (
        <>
          <OrganizationFormView />
        </>
      ) : (
        <>
          <BreadcrumbsForPage
            name="Edit Organization"
            obj={{ title: 'All Organizations', title2: 'Edit Organizations', url: '/dashboard/organizations' }}
          />
          <MainCard>
            <Formik
              initialValues={initials}
              validationSchema={validationSchema}
              onSubmit={async (value) => {
                // console.log('🚀 ~ onSubmit={ ~ value:', value);
                // const orgTypesArr = value?.orgSelection ? value?.orgSelection.map((item) => item.toString().replaceAll(' ', '')) : [];
                const _location = value.locationPickUp
                  .filter((item) => item?.id !== '')
                  .map((item) => ({
                    details: item.id,
                    isPrimary: item?.isPrimary ? item?.isPrimary : false
                  }));
                // const _usr = value.users.filter((i) => i.firstName != '');
                // const requiredFields = ['locationName', 'location', 'city', 'state', 'country', 'postalCode', 'tax'];
                // const isLocationFieldsEmpty = requiredFields.every((field) => !value[field]?.trim());
                // const isLocationEmpty = _location.length === 0 && isLocationFieldsEmpty;

                // if (isLocationEmpty) {
                //   toast.warning('Either select an organization location or create a new one');
                //   // setIsLocationError(false);
                //   return;
                // }
                const submitData = {
                  id: id.slice(1),
                  isBothPOCsSame: value?.isChecked,
                  name: value?.OrgName,
                  website: value?.OrgWeb,

                  serviceRadius:
                    value?.orgSelection?.includes('Recycler') || value?.orgSelection?.includes('Service Provider')
                      ? {
                          unit: value?.serviceRadiusUnit,
                          value: value?.service_radius
                        }
                      : null,

                  mainPOC: {
                    id: value?.mainPocId,
                    prefix: value?.title,
                    title: value?.designation,
                    firstName: value?.firstName,
                    lastName: value?.lastName,
                    email: value?.associateEmpEmail,
                    phone: value?.associateEmpPhone,
                    address: value?.associateEmpAdd,
                    authenticationInfo: {
                      rfidCode: value?.orgSelection?.includes('Enterprise') ? value?.rfIdCodePrimary : '',
                      authPIN: value?.orgSelection?.includes('Enterprise') ? value?.authPinPrimary : ''
                    },
                    // types: ['Main POC'],
                    types: value?.isChecked ? ['Main POC', 'Secondary POC'] : ['Main POC'],
                    organizationTypes: value?.orgPreviousSelection // Previous Org Types
                  },

                  secondaryPOC: {
                    id: value?.isChecked ? value?.mainPocId : value?.SecondarPocId,
                    prefix: value?.secondaryTitle,
                    title: value?.secondaryDesignation,
                    firstName: value?.secondaryFirstName,
                    lastName: value?.secondaryLastName,
                    email: value?.SecondaryEmpEmail,
                    phone: value?.SecondaryEmpPhone,
                    address: value?.SecondaryEmpAdd,
                    authenticationInfo: {
                      rfidCode: value?.orgSelection?.includes('Enterprise') ? value?.rfIdCodeSecondary : '',
                      authPIN: value?.orgSelection?.includes('Enterprise') ? value?.authPinSecondary : ''
                    },
                    types: ['Secondary POC'],
                    organizationTypes: value?.orgPreviousSelection // Previous Org Types
                  },
                  // users: value?.orgSelection?.includes('Enterprise') ? _usr : null,
                  locations: _location,
                  types: value?.orgSelection.map((item) => item.replaceAll(' ', '')), /// Current Org Types && it is used as Service Provider is coming as Service Provider it should be ServicePriovider
                  addedLocation: value?.locationName
                    ? {
                        name: value?.locationName || null,
                        taxAmount: value?.tax ?? null, // Ensure taxAmount is explicitly null when not provided
                        address: {
                          fullAddress: value?.location || null, // Use null instead of empty string
                          placeId: value?.placeId || null, // Use null instead of empty string
                          coOrdinates: {
                            latitude: value?.latitude ?? null,
                            longitude: value?.longitude ?? null
                          },
                          countryCode: value?.countryCode || null,
                          countryName: value?.country || null,
                          city: value?.city || null,
                          postalCode: value?.postalCode || null,
                          state: value?.state || null
                        }
                      }
                    : null // Don't send `addedLocation` at all if `locationName` is missing
                };

                employeerelocation(
                  {
                    orgId: organizationNewDetails?.id,
                    previousOrgTypes: value?.orgPreviousSelection.map((item) => item.replaceAll(' ', '')),
                    currentOrgTypes: value?.orgSelection.map((item) => item.replaceAll(' ', ''))
                  },
                  {
                    onSuccess: (res) => {
                      if (res?.status == 200) {
                        // Relocation API worked good => Check data [] is empty or not
                        if (res?.data?.data.length > 0) {
                          // Employees available to relocate => Show modal
                          setOpenEditModal(true);
                          setStoreCurrentOrgType(value?.orgSelection.map((item) => item.replaceAll(' ', '')));
                          setStoreSubmitOrg({
                            ...submitData,
                            previousOrgTypes: value?.orgPreviousSelection.map((item) => item.replaceAll(' ', ''))
                          });
                        } else {
                          // No employee available for relocation => Update organization silently
                          updateOrganizationNew(submitData);
                          setStoreCurrentOrgType([]);
                          setStoreSubmitOrg({});
                        }
                      } else {
                        updateOrganizationNew(submitData);
                        setStoreCurrentOrgType([]);
                        setStoreSubmitOrg({});
                        // Show error => error message
                      }
                    },
                    onError: (error) => {
                      console.log('check error', error);
                    }
                  }
                );
              }}
              validateOnBlur={true}
              validateOnChange={true}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue,
                setTouched,
                setValues,
                setErrors
              }) => (
                <>
                  {editPermissionsShowEdit ? (
                    <>
                      <OrganizationsNewForm
                        btnName="Save Changes"
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        isSubmitting={isSubmitting}
                        touched={touched}
                        values={values}
                        setFieldValue={setFieldValue}
                        id={id}
                        setTouched={setTouched}
                        setValues={setValues}
                        isLoadingOrganizationNew={isLoadingOrganizationNew || isEmployeeRelocation}
                        // isLoadingOrganizationNew={isLoadingOrganizationNew || isLoadingOrganizationNullCheck}
                        organizationNewDetails={organizationNewDetails}
                        buttonPermissions={buttonPermissions}
                        mainPOPermissions={mainPOPermissions}
                        secondPOPermissions={secondPOPermissions}
                        otherPOPermissions={otherPOPermissions}
                        isEditable={true}
                        openEditModal={openEditModal}
                        setOpenEditModal={setOpenEditModal}
                        employeerelocationData={employeerelocationData}
                        isEmployeeRelocation={isEmployeeRelocation}
                        setErrors={setErrors}
                      />
                    </>
                  ) : editPermissionsShowEdit === undefined ? (
                    <>
                      <OrganizationFormView />
                    </>
                  ) : (
                    <>
                      <Alert variant="outlined" severity="error" icon={<Lock />}>
                        <AlertTitle>Permission Denied</AlertTitle>
                        You cannot edit this Organization due to your access permissions.
                      </Alert>
                    </>
                  )}
                </>
              )}
            </Formik>
          </MainCard>
          {openEditModal && (
            <>
              <OrganizationEditDialog
                open={openEditModal}
                setOpen={setOpenEditModal}
                organizationNewDetails={organizationNewDetails}
                employeerelocation={employeerelocation}
                isEmployeeRelocation={isEmployeeRelocation}
                employeerelocationData={employeerelocationData}
                storeCurrentOrgType={storeCurrentOrgType}
                storeSubmitOrg={storeSubmitOrg}
                setStoreSubmitOrg={setStoreSubmitOrg}
              />
            </>
          )}
          {/* {storeEditObj && (
            <>
              <ConfirmDialogOrg
                open={openModal}
                setOpen={setOpenModal}
                title={'Confirmation !'}
                subTitle={'An user already exists in your organization with this email. Do you want to add this user as secondary POC?'}
                handleSubmit={() => {
                  updateOrganizationNew(storeEditObj);
                }}
                isDeleteLoading={isLoadingOrganizationNew}
              />
            </>
          )} */}

          {/* <ConfirmDialogOrg
            open={true}
            setOpen={setOpenModal}
            title={'Confirmation !'}
            subTitle={'An user already exists in your organization with this email. Do you want to add this user as secondary POC?'}
            handleSubmit={() => {
              updateOrganizationNew(storeEditObj);
            }}
            isDeleteLoading={isLoadingOrganizationNew}
          /> */}
        </>
      )}
    </>
  );
};

export default OrganizationsNewEdit;
