// import { Menu } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
// import { useGetPermissionsOrganizationNewList } from 'hooks/useOrganizationNewHooks';
// import { textAlign } from '@mui/system';
import React from 'react';
import { useEffect } from 'react';
// import { useSelector } from 'react-redux';
import { gridSpacing } from 'store/constant';

const SubscriptionsFilterForm = ({
  errors,
  handleBlur,
  handleChange,
  // handleSubmit,
  touched,
  values,
  setFieldValue,
  // setTouched,
  resetForm,
  filterObj,
  // handleCloseFilter,
  setFilterObj
  // permissionsList,
  // orgFilters
}) => {
  const theme = useTheme();

  useEffect(() => {
    if (filterObj) {
      // console.log('🚀 ~ useEffect ~ filterObj:', filterObj);
      setFieldValue('title', filterObj?.title ? filterObj?.title : '');
      setFieldValue('price', filterObj?.price ? filterObj?.price : '');
      setFieldValue('isPriceLess', filterObj?.isPriceLess ? filterObj?.isPriceLess : '==');
      setFieldValue('interval', filterObj?.interval ? filterObj?.interval : '');
      setFieldValue('localUser', filterObj?.localUser ? filterObj?.localUser : '');
      setFieldValue('globalUser', filterObj?.globalUser ? filterObj?.globalUser : '');
    }
  }, [filterObj, setFieldValue]);

  // const organizationType = useSelector((state) => state.customization.orgType);
  // const usersType = useSelector((state) => state.customization.userType);
  // const { data: permissionsList } = useGetPermissionsOrganizationNewList({
  //   organizationType: organizationType !== 'Super Admin' ? organizationType : 'SuperAdmin',
  //   usersType: usersType === 'Main POC' ? 'POC' : usersType
  // });

  return (
    <>
      <Grid container spacing={gridSpacing} sx={{ marginBottom: '20px', alignItems: 'center', padding: '0px 20px' }}>
        {/* Title Field */}
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className="select-machine-id inv-number select-machine" alignItems={'center'}>
          <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
            Name
          </Typography>
          <TextField
            id="outlined-title"
            type="text"
            name="title"
            placeholder="Search Name"
            fullWidth
            value={values.title}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(touched.title && errors.title)}
            helperText={touched.title && errors.title ? errors.title : ''}
            variant="outlined"
          />
        </Grid>

        {/* Price Field */}
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className="select-machine-id inv-number select-machine" alignItems={'center'}>
          <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
            Price
          </Typography>
          <TextField
            id="outlined-price"
            type="number"
            name="price"
            placeholder="Search Price"
            fullWidth
            value={values.price}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(touched.price && errors.price)}
            helperText={touched.price && errors.price ? errors.price : ''}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <span style={{ fontSize: '1rem', fontWeight: 500, color: theme.palette.text.secondary }}>$</span>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{
                    fontSize: '10px',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <span style={{ fontSize: '0.9rem', fontWeight: 400, color: theme.palette.text.secondary, marginRight: 6 }}>
                    /user per month
                  </span>

                  <FormControl size="small">
                    <Select
                      id="outlined-isPriceLess"
                      name="isPriceLess"
                      value={values.isPriceLess}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                    >
                      <MenuItem value={'<='}>{'<='}</MenuItem>
                      <MenuItem value={'>='}>{'>='}</MenuItem>
                      <MenuItem value={'=='}>{'=='}</MenuItem>
                    </Select>
                  </FormControl>
                </InputAdornment>
              )
            }}
          />
        </Grid>

        {/* Interval Field */}
        <Grid item xs={12} sm={6} md={6} lg={3} xl={3} className="select-job-enterprise select-job-enterprise-type">
          <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
            Interval
          </Typography>
          <Autocomplete
            id="autocomplete-interval"
            options={['1 Month', '4 Months', '6 Months']}
            getOptionLabel={(option) => option}
            value={values.interval || ''} // Use null as the default value
            onChange={(event, newValue) => setFieldValue('interval', newValue)} // Update Formik's state
            onBlur={() => handleBlur('interval')} // Trigger Formik's blur handling
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select Interval"
                variant="outlined"
                error={Boolean(touched.interval && errors.interval)}
                helperText={touched.interval && errors.interval ? errors.interval : ''}
              />
            )}
          />
        </Grid>

        {/* Local User Field */}
        <Grid item xs={12} sm={6} md={6} lg={3} xl={3} className="select-machine-id inv-number select-machine" alignItems={'center'}>
          <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
            Machine Specific User Count
          </Typography>
          <TextField
            id="outlined-local-user"
            type="number"
            name="localUser"
            placeholder="Search Machine Specific User Count"
            fullWidth
            value={values.localUser}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(touched.localUser && errors.localUser)}
            helperText={touched.localUser && errors.localUser ? errors.localUser : ''}
            variant="outlined"
          />
        </Grid>

        {/* Global User Field */}
        <Grid item xs={12} sm={6} md={6} lg={3} xl={3} className="select-machine-id inv-number select-machine" alignItems={'center'}>
          <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
            Global User Count
          </Typography>
          <TextField
            id="outlined-global-user"
            type="number"
            name="globalUser"
            placeholder="Search Global User Count"
            fullWidth
            value={values.globalUser}
            onChange={handleChange}
            onBlur={handleBlur}
            error={Boolean(touched.globalUser && errors.globalUser)}
            helperText={touched.globalUser && errors.globalUser ? errors.globalUser : ''}
            variant="outlined"
          />
        </Grid>
      </Grid>

      <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'right', paddingBottom: '20px', paddingRight: '20px' }}>
        <Button
          variant="outlined"
          type="reset"
          color="error"
          onClick={() => {
            resetForm();
            setFilterObj({
              status: '',
              orgName: '',
              orgTypes: ''
            });
            // handleCloseFilter();
          }}
        >
          Clear all
        </Button>
        <div style={{ marginLeft: '10px' }}>
          {errors?.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors?.submit}</FormHelperText>
            </Box>
          )}
          <Box sx={{ m: 0, position: 'relative' }}>
            <Button
              // fullWidth
              variant="contained"
              type="submit"
              style={{
                color: '#fff'
              }}
              color="primary"
              //   disabled={isLoadingUpdateInvoiceStatus}
            >
              Search
            </Button>
          </Box>
        </div>
      </div>
    </>
  );
};

export default SubscriptionsFilterForm;
