import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axiosInstance from 'utils/axiosInstance'; // Your axios instance
import { endpoints } from 'endpoints/endpoints'; // Your API endpoints
import { setToast, toastConfig } from 'utils/commonUtil';
//
export const useGetLeftPanelList = ({ userType, orgType }) => {
  return useQuery({
    queryKey: ['getLeftPanelList', userType, orgType],
    queryFn: async () => {
      return await axiosInstance
        .get(`${endpoints.permissions}/panel/${userType}/${orgType}`)
        .then((res) => {
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data.message);
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },
    staleTime: 0,
    enabled: Boolean(userType && orgType),
    onSuccess: ({ data }) => {
      // queryClient.refetchQueries({ queryKey: ['getEnterpriseDetailsById'], type: 'active' });
      // queryClient.refetchQueries('getEnterpriseData');
      // queryClient.refetchQueries('getEnterpriseDetailsById');
      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};

export const useSaveLeftPanelData = ({ org, user }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      try {
        return await axiosInstance
          .post(`${endpoints.permissions}/panel`, {
            userType: user,
            orgType: org,
            data: data
          })
          .then((res) => {
            if (res?.status == 200) {
              toastConfig.type = 'success';
              setToast(toastConfig, res?.data.message);
            } else {
              toastConfig.type = 'error';
              setToast(toastConfig, error?.response?.data?.error);
            }

            return res;
          })
          .catch((error) => {
            toastConfig.type = 'error';
            setToast(toastConfig, error?.response?.data?.error);
          });
      } catch (error) {
        // console.error(error);
        throw new Error('Error saving data');
      }
    },
    onSuccess: ({ data }) => {
      // queryClient.refetchQueries({ queryKey: ['getEnterpriseDetailsById'], type: 'active' });
      queryClient.refetchQueries('getRoutingList');
      // queryClient.refetchQueries('getEnterpriseDetailsById');
      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};

//

export const useGetScreenData = ({ screen }) => {
  return useQuery({
    queryKey: ['getScreenData', screen],
    queryFn: async () => {
      return await axiosInstance
        .get(`${endpoints.permissions}/options/screen/${screen}`)
        .then((res) => {
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data.message);
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },
    staleTime: 0,
    enabled: Boolean(screen), // Only fetch if the screen is set
    onError: (error) => {
      toastConfig.type = 'error';
      setToast(toastConfig, error.message);
    }
  });
};

export const useSaveScreenData = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      return await axiosInstance
        .post(`${endpoints.permissions}/options/screen`, data)
        .then((res) => {
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data.message);
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, error?.response?.data?.error);
          }
          return res;
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },
    onSuccess: ({ data }) => {
      // queryClient.refetchQueries({ queryKey: ['getEnterpriseDetailsById'], type: 'active' });
      queryClient.refetchQueries('getRoutingList');
      // queryClient.refetchQueries('getEnterpriseDetailsById');
      return data;
    }
  });
};

//

export const useGetPermissionData = ({ userType, orgType, screen }) => {
  return useQuery({
    queryKey: ['getPermissionData', userType, orgType, screen],
    queryFn: async () => {
      return await axiosInstance
        .get(`${endpoints.permissions}/screen/${userType}/${orgType}/${screen}`)
        .then((res) => {
          if (res?.status === 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, 'Data fetched successfully');
            return res.data.data;
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, res?.data?.message || 'Error fetching data');
            throw new Error(res?.data?.message || 'Error fetching data');
          }
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error || error.message);
          throw error;
        });
    },
    staleTime: 0,
    enabled: Boolean(userType && orgType && screen), // Fetch only if parameters are valid
    onError: (error) => {
      toastConfig.type = 'error';
      setToast(toastConfig, error.message || 'An error occurred while fetching data');
    }
  });
};

export const useSavePermissionData = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      return await axiosInstance
        .post(`${endpoints.permissions}/screens`, data)
        .then((res) => {
          if (res?.status === 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data?.message || 'Data saved successfully');
            return res.data;
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, res?.data?.message || 'Error saving data');
            throw new Error(res?.data?.message || 'Error saving data');
          }
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error || error.message);
          throw error;
        });
    },
    onSuccess: ({ data }) => {
      // queryClient.refetchQueries({ queryKey: ['getEnterpriseDetailsById'], type: 'active' });
      queryClient.refetchQueries('getRoutingList');
      // queryClient.refetchQueries('getEnterpriseDetailsById');
      return data;
    },
    onError: (error) => {
      toastConfig.type = 'error';
      setToast(toastConfig, error.message || 'An error occurred while saving data');
    }
  });
};
