import styled from '@emotion/styled';
import { Box, Divider, Grid, Paper, Skeleton, Step, StepLabel, Stepper, Typography } from '@mui/material';
import React from 'react';
import { gridSpacing } from 'store/constant';

const MachineViewModalSkeleton = () => {
  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.h4,
    // textAlign: 'center',
    // color: theme.palette.text.secondary,
    // height: 60,
    // lineHeight: '60px',
    padding: '10px'
  }));

  const stepsLoading = ['Select master blaster campaign settings', 'Create an ad group', 'Create an ad'];

  return (
    <>
      <Grid container spacing={gridSpacing} style={{ paddingTop: '15px' }}>
        <Grid item xs={12} sm={12} md={6} lg={4} className="machineModalView">
          <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', height: '100%' }}>
            <div className="address_locationmain" style={{ background: '#6fd74b', mb: 2, padding: '10px 15px' }}>
              <Typography
                component="h4"
                variant="h3"
                // sx={{ mr: 1, pb: 1.8 }}
                sx={{ color: '#fff' }}
              >
                <Skeleton />
              </Typography>
            </div>

            <div className="organization_machine_name">
              <Typography variant="h4" component="p" style={{ fontWeight: 'bold' }}>
                <Skeleton />
              </Typography>
              <Typography variant="h4" component="p" style={{ fontWeight: 'unset', paddingTop: 10, paddingBottom: '10px' }}>
                <Skeleton animation="wave" width="60%" />
              </Typography>
              <Divider />
              <Typography variant="h4" component="p" className="point_cntct_hd">
                Point Of Contact{' '}
              </Typography>
              <Grid container spacing={2} style={{ paddingTop: '10px', paddingBottom: '20px' }}>
                <Grid item xs={12} sx={{ paddingBottom: '5px' }}>
                  <div style={{ border: '1px solid #fff', borderRadius: '6px' }}>
                    <div className="" style={{ display: 'inherit' }}>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                        Email :{' '}
                      </Typography>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                        <Skeleton animation="wave" />
                      </Typography>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} sx={{ padding: 0 }}>
                  <div style={{ border: '1px solid #fff', borderRadius: '6px' }}>
                    <div className="" style={{ display: 'inherit' }}>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                        Phone :{' '}
                      </Typography>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                        <Skeleton animation="wave" />
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Divider />
              {/* <Typography variant="h4" component="p" style={{ fontWeight: 'bold', paddingTop: 15 }}>
                            Organization Types{' '}
                          </Typography> */}
              <Grid container spacing={2} style={{ paddingTop: '10px', paddingBottom: '20px' }}>
                <Grid item xs={12} sx={{ padding: 0 }}>
                  <div style={{ border: '1px solid #fff', borderRadius: '6px' }}>
                    <div className="" style={{ display: 'inherit', paddingTop: '15px' }}>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                        Organization Type :{' '}
                      </Typography>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                        <Skeleton animation="wave" />
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} className="machineModalView">
          <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', height: '100%' }}>
            <div className="address_locationmain" style={{ background: '#6fd74b', mb: 2, padding: '10px 15px' }}>
              <Typography
                component="h4"
                variant="h3"
                // sx={{ mr: 1, pb: 1.8 }}
                sx={{ color: '#fff' }}
              >
                <Skeleton />
              </Typography>
            </div>

            <div className="organization_machine_name">
              <Typography variant="h4" component="p" style={{ fontWeight: 'bold' }}>
                <Skeleton />
              </Typography>
              <Typography variant="h4" component="p" style={{ fontWeight: 'unset', paddingTop: 10, paddingBottom: '10px' }}>
                <Skeleton animation="wave" width="60%" />
              </Typography>
              <Divider />
              <Typography variant="h4" component="p" className="point_cntct_hd">
                Point Of Contact{' '}
              </Typography>
              <Grid container spacing={2} style={{ paddingTop: '10px', paddingBottom: '20px' }}>
                <Grid item xs={12} sx={{ paddingBottom: '5px' }}>
                  <div style={{ border: '1px solid #fff', borderRadius: '6px' }}>
                    <div className="" style={{ display: 'inherit' }}>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                        Email :{' '}
                      </Typography>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                        <Skeleton animation="wave" />
                      </Typography>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} sx={{ padding: 0 }}>
                  <div style={{ border: '1px solid #fff', borderRadius: '6px' }}>
                    <div className="" style={{ display: 'inherit' }}>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                        Phone :{' '}
                      </Typography>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                        <Skeleton animation="wave" />
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Divider />
              {/* <Typography variant="h4" component="p" style={{ fontWeight: 'bold', paddingTop: 15 }}>
                            Organization Types{' '}
                          </Typography> */}
              <Grid container spacing={2} style={{ paddingTop: '10px', paddingBottom: '20px' }}>
                <Grid item xs={12} sx={{ padding: 0 }}>
                  <div style={{ border: '1px solid #fff', borderRadius: '6px' }}>
                    <div className="" style={{ display: 'inherit', paddingTop: '15px' }}>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                        Organization Type :{' '}
                      </Typography>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                        <Skeleton animation="wave" />
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} className="machineModalView">
          <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', height: '100%' }}>
            <div className="address_locationmain" style={{ background: '#6fd74b', mb: 2, padding: '10px 15px' }}>
              <Typography
                component="h4"
                variant="h3"
                // sx={{ mr: 1, pb: 1.8 }}
                sx={{ color: '#fff' }}
              >
                <Skeleton />
              </Typography>
            </div>

            <div className="organization_machine_name">
              <Typography variant="h4" component="p" style={{ fontWeight: 'bold' }}>
                <Skeleton />
              </Typography>
              <Typography variant="h4" component="p" style={{ fontWeight: 'unset', paddingTop: 10, paddingBottom: '10px' }}>
                <Skeleton animation="wave" width="60%" />
              </Typography>
              <Divider />
              <Typography variant="h4" component="p" className="point_cntct_hd">
                Point Of Contact{' '}
              </Typography>
              <Grid container spacing={2} style={{ paddingTop: '10px', paddingBottom: '20px' }}>
                <Grid item xs={12} sx={{ paddingBottom: '5px' }}>
                  <div style={{ border: '1px solid #fff', borderRadius: '6px' }}>
                    <div className="" style={{ display: 'inherit' }}>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                        Email :{' '}
                      </Typography>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                        <Skeleton animation="wave" />
                      </Typography>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} sx={{ padding: 0 }}>
                  <div style={{ border: '1px solid #fff', borderRadius: '6px' }}>
                    <div className="" style={{ display: 'inherit' }}>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                        Phone :{' '}
                      </Typography>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                        <Skeleton animation="wave" />
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Divider />
              {/* <Typography variant="h4" component="p" style={{ fontWeight: 'bold', paddingTop: 15 }}>
                            Organization Types{' '}
                          </Typography> */}
              <Grid container spacing={2} style={{ paddingTop: '10px', paddingBottom: '20px' }}>
                <Grid item xs={12} sx={{ padding: 0 }}>
                  <div style={{ border: '1px solid #fff', borderRadius: '6px' }}>
                    <div className="" style={{ display: 'inherit', paddingTop: '15px' }}>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                        Organization Type :{' '}
                      </Typography>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                        <Skeleton animation="wave" />
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} className="machineModalView">
          <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', height: '100%' }}>
            <div className="address_locationmain" style={{ background: '#6fd74b', mb: 2, padding: '10px 15px' }}>
              <Typography
                component="h4"
                variant="h3"
                // sx={{ mr: 1, pb: 1.8 }}
                sx={{ color: '#fff' }}
              >
                <Skeleton />
              </Typography>
            </div>

            <div className="organization_machine_name">
              <Typography variant="h4" component="p" style={{ fontWeight: 'bold' }}>
                <Skeleton />
              </Typography>
              <Typography variant="h4" component="p" style={{ fontWeight: 'unset', paddingTop: 10, paddingBottom: '10px' }}>
                <Skeleton animation="wave" width="60%" />
              </Typography>
              <Divider />
              <Typography variant="h4" component="p" className="point_cntct_hd">
                Point Of Contact{' '}
              </Typography>
              <Grid container spacing={2} style={{ paddingTop: '10px', paddingBottom: '20px' }}>
                <Grid item xs={12} sx={{ paddingBottom: '5px' }}>
                  <div style={{ border: '1px solid #fff', borderRadius: '6px' }}>
                    <div className="" style={{ display: 'inherit' }}>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                        Email :{' '}
                      </Typography>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                        <Skeleton animation="wave" />
                      </Typography>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} sx={{ padding: 0 }}>
                  <div style={{ border: '1px solid #fff', borderRadius: '6px' }}>
                    <div className="" style={{ display: 'inherit' }}>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                        Phone :{' '}
                      </Typography>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                        <Skeleton animation="wave" />
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Divider />
              {/* <Typography variant="h4" component="p" style={{ fontWeight: 'bold', paddingTop: 15 }}>
                            Organization Types{' '}
                          </Typography> */}
              <Grid container spacing={2} style={{ paddingTop: '10px', paddingBottom: '20px' }}>
                <Grid item xs={12} sx={{ padding: 0 }}>
                  <div style={{ border: '1px solid #fff', borderRadius: '6px' }}>
                    <div className="" style={{ display: 'inherit', paddingTop: '15px' }}>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                        Organization Type :{' '}
                      </Typography>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                        <Skeleton animation="wave" />
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} className="machineModalView">
          <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', height: '100%' }}>
            <div className="address_locationmain" style={{ background: '#6fd74b', mb: 2, padding: '10px 15px' }}>
              <Typography
                component="h4"
                variant="h3"
                // sx={{ mr: 1, pb: 1.8 }}
                sx={{ color: '#fff' }}
              >
                <Skeleton />
              </Typography>
            </div>

            <div className="organization_machine_name">
              <Typography variant="h4" component="p" style={{ fontWeight: 'bold' }}>
                <Skeleton />
              </Typography>
              <Typography variant="h4" component="p" style={{ fontWeight: 'unset', paddingTop: 10, paddingBottom: '10px' }}>
                <Skeleton animation="wave" width="60%" />
              </Typography>
              <Divider />
              <Typography variant="h4" component="p" className="point_cntct_hd">
                Point Of Contact{' '}
              </Typography>
              <Grid container spacing={2} style={{ paddingTop: '10px', paddingBottom: '20px' }}>
                <Grid item xs={12} sx={{ paddingBottom: '5px' }}>
                  <div style={{ border: '1px solid #fff', borderRadius: '6px' }}>
                    <div className="" style={{ display: 'inherit' }}>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                        Email :{' '}
                      </Typography>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                        <Skeleton animation="wave" />
                      </Typography>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} sx={{ padding: 0 }}>
                  <div style={{ border: '1px solid #fff', borderRadius: '6px' }}>
                    <div className="" style={{ display: 'inherit' }}>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                        Phone :{' '}
                      </Typography>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                        <Skeleton animation="wave" />
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Divider />
              {/* <Typography variant="h4" component="p" style={{ fontWeight: 'bold', paddingTop: 15 }}>
                            Organization Types{' '}
                          </Typography> */}
              <Grid container spacing={2} style={{ paddingTop: '10px', paddingBottom: '20px' }}>
                <Grid item xs={12} sx={{ padding: 0 }}>
                  <div style={{ border: '1px solid #fff', borderRadius: '6px' }}>
                    <div className="" style={{ display: 'inherit', paddingTop: '15px' }}>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                        Organization Type :{' '}
                      </Typography>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                        <Skeleton animation="wave" />
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={4}>
          <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}>
            <div className="address_locationmain" style={{ background: '#6fd74b', mb: 2, padding: '10px 15px' }}>
              <Typography
                component="h4"
                variant="h3"
                // sx={{ mr: 1, pb: 1.8 }}
                sx={{ color: '#fff' }}
              >
                <Skeleton />
              </Typography>
            </div>
            <div style={{ padding: '10px 15px 15px' }}>
              <Typography variant="h4" component="p" style={{ fontWeight: 'bold' }}>
                {/* Organization Name{' '} */}
                <Skeleton />
              </Typography>
              <Typography variant="h4" component="p" style={{ fontWeight: 'unset', paddingTop: 10, paddingBottom: '10px' }}>
                <Skeleton animation="wave" width="60%" />
              </Typography>
              <Divider />
              <Typography variant="h4" component="p" style={{ fontWeight: 'bold', paddingTop: 15 }}>
                Point Of Contact{' '}
              </Typography>
              <Grid container spacing={2} style={{ paddingTop: '10px', paddingBottom: '20px' }}>
                <Grid item xs={12} sx={{ padding: 0 }}>
                  <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}>
                    <div className="address_locationmain" style={{ margin: '2px', padding: '5px', display: 'inherit' }}>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                        Email :{' '}
                      </Typography>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                        <Skeleton animation="wave" />
                      </Typography>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} sx={{ padding: 0 }}>
                  <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}>
                    <div className="address_locationmain" style={{ margin: '2px', padding: '5px', display: 'inherit' }}>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                        Phone :{' '}
                      </Typography>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                        <Skeleton animation="wave" />
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}>
            <div className="address_locationmain" style={{ background: '#6fd74b', mb: 2, padding: '10px 15px' }}>
              <Typography
                component="h4"
                variant="h3"
                // sx={{ mr: 1, pb: 1.8 }}
                sx={{ color: '#fff' }}
              >
                <Skeleton />
              </Typography>
            </div>
            <div style={{ padding: '10px 15px 15px' }}>
              <Typography variant="h4" component="p" style={{ fontWeight: 'bold' }}>
                {/* Organization Name{' '} */}
                <Skeleton />
              </Typography>
              <Typography variant="h4" component="p" style={{ fontWeight: 'unset', paddingTop: 10, paddingBottom: '10px' }}>
                <Skeleton animation="wave" width="60%" />
              </Typography>
              <Divider />
              <Typography variant="h4" component="p" style={{ fontWeight: 'bold', paddingTop: 15 }}>
                Point Of Contact{' '}
              </Typography>
              <Grid container spacing={2} style={{ paddingTop: '10px', paddingBottom: '20px' }}>
                <Grid item xs={12} sx={{ padding: 0 }}>
                  <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}>
                    <div className="address_locationmain" style={{ margin: '2px', padding: '5px', display: 'inherit' }}>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                        Email :{' '}
                      </Typography>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                        <Skeleton animation="wave" />
                      </Typography>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} sx={{ padding: 0 }}>
                  <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}>
                    <div className="address_locationmain" style={{ margin: '2px', padding: '5px', display: 'inherit' }}>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                        Phone :{' '}
                      </Typography>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                        <Skeleton animation="wave" />
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={gridSpacing} style={{ paddingTop: '30px' }}>
        <Grid item xs={12}>
          <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}>
            <div className="address_locationmain" style={{ background: '#6fd74b', mb: 2, padding: '10px 15px' }}>
              <Typography component="h4" variant="h3" sx={{ color: '#fff' }}>
                <Skeleton animation="wave" />
              </Typography>
            </div>
            <Grid container spacing={gridSpacing} className="padding-address-new machineViewAddress">
              <Grid item lg={12} className="machineViewAddress">
                <div style={{ padding: '10px', width: '100%' }} className="addrs-new-bx-strt">
                  <Typography variant="h4" component="p" style={{ fontWeight: 'bold' }}>
                    <Skeleton animation="wave" />
                  </Typography>
                  <Typography variant="h4" component="p" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                    <Skeleton animation="wave" />
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={gridSpacing} style={{ paddingTop: '30px' }}>
        <Grid item xs={12}>
          <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}>
            <div className="address_locationmain" style={{ background: '#6fd74b', mb: 2, padding: '10px 15px' }}>
              <Typography
                component="h4"
                variant="h3"
                // sx={{ mr: 1, pb: 1.8 }}
                sx={{ color: '#fff' }}
              >
                <Skeleton animation="wave" />
              </Typography>
            </div>
            <Grid container spacing={gridSpacing} style={{ paddingTop: '10px', paddingBottom: '20px' }}>
              <Grid item xs={12} sm={6} md={4} lg={4} sx={{ padding: 0 }}>
                <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginLeft: 15, marginRight: 15 }}>
                  <div className="address_locationmain" style={{ margin: 5, padding: '5px', display: 'inherit' }}>
                    <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                      Machine Type :{' '}
                    </Typography>
                    <Typography variant="h4" component="sapn" style={{ fontWeight: 'unset', paddingTop: 10 }}>
                      <Skeleton animation="wave" />
                    </Typography>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4} sx={{ padding: 0 }}>
                <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginLeft: 15, marginRight: 15 }}>
                  <div className="address_locationmain" style={{ margin: 5, padding: '5px', display: 'inherit' }}>
                    <Typography variant="h4" component="p" style={{ fontWeight: 'bold' }}>
                      Nickname :{' '}
                    </Typography>
                    <Typography variant="h4" component="p" style={{ fontWeight: 'unset', paddingTop: 10 }}>
                      <Skeleton animation="wave" />
                    </Typography>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4} sx={{ padding: 0 }}>
                <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginLeft: 15, marginRight: 15 }}>
                  <div className="address_locationmain" style={{ margin: 5, padding: '5px', display: 'inherit' }}>
                    <Typography variant="h4" component="p" style={{ fontWeight: 'bold' }}>
                      Machine Model :{' '}
                    </Typography>
                    <Typography variant="h4" component="p" style={{ fontWeight: 'unset', paddingTop: 10 }}>
                      <Skeleton animation="wave" />
                    </Typography>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4} sx={{ padding: 0 }}>
                <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginLeft: 15, marginRight: 15 }}>
                  <div className="address_locationmain" style={{ margin: 5, padding: '5px', display: 'inherit' }}>
                    <Typography variant="h4" component="p" style={{ fontWeight: 'bold' }}>
                      Machine Software :{' '}
                    </Typography>
                    <Typography variant="h4" component="p" style={{ fontWeight: 'unset', paddingTop: 10 }}>
                      <Skeleton animation="wave" />
                    </Typography>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ padding: 0 }}>
                <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginLeft: 15, marginRight: 15 }}>
                  <div className="address_locationmain" style={{ margin: 5, padding: '5px', display: 'inherit' }}>
                    <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                      Bins :{' '}
                    </Typography>
                    <Typography variant="h4" component="p" style={{ fontWeight: 'unset', paddingTop: 10 }}>
                      <Skeleton animation="wave" />
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={gridSpacing} style={{ paddingTop: '30px' }}>
        <Grid item xs={12}>
          <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}>
            <div className="address_locationmain" style={{ background: '#6fd74b', mb: 2, padding: '10px 15px' }}>
              <Typography
                component="h4"
                variant="h3"
                // sx={{ mr: 1, pb: 1.8 }}
                sx={{ color: '#fff' }}
              >
                <Skeleton animation="wave" />
              </Typography>
            </div>
            <Grid container spacing={gridSpacing} style={{ paddingTop: '10px', paddingBottom: '20px' }}>
              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ padding: 0 }}>
                <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginLeft: 15, marginRight: 15 }}>
                  <div className="address_locationmain" style={{ margin: 5, padding: '5px', display: 'inherit' }}>
                    <Typography variant="h4" component="p" style={{ fontWeight: 'bold' }}>
                      Machine API Key :{' '}
                    </Typography>
                    <Typography variant="h4" component="p" style={{ fontWeight: 'unset', paddingTop: 10 }}>
                      <Skeleton animation="wave" />
                    </Typography>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ padding: 0 }}>
                <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginLeft: 15, marginRight: 15 }}>
                  <div className="address_locationmain" style={{ margin: 5, padding: '5px', display: 'inherit' }}>
                    <Typography variant="h4" component="p" style={{ fontWeight: 'bold' }}>
                      Machine Serial Number :{' '}
                    </Typography>
                    <Typography variant="h4" component="p" style={{ fontWeight: 'unset', paddingTop: 10 }}>
                      <Skeleton animation="wave" />
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>

      <div style={{ paddingTop: '25px' }}>
        <Item elevation={0} style={{ border: '1px solid #b4b4b4', borderRadius: 18 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Typography variant="p" component="span" style={{ fontWeight: 'bold' }}>
                <Skeleton animation="wave" />
              </Typography>
              <Typography variant="p" component="span" style={{ fontWeight: 'bold' }}></Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Typography variant="p" component="span" style={{ fontWeight: 'bold' }}>
                <Skeleton animation="wave" />
              </Typography>
              <Typography variant="p" component="span" style={{ fontWeight: 'bold' }}></Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Typography variant="p" component="span" style={{ fontWeight: 'bold' }}>
                <Skeleton animation="wave" />
              </Typography>
              <Typography variant="p" component="span" style={{ fontWeight: 'bold' }}></Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Typography variant="p" component="span" style={{ fontWeight: 'bold' }}>
                <Skeleton animation="wave" />
              </Typography>
              <Typography variant="p" component="span" style={{ fontWeight: 'bold' }}></Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box sx={{ width: '100%' }}>
                <Stepper activeStep={1} alternativeLabel>
                  {
                    // If loading, show skeletons instead of step labels
                    stepsLoading.map((label, index) => (
                      <Step key={index}>
                        <StepLabel>
                          <Skeleton variant="text" width="100%" />
                        </StepLabel>
                      </Step>
                    ))
                  }
                </Stepper>
              </Box>
            </Grid>
          </Grid>
        </Item>
      </div>
    </>
  );
};

export default MachineViewModalSkeleton;
