import React from 'react';
import { Form, Formik } from 'formik';
import TicketFilterForm from './TicketFilterForm';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
const TicketFilter = ({ setFilterObj, filterObj, isExistingFilter, permissionList }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const machineIdFromQuery = queryParams.get('machineId') || 'Select Machine Id'; // Default if not in URL
  const machineTicketFilters = permissionList?.view?.filters ?? [];

  const validationSchema = Yup.object().shape(
    {
      fromDate: Yup.string()

        .notRequired()
        .when(['toDate'], {
          is: (dep_1, dep_2) => dep_1 || dep_2,
          then: Yup.string().required('From Date is required'),
          otherwise: Yup.string().notRequired()
        }),
      // .nullable()
      toDate: Yup.string()

        .notRequired()
        .when(['fromDate'], {
          is: (dep_1, dep_2) => dep_1 || dep_2,
          then: Yup.string().required('To Date is required'),
          otherwise: Yup.string().notRequired()
        })
      // .nullable()
    },
    ['toDate', 'fromDate']
  );

  return (
    <>
      <Formik
        initialValues={{
          subject: '',
          // ticketreason: '',
          status: 'All',
          ticketNo: '',
          fromDate: '',
          toDate: '',
          // machineId: 'Select Machine Id'
          machineId: machineIdFromQuery,
          ticketType: 'All'
          // machineId: []
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          setFilterObj({
            subject: values?.subject || null,
            // ticketreason: values?.ticketreason || null,
            status: values?.status === 'All' ? '' : values?.status,
            ticketNo: values?.ticketNo || null,
            // fromDate: values?.fromDate || null,
            // toDate: values?.toDate || null
            fromDate: values?.fromDate ? dayjs(values?.fromDate).format('DD/MM/YYYY') : '',
            toDate: values?.toDate ? dayjs(values?.toDate).format('DD/MM/YYYY') : '',
            machineId: values?.machineId === 'Select Machine Id' ? '' : values?.machineId,
            ticketType: values?.ticketType === 'All' ? '' : values?.ticketType
            // machineId: values?.machineId?.id || null
          });
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, setTouched, resetForm }) => (
          <Form onSubmit={handleSubmit}>
            <TicketFilterForm
              errors={errors}
              handleBlur={handleBlur}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              touched={touched}
              values={values}
              setFieldValue={setFieldValue}
              setTouched={setTouched}
              resetForm={resetForm}
              filterObj={filterObj}
              // handleCloseFilter={handleCloseFilter}
              setFilterObj={setFilterObj}
              isExistingFilter={isExistingFilter}
              permissionsList={permissionList}
              machineTicketFilters={machineTicketFilters}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default TicketFilter;
