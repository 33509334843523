import { useEffect, useRef } from 'react';
/**
 * How To Use
 *
 * Attach ref to field
 * * inputRef={(el) => setFieldRef('pre_drive_cost_price', el)}
 *
 * Initialize the custom hook
 * * const setFieldRef = useFocusOnError(errors, touched, isSubmitting);
 */
export const useFocusOnError = (errors, touched, isSubmitting) => {
  const refs = useRef({});

  useEffect(() => {
    // Find the first field with an error and focus on it
    try {
      if (isSubmitting && errors && touched) {
        for (const field in errors) {
          if (errors[field] && touched[field] && refs.current[field]) {
            refs.current[field].focus();
            break; // Only focus the first error
          }
        }
      }
    } catch (error) {
      console.log('useFocusOnError error:', error);
    }
  }, [isSubmitting, errors, touched]);

  // Return refs for each field
  const setFieldRef = (name, ref) => {
    refs.current[name] = ref || null;
  };
  // Return the ref setter function and the refs object
  return {
    setFieldRef,
    fieldRef: refs.current
  };

  // return setFieldRef;
};
