import React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

export function OrganizationTypeToggleButton({ organizationTypesArray, setFieldValue, values, isStatic = false }) {
  const [alignment, setAlignment] = React.useState(
    organizationTypesArray && organizationTypesArray.length ? organizationTypesArray[0] : ''
  );

  React.useEffect(() => {
    /*console.log(
      'useEffect | OrganizationTypeToggleButton : ',
      values.organizationType || (organizationTypesArray && organizationTypesArray.length),
      'values.organizationType : ',
      values.organizationType,
      ' | organizationTypesArray : ',
      organizationTypesArray,
      'nabin'
    );*/
    if (values.organizationType || organizationTypesArray) {
      setAlignment(values.organizationType ?? (organizationTypesArray && organizationTypesArray.length ? organizationTypesArray[0] : ''));
      /*setFieldValue(
        'organizationType',
        values.organizationType || (organizationTypesArray && organizationTypesArray.length ? organizationTypesArray[0] : '')
      );*/
    }
  }, [values.organizationType, organizationTypesArray]);

  const handleChange = (event, newValue) => {
    if (newValue) {
      setFieldValue('organizationType', newValue);
      setAlignment(newValue);
    }
  };
  // console.log('organizationTypesArray OrganizationTypeToggleButton', organizationTypesArray, values.organizationType);
  return (
    <ToggleButtonGroup color="primary" value={alignment} exclusive onChange={handleChange} aria-label="Platform" disabled={isStatic}>
      {organizationTypesArray &&
        organizationTypesArray.length > 0 &&
        organizationTypesArray.map((val, index) => {
          return (
            <ToggleButton key={index} value={val}>
              {val}
            </ToggleButton>
          );
        })}
    </ToggleButtonGroup>
  );
}
