/* eslint-disable no-restricted-imports */
import {
  // Autocomplete,
  // Autocomplete,
  Button,
  // DialogActions,
  // DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  // InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
// import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import dayjs from 'dayjs';
import React from 'react';
import { gridSpacing } from 'store/constant';
// import { useTheme } from '@emotion/react';
// import { useEffect } from 'react';
// import { useGetInvoiceListByRecyclerForOrg } from 'hooks/useInvoiceHooks';
import { useTheme } from '@emotion/react';
// import { useState } from 'react';
// import { useGetRecyclerAllListInvoice } from 'hooks/useRecycleHooks';

// import { useState } from 'react';
// import { ErrorMessage } from 'formik';
// const FormikAutocomplete = ({ field, form, options, ...props }) => {
//   const { name } = field;
//   const { setFieldValue, touched, errors, values } = form;

//   // Handle selection change and update both `raised_org` and `org_data`
//   const handleOrgChange = (event, value) => {
//     setFieldValue('org_data', value); // Set org_data to selected object
//     setFieldValue('raised_org', value?.id || '');
//     setFieldValue('raised_org_type', value?.type || '');
//   };

//   return (
//     <Autocomplete
//       id="autocomplete"
//       {...props}
//       options={options ?? []}
//       groupBy={(option) => option?.type}
//       value={values.org_data || null} // Use `null` to clear selection when value is empty
//       getOptionLabel={(option) => option?.name || ''}
//       isOptionEqualToValue={(option, value) => option?.id === value?.id}
//       onChange={handleOrgChange}
//       renderInput={(params) => (
//         <TextField
//           {...params}
//           label="Select Organization"
//           variant="outlined"
//           placeholder="Select Organization"
//           error={touched[name] && Boolean(errors[name])}
//           helperText={touched[name] && errors[name]?.message}
//           name={name}
//           className="organization-chip"
//           id="notes-recycler-textarea"
//         />
//       )}
//       renderGroup={(params) => (
//         <li key={params.key}>
//           <div style={{ fontWeight: 'bold', paddingLeft: '12px' }}>{params.group}</div>
//           <ul style={{ padding: 0, listStyle: 'none' }}>{params.children}</ul>
//         </li>
//       )}
//     />
//   );
// };
const FilterForm = ({
  errors,
  handleBlur,
  handleChange,
  // handleSubmit,
  touched,
  values,

  resetForm,
  // filterObj,
  // handleCloseFilter,
  setFilterObj,
  invoiceFilters
  // isExistingFilter
}) => {
  // const { data: allRecyclerList } = useGetRecyclerAllListInvoice();

  // const handleRecyclerChange = (event) => {
  //   setFieldValue('raised_org', event.target.value);
  //   setFieldValue('raised_org_type', 'Recycler');
  // };
  const theme = useTheme();
  const exceptThisSymbols = ['e', 'E', '+', '-', '#', '%', '^', '.'];

  const sowField = (filter, index) => {
    if (filter?.show)
      if (filter?.id == 'invoiceNumber')
        return (
          <Grid
            key={index}
            container
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className="select-machine-id inv-number select-machine"
            // className="select-job-enterprise select-inv-number inv-number inv-number-new"
            alignItems="center"
          >
            <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
              Invoice Number
            </Typography>
            <FormControl fullWidth error={Boolean(touched.invoiceNumber && errors.invoiceNumber)}>
              <TextField
                placeholder=" Search Invoice Number"
                variant="outlined"
                type="number"
                fullWidth
                name="invoiceNumber"
                value={values?.invoiceNumber}
                onChange={handleChange}
                error={Boolean(touched.invoiceNumber && errors.invoiceNumber)}
                // onPaste={(e) => {
                //   e.preventDefault();
                //   return false;
                // }}
                // onCopy={(e) => {
                //   e.preventDefault();
                //   return false;
                // }}
                onKeyDown={(e) => {
                  exceptThisSymbols.includes(e.key) && e.preventDefault();
                }}
              />
            </FormControl>
            {touched.invoiceNumber && errors.invoiceNumber && (
              <FormHelperText
                error
                sx={{
                  color: 'red'
                }}
              >
                {errors.invoiceNumber}
              </FormHelperText>
            )}
          </Grid>
        );
      else if (filter?.id == 'status') {
        return (
          <Grid
            key={index}
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            // className="select-job-enterprise select_separate2"
            className="select-status-filter"
          >
            <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
              Status
            </Typography>
            <FormControl fullWidth variant="outlined">
              <Select
                // label="Status"
                value={values?.status}
                onChange={handleChange}
                name="status"
                inputProps={{ 'aria-label': 'Without label' }}
                labelId="retailer-primary-machine-label"
              >
                <MenuItem value="All" defaultChecked>
                  All
                </MenuItem>
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="Paid">Paid</MenuItem>
                <MenuItem value="AwaitingClearance">Awaiting Clearance</MenuItem>
                <MenuItem value="Cancelled">Cancelled</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        );
      } else if (filter?.id == 'amount') {
        return (
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className="select-machine-id inv-number select-machine"
            alignItems={'center'}
            key={index}
          >
            <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
              Amount
            </Typography>
            <TextField
              id="outlined-amount"
              type="number"
              name="amt_value"
              placeholder="Search Amount"
              fullWidth
              value={values.amt_value}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.amt_value && errors.amt_value)}
              helperText={touched.amt_value && errors.amt_value ? errors.amt_value : ''}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <span style={{ fontSize: '1rem', fontWeight: 500, color: theme.palette.text.secondary }}>$</span>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{
                      fontSize: '10px',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    {/* <span style={{ fontSize: '0.9rem', fontWeight: 400, color: theme.palette.text.secondary, marginRight: 6 }}>
                    /user per month
                  </span> */}

                    <FormControl size="small">
                      <Select
                        id="outlined-amt_type"
                        name="amt_type"
                        value={values.amt_type}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                      >
                        <MenuItem value={'<='}>{'<='}</MenuItem>
                        <MenuItem value={'>='}>{'>='}</MenuItem>
                        <MenuItem value={'=='}>{'=='}</MenuItem>
                      </Select>
                    </FormControl>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        );
      } else if (filter?.id == 'invoiceCategory') {
        return (
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            // className="select-job-enterprise select_separate2"
            className="select-status-filter"
            key={index}
          >
            <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
              Invoice Category
            </Typography>
            <FormControl fullWidth variant="outlined">
              <Select
                // label="Status"
                value={values?.invoiceCategory}
                onChange={handleChange}
                name="invoiceCategory"
                inputProps={{ 'aria-label': 'Without label' }}
                labelId="retailer-primary-machine-label"
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="Raised">Raised</MenuItem>
                <MenuItem value="Received">Received</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        );
      }
  };
  return (
    <>
      {' '}
      {/* <DialogContent className="choose_option_modal"> */}
      <Grid
        key={'organizationFilter'}
        container
        spacing={gridSpacing}
        sx={{ marginBottom: '20px', alignItems: 'center', padding: '0px 20px' }}
      >
        {invoiceFilters?.map((filter, index) => sowField(filter, index))}
      </Grid>
      <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'right', paddingBottom: '20px', paddingRight: '20px' }}>
        <Button
          variant="outlined"
          type="reset"
          color="error"
          onClick={() => {
            resetForm();
            setFilterObj({
              amt_value: '',
              status: '',
              amt_type: '',
              invoiceNumber: '',
              invoiceCategory: ''
            });
          }}
        >
          Clear all
        </Button>
        <div style={{ marginLeft: '10px' }}>
          {/* {errors?.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors?.submit}</FormHelperText>
            </Box>
          )} */}
          <Box sx={{ m: 0, position: 'relative' }}>
            <Button
              // fullWidth
              variant="contained"
              type="submit"
              style={{
                color: '#fff'
              }}
              color="primary"
              //   disabled={isLoadingUpdateInvoiceStatus}
            >
              Search
            </Button>
          </Box>
        </div>
      </div>
    </>
  );
};

export default FilterForm;
