import React from 'react';
import { useGetSubcriptionPlansForMachineList } from 'hooks/useMachineHooks';
import PricingTable from './PricingTable';
import SubcriptionSliderSkeleton from 'ui-component/cards/Skeleton/SubcriptionSliderSkeleton';
import { Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Skeleton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const EmployeeSubscription = ({ passedObject, callFrom, openViewModal, setOpenViewModal }) => {
  //   const [isLoading, setLoading] = useState(true);
  // console.log(
  //   'EmployeeSubscription - openViewModal',
  //   openViewModal,
  //   ' | setOpenViewModal : ',
  //   setOpenViewModal,
  //   ' | callFrom : ',
  //   callFrom,
  //   ' | passedObject : ',
  //   passedObject
  // );
  const { data: subcriptionList, isLoading: isLoadingSubcriptionList } = useGetSubcriptionPlansForMachineList({});

  const handleClose = () => {
    setOpenViewModal((state) => ({ ...state, open: false, machineId: null, openSubscription: false, machineSubFromEmployee: null }));
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'xl'} // Set this to "xs" to make the dialog smaller
        onClose={handleClose}
        open={openViewModal}
      >
        <DialogTitle
          id="alert-dialog-title"
          variant="span"
          scroll={'papper'}
          style={{
            textTransform: 'capitalize',
            fontSize: '20px' // Adjust the font size for the title
          }}
        >
          <Grid
            container
            spacing={0}
            sx={{
              marginTop: '0px',
              padding: {
                xs: '10px 0 0 0', // xs and sm
                sm: '10px 0 0 0' // sm
              }
            }}
          >
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8} sx={{ wordWrap: 'break-word' }}>
              {isLoadingSubcriptionList ? <Skeleton variant="rectangular" width={500} height={35} /> : `View Machine Subscription Details`}
              {'  '}
            </Grid>
          </Grid>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500]
          })}
        >
          <CloseIcon color="error" />
        </IconButton>
        <Divider />
        <DialogContent
          dividers
          className="choose_option_modal"
          style={{
            padding: '8px 16px', // Reduce padding to make the dialog more compact
            boxShadow: 'unset',
            border: 'unset'
          }}
        >
          {isLoadingSubcriptionList ? (
            <>
              <SubcriptionSliderSkeleton />
            </>
          ) : (
            <PricingTable value={passedObject} subcriptionList={subcriptionList} callFrom={callFrom} setOpenViewModal={setOpenViewModal} />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EmployeeSubscription;
