/* eslint-disable no-unused-vars */
import React from 'react';
import { Button, Typography, Grid, Paper, List, ListItem, ListItemText, ListItemIcon, Box } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SubscriptionPlansCarousel from './SubscriptionPlansCarousel';
import ContactUsDialog from './ContactUsDialog';
import { useSubscriptionsActiveUpdate } from 'hooks/useSubscriptionsHooks';
import { setToast, toastConfig } from 'utils/commonUtil';

const PricingTable = ({ value, subcriptionList, callFrom, setOpenViewModal }) => {
  const navigator = useNavigate();
  const [openContactUs, setOpenContactUs] = React.useState(false);
  const { mutate: subcriptionActive, loading: isLoadingActive } = useSubscriptionsActiveUpdate();
  const callInvoiceScreen = (id = '', setIsSubscriptionBtn) => {
    const payload = {
      machineId: value?.machineId || null,
      planId: id || null
    };
    // console.log('callInvoiceScreen | id', id, value, ' | payload : ', payload);
    subcriptionActive(payload, {
      onSuccess: (res) => {
        // console.log('res - submitData - Employee : ', res);
        if (res?.status === 200) {
          // toastConfig.type = 'success';
          // setToast(toastConfig, res?.data.message);
          // console.log('subcriptionActive : ', res);
          const paymentLink = res?.data?.data?.paymentLink;
          window.open(paymentLink, '_blank', 'noopener');
          if (!callFrom) {
            navigator('/dashboard/machines?viewMachineID=' + value?.machineId);
          } else if (callFrom && callFrom === 'Employee') {
            //Pass
            // console.log('call from  : ', callFrom, ' | machineId : ', value?.machineId);
            setOpenViewModal((state) => ({
              ...state,
              open: false,
              machineId: null,
              openSubscription: false,
              machineSubFromEmployee: null
            }));
          }
        } else {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        }
        setIsSubscriptionBtn((prev) => ({ show: false, id: null }));
      },
      onError: (error) => {
        toastConfig.type = 'error';
        setToast(toastConfig, error?.response?.data?.error);
        setIsSubscriptionBtn((prev) => ({ show: false, id: null }));
      }
    });
    // navigate('/dashboard/machines/create-invoice', {
    //   state: {
    //     machineObj: value,
    //     subId: id,
    //     subObj: subcriptionList && subcriptionList.length > 0 ? subcriptionList.filter((sub) => sub.id === id) : null
    //   }
    // });
  };

  // const carouselItems = [<div key="1">Slide 1 Content</div>, <div key="2">Slide 2 Content</div>, <div key="2">Slide 3 Content</div>];
  return (
    <>
      <Grid container spacing={3} justifyContent="center" sx={{ mt: 'unset' }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {/* <Typography variant="h2" align="left" sx={{ fontWeight: 'bold', paddingBottom: 'unset', color: '#000' }}>
            Choose Subscription
          </Typography> */}
          <Typography className="skip-heading" component="h4" variant="h2" sx={{ mb: 2 }}>
            Choose Subscription
          </Typography>
        </Grid>
        {/* <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box
            className="skip-btn"
            sx={{
              position: 'absolute', // Position the button absolutely
              right: 20, // Space from the right edge of the page
              top: '5%', // Vertically center the button
              // transform: 'translateY(-50%)', // To perfectly center the button
              zIndex: 1000 // Ensure it is on top of other elements
            }}
          >
            <Button
              onClick={() => callInvoiceScreen('')}
              variant="contained"
              color="primary"
              endIcon={<SkipNextIcon />}
              sx={{ color: '#fff', fontWeight: 'unset', marginBottom: '20px' }}
            >
              Skip Subscription
            </Button>
          </Box>
        </Grid> */}
      </Grid>

      <SubscriptionPlansCarousel
        subcriptionList={subcriptionList}
        callInvoiceScreen={callInvoiceScreen}
        setOpenContactUs={setOpenContactUs}
        openContactUs={openContactUs}
        value={value}
      />

      {openContactUs && <ContactUsDialog open={openContactUs} setOpen={setOpenContactUs} machineObj={value} />}
    </>
  );
};

export default PricingTable;
