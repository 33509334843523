import { Button, CardContent, CardHeader, Divider, FormControlLabel, Grid, IconButton, Paper, Switch } from '@mui/material';
import { FieldArray } from 'formik';
import React from 'react';
import AddNewTableHeaderModal from './add-new-table-header';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { useEffect } from 'react';

const ModifyPermissionSection = ({
  initialValues,
  renderPermissionCards,
  setAddTableHeader,
  editableData,
  setEditableData,
  setFieldValue,
  values,
  addTableHeader,
  setValues,
  permissionList,
  isLoadingPermissionList,
  isSaving
}) => {
  useEffect(() => {
    // Only update values if data is loaded and available
    if (permissionList && !isLoadingPermissionList) {
      setValues({
        showAdd: permissionList?.showAdd || initialValues.showAdd,
        showEdit: permissionList?.showEdit || initialValues.showEdit,
        showView: permissionList?.showView || initialValues.showView,
        showModal: permissionList?.showModal || initialValues.showModal,
        add: permissionList?.add || initialValues.add,
        edit: permissionList?.edit || initialValues.edit,
        filters: permissionList?.filters || initialValues.filters,
        tableHeaders: permissionList?.tableHeaders || initialValues.tableHeaders,
        modal: permissionList?.modal || initialValues.modal,
        newTableHeader: {
          id: '',
          label: '',
          changeState: false,
          sortField: false
        }
      });
    }
  }, [permissionList, isLoadingPermissionList, setValues]);
  return (
    <>
      {/* Filters Section */}
      <Paper variant="outlined" sx={{ mb: 2 }}>
        <CardHeader title="Filters" />

        <Divider />
        <CardContent>
          <FieldArray name="filters" render={(arrayHelpers) => renderPermissionCards('filters', values, arrayHelpers)} />
        </CardContent>
      </Paper>

      {/* Table Headers Section */}
      <Paper variant="outlined" sx={{ mb: 2 }}>
        <CardHeader
          title="Table Headers"
          action={
            <IconButton disabled={isSaving} onClick={() => setAddTableHeader(true)}>
              <PlaylistAddIcon color="primary" />
            </IconButton>
          }
        />
        {values?.tableHeaders?.length > 0 && (
          <>
            <Divider />
            <CardContent>
              <FieldArray name="tableHeaders" render={(arrayHelpers) => renderPermissionCards('tableHeaders', values, arrayHelpers)} />
              {/* <AddNewTableHeaderModal
                editableData={editableData}
                setEditableData={setEditableData}
                setFieldValue={setFieldValue}
                values={values}
                open={addTableHeader}
                setOpen={setAddTableHeader}
              /> */}
            </CardContent>
          </>
        )}
      </Paper>

      {/* Add Options Section */}
      {values?.add?.length > 0 && (
        <Paper variant="outlined" sx={{ mb: 2 }}>
          <CardHeader
            title="Add Options"
            action={
              <FormControlLabel
                control={<Switch checked={values.showAdd} onChange={() => setFieldValue('showAdd', !values.showAdd)} />}
                label="Show Add"
              />
            }
          />
          {values.showAdd && (
            <>
              <Divider />
              <CardContent>
                <FieldArray name="add" render={(arrayHelpers) => renderPermissionCards('add', values, arrayHelpers)} />
              </CardContent>
            </>
          )}
        </Paper>
      )}

      {/* Edit Options Section */}
      {values?.edit?.length > 0 && (
        <Paper variant="outlined" sx={{ mb: 2 }}>
          <CardHeader
            title="Edit Options"
            action={
              <FormControlLabel
                control={<Switch checked={values.showEdit} onChange={() => setFieldValue('showEdit', !values.showEdit)} />}
                label="Show Edit"
              />
            }
          />
          {values.showEdit && (
            <>
              <Divider />
              <CardContent>
                <FieldArray name="edit" render={(arrayHelpers) => renderPermissionCards('edit', values, arrayHelpers)} />
              </CardContent>
            </>
          )}
        </Paper>
      )}

      {/* Modal Section */}
      {values?.modal?.length > 0 && (
        <Paper variant="outlined" sx={{ mb: 2 }}>
          <CardHeader
            title="Modal"
            action={
              <FormControlLabel
                control={<Switch checked={values.showModal} onChange={() => setFieldValue('showModal', !values.showModal)} />}
                label="Show Modal"
              />
            }
          />
          {values.showModal && (
            <>
              <Divider />
              <CardContent>
                <FieldArray name="modal" render={(arrayHelpers) => renderPermissionCards('modal', values, arrayHelpers)} />
              </CardContent>
            </>
          )}
        </Paper>
      )}

      {/* Submit Button */}
      <Grid container justifyContent="center" sx={{ mt: 3 }}>
        <Button disabled={isSaving} type="submit" variant="contained" color="primary" sx={{ color: '#fff' }}>
          {isSaving ? 'Calibrate' : 'Calibrating'}
        </Button>
      </Grid>
      {addTableHeader && (
        <AddNewTableHeaderModal
          editableData={editableData}
          setEditableData={setEditableData}
          setFieldValue={setFieldValue}
          values={values}
          open={addTableHeader}
          setOpen={setAddTableHeader}
        />
      )}
    </>
  );
};

export default ModifyPermissionSection;
