// material-ui
/* eslint-disable no-unused-vars */
import { useTheme, styled } from '@mui/material/styles';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Skeleton,
  Stack,
  Typography
} from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

// assets
import { IconBrandTelegram, IconBuildingStore, IconMailbox, IconPhoto } from '@tabler/icons';
import User1 from 'assets/images/users/user-round.svg';
import { useGetNotificationList } from 'hooks/useNotificationHooks';
import { useState } from 'react';
import { useEffect } from 'react';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  padding: 16,
  '&:hover': {
    background: theme.palette.primary.light
  },
  '& .MuiListItem-root': {
    padding: 0
  }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //
const NotificationListAll = () => {
  const {
    data: allNotificationList,
    isPending: isAllNotificationListLoader,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    totalCount // Assuming total count is available here from the API
  } = useGetNotificationList({
    limit: 10
  });

  const theme = useTheme();

  const chipSX = {
    height: 24,
    padding: '0 6px'
  };

  const chipErrorSX = {
    ...chipSX,
    marginRight: '5px'
  };

  const chipWarningSX = {
    ...chipSX
  };

  const chipSuccessSX = {
    ...chipSX,
    color: theme.palette.success.dark,
    backgroundColor: theme.palette.success.light,
    height: 28
  };

  // Disable Load More button if total notifications are loaded
  // const isLoadMoreDisabled =
  //   allNotificationList?.pages?.reduce((acc, page) => acc + (page?.data?.notifications?.length || 0), 0) >= totalCount || !hasNextPage;
  const isLoadMoreDisabled =
    !hasNextPage || // No more pages available
    isFetchingNextPage || // Prevent multiple clicks during fetching
    allNotificationList?.pages?.reduce((acc, page) => acc + (page?.data?.notifications?.length || 0), 0) >= totalCount;

  // console.log('🚀 ~ NotificationListAll ~ isLoadMoreDisabled:', isLoadMoreDisabled);

  return (
    <>
      <List
        sx={{
          width: '100%',
          py: 0,
          borderRadius: '10px',
          [theme.breakpoints.down('md')]: {},
          '& .MuiListItemSecondaryAction-root': {
            top: 22
          },
          '& .MuiDivider-root': {
            my: 0
          },
          '& .list-container': {
            pl: 7
          }
        }}
      >
        {isAllNotificationListLoader ? (
          <>
            <ListItemWrapper>
              <ListItem alignItems="center">
                <ListItemAvatar>
                  <Avatar variant="circular" />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Grid item xs={12}>
                      <Skeleton variant="rounded" width={100} height={20} />
                    </Grid>
                  }
                  secondary={
                    <Typography variant="caption" display="block">
                      ...
                    </Typography>
                  }
                />
                <ListItemSecondaryAction>
                  <Grid container justifyContent="flex-end">
                    <Grid item xs={12}>
                      <Typography variant="caption" display="block" gutterBottom>
                        <FiberManualRecordIcon
                          sx={{
                            fontSize: 15,
                            color: '#d3d3d3',
                            animation: 'blink 1s infinite',
                            '@keyframes blink': {
                              '0%': { opacity: 1 },
                              '50%': { opacity: 0 },
                              '100%': { opacity: 1 }
                            }
                          }}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItemSecondaryAction>
              </ListItem>
              <Grid container direction="column" className="list-container">
                <Grid item xs={12}>
                  <Stack spacing={1} sx={{ alignItems: 'left' }}>
                    <Stack direction="row" spacing={1}>
                      <Grid item xs={12} sm={12} md={3} lg={3}>
                        <Skeleton variant="rounded" width={100} height={20} />
                      </Grid>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
              <Divider />
            </ListItemWrapper>
          </>
        ) : (
          <>
            {allNotificationList?.pages?.map((page) =>
              page?.data?.notifications?.map((item) => (
                <ListItemWrapper key={item.id}>
                  <ListItem alignItems="center">
                    <ListItemAvatar>
                      <Avatar alt="Notification Image" src={item?.image} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={<Typography variant="subtitle1">{item?.title}</Typography>}
                      secondary={
                        <Typography variant="caption" display="block">
                          {item?.timeAgo}
                        </Typography>
                      }
                    />
                    <ListItemSecondaryAction>
                      <Grid container justifyContent="flex-end">
                        <Grid item xs={12}>
                          <Typography variant="caption" display="block" gutterBottom>
                            {item?.unread && (
                              <FiberManualRecordIcon
                                sx={{
                                  fontSize: 15,
                                  color: '#6fd74b',
                                  marginTop: '50px',
                                  animation: 'blink 1s infinite',
                                  '@keyframes blink': {
                                    '0%': { opacity: 1 },
                                    '50%': { opacity: 0 },
                                    '100%': { opacity: 1 }
                                  }
                                }}
                              />
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Grid container direction="column" className="list-container" style={{ marginBottom: '10px' }}>
                    <Grid item xs={12}>
                      <Stack spacing={1} sx={{ alignItems: 'left' }}>
                        <Stack direction="row" spacing={1}>
                          <Chip
                            label={item?.tag}
                            sx={{
                              backgroundColor: item?.tagColor?.bgColor,
                              color: item?.tagColor?.primaryColor,
                              borderColor: item?.tagColor?.primaryColor,
                              borderWidth: '1px'
                            }}
                          />
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Divider />
                </ListItemWrapper>
              ))
            )}
          </>
        )}

        {/* Load More Button */}
      </List>
      {hasNextPage && !isLoadMoreDisabled && (
        <Button onClick={() => fetchNextPage()} disabled={isLoadMoreDisabled} variant="contained" fullWidth sx={{ mt: 2, color: '#fff' }}>
          {isFetchingNextPage ? 'Loading...' : 'Load More'}
        </Button>
      )}
    </>
  );
};

export default NotificationListAll;
