// import { Dialog, DialogTitle, IconButton } from '@mui/material';
// import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
// import CloseIcon from '@mui/icons-material/Close';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

import React from 'react';
// import { gridSpacing } from 'store/constant';

import { Form, Formik } from 'formik';
import FilterForm from './filterFom';

// import dayjs from 'dayjs';
import * as Yup from 'yup';

const InvoiceFliter = ({ setFilterObj, filterObj, isExistingFilter, permissionsList }) => {
  const invoiceFilters = permissionsList?.view?.filters ?? [];

  return (
    <>
      <Formik
        initialValues={{
          invoiceCategory: 'All',
          amt_value: '',
          status: 'All',
          amt_type: '==',
          invoiceNumber: ''
        }}
        validationSchema={Yup.object().shape({
          // amt_type: Yup.string().oneOf(['>=', '<='], 'Invalid amount type').nullable(), // Not required, allows null values
          // amt_type: Yup.string().oneOf(['>=', '<='], 'Invalid amount type').nullable().optional(),
          amt_type: Yup.string().notRequired(),
          amt_value: Yup.number().typeError('Amount value must be a valid number').positive('Amount value must be positive').notRequired()
        })}
        onSubmit={async (value) => {
          // console.log('dsd', value);
          // const formattedFromMonth = value.fromMonth != null ? dayjs(value.fromMonth).format('YYYY-MM-DD') : null;
          // const formattedToMonth = value.toMonth != null ? dayjs(value.toMonth).format('YYYY-MM-DD') : null;

          setFilterObj({
            amt_value: value?.amt_value || '',
            status: value?.status === 'All' ? '' : value?.status,
            amt_type: value?.amt_type || '==',
            invoiceNumber: value?.invoiceNumber || '',
            invoiceCategory: value?.invoiceCategory === 'All' ? '' : value?.invoiceCategory
          });
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, setTouched, resetForm }) => (
          <Form>
            <FilterForm
              errors={errors}
              handleBlur={handleBlur}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              touched={touched}
              values={values}
              setFieldValue={setFieldValue}
              setTouched={setTouched}
              resetForm={resetForm}
              filterObj={filterObj}
              setFilterObj={setFilterObj}
              isExistingFilter={isExistingFilter}
              permissionsList={permissionsList}
              invoiceFilters={invoiceFilters}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default InvoiceFliter;
