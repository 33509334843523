import { useMutation, useQuery } from '@tanstack/react-query';
import { endpoints } from 'endpoints/endpoints';
// import { useNavigate } from 'react-router-dom';
import axiosInstance from 'utils/axiosInstance';
import { setToast, toastConfig } from 'utils/commonUtil';
// import { setToast, toastConfig } from 'utils/commonUtil';
export const useGetMachineTicketList = ({
  limit,
  sortOrder,
  sortField,
  page,
  status,
  subject,
  ticketNo,
  fromDate,
  toDate,
  machineId,
  ticketType
}) => {
  const organizationType = localStorage.getItem('orgType');
  const usersType = localStorage.getItem('userType');
  // console.log('useGetTicketReasonList : ', limit , page)
  return useQuery({
    queryKey: [
      'getMachineTicketNewData',
      limit,
      sortOrder,
      sortField,
      page,
      status,
      subject,
      ticketNo,
      fromDate,
      toDate,
      machineId,
      ticketType,
      organizationType,
      usersType
    ],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.machineticket}/${page}/${limit}`, {
          // filters: {},
          // sort: {
          //   sortField: sortField,
          //   sortOrder: sortOrder
          // }
          filters: {
            status: status || null,
            subject: subject || null,
            ticketNo: ticketNo || null,
            machineId: machineId || null,
            ticketType: ticketType || null,
            createdDate: {
              from: fromDate ? fromDate : null,
              to: toDate ? toDate : null
            }
          },
          sort: {
            sortField: 'createdAt',
            sortOrder: 'desc'
          }
        })

        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    cacheTime: 0, // This prevents React Query from caching the data
    staleTime: 0, // This ensures that the data is always considered "stale" and refetched on each request
    enabled: Boolean(organizationType) && Boolean(usersType) && Boolean(localStorage.getItem('userJwtToken')),
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

// Add
export const useMachineTicketAdd = async (data) => {
  const res = await axiosInstance.post(`${endpoints.machineticket}/save`, data);
  return res;
};

export const useGetMachineTicketById = ({ id }) => {
  return useQuery({
    queryKey: ['getMachineTicketById', id],
    queryFn: async () => {
      return await axiosInstance
        .get(`${endpoints.machineticket}/${id}`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    enabled: Boolean(id),
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

////Rejected Modal

export const useGetRejectReasonTicketModal = () => {
  return useMutation({
    mutationFn: async (data) => {
      return await axiosInstance
        .patch(`${endpoints.machineticket}/${data?.id}`, {
          status: data?.status,
          rejectReason: data?.rejectReason || ''
        })
        .then((res) => {
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data.message);
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },
    onSuccess: ({ data }) => {
      // queryClient.refetchQueries('getTicketReasonData');
      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};

// Status Ticket

export const useGetRejectReasonTicketStatus = () => {
  return useMutation({
    mutationFn: async (data) => {
      return await axiosInstance
        .patch(`${endpoints.machineticket}/${data?.id}`, {
          status: data?.status
        })
        .then((res) => {
          if (res?.status == 200) {
            toastConfig.type = 'success';
            // setToast(toastConfig, res?.data.message);
          } else {
            toastConfig.type = 'error';
            // setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },
    onSuccess: ({ data }) => {
      // queryClient.refetchQueries('getTicketReasonData');
      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};

// Chats

export const useGetMachineTicketChatList = ({ ticketID }) => {
  return useQuery({
    queryKey: ['getMachineTicketChatList', ticketID],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.machineticket}/chats`, {
          ticketId: ticketID
        })
        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    enabled: Boolean(ticketID),
    refetchOnMount: false, // Avoid unnecessary refetching on component mount
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

export const useMachineTicketChatCreate = async (data) => {
  const res = await axiosInstance.post(`${endpoints.machineticket}/chats/save`, data);
  return res;
};

// permission List
export const useGetPermissionMachineTicket = ({ organizationType, usersType }) => {
  return useQuery({
    queryKey: ['getPermissionsMachineTicket', organizationType, usersType],
    queryFn: async () => {
      return await axiosInstance

        .get(`${endpoints.permissionsScreen}/${usersType}/${organizationType}/Tickets`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          toastConfig.type = 'error';
          setToast(toastConfig, e.response.data.e);
        });
    },
    enabled: Boolean(organizationType) && Boolean(usersType),
    // enabled: Boolean(organizationType !== 'SuperAdmin') && Boolean(usersType),
    staleTime: 0,
    onSuccess: ({ data }) => {
      return data;
    }
    // refetchInterval: 30000
  });
};

// All Machine Ticket

export const useGetAllMachineTicketList = (data = {}) => {
  return useQuery({
    queryKey: ['getAllMachineTicketlist', data],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.machineticket}`, data)

        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
          toastConfig.type = 'error';
          setToast(toastConfig, e.response.data.error);
        });
    },
    cacheTime: 0, // This prevents React Query from caching the data
    staleTime: 0, // This ensures that the data is always considered "stale" and refetched on each request
    // enabled: Boolean(organizationType) && Boolean(usersType) && Boolean(localStorage.getItem('userJwtToken')),
    onSuccess: ({ data }) => {
      return data;
    }
  });
};
