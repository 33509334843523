/* eslint-disable */
import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import MainCard from 'ui-component/cards/MainCard';
import BreadcrumbsForPage from 'ui-component/extended/BreadcrumbsForPage';
import { useState } from 'react';
import { useEffect } from 'react';
// import FormView from 'ui-component/cards/Skeleton/FormView';
import { useMutation } from '@tanstack/react-query';
import { useTicketReasonAdd } from 'hooks/useTicketReasonHooks';
import { setToast, toastConfig } from 'utils/commonUtil';
import OrganizationFormView from 'ui-component/cards/Skeleton/OrganizationFormView';
import MachineForm from 'views/pages/machines/machine-form';
import { useLocation, useNavigate, useParams } from 'react-router';
import ContactUsDialogForm from '../form/ContactUsDialogForm';
import { Backdrop, CircularProgress } from '@mui/material';
const ContactUsDialog = ({
  open,
  setOpen,
  machineObj,
  onHandleClose,
  updateMachineSubscription,
  machineId,
  setLoadingChooseSubscription
}) => {
  console.log('ContactUsDialog - open : ', open);
  const [isLoading, setLoading] = useState(true);
  // const [isLoadingChooseSubscription, setLoadingChooseSubscription] = useState(false);
  const navigate = useNavigate();
  //   let { machineType } = useParams();
  //   machineType =
  // machineType.toString().toLowerCase() !== 'single' && machineType.toString().toLowerCase() !== 'batch' ? 'single' : machineType;
  // console.log('machineType : ', machineType);

  const { mutate: addTicketReason, isPending: isLoadingTicketreason } = useMutation({
    mutationFn: useTicketReasonAdd
  });
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 20);
  }, []);

  const { state: permissionsList } = useLocation();

  const permissionsAdd = permissionsList?.Add;

  console.log('location : Machine Add n : ', permissionsAdd);

  //   const noOfMachine = machineType.toString().toLowerCase() === 'single' ? 1 : 2;

  /*const validationSchema = Yup.object().shape({
    organization: Yup.string().required('Organization is required'),
    location: Yup.string().required('Location is required')
  });*/

  const validationSchema = Yup.object().shape({
    aproxMachineUser: Yup.number()
      .typeError('Approx. Machine User must be an integer')
      .required('Approx. Machine User is required')
      .positive('Approx. Machine User must be a positive number')
      .min(1, 'Approx. Machine User must be at least 1'),

    aproxGlobalUser: Yup.number()
      .typeError('Approx. Global User must be an integer')
      .required('Approx. Global User is required')
      .positive('Approx. Global User must be a positive number')
      .min(1, 'Approx. Global User must be at least 1'),

    noOfAds: Yup.number()
      .typeError('Number of Ads must be an integer')
      .required('Number of Ads is required')
      .positive('Number of Ads cannot be negative')
      .min(1, 'At least 1 ads needed.')
  });

  return (
    <>
      {isLoading ? (
        <OrganizationFormView />
      ) : (
        <>
          {/* <BreadcrumbsForPage
            name={machineType.toString().toLowerCase() === 'single' ? 'Add Machines (Single)' : 'Add Machines (Batch-Wise)'}
            obj={{ title: 'All Machines', title2: 'Add Machines', url: '/dashboard/machines' }}
          /> */}
          {/* <MainCard> */}
          <Formik
            initialValues={{
              aproxMachineUser: '',
              aproxGlobalUser: '',
              noOfAds: ''
              // organization: '',
              // location: '',
              // location_tax: ''
            }}
            validationSchema={validationSchema}
            onSubmit={async (value, { resetForm }) => {
              // console.log('custom plan updateMachineSubscription payload : ', 'payload', value);
              // console.log('value | ContactUsDialog : ', value, machineObj);
              let customSubscriptionObj = {
                localUser: value?.aproxMachineUser,
                globalUser: value?.aproxGlobalUser,
                advertisements: value?.noOfAds
              };
              const payload = { id: machineId || null, subId: null, customSubscription: customSubscriptionObj };
              // console.log('custom plan updateMachineSubscription payload : ', payload, value);
              setLoadingChooseSubscription(true);
              updateMachineSubscription(payload, {
                onSuccess: (res) => {
                  if (res?.status === 200) {
                    // console.log('res - submitData - Employee : ', res);
                    setOpen((state) => !state);
                    setTimeout(() => {
                      setLoadingChooseSubscription(false);
                      onHandleClose();
                    }, 500);
                  } else {
                    setLoadingChooseSubscription(false);
                  }
                },
                onError: (error) => {
                  console.log('error - submitData - Employee : ', error);
                  setLoadingChooseSubscription(false);
                }
              });
            }}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, values, touched }) => (
              <>
                <ContactUsDialogForm
                  open={open}
                  setOpen={setOpen}
                  btnName="Submit"
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  setFieldValue={setFieldValue}
                  values={values}
                  //   isLoadingTicket={isLoadingTicketreason}
                  touched={touched}
                  id={''}
                  // permissionsAdd={permissionsAdd}
                  onHandleClose={onHandleClose}
                  updateMachineSubscription={updateMachineSubscription}
                  machineId={machineId}
                />
              </>
            )}
          </Formik>
          {/* </MainCard> */}
        </>
      )}
    </>
  );
};

export default ContactUsDialog;
