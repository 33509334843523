import {
  Autocomplete,
  // Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  // MenuItem,
  OutlinedInput,
  Paper,
  // Paper,
  Radio,
  RadioGroup,
  // Select,
  TextField,
  // TextField,
  // TextareaAutosize,
  // TextField,
  Typography
} from '@mui/material';
import { Form } from 'formik';
import React from 'react';
import { gridSpacing } from 'store/constant';
import { useTheme } from '@mui/material/styles';
import AnimateButton from 'ui-component/extended/AnimateButton';

// import DocumentUploadTicket from 'views/pages/retailer/retailer-user/retailer-tickets/files/uploader';
import { useGetAllMachineNewList } from 'hooks/useMachineHooks';
import { useGetTicketReasonAllList } from 'hooks/useTicketReasonHooks';
// import { useNavigate } from 'react-router';
import DocumentUpload from 'ui-component/file-upload';

const TicketsForm = ({
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  //   isSubmitting,
  touched,
  values,

  setFieldValue,

  btnName,
  isLoadingMachineTicket

  // setTouched,
  //   ticketDetails
}) => {
  const { data: allTicketReasonDataList } = useGetTicketReasonAllList();
  const { data: allmachineList } = useGetAllMachineNewList();
  // const navigate = useNavigate();
  const theme = useTheme();
  return (
    <>
      <Form>
        <Grid
          container
          spacing={gridSpacing}
          sx={{
            alignContent: 'center'
          }}
        >
          <Grid item xs={12}>
            <Typography component="h4" variant="h2" sx={{ mb: 2 }}>
              Machine Ticket Details
            </Typography>
            <Grid container spacing={gridSpacing}>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <FormControl fullWidth error={Boolean(touched.subject && errors.subject)} sx={{ ...theme.typography.customInput }}>
                  <InputLabel htmlFor="outlined-adornment-email-login">Subject * </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-secondary-address"
                    type="text"
                    value={values.subject}
                    name="subject"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label="Subject *"
                    placeholder="e.g. Malfunctioning Machine"
                    inputProps={{}}
                    autoComplete="off"
                  />
                  {touched.subject && errors.subject && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                      sx={{
                        fontSize: '13.8px'
                      }}
                    >
                      {errors.subject}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={6}
                xs={12}
                // className="select-job-enterprise select-job-enterprise-type"
                className="select-job-enterprise select-job-enterprise-type-machine"
              >
                <FormControl
                  fullWidth
                  // error={Boolean(touched.reason && errors.reason)}
                  sx={{ ...theme.typography.customInput }}
                >
                  <Autocomplete
                    options={allTicketReasonDataList?.data || []}
                    getOptionLabel={(option) => (option.id ? `${option.category ? `${option.category}` : ''}` : '')}
                    value={allTicketReasonDataList?.data?.find((item) => item.id === values.reason) || null}
                    onChange={(event, newValue) => {
                      handleChange({ target: { name: 'reason', value: newValue?.id || '' } });
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        const { onDelete, ...tagProps } = getTagProps({ index }); // Extract onDelete for manual use
                        // console.log('option Buyers : ', option);
                        return (
                          <span
                            key={option}
                            {...tagProps}
                            style={{
                              backgroundColor: '#6fd74b',
                              color: '#fff',
                              padding: '2px 8px',
                              borderRadius: '4px',
                              marginRight: '5px',
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            {option}
                            <button
                              onClick={onDelete} // Call the delete function for this tag
                              style={{
                                marginLeft: '5px',
                                background: 'none',
                                border: 'none',
                                color: '#fff',
                                cursor: 'pointer'
                              }}
                            >
                              ✖
                            </button>
                          </span>
                        );
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Category *"
                        variant="outlined"
                        error={Boolean(touched.reason && errors.reason)}
                        // helperText={touched.reason && errors.reason ? errors.reason : ''}
                      />
                    )}
                    PaperComponent={(props) => <Paper {...props} style={{}} />}
                  />
                  {touched.reason && errors.reason && (
                    <FormHelperText error sx={{ fontSize: '13.8px' }}>
                      {errors.reason}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid
                item
                lg={6}
                md={6}
                sm={6}
                xs={12}
                // className="select-job-enterprise select-job-enterprise-type"
                className="select-job-enterprise select-job-enterprise-type-machine"
              >
                <FormControl
                  fullWidth
                  // error={Boolean(touched.machine && errors.machine)}
                  sx={{ ...theme.typography.customInput }}
                >
                  <Autocomplete
                    options={allmachineList?.data || []}
                    getOptionLabel={(option) => (option.id ? `${option.machineName ? `${option.machineName}` : ''}` : '')}
                    value={allmachineList?.data?.find((item) => item.id === values.machine) || null}
                    onChange={(event, newValue) => {
                      handleChange({ target: { name: 'machine', value: newValue?.id || '' } });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Machine *"
                        variant="outlined"
                        error={Boolean(touched.machine && errors.machine)}
                        // helperText={touched.machine && errors.machine ? errors.machine : ''}
                      />
                    )}
                    PaperComponent={(props) => <Paper {...props} style={{}} />}
                  />
                  {touched.machine && errors.machine && (
                    <FormHelperText error sx={{ fontSize: '13.8px' }}>
                      {errors.machine}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <FormControl error={Boolean(touched.priority && errors.priority)}>
                  <FormLabel id="demo-radio-buttons-group-label">Priority*</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="priority"
                    value={values.priority}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <FormControlLabel value="High" control={<Radio />} label="High" />

                    <FormControlLabel value="Low" control={<Radio />} label="Low" />
                    <FormControlLabel value="Medium" control={<Radio />} label="Medium" />
                  </RadioGroup>

                  {touched.priority && errors.priority && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                      sx={{
                        fontSize: '13.8px'
                      }}
                    >
                      {errors.priority}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormControl
                  className="ticket_detailstextarea"
                  fullWidth
                  error={Boolean(touched.notes && errors.notes)}
                  sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-email-login">Notes</InputLabel>
                  <OutlinedInput
                    value={values.notes}
                    onChange={handleChange}
                    multiline
                    rows={4}
                    placeholder="Type your query here..."
                    fullWidth
                    variant="outlined"
                    name="notes"
                    label="Notes"
                  />

                  {touched.notes && errors.notes && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                      sx={{
                        fontSize: '13.8px'
                      }}
                    >
                      {errors.notes}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Grid>
                  <div className="field-input-all">
                    <Typography variant="h5" component={'span'} className="font-medium">
                      Related Files/Documents
                    </Typography>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      style={{
                        marginTop: '10px'
                      }}
                      className="related_docfile"
                    >
                      {/* <DocumentUploadTicket
                        {...{
                          //   values,
                          //   errors,
                          //   touched,
                          //   handleChange,
                          //   handleBlur,
                          //   handleSubmit,
                          //   isSubmitting,
                          //   setFieldValue,
                          //   // resetForm,
                          //   ticketDetails
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit,
                          setFieldValue,
                          handleBlur
                          // isSubmitting,
                          // ticketDetails
                        }}
                      /> */}
                      <DocumentUpload
                        {...{
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit,
                          setFieldValue
                        }}
                      />
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid className="ticket_reason_btns_start" item xs={12}>
            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
            <Box className="ticket_reason_btns" sx={{ mt: 5, display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
              <Box sx={{ display: 'inline-block' }}>
                <AnimateButton>
                  <Button
                    disableElevation
                    disabled={isLoadingMachineTicket}
                    size="large"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    style={{
                      color: 'white',
                      minWidth: '200px',
                      margin: '0px auto'
                    }}
                  >
                    {isLoadingMachineTicket ? (
                      <>
                        <CircularProgress
                          sx={{
                            color: 'white',
                            height: 20,
                            width: 20
                          }}
                        />
                      </>
                    ) : (
                      `${btnName} Ticket`
                    )}
                  </Button>
                </AnimateButton>
              </Box>
              {/* <Box sx={{ display: 'inline-block', marginLeft: '15px' }}>
                <AnimateButton>
                  <Button
                    disableElevation
                    // disabled={isLoadingMachineTicket}
                    size="large"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    style={{
                      color: 'white',
                      minWidth: '200px',
                      margin: '0px auto'
                    }}
                    onClick={() => navigate('/dashboard/invoices')}
                  >
                    Add Ticket With Invoices
                  
                  </Button>
                </AnimateButton>
              </Box> */}
            </Box>
          </Grid>
        </Grid>
      </Form>
    </>
  );
};

export default TicketsForm;
