/* eslint-disable react-hooks/rules-of-hooks */

import { toast } from 'react-toastify';
// import axios from 'axios';
import { IconButton, Tooltip } from '@mui/material';
import { CopyAll } from '@mui/icons-material';
import axiosInstance from './axiosInstance';
import { endpoints } from 'endpoints/endpoints';
export const toastConfig = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  theme: 'colored',
  type: 'info',
  progress: undefined
};

export const setToast = (config, message) => {
  toast(message, config);
};
// export const roundOff = (num) => {
//   return +(Math.round(num + 'e+2') + 'e-2');
// };

// export const roundOffTwo = (num) => {
//   return +parseFloat(num).toFixed(2);
// };
export const roundOffTwo2 = (num) => {
  return parseFloat(num).toFixed(2);
};

// export const handleDownloadInvoice = ({ id, invoiceId }) => {
//   const link = document.createElement('a');
//   link.href = `https://shredbox.vercel.app/api/v1/invoices/pdf/${id}`;
//   //link.href = `https://firebasestorage.googleapis.com/v0/b/shredbox-52385.appspot.com/o/invoices%2FINV-000017-Raised%20RSg1lisvoUkWWqvaNc16.pdf?alt=media&token=bf3450eb-7a3b-4481-89ca-daea46685e0c`;
//   link.target = '__blank';
//   link.setAttribute('download', `invoice_${invoiceId}.pdf`);
//   document.body.appendChild(link);
//   link.click();
//   link.remove();
//   document.body.removeChild(link);
//   // const url = `https://shredbox.vercel.app/api/v1/invoices/pdf/${id}`;
//   // const fileName = `invoice_${invoiceId}.pdf`;

//   // fetch(url)
//   //   .then((response) => response.blob())
//   //   .then((blob) => {
//   //     const link = document.createElement('a');
//   //     const objectUrl = window.URL.createObjectURL(blob);
//   //     link.href = objectUrl;
//   //     link.target = '__blank';
//   //     link.setAttribute('download', fileName);
//   //     document.body.appendChild(link);
//   //     link.click();
//   //     link.remove();
//   //     window.URL.revokeObjectURL(objectUrl); // Cleanup
//   //   })
//   //   .catch((error) => console.error('Download failed:', error));
// };

//========= if api is added==========
// export const handleDownloadInvoice = async ({ id, setLoading }) => {
//   try {
//     await axiosInstance

//       .get(`${endpoints.invoice}/${id}/machines/pdf`)
//       .then((res) => {
//         console.log('🚀 ~ .then ~ res:', res);
//         if (res.status === 200) {
//           toastConfig.type = 'success';
//           setToast(toastConfig, res?.data?.message);
//           // setLoading(true);
//           window.open(res.data.data, '_blank');
//         } else {
//           toastConfig.type = 'error';
//           setToast(toastConfig, 'Something went wrong');
//         }
//         return res;
//       })
//       .catch((e) => {
//         console.log(e);
//         toastConfig.type = 'error';
//         setToast(toastConfig, 'Something went wrong');
//         // setLoading(false);
//       });
//   } catch (error) {
//     console.error('Download failed:', error);
//     setLoading(false);
//   }
// };
export const handleDownloadInvoice = async ({ id, setLoading }) => {
  setLoading(true); // Ensure state updates before the request
  try {
    const res = await axiosInstance.get(`${endpoints.invoice}/${id}/machines/pdf`);

    if (res.status === 200) {
      toastConfig.type = 'success';

      window.open(res.data.data, '_blank');
      setToast(toastConfig, res?.data?.message);
    } else {
      toastConfig.type = 'error';
      setToast(toastConfig, 'Something went wrong');
    }
  } catch (error) {
    console.error('Download failed:', error);
    toastConfig.type = 'error';
    setToast(toastConfig, 'Something went wrong');
  } finally {
    setLoading(false); // Ensure loading stops after completion or failure
  }
};

// export const handleDownloadInvoicePDF = ({ pdfUrl, invoiceId }) => {
//   const link = document.createElement('a');
//   link.href = pdfUrl; // Directly use the URL
//   link.setAttribute('download', `invoice_${invoiceId}.pdf`); // Suggested filename
//   // link.target = '_blank'; // Open in a new tab if needed
//   window.open(pdfUrl, '_blank');
//   document.body.appendChild(link);
//   link.click();
//   document.body.removeChild(link);
// };

// export const handleDownloadInvoice = async ({ id, invoiceId }) => {
//   try {
//     const response = await axios.get(`https://shredbox.vercel.app/api/v1/invoices/pdf/${id}`, {
//       responseType: 'blob'
//     });

//     // Create a Blob URL from the response data
//     const url = window.URL.createObjectURL(new Blob([response.data]));
//     const link = document.createElement('a');
//     link.href = url;
//     link.setAttribute('download', `invoice_${invoiceId}.pdf`); // Specify the file name
//     link.target = '_blank'; // Ensure it opens in a new tab
//     document.body.appendChild(link);
//     link.click();

//     // Cleanup
//     link.remove();
//     window.URL.revokeObjectURL(url); // Free up memory
//   } catch (error) {
//     console.error('Download failed:', error);
//   }
// };
export const getDomainName = (url) => {
  try {
    const { host } = new URL(url);
    return host; // e.g., "localhost:3000"
  } catch {
    return url; // Fallback for invalid URLs
  }
};

/// Select Support Type
export const getSupportType = [
  {
    id: 1,
    supportType: 'Machine Related Issues',
    value: 'Machine Related Issues'
  },
  {
    id: 2,
    supportType: 'Subscription Package',
    value: 'Subscription Package'
  },
  {
    id: 3,
    supportType: 'Organization Related Issues',
    value: 'Organization Related Issues'
  },
  {
    id: 4,
    supportType: 'Invoice Related Issues',
    value: 'Invoice Related Issues'
  },
  {
    id: 5,
    supportType: 'Jobs Related Issues',
    value: 'Jobs Related Issues'
  },
  {
    id: 6,
    supportType: 'Ticket Related Issues',
    value: 'Ticket Related Issues'
  },
  {
    id: 7,
    supportType: 'Employees Related Issues',
    value: 'Employees Related Issues'
  },
  {
    id: 8,
    supportType: 'Others',
    value: 'Others'
  }
];

/**
 * Copy To Clipboard
 * @param {string}
 * <CopyIconButton textToCopy={apiKey} />
 */
export const CopyIconButton = ({ textToCopy, isIconDiv = false }) => {
  const handleCopy = async () => {
    // textToCopy = 'This is the text you want to copy!'; // Replace this with the text you want to copy

    try {
      // Write text to clipboard
      await navigator.clipboard.writeText(textToCopy);
      // console.log('Text copied to clipboard!');
      toastConfig.type = 'success';
      setToast(toastConfig, 'API Key is copied to clipboard');
    } catch (err) {
      console.error('Failed to copy: ', err);
      toastConfig.type = 'error';
      setToast(toastConfig, 'Failed to copy');
    }
  };

  return (
    <Tooltip title="Copy to Clipboard">
      {isIconDiv ? (
        <span>
          <CopyAll color="primary" style={{ position: 'relative', top: '7px' }} onClick={handleCopy} />
        </span>
      ) : (
        <IconButton onClick={handleCopy}>
          <CopyAll color="primary" />
        </IconButton>
      )}
    </Tooltip>
  );
};

export function maskData(data, visibleStart = 4, visibleEnd = 4, maskChar = '*') {
  // Ensure the data is a string
  const dataStr = String(data);

  // Check if the string is long enough to be masked
  if (dataStr.length <= visibleStart + visibleEnd) {
    return dataStr; // If not, return the original string
  }

  // Mask all characters except the visible start and end parts
  const maskedPart = maskChar.repeat(dataStr.length - visibleStart - visibleEnd);
  const visiblePart = dataStr.slice(0, visibleStart) + maskedPart + dataStr.slice(-visibleEnd);

  return visiblePart;
}

export const truncatedText = (text, maxLength = 50) => {
  // console.log('truncatedText text : ', text);
  let truncatedText = text;
  if (truncatedText) {
    if (text.length > maxLength) {
      truncatedText = text.substring(0, maxLength) + '...';
    }
  }
  return truncatedText;
};

// Get Full Name for Employee Modeule
export const getEmployeeFullName = (employeeDetails, prefix = true) => {
  if (employeeDetails) {
    if (prefix && employeeDetails.prefix) {
      return `${employeeDetails?.prefix} ${employeeDetails?.firstName} ${employeeDetails?.lastName}`;
    } else {
      return `${employeeDetails?.firstName} ${employeeDetails?.lastName}`;
    }
  }
};

// Machine Module Status Color Code
export const machineStatusColors = {
  Build: 'rgb(105,200,191)', //'#FCA311', // Orange for "Build"
  Procurement: '#FB5607', // Light Blue for "Preparation"
  Online: '#76baff', //'#006747', // Green for "Online"
  Offline: 'rgb(175 116 255)', // Red for "Offline"
  Maintenance: '#F8BA40', // Grey for "Maintenance"
  Preparation: '#beca4c' //'#E7BA0A'
};

//Machine Thresold color
// export const machinebinThresoldColors = {
//   '1-20': '#FF4C4C', // Red
//   '21-40': '#FFA500', // Orange
//   '41-60': '#FFD700', // Yellow
//   '61-80': '#32CD32', // Green
//   '81-100': '#0080FF' // Blue
// };
// export const machinebinThresoldColors = (value) => {
//   if (value >= 1 && value <= 20) return '#FF4C4C'; // Red
//   if (value >= 21 && value <= 40) return '#FFA500'; // Orange
//   if (value >= 41 && value <= 60) return '#FFD700'; // Yellow
//   if (value >= 61 && value <= 80) return '#32CD32'; // Green
//   if (value >= 81 && value <= 100) return '#0080FF'; // Blue
//   return '#CCCCCC'; // Default gray if out of range
// };
// Dyanamic Data For Table
export function getValueFromArrayPath(obj, pathArray) {
  return Array.isArray(pathArray) && pathArray.length > 1
    ? pathArray.reduce((acc, part) => {
        // Check for bracket notation (e.g., ['data'])
        const matches = part.match(/^(\w+)\['([^']+)'\]$/);
        if (matches) {
          const key = matches[2]; // Extract key inside quotes
          return acc && acc[matches[1]] ? acc[matches[1]][key] : undefined;
        }

        // Check for array index (e.g., [0])
        const arrayMatches = part.match(/^(\w+)\[(\d+)\]$/);
        if (arrayMatches) {
          const key = arrayMatches[1]; // Extract object key
          const index = parseInt(arrayMatches[2], 10); // Extract array index
          return acc && acc[key] && Array.isArray(acc[key]) ? acc[key][index] : undefined;
        }

        // Otherwise, access the property directly
        return acc && acc[part];
      }, obj)
    : '';
}

// Function to get value from an object based on dot notation string
export function getValueFromPath(obj, path) {
  // console.log('getValueFromPath : ', path);
  const pathToArray = path.split('.');
  // const finalData = pathToArray.length > 1 ? pathToArray.reduce((acc, part) => acc && acc[part], obj) : obj[path];
  const finalData = pathToArray.length > 1 ? getValueFromArrayPath(obj, pathToArray) : obj[path];
  // if (path === 'softwareVersions')
  // console.log(pathToArray, ' finalData : ', finalData, ' | Array.isArray(finalData) : ', Array.isArray(finalData));
  return Array.isArray(finalData) ? (typeof data === 'object' ? new Set(finalData)?.join(', ') : finalData?.join(', ')) : finalData;
}

// Function to convert camelCase to space-separated words For Organixations Types
export const addSpacesToCamelCase = (str) => {
  // if (str) return str.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
  try {
    if (str) {
      let superAdmin = str.toString().trim().toLowerCase();
      if (superAdmin === 'superadmin' || superAdmin === 'super admin') {
        // return 'ShredBox';
      }
      return str.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
    } else return str;
  } catch (error) {
    console.log('addSpacesToCamelCase - error ', error);
    return str;
  }
};

// This Function For Check for Object is Empty
export function isEmptyObject(obj) {
  if (obj === null || obj === undefined || obj === '') {
    return false; // or handle as needed (e.g., return true if you consider null/undefined as "empty")
  }
  return Object.keys(obj).length === 0;
}

// Automatic Phone Number Format 123-456-7890

const formatPhoneNumber = (value) => {
  // Remove all non-digit characters
  let cleaned = value.replace(/\D/g, '');

  // Format the cleaned value as (XXX) XXX-XXXX
  if (cleaned.length <= 3) {
    return cleaned;
  } else if (cleaned.length <= 6) {
    return cleaned.replace(/(\d{3})(\d{0,3})/, '$1-$2');
  } else {
    return cleaned.replace(/(\d{3})(\d{3})(\d{0,4})/, '$1-$2-$3');
  }
};

export const handlePhoneChange = (e, setFieldValue, name) => {
  const { value } = e.target;
  const formattedPhone = formatPhoneNumber(value);
  if (!formattedPhone) setFieldValue(name, '');
  else if (formattedPhone && formattedPhone.length <= 12) setFieldValue(name, formattedPhone); // Update Formik field value
};
