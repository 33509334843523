import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { endpoints } from 'endpoints/endpoints';
import axiosInstance from 'utils/axiosInstance';
import { setToast, toastConfig } from 'utils/commonUtil';

// Employee Create API
export const useEmployeeCreate = () => {
  // const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      return await axiosInstance
        .post(`${endpoints.employees}/save`, data)
        .then((res) => {
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data.success);
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },
    onSuccess: ({ data }) => {
      // queryClient.refetchQueries({ queryKey: ['getMachineDetailsById'], type: 'active' });
      // queryClient.refetchQueries('getMachineData');
      // queryClient.refetchQueries('getLocationDetailsById');
      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};

// Employee Status Update API
export const useEmployeeNewStatusUpdate = (statusField) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => {
      return await axiosInstance
        .patch(`${endpoints.employees}/${data?.id}`, { [statusField]: data?.status })
        .then((res) => {
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data.message);
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },
    onSuccess: ({ data }) => {
      // queryClient.refetchQueries('getOrganizationNewData');
      queryClient.refetchQueries({
        queryKey: ['getEmployeeNewDataList'],
        type: 'active'
      });
      // queryClient.refetchQueries('getEmployeeNewDataList');
      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};

// Employee List With Filter API
export const useGetEmployeeNewList = ({
  limit,
  sortOrder,
  sortField,
  page,
  name,
  status,
  onlyAdmin,
  email,
  title,
  installerRfidPin,
  installerRfid,
  rfidPin,
  rfid
}) => {
  // console.log('getEmployeeNewDataList');
  return useQuery({
    queryKey: [
      'getEmployeeNewDataList',
      limit,
      sortOrder,
      sortField,
      page,
      name,
      status,
      onlyAdmin,
      email,
      title,
      installerRfidPin,
      installerRfid,
      rfidPin,
      rfid
    ],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.employees}/${page}/${limit}`, {
          filters: {
            enabled: status ? status : null,
            name: name || null,
            employeeType: onlyAdmin || null,
            email: email || null,
            title: title || null,
            installerRfidPin: installerRfidPin || null,
            installerRfid: installerRfid || null,
            enterpriserfidPin: rfidPin || null,
            enterpriserfid: rfid || null
          },
          sort: {
            sortField: sortField,
            sortOrder: sortOrder
          }
        })

        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // enabled: Boolean(limit) && Boolean(page),
    // cacheTime: 0,
    // staleTime: 0,
    enabled: Boolean(limit) && Boolean(sortField),
    // refetchOnMount: false, // Avoid unnecessary refetching on component mount
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

export const useGetEmployeeById = ({ id }) => {
  return useQuery({
    queryKey: ['getEmployeeeDetailsById', id],
    queryFn: async () => {
      return await axiosInstance
        .get(`${endpoints.employees}/${id}`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data || [];
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    enabled: Boolean(id),
    cacheTime: 0,
    staleTime: 0,
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

// Employee List API

// Employee All List API
export const useGetAllEmployeeList = () => {
  return useQuery({
    queryKey: ['getAllEmployeeListData'],
    queryFn: async () => {
      return await axiosInstance

        .post(`${endpoints.employees}`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

export const useEmployeeUpdate = () => {
  // const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      return await axiosInstance
        // .patch(`${endpoints.locations}/${data.id}`, data)
        .put(`${endpoints.employees}/${data.id}`, data)
        .then((res) => {
          // console.log('useLocationUpdate res', res);
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data.message);
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },

    onSuccess: ({ data }) => {
      // queryClient.refetchQueries({ queryKey: ['getOrganizationNewDetailsById'], type: 'active' });
      // queryClient.refetchQueries('getLocationDetailsById');
      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};

// Call Before Employee ADD API
export const useGetSubcriptionsLimit = () => {
  // const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => {
      console.log('useGetSubcriptionsLimit : ', data);
      return await axiosInstance
        .post(`${endpoints.employees}/subscriptions/limit`, data)
        .then((res) => {
          if (res?.status == 200) {
            // toastConfig.type = 'success';
            // setToast(toastConfig, res?.data.message);
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          console.log('useGetSubcriptionsLimit ~ error : ', error);
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
          return error;
        });
    },
    onSuccess: ({ data }) => {
      // queryClient.refetchQueries('getOrganizationNewData');
      // queryClient.refetchQueries({
      //   queryKey: ['getEmployeeNewDataList'],
      //   type: 'active'
      // });
      // queryClient.refetchQueries('getEmployeeNewDataList');
      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};

// api/v1/employees/globalusers/remaining
export const useGetGlobalUserCount = () => {
  return useQuery({
    queryKey: ['getGlobalUserCount'],
    queryFn: async () => {
      return await axiosInstance
        .get(`${endpoints.employees}/globalusers/remaining`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data ?? null;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

//Employee Permissions API

export const useGetPermissionsEmployeeList = ({ organizationType, usersType }) => {
  organizationType = organizationType ? organizationType.replaceAll(' ', '') : '';
  return useQuery({
    queryKey: ['getPermissionsEmployee', organizationType, usersType],
    queryFn: async () => {
      return await axiosInstance
        // .get(`${endpoints.permissionsScreen}/Machines/${usersType}/${organizationType}`)
        .get(`${endpoints.permissionsScreen}/${usersType}/${organizationType}/Employees`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          toastConfig.type = 'error';
          setToast(toastConfig, e.response.data.e);
        });
    },
    enabled: Boolean(organizationType) && Boolean(usersType),
    cacheTime: 0,
    staleTime: 0,
    onSuccess: ({ data }) => {
      return data;
    }
    // refetchInterval: 30000
  });
};
