import React from 'react';
import {
  Modal,
  Box,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  OutlinedInput,
  Switch,
  FormControlLabel,
  Button,
  Divider,
  IconButton
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTheme } from '@emotion/react';
import CloseIcon from '@mui/icons-material/Close';

const AddNewTableHeaderModal = ({ editableData, setEditableData, setFieldValue, values, open, setOpen }) => {
  const theme = useTheme();

  const initials = {
    id: editableData?.id || '',
    label: editableData?.label || '',
    show: editableData?.show || false,
    changeState: editableData?.changeState || false,
    sortField: editableData?.sortField || false
  };

  const validationSchema = Yup.object().shape({
    id: Yup.string()
      .required('ID is required')
      .test(
        'unique-id',
        'ID must be unique',
        (value) => !values?.tableHeaders?.some((item) => item?.id === value && item?.id !== editableData?.id)
      ),
    label: Yup.string().required('Label is required'),
    changeState: Yup.boolean(),
    sortField: Yup.boolean()
  });

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
      xs: '90%',
      sm: '80%',
      lg: '70%'
    },
    maxWidth: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2
  };

  function onAdd({ id, label, show, changeState, sortField }) {
    const index = values?.tableHeaders?.findIndex((item) => item?.id === editableData?.id);

    if (index >= 0) {
      const updatedList = [...values.tableHeaders];
      updatedList[index] = { id, label, show, changeState, sortField };
      setFieldValue('tableHeaders', updatedList);
    } else {
      setFieldValue('tableHeaders', [...values.tableHeaders, { id, label, show, changeState, sortField }]);
    }

    setEditableData(null);
    setOpen(false);
  }

  const handleClose = () => {
    // setEditableData(null);
    setOpen(false);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Typography variant="h4" component="span" sx={{ mb: 2 }}>
          {editableData ? `Edit ` : `Add `}Table Header
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={(theme) => ({
              position: 'absolute',
              right: 25,
              top: 20,
              color: theme.palette.grey[500]
            })}
          >
            <CloseIcon color="error" />
          </IconButton>
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Formik
          initialValues={initials}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            onAdd(values);
            // setEditableData(null);
            resetForm();
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, resetForm }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl sx={{ ...theme.typography.customInput }} fullWidth error={Boolean(touched.id && errors.id)}>
                    <InputLabel htmlFor="id">ID</InputLabel>
                    <OutlinedInput id="id" name="id" value={values.id} onChange={handleChange} onBlur={handleBlur} label="ID" />
                    {touched.id && errors.id && (
                      <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                        {errors.id}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl sx={{ ...theme.typography.customInput }} fullWidth error={Boolean(touched.label && errors.label)}>
                    <InputLabel htmlFor="label">Label</InputLabel>
                    <OutlinedInput id="label" name="label" value={values.label} onChange={handleChange} onBlur={handleBlur} label="Label" />
                    {touched.label && errors.label && (
                      <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                        {errors.label}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControlLabel control={<Switch id="show" name="show" checked={values.show} onChange={handleChange} />} label="Show" />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControlLabel
                    control={<Switch id="changeState" name="changeState" checked={values.changeState} onChange={handleChange} />}
                    label="Change State"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControlLabel
                    control={<Switch id="sortField" name="sortField" checked={values.sortField} onChange={handleChange} />}
                    label="Sort Field"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography component={'span'} variant="body1" sx={{ fontSize: 12 }}>
                    {`Note: After you save the above information, you need to 'Calibrate' to make changes on Database, otherwise this temporary changes will not be remained.`}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    type="reset"
                    variant="outlined"
                    color="error"
                    fullWidth
                    onClick={resetForm}
                    sx={{ visibility: editableData ? 'hidden' : 'visible' }}
                  >
                    Reset
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button type="submit" variant="contained" color="primary" fullWidth sx={{ color: '#fff' }}>
                    Save
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default AddNewTableHeaderModal;
