import { Button, Grid, List, ListItem, ListItemIcon, ListItemText, Paper, Skeleton, Typography } from '@mui/material';
import React from 'react';
import Slider from 'react-slick';
import CheckIcon from '@mui/icons-material/Check';
import { useRef } from 'react';

const SubcriptionSliderSkeleton = () => {
  const sliderRef = useRef(null);

  const settings = {
    dots: true,
    slidesToShow: 3,
    infinite: true,
    slidesToScroll: 1,
    autoplay: false,
    /*dots: filterSubcriptionList && filterSubcriptionList.length > 3,
        slidesToShow: filterSubcriptionList && filterSubcriptionList.length >= 2 ? 3 : filterSubcriptionList.length, // Show all items if 3 or less
        infinite: filterSubcriptionList && filterSubcriptionList.length > 3,
        slidesToScroll: 1,
        autoplay: filterSubcriptionList && filterSubcriptionList.length > 3,*/
    speed: 200,
    autoplaySpeed: 3500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
    init: () => {
      console.log('Slick Slider initialized and ready!');
      // You can also perform actions here, like updating state or triggering animations
    }
  };
  return (
    <div className="slider-container slide-subscribe-plan" style={{ position: 'relative', paddingBottom: 30, overflowX: 'hidden' }}>
      <Slider {...settings} ref={sliderRef} className="slick-slider">
        <Grid item xs={12} sm={6} md={4} className="subscription-plan-wrapper">
          <Paper sx={{ p: 6, borderRadius: 2, boxShadow: 3, height: '100%' }}>
            <Typography variant="h2" align="left" sx={{ fontWeight: '600' }}>
              <Skeleton variant="text" width={150} />
            </Typography>
            <Typography variant="body2" align="left" sx={{ mb: 2, pt: 2 }}>
              <Skeleton variant="text" width={300} />
              <Skeleton variant="text" width={150} />
            </Typography>
            <Typography variant="h1" align="left" sx={{ fontWeight: '700', mb: 2 }}>
              <Skeleton variant="rectangular" width={100} height={50} />{' '}
              <span style={{ fontSize: '12.5px', color: '#a4a4a4', fontWeight: '500' }}></span>
            </Typography>
            <Typography variant="h4" align="left" sx={{ fontWeight: '600' }}>
              <Skeleton variant="text" width={180} />
            </Typography>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                mt: 4,
                p: 2,
                color: '#FFF',
                backgroundColor: '#e1ac0d',
                '&:hover': {
                  backgroundColor: '#d8a408'
                },
                fontSize: '1rem',
                boxShadow: 'none'
              }}
            >
              <Skeleton variant="text" width={200} />
            </Button>
            <List sx={{ paddingTop: 5 }}>
              <ListItem sx={{ paddingLeft: 0 }}>
                <ListItemIcon>
                  <CheckIcon sx={{ color: '#FFBF00' }} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography sx={{ fontWeight: 'unset' }}>
                      <Skeleton variant="text" width={100} />
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem sx={{ paddingLeft: 0 }}>
                <ListItemIcon>
                  <CheckIcon sx={{ color: '#FFBF00' }} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography sx={{ fontWeight: 'unset' }}>
                      <Skeleton variant="text" width={100} />
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem sx={{ paddingLeft: 0 }}>
                <ListItemIcon>
                  <CheckIcon sx={{ color: '#FFBF00' }} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography sx={{ fontWeight: 'unset' }}>
                      <Skeleton variant="text" width={100} />
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem sx={{ paddingLeft: 0 }}>
                <ListItemIcon>
                  <CheckIcon sx={{ color: '#FFBF00' }} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography sx={{ fontWeight: 'unset' }}>
                      <Skeleton variant="text" width={100} />
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4} className="subscription-plan-wrapper">
          <Paper sx={{ p: 6, borderRadius: 2, boxShadow: 3, height: '100%' }}>
            <Typography variant="h2" align="left" sx={{ fontWeight: '600' }}>
              <Skeleton variant="text" width={150} />
            </Typography>
            <Typography variant="body2" align="left" sx={{ mb: 2, pt: 2 }}>
              <Skeleton variant="text" width={300} />
              <Skeleton variant="text" width={150} />
            </Typography>
            <Typography variant="h1" align="left" sx={{ fontWeight: '700', mb: 2 }}>
              <Skeleton variant="rectangular" width={100} height={50} />{' '}
              <span style={{ fontSize: '12.5px', color: '#a4a4a4', fontWeight: '500' }}></span>
            </Typography>
            <Typography variant="h4" align="left" sx={{ fontWeight: '600' }}>
              <Skeleton variant="text" width={180} />
            </Typography>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                mt: 4,
                p: 2,
                color: '#FFF',
                backgroundColor: '#e1ac0d',
                '&:hover': {
                  backgroundColor: '#d8a408'
                },
                fontSize: '1rem',
                boxShadow: 'none'
              }}
            >
              <Skeleton variant="text" width={200} />
            </Button>
            <List sx={{ paddingTop: 5 }}>
              <ListItem sx={{ paddingLeft: 0 }}>
                <ListItemIcon>
                  <CheckIcon sx={{ color: '#FFBF00' }} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography sx={{ fontWeight: 'unset' }}>
                      <Skeleton variant="text" width={100} />
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem sx={{ paddingLeft: 0 }}>
                <ListItemIcon>
                  <CheckIcon sx={{ color: '#FFBF00' }} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography sx={{ fontWeight: 'unset' }}>
                      <Skeleton variant="text" width={100} />
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem sx={{ paddingLeft: 0 }}>
                <ListItemIcon>
                  <CheckIcon sx={{ color: '#FFBF00' }} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography sx={{ fontWeight: 'unset' }}>
                      <Skeleton variant="text" width={100} />
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem sx={{ paddingLeft: 0 }}>
                <ListItemIcon>
                  <CheckIcon sx={{ color: '#FFBF00' }} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography sx={{ fontWeight: 'unset' }}>
                      <Skeleton variant="text" width={100} />
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4} className="subscription-plan-wrapper">
          <Paper sx={{ p: 6, borderRadius: 2, boxShadow: 3, height: '100%' }}>
            <Typography variant="h2" align="left" sx={{ fontWeight: '600' }}>
              <Skeleton variant="text" width={150} />
            </Typography>
            <Typography variant="body2" align="left" sx={{ mb: 2, pt: 2 }}>
              <Skeleton variant="text" width={300} />
              <Skeleton variant="text" width={150} />
            </Typography>
            <Typography variant="h1" align="left" sx={{ fontWeight: '700', mb: 2 }}>
              <Skeleton variant="rectangular" width={100} height={50} />{' '}
              <span style={{ fontSize: '12.5px', color: '#a4a4a4', fontWeight: '500' }}></span>
            </Typography>
            <Typography variant="h4" align="left" sx={{ fontWeight: '600' }}>
              <Skeleton variant="text" width={180} />
            </Typography>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                mt: 4,
                p: 2,
                color: '#FFF',
                backgroundColor: '#e1ac0d',
                '&:hover': {
                  backgroundColor: '#d8a408'
                },
                fontSize: '1rem',
                boxShadow: 'none'
              }}
            >
              <Skeleton variant="text" width={200} />
            </Button>
            <List sx={{ paddingTop: 5 }}>
              <ListItem sx={{ paddingLeft: 0 }}>
                <ListItemIcon>
                  <CheckIcon sx={{ color: '#FFBF00' }} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography sx={{ fontWeight: 'unset' }}>
                      <Skeleton variant="text" width={100} />
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem sx={{ paddingLeft: 0 }}>
                <ListItemIcon>
                  <CheckIcon sx={{ color: '#FFBF00' }} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography sx={{ fontWeight: 'unset' }}>
                      <Skeleton variant="text" width={100} />
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem sx={{ paddingLeft: 0 }}>
                <ListItemIcon>
                  <CheckIcon sx={{ color: '#FFBF00' }} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography sx={{ fontWeight: 'unset' }}>
                      <Skeleton variant="text" width={100} />
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem sx={{ paddingLeft: 0 }}>
                <ListItemIcon>
                  <CheckIcon sx={{ color: '#FFBF00' }} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography sx={{ fontWeight: 'unset' }}>
                      <Skeleton variant="text" width={100} />
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Paper>
        </Grid>
      </Slider>
    </div>
  );
};

export default SubcriptionSliderSkeleton;
