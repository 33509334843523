/* eslint-disable no-restricted-imports */
import {
  Autocomplete,
  Button,
  // DialogActions,
  FormControl,
  // FormHelperText,
  // FormHelperText,
  Grid,
  // InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
// import { useEffect } from 'react';
import { gridSpacing } from 'store/constant';

import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useGetAllMachineNewList } from 'hooks/useMachineHooks';

// import { useTheme } from '@emotion/react';
// import EventIcon from '@mui/icons-material/Event';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
const TicketFilterForm = ({
  errors,
  handleBlur,
  handleChange,
  // handleSubmit,
  // touched,
  values,
  setFieldValue,
  // setTouched,
  resetForm,
  // filterObj,
  // handleCloseFilter,
  setFilterObj,
  touched,
  machineTicketFilters
}) => {
  const handleDateChange = (newValue, fieldName) => {
    if (dayjs(newValue).isValid()) {
      setFieldValue(fieldName, newValue);
    } else if (!newValue) {
      // If cleared manually, set to null
      setFieldValue(fieldName, null);
    }
  };

  const { data: allmachineList } = useGetAllMachineNewList({
    // isActive: true
  });
  const sowField = (filter, index) => {
    if (filter?.show)
      if (filter?.id == 'subject')
        return (
          <Grid
            key={index}
            container
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            // className="select-machine-id inv-number select-machine"
            className="select-machine-id inv-number select-machine"
            alignItems={'center'}
          >
            <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
              Ticket Subject
            </Typography>
            <FormControl
              fullWidth
              variant="outlined"
              // error={Boolean(touched.status && errors.status)}
            >
              <TextField
                // label="Ticket Reason"
                variant="outlined"
                type="text"
                fullWidth
                name="subject"
                value={values?.subject}
                onChange={handleChange}
                placeholder="Search ticket subject"
                // error={Boolean(touched.subject && errors.subject)}
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                // onKeyDown={(e) => {
                //   exceptThisSymbols.includes(e.key) && e.preventDefault();
                // }}
              />
            </FormControl>
          </Grid>
        );
      else if (filter?.id == 'ticketNo') {
        return (
          <Grid container item xs={12} sm={12} md={4} lg={4} xl={4} className="select-machine-id inv-number select-machine" key={index}>
            <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
              Ticket Number
            </Typography>
            <FormControl
              fullWidth
              variant="outlined"
              // error={Boolean(touched.status && errors.status)}
            >
              <TextField
                // label="Ticket Reason"
                variant="outlined"
                type="text"
                fullWidth
                name="ticketNo"
                placeholder="Search ticket no."
                value={values?.ticketNo}
                onChange={handleChange}
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
              />
            </FormControl>
          </Grid>
        );
      } else if (filter?.id == 'status') {
        return (
          <Grid container item xs={12} sm={12} md={4} lg={4} xl={4} className="select-status-filter" key={index}>
            <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
              Status
            </Typography>
            <FormControl
              fullWidth
              variant="outlined"
              // error={Boolean(touched.status && errors.status)}
            >
              {/* <InputLabel>Status</InputLabel> */}
              <Select
                // label="Status"
                value={values?.status}
                onChange={handleChange}
                name="status"
                inputProps={{ 'aria-label': 'Without label' }}
                labelId="retailer-primary-machine-label"
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="Resolved">Resolved</MenuItem>
                <MenuItem value="InReview">In Review</MenuItem>
                <MenuItem value="Cancelled">Cancelled</MenuItem>
                <MenuItem value="Accepted">Accepted</MenuItem>
                <MenuItem value="Rejected">Rejected</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        );
      } else if (filter?.id == 'machineId') {
        return (
          <Grid
            key={index}
            container
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className="select-job-enterprise select-job-enterprise-type-machine-filter"
            // alignItems={'center'}
          >
            <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
              Machine
            </Typography>
            <Autocomplete
              fullWidth
              options={allmachineList?.data || []}
              getOptionLabel={(option) => (option.id ? `${option.machineName ? `${option.machineName}` : ''}` : '')}
              value={allmachineList?.data.find((item) => item.id === values?.machineId) || null}
              onChange={(event, newValue) => {
                handleChange({ target: { name: 'machineId', value: newValue ? newValue.id : '' } });
              }}
              onBlur={handleBlur}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Machine "
                  name="machineId"
                  error={touched?.machineId && Boolean(errors?.machineId)}
                  helperText={touched?.machineId && errors?.machineId}
                />
              )}
              PaperComponent={(props) => <Paper {...props} style={{}} />}
            />
          </Grid>
        );
      } else if (filter?.id == 'createdDate') {
        return (
          <Grid
            key={index}
            container
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            className="select-machine-id inv-number select-machine "
            alignItems={'center'}
          >
            <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
              Date Range
            </Typography>
            <FormControl fullWidth className="form_monthrange">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container spacing={2}>
                  <Grid item xs={6} className="date_range_machine_ticket_line">
                    <DatePicker
                      format="DD/MM/YYYY"
                      value={values.fromDate ? dayjs(values.fromDate) : null}
                      onChange={(newValue) => handleDateChange(newValue, 'fromDate')}
                      maxDate={dayjs()}
                      slotProps={{
                        textField: {
                          helperText: touched.fromDate && errors.fromDate,
                          error: touched.fromDate && Boolean(errors.fromDate),
                          readOnly: true
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DatePicker
                      format="DD/MM/YYYY"
                      value={values.toDate ? dayjs(values.toDate) : null}
                      onChange={(newValue) => handleDateChange(newValue, 'toDate')}
                      minDate={values.toDate || dayjs()}
                      slotProps={{
                        textField: {
                          helperText: touched.toDate && errors.toDate,
                          error: touched.toDate && Boolean(errors.toDate),
                          readOnly: true // Disables manual input
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </LocalizationProvider>
            </FormControl>
          </Grid>
        );
      } else if (filter?.id == 'ticketType') {
        return (
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            // className="select-job-enterprise select_separate2"
            className="select-status-filter"
            key={index}
          >
            <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
              Ticket Type
            </Typography>
            <FormControl fullWidth variant="outlined">
              <Select
                // label="Status"
                value={values?.ticketType}
                onChange={handleChange}
                name="ticketType"
                inputProps={{ 'aria-label': 'Without label' }}
                labelId="retailer-primary-machine-label"
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="Raised">Raised</MenuItem>
                <MenuItem value="Received">Received</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        );
      }
  };
  return (
    <>
      <Grid container spacing={gridSpacing} style={{ marginBottom: '20px', display: 'flex', alignItems: 'center', padding: '0px 20px' }}>
        {machineTicketFilters?.map((filter, index) => sowField(filter, index))}
      </Grid>

      <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'right', paddingBottom: '20px', paddingRight: '20px' }}>
        <Button
          variant="outlined"
          type="reset"
          color="error"
          onClick={() => {
            resetForm();
            setFilterObj({
              subject: '',
              status: '',
              ticketNo: '',

              fromDate: '',
              toDate: '',
              machineId: '',
              ticketType: ''
            });
            // handleCloseFilter();
          }}
        >
          Clear all
        </Button>
        <div style={{ marginLeft: '10px' }}>
          {/* {errors?.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors?.submit}</FormHelperText>
            </Box>
          )} */}
          <Box sx={{ m: 0, position: 'relative' }}>
            <Button
              // fullWidth
              variant="contained"
              type="submit"
              style={{
                color: '#fff'
              }}
              color="primary"
            >
              Search
            </Button>
          </Box>
        </div>
      </div>
    </>
  );
};

export default TicketFilterForm;
