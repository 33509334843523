// MUI Imports
// import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
// import CloseIcon from '@mui/icons-material/Close';
// import IconButton from '@mui/material/IconButton';
import { DialogContentText } from '@mui/material';
import { Box } from '@mui/system';
// import { useNavigate } from 'react-router-dom';

const AlertDialog = ({
  open,
  setOpen,
  title,
  subTitle,
  payload,
  updateMachineSubscription,
  onHandleClose,
  setLoadingChooseSubscription
}) => {
  const handleClose = () => {
    setOpen((prev) => ({ ...prev, open: false, currentPrice: null, sellectedPrice: null }));
  };
  // const navigate = useNavigate();
  //   useEffect(() => {
  //     if (!isDeleteLoading) {
  //       setOpen(false);
  //     }
  //   }, [isDeleteLoading, setOpen]);
  // const [openInvoiceModal, setOpenInvoiceModal] = useState(false);

  // const handleOpenInvoice = () => {
  //   // <Navigate to="/dashboard/default" />;
  //   navigate(`/dashboard/recycler/create/invoices`);
  //   // setOpenInvoiceModal(true);
  // };

  // const handleCloseInvoice = () => {
  //   setOpenInvoiceModal(false); // Close the invoice modal
  // };

  const onHandelSubmit = () => {
    setLoadingChooseSubscription(true);
    updateMachineSubscription(payload, {
      onSuccess: (res) => {
        console.log('res - submitData - Employee : ', res);
        if (res?.status === 200) {
          handleClose();
          /*setOpenDialog((prev) => {
            console.log('prev - setOpenDialog : ', prev);
            return { ...prev, show: false, isLocalUser: false, machineObj: null };
          });*/
          // onHandleClose();
          setTimeout(() => {
            setLoadingChooseSubscription(false);
            onHandleClose();
          }, 500);
        } else {
          setLoadingChooseSubscription(false);
        }
      },
      onError: (error) => {
        console.log('error - submitData - Employee : ', error);
        setLoadingChooseSubscription(false);
      }
    });
  };
  return (
    <>
      <Dialog
        open={open}
        // fullWidth={true}
        fullScreen={false}
        onClose={handleClose}
        // aria-labelledby="alert-dialog-title"
        // aria-describedby="alert-dialog-description"
        maxWidth="md"
        // className="dlte_file_mdlnw machine-settings"
      >
        <DialogTitle
          // id="alert-dialog-title"
          variant="span"
          style={{
            textTransform: 'capitalize'
          }}
        >
          {title}
          {/* <span style={{ position: 'relative', top: '3px' }}>{icon}</span> */}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ fontWeight: 500 }}>
            {subTitle}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          {/* {createInvoice && (
            <Button
              variant="contained"
              type="submit"
              onClick={handleOpenInvoice}
              style={{
                color: '#fff'
              }}
              color="primary"
              // disabled={isDeleteLoading}
            >
              Create Invoice
              {/* {isDeleteLoading && (
              <CircularProgress
                size={24}
                sx={{
                  color: '#16B1FF',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px'
                }}
              />
            )} */}
          {/* </Button>
          )} */}

          <Box sx={{ m: 1, position: 'relative' }}>
            <Button
              fullWidth
              variant="contained"
              type="submit"
              onClick={onHandelSubmit}
              style={{
                color: '#fff'
              }}
              color="primary"
              //   disabled={isDeleteLoading}
            >
              Yes
              {/* {isDeleteLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: '#16B1FF',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px'
                  }}
                />
              )} */}
            </Button>
          </Box>
          <Button variant="outlined" type="reset" color="error" onClick={handleClose}>
            Cancel
          </Button>
          {/* </Box> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AlertDialog;
