/* eslint-disable */
import * as React from 'react';
// import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Link,
  Paper,
  Skeleton,
  Step,
  StepLabel,
  Stepper,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { gridSpacing } from 'store/constant';
import { useNavigate } from 'react-router';
import { useGetMachineById } from 'hooks/useMachineHooks';
import styled from '@emotion/styled';
import { padding } from '@mui/system';
// import MachineStepper from './MachineStepper';
import { useSelector } from 'react-redux';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import BuildIcon from '@mui/icons-material/Build';
// import PersonAddIcon from '@mui/icons-material/PersonAdd'; // Or TransferWithinAStationIcon for client change
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import { useGetEmployeeById } from 'hooks/useEmployeesHooks';
import { getEmployeeFullName } from 'utils/commonUtil';
import { purple } from '@mui/material/colors';
import EmployeeViewModalSkeleton from 'ui-component/cards/Skeleton/EmployeeViewModalSkeleton';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useGetResendConfirmationEmail } from 'hooks/useAuthHooks';

export default function EmployeeModalDialog({ open, setOpen }) {
  // const [open, setOpen] = React.useState(false);
  /*
  const handleClickOpen = () => {
    setOpen(true);
  };*/

  const { data: employeeDetails, isLoading: isLoadingEmployee } = useGetEmployeeById({
    id: open.empId
  });

  const orgType = useSelector((state) => state.customization.orgType);
  // console.log('employeeDetails - EmployeeModalDialog : ', employeeDetails);

  const handleClose = () => {
    setOpen((state) => ({ ...state, open: !state.open }));
  };
  const navigate = useNavigate();
  const { mutate: resendmail, isPending: isLoadingResendEmail } = useGetResendConfirmationEmail();
  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.h4,
    // textAlign: 'center',
    // color: theme.palette.text.secondary,
    // height: 60,
    // lineHeight: '60px',
    padding: '10px'
  }));

  const statusColors = {
    Admin: '#5288b7', //'#FCA311', // Orange for "Build"
    Employee: '#FB5607', // Light Blue for "Preparation"
    Online: '#76baff', // Green for "Online"
    Offline: 'rgb(175 116 255)', // Red for "Offline"
    Maintenance: '#F8BA40' // Grey for "Maintenance"
  };

  const isValidImage = (url) => {
    return url && (url.startsWith('http') || url.startsWith('https'));
  };

  const getAvatarContent = (employeeDetails) => {
    // Check if imageURL is valid, if not fallback to initials
    if (isValidImage(employeeDetails?.image?.url)) {
      return (
        <Avatar
          alt={getEmployeeFullName(employeeDetails)}
          src={employeeDetails.image.url}
          sx={{ width: 250, height: 250, backgroundColor: '#ccc' }}
        />
      );
    } else {
      return (
        <Avatar
          src='src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"'
          sx={{ width: 240, height: 240, border: '1px solid black', backgroundColor: '#f8fafc !important' }}
        ></Avatar>
      );
    }
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'xl'} // Set this to "xs" to make the dialog smaller
        onClose={handleClose}
        open={open.open}
      >
        <DialogTitle
          id="alert-dialog-title"
          variant="span"
          scroll={'papper'}
          style={{
            textTransform: 'capitalize',
            fontSize: '20px', // Adjust the font size for the title
            fontWeight: '700'
          }}
        >
          Employee : {getEmployeeFullName(employeeDetails)}
          {'  '}
          {/* {employeeDetails?.types &&
            Array.isArray(employeeDetails?.types) &&
            employeeDetails?.types.length &&
            employeeDetails?.types[0] === 'Admin' && (
              <Chip
                label={'Admin'}
                sx={{
                  borderRadius: '4px', // Customize the border radius here
                  backgroundColor: statusColors['Admin'],
                  color: '#fff',
                  fontWeight: '500',
                  marginLeft: '20px'
                  // textTransform: 'capitalize'
                }}
              />
            )} */}
          {employeeDetails && (
            <Chip
              // label={employeeDetails?.isConfirmed ? 'Verified' : 'Unverified'}
              label={`Status- ${employeeDetails?.isConfirmed ? 'Verified' : 'Unverified'}`}
              sx={{
                borderRadius: '4px', // Customize the border radius here
                backgroundColor: employeeDetails?.isConfirmed === true ? '#006747' : '#FCA311',
                color: '#fff',
                fontWeight: '500',
                marginLeft: '20px'
              }}
            />
          )}
          {employeeDetails?.types?.map((type, index) => (
            <Chip
              key={index}
              sx={{ borderRadius: '4px', color: '#fff', fontWeight: '500', marginLeft: '20px', backgroundColor: '#a864b3' }}
              label={type}
            />
          ))}
          {employeeDetails?.hasGlobalAccess && (
            <Chip
              label={'Gobal User'}
              sx={{
                borderRadius: '4px', // Customize the border radius here
                backgroundColor: '#8174A0',
                color: '#fff',
                fontWeight: '500',
                marginLeft: '20px'
                // textTransform: 'capitalize'
              }}
            />
          )}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500]
          })}
        >
          <CloseIcon color="error" />
        </IconButton>
        <Divider />
        <DialogContent
          dividers
          className="choose_option_modal"
          style={{
            padding: '8px 16px', // Reduce padding to make the dialog more compact
            boxShadow: 'unset',
            border: 'unset'
          }}
        >
          {/* style={{ display: 'flex', alignItems: 'center' }} */}
          {/* style={{ fontWeight: 'bold' }} */}
          {isLoadingEmployee ? (
            <>
              <EmployeeViewModalSkeleton />
            </>
          ) : (
            <>
              <Grid container spacing={gridSpacing} style={{ paddingTop: '15px' }}>
                <Grid item xs={12} sm={12} md={3} lg={4}>
                  <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}>
                    <div className="address_locationmain" style={{ background: '#6fd74b', mb: 2, padding: '10px 15px' }}>
                      <Typography
                        component="h4"
                        variant="h3"
                        // sx={{ mr: 1, pb: 1.8 }}
                        sx={{ color: '#fff' }}
                      >
                        Employee Profile Image
                      </Typography>
                    </div>
                    <div style={{ padding: '10px 15px 15px', display: 'flex', justifyContent: 'center' }}>
                      {/* Profile Image from URL */}
                      {/* <Avatar
                        alt="Employee Profile Image"
                        src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                        sx={{ width: 250, height: 250, backgroundColor: '#ccc' }}
                      /> */}
                      {/* <Avatar
                        src='src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"'
                        sx={{ width: 250, height: 250, border: '1px solid black', backgroundColor: '#f8fafc !important' }}
                      >
                      </Avatar> */}
                      {getAvatarContent(employeeDetails)}

                      {/* Profile Image with Initials */}
                      {/* <Avatar sx={{ width: 56, height: 56 }}>AB</Avatar> */}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={8} className="employeeModule support_ticket_view_new">
                  <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', height: '100%' }}>
                    <div className="address_locationmain" style={{ background: '#6fd74b', mb: 2, padding: '10px 15px' }}>
                      <Typography
                        component="h4"
                        variant="h3"
                        // sx={{ mr: 1, pb: 1.8 }}
                        sx={{ color: '#fff' }}
                      >
                        Employee Details
                      </Typography>
                    </div>
                    {employeeDetails?.seller !== null ? (
                      <>
                        <div style={{ padding: '0 15px 15px' }}>
                          <Grid container spacing={2} style={{ paddingTop: '10px', paddingBottom: '20px' }}>
                            <Grid item xs={12} sx={{ padding: 0 }}>
                              {/* <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}> */}
                              <div className="address_locationmain" style={{ margin: '2px', padding: '5px', display: 'inherit' }}>
                                <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                                  Name :{' '}
                                </Typography>
                                <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                                  {getEmployeeFullName(employeeDetails)}
                                </Typography>
                              </div>
                              {/* </div> */}
                            </Grid>

                            <Grid item xs={12} sx={{ padding: 0 }}>
                              {/* <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}> */}
                              <div className="address_locationmain" style={{ margin: '2px', padding: '5px', display: 'inherit' }}>
                                <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                                  Title :{' '}
                                </Typography>
                                <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                                  {employeeDetails?.title}
                                </Typography>
                              </div>
                              {/* </div> */}
                            </Grid>

                            <Grid item xs={12} sx={{ padding: 0 }}>
                              {/* <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}> */}
                              <div className="address_locationmain" style={{ margin: '2px', padding: '5px', display: 'inherit' }}>
                                <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                                  Email :{' '}
                                </Typography>
                                <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                                  <Link href={`mailto:${employeeDetails?.email}`} style={{ textDecoration: 'underline', color: 'blue' }}>
                                    {employeeDetails?.email}
                                  </Link>
                                </Typography>
                              </div>
                              {/* </div> */}
                            </Grid>

                            <Grid item xs={12} sx={{ padding: 0 }}>
                              {/* <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}> */}
                              <div className="address_locationmain" style={{ margin: '2px', padding: '5px', display: 'inherit' }}>
                                <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                                  Phone :{' '}
                                </Typography>
                                <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                                  {employeeDetails?.phone || 'N.A.'}
                                </Typography>
                              </div>
                              {/* </div> */}
                            </Grid>

                            <Grid item xs={12} sx={{ padding: 0 }}>
                              {/* <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}> */}
                              <div className="address_locationmain" style={{ margin: '2px', padding: '5px', display: 'inherit' }}>
                                <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                                  Address :{' '}
                                </Typography>
                                <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                                  {employeeDetails?.address || 'N.A.'}
                                </Typography>
                              </div>
                              {/* </div> */}
                            </Grid>
                          </Grid>

                          {orgType && (orgType === 'Enterprise' || orgType === 'Super Admin') && <Divider />}

                          {/* <Typography variant="h4" component="p" style={{ fontWeight: 'bold', paddingTop: 15 }}>
                            For Enterprise{' '}
                          </Typography> */}
                          {orgType && (orgType === 'Enterprise' || orgType === 'Super Admin') && (
                            <Grid container spacing={2} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                              {/* Enterprise */}
                              {orgType && orgType === 'Enterprise' && (
                                <>
                                  <Grid item xs={12} sx={{ padding: 0 }}>
                                    {/* <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}> */}
                                    <div className="address_locationmain" style={{ margin: '2px', padding: '5px', display: 'inherit' }}>
                                      <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                                        RFID :{' '}
                                      </Typography>
                                      <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                                        {employeeDetails?.authenticationInfo ? employeeDetails?.authenticationInfo?.rfidCode : 'N.A.'}
                                      </Typography>
                                    </div>
                                    {/* </div> */}
                                  </Grid>

                                  <Grid item xs={12} sx={{ padding: 0 }}>
                                    {/* <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}> */}
                                    <div className="address_locationmain" style={{ margin: '2px', padding: '5px', display: 'inherit' }}>
                                      <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                                        Auth Pin :{' '}
                                      </Typography>
                                      <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                                        {employeeDetails?.authenticationInfo ? employeeDetails?.authenticationInfo?.authPIN : 'N.A.'}
                                      </Typography>
                                    </div>
                                    {/* </div> */}
                                  </Grid>
                                </>
                              )}
                              {/* Super Admin */}
                              {orgType && orgType === 'Super Admin' && (
                                <>
                                  <Grid item xs={12} sx={{ padding: 0 }}>
                                    {/* <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}> */}
                                    <div className="address_locationmain" style={{ margin: '2px', padding: '5px', display: 'inherit' }}>
                                      <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                                        Installer RFID :{' '}
                                      </Typography>
                                      <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                                        {employeeDetails?.saAuthenticationInfo ? employeeDetails?.saAuthenticationInfo?.rfidCode : 'N.A.'}
                                      </Typography>
                                    </div>
                                    {/* </div> */}
                                  </Grid>

                                  <Grid item xs={12} sx={{ padding: 0 }}>
                                    {/* <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}> */}
                                    <div className="address_locationmain" style={{ margin: '2px', padding: '5px', display: 'inherit' }}>
                                      <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                                        Installer Auth Pin :{' '}
                                      </Typography>
                                      <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                                        {employeeDetails?.saAuthenticationInfo ? employeeDetails?.saAuthenticationInfo?.authPIN : 'N.A.'}
                                      </Typography>
                                    </div>
                                    {/* </div> */}
                                  </Grid>
                                </>
                              )}
                            </Grid>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div style={{ padding: '10px 15px 15px' }}>
                          <Typography variant="h4" component="p" style={{ fontWeight: 'bold' }}>
                            Not Assigned
                          </Typography>
                        </div>
                      </>
                    )}
                  </div>
                </Grid>

                {/* Assign Organization  */}
                {employeeDetails?.assignedOrganizations &&
                  employeeDetails?.assignedOrganizations &&
                  Array.isArray(employeeDetails?.assignedOrganizations) &&
                  employeeDetails?.assignedOrganizations?.length > 0 && (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}>
                        <div className="address_locationmain" style={{ background: '#6fd74b', mb: 2, padding: '10px 15px' }}>
                          <Typography
                            component="h4"
                            variant="h3"
                            // sx={{ mr: 1, pb: 1.8 }}
                            sx={{ color: '#fff' }}
                          >
                            Assigned Organizations
                          </Typography>
                        </div>
                        <div style={{ padding: '10px 15px 15px' }}>
                          <Grid container spacing={gridSpacing} sx={{ padding: '10px 15px' }}>
                            {employeeDetails?.assignedOrganizations.map((organization, index) => (
                              <Grid item xs={12} sm={12} md={4} lg={4} key={index}>
                                <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginBottom: '10px', height: '100%' }}>
                                  <div style={{ background: '#6fd74b', color: '#fff', padding: '10px', fontSize: '16px' }}>
                                    {organization.name || 'N.A.'}
                                  </div>
                                  <div style={{ padding: '10px' }}>
                                    <Typography variant="body2" component={'h6'} sx={{ margin: 0, fontWeight: 'bold' }}>
                                      Address :{' '}
                                    </Typography>
                                    <Typography variant="body2" sx={{ marginTop: '4px' }}>
                                      {organization?.locations && Array.isArray(organization?.locations) && organization?.locations?.length
                                        ? organization?.locations.map((loc, index) => {
                                            const isLast = index === organization.locations.length - 1;
                                            return (
                                              <>
                                                <p style={{ margin: 0 }}>
                                                  {loc?.details?.address?.fullAddress}
                                                  {loc?.isPrimary && (
                                                    <>
                                                      &nbsp;
                                                      <Chip
                                                        label={'Primary'}
                                                        sx={{ backgroundColor: '#6fd74b', color: '#fff' }}
                                                        size={'small'}
                                                      />
                                                    </>
                                                  )}
                                                </p>
                                                {/* Render Divider only if it's not the last element */}
                                                {!isLast && <Divider sx={{ marginBottom: 1, marginTop: 1 }} />}
                                              </>
                                            );
                                          })
                                        : 'N.A.'}{' '}
                                    </Typography>
                                  </div>
                                </div>
                              </Grid>
                            ))}
                          </Grid>
                        </div>
                      </div>
                    </Grid>
                  )}

                {/* Assign Machine   */}
                {employeeDetails?.assignedMachines &&
                  employeeDetails?.assignedMachines &&
                  Array.isArray(employeeDetails?.assignedMachines) &&
                  employeeDetails?.assignedMachines?.length > 0 && (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}>
                        <div className="address_locationmain" style={{ background: '#6fd74b', mb: 2, padding: '10px 15px' }}>
                          <Typography
                            component="h4"
                            variant="h3"
                            // sx={{ mr: 1, pb: 1.8 }}
                            sx={{ color: '#fff' }}
                          >
                            Assigned Machines
                          </Typography>
                        </div>
                        <div style={{ padding: '10px 15px 15px' }}>
                          <Grid container spacing={gridSpacing} sx={{ padding: '10px 15px' }}>
                            {/* <Grid item xs={12} sm={12} md={4} lg={4}>
                              <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginBottom: '10px' }}>
                                <div style={{ background: '#6fd74b', color: '#fff', padding: '10px', fontSize: '16px' }}>
                                  Machine Name 1
                                </div>
                                <div style={{ padding: '10px' }}>
                                  <Typography variant="h6" sx={{ margin: 0 }}>
                                  </Typography>
                                  <Typography variant="body2" sx={{ marginTop: '4px' }}>
                                  </Typography>
                                </div>
                              </div>
                            </Grid> */}
                            {employeeDetails?.assignedMachines.map((m, index) => {
                              // console.log('assignedMachines modal view : ', m);
                              return (
                                <Grid item xs={12} sm={12} md={4} lg={4} key={index}>
                                  <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginBottom: '10px', height: '100%' }}>
                                    <div style={{ background: '#6fd74b', color: '#fff', padding: '10px', fontSize: '16px' }}>
                                      {m?.machineName || 'N.A.'}
                                    </div>
                                    <div style={{ padding: '10px' }}>
                                      <Grid container spacing={2} style={{ paddingTop: '10px', paddingBottom: '20px' }}>
                                        <Grid item xs={12} sx={{ padding: 0 }}>
                                          <Typography variant="h5" component={'span'} sx={{ margin: 0, fontWeight: 700 }}>
                                            Client :{' '}
                                          </Typography>
                                          <Typography variant="h5" component={'span'} sx={{ margin: 0, fontWeight: 'unset' }}>
                                            {m?.client ? m?.client?.name : 'N.A.'}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{ padding: 0 }}>
                                          <Typography variant="h5" component={'span'} sx={{ margin: 0, fontWeight: 700 }}>
                                            NickName :{' '}
                                          </Typography>
                                          <Typography variant="h5" component={'span'} sx={{ margin: 0, fontWeight: 'unset' }}>
                                            {m?.nickName || 'N.A.'}
                                          </Typography>
                                          <br />
                                          <div style={{ marginTop: '8px' }}>
                                            <Typography variant="h5" component={'span'} sx={{ margin: 0, fontWeight: 700 }}>
                                              Serial No. :{' '}
                                            </Typography>
                                            <Typography variant="h5" component={'span'} sx={{ margin: 0, fontWeight: 'unset' }}>
                                              {m?.serialNumber || 'N.A.'}
                                            </Typography>
                                          </div>
                                        </Grid>
                                        <Grid item xs={12} sx={{ padding: 0 }}>
                                          <Typography variant="h5" component={'span'} sx={{ margin: 0, fontWeight: 700 }}>
                                            Location :{' '}
                                          </Typography>
                                          <Typography variant="h5" component={'span'} sx={{ margin: 0, fontWeight: 'unset' }}>
                                            <p style={{ margin: 0, fontWeight: 500 }}>{m?.location?.name || 'N.A.'}</p>
                                            {m?.location?.address?.fullAddress || 'N.A.'}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </div>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </div>
                      </div>
                    </Grid>
                  )}

                {/* Allocate Machine  */}
                {employeeDetails.allocatedMachines &&
                  employeeDetails.allocatedMachines &&
                  Array.isArray(employeeDetails.allocatedMachines) &&
                  employeeDetails.allocatedMachines.length > 0 && (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}>
                        <div className="address_locationmain" style={{ background: '#6fd74b', mb: 2, padding: '10px 15px' }}>
                          <Typography
                            component="h4"
                            variant="h3"
                            // sx={{ mr: 1, pb: 1.8 }}
                            sx={{ color: '#fff' }}
                          >
                            Allocated Machines
                          </Typography>
                        </div>
                        <div style={{ padding: '10px 15px 15px' }}>
                          <Grid container spacing={gridSpacing} sx={{ padding: '10px 15px' }}>
                            {employeeDetails.allocatedMachines.map((m, index) => (
                              <Grid item xs={12} sm={12} md={4} lg={4} key={index}>
                                <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginBottom: '10px', height: '100%' }}>
                                  <div style={{ background: '#6fd74b', color: '#fff', padding: '10px', fontSize: '16px' }}>
                                    {m?.machineName || 'N.A.'}
                                  </div>
                                  <div style={{ padding: '10px' }}>
                                    <Grid container spacing={2} style={{ paddingTop: '10px', paddingBottom: '20px' }}>
                                      <Grid item xs={12} sx={{ padding: 0 }}>
                                        <Typography variant="h5" component={'span'} sx={{ margin: 0, fontWeight: 700 }}>
                                          NickName :{' '}
                                        </Typography>
                                        <Typography variant="h5" component={'span'} sx={{ margin: 0, fontWeight: 'unset' }}>
                                          {m?.nickName || 'N.A.'}
                                        </Typography>
                                        <br />
                                        <div style={{ marginTop: '8px' }}>
                                          <Typography variant="h5" component={'span'} sx={{ margin: 0, fontWeight: 700 }}>
                                            Serial No. :{' '}
                                          </Typography>
                                          <Typography variant="h5" component={'span'} sx={{ margin: 0, fontWeight: 'unset' }}>
                                            {m?.serialNumber || 'N.A.'}
                                          </Typography>
                                        </div>
                                      </Grid>
                                      <Grid item xs={12} sx={{ padding: 0 }}>
                                        <Typography variant="h5" component={'span'} sx={{ margin: 0, fontWeight: 700 }}>
                                          Location :{' '}
                                        </Typography>
                                        <Typography variant="h5" component={'span'} sx={{ margin: 0, fontWeight: 'unset' }}>
                                          <p style={{ margin: 0, fontWeight: 500 }}>{m?.location?.name || 'N.A.'}</p>
                                          {m?.location?.address?.fullAddress || 'N.A.'}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </Grid>
                            ))}
                          </Grid>
                        </div>
                      </div>
                    </Grid>
                  )}
              </Grid>
              {!employeeDetails?.isConfirmed && (
                <DialogActions>
                  <Box sx={{ position: 'relative' }}>
                    <AnimateButton>
                      <Button
                        variant="contained"
                        type="submit"
                        sx={{
                          backgroundColor: '#6fd74b',
                          color: '#fff',
                          '&:hover': {
                            backgroundColor: '#6fd74b'
                          },
                          minWidth: '200px',
                          my: '5px'
                          // margin: '0px auto'
                        }}
                        disabled={isLoadingResendEmail}
                        onClick={() => {
                          resendmail(
                            { id: employeeDetails?.id },
                            {
                              onSuccess: () => {
                                setOpen(false);
                              }
                            }
                          );
                        }}
                      >
                        {isLoadingResendEmail ? (
                          <CircularProgress size={20} sx={{ color: 'white', width: 20, height: 20 }} />
                        ) : (
                          <>Resend Confirmation Email</>
                        )}
                      </Button>
                    </AnimateButton>
                  </Box>
                </DialogActions>
              )}
              {/* <Grid container spacing={gridSpacing} style={{ paddingTop: '30px' }}>
                <Grid item xs={12}>
                  <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}>
                    <div className="address_locationmain" style={{ background: '#6fd74b', mb: 2, padding: '10px 15px' }}>
                      <Typography component="h4" variant="h3" sx={{ color: '#fff' }}>
                        Machine Location
                      </Typography>
                    </div>
                    <Grid container spacing={gridSpacing} className="padding-address-new">
                      {employeeDetails?.locations?.map((item, i) => (
                        <Grid item lg={12} key={i}>
                          <div className="addrs-new-bx-strt">
                            Address line {i + 1}
                            <div style={{ padding: '20px' }}>
                              <h5>{item?.details?.name}</h5>
                              <p>{item?.details?.address?.fullAddress}</p>
                            </div>
                          </div>
                        </Grid>
                      ))}
                      <Grid item lg={12} className="machineViewAddress">
                        <div style={{ padding: '10px' }} className="addrs-new-bx-strt">
                          <Typography variant="h4" component="p" style={{ fontWeight: 'bold' }}>
                            {employeeDetails?.location?.name}
                          </Typography>
                          <Typography variant="h4" component="p" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                            {employeeDetails?.location?.address?.fullAddress}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid> */}

              {/* <Grid container spacing={gridSpacing} style={{ paddingTop: '30px' }}>
                <Grid item xs={12}>
                  <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}>
                    <div className="address_locationmain" style={{ background: '#6fd74b', mb: 2, padding: '10px 15px' }}>
                      <Typography
                        component="h4"
                        variant="h3"
                        // sx={{ mr: 1, pb: 1.8 }}
                        sx={{ color: '#fff' }}
                      >
                        Machine Information
                      </Typography>
                    </div>
                    <Grid container spacing={gridSpacing} style={{ paddingTop: '10px', paddingBottom: '20px' }}>
                      <Grid item xs={12} sm={6} md={4} lg={4} sx={{ padding: 0 }}>
                        <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginLeft: 15, marginRight: 15 }}>
                          <div className="address_locationmain" style={{ margin: 5, padding: '5px', display: 'inherit' }}>
                            <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                              Machine Type :{' '}
                            </Typography>
                            <Typography variant="h4" component="sapn" style={{ fontWeight: 'unset', paddingTop: 10 }}>
                              {employeeDetails?.type}
                            </Typography>
                          </div>
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4} lg={4} sx={{ padding: 0 }}>
                        <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginLeft: 15, marginRight: 15 }}>
                          <div className="address_locationmain" style={{ margin: 5, padding: '5px', display: 'inherit' }}>
                            <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                              Nickname :{' '}
                            </Typography>
                            {employeeDetails?.nickName && (
                              <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 10 }}>
                                {employeeDetails?.nickName}
                              </Typography>
                            )}
                          </div>
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4} lg={4} sx={{ padding: 0 }}>
                        <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginLeft: 15, marginRight: 15 }}>
                          <div className="address_locationmain" style={{ margin: 5, padding: '5px', display: 'inherit' }}>
                            <Typography variant="h4" component="p" style={{ fontWeight: 'bold' }}>
                              Machine Model :{' '}
                            </Typography>
                            {employeeDetails?.model && (
                              <Typography variant="h4" component="p" style={{ fontWeight: 'unset', paddingTop: 10 }}>
                                {employeeDetails?.model}
                              </Typography>
                            )}
                          </div>
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4} lg={4} sx={{ padding: 0 }}>
                        <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginLeft: 15, marginRight: 15 }}>
                          <div className="address_locationmain" style={{ margin: 5, padding: '5px', display: 'inherit' }}>
                            <Typography variant="h4" component="p" style={{ fontWeight: 'bold' }}>
                              Machine Software :{' '}
                            </Typography>
                            {employeeDetails?.softwareVersions && Array.isArray(employeeDetails?.softwareVersions) && (
                              <Typography variant="h4" component="p" style={{ fontWeight: 'unset', paddingTop: 10 }}>
                                {employeeDetails?.softwareVersions && Array.isArray(employeeDetails?.softwareVersions)
                                  ? employeeDetails?.softwareVersions.join(',')
                                  : ''}
                              </Typography>
                            )}
                          </div>
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4} lg={4} sx={{ padding: 0 }}>
                        <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginLeft: 15, marginRight: 15 }}>
                          <div className="address_locationmain" style={{ margin: 5, padding: '5px', display: 'inherit' }}>
                            <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                              Bins :{' '}
                            </Typography>
                            <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 10 }}>
                              {employeeDetails?.bins}
                            </Typography>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid> */}

              {/* <Grid container spacing={gridSpacing} style={{ paddingTop: '30px' }}>
                <Grid item xs={12}>
                  <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}>
                    <div className="address_locationmain" style={{ background: '#6fd74b', mb: 2, padding: '10px 15px' }}>
                      <Typography
                        component="h4"
                        variant="h3"
                        // sx={{ mr: 1, pb: 1.8 }}
                        sx={{ color: '#fff' }}
                      >
                        Machine Invoice
                      </Typography>
                    </div>
                    <Grid container spacing={gridSpacing} style={{ paddingTop: '10px', paddingBottom: '20px' }}>
                      <Grid item xs={12} sm={6} md={6} lg={6} sx={{ padding: 0 }}>
                        <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginLeft: 15, marginRight: 15 }}>
                          <div className="address_locationmain" style={{ margin: 5, padding: '5px', display: 'inherit' }}>
                            <Typography variant="h4" component="p" style={{ fontWeight: 'bold' }}>
                              Machine Invoice Number :{' '}
                            </Typography>
                            {employeeDetails?.invoiceNumber && (
                              <Typography variant="h4" component="p" style={{ fontWeight: 'unset', paddingTop: 10 }}>
                                {employeeDetails?.invoiceNumber}
                              </Typography>
                            )}
                          </div>
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6} lg={6} sx={{ padding: 0 }}>
                        <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginLeft: 15, marginRight: 15 }}>
                          <div className="address_locationmain" style={{ margin: 5, padding: '5px', display: 'inherit' }}>
                            <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                              Machine Invoice Status :{' '}
                            </Typography>
                            <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 10 }}>
                              {employeeDetails?.status}
                            </Typography>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid> */}

              {/* <Grid container spacing={gridSpacing} style={{ paddingTop: '30px' }}>
                <Grid item xs={12}>
                  <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}>
                    <div className="address_locationmain" style={{ background: '#6fd74b', mb: 2, padding: '10px 15px' }}>
                      <Typography
                        component="h4"
                        variant="h3"
                        // sx={{ mr: 1, pb: 1.8 }}
                        sx={{ color: '#fff' }}
                      >
                        Machine Configuration
                      </Typography>
                    </div>
                    <Grid container spacing={gridSpacing} style={{ paddingTop: '10px', paddingBottom: '20px' }}>
                      <Grid item xs={12} sm={6} md={6} lg={6} sx={{ padding: 0 }}>
                        <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginLeft: 15, marginRight: 15 }}>
                          <div className="address_locationmain" style={{ margin: 5, padding: '5px', display: 'inherit' }}>
                            <Typography variant="h4" component="p" style={{ fontWeight: 'bold' }}>
                              Machine API Key :{' '}
                            </Typography>
                            {employeeDetails?.apiKey && (
                              <Typography variant="h4" component="p" style={{ fontWeight: 'unset', paddingTop: 10 }}>
                                {employeeDetails?.apiKey}
                              </Typography>
                            )}
                          </div>
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6} lg={6} sx={{ padding: 0 }}>
                        <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginLeft: 15, marginRight: 15 }}>
                          <div className="address_locationmain" style={{ margin: 5, padding: '5px', display: 'inherit' }}>
                            <Typography variant="h4" component="p" style={{ fontWeight: 'bold' }}>
                              Machine Serial Number :{' '}
                            </Typography>
                            {employeeDetails?.serialNumber && (
                              <Typography variant="h4" component="p" style={{ fontWeight: 'unset', paddingTop: 10 }}>
                                {employeeDetails?.serialNumber}
                              </Typography>
                            )}
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid> */}
            </>
          )}

          {/* display: 'flex', alignItems: 'center', */}
          {/* style={{ textAlign: 'center' }} */}
          {/* <Grid container spacing={2} className="machineViewBtnContainer">
            {orgType !== 'Recycler' ? (
              <>
                <div className="machineViewBtnWrap">
                  <Button
                    disableElevation
                    size="large"
                    type="button"
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      // navigate(urlAddBatch);
                    }}
                    className="machineViewBtn"
                    style={{ backgroundColor: '#1976d2' }} // Adjust button padding and font size
                  >
                    <HelpOutlineIcon />
                    &nbsp; Raise Ticket
                  </Button>
                  <Button
                    disableElevation
                    size="large"
                    type="button"
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      // navigate(urlSingle);
                    }}
                    className="machineViewBtn"
                    style={{ backgroundColor: '#0288d1' }} // Adjust button padding and font size
                  >
                    <RemoveRedEyeIcon />
                    &nbsp; View Ticket
                  </Button>
                </div>
                <div className="machineViewBtnWrap">
                  <Button
                    disableElevation
                    size="large"
                    type="button"
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      // setOpenLeaseMachineModal((state) => !state);
                      navigate('/dashboard/machines/rent-machine', { state: { employeeDetails: employeeDetails } });
                    }}
                    className="machineViewBtn"
                    style={{ backgroundColor: '#ff9800' }} // Adjust button padding and font size
                  >
                    <BuildIcon />
                    &nbsp; Rent Machine
                  </Button>
                  <Button
                    disableElevation
                    size="large"
                    type="button"
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      // navigate(urlAddBatch);
                    }}
                    className="machineViewBtn"
                    style={{ backgroundColor: '#4caf50' }} // Adjust button padding and font size
                  >
                    <TransferWithinAStationIcon />
                    &nbsp; Change Client
                  </Button>
                </div>
              </>
            ) : (
              <>
                {orgType === 'Recycler' && toggleBoughtRentedAssigned === 'Bought' && (
                  <>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      <Button
                        disableElevation
                        size="large"
                        type="button"
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          // setOpenLeaseMachineModal((state) => !state);
                          navigate('/dashboard/machines/rent-machine', { state: { employeeDetails: employeeDetails } });
                        }}
                        className="machineViewBtn"
                        style={{ backgroundColor: 'rgb(111, 186, 85)' }} // Adjust button padding and font size
                      >
                        <BuildIcon />
                        &nbsp; Rent Machine
                      </Button>
                    </Grid>
                  </>
                )}

                {orgType === 'Recycler' && toggleBoughtRentedAssigned === 'Rented' && (
                  <>
                    <Grid item xs={6} sm={4} md={4} lg={4}>
                      <Button
                        disableElevation
                        size="large"
                        type="button"
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          // navigate(urlAddBatch);
                        }}
                        className="machineViewBtn"
                        style={{ backgroundColor: 'rgb(111, 186, 85)' }} // Adjust button padding and font size
                      >
                        <TransferWithinAStationIcon />
                        &nbsp; Change Client
                      </Button>
                    </Grid>
                  </>
                )}

                {orgType === 'Recycler' && toggleBoughtRentedAssigned === 'Assigned' && (
                  <>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      <Button
                        disableElevation
                        size="large"
                        type="button"
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          // navigate(urlSingle);
                        }}
                        style={{ backgroundColor: 'rgb(111, 186, 85)' }} // Adjust button padding and font size
                      >
                        <RemoveRedEyeIcon />
                        &nbsp; View Ticket
                      </Button>
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid> */}
        </DialogContent>
      </Dialog>
    </>
  );
}
