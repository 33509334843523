import React from 'react';
import { Formik } from 'formik';

import MainCard from 'ui-component/cards/MainCard';

import BreadcrumbsForPage from 'ui-component/extended/BreadcrumbsForPage';
import * as Yup from 'yup';
import 'yup-phone';

import { useLocation, useParams } from 'react-router-dom';
// import FormView from 'ui-component/cards/Skeleton/FormView';
import { useState, useEffect } from 'react';
// import { useGetOrgById, useGetOrgEditedList } from 'hooks/useOrganizationHooks';
import { useGetLocationNewId, useGetPermissionsLocationsList, useLocationNewUpdate } from 'hooks/useLocationHooks';
import LocationForm from '../location-form';
import OrganizationFormView from 'ui-component/cards/Skeleton/OrganizationFormView';
import { useSelector } from 'react-redux';
import { setToast, toastConfig } from 'utils/commonUtil';
// import { useLocationUpdate, useGetRecycleId } from 'hooks/useRecycleHooks';

const LocationEdit = () => {
  let { id } = useParams();
  // console.log('🚀 ~ LocationEdit ~ id:', id);
  const { data: locationDetails } = useGetLocationNewId({
    id: id.slice(1)
  });
  const location = useLocation();
  // const navigate = useNavigate();

  // Extract values from the state
  const {
    // permissionsList,
    locationToggledButton
  } = location.state || {};
  const organizationType = useSelector((state) => state.customization.orgType);
  const usersType = useSelector((state) => state.customization.userType);
  const {
    data: permissionsList

    // isLoading: isLoadingPermissionsList
  } = useGetPermissionsLocationsList({
    organizationType:
      organizationType === 'Service Provider' ? 'ServiceProvider' : organizationType !== 'Super Admin' ? organizationType : 'SuperAdmin',
    // usersType: usersType === 'Main POC' ? 'POC' : 'POC'
    usersType: usersType === 'Main POC' || usersType === 'Secondary POC' ? 'POC' : usersType
  });

  const editPermissionsList = permissionsList?.edit || [];
  // console.log('🚀 ~ addPermissionsList:', editPermissionsList, 'isLoadingPermissionsList', isLoadingPermissionsList);

  const { mutate: updateLocation, isPending: isLoadingUpdateLocation } = useLocationNewUpdate();

  const [isLoading, setLoading] = useState(true);
  const [locationLatLng, setLocationLatLng] = useState({
    lat: '',
    lng: '',
    label: ''
  });
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const initials = {
    location_name: '',
    // address: '',
    // city: '',
    // state: '',
    // country: '',
    // zip: '',
    location: '',
    placeId: '',
    latitude: '',
    longitude: '',
    tax: '',
    state: '',
    country: '',
    city: '',
    postalCode: '',
    submit: null
  };
  const dynamicalidationSchema = Yup.object(
    editPermissionsList.reduce((schema, filter) => {
      if (filter?.show) {
        switch (filter.id) {
          case 'locationName':
            schema['location_name'] = Yup.string().max(50, 'Location Name must be at most 50 characters').trim();
            if (filter.isRequired && !filter?.isStatic) {
              schema['location_name'] = schema['location_name'].required('Location Name is required');
            }
            break;
          case 'address':
            schema['location'] = Yup.string();
            if (filter.isRequired && !filter?.isStatic) {
              schema['location'] = schema['location'].required('Address is required');
            }
            break;
          case 'tax':
            schema['tax'] = Yup.number()
              .positive('Tax must contain a positive number')
              .max(100, 'Maximum allowed value is 100')
              .test('is-decimal', 'Tax must have at most two decimal places', (value) => /^\d+(\.\d{1,2})?$/.test(value))
              .typeError('Tax must be a number');
            if (filter.isRequired && !filter?.isStatic) {
              schema['tax'] = schema['tax']
                .when('areSeatsLimited', {
                  is: 'yes',
                  then: Yup.number().required().typeError('Tax must be a number'),
                  otherwise: Yup.number().notRequired().typeError('Tax must be a number')
                })
                .required('Tax is required');
            }
            break;

          case 'city':
            schema['city'] = Yup.string();
            if (filter.isRequired && !filter?.isStatic) {
              schema['city'] = schema['city'].required('City is required');
            }
            break;
          case 'country':
            schema['country'] = Yup.string();
            if (filter.isRequired && !filter?.isStatic) {
              schema['country'] = schema['country'].required('Country is required');
            }
            break;
          case 'state':
            schema['state'] = Yup.string();
            if (filter.isRequired && !filter?.isStatic) {
              schema['state'] = schema['state'].required('State is required');
            }
            break;
          case 'postalCode':
            schema['postalCode'] = Yup.string();
            if (filter.isRequired && !filter?.isStatic) {
              schema['postalCode'] = schema['postalCode'].required('Postal Code is required');
            }
            break;
          default:
            break;
        }
      }
      return schema;
    }, {})
  );

  return (
    <>
      {isLoading ? (
        <>
          <OrganizationFormView />
        </>
      ) : (
        <>
          <BreadcrumbsForPage
            name={`Edit Location ${locationToggledButton ? `(${locationToggledButton.split(' ')?.[0]})` : ''}`}
            obj={{ title: 'All Locations', title2: '', url: '/dashboard/location' }}
          />
          <MainCard>
            <Formik
              initialValues={initials}
              validationSchema={dynamicalidationSchema}
              onSubmit={async (value) => {
                if (!value.location || !value.placeId) {
                  toastConfig.type = 'error';
                  setToast(toastConfig, 'Oops! Enter your full address');
                  return;
                }
                const submitData = {
                  id: id.slice(1),
                  name: value?.location_name.trim(),
                  address: {
                    fullAddress: value?.location.trim(),
                    placeId: value?.placeId,
                    coOrdinates: {
                      latitude: value?.latitude,
                      longitude: value?.longitude
                    },
                    state: value?.state,
                    countryName: value?.country,
                    city: value?.city,
                    postalCode: value?.postalCode
                  },
                  taxAmount: value?.tax
                };

                updateLocation(submitData);
                // refetchDetails();
              }}
            >
              {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, setErrors }) => (
                <LocationForm
                  btnName="Update"
                  errors={errors}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting}
                  touched={touched}
                  values={values}
                  setFieldValue={setFieldValue}
                  locationDetails={locationDetails}
                  isLoadingLocation={isLoadingUpdateLocation}
                  locationLatLng={locationLatLng}
                  setLocationLatLng={setLocationLatLng}
                  // id={id}
                  permissionsList={editPermissionsList}
                  isEditable={true}
                  setErrors={setErrors}
                />
              )}
            </Formik>
          </MainCard>
        </>
      )}
    </>
  );
};

export default LocationEdit;
