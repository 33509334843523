import {
  Dialog,
  DialogContent,
  DialogTitle,
  // FormControl,
  Grid,
  IconButton,
  // InputLabel,
  // OutlinedInput,
  Typography,
  Box,
  Chip,
  Skeleton,
  Link,
  DialogActions,
  Button
} from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { gridSpacing } from 'store/constant';

// import FormView from 'ui-component/cards/Skeleton/FormView';
import { styled } from '@mui/material/styles';
import DeleteDialog from 'ui-component/delete-dialog';
import { useLocation } from 'react-router-dom';
import { setToast, toastConfig } from 'utils/commonUtil';
import { useQueryClient } from '@tanstack/react-query';
// import { useRetailerTicketStatusUpdate } from 'hooks/useRetailerUserHooks';
import ViewTicketSkeleton from 'ui-component/cards/Skeleton/ViewTicketSkeleton';
import { useGetMachineTicketById, useGetRejectReasonTicketStatus } from 'hooks/useMachineTicketHooks';
import RejectTicketModal from '../ticket-modal';

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  margin: '0 auto',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626'
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959'
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343'
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c'
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff'
  }
}));
const ViewTicket = ({ open, setOpen, details }) => {
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false); //status=== Resolved
  const [openRejectModal, setOpenRejectModal] = useState(false); //status === Rejected
  const [openCancelModal, setOpenCancelModal] = useState(false); // status ==Cancelled
  const [openAcceptModal, setopenAcceptModal] = useState(false); //status== Accepted
  const [openInReview, setOpenInReview] = useState(false); //status == In Review
  const queryClient = useQueryClient();
  const location = useLocation();
  // Use URLSearchParams to extract the query parameters from the URL
  const queryParams = new URLSearchParams(location?.search);
  const viewTicketID = queryParams?.get('viewTicketID');
  details = {
    ...details,
    id: details?.id ? details?.id : viewTicketID
  };
  const {
    data: ticketDetails,
    isPending: isLoadingTicketDetails,
    refetch: refetchTicketDetails
  } = useGetMachineTicketById({
    id: details?.id
  });
  // console.log('🚀 ~ ViewTicket ~ ticketDetails:', ticketDetails);
  const ticketAllDetails = ticketDetails?.ticket;
  // console.log('🚀 ~ ViewTicket ~ ticketAllDetails:', ticketAllDetails);
  const ticketPermission = ticketDetails?.permissions;
  // console.log('🚀 ~ ViewTicket ~ ticketPermission:', ticketPermission);

  const { mutate: updatedStatusTicket, isPending: isLoadingUpdateTicketStatus } = useGetRejectReasonTicketStatus();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        fullWidth={false}
        fullScreen={false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
      >
        <DialogTitle
          id="alert-dialog-title"
          variant="span"
          style={{
            textTransform: 'capitalize'
          }}
        >
          {isLoadingTicketDetails ? (
            <>
              <Skeleton variant="text" width={300} />
            </>
          ) : (
            <>
              {`View Machine Ticket : ${ticketAllDetails?.number}`}{' '}
              <Chip
                size="small"
                label={
                  ticketAllDetails?.status === 'Pending'
                    ? 'Pending'
                    : ticketAllDetails?.status === 'InReview'
                    ? 'In Review'
                    : ticketAllDetails?.status === 'Cancelled'
                    ? 'Cancelled'
                    : ticketAllDetails?.status === 'Rejected'
                    ? 'Rejected'
                    : ticketAllDetails?.status === 'Accepted'
                    ? 'Accepted'
                    : 'Resolved'
                }
                style={{
                  color: '#fff',
                  fontWeight: '400',
                  marginRight: '10px',
                  backgroundColor:
                    ticketAllDetails?.status === 'Pending'
                      ? '#ae30c2'
                      : ticketAllDetails?.status === 'InReview'
                      ? '#dbc115'
                      : ticketAllDetails?.status === 'Cancelled'
                      ? '#d84315'
                      : ticketAllDetails?.status === 'Rejected'
                      ? '#f44336'
                      : ticketAllDetails?.status === 'Accepted'
                      ? '#3d5afe'
                      : '#6fd74b'
                }}
              />
              <Chip
                size="small"
                label={`Priority : ${
                  ticketAllDetails?.priority === 'High' ? 'High' : ticketAllDetails?.priority === 'Low' ? 'Low' : 'Medium'
                }`}
                style={{
                  color: '#fff',
                  fontWeight: '400',
                  backgroundColor:
                    ticketAllDetails?.priority === 'High' ? '#c62828' : ticketAllDetails?.priority === 'Low' ? '#1bb134' : '#ffc107'
                }}
              />
            </>
          )}

          {['Raised', 'Received'].includes(ticketAllDetails?.ticketType) && (
            <Chip
              size="small"
              label={`Ticket Type: ${ticketAllDetails?.ticketType}`}
              style={{
                color: '#000',
                fontWeight: '500',
                marginLeft: '8px',
                backgroundColor: ticketAllDetails?.ticketType === 'Raised' ? '#FFE3D6' : '#DFFCB0'
              }}
            />
          )}
        </DialogTitle>
        {isLoadingTicketDetails ? (
          <>
            <Skeleton
              variant="circular"
              width={20}
              height={20}
              sx={(theme) => ({
                position: 'absolute',
                right: 10,
                top: 15,
                color: theme.palette.grey[500]
              })}
            />
          </>
        ) : (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={(theme) => ({
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme.palette.grey[500]
            })}
          >
            <CloseIcon color="error" />
          </IconButton>
        )}

        <DialogContent dividers>
          {isLoadingTicketDetails ? (
            <ViewTicketSkeleton />
          ) : (
            <Grid container spacing={gridSpacing} sx={{ alignContent: 'center' }}>
              <Grid item xs={12}>
                <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}>
                  <div className="address_locationmain" style={{ background: '#6fd74b', marginBottom: '16px', padding: '10px 15px' }}>
                    <Typography component="h4" variant="h3" sx={{ color: '#fff' }}>
                      Machine Ticket Details
                    </Typography>
                  </div>
                  <div style={{ padding: '0px 15px 15px' }}>
                    <Grid container spacing={gridSpacing}>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="support_ticket_view_new">
                        <div style={{ display: 'flex', marginBottom: '10px' }}>
                          {' '}
                          <Typography component="p" variant="h4" sx={{ fontWeight: '600', marginRight: '4px', minWidth: '200px' }}>
                            Subject :
                          </Typography>
                          <span
                            style={{
                              fontSize: '15px'
                            }}
                          >
                            {ticketAllDetails?.subject || 'N.A.'}
                          </span>
                        </div>

                        <div style={{ display: 'flex', marginBottom: '10px' }}>
                          {' '}
                          <Typography component={'p'} variant="h4" sx={{ fontWeight: '600', marginRight: '4px', minWidth: '200px' }}>
                            Category :
                          </Typography>
                          <span
                            style={{
                              fontSize: '15px'
                            }}
                          >
                            {ticketAllDetails?.ticketCategory?.category || 'N.A.'}
                          </span>
                        </div>

                        <div style={{ display: 'flex', marginBottom: '10px' }}>
                          {' '}
                          <Typography component={'p'} variant="h4" sx={{ fontWeight: '600', marginRight: '4px', minWidth: '200px' }}>
                            Machine :
                          </Typography>
                          <span
                            style={{
                              fontSize: '15px'
                            }}
                          >
                            {ticketAllDetails?.machine?._id
                              ? `${ticketAllDetails.machine._id} ${
                                  ticketAllDetails.machine.nickName ? '(' + ticketAllDetails.machine.nickName + ')' : ''
                                }`
                              : 'N.A.'}
                          </span>
                        </div>

                        <div style={{ display: 'flex', marginBottom: '10px' }}>
                          {' '}
                          <Typography component={'p'} variant="h4" sx={{ fontWeight: '600', marginRight: '4px', minWidth: '200px' }}>
                            Notes :
                          </Typography>
                          <span
                            style={{
                              fontSize: '15px'
                            }}
                          >
                            {ticketAllDetails?.notes || 'N.A.'}
                          </span>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                {/* Raised By */}
                <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginTop: '12px' }}>
                  <div className="address_locationmain" style={{ background: '#6fd74b', marginBottom: '16px', padding: '10px 15px' }}>
                    <Typography component="h4" variant="h3" sx={{ color: '#fff' }}>
                      Raised By
                    </Typography>
                  </div>
                  <div style={{ padding: '0px 15px 15px' }}>
                    <Grid container spacing={gridSpacing}>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="support_ticket_view_new">
                        <div style={{ display: 'flex', marginBottom: '10px' }}>
                          {' '}
                          <Typography component="p" variant="h4" sx={{ fontWeight: '600', marginRight: '4px', minWidth: '200px' }}>
                            Name:
                          </Typography>
                          <span
                            style={{
                              fontSize: '15px'
                            }}
                          >
                            {ticketAllDetails?.createdBy?.organization?.name || 'N.A.'}
                          </span>
                        </div>

                        <div style={{ display: 'flex', marginBottom: '10px' }}>
                          {' '}
                          <Typography component={'p'} variant="h4" sx={{ fontWeight: '600', marginRight: '4px', minWidth: '200px' }}>
                            Contact :
                          </Typography>
                          {/* <span
                            style={{
                              fontSize: '15px'
                            }}
                          >
                            {ticketAllDetails?.createdBy
                              ? `${ticketAllDetails.createdBy.firstName} ${ticketAllDetails.createdBy.lastName} (${ticketAllDetails.createdBy.email}, ${ticketAllDetails.createdBy.phone})`
                              : 'N.A.'}
                          </span> */}
                          <span style={{ fontSize: '15px' }}>
                            {ticketAllDetails?.createdBy ? (
                              <>
                                {ticketAllDetails?.createdBy?.firstName} {ticketAllDetails?.createdBy?.lastName} (
                                <a href={`mailto:${ticketAllDetails?.createdBy?.email}`}>{ticketAllDetails?.createdBy?.email}</a>,{' '}
                                {ticketAllDetails?.createdBy?.phone})
                              </>
                            ) : (
                              'N.A.'
                            )}
                          </span>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                {/* Raised To */}

                <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginTop: '12px' }}>
                  <div className="address_locationmain" style={{ background: '#6fd74b', marginBottom: '16px', padding: '10px 15px' }}>
                    <Typography component="h4" variant="h3" sx={{ color: '#fff' }}>
                      Raised To
                    </Typography>
                  </div>
                  <div style={{ padding: '0px 15px 15px' }}>
                    {ticketAllDetails?.raisedTo?.map((item, index) => (
                      <>
                        <Grid container spacing={gridSpacing}>
                          <Grid item lg={12} md={12} sm={12} xs={12} className="support_ticket_view_new">
                            <Grid item lg={12} sm={12} md={12} key={index}>
                              <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginBottom: '10px' }}>
                                <div style={{ padding: '10px', fontSize: '16px' }}>
                                  <div style={{ display: 'flex', marginBottom: '10px' }}>
                                    {' '}
                                    <Typography
                                      component="p"
                                      variant="h4"
                                      sx={{ fontWeight: '600', marginRight: '4px', minWidth: '200px' }}
                                    >
                                      Name:
                                    </Typography>
                                    <span
                                      style={{
                                        fontSize: '15px'
                                      }}
                                    >
                                      {item?.org?.name || 'N.A.'}
                                    </span>
                                  </div>

                                  <div style={{ display: 'flex', marginBottom: '10px' }}>
                                    {' '}
                                    <Typography
                                      component={'p'}
                                      variant="h4"
                                      sx={{ fontWeight: '600', marginRight: '4px', minWidth: '200px' }}
                                    >
                                      Contact :
                                    </Typography>
                                    {/* <span
                                      style={{
                                        fontSize: '15px'
                                      }}
                                    >
                                      {item?.org?.mainPOC
                                        ? `${item?.org?.mainPOC.firstName} ${item?.org?.mainPOC.lastName} (${item?.org?.mainPOC.email}, ${item?.org?.mainPOC.phone})`
                                        : 'N.A.'}
                                    </span> */}
                                    <span style={{ fontSize: '15px' }}>
                                      {item?.org?.mainPOC ? (
                                        <>
                                          {item.org.mainPOC.firstName} {item.org.mainPOC.lastName} (
                                          <a href={`mailto:${item.org.mainPOC.email}`}>{item.org.mainPOC.email}</a>,{' '}
                                          {item.org.mainPOC.phone})
                                        </>
                                      ) : (
                                        'N.A.'
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    ))}
                  </div>
                </div>

                <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginTop: '12px' }}>
                  <div>
                    <div className="address_locationmain" style={{ background: '#6fd74b', mb: 2, padding: '10px 15px' }}>
                      <Typography component="h4" variant="h3" sx={{ color: '#fff' }}>
                        Related Files/Documents
                      </Typography>
                    </div>

                    <Grid
                      spacing={gridSpacing}
                      container
                      item
                      xs={12}
                      sm={12}
                      style={{
                        // marginTop: '10px',
                        padding: '10px 15px 15px'
                      }}
                    >
                      {ticketAllDetails?.files?.length > 0 ? (
                        <>
                          {ticketAllDetails?.files?.map((item, i) => (
                            <Grid item xs={12} md={4} key={i} lg={4} sm={4}>
                              <div className="chse-prefix-newbx">
                                <Typography variant="span" component="h3">
                                  <Link
                                    href={item?.url}
                                    target="_blank"
                                    sx={{
                                      color: '#fff',
                                      textDecoration: 'none'
                                    }}
                                  >
                                    View
                                  </Link>
                                </Typography>
                                {!!item?.mimetype && item?.mimetype?.startsWith('application/pdf') ? (
                                  <>
                                    <iframe
                                      src={`${item?.url}#toolbar=0`}
                                      height="500px"
                                      width="100%"
                                      title={item?.name}
                                      style={{ border: 'none', overflow: 'hidden', cursor: 'pointer' }}
                                    ></iframe>{' '}
                                  </>
                                ) : (
                                  <>
                                    <img
                                      src={item?.url}
                                      alt={item?.name}
                                      style={{
                                        width: '100%',
                                        cursor: 'pointer'
                                      }}
                                    />
                                  </>
                                )}
                                <div className="chse-prefix-captionbx">
                                  <div className="chse-prefix-caption-first">
                                    <h4>{item?.name}</h4>
                                  </div>
                                  <div></div>
                                </div>
                              </div>
                            </Grid>
                          ))}
                        </>
                      ) : (
                        <>
                          <StyledGridOverlay>
                            <svg style={{ flexShrink: 0 }} width="240" height="200" viewBox="0 0 184 152" aria-hidden focusable="false">
                              <g fill="none" fillRule="evenodd">
                                <g transform="translate(24 31.67)">
                                  <ellipse className="ant-empty-img-5" cx="67.797" cy="106.89" rx="67.797" ry="12.668" />
                                  <path
                                    className="ant-empty-img-1"
                                    d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                                  />
                                  <path
                                    className="ant-empty-img-2"
                                    d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                                  />
                                  <path
                                    className="ant-empty-img-3"
                                    d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                                  />
                                </g>
                                <path
                                  className="ant-empty-img-3"
                                  d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                                />
                                <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                                  <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                                  <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                                </g>
                              </g>
                            </svg>
                            <Box sx={{ mt: 1 }}>No data found!</Box>
                          </StyledGridOverlay>
                        </>
                      )}
                    </Grid>
                  </div>
                </div>
                {ticketAllDetails?.rejectReason && (
                  <Grid item xs={12} className="support_ticket_view_new">
                    <>
                      <div style={{ border: '1px solid #f44336', borderRadius: '6px', marginTop: '12px' }}>
                        <Grid item xs={12}>
                          <div className="address_locationmain" style={{ background: '#f44336', mb: 2, padding: '10px 15px' }}>
                            <Typography component="h4" variant="h3" sx={{ color: '#fff' }}>
                              Rejected Reason
                            </Typography>
                          </div>
                          <Grid container spacing={gridSpacing}>
                            <Grid item lg={6} md={6} sm={6} xs={12} className="select-job-enterprise select-job-enterprise-type">
                              <div style={{ padding: '0px 15px 10px' }}>
                                <div style={{ display: 'flex', marginBottom: '10px' }}>
                                  <Typography
                                    component={'p'}
                                    variant="h4"
                                    sx={{ fontWeight: '600', marginRight: '4px', minWidth: '200px' }}
                                  >
                                    Reason :
                                  </Typography>
                                  <span
                                    style={{
                                      fontSize: '15px',
                                      fontWeight: '600'
                                    }}
                                  >
                                    {ticketAllDetails?.rejectReason ? ticketAllDetails?.rejectReason : 'N.A.'}
                                  </span>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    </>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </DialogContent>
        {/* Raised By */}

        {/* {ticketAllDetails?.ticketType === 'Raised' && (
          <>
            {ticketAllDetails?.status === 'Pending' && (
              <DialogActions>
                <Box sx={{ m: 1, position: 'relative' }}>
                  <Button
                    variant="contained"
                    type="reset"
                    color="error"
                    onClick={() => {
                      setOpenCancelModal(!openCancelModal);
                    }}
                    disabled={isLoadingUpdateTicketStatus}
                  >
                    Cancel Ticket
                  </Button>
                </Box>
              </DialogActions>
            )}

            {ticketAllDetails?.status === 'InReview' && (
              <DialogActions>
                <Box sx={{ m: 1, position: 'relative' }}>
                  <Button
                    variant="contained"
                    type="reset"
                    onClick={() => {
                      setOpenConfirmationModal(!openConfirmationModal);
                    }}
                    sx={{
                      backgroundColor: '#dbc115',
                      color: '#fff',
                      '&:hover': {
                        backgroundColor: '#dbc115' // Keeps the same color on hover
                      }
                    }}
                    disabled={isLoadingUpdateTicketStatus}
                  >
                    Accepted Clearance
                  </Button>
                </Box>
              </DialogActions>
            )}
          </>
        )} */}
        {ticketPermission?.showCancelledButton && (
          <DialogActions>
            <Box sx={{ m: 1, position: 'relative' }}>
              <Button
                variant="contained"
                type="submit"
                color="error"
                onClick={() => {
                  setOpenCancelModal(!openCancelModal);
                }}
                disabled={isLoadingUpdateTicketStatus}
              >
                Cancel Ticket
              </Button>
            </Box>
          </DialogActions>
        )}

        {ticketPermission?.showAcceptClearanceButton && (
          <DialogActions>
            <Box sx={{ m: 1, position: 'relative' }}>
              <Button
                variant="contained"
                type="submit"
                onClick={() => {
                  setOpenConfirmationModal(!openConfirmationModal);
                }}
                sx={{
                  backgroundColor: '#dbc115',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#dbc115' // Keeps the same color on hover
                  }
                }}
                disabled={isLoadingUpdateTicketStatus}
              >
                Accepted Clearance
              </Button>
            </Box>
          </DialogActions>
        )}
        {/* Received */}
        {/* {ticketAllDetails?.ticketType === 'Received' && ( */}
        <>
          {/* {ticketAllDetails?.status === 'Pending' && (
              <DialogActions>
                <Box sx={{ m: 1, position: 'relative' }}>
                  <Button
                    variant="contained"
                    type="reset"
                    color="primary"
                    onClick={() => {
                      setopenAcceptModal(!openAcceptModal);
                    }}
                    disabled={isLoadingUpdateTicketStatus}
                    sx={{
                      color: '#fff'
                    }}
                  >
                    Accept
                  </Button>
                </Box>
                <Button
                  variant="contained"
                  type="reset"
                  color="error"
                  disabled={isLoadingUpdateTicketStatus}
                  onClick={() => {
                    setOpenRejectModal(!openRejectModal);
                  }}
                >
                  Reject
                </Button>
              </DialogActions>
            )} */}
          <>
            <DialogActions>
              {ticketPermission?.showAcceptedButton && (
                <Box sx={{ m: 1, position: 'relative' }}>
                  <Button
                    variant="contained"
                    type="reset"
                    color="primary"
                    onClick={() => {
                      setopenAcceptModal(!openAcceptModal);
                    }}
                    disabled={isLoadingUpdateTicketStatus}
                    sx={{
                      color: '#fff'
                    }}
                  >
                    Accept
                  </Button>
                </Box>
              )}
              {ticketPermission?.showRejectedButton && (
                <Box sx={{ m: 1, position: 'relative' }}>
                  <Button
                    variant="contained"
                    type="reset"
                    color="error"
                    disabled={isLoadingUpdateTicketStatus}
                    onClick={() => {
                      setOpenRejectModal(!openRejectModal);
                    }}
                  >
                    Reject
                  </Button>
                </Box>
              )}
            </DialogActions>
          </>
          {/* {ticketAllDetails?.status === 'Accepted' && (
              <DialogActions>
                <Box sx={{ m: 1, position: 'relative' }}>
                  <Button
                    variant="contained"
                    type="reset"
                    color="primary"
                    onClick={() => {
                      setOpenInReview(!openInReview);
                    }}
                    sx={{
                      color: '#fff'
                    }}
                    // color="primary"
                    // disabled={isLoadingUpdateInvoiceStatus}
                    disabled={isLoadingUpdateTicketStatus}
                  >
                    Mark as resolved
                  </Button>
                </Box>
              </DialogActions>
            )} */}
          {ticketPermission?.showMarkResolvedButton && (
            <DialogActions>
              <Box sx={{ m: 1, position: 'relative' }}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  onClick={() => {
                    setOpenInReview(!openInReview);
                  }}
                  sx={{
                    color: '#fff'
                  }}
                  // color="primary"
                  // disabled={isLoadingUpdateInvoiceStatus}
                  disabled={isLoadingUpdateTicketStatus}
                >
                  Mark as resolved
                </Button>
              </Box>
            </DialogActions>
          )}
        </>
        {/* )} */}
      </Dialog>

      <DeleteDialog
        open={openConfirmationModal}
        setOpen={setOpenConfirmationModal}
        title={'Confirmation !'}
        subTitle={'Are you sure to accept the closure request?'}
        handleSubmit={() => {
          updatedStatusTicket(
            {
              id: ticketAllDetails?._id,
              status: 'Resolved'
              //   isRetailer: true
            },
            {
              onSuccess: (res) => {
                if (res?.status == 200) {
                  toastConfig.type = 'success';
                  setToast(toastConfig, res?.data.message);
                  queryClient.refetchQueries({
                    queryKey: ['getMachineTicketNewData'],
                    type: 'active'
                  });
                  queryClient.refetchQueries({
                    queryKey: ['getMachineTicketById'],
                    type: 'active'
                  });
                  setOpenConfirmationModal(false);
                } else {
                  toastConfig.type = 'error';
                  setToast(toastConfig, error?.response?.data?.error);
                  setOpenConfirmationModal(false);
                }
              },
              onError: (error) => {
                toastConfig.type = 'error';
                setToast(toastConfig, error?.response?.data?.error);
                setOpenConfirmationModal(false);
              }
            }
          );
        }}
        isDeleteLoading={isLoadingUpdateTicketStatus}
      />
      {/* InReview  for received*/}
      <DeleteDialog
        open={openInReview}
        setOpen={setOpenInReview}
        title={'Confirmation !'}
        subTitle={'Are you sure to mark the ticket as resolved ?'}
        handleSubmit={() => {
          updatedStatusTicket(
            {
              id: ticketAllDetails?._id,
              status: 'InReview'
            },
            {
              onSuccess: (res) => {
                if (res?.status == 200) {
                  toastConfig.type = 'success';
                  setToast(toastConfig, res?.data.message);
                  queryClient.refetchQueries({
                    queryKey: ['getMachineTicketNewData'],
                    type: 'active'
                  });
                  queryClient.refetchQueries({
                    queryKey: ['getMachineTicketById'],
                    type: 'active'
                  });
                  setOpenRejectModal(false);
                } else {
                  toastConfig.type = 'error';
                  setToast(toastConfig, error?.response?.data?.error);
                  setOpenRejectModal(false);
                }
              },
              onError: (error) => {
                toastConfig.type = 'error';
                setToast(toastConfig, error?.response?.data?.error);
                setOpenRejectModal(false);
              }
            }
          );
        }}
        isDeleteLoading={isLoadingUpdateTicketStatus}
      />
      <DeleteDialog
        open={openAcceptModal}
        setOpen={setopenAcceptModal}
        title={'Confirmation !'}
        subTitle={'Are you sure to accept the ticket ?'}
        handleSubmit={() => {
          updatedStatusTicket(
            {
              id: ticketAllDetails?._id,
              status: 'Accepted',
              isRetailer: true
            },
            {
              onSuccess: (res) => {
                if (res?.status == 200) {
                  toastConfig.type = 'success';
                  setToast(toastConfig, res?.data.message);
                  queryClient.refetchQueries({
                    queryKey: ['getMachineTicketNewData'],
                    type: 'active'
                  });
                  queryClient.refetchQueries({
                    queryKey: ['getMachineTicketById'],
                    type: 'active'
                  });
                  setopenAcceptModal(false);
                } else {
                  toastConfig.type = 'error';
                  setToast(toastConfig, error?.response?.data?.error);
                  setopenAcceptModal(false);
                }
              },
              onError: (error) => {
                toastConfig.type = 'error';
                setToast(toastConfig, error?.response?.data?.error);
                setopenAcceptModal(false);
              }
            }
          );
        }}
        isDeleteLoading={isLoadingUpdateTicketStatus}
      />

      <DeleteDialog
        open={openCancelModal}
        setOpen={setOpenCancelModal}
        title={'Confirmation !'}
        subTitle={'Are you sure to cancel the ticket?'}
        handleSubmit={() => {
          updatedStatusTicket(
            {
              id: ticketAllDetails?._id,
              status: 'Cancelled'
            },
            {
              onSuccess: (res) => {
                if (res?.status == 200) {
                  toastConfig.type = 'success';
                  setToast(toastConfig, res?.data.message);
                  queryClient.refetchQueries({
                    queryKey: ['getMachineTicketNewData'],
                    type: 'active'
                  });
                  queryClient.refetchQueries({
                    queryKey: ['getMachineTicketById'],
                    type: 'active'
                  });
                  setOpenCancelModal(false);
                } else {
                  toastConfig.type = 'error';
                  setToast(toastConfig, error?.response?.data?.error);
                  setOpenCancelModal(false);
                }
              },
              onError: (error) => {
                toastConfig.type = 'error';
                setToast(toastConfig, error?.response?.data?.error);
                setOpenCancelModal(false);
              }
            }
          );
        }}
        isDeleteLoading={isLoadingUpdateTicketStatus}
      />
      {openRejectModal && (
        <RejectTicketModal
          open={openRejectModal}
          setOpen={setOpenRejectModal}
          ticketDetailsId={details?.id}
          ticketAllDetails={ticketAllDetails}
          refetchTicketDetails={refetchTicketDetails}
        />
      )}
    </>
  );
};

export default ViewTicket;
