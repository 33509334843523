import { Avatar, Divider, Grid, Skeleton, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { gridSpacing } from 'store/constant';

const EmployeeViewModalSkeleton = () => {
  const orgType = useSelector((state) => state.customization.orgType);
  return (
    <>
      <Grid container spacing={gridSpacing} style={{ paddingTop: '15px' }}>
        <Grid item xs={12} sm={12} md={3} lg={4}>
          <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}>
            <div className="address_locationmain" style={{ background: '#6fd74b', mb: 2, padding: '10px 15px' }}>
              <Typography
                component="h4"
                variant="h3"
                // sx={{ mr: 1, pb: 1.8 }}
                sx={{ color: '#fff' }}
              >
                <Skeleton />
              </Typography>
            </div>
            <div style={{ padding: '10px 15px 15px', display: 'flex', justifyContent: 'center' }}>
              <Avatar
                // src='src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"'
                sx={{ width: 250, height: 250, border: '1px solid black', backgroundColor: '#f8fafc !important' }}
              >
                <Skeleton variant="circular" width={250} height={250} />
              </Avatar>

              {/* Profile Image with Initials */}
              {/* <Avatar sx={{ width: 56, height: 56 }}>AB</Avatar> */}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={9} lg={8} className="employeeModule">
          <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', height: '100%' }}>
            <div className="address_locationmain" style={{ background: '#6fd74b', mb: 2, padding: '10px 15px' }}>
              <Typography
                component="h4"
                variant="h3"
                // sx={{ mr: 1, pb: 1.8 }}
                sx={{ color: '#fff' }}
              >
                <Skeleton />
              </Typography>
            </div>
            <div style={{ padding: '10px 15px 15px' }}>
              <Grid container spacing={2} style={{ paddingTop: '10px', paddingBottom: '20px' }}>
                <Grid item xs={12} sx={{ padding: 0 }}>
                  {/* <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}> */}
                  <div className="address_locationmain" style={{ margin: '2px', padding: '5px', display: 'inherit' }}>
                    <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                      Name :{' '}
                    </Typography>
                    <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                      <Skeleton />
                    </Typography>
                  </div>
                  {/* </div> */}
                </Grid>

                <Grid item xs={12} sx={{ padding: 0 }}>
                  {/* <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}> */}
                  <div className="address_locationmain" style={{ margin: '2px', padding: '5px', display: 'inherit' }}>
                    <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                      Title :{' '}
                    </Typography>
                    <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                      <Skeleton />
                    </Typography>
                  </div>
                  {/* </div> */}
                </Grid>

                <Grid item xs={12} sx={{ padding: 0 }}>
                  {/* <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}> */}
                  <div className="address_locationmain" style={{ margin: '2px', padding: '5px', display: 'inherit' }}>
                    <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                      Email :{' '}
                    </Typography>
                    <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                      <Skeleton />
                    </Typography>
                  </div>
                  {/* </div> */}
                </Grid>

                <Grid item xs={12} sx={{ padding: 0 }}>
                  {/* <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}> */}
                  <div className="address_locationmain" style={{ margin: '2px', padding: '5px', display: 'inherit' }}>
                    <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                      Phone :{' '}
                    </Typography>
                    <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                      <Skeleton />
                    </Typography>
                  </div>
                  {/* </div> */}
                </Grid>

                <Grid item xs={12} sx={{ padding: 0 }}>
                  {/* <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}> */}
                  <div className="address_locationmain" style={{ margin: '2px', padding: '5px', display: 'inherit' }}>
                    <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                      Address :{' '}
                    </Typography>
                    <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                      <Skeleton />
                    </Typography>
                  </div>
                  {/* </div> */}
                </Grid>
              </Grid>

              {orgType && (orgType === 'Enterprise' || orgType === 'Super Admin') && <Divider />}

              {/* <Typography variant="h4" component="p" style={{ fontWeight: 'bold', paddingTop: 15 }}>
                            For Enterprise{' '}
                          </Typography> */}
              {orgType && (orgType === 'Enterprise' || orgType === 'Super Admin') && (
                <Grid container spacing={2} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                  {/* Enterprise */}
                  {orgType && orgType === 'Enterprise' && (
                    <>
                      <Grid item xs={12} sx={{ padding: 0 }}>
                        {/* <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}> */}
                        <div className="address_locationmain" style={{ margin: '2px', padding: '5px', display: 'inherit' }}>
                          <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                            RFID :{' '}
                          </Typography>
                          <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                            <Skeleton />
                          </Typography>
                        </div>
                        {/* </div> */}
                      </Grid>

                      <Grid item xs={12} sx={{ padding: 0 }}>
                        {/* <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}> */}
                        <div className="address_locationmain" style={{ margin: '2px', padding: '5px', display: 'inherit' }}>
                          <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                            Auth Pin :{' '}
                          </Typography>
                          <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                            <Skeleton />
                          </Typography>
                        </div>
                        {/* </div> */}
                      </Grid>
                    </>
                  )}
                  {/* Super Admin */}
                  {orgType && orgType === 'Super Admin' && (
                    <>
                      <Grid item xs={12} sx={{ padding: 0 }}>
                        {/* <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}> */}
                        <div className="address_locationmain" style={{ margin: '2px', padding: '5px', display: 'inherit' }}>
                          <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                            Installer RFID :{' '}
                          </Typography>
                          <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                            <Skeleton />
                          </Typography>
                        </div>
                        {/* </div> */}
                      </Grid>

                      <Grid item xs={12} sx={{ padding: 0 }}>
                        {/* <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}> */}
                        <div className="address_locationmain" style={{ margin: '2px', padding: '5px', display: 'inherit' }}>
                          <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                            Installer Auth Pin :{' '}
                          </Typography>
                          <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                            <Skeleton />
                          </Typography>
                        </div>
                        {/* </div> */}
                      </Grid>
                    </>
                  )}
                </Grid>
              )}
            </div>
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', height: '100%' }}>
            <div className="address_locationmain" style={{ background: '#6fd74b', mb: 2, padding: '10px 15px' }}>
              <Typography
                component="h4"
                variant="h3"
                // sx={{ mr: 1, pb: 1.8 }}
                sx={{ color: '#fff' }}
              >
                <Skeleton />
              </Typography>
            </div>
            <div style={{ padding: '10px 15px 15px' }}>
              <Grid container spacing={gridSpacing} sx={{ padding: '10px 15px' }}>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  {/* <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginBottom: '10px' }}> */}
                  <div style={{ background: '#6fd74b', color: '#fff', padding: '10px', fontSize: '16px' }}>
                    <Skeleton />
                  </div>
                  <div style={{ padding: '10px' }}>
                    <Typography variant="h6" sx={{ margin: 0 }}>
                      <Skeleton />
                    </Typography>
                    <Typography variant="body2" sx={{ marginTop: '4px' }}>
                      <Skeleton />
                    </Typography>
                  </div>
                  {/* </div> */}
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  {/* <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginBottom: '10px' }}> */}
                  <div style={{ background: '#6fd74b', color: '#fff', padding: '10px', fontSize: '16px' }}>
                    <Skeleton />
                  </div>
                  <div style={{ padding: '10px' }}>
                    <Typography variant="h6" sx={{ margin: 0 }}>
                      <Skeleton />
                    </Typography>
                    <Typography variant="body2" sx={{ marginTop: '4px' }}>
                      <Skeleton />
                    </Typography>
                  </div>
                  {/* </div> */}
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                  {/* <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginBottom: '10px' }}> */}
                  <div style={{ background: '#6fd74b', color: '#fff', padding: '10px', fontSize: '16px' }}>
                    <Skeleton />
                  </div>
                  <div style={{ padding: '10px' }}>
                    <Typography variant="h6" sx={{ margin: 0 }}>
                      <Skeleton />
                    </Typography>
                    <Typography variant="body2" sx={{ marginTop: '4px' }}>
                      <Skeleton />
                    </Typography>
                  </div>
                  {/* </div> */}
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default EmployeeViewModalSkeleton;
