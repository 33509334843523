import { Box, Button, CircularProgress, FormControl, FormHelperText, Grid, IconButton, OutlinedInput, Typography } from '@mui/material';
import { Field, FieldArray, Form } from 'formik';
import React from 'react';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useDeleteMachineVersion, useGetAllMachineVersion } from 'hooks/useSettingsHooks';
import { useEffect } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import DeleteDialog from 'ui-component/delete-dialog';
import { useState } from 'react';
import { setToast, toastConfig } from 'utils/commonUtil';
import SettingsView from 'ui-component/cards/Skeleton/SettingsView';
import { gridSpacing } from 'store/constant';
// import { Link } from 'react-router-dom';

const MachineVersionForm = ({ values, touched, errors, handleChange, handleBlur, isLoadingMachineVersion, setFieldValue }) => {
  // console.log('🚀errors:', errors);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [versionId, setVersionId] = useState('');
  const { data: machineVersionList, isPending: isMachineVersionListLoading } = useGetAllMachineVersion({
    isActive: true
  });
  const { mutate: deleteMachineVersion, isPending: isDeleteMachineVersionLoading } = useMutation({
    mutationFn: useDeleteMachineVersion
  });
  const queryClient = useQueryClient();
  useEffect(() => {
    if (machineVersionList) {
      console.log('🚀 ~ useEffect ~ machineVersionList:', machineVersionList);
      setFieldValue(
        'machineVersion',
        machineVersionList?.length > 0
          ? machineVersionList
          : [
              {
                version: ''
              }
            ]
      );
    }
  }, [machineVersionList, setFieldValue]);
  return (
    <>
      {isMachineVersionListLoading ? (
        <>
          <SettingsView />
        </>
      ) : (
        <>
          <Form>
            <Grid item xs={12}>
              <Typography component="h4" variant="h2" sx={{ mb: 2 }}>
                Machine Version Details
              </Typography>
              <Grid container spacing={gridSpacing}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography component="h5" variant="h4" sx={{ mb: 2 }}>
                    This option defines the machine version details.
                  </Typography>
                  <Grid container className="settings-row-page">
                    <FieldArray name="machineVersion">
                      {({ push, remove }) => (
                        <>
                          {values?.machineVersion?.map((machineVersion, index) => (
                            <Box
                              key={index}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                my: 1
                              }}
                            >
                              <FormControl
                                fullWidth
                                error={touched.machineVersion?.[index]?.version && Boolean(errors.machineVersion?.[index]?.version)}
                              >
                                <Field
                                  as={OutlinedInput}
                                  name={`machineVersion[${index}].version`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  variant="outlined"
                                  size="small"
                                  type="text"
                                  value={values.machineVersion[index].version || ''}
                                />

                                {Array.isArray(errors.machineVersion) && (
                                  <>
                                    {touched.machineVersion?.[index]?.version && errors.machineVersion?.[index]?.version && (
                                      <FormHelperText error sx={{ fontSize: '13.8px' }}>
                                        {errors.machineVersion?.[index]?.version}
                                      </FormHelperText>
                                    )}
                                  </>
                                )}
                              </FormControl>

                              <IconButton
                                // onClick={() => remove(index)}
                                onClick={() => {
                                  if (values.machineVersion[index]?.id) {
                                    setOpenDeleteDialog(!openDeleteDialog);
                                    setVersionId(values.machineVersion[index]?.id);
                                  } else {
                                    remove(index);
                                  }
                                }}
                                color="error"
                                disabled={values.machineVersion.length === 1 || isDeleteMachineVersionLoading} // Disable remove for first two values
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          ))}

                          <div>
                            <IconButton
                              onClick={() => push('')} // Add a new empty value
                              color="primary"
                              className="settings-addbtn"
                            >
                              <AddIcon />
                            </IconButton>
                          </div>
                        </>
                      )}
                    </FieldArray>
                  </Grid>
                </Grid>
              </Grid>
              {!Array.isArray(errors.machineVersion) && (
                <>
                  {touched.machineVersion && errors.machineVersion && (
                    <FormHelperText error sx={{ fontSize: '13.8px', top: '-28px', position: 'relative', left: '21px' }}>
                      {errors.machineVersion}
                    </FormHelperText>
                  )}
                </>
              )}

              {/* {!Array.isArray(errors.machineVersion) && errors.machineVersion && (
            <FormHelperText error sx={{ fontSize: '13.8px', top: '-28px', position: 'relative', left: '21px' }}>
              {errors.machineVersion}
            </FormHelperText>
          )} */}
            </Grid>
            <Grid item xs={12}>
              {errors.submit && (
                <Box sx={{ mt: 3 }}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
              <Typography component="h5" variant="h4" sx={{ mt: 2 }}>
                Please make sure a folder is available in the firebase storage with this version name with necessary files.
                <a
                  href="https://console.firebase.google.com/project/shredbox-52385/storage/shredbox-52385.appspot.com/files/~2FmachineSoftware"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: 'blue',
                    textDecoration: 'underline',
                    fontSize: '15px'
                  }}
                >
                  Open Firebase Storage
                </a>
              </Typography>
              <Box sx={{ mt: 5, display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                <Box sx={{ display: 'inline-block' }}>
                  <AnimateButton>
                    <Button
                      disableElevation
                      size="large"
                      type="submit"
                      variant="contained"
                      color="secondary"
                      style={{
                        color: 'white',
                        minWidth: '200px',
                        margin: '0px auto'
                      }}
                    >
                      {isLoadingMachineVersion ? (
                        <>
                          <CircularProgress
                            sx={{
                              color: 'white',
                              height: 20,
                              width: 20
                            }}
                          />
                        </>
                      ) : (
                        `Save`
                      )}
                    </Button>
                  </AnimateButton>
                </Box>
              </Box>
            </Grid>
          </Form>
        </>
      )}

      <DeleteDialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        title={'Confirmation !'}
        subTitle={'Are you sure to delete the machine version?'}
        handleSubmit={() => {
          deleteMachineVersion(versionId, {
            onSuccess: (res) => {
              if (res?.status == 200) {
                toastConfig.type = 'success';
                setToast(toastConfig, res?.data.message);
                queryClient.refetchQueries({
                  queryKey: ['getallMachineVersion'],
                  type: 'active'
                });
              } else {
                toastConfig.type = 'error';
                setToast(toastConfig, 'Something went wrong!');
              }
            },
            onError: (error) => {
              toastConfig.type = 'error';
              setToast(toastConfig, error?.response?.data?.error);
            }
          });
        }}
        isDeleteLoading={isDeleteMachineVersionLoading}
      />
    </>
  );
};

export default MachineVersionForm;
