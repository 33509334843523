/* eslint-disable */
import React, { useRef, useEffect, useState } from 'react';
import Slider from 'react-slick';
import { Grid, Paper, Typography, Button, List, ListItem, ListItemIcon, IconButton, ListItemText, Skeleton } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import AddIcon from '@mui/icons-material/Add';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { margin } from '@mui/system';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import SubcriptionSliderSkeleton from 'ui-component/cards/Skeleton/SubcriptionSliderSkeleton';
import DeleteDialog from 'ui-component/delete-dialog';
import AlertDialog from './AlertDialog';

const SubscriptionPlansCarousel = ({
  subcriptionList,
  isLoadingSubcriptionList,
  callChooseSubscription,
  setOpenContactUs,
  openContactUs,
  subId,
  isCurrentSubscription
}) => {
  const sliderRef = useRef(null);
  console.log('filterSubcriptionList - filter - subId : ', subId);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // 'sm' is the breakpoint for mobile

  const [maxHeight, setMaxHeight] = useState(555);
  const [filterSubcriptionList, setFilterSubcriptionList] = useState([]);
  console.log('SubscriptionPlansCarousel ~ filterSubcriptionList', filterSubcriptionList);
  useEffect(() => {
    if (subcriptionList) setFilterSubcriptionList(subcriptionList.filter((plan) => plan.isPublic));
  }, [subcriptionList]);
  // console.log('subcriptionList.length', subcriptionList.length, filterSubcriptionList.length, filterSubcriptionList);
  // Slick Carousel settings
  console.log('Slick Carousel settings autoplay : ', openContactUs, !openContactUs);
  const settings = {
    dots: true,
    slidesToShow: 3,
    infinite: isMobile ? true : filterSubcriptionList && filterSubcriptionList.length > 2 ? true : false,
    slidesToScroll: 1,
    autoplay: !openContactUs,
    /*dots: filterSubcriptionList && filterSubcriptionList.length > 3,
    slidesToShow: filterSubcriptionList && filterSubcriptionList.length >= 2 ? 3 : filterSubcriptionList.length, // Show all items if 3 or less
    infinite: filterSubcriptionList && filterSubcriptionList.length > 3,
    slidesToScroll: 1,
    autoplay: filterSubcriptionList && filterSubcriptionList.length > 3,*/
    speed: 200,
    autoplaySpeed: 3500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
    init: () => {
      console.log('Slick Slider initialized and ready!');
      // You can also perform actions here, like updating state or triggering animations
    }
  };

  return (
    <>
      {isLoadingSubcriptionList ? (
        <>
          <SubcriptionSliderSkeleton />
        </>
      ) : (
        <div className="slider-container slide-subscribe-plan" style={{ position: 'relative', paddingBottom: 30, overflowX: 'hidden' }}>
          {!isMobile && filterSubcriptionList && filterSubcriptionList.length > 2 && (
            <>
              {/* Left Arrow */}
              <IconButton
                onClick={() => sliderRef.current.slickPrev()}
                style={{
                  position: 'absolute',
                  left: '0 !important',
                  top: '50%',
                  transform: 'translateY(-100%)',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  color: '#fff',
                  zIndex: 10
                  // display: filterSubcriptionList && filterSubcriptionList.length >= 2 ? 'none' : 'block'
                }}
                className="carousel-arrow-emp-left"
              >
                <ArrowCircleLeftIcon style={{ fontSize: '40px' }} />
              </IconButton>
              {/* Right Arrow */}
              <IconButton
                onClick={() => sliderRef.current.slickNext()}
                style={{
                  position: 'absolute',
                  right: '0',
                  top: '50%',
                  transform: 'translateY(-100%)',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  color: '#fff',
                  zIndex: 10
                  // display: filterSubcriptionList && filterSubcriptionList.length >= 2 ? 'none' : 'block'
                }}
                className="carousel-arrow-emp-right"
              >
                <ArrowCircleRightIcon style={{ fontSize: '40px' }} />
              </IconButton>
            </>
          )}
          {isMobile && (
            <>
              {/* Left Arrow */}
              <IconButton
                onClick={() => sliderRef.current.slickPrev()}
                style={{
                  position: 'absolute',
                  left: '0 !important',
                  top: '50%',
                  transform: 'translateY(-100%)',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  color: '#fff',
                  zIndex: 10
                  // display: filterSubcriptionList && filterSubcriptionList.length >= 2 ? 'none' : 'block'
                }}
                className="carousel-arrow-emp-left"
              >
                <ArrowCircleLeftIcon style={{ fontSize: '40px' }} />
              </IconButton>
              {/* Right Arrow */}
              <IconButton
                onClick={() => sliderRef.current.slickNext()}
                style={{
                  position: 'absolute',
                  right: '0',
                  top: '50%',
                  transform: 'translateY(-100%)',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  color: '#fff',
                  zIndex: 10
                  // display: filterSubcriptionList && filterSubcriptionList.length >= 2 ? 'none' : 'block'
                }}
                className="carousel-arrow-emp-right"
              >
                <ArrowCircleRightIcon style={{ fontSize: '40px' }} />
              </IconButton>
            </>
          )}

          <Slider
            {...settings}
            ref={sliderRef}
            className="slick-slider"
            // onMouseEnter={() => {
            //   document.querySelector('.carousel-arrow-left').style.display = 'block';
            //   document.querySelector('.carousel-arrow-right').style.display = 'block';
            // }}
            // onMouseLeave={() => {
            //   document.querySelector('.carousel-arrow-left').style.display = 'none';
            //   document.querySelector('.carousel-arrow-right').style.display = 'none';
            // }}
          >
            {filterSubcriptionList &&
              filterSubcriptionList.length > 0 &&
              filterSubcriptionList
                .filter((s) => {
                  console.log('filterSubcriptionList - filter : ', s.id, subId);
                  return s.id !== subId;
                })
                .map((sub, index) => (
                  <div key={index}>
                    <Grid item xs={12} sm={6} md={4} className="subscription-plan-wrapper">
                      <Paper sx={{ p: 6, borderRadius: 2, boxShadow: 3, height: '100%' }}>
                        <div className="subcription-content">
                          <Typography variant="h2" align="left" sx={{ fontWeight: '600' }}>
                            {sub?.title}
                          </Typography>
                          <Typography variant="body2" align="left" sx={{ mb: 2, pt: 2 }}>
                            {sub?.description}
                          </Typography>
                          <Typography variant="h1" align="left" sx={{ fontWeight: '700', mb: 2 }}>
                            ${sub?.price?.amount}{' '}
                            <span style={{ fontSize: '12.5px', color: '#a4a4a4', fontWeight: '500' }}>/ {sub?.price?.interval}</span>
                          </Typography>
                          <Typography variant="h4" align="left" sx={{ fontWeight: '600' }}>
                            {sub?.noUsers?.machineSpecificUser || 0} Machine Specific User(s) and {sub?.noUsers?.globalUser || 0} Global
                            User(s) with {sub?.noOfAdvertisements ? sub?.noOfAdvertisements + ' Advertisements' : 'No Advertisements'}
                          </Typography>

                          <List sx={{ paddingTop: 5 }}>
                            {sub?.features &&
                              sub?.features.length &&
                              sub?.features.map((feature, index) => (
                                <ListItem key={index} sx={{ paddingLeft: 0 }}>
                                  <ListItemIcon>
                                    <CheckIcon sx={{ color: sub?.colorCode?.hex }} />
                                  </ListItemIcon>
                                  <Typography variant="h5" component={'span'} style={{ fontWeight: '600' }}>
                                    {feature?.trim()}
                                  </Typography>
                                </ListItem>
                              ))}
                          </List>
                        </div>

                        <Button
                          type="button"
                          variant="contained"
                          color="primary"
                          fullWidth
                          sx={{
                            mt: 4,
                            p: 2,
                            color: '#FFF',
                            backgroundColor: sub?.colorCode?.hex,
                            '&:hover': {
                              backgroundColor: sub?.colorCode?.hex
                            },
                            fontSize: '1rem',
                            boxShadow: 'none'
                          }}
                          onClick={() => callChooseSubscription(sub)}
                        >
                          Select Plan
                        </Button>
                      </Paper>
                    </Grid>
                  </div>
                ))}
            <Grid item xs={12} sm={6} md={4} className="subscription-plan-wrapper">
              <Paper sx={{ p: 6, borderRadius: 2, boxShadow: 3, height: '100%' }}>
                <div className="subcription-content">
                  <Typography variant="h2" align="left" sx={{ fontWeight: '600' }}>
                    Custom Plan
                  </Typography>
                  <Typography variant="body2" align="left" sx={{ mb: 2, pt: 2 }}>
                    For big organizations with large number of drive crush capacity
                  </Typography>
                  <Typography variant="h1" align="left" sx={{ fontWeight: '700', mb: 2 }}></Typography>
                  <Typography variant="h4" align="left" sx={{ fontWeight: '600' }}></Typography>

                  <List sx={{ paddingTop: 5 }}>
                    <ListItem sx={{ paddingLeft: 0 }}>
                      <ListItemIcon>
                        <AddIcon sx={{ color: '#6FBA55' }} />
                      </ListItemIcon>
                      <ListItemText primary="Customised users capacity" />
                    </ListItem>
                    <ListItem sx={{ paddingLeft: 0 }}>
                      <ListItemIcon>
                        <AddIcon sx={{ color: '#6FBA55' }} />
                      </ListItemIcon>
                      <ListItemText primary="More than 5 advertisement options" />
                    </ListItem>
                  </List>
                </div>
                <Button
                  type="button"
                  variant="contained"
                  color="success"
                  fullWidth
                  sx={{ mt: 4, p: 2, color: '#FFF', backgroundColor: '#6FBA55', fontSize: '1rem', boxShadow: 'none' }}
                  onClick={() => setOpenContactUs(true)}
                >
                  Contact Us
                </Button>
              </Paper>
            </Grid>
          </Slider>
        </div>
      )}
    </>
  );
};

export default SubscriptionPlansCarousel;
