// / eslint-disable react/prop-types /
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  // InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography
} from '@mui/material';

import { Box } from '@mui/system';
import { Form } from 'formik';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { gridSpacing } from 'store/constant';
import { useEffect } from 'react';
import GoogleMaps from 'utils/google-place-api-autocomplete';
import CustomeMap from 'utils/CustomeMap';
const LocationForm = ({
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  permissionsList,
  touched,
  values,
  isLoadingLocation,
  setFieldValue,
  locationDetails,
  btnName,
  locationLatLng,
  setLocationLatLng,
  isEditable = false,
  setErrors
  // id
}) => {
  const theme = useTheme();
  const exceptThisSymbols = ['e', 'E', '+', '-', '#', '%', '^'];
  useEffect(() => {
    if (locationDetails) {
      // console.log('🚀 ~ useEffect ~ locationDetails:', locationDetails);
      setFieldValue('location_name', locationDetails?.name);
      setFieldValue('location', locationDetails?.address?.fullAddress);
      setFieldValue('placeId', locationDetails?.address?.placeId);
      setFieldValue('latitude', locationDetails?.address?.coOrdinates?.latitude);
      setFieldValue('longitude', locationDetails?.address?.coOrdinates?.longitude);
      setFieldValue('tax', locationDetails?.taxAmount);
      setFieldValue('city', locationDetails?.address?.city);
      setFieldValue('country', locationDetails?.address?.countryName);
      setFieldValue('postalCode', locationDetails?.address?.postalCode);
      setFieldValue('state', locationDetails?.address?.state);

      setLocationLatLng((prev) => {
        return {
          ...prev,
          lat: locationDetails?.address?.coOrdinates?.latitude,
          lng: locationDetails?.address?.coOrdinates?.longitude,
          label: locationDetails?.address?.fullAddress
        };
      });
    }
  }, [setFieldValue, locationDetails]);
  useEffect(() => {
    setErrors((prev) => {
      let updatedErrors = { ...prev };

      if (values.city && prev.city) {
        updatedErrors.city = '';
      }
      if (values.state && prev.state) {
        updatedErrors.state = '';
      }
      if (values.country && prev.country) {
        updatedErrors.country = '';
      }
      if (values.tax && prev.tax) {
        updatedErrors.tax = '';
      }
      if (values.postalCode && prev.postalCode) {
        updatedErrors.postalCode = '';
      }
      return updatedErrors;
    });
  }, [values.city, values.state, values.country, values.tax, values?.postalCode]);
  return (
    <>
      <Form
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        <Grid container spacing={gridSpacing} sx={{ alignContent: 'center' }}>
          <Grid item xs={12}>
            <Typography component="h4" variant="h2" sx={{}}>
              Location Details
            </Typography>
            <Grid container spacing={gridSpacing}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Grid
                  container
                  spacing={gridSpacing}
                  className="org-first-frm"
                  style={{
                    marginTop: '10px'
                  }}
                >
                  {permissionsList?.map((permission, index) => {
                    if (!permission.show) return null;
                    if (permission?.id == 'locationName') {
                      return (
                        <Grid key={index} item lg={6} md={6} sm={6} xs={6}>
                          <FormControl
                            fullWidth
                            error={Boolean(touched.location_name && errors.location_name)}
                            sx={{ ...theme.typography.customInput }}
                          >
                            <InputLabel htmlFor="outlined-adornment-email-login"> Location Name *</InputLabel>
                            <OutlinedInput
                              disabled={isEditable ? permission?.isStatic : false}
                              id="outlined-adornment-email-login"
                              type="text"
                              value={values?.location_name}
                              name="location_name"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              label=" Location Name *"
                              inputProps={{}}
                              autoComplete="off"
                              placeholder="Enter Your Location"
                            />
                            {touched.location_name && errors.location_name && (
                              <FormHelperText
                                error
                                id="standard-weight-helper-text-email-login"
                                sx={{
                                  fontSize: '13.8px'
                                }}
                              >
                                {errors.location_name}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      );
                    } else if (permission?.id == 'tax') {
                      return (
                        <Grid key={index} item lg={6} md={6} sm={6} xs={6}>
                          <FormControl fullWidth error={Boolean(touched.tax && errors.tax)} sx={{ ...theme.typography.customInput }}>
                            <InputLabel htmlFor="outlined-adornment-service-tax"> Tax % *</InputLabel>
                            <OutlinedInput
                              disabled={isEditable ? permission?.isStatic : false}
                              id="outlined-adornment-service-tax"
                              type="number"
                              value={values.tax}
                              name="tax"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              label="Tax % *"
                              placeholder="0.00"
                              autoComplete="off"
                              onPaste={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                              onCopy={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                              onKeyDown={(e) => {
                                exceptThisSymbols.includes(e.key) && e.preventDefault();
                              }}
                            />
                            {touched.tax && errors.tax && (
                              <FormHelperText
                                error
                                id="standard-weight-helper-text-email-login"
                                sx={{
                                  fontSize: '13.8px'
                                }}
                              >
                                {errors.tax}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      );
                    } else if (permission?.id == 'address') {
                      return (
                        <Grid key={index} item lg={12} md={12} sm={12} xs={12}>
                          <FormControl
                            fullWidth
                            error={Boolean(touched.location && errors.location)}
                            sx={{ ...theme.typography.customInput }}
                          >
                            <InputLabel htmlFor="outlined-adornment-email-login"> Address *</InputLabel>
                            <GoogleMaps
                              disabled={isEditable ? permission?.isStatic : false}
                              handleChange={handleChange}
                              onBlur={handleBlur}
                              setFieldValue={setFieldValue}
                              values={values}
                              error={Boolean(touched.location && errors.location)}
                              setLocationLatLng={setLocationLatLng}
                              locationDetails={locationDetails}
                            />
                            {touched.location && errors.location && (
                              <FormHelperText
                                error
                                id="standard-weight-helper-text-email-login"
                                sx={{
                                  fontSize: '13.8px'
                                }}
                              >
                                {errors.location}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      );
                    } else if (permission?.id == 'city') {
                      return (
                        <Grid key={index} container item lg={6} md={6} sm={6} xs={12}>
                          <FormControl fullWidth error={Boolean(touched.city && errors.city)} sx={{ ...theme.typography.customInput }}>
                            <InputLabel htmlFor="outlined-adornment-name">City</InputLabel>
                            <OutlinedInput
                              // disabled={isEditable ? other?.isStatic : false}
                              id="outlined-adornment-name"
                              type="text"
                              value={values.city}
                              name="city"
                              onBlur={handleBlur}
                              // onBlur={() => setFieldTouched('city', true)}
                              onChange={handleChange}
                              label="city"
                              // placeholder="e.g. Example Pvt Ltd"
                              inputProps={{}}
                              autoComplete="off"
                            />
                            {touched.city && errors.city && (
                              <FormHelperText
                                error
                                id="standard-weight-helper-text-name"
                                sx={{
                                  fontSize: '13.8px'
                                }}
                              >
                                {errors.city}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      );
                    } else if (permission?.id == 'state') {
                      return (
                        <Grid key={index} container item lg={6} md={6} sm={6} xs={12}>
                          <FormControl fullWidth error={Boolean(touched.state && errors.state)} sx={{ ...theme.typography.customInput }}>
                            <InputLabel htmlFor="outlined-adornment-name">State / Province</InputLabel>
                            <OutlinedInput
                              // disabled={isEditable ? other?.isStatic : false}
                              id="outlined-adornment-name"
                              type="text"
                              value={values.state}
                              name="state"
                              // onBlur={() => setFieldTouched('state', true)}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              label="State / Province"
                              // placeholder="e.g. Example Pvt Ltd"
                              inputProps={{}}
                              autoComplete="off"
                            />
                            {touched.state && errors.state && (
                              <FormHelperText
                                error
                                id="standard-weight-helper-text-name"
                                sx={{
                                  fontSize: '13.8px'
                                }}
                              >
                                {errors.state}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      );
                    } else if (permission?.id == 'country') {
                      return (
                        <Grid container item lg={6} md={6} sm={6} xs={12} key={index}>
                          <FormControl
                            fullWidth
                            error={Boolean(touched.country && errors.country)}
                            sx={{ ...theme.typography.customInput }}
                          >
                            <InputLabel htmlFor="outlined-adornment-name">Country</InputLabel>
                            <OutlinedInput
                              // disabled={isEditable ? other?.isStatic : false}
                              id="outlined-adornment-name"
                              type="text"
                              value={values.country}
                              name="country"
                              onBlur={handleBlur}
                              // onBlur={() => setFieldTouched('country', true)}
                              onChange={handleChange}
                              label="Country"
                              // placeholder="e.g. Example Pvt Ltd"
                              inputProps={{}}
                              autoComplete="off"
                            />
                            {touched.country && errors.country && (
                              <FormHelperText
                                error
                                id="standard-weight-helper-text-name"
                                sx={{
                                  fontSize: '13.8px'
                                }}
                              >
                                {errors.country}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      );
                    } else if (permission?.id == 'postalCode') {
                      return (
                        <Grid container item lg={6} md={6} sm={6} xs={12} key={index}>
                          <FormControl
                            fullWidth
                            error={Boolean(touched.postalCode && errors.postalCode)}
                            sx={{ ...theme.typography.customInput }}
                          >
                            <InputLabel htmlFor="outlined-adornment-name">Postal Code</InputLabel>
                            <OutlinedInput
                              // disabled={isEditable ? other?.isStatic : false}
                              id="outlined-adornment-name"
                              type="text"
                              value={values.postalCode}
                              name="postalCode"
                              onBlur={handleBlur}
                              // onBlur={() => setFieldTouched('postalCode', true)}
                              onChange={handleChange}
                              label="Postal Code"
                              // placeholder="e.g. Example Pvt Ltd"
                              inputProps={{}}
                              autoComplete="off"
                            />
                            {touched.postalCode && errors.postalCode && (
                              <FormHelperText
                                error
                                id="standard-weight-helper-text-name"
                                sx={{
                                  fontSize: '13.8px'
                                }}
                              >
                                {errors.postalCode}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      );
                    }
                  })}
                </Grid>
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box>
                  <CustomeMap locationLatLng={locationLatLng} />
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
            <Box sx={{ mt: 5, display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
              <Box sx={{ display: 'inline-block' }}>
                <AnimateButton>
                  <Button
                    disableElevation
                    disabled={isLoadingLocation}
                    size="large"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    style={{
                      color: 'white',
                      minWidth: '200px',
                      margin: '0px auto'
                    }}
                  >
                    {isLoadingLocation ? (
                      <>
                        <CircularProgress
                          sx={{
                            color: 'white',
                            height: 20,
                            width: 20
                          }}
                        />
                      </>
                    ) : (
                      `${btnName} Location`
                    )}
                  </Button>
                </AnimateButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Form>
    </>
  );
};

export default LocationForm;
