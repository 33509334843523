import { Box, Button, CircularProgress, DialogActions, FormControl, FormHelperText, Grid, TextField, useTheme } from '@mui/material';
import { Form } from 'formik';
import React from 'react';
import { gridSpacing } from 'store/constant';

const ReportTicketModalForm = ({
  errors,
  touched,
  handleChange,
  values,
  handleSubmit,
  //   setFieldValue,
  handleBlur,
  handleClose,
  isLoadingReportReason
}) => {
  const theme = useTheme();
  return (
    <>
      <Form noValidate onSubmit={handleSubmit}>
        <Grid
          container
          spacing={gridSpacing}
          sx={{
            alignContent: 'center'
          }}
        >
          <Grid item xs={12}>
            <Grid container spacing={gridSpacing}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.reopenReason && errors.reopenReason)}
                  sx={{ ...theme.typography.customInput }}
                >
                  <TextField
                    variant="outlined"
                    type="text"
                    fullWidth
                    name="reopenReason"
                    value={values?.reopenReason}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    reopenReason
                    error={Boolean(touched.reopenReason && errors.reopenReason)}
                    multiline={2}
                  />
                  {touched.reopenReason && errors.reopenReason && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                      sx={{
                        fontSize: '13.8px'
                      }}
                    >
                      {errors.reopenReason}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <DialogActions>
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box sx={{ m: 1, position: 'relative' }}>
            <Button
              fullWidth
              variant="contained"
              type="submit"
              style={{
                color: '#fff'
              }}
              color="primary"
              disabled={isLoadingReportReason}
            >
              Send
              {isLoadingReportReason && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: '#16B1FF',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px'
                  }}
                />
              )}
            </Button>
          </Box>
          <Button variant="contained" type="reset" color="error" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Form>
    </>
  );
};

export default ReportTicketModalForm;
