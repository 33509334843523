import { useState } from 'react';

// MUI Imports
// import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
// import IconButton from '@mui/material/IconButton';
import { CircularProgress, DialogContentText, IconButton } from '@mui/material';
import { Box } from '@mui/system';
// import { useNavigate } from 'react-router-dom';

const DeleteDialog = ({ open, setOpen, title, handleSubmit, isDeleteLoading, subTitle }) => {
  const handleClose = () => {
    setOpen(false);
  };
  // const navigate = useNavigate();
  // useEffect(() => {
  //   if (!isDeleteLoading) {
  //     setOpen(false);
  //   }
  // }, [isDeleteLoading, setOpen]);
  const [openInvoiceModal, setOpenInvoiceModal] = useState(false);

  // const handleOpenInvoice = () => {
  //   // <Navigate to="/dashboard/default" />;
  //   navigate(`/dashboard/recycler/create/invoices`);
  //   // setOpenInvoiceModal(true);
  // };

  const handleCloseInvoice = () => {
    setOpenInvoiceModal(false); // Close the invoice modal
  };
  return (
    <>
      <Dialog
        open={open}
        fullWidth={false}
        fullScreen={false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        className="dlte_file_mdlnw machine-settings"
      >
        <DialogTitle
          id="alert-dialog-title"
          variant="span"
          style={{
            textTransform: 'capitalize'
          }}
        >
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{subTitle}</DialogContentText>
        </DialogContent>

        <DialogActions>
          {/* {createInvoice && (
            <Button
              variant="contained"
              type="submit"
              onClick={handleOpenInvoice}
              style={{
                color: '#fff'
              }}
              color="primary"
              // disabled={isDeleteLoading}
            >
              Create Invoice
              {/* {isDeleteLoading && (
              <CircularProgress
                size={24}
                sx={{
                  color: '#16B1FF',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px'
                }}
              />
            )} */}
          {/* </Button>
          )} */}

          <Box sx={{ m: 1, position: 'relative' }}>
            <Button
              fullWidth
              variant="contained"
              type="submit"
              onClick={handleSubmit}
              style={{
                color: '#fff'
              }}
              color="primary"
              disabled={isDeleteLoading}
            >
              Yes
              {isDeleteLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: '#16B1FF',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px'
                  }}
                />
              )}
            </Button>
          </Box>
          <Button variant="outlined" type="reset" color="error" onClick={handleClose}>
            Cancel
          </Button>
          {/* </Box> */}
        </DialogActions>
      </Dialog>
      <Dialog
        open={openInvoiceModal}
        onClose={handleCloseInvoice}
        aria-labelledby="invoice-dialog-title"
        aria-describedby="invoice-dialog-description"
        // maxWidth="sm"
      >
        <DialogTitle id="invoice-dialog-title">Invoice Details</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseInvoice}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500]
          })}
        >
          <CloseIcon color="error" />
        </IconButton>
        <DialogContent>
          {/* Invoice form or content can be placed here */}
          <DialogContentText id="invoice-dialog-description">Please fill out the invoice details here.</DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DeleteDialog;
