/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Button,
  Typography,
  Grid,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Divider,
  CardContent,
  CardActions,
  Backdrop,
  CircularProgress
} from '@mui/material';
// import SkipNextIcon from '@mui/icons-material/SkipNext';
// import SubscriptionPlansCarousel from './SubscriptionPlansCarousel';
// import { useGetSubcriptionForMachineList } from 'hooks/useMachineHooks';
// import ContactUsDialog from './ContactUsDialog';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import EmployeeSubcriptionForm from '../form/EmployeeSubcriptionForm';
import EmployeeSubcriptionMachineDetailsForm from '../form/EmployeeSubcriptionMachineDetailsForm';
import { useRef } from 'react';
import { useEffect } from 'react';

const MachineSubscription = ({ open, onClose, setOpenDialog }) => {
  //   const { data: subcriptionList, isLoading: isLoadingSubcriptionList } = useGetSubcriptionForMachineList({
  //     filters: { machineSubs: true }
  //   });
  const [isLoadingChooseSubscription, setLoadingChooseSubscription] = useState(false);
  const dialogContentRef = useRef(null);
  const [openMachineDetails, setOpenMachineDetails] = useState({
    open: false,
    machineObj: null,
    isCurrentSubscription: false,
    isLocalUser: false
  });
  // console.log('Employee ~ MachineSubscription ~ openDialog ~ ', open);
  /*const callInvoiceScreen = (id = '') => {
    console.log('callInvoiceScreen | id', id);
    navigate('/dashboard/machines/create-invoice', {
      state: {
        machineObj: value,
        subId: id,
        subObj: subcriptionList && subcriptionList.length > 0 ? subcriptionList.filter((sub) => sub.id === id) : null
      }
    });
  };*/

  useEffect(() => {
    if (dialogContentRef.current) {
      dialogContentRef.current.scrollTop = 0; // Scroll to the top
    }
  }, [openMachineDetails]);
  useEffect(() => {
    if (open && open.isLocalUser) {
      // console.log('Employee ~ MachineSubscription ~ openDialog ~ open?.machineObj', open?.machineObj);
      setOpenMachineDetails((prev) => ({
        ...prev,
        open: true,
        machineObj: open?.machineObj ?? null,
        isCurrentSubscription: true,
        isLocalUser: open?.isLocalUser
      }));
    }
  }, [open, open?.isLocalUser]);
  const onHandleClose = (event, reason) => {
    // Prevent closing if the reason is 'backdropClick' or 'escapeKeyDown'
    if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
      return;
    }
    onClose(); // Close the dialog only when explicitly triggered (e.g., button click)
    setOpenMachineDetails((prev) => ({
      ...prev,
      open: false,
      machineObj: null,
      isCurrentSubscription: false,
      isLocalUser: false
    }));
  };

  return (
    <>
      <Dialog open={open.show} onClose={onHandleClose} maxWidth="xl" fullWidth>
        <DialogTitle sx={{ paddingBottom: 0 }}>
          <Typography className="employee-modal-title" component="h4" variant="h2" sx={{ mb: 2 }}>
            {open.status === 402 ? 'Upgrade Subscription' : null}
            {open.status === 403 ? 'Choose Subscription' : null}
            {open.status === 0 && open?.isLocalUser ? 'Upgrade Machine Specific Subscription' : null}
            {open.status === 1 && open?.isLocalUser ? 'Add Machine Specific Subscription' : null}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={(theme) => ({
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme.palette.grey[500]
            })}
          >
            <CloseIcon color="error" />
          </IconButton>
        </DialogTitle>
        {/* <Divider /> */}
        <DialogContent ref={dialogContentRef} dividers>
          {open?.isLocalUser ? (
            <>
              {openMachineDetails.open ? (
                <EmployeeSubcriptionMachineDetailsForm
                  openMachineDetails={openMachineDetails}
                  setOpenMachineDetails={setOpenMachineDetails}
                  onClose={onClose}
                  setOpenDialog={setOpenDialog}
                  onHandleClose={onHandleClose}
                  setLoadingChooseSubscription={setLoadingChooseSubscription}
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              {!openMachineDetails.open ? (
                <EmployeeSubcriptionForm setOpenMachineDetails={setOpenMachineDetails} />
              ) : (
                <EmployeeSubcriptionMachineDetailsForm
                  openMachineDetails={openMachineDetails}
                  setOpenMachineDetails={setOpenMachineDetails}
                  onClose={onClose}
                  setOpenDialog={setOpenDialog}
                  onHandleClose={onHandleClose}
                  setLoadingChooseSubscription={setLoadingChooseSubscription}
                />
              )}
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 99999999 })} open={isLoadingChooseSubscription}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* {openContactUs && <ContactUsDialog open={openContactUs} setOpen={setOpenContactUs} />} */}
    </>
  );
};

export default MachineSubscription;
