import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Formik } from 'formik';
import OrganizationEditModalForm from './OrganizationEditModalForm';
// import { useGetAllEmployeeListModal } from 'hooks/useOrganizationNewHooks';
import * as Yup from 'yup';
import { useOrganizationNewUpdate } from 'hooks/useOrganizationNewHooks';
const OrganizationEditDialog = ({
  open,
  setOpen,
  organizationNewDetails,
  employeerelocation,
  isEmployeeRelocation,
  employeerelocationData,
  storeCurrentOrgType,
  storeSubmitOrg
  // setStoreSubmitOrg
}) => {
  const handleClose = () => {
    setOpen(false);
  };
  const { mutate: updateOrganizationNew, isPending: isLoadingOrganizationNew } = useOrganizationNewUpdate();
  // Yup Validation Schema
  const validationSchema = Yup.object().shape({
    org_types: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string().required('Employee ID is required'), // Ensures each employee has an ID
          selectedOrgTypes: Yup.array()
            .nullable()
            .test(
              'at-least-one-selection',
              'At least one organization type is required if reallocated',
              (value) => !value || value.length > 0 // Only validate if modified
            )
        })
      )
      .test(
        'at-least-one-employee',
        'At least one employee must be reallocated',
        (orgTypes) => orgTypes?.some((org) => org.selectedOrgTypes.length > 0) // Ensure at least one employee is updated
      )
  });

  return (
    <>
      <Dialog
        open={open}
        fullWidth={false}
        fullScreen={false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
      >
        <DialogTitle
          id="alert-dialog-title"
          variant="span"
          style={{
            textTransform: 'capitalize',
            borderBottom: '1px solid #ccc',
            padding: '20px 24px',
            whiteSpace: 'pre-wrap'
          }}
        >
          Employee Relocation
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500]
          })}
        >
          <CloseIcon color="error" />
        </IconButton>
        <DialogContent className="text-wrap-auto">
          {' '}
          <Formik
            initialValues={{
              org_types: [
                {
                  id: '',
                  selectedOrgTypes: []
                }
              ]
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              const submitData = {
                ...storeSubmitOrg,
                relocatedEmployees: values?.org_types
              };
              // console.log('🚀 ~ submitData:', submitData);
              updateOrganizationNew(submitData);
            }}
          >
            {({ errors, touched, handleChange, values, handleSubmit, setFieldValue, handleBlur, setFieldTouched }) => (
              <OrganizationEditModalForm
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                values={values}
                handleSubmit={handleSubmit}
                setFieldValue={setFieldValue}
                handleClose={handleClose}
                organizationNewDetails={organizationNewDetails}
                employeerelocation={employeerelocation}
                isEmployeeRelocation={isEmployeeRelocation}
                employeerelocationData={employeerelocationData}
                storeCurrentOrgType={storeCurrentOrgType}
                handleBlur={handleBlur}
                isLoadingOrganizationNew={isLoadingOrganizationNew}
                setFieldTouched={setFieldTouched}
              />
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default OrganizationEditDialog;
