import { useMutation, useQuery } from '@tanstack/react-query';
import { endpoints } from 'endpoints/endpoints';
import axiosInstance from 'utils/axiosInstance';
import { setToast, toastConfig } from 'utils/commonUtil';

export const useGetSupportAdd = async (data) => {
  const res = await axiosInstance.post(`${endpoints.getsupportTicket}/save`, data);
  return res;
};

// List Api
export const useGetSupportTicketList = ({
  limit,
  sortOrder,
  sortField,
  page,
  status,
  supportType,
  ticketNo,
  subject,
  message,
  organizeName
}) => {
  const organizationType = localStorage.getItem('orgType');
  const usersType = localStorage.getItem('userType');
  return useQuery({
    queryKey: [
      'getSupportTicketListData',
      limit,
      sortOrder,
      sortField,
      page,
      status,
      supportType,
      ticketNo,
      subject,
      message,
      organizeName,
      organizationType,
      usersType
    ],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.getsupportTicket}/${page}/${limit}`, {
          filters: {
            status: status ? status : null,

            supportType: supportType ? supportType : null,
            subject: subject || null,
            // message: message || null,
            organizationName: organizeName ? organizeName : null
          },
          sort: {
            sortField: sortField,
            sortOrder: sortOrder
          }
        })

        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    staleTime: 0,
    enabled: Boolean(organizationType) && Boolean(usersType) && Boolean(localStorage.getItem('userJwtToken')),
    // refetchOnMount: true,
    // enabled: Boolean(limit) && Boolean(sortField),
    // refetchOnMount: false, // Avoid unnecessary refetching on component mount
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

//PermissionList

export const useGetPermissionsGetSupportList = ({ organizationType, usersType }) => {
  return useQuery({
    queryKey: ['getPermissionsGetSupport', organizationType, usersType],
    queryFn: async () => {
      return await axiosInstance

        .get(`${endpoints.permissionsScreen}/${usersType}/${organizationType}/Get_Support`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          toastConfig.type = 'error';
          setToast(toastConfig, e.response.data.e);
        });
    },
    enabled: Boolean(organizationType === 'SuperAdmin') && Boolean(usersType),
    staleTime: 0,
    onSuccess: ({ data }) => {
      return data;
    }
    // refetchInterval: 30000
  });
};

/// Details Api
export const useGetSupportTicketDetailsId = ({ id, enabled = true }) => {
  return useQuery({
    queryKey: ['getSupportTicketDetailsById', id],
    queryFn: async () => {
      return await axiosInstance
        .get(`${endpoints.getsupportTicket}/${id}`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          toastConfig.type = 'error';
          setToast(toastConfig, e.response.data.e);
          console.log(e);
        });
    },
    staleTime: 0,
    enabled: Boolean(id) && enabled,
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

///////
export const useGetPermissionsGetSupportTicketList = ({ organizationType, usersType }) => {
  return useQuery({
    queryKey: ['getPermissionsGetSupport', organizationType, usersType],
    queryFn: async () => {
      return await axiosInstance

        .get(`${endpoints.permissionsScreen}/${usersType}/${organizationType}/Support_Tickets`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          toastConfig.type = 'error';
          setToast(toastConfig, e.response.data.e);
        });
    },
    enabled: Boolean(organizationType) && Boolean(usersType),
    // enabled: Boolean(organizationType !== 'SuperAdmin') && Boolean(usersType),
    staleTime: 0,
    onSuccess: ({ data }) => {
      return data;
    }
    // refetchInterval: 30000
  });
};

export const useGetReportReason = () => {
  return useMutation({
    mutationFn: async (data) => {
      return await axiosInstance
        .patch(`${endpoints.getsupportTicket}/${data?.id}`, { status: data?.status, reopenReason: data?.reopenReason })
        .then((res) => {
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data.message);
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },
    onSuccess: ({ data }) => {
      // queryClient.refetchQueries('getTicketReasonData');
      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};

///mark as resolved
export const useGetMarkAsResolved = () => {
  return useMutation({
    mutationFn: async (data) => {
      return await axiosInstance
        .patch(`${endpoints.getsupportTicket}/${data?.id}`, { status: data?.status })
        .then((res) => {
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data.message);
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },
    onSuccess: ({ data }) => {
      // queryClient.refetchQueries('getTicketReasonData');
      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};
