import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { endpoints } from 'endpoints/endpoints';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import axiosInstance from 'utils/axiosInstance';
import { setToast, toastConfig } from 'utils/commonUtil';

export const useGetMachineList = ({ limit, sortOrder, sortField, current, previous, first, last, enterpriseId }) => {
  return useQuery({
    queryKey: ['getMachineData', limit, sortOrder, sortField, current, previous, first, last, enterpriseId],
    queryFn: async () => {
      return await axiosInstance
        // .get(
        //   `${endpoints.machines}/all?previous=${previous}&current=${current}&sortField=${sortField}&limit=${limit}&sortOrder=${sortOrder}&first=${first}&last=${last}&enterpriseId=${enterpriseId}`
        // )
        .post(`${endpoints.machines}`, {
          previous: previous,
          current: current,
          limit: limit,
          first: first,
          last: last,
          field: sortField,
          order: sortOrder
          // enterpriseId:enterpriseId
        })
        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

export const useMchineUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      return await axiosInstance
        .put(`${endpoints.machines}/${data.id}`, data)
        .then((res) => {
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data.message);
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },
    onSuccess: ({ data }) => {
      queryClient.refetchQueries({ queryKey: ['getMachineDetailsById'], type: 'active' });
      // queryClient.refetchQueries('getMachineData');
      // queryClient.refetchQueries('getLocationDetailsById');
      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};

// export const useMchineUpdate = () => {
//   const queryClient = useQueryClient();
//   return useMutation({
//     mutationFn: async (data) => {
//       return await axiosInstance
//         .patch(`${endpoints.machines}/${data.id} `, data)
//         .then((res) => {
//           if (res?.status == 200) {
//             toastConfig.type = 'success';
//             setToast(toastConfig, res?.data.success);
//           } else {
//             toastConfig.type = 'error';
//             setToast(toastConfig, error?.response?.data?.error);
//           }

//           return res;
//         })
//         .catch((error) => {
//           toastConfig.type = 'error';
//           setToast(toastConfig, error?.response?.data?.error);
//         });
//     },
//     onSuccess: ({ data }) => {
//        queryClient.refetchQueries('getMachineData');
//       // queryClient.refetchQueries('getLocationDetailsById');
//       return data;
//     },
//     onError: ({ error }) => {
//       return error;
//     },
//     onSettled: ({ data }) => {
//       return data;
//     }
//   });
// };

// export const useMchineUpdate = () => {
//   const queryClient = useQueryClient();

//   return useMutation({
//     mutationFn: async (data) => {
//       const { enterprise, recycler, ...rest } = data;

//       const requestBody = enterprise
//         ? {
//             enterprise, // Selected enterprise id
//             location: data.location, // Selected either retailer or enterprise location id
//             retailer: data.retailer, // Selected retailer id
//             price: data.price // Entered price in Number
//           }
//         : {
//             recycler, // Selected recycler id
//             location: data.location, // Selected recycler location id
//             price: data.price // Entered price in Number
//           };

//       return await axiosInstance
//         .patch(`${endpoints.machines}/${data.id}`, requestBody)
//         .then((res) => {
//           if (res?.status === 200) {
//             toastConfig.type = 'success';
//             setToast(toastConfig, res?.data.success);
//           } else {
//             toastConfig.type = 'error';
//             setToast(toastConfig, error?.response?.data?.error);
//           }

//           return res;
//         })
//         .catch((error) => {
//           toastConfig.type = 'error';
//           setToast(toastConfig, error?.response?.data?.error);
//         });
//     },
//     onSuccess: ({ data }) => {
//       queryClient.refetchQueries('getMachineData');
//       return data;
//     },
//     onError: ({ error }) => {
//       return error;
//     },
//     onSettled: ({ data }) => {
//       return data;
//     }
//   });
// };

export const useGetMachineById = ({ id }) => {
  return useQuery({
    queryKey: ['getMachineDetailsById', id],
    queryFn: async () => {
      return await axiosInstance
        .get(`${endpoints.machines}/${id}`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    enabled: Boolean(id),
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

////all Machine List

export const useGetAllMachineList = () => {
  return useQuery({
    queryKey: ['getAllMachineListData'],
    queryFn: async () => {
      return await axiosInstance

        .post(`${endpoints.machines}`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

// get Machine Subscription Sync
export const useGetMachineSubscription = (data) => {
  return useQuery({
    queryKey: ['getMachineSubscriptionData', data.id],
    queryFn: async () => {
      return await axiosInstance

        .get(`${endpoints.machines}/${data.id}/subscriptions`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onSuccess: ({ data }) => {
      return data;
    },
    enabled: data.active || false
  });
};
// get Machine Subscription Sync
export const useGetMachineApiKeyRegenerate = (data) => {
  return useQuery({
    queryKey: ['getMachineApiKeyRegenerateData', data.id],
    queryFn: async () => {
      return await axiosInstance

        .get(`${endpoints.machines}/${data.id}/apikey/regenerate`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onSuccess: ({ data }) => {
      return data;
    },
    enabled: data.active || false
  });
};

/**
 * The function `useGetJobEnterpriseMachineList` fetches a list of machines associated with a specific
 * enterprise using a query key and axios for API requests.
 * @returns The `useGetJobEnterpriseMachineList` function is returning a custom hook that uses the
 * `useQuery` hook from a library like React Query. This custom hook fetches data by making a POST
 * request to an API endpoint to retrieve a list of machines associated with a specific enterprise. The
 * function returns the data fetched from the API if the response status is 200, otherwise it returns
 * an empty array
 */
export const useGetJobEnterpriseMachineList = ({ enterprise }) => {
  return useQuery({
    queryKey: ['getJobEnterpriseMachineListData', enterprise],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.machines}`, {
          enterprise: enterprise
        })

        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

// Machine Create API
export const useMchineCreate = () => {
  // const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      return await axiosInstance
        .post(`${endpoints.machines}/save`, data)
        .then((res) => {
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data.success);
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },
    onSuccess: ({ data }) => {
      // queryClient.refetchQueries({ queryKey: ['getMachineDetailsById'], type: 'active' });
      // queryClient.refetchQueries('getMachineData');
      // queryClient.refetchQueries('getLocationDetailsById');
      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};

export const useGetMachineNewList = ({
  limit,
  sortOrder,
  sortField,
  page,
  buyer,
  seller,
  status,
  type,
  nickname,
  model,
  softwareVersions
}) => {
  const organizationType = localStorage.getItem('orgType');
  const usersType = localStorage.getItem('userType');
  return useQuery({
    queryKey: [
      'getMachineNewData',
      limit,
      sortOrder,
      sortField,
      page,
      buyer,
      seller,
      status,
      type,
      nickname,
      model,
      softwareVersions,
      organizationType,
      usersType
    ],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.machines}/${page}/${limit}`, {
          // previous: previous,
          // current: current,
          // limit: limit,
          // first: first,
          // last: last,
          // field: sortField,
          // order: sortOrder
          //   searchParams: {
          //     enabled: status ? status : null,
          //     name: orgName || null
          //   },
          //   sortParams: {
          //     sortField: sortField,
          //     sortOrder: sortOrder
          //   }
          type: type || null,
          filters: {
            /*enabled: status ? status : null,
            name: orgName || null,
            types: orgTypes || null*/
            buyer: buyer || null,
            seller: seller || null,
            nickname: nickname || null,
            model: model || null,
            softwareVersions: softwareVersions || null,
            // type: type || null,
            status: status || null
          },
          sort: {
            sortField: sortField,
            sortOrder: sortOrder
          }
        })

        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    enabled: Boolean(organizationType) && Boolean(usersType) && Boolean(localStorage.getItem('userJwtToken')),
    cacheTime: 0,
    // enabled: Boolean(limit) && Boolean(sortField),
    // refetchOnMount: false, // Avoid unnecessary refetching on component mount
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

export const useGetPermissionsMachineList = ({ organizationType, usersType }) => {
  return useQuery({
    queryKey: ['getPermissionsMachine', organizationType, usersType],
    queryFn: async () => {
      return await axiosInstance
        // .get(`${endpoints.permissionsScreen}/Machines/${usersType}/${organizationType}`)
        .get(`${endpoints.permissionsScreen}/${usersType}/${organizationType}/Machines`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          toastConfig.type = 'error';
          setToast(toastConfig, e.response.data.e);
        });
    },
    enabled: Boolean(organizationType) && Boolean(usersType),
    cacheTime: 0,
    staleTime: 0,
    onSuccess: ({ data }) => {
      return data;
    }
    // refetchInterval: 30000
  });
};

export const useGetSubcriptionForMachineList = (data = {}) => {
  return useQuery({
    queryKey: ['getSubcriptionForMachineList'],
    queryFn: async () => {
      return await axiosInstance
        .post(`/v1/subscriptions`, data)
        // .get(`${endpoints.permissionsScreen}/${usersType}/${organizationType}/Machines`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          toastConfig.type = 'error';
          setToast(toastConfig, e.response.data.e);
        });
    },
    // enabled: Boolean(organizationType) && Boolean(usersType),
    onSuccess: ({ data }) => {
      return data;
    }
    // refetchInterval: 30000
  });
};

export const useGetSubcriptionPlansForMachineList = (data = {}) => {
  return useQuery({
    queryKey: ['getSubcriptionPlansForMachineList'],
    queryFn: async () => {
      return await axiosInstance
        .post(`/v1/subscriptions/plans`, data)
        // .get(`${endpoints.permissionsScreen}/${usersType}/${organizationType}/Machines`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          toastConfig.type = 'error';
          setToast(toastConfig, e.response.data.e);
        });
    },
    // enabled: Boolean(organizationType) && Boolean(usersType),
    onSuccess: ({ data }) => {
      return data;
    }
    // refetchInterval: 30000
  });
};

export const useGetAllMachineNewList = (data = {}) => {
  return useQuery({
    queryKey: ['getAllMachineNewData', data],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.machines}`, data)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
          toastConfig.type = 'error';
          setToast(toastConfig, e.response.data.error);
        });
    },
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

///api/v1/machines/assignments

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Cleanup the timeout when the component is unmounted or the value/delay changes
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};
export const useGetAllMachineAssignmentsList = (show = true, searchInput = '') => {
  const debouncedSearchInput = useDebounce(searchInput, 300); // Apply debounce with 300ms delay

  // console.log('useGetAllMachineAssignmentsList - show', show);
  return useQuery({
    queryKey: ['getAllMachineAssignmentsData', debouncedSearchInput],
    queryFn: async () => {
      return await axiosInstance
        .post(`${endpoints.machines}/assignments`, {
          filters: {
            searchInput: debouncedSearchInput
          }
        })
        .then((res) => {
          if (res?.status == 200) {
            return res?.data || [];
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
          toastConfig.type = 'error';
          setToast(toastConfig, e.response.data.error);
        });
    },
    onSuccess: ({ data }) => {
      return data;
    },
    // enabled: Boolean(show) && searchInput.length >= 3, // Only trigger the query when the searchTerm has at least 3 characters
    enabled: Boolean(show), // Only trigger the query when the searchTerm has at least 3 characters
    keepPreviousData: true, // Optionally keep the previous data while loading
    cacheTime: 0,
    staleTime: 0
  });
};

// /api/v1/machines/subscriptions
export const useGetAllMachineSubscriptionsList = (show) => {
  // const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      return await axiosInstance
        .post(`${endpoints.machines}/subscriptions`, {
          filters: {
            searchInput: data
          }
        })
        .then((res) => {
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data.success);
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },
    onSuccess: ({ data }) => {
      // queryClient.refetchQueries({ queryKey: ['getMachineDetailsById'], type: 'active' });
      // queryClient.refetchQueries('getMachineData');
      // queryClient.refetchQueries('getLocationDetailsById');
      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    },
    enabled: Boolean(show), // Only trigger the query when the searchTerm has at least 3 characters
    keepPreviousData: true, // Optionally keep the previous data while loading
    cacheTime: 0,
    staleTime: 0
  });
};

////// Jobs Machines

export const useGetAllMachineJobList = (data = {}) => {
  return useQuery({
    queryKey: ['getAllMachineJobData', data],
    queryFn: async () => {
      return await axiosInstance
        // .get(`${endpoints.jobs}/machines`, data, {})
        .post(`${endpoints.machines}`, {})
        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
          toastConfig.type = 'error';
          setToast(toastConfig, e.response.data.error);
        });
    },
    onSuccess: ({ data }) => {
      return data;
    }
  });
};

// Update Machine Subscription in Employee Module
export const useMachineSubscriptionUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      return await axiosInstance
        .put(`${endpoints.machines}/${data?.id}/subscription`, {
          subscriptionId: data?.subId || null,
          customSubscription: data?.customSubscription || null
        })
        .then((res) => {
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data.success);
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },
    onSuccess: ({ data }) => {
      // console.log('useMachineSubscriptionUpdate - success ', data);
      // queryClient.refetchQueries({ queryKey: ['getGlobalUserCount'] });
      // queryClient.refetchQueries(['getAllMachineAssignmentsData', '']);
      // queryClient.refetchQueries({ queryKey: ['getMachineDetailsById'], type: 'active' });
      toastConfig.type = 'success';
      setToast(toastConfig, data.message);
      queryClient.invalidateQueries(['getGlobalUserCount']);
      queryClient.invalidateQueries(['getAllMachineAssignmentsData', '']);
      return data;
    },
    onError: ({ error }) => {
      console.log('useMachineSubscriptionUpdate - error', error);
      // queryClient.invalidateQueries(['getGlobalUserCount']);
      // queryClient.invalidateQueries(['getAllMachineAssignmentsData', '']);
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};

///
// export const useMachineExistingClientAdd = async (data) => {
//   const res = await axiosInstance.patch(`${endpoints.machines}/clients/assignments`, data, {});
//   return res;
// };
export const useRentMachineUpdate = () => {
  // const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: async (data) => {
      // console.log('🚀 ~ mutationFn: ~ data:', data);
      return await axiosInstance
        // .patch(`${endpoints.locations}/${data.id}`, data)
        .put(`${endpoints.machines}/clients/assignments/${data.id}`, data)
        .then((res) => {
          // console.log('useLocationUpdate res', res);
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data.message);
            navigate('/dashboard/machines');
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },

    onSuccess: ({ data }) => {
      // queryClient.refetchQueries({ queryKey: ['getOrganizationNewDetailsById'], type: 'active' });
      // queryClient.refetchQueries('getLocationDetailsById');
      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};

///// Machine Status

export const useMachineStatus = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      return await axiosInstance
        .patch(`${endpoints.machines}/status/${data.id}`, {
          status: data?.status
        })
        .then((res) => {
          if (res?.status == 200) {
            toastConfig.type = 'success';
            // setToast(toastConfig, res?.data.message);
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },
    onSuccess: ({ data }) => {
      queryClient.refetchQueries({ queryKey: ['getMachineDetailsById'], type: 'active' });
      queryClient.refetchQueries({ queryKey: ['getMachineNewData'], type: 'active' });
      // queryClient.refetchQueries('getMachineData');
      // queryClient.refetchQueries('getLocationDetailsById');
      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};

// Thresold Bin

export const useMachineBinThresold = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data) => {
      console.log('🚀 ~ mutationFn: ~ data:', data);
      return await axiosInstance
        .patch(`${endpoints.machines}/${data.id}/threshold`, {
          binThreshold: data?.binThreshold
        })
        .then((res) => {
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data.message);
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          console.log('🚀 ~ mutationFn: ~ error:', error);
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },
    onSuccess: ({ data }) => {
      queryClient.refetchQueries({ queryKey: ['getMachineDetailsById'], type: 'active' });
      queryClient.refetchQueries({ queryKey: ['getMachineNewData'], type: 'active' });

      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};

//
export const useGetMachineUrlPdf = ({ id, apiKey }) => {
  // const queryClient = useQueryClient();
  return useQuery({
    queryKey: ['getMachineUrlPdf', id],
    queryFn: async () => {
      return await axiosInstance
        .get(`${endpoints.machines}/manual/${id}`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          toastConfig.type = 'error';
          setToast(toastConfig, e.response.data.e);
          console.log(e);
        });
    },
    enabled: Boolean(id) && apiKey !== null
    // onSuccess: ({ data }) => {
    //   queryClient.refetchQueries({
    //     queryKey: ['getJobById'],
    //     type: 'active'
    //   });
    //   return data;
    // }
  });
};
