/* eslint-disable */
import { Formik } from 'formik';
import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import EmployeeForm from '../form';
import BreadcrumbsForPage from 'ui-component/extended/BreadcrumbsForPage';
import * as Yup from 'yup';
import 'yup-phone';
import { useEmployeeUpdate, useGetEmployeeById, useGetPermissionsEmployeeList, useGetSubcriptionsLimit } from 'hooks/useEmployeesHooks';
import { useSelector } from 'react-redux';
import { Alert, AlertTitle } from '@mui/material';
import { Lock } from '@mui/icons-material';
import OrganizationFormView from 'ui-component/cards/Skeleton/OrganizationFormView';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useEffect } from 'react';
import { useRef } from 'react';

const EmployeeEdit = () => {
  // {Yup.object().shape({
  //   prefix: Yup.string().required('Prefix is required'),
  //   firstName: Yup.string()
  //     .max(50, 'First Name must be at most 50 characters')
  //     .required('First Name is required')
  //     .trim()
  //     .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')
  //     .matches(/^[a-zA-Z\s]*$/, 'First Name should only contain letters and spaces'),
  //   lastName: Yup.string().max(50, 'Last Name must be at most 50 characters').required('Last Name is required').trim(),
  //   title: Yup.string().required('Title is required'),
  //   email: Yup.string().email('Invalid email').required('Email is required'),
  //   phone: Yup.string()
  //     .phone('IN', true, 'Invalid phone number') // Use yup-phone if necessary
  //     .required('Phone number is required'),
  //   address: Yup.string().max(255, 'Address can be at most 255 characters'),
  //   rfid: Yup.string().required('RFID is required'),
  //   authPin: Yup.string().required('Auth Pin is required')
  // })}
  let { id } = useParams();
  const navigator = useNavigate();
  const { data: employeeDetails, isLoading: isLoadingEmployee } = useGetEmployeeById({
    id: id.slice(1)
  });
  const { mutate: editEmployee, isPending: isLoadingEditEmployee } = useEmployeeUpdate();
  const { mutate: checkEmployeeAdd, isPending: isLoadingCheckAdd } = useGetSubcriptionsLimit();

  // console.log('Employee ~ Edit ~ employeeDetails : ', employeeDetails, isLoadingEmployee);

  const orgType = useSelector((state) => state.customization.orgType);
  const organizationType = useSelector((state) => state.customization.orgType);
  const usersType = useSelector((state) => state.customization.userType);
  const { data: permissionsList, isLoading: isLoadingPermissionsList } = useGetPermissionsEmployeeList({
    organizationType: organizationType !== 'Super Admin' ? organizationType.replaceAll(' ', '') : 'SuperAdmin',
    usersType: usersType === 'Main POC' ? 'POC' : usersType
  });

  const [openDialog, setOpenDialog] = useState({ show: false, status: 0, isLocalUser: false, machineObj: null });
  const [isEmployeeValidForOrg, setEmployeeValidForOrg] = useState(true);
  /*useEffect(() => {
    if (employeeDetails && !isLoadingEmployee) {
      if (
        employeeDetails?.organizationTypes &&
        Array.isArray(employeeDetails.organizationTypes) &&
        employeeDetails?.organizationTypes?.length
      ) {
        if (!employeeDetails.organizationTypes.includes(organizationType.toString().replaceAll(' ', ''))) {
          setEmployeeValidForOrg(false);
        }
      }
    }
  }, [employeeDetails]);*/

  const permissionsEdit = permissionsList?.edit;
  const permissionsEditObj = permissionsList?.edit;
  const permissionsShowEdit = permissionsList?.showEdit;
  /*
  if (permissionsEdit && Array.isArray(permissionsEdit) && permissionsEdit.length) {
    if (permissionsEdit[permissionsEdit.length - 1].id !== 'organizationType')
      permissionsEdit.push({
        id: 'organizationType',
        isRequired: false,
        label: 'Buyer Organization Type',
        show: true,
        isStatic: false,
        _id: 'hgdvhavdgahs554555'
      });
  }*/
  if (permissionsEditObj && Array.isArray(permissionsEditObj) && permissionsEditObj.length) {
    if (permissionsEditObj[permissionsEditObj.length - 1].id !== 'hasGlobalAccess') {
      const idsSet = new Set(
        permissionsEditObj.map((item) => {
          if (item.show) return item.id;
        })
      );
      // console.log('Employee ~ hasGlobalAccess - idsSet', idsSet);
      if (idsSet.has('empAssignMachines')) {
        // permissionsEditObj.push({
        //   id: 'hasGlobalAccess',
        //   isRequired: false,
        //   label: 'Make This Employee "Global User"',
        //   show: true,
        //   _id: 'hgdvhavdgahs554555'
        // });
      }
    }
  }

  // console.log('location : Employee Edit n : ', permissionsEdit, permissionsShowEdit, ' | permissionsEditObj : ', permissionsEditObj);
  // Create dynamic input refs based on permissionsEditObj
  // const inputRefs = useRef({});

  const keyMapping = {
    empPrefix: 'prefix',
    empFirstName: 'firstName',
    empLastName: 'lastName',
    empTitle: 'title',
    empEmail: 'email',
    empPhone: 'phone',
    empAddress: 'address',
    empRfid: 'rfid',
    empAuthPin: 'authPin',
    empInstallerRfid: 'installerRfid',
    empInstallerAuthPin: 'installerAuthPin',
    profileImage: 'profileImage',
    empIsAdmin: 'isAdmin',
    empAssignOrg: 'assign_org',
    empAssignMachines: 'assign_machine',
    machineUserType: 'machineUserType',
    hasGlobalAccess: 'hasGlobalAccess',
    empOrgType: 'org_type'
  };
  const phoneRegexMain = RegExp(/^\d{3}-\d{3}-\d{4}$/);
  const validationSchema = Yup.object().shape(
    permissionsEditObj?.reduce((acc, field) => {
      const { id, label, isRequired, show } = field;
      // console.log('field | validationSchema | Employee : ', field);
      // Only apply validation if 'show' is true
      if (show) {
        let validationRule;
        const errorMessage = `${label} is required`;
        const labelMsg = label.replace(/\bSelect\b\s*/, '');

        // Define validation rules based on field ID
        switch (id) {
          case 'empPrefix':
          case 'empFirstName':
          case 'empLastName':
          case 'empTitle':
            validationRule = Yup.string().max(100, `${labelMsg} cannot be longer than 100 characters`);
            if (isRequired) validationRule = validationRule.required(errorMessage);
            break;
          case 'empAddress':
            validationRule = Yup.string().max(150, `${labelMsg} cannot be longer than 150 characters`);
            if (isRequired) validationRule = validationRule.required(errorMessage);
            break;
          case 'empEmail':
            validationRule = Yup.string().email(`Invalid ${labelMsg} format`);
            if (isRequired) validationRule = validationRule.required(errorMessage);
            break;
          case 'empPhone':
            validationRule = Yup.string().matches(phoneRegexMain, 'Employee Phone is not valid').trim();
            // Yup.string().matches(/^[0-9]+$/, `${labelMsg} number should be numeric`);
            // if (isRequired) validationRule = validationRule.required(errorMessage);
            break;

          case 'empRfid':
            // if (orgType === 'Enterprice') {
            validationRule = Yup.string().matches(/^[a-zA-Z0-9-]+$/, `${labelMsg} should be alphanumeric and may contain hyphens`);
            if (isRequired) validationRule = validationRule.required(errorMessage);
            // }
            break;

          case 'empAuthPin':
            // if (orgType === 'Enterprice') {
            // validationRule = Yup.string().matches(/^[a-zA-Z0-9]+$/, `${labelMsg} should be alphanumeric`);
            validationRule = Yup.number()
              .integer(`${labelMsg} must be an integer`)
              .min(0, `${labelMsg} cannot be negative`)
              .typeError(`${labelMsg} must be a valid number`);
            if (isRequired) validationRule = validationRule.required(errorMessage);
            // }
            break;
          case 'empInstallerRfid':
            // if (orgType === 'Super Admin') {
            validationRule = Yup.string().matches(/^[a-zA-Z0-9-]+$/, `${labelMsg} should be alphanumeric and may contain hyphens`);
            if (isRequired) validationRule = validationRule.required(errorMessage);
            // }
            break;
          case 'empInstallerAuthPin':
            // if (orgType === 'Super Admin') {
            // validationRule = Yup.string().matches(/^[a-zA-Z0-9]+$/, `${labelMsg} should be alphanumeric`);
            validationRule = Yup.number()
              .integer(`${labelMsg} must be an integer`)
              .min(0, `${labelMsg} cannot be negative`)
              .typeError(`${labelMsg} must be a valid number`);
            if (isRequired) validationRule = validationRule.required(errorMessage);
            // }
            break;

          case 'empOrgType':
            validationRule = Yup.array().of(Yup.mixed());
            // if (isRequired) validationRule = validationRule.min(1, `Must have at least one ${label}`).required(errorMessage);
            if (isRequired)
              validationRule = validationRule
                .min(1, `Please select at least one Organization Type to Edit Employee`)
                .required(errorMessage);
            break;
          case 'empAssignOrg':
            validationRule = Yup.array().of(Yup.object());
            if (isRequired) validationRule = validationRule.min(1, `Must have at least one ${label}`).required(errorMessage);
            break;

          case 'empAssignMachines':
            validationRule = Yup.array().of(Yup.object());
            if (isRequired) validationRule = validationRule.min(1, `Must have at least one ${label}`).required(errorMessage);
            break;
          case 'empAllocateMachine':
            validationRule = Yup.array().of(Yup.object());
            if (isRequired) validationRule = validationRule.min(1, `Must have at least one ${label}`).required(errorMessage);
            break;

          case 'machineUserType':
            validationRule = Yup.object().test('validate-machine-user-type', 'Machine User Type cannot be empty', function (value) {
              const errors = {};
              for (let key in value) {
                if (!value[key].machineTypeUser || value[key].machineTypeUser.trim() === '') {
                  errors[key] = {
                    machineTypeUser: 'Please Select Machine User Type'
                  };
                }
              }
              if (Object.keys(errors).length > 0) {
                return this.createError({
                  path: 'machineUserType',
                  message: JSON.stringify(errors),
                  params: { errors }
                });
              }
              return true;
            });
            break;
          case 'hasGlobalAccess':
            validationRule = Yup.boolean().oneOf([true, false], 'Must select Yes or No');
            break;

          default:
            validationRule = Yup.string().optional(); // Default case for any other field
        }

        // Add validation rule to accumulator
        acc[keyMapping[id]] = validationRule;
        // if (keyMapping[id]) inputRefs.current[keyMapping[id]] = inputRefs.current[keyMapping[id]] || { current: null };
      }

      return acc;
    }, {})
  );
  // console.log('inputRefs employee edit', inputRefs);
  function extractIds(data) {
    return data && Array.isArray(data) && data.length ? data.map((item) => item.id) : null;
  }

  const editEmployeeHandler = (submitData, resetForm, navigator) => {
    // debugger;
    editEmployee(submitData, {
      onSuccess: (res) => {
        // console.log('res - submitData - Employee - EDIT : ', res);
        if (res?.status === 200) {
          // toastConfig.type = 'success';
          // setToast(toastConfig, res?.data.message);
          resetForm({});
          navigator('/dashboard/employees');
        } else {
          // toastConfig.type = 'error';
          // setToast(toastConfig, error?.response?.data?.error);
        }
      },
      onError: (error) => {
        // toastConfig.type = 'error';
        // setToast(toastConfig, error?.response?.data?.error);
      }
    });
  };

  return (
    <>
      <BreadcrumbsForPage
        name="Edit Employee"
        obj={{
          title: 'All Employees',
          title2: 'Edit Employee',
          url: '/dashboard/employees'
        }}
      />
      <MainCard>
        <Formik
          initialValues={{
            prefix: '',
            firstName: '',
            lastName: '',
            title: '',
            email: '',
            phone: '',
            address: '',
            rfid: '',
            authPin: '',
            installerRfid: '',
            installerAuthPin: '',
            // documents: [],
            profileImage: '',
            isAdmin: false,
            // assignOrg: [],
            // assignMachine: [],
            assign_org: [],
            assign_machine: [],
            allocate_machine: [],
            machineUserType: {},
            createdByOrgType: orgType,
            hasGlobalAccess: false,
            org_type: []
            // submit: null,
            // user_type: ''
          }}
          validationSchema={validationSchema}
          onSubmit={async (value, { resetForm }) => {
            const organizationType =
              value?.org_type && Array.isArray(value?.org_type) && value?.org_type?.length
                ? value?.org_type.map((o) => {
                    return o.toString().replaceAll(' ', '');
                  })
                : '';
            const submitData = {
              id: employeeDetails?.id || '',
              isAdmin: value?.isAdmin ?? null,
              // createdByOrgType: value?.createdByOrgType ? value?.createdByOrgType?.replaceAll(' ', '') : '' || null,
              prefix: value?.prefix || null,
              firstName: value?.firstName || null,
              lastName: value?.lastName || null,
              title: value?.title || null,
              email: value?.email || null,
              phone: value?.phone || null,
              address: value?.address || null,
              authenticationInfo:
                value?.rfid || value?.authPin
                  ? {
                      rfidCode: value?.rfid || null,
                      authPIN: value?.authPin || null
                    }
                  : null,
              saAuthenticationInfo:
                value?.installerRfid || value?.installerAuthPin
                  ? {
                      rfidCode: value?.installerRfid || null,
                      authPIN: value?.installerAuthPin || null
                    }
                  : null,
              image:
                value?.profileImage && Array.isArray(value?.profileImage) && value?.profileImage.length ? value?.profileImage[0] : null,
              assignedOrganizations: extractIds(value?.assign_org) || [],
              assignedMachines: extractIds(value?.assign_machine) || [],
              // allotedMachines: transformMachineUserType(value?.machineUserType) || [],
              allocatedMachines: extractIds(value?.allocate_machine) || [],
              hasGlobalAccess: value?.hasGlobalAccess || null,
              organizationTypes: organizationType || null
            };

            // console.log(
            //   '🚀 ~ onSubmit={ ~ submitData | Employee :',
            //   submitData,
            //   value,
            //   employeeDetails /*
            //   value?.machineUserType,
            //   ' | allotedMachines : ',
            //   transformMachineUserType(value?.machineUserType)*/
            // );

            const checkEmployeAddPayload = {
              allocatedMachines: !value?.hasGlobalAccess ? extractIds(value?.allocate_machine) || [] : null,
              hasGlobalAccess: value?.hasGlobalAccess ?? null
            };
            /*console.log(
              'checkEmployeAdd - Employee : ',
              checkEmployeAddPayload,
              submitData.createdByOrgType,
              value?.hasGlobalAccess,
              value?.allocate_machine
            );*/
            // console.log('Employee edit - value.createdByOrgType : ', value.createdByOrgType);
            if (value.createdByOrgType === 'Enterprise') {
              checkEmployeeAdd(checkEmployeAddPayload, {
                onSuccess: (res) => {
                  // console.log('res - checkEmployeeAdd - Employee : ', res, checkEmployeAddPayload);
                  if (res?.status == 200) {
                    editEmployeeHandler(submitData, resetForm, navigator);
                    // setOpenDialog(true);
                    //TESTING
                    // setOpenDialog((prev) => ({ ...prev, show: true, status: 402 }));
                  } else {
                    // toastConfig.type = 'error';
                    // setToast(toastConfig, res?.response?.data?.error);
                    if (res?.response?.status === 402 || res?.response?.status === 403) {
                      setOpenDialog((prev) => ({ ...prev, show: true, status: res?.response?.status }));
                    }
                  }
                },
                onError: (error) => {
                  // toastConfig.type = 'error';
                  // setToast(toastConfig, error?.response?.data?.error);
                  console.log('checkEmployeeAdd ~ error : ', error);
                }
              });
            } else {
              editEmployeeHandler(submitData, resetForm, navigator);
            }
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, setValues, setTouched }) => (
            <>
              {permissionsShowEdit && !isLoadingEmployee && isEmployeeValidForOrg ? (
                <EmployeeForm
                  btnName={'Save'}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  errors={errors}
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting}
                  values={values}
                  setFieldValue={setFieldValue}
                  setValues={setValues}
                  setTouched={setTouched}
                  permissionsAddEdit={permissionsEdit}
                  openDialog={openDialog}
                  setOpenDialog={setOpenDialog}
                  employeeDetails={employeeDetails}
                  isLoadingEmployee={isLoadingEmployee}
                  isLoadingAddEmployee={isLoadingEditEmployee}
                  // inputRefs={inputRefs}
                />
              ) : permissionsShowEdit === undefined || isLoadingEmployee ? (
                <>
                  <OrganizationFormView />
                </>
              ) : !isEmployeeValidForOrg ? (
                <Alert variant="outlined" severity="error" icon={<Lock />}>
                  <AlertTitle>Action Not Allowed</AlertTitle>
                  You cannot edit this Employee because they do not belong to the Active Organization <b>({organizationType})</b>.
                </Alert>
              ) : (
                <Alert variant="outlined" severity="error" icon={<Lock />}>
                  <AlertTitle>Permission Denied</AlertTitle>
                  You cannot edit this Employee due to your access permissions.
                </Alert>
              )}
            </>
          )}
        </Formik>
      </MainCard>
    </>
  );
};

export default EmployeeEdit;
