import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
  useTheme
} from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

import { Form, Formik } from 'formik';
import * as Yup from 'yup';
// import { useParams } from 'react-router-dom';

// import { useMutation } from '@tanstack/react-query';
// import { useGetReportReason } from 'hooks/useGetSupportTicket';
import { setToast, toastConfig } from 'utils/commonUtil';
import { useQueryClient } from '@tanstack/react-query';
import { gridSpacing } from 'store/constant';
import { useGetRejectReasonTicketModal } from 'hooks/useMachineTicketHooks';
// import { useState } from 'react';

const RejectTicketModal = ({ open, setOpen, ticketDetailsId, ticketDetails, refetchTicketDetails }) => {
  console.log('🚀 ~ RejectTicketModal ~ :', ticketDetails);
  const theme = useTheme();
  const handleClose = () => {
    setOpen(false);
  };
  const queryClient = useQueryClient();
  const { mutate: addrejectReason, isPending: isLoadingRejectReason } = useGetRejectReasonTicketModal();

  const validationSchema = Yup.object({
    rejectReason: Yup.string().test('no-whitespace', 'Reject reason cannot be empty', (value) => value && value.trim().length > 0)
  });
  return (
    <>
      <Dialog
        open={open}
        fullWidth={true}
        fullScreen={false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // maxWidth="sm"
      >
        <DialogTitle
          id="alert-dialog-title"
          variant="span"
          style={{
            textTransform: 'capitalize'
          }}
        >
          Reject Reason Details
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500]
          })}
        >
          <CloseIcon color="error" />
        </IconButton>

        <DialogContent dividers>
          <Formik
            initialValues={{
              rejectReason: '',
              status: 'Rejected'
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              const submitData = {
                rejectReason: values?.rejectReason.trim(),
                id: ticketDetailsId,
                status: values?.status
              };
              console.log('🚀 ~ submitData:', submitData);

              addrejectReason(submitData, {
                onSuccess: (res) => {
                  if (res?.status == 200) {
                    toastConfig.type = 'success';

                    refetchTicketDetails();
                    setOpen(false);
                    queryClient.refetchQueries({ queryKey: ['getMachineTicketNewData'], type: 'active' });
                  } else {
                    toastConfig.type = 'error';
                    setOpen(false);
                  }
                },
                onError: (error) => {
                  toastConfig.type = 'error';
                  setToast(toastConfig, error?.response?.data?.error);
                }
              });
            }}
          >
            {({ errors, touched, handleChange, values, handleSubmit, handleBlur }) => (
              <>
                {/* <ReportTicketModalForm
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  values={values}
                  handleSubmit={handleSubmit}
                  setFieldValue={setFieldValue}
                  handleClose={handleClose}
                  handleBlur={handleBlur}
                  isLoadingReportReason={isLoadingReportReason} */}
                <Form noValidate onSubmit={handleSubmit}>
                  <Grid
                    container
                    spacing={gridSpacing}
                    sx={{
                      alignContent: 'center'
                    }}
                  >
                    <Grid item xs={12}>
                      <Grid container spacing={gridSpacing}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <FormControl fullWidth error={Boolean(touched.reason && errors.reason)} sx={{ ...theme.typography.customInput }}>
                            <TextField
                              variant="outlined"
                              type="text"
                              fullWidth
                              name="rejectReason"
                              value={values?.rejectReason}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={Boolean(touched.rejectReason && errors.rejectReason)}
                              multiline={2}
                            />
                            {touched.rejectReason && errors.rejectReason && (
                              <FormHelperText
                                error
                                id="standard-weight-helper-text-email-login"
                                sx={{
                                  fontSize: '13.8px'
                                }}
                              >
                                {errors.rejectReason}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <DialogActions>
                    {errors.submit && (
                      <Box sx={{ mt: 3 }}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Box>
                    )}
                    <Box sx={{ m: 1, position: 'relative' }}>
                      <Button
                        fullWidth
                        variant="contained"
                        type="submit"
                        style={{
                          color: '#fff'
                        }}
                        color="primary"
                        disabled={isLoadingRejectReason}
                      >
                        {isLoadingRejectReason ? (
                          <CircularProgress
                            size={24}
                            sx={{
                              color: '#16B1FF',
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              marginTop: '-12px',
                              marginLeft: '-12px'
                            }}
                          />
                        ) : (
                          'Send'
                        )}
                      </Button>
                    </Box>
                    <Button variant="outlined" type="reset" color="error" onClick={handleClose}>
                      Close
                    </Button>
                  </DialogActions>
                </Form>
              </>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RejectTicketModal;
