/* eslint-disable react/prop-types */
/* eslint-disable */
import {
  Autocomplete,
  Button,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography
} from '@mui/material';

import { Box } from '@mui/system';
import { Form, useFormikContext } from 'formik';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { gridSpacing } from 'store/constant';
import { useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import InfoIcon from '@mui/icons-material/Info';

// import { useGetEnterpriseMachineLocations } from 'hooks/useEnterpriseHooks';
import moment from 'moment';
import // useGetAllEnterpriseAfterRecycle,
// useGetAllRetailerAfterRecycle,
// useGetRecycleId
// useGetRecyclerAllList
'hooks/useRecycleHooks';
// import { useGetRetailerAllList } from 'hooks/useRetailerHooks';
// import { useState } from 'react';
// import { useGetRetailerMachineLocations } from 'hooks/useRetailerHooks';
// import styled from '@emotion/styled';
import { useGetLocationAllLists } from 'hooks/useCommonHooks';
import styled from '@emotion/styled';
import { useGetAllMachineSettings } from 'hooks/useSettingsHooks';
import { useGetAllOrganizationList, useGetOrgById } from 'hooks/useOrganizationHooks';
import { MachineTypeConfirmationDialog } from 'ui-component/machine-add-dialog/MachineTypeConfirmationDialog';
import { OrganizationTypeToggleButton } from 'ui-component/machine-add-dialog/OrganizationTypeToggleButton';
import { useState } from 'react';
import { useFocusOnError } from 'hooks/useFocusOnError';

// const CustomChip = styled(Chip)(({ theme }) => ({
//   margin: theme.spacing(0.5),
//   backgroundColor: '#6fd74b',
//   color: '#fff',
//   '& .MuiChip-deleteIcon': {
//     color: '#fff'
//   }
// }));

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  margin: '0 auto',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626'
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959'
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343'
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c'
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff'
  }
}));

const MachineForm = ({
  isSubmitting,
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  touched,
  values,
  isLoadingMachine,
  setFieldValue,
  validateField,
  machineDetails,
  btnName,
  machineType,
  permissionsAdd
  // id
}) => {
  const theme = useTheme();
  // const inputRefs = React.useRef({});

  const { setFieldRef } = useFocusOnError(errors, touched, isSubmitting); // Initialize the custom hook

  // const { data: allLocationAllLists } = useGetLocationAllLists();
  const userData = JSON.parse(localStorage.getItem('userData'));
  // console.log('userData', userData);
  const { data: machineSettingsList, isPending: isMachineSettingsListLoading } = useGetAllMachineSettings({
    isActive: true
  });

  const { data: orgList, isLoading: orgListIsLoading } = useGetAllOrganizationList({ filters: { onlyBuyers: true } });
  const { data: orgListById, isLoading: orgListByIdIsLoading } = useGetOrgById({ id: values?.organization?.id || null });
  // console.log('machineSettingsList : ', machineSettingsList, isMachineSettingsListLoading);
  // console.log('orgList : machine : ', orgListById, values.organization);

  const exceptThisSymbols = ['e', 'E', '+', '-', '#', '%', '^'];

  const [noMachine, setNoMachine] = React.useState(values.no_machine); // Set initial state for the no_machine value
  const [machineSoftware, setMachineSoftware] = React.useState([]);
  const [machineVersionType, setMachineVersionType] = React.useState([]);
  const [perDriveCost, setPerDriveCost] = React.useState('');

  const inputMachineTypeRef = React.useRef(null);

  const handleIncrement = () => {
    setNoMachine((prev) => (prev + 1 >= 2 ? prev + 1 : prev)); // Increase value by 1
    // handleChange();
  };

  const handleDecrement = () => {
    setNoMachine((prev) => (prev - 1 >= 2 ? prev - 1 : prev)); // Decrease value but prevent going below 0
    // setNoMachine((prev) => (prev > 0 ? prev - 1 : 0));
    // handleChange();
  };
  React.useEffect(() => {
    if (noMachine) setFieldValue('no_machine', noMachine);
  }, [noMachine]);

  const [locationsArray, setLocationsArray] = useState([]);
  const [organizationTypesArray, setOrganizationTypesArray] = useState([]);
  const [filteredOrganizationTypes, setFilteredOrganizationTypes] = useState([]);

  useEffect(() => {
    // Check if orgListById and its nested properties are available
    if (orgListById && orgListById.data) {
      // Extract locationsArray
      const locations =
        orgListById.data.locations && orgListById.data.locations.length > 0
          ? orgListById.data.locations.map((location) => ({
              id: location?.details?.id || '',
              name: location?.details?.name || '',
              taxAmount: location?.details?.taxAmount || '',
              isPrimary: location?.isPrimary || ''
            }))
          : [];
      setLocationsArray(locations);

      // Extract organizationTypesArray
      const types = orgListById.data.types && orgListById.data.types.length > 0 ? orgListById.data.types : [];
      setOrganizationTypesArray(types);
    }
  }, [orgListById]); // Dependency on orgListById so it updates when it changes

  // Effect to filter organizationTypesArray
  useEffect(() => {
    if (organizationTypesArray && Array.isArray(organizationTypesArray) && organizationTypesArray.length) {
      const filtered = organizationTypesArray.filter((item) => item === 'Enterprise' || item === 'Retailer' || item === 'Recycler');
      setFilteredOrganizationTypes(filtered); // Update the filtered state
      if (filtered && Array.isArray(filtered) && filtered.length) setFieldValue('organizationType', filtered[0]);
    } else {
      setFilteredOrganizationTypes([]); // If no valid types, set an empty array
    }
  }, [organizationTypesArray]);

  /*const locationsArray =
    orgListById && orgListById?.data && orgListById?.data?.locations && orgListById?.data?.locations?.length > 0
      ? orgListById?.data?.locations?.map((location) => {
          return {
            id: location?.details?.id || '',
            name: location?.details?.name || '',
            taxAmount: location?.details?.taxAmount || '',
            isPrimary: location?.isPrimary || ''
          };
        })
      : [];

  const organizationTypesArray =
    orgListById && orgListById?.data && orgListById?.data?.types && orgListById?.data?.types?.length > 0 ? orgListById?.data?.types : [];*/

  // console.log('locationsArray', locationsArray);

  const sallerDataObj = userData
    ? {
        OrgName: userData?.organization?.name || '',
        namePOC: userData?.prefix + ' ' + userData?.firstName + ' ' + userData?.lastName || '',
        email: userData?.email || '',
        phone: userData?.phone || '',
        id: userData?.organization?.id || ''
      }
    : {};

  const buyerDataObj = {
    OrgName: orgListById && orgListById?.data ? orgListById?.data.name : '',
    namePOC:
      orgListById && orgListById?.data && orgListById?.data?.mainPOC
        ? ' ' + orgListById?.data.mainPOC.prefix + ' ' + orgListById?.data.mainPOC.firstName + ' ' + orgListById?.data.mainPOC.lastName
        : '',
    email: orgListById && orgListById?.data && orgListById?.data?.mainPOC ? ' ' + orgListById?.data?.mainPOC?.email : '',
    phone: orgListById && orgListById?.data && orgListById?.data?.mainPOC ? ' ' + orgListById?.data?.mainPOC?.phone : '',
    id: orgListById && orgListById?.data && orgListById?.data?.id ? ' ' + orgListById?.data?.id : '',
    types: orgListById && orgListById?.data && orgListById?.data?.types ? orgListById?.data?.types?.join(', ') : ''
  };
  const { setFieldTouched, setFieldError } = useFormikContext();
  React.useEffect(() => {
    if (locationsArray && Array.isArray(locationsArray) && locationsArray.length === 1) {
      // console.log('locationsArray', locationsArray);
      //name taxAmount
      setFieldValue('location', locationsArray[0].id);
      setFieldValue('locationObj', locationsArray[0]);
      setFieldValue('location_tax', locationsArray[0].taxAmount);

      // Manually set the location field as "touched" and clear errors
      // setFieldTouched('location', false); // Mark as touched
      // setFieldError('location', ''); // Clear any existing error
    }
  }, [locationsArray]);
  React.useEffect(() => {
    setFieldValue('seller', sallerDataObj);
    // console.log('buyerDataObj', buyerDataObj);
    if (orgListById) setFieldValue('buyer', buyerDataObj);
  }, [orgListById]);

  const recycleDetails = [];

  const handleChangeRecylerLocation = (event) => {
    setFieldValue('recycler_location1ABC', event.target.value);
    // setrecycleLocationId(event.target.value);
  };

  const handleChangeLocation = (event) => {
    const [value, tax] = event.target.value.split('@##@');
    // console.log('event.target.value.split()', event.target.value.split('@##@'));
    setFieldValue('location', value);
    setFieldValue('location_tax', tax);
  };

  const getMachineTypeByType = (machineType) => {
    switch (machineType) {
      case 'E':
        return 'Enterprise';
      case 'R':
        return 'Retailer';
      default:
        return '';
    }
  };

  const handelMachineTypeOnChange = (event) => {
    /*console.log(
      '��� ~ handleChangeMachineType ~ event.target.value:',
      event.target.value,
      getMachineTypeByType(event.target.value),
      orgListById?.data?.types
    );
    console.log('inputMachineTypeRef : ', inputMachineTypeRef);*/
    inputMachineTypeRef.current.value = event.target.value;
    /*if (orgListById && orgListById?.data && orgListById?.data?.types) {
      if (!orgListById?.data?.types?.includes(getMachineTypeByType(event.target.value))) {
        setOpenConfirmationDialog(true);
      } else {
        setFieldValue('type', event.target.value);
      }
    }*/
    checkMachineTypeConfirmationDialog(event.target.value);
    // setFieldValue('type', event.target.value);
  };

  const handelOrganizationChange = (event) => {
    setFieldValue('organization', event.target.value);
    // console.log('inputMachineTypeRef | organization : ', inputMachineTypeRef);
    if (inputMachineTypeRef?.current?.value) {
      // checkMachineTypeConfirmationDialog(inputMachineTypeRef?.current?.value);
      // setFieldValue('type', '');
    }
    setFieldValue('location', '');
    setFieldValue('organizationType', '');
    setFieldValue('type', '');
  };

  const checkMachineTypeConfirmationDialog = (machineType) => {
    /*console.log(
      '��� ~ handleChangeMachineType ~ checkMachineTypeConfirmationDialog :',
      machineType,
      getMachineTypeByType(machineType),
      orgListById?.data?.types
    );*/
    if (machineType) {
      if (orgListById && orgListById?.data && orgListById?.data?.types) {
        if (!orgListById?.data?.types?.includes(getMachineTypeByType(machineType))) {
          setOpenConfirmationDialog((prev) => ({ ...prev, open: true, machineType: getMachineTypeByType(machineType) }));
        } else {
          setFieldValue('type', machineType);
        }
      }
    }
  };

  const [openConfirmationDialog, setOpenConfirmationDialog] = React.useState({ open: false, machineType: '' });

  const handleOpenConfirmationDialog = () => {
    setOpenConfirmationDialog(true);
  };

  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog((prev) => ({ ...prev, open: false }));
  };

  const handleChangeType = () => {
    // console.log('Changing type...');
    setOpenConfirmationDialog(false);
  };

  useEffect(() => {
    if (machineDetails) {
      // console.log('🚀 ~ useEffect ~ machineDetails:', machineDetails);
      setFieldValue('machine_id', machineDetails?.machineId ?? '');
      setFieldValue('machineType', machineDetails?.machineType ?? 'E');
      setFieldValue(
        'installation_date',
        machineDetails?.activationDate && moment(machineDetails?.activationDate).format('Do MMMM YYYY, h:mm a')
      );
      setFieldValue('recycle_id', machineDetails?.recycler?.details?.id ?? '');
      setFieldValue('recycler_location', machineDetails?.recycler?.location?.id ?? '');
      setFieldValue('enterprise_id', machineDetails?.enterprise?.id ?? '');
      setFieldValue('organization_location', machineDetails?.location?.id ?? '');
      setFieldValue('price', machineDetails?.price ?? '');
      setrecycleLocationId(machineDetails?.recycler?.location?.id ?? '');
      setRecycleID(machineDetails?.recycler?.details?.id ?? '');
      setEnterpriseId(machineDetails?.enterprise?.id ?? '');

      setFieldValue('retailer_id', machineDetails?.retailer?.id ?? '');
      setRetailerId(machineDetails?.retailer?.id ?? '');
    }
  }, [setFieldValue, machineDetails]);

  const CustomChip = styled(Chip)(({ theme, isPrimary }) => ({
    margin: theme.spacing(0.5),
    backgroundColor: isPrimary ? 'blue' : '#6fd74b',
    color: '#fff',
    '& .MuiChip-deleteIcon': {
      color: '#fff'
    }
  }));

  React.useEffect(() => {
    if (values.model) {
      const objByModel =
        machineSettingsList && machineSettingsList.length > 0 ? machineSettingsList?.filter((m) => m.model === values.model) : [];
      if (objByModel && objByModel.length > 0) {
        setMachineSoftware(objByModel[0]?.versions || []);

        setMachineVersionType(objByModel[0]?.types || []);
        // setCostPrice(objByModel[0]?.basePrice || '');
        setPerDriveCost(objByModel[0]?.perDriveCost || '');

        // If only one machine version is present, auto select it
        // console.log('objByModel[0]?.versions : ', objByModel[0]?.versions);
        if (objByModel[0]?.versions && Array.isArray(objByModel[0]?.versions) && objByModel[0]?.versions?.length === 1) {
          setFieldValue('software', objByModel[0]?.versions);
        } else {
          setFieldValue('software', []);
        }
        //Extra Point
        if (objByModel[0]?.types && Array.isArray(objByModel[0]?.types) && objByModel[0]?.types?.length === 1) {
          setFieldValue('type', objByModel[0]?.types[0]);
        } else {
          setFieldValue('type', '');
        }

        setFieldValue('base_price', objByModel[0]?.basePrice || '');
        setFieldValue('pre_drive_cost_price', objByModel[0]?.perDriveCost || '');
        setPerDriveCost(objByModel[0]?.perDriveCost || 0);
      }
      // console.log('useEffect ~values.model : ', values.model, objByModel);
    }
  }, [values.model]);

  React.useEffect(() => {
    if (values.type) {
      if (values.type === 'R') {
        setFieldValue('pre_drive_cost_price', 0);
      } else if (values.type === 'E') {
        setFieldValue('pre_drive_cost_price', perDriveCost);
      } else {
        setFieldValue('pre_drive_cost_price', '');
      }
    }
  }, [values.type]);

  // const permissionsAddShow = permissionsAdd ? permissionsAdd?.filter((item) => item.show === true).map((item) => item.id) : [];
  // const permissionsAddShow = permissionsAdd
  //   ? permissionsAdd?.filter((item) => {
  //       if (item.show) {
  //         return item.id;
  //       }
  //     })
  //   : [];

  const transformedPermissionsAdd = permissionsAdd?.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});

  // console.log('permissionsAddShow - transformedPermissionsAdd : ', permissionsAdd, transformedPermissionsAdd);

  return (
    <>
      <>
        <Form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={gridSpacing} sx={{ alignContent: 'center' }}>
            <Grid item xs={12}>
              <Typography component="h4" variant="h2" sx={{ mb: 2 }}>
                Machine Details
              </Typography>
              {/* Organization & Location */}
              <Grid container spacing={gridSpacing}>
                {/* Select Organization */}
                {transformedPermissionsAdd['buyerOrganization']?.show && (
                  <>
                    <Grid item lg={6} md={6} sm={6} xs={12} className="select-job-enterprise select-machine-edit-module">
                      {/* <FormControl
                        fullWidth
                        error={Boolean(touched.organization && errors.organization)}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <InputLabel id="demo-simple-select-label_organization">
                          {transformedPermissionsAdd['buyerOrganization']?.label}{' '}
                          {transformedPermissionsAdd['buyerOrganization']?.isRequired ? ' *' : ''}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label_organization"
                          id="demo-simple-select"
                          value={values?.organization}
                          name="organization"
                          label={transformedPermissionsAdd['buyerOrganization']?.label}
                          // inputRef={(el) => (inputRefs.current['organization'] = el)}
                          inputRef={(el) => setFieldRef('organization', el)}
                          // onChange={handleChange}
                          onChange={handelOrganizationChange}
                        >
                          {!orgListIsLoading &&
                            !!orgList?.data &&
                            orgList?.data?.length > 0 &&
                            orgList?.data?.map((val, index) => (
                              <MenuItem value={val?.id} key={index}>
                                {val?.name}
                              </MenuItem>
                            ))}
                        </Select>

                        {touched.organization && errors.organization && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-first-name"
                            sx={{
                              fontSize: '13.8px'
                            }}
                          >
                            {errors.organization}
                          </FormHelperText>
                        )}
                      </FormControl> */}
                      <FormControl
                        fullWidth
                        error={Boolean(touched.organization && errors.organization)}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <Autocomplete
                          options={orgList?.data || []} // Use the orgList data as options
                          getOptionLabel={(option) => option?.name ?? ''}
                          // isOptionEqualToValue={(option, value) => option.id === value.id}
                          isOptionEqualToValue={(option, value) => {
                            // console.log('organization machine : option ', option, ' | value : ', value, option.id === value);
                            return option.id === value.id;
                          }}
                          value={values?.organization || null} // Ensure the value is set to a single item
                          onChange={(event, newValue) => {
                            handleChange({ target: { name: 'organization', value: newValue ?? null } });
                            setFieldValue('location', '');
                            setFieldValue('locationObj', null);
                            setFieldValue('organizationType', '');
                            // setFieldValue('type', '');
                          }}
                          disabled={orgListIsLoading || transformedPermissionsAdd['buyerOrganization']?.isStatic}
                          loading={orgListIsLoading}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={`Search and ${transformedPermissionsAdd['buyerOrganization']?.label} ${
                                transformedPermissionsAdd['buyerOrganization']?.isRequired ? ' *' : ''
                              }`}
                              variant="outlined"
                              onBlur={handleBlur}
                              error={touched.organization && errors.organization}
                              // inputRef={(el) => (inputRefs.current['organization'] = el)}
                              inputRef={(el) => setFieldRef('organization', el)}
                            />
                          )}
                        />
                        {touched.organization && errors.organization && (
                          <FormHelperText error id="standard-weight-helper-text-organization" sx={{ fontSize: '13.8px' }}>
                            {errors.organization}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </>
                )}

                {/* Select Organization Type */}
                {/* {transformedPermissionsAdd['buyerOrganization']?.show && (
                  <>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.organizationType && errors.organizationType)}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <InputLabel id="demo-simple-select-label_organization_type">Select Buyer Organization Type</InputLabel>
                        <Select
                          labelId="demo-simple-select-label_organization_type"
                          id="demo-simple-select"
                          value={values?.organizationType}
                          name="organizationType"
                          // label={transformedPermissionsAdd['buyerOrganization']?.label}
                          lable={'Select Buyer Organization Type'}
                          onChange={handleChange}
                          // onChange={handelOrganizationChange}
                        >
                          {!!organizationTypesArray &&
                            organizationTypesArray?.length > 0 &&
                            organizationTypesArray?.map((val, index) => (
                              <MenuItem value={`${val}`} key={index}>
                                {val}
                              </MenuItem>
                            ))}
                        </Select>

                        {touched.organizationType && errors.organizationType && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-first-name"
                            sx={{
                              fontSize: '13.8px'
                            }}
                          >
                            {errors.organization}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </>
                )} */}

                {/* Location */}
                {/* permissionsAddShow.includes('buyerOrganization') &&  */}
                {transformedPermissionsAdd['selectLocation']?.show && (
                  <>
                    <Grid item lg={6} md={6} sm={6} xs={12} className="select-job-enterprise select-machine-edit-module">
                      {/* <FormControl fullWidth sx={{ ...theme.typography.customInput }}>
                    <InputLabel htmlFor="outlined-adornment-email-installDate"> Installation Date</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-email-installDate"
                      type="text"
                      value={values?.installation_date}
                      name="activationDate"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      label="Installation Date"
                      inputProps={{}}
                      autoComplete="off"
                      disabled
                    />
                  </FormControl> */}

                      {/* <FormControl
                    fullWidth
                    error={Boolean(touched.locationPickUp && errors.locationPickUp)}
                    sx={{ ...theme.typography.customInput }}
                  >
                    <Autocomplete
                      // {...props}
                      id="autocomplete"
                      multiple
                      limitTags={2}
                      options={allLocationAllLists?.data ?? []}
                      // value={selectedOptions}
                      value={values?.locationPickUp || []}
                      getOptionLabel={(option) => option?.name}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      onChange={(event, value) => {
                        if (value.length === 1) {
                          setFieldValue('locationPickUp', [{ ...value[0], isPrimary: true }]);
                        } else {
                          let newVal = value.filter((item) => item.isPrimary == true);
                          if (newVal.length == 0) {
                            newVal = value.map((item, index) => (index == 0 ? { ...item, isPrimary: true } : item));
                            setFieldValue('locationPickUp', newVal);
                          } else {
                            setFieldValue('locationPickUp', value);
                          }
                        }
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                          return (
                            <CustomChip
                              key={index}
                              label={option?.name}
                              isPrimary={option?.isPrimary}
                              {...getTagProps({ index })}
                              onClick={() => {
                                let data = values?.locationPickUp?.map((location) =>
                                  location?.id === option?.id ? { ...location, isPrimary: true } : { ...location, isPrimary: false }
                                );

                                setFieldValue('locationPickUp', data);
                              }}
                            />
                          );
                        })
                      }
                      // value={values?.locationPickUp}

                      renderInput={(params) => (
                        <TextField
                          className="enterprise-location"
                          id="notes-recycler-textarea"
                          name="locationPickUp"
                          {...params}
                          htmlFor="autocomplete"
                          label="Select Location *"
                          placeholder="e.g. street, city, state, country etc."
                          // variant="outlined"
                          // margin="normal"
                          fullWidth
                          error={Boolean(touched.locationPickUp && errors.locationPickUp)}
                          //value={values?.locationPickUp}
                        />
                      )}
                    />

                    {touched.locationPickUp && errors.locationPickUp && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-email-login"
                        sx={{
                          fontSize: '13.8px'
                        }}
                      >
                        {errors.locationPickUp}
                      </FormHelperText>
                    )}
                  </FormControl> */}
                      {/* <FormControl fullWidth error={Boolean(touched.location && errors.location)} sx={{ ...theme.typography.customInput }}>
                        <InputLabel id="demo-simple-select-label_location">
                          {transformedPermissionsAdd['selectLocation']?.label}{' '}
                          {transformedPermissionsAdd['selectLocation']?.isRequired ? ' *' : ''}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label_location"
                          id="demo-simple-select"
                          value={`${values?.location}@##@${values?.location_tax}`}
                          name="location"
                          inputRef={(el) => setFieldRef('location', el)}
                          label={transformedPermissionsAdd['selectLocation']?.label}
                          // onChange={handleChange}
                          onChange={handleChangeLocation}
                        >
                          {!!locationsArray &&
                            locationsArray?.length > 0 &&
                            locationsArray?.map((val, index) => (
                              <MenuItem value={`${val?.id}@##@${val?.taxAmount}`} key={index}>
                                {val?.name} (Tax : {val?.taxAmount}%)
                              </MenuItem>
                            ))}
                        </Select>

                        {touched.location && errors.location ? (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-first-name"
                            sx={{
                              fontSize: '13.8px'
                            }}
                          >
                            {errors.location}
                          </FormHelperText>
                        ) : null}
                      </FormControl> */}

                      <FormControl fullWidth error={Boolean(touched.location && errors.location)} sx={{ ...theme.typography.customInput }}>
                        <Autocomplete
                          options={locationsArray || []} // The available locations data
                          getOptionLabel={(option) => `${option?.name} (Tax: ${option?.taxAmount}%)`} // Label for the option
                          isOptionEqualToValue={(option, value) => option?.id === value?.id} // Ensuring correct value comparison
                          value={locationsArray?.find((loc) => loc?.id === values?.location) || null} // Find the location by its ID
                          onChange={(event, newValue) => {
                            // Split the value into location and tax and set them separately
                            const location = newValue ? newValue.id : '';
                            const tax = newValue ? newValue.taxAmount : '';
                            setFieldValue('locationObj', newValue || null);
                            setFieldValue('location', location);
                            setFieldValue('location_tax', tax);
                          }}
                          disabled={orgListIsLoading || transformedPermissionsAdd['selectLocation']?.isStatic}
                          loading={orgListIsLoading}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              onBlur={handleBlur}
                              error={touched.location && errors.location}
                              label="Search and Select Location"
                              inputRef={(el) => setFieldRef('location', el)}
                            />
                          )}
                        />

                        {touched.location && errors.location && (
                          <FormHelperText error id="standard-weight-helper-text-location" sx={{ fontSize: '13.8px' }}>
                            {errors.location}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </>
                )}
                {/* Machine Nickname */}
                {transformedPermissionsAdd['nickname']?.show && (
                  <>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <FormControl fullWidth error={Boolean(touched.nickname && errors.nickname)} sx={{ ...theme.typography.customInput }}>
                        <InputLabel htmlFor="outlined-adornment-first-name">
                          {' '}
                          {transformedPermissionsAdd['nickname']?.label} {transformedPermissionsAdd['nickname']?.isRequired ? ' *' : ''}
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-first-name"
                          type="text"
                          value={values.nickname}
                          name="nickname"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label={transformedPermissionsAdd['nickname']?.label}
                          placeholder="e.g. John"
                          inputProps={{}}
                          // inputRef={(el) => (inputRefs.current['nickname'] = el)}
                          inputRef={(el) => setFieldRef('nickname', el)}
                          autoComplete="off"
                        />
                        {touched.nickname && errors.nickname && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-first-name"
                            sx={{
                              fontSize: '13.8px'
                            }}
                          >
                            {errors.nickname}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </>
                )}

                {/* No. of Machine */}
                {transformedPermissionsAdd['noOfMachine']?.show && (
                  <>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.no_machine && errors.no_machine)}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-service-price">
                          {transformedPermissionsAdd['noOfMachine']?.label}{' '}
                          {transformedPermissionsAdd['noOfMachine']?.isRequired ? ' *' : ''}
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-service-price"
                          type="number"
                          value={values.no_machine} // Update the value with the state
                          name="no_machine"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label={transformedPermissionsAdd['noOfMachine']?.label}
                          placeholder="0"
                          autoComplete="off"
                          disabled={machineType.toString().toLowerCase() === 'single' ? true : false}
                          onPaste={(e) => e.preventDefault()}
                          onCopy={(e) => e.preventDefault()}
                          onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                          // inputRef={(el) => (inputRefs.current['no_machine'] = el)}
                          inputRef={(el) => setFieldRef('no_machine', el)}
                          endAdornment={
                            machineType.toString().toLowerCase() === 'batch' ? (
                              <>
                                <IconButton onClick={handleDecrement} edge="end">
                                  <RemoveIcon color="error" />
                                </IconButton>
                                <IconButton onClick={handleIncrement} edge="end">
                                  <AddIcon color="success" />
                                </IconButton>
                              </>
                            ) : null
                          }
                        />
                        {touched.no_machine && errors.no_machine && (
                          <FormHelperText error id="standard-weight-helper-text-email-login" sx={{ fontSize: '13.8px' }}>
                            {errors.no_machine}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </>
                )}
              </Grid>

              {/*Buyer & Seller Details*/}
              {(transformedPermissionsAdd['buyerInfo']?.show || transformedPermissionsAdd['sellerInfo']?.show) && (
                <Grid container spacing={gridSpacing}>
                  <Grid container spacing={2} style={{ marginBottom: '20px' }} className="address_detail_new">
                    <Grid item xs={12} sm={6}>
                      {transformedPermissionsAdd['buyerInfo']?.show && (
                        <>
                          <Box>
                            <div className="view_ticket_addressbx view_ticket_addressbx-new2">
                              {orgListByIdIsLoading ? (
                                <>
                                  <div className="view_ticket_addressbx view_ticket_addressbx2 view_ticket_addressbx222">
                                    <Typography variant="h5" component={'h5'}>
                                      <Skeleton animation="wave" />
                                    </Typography>

                                    <Typography variant="h5" component={'h5'}>
                                      <Skeleton animation="wave" />
                                    </Typography>
                                    <Typography variant="h5" component={'h5'}>
                                      <Skeleton animation="wave" />
                                    </Typography>
                                    <Typography variant="h5" component={'h5'}>
                                      <Skeleton animation="wave" />
                                    </Typography>
                                    <Typography variant="h5" component={'h5'}>
                                      <Skeleton animation="wave" />
                                    </Typography>
                                  </div>
                                </>
                              ) : orgListById ? (
                                <>
                                  <Typography variant="h5" component={'h5'}>
                                    {/* ShredBox Pvt. Ltd. */}
                                    {transformedPermissionsAdd['buyerInfo']?.label} :
                                    <Typography variant="h3">
                                      {/* {userData?.organizationType === 'superadmin' ? userData?.superAdmin?.name : ''} */}
                                      {orgListById && orgListById?.data ? orgListById?.data.name : ''}
                                    </Typography>
                                  </Typography>
                                  <Typography variant="h5" component={'h5'}>
                                    Name:
                                    {/* {userData?.prefix + ' ' + userData.firstName + ' ' + userData.lastName} */}
                                    {orgListById && orgListById?.data && orgListById?.data?.mainPOC
                                      ? ' ' +
                                        orgListById?.data.mainPOC.prefix +
                                        ' ' +
                                        orgListById?.data.mainPOC.firstName +
                                        ' ' +
                                        orgListById?.data.mainPOC.lastName
                                      : ''}
                                  </Typography>
                                  <Typography variant="h5" component={'h5'}>
                                    Email:
                                    {orgListById && orgListById?.data && orgListById?.data?.mainPOC
                                      ? ' ' + orgListById?.data?.mainPOC?.email
                                      : ''}
                                  </Typography>
                                  <Typography variant="h5" component={'h5'}>
                                    Phone:
                                    {orgListById && orgListById?.data && orgListById?.data?.mainPOC
                                      ? ' ' + orgListById?.data?.mainPOC?.phone
                                      : ''}
                                  </Typography>
                                  <Divider sx={{ m: 0 }} />
                                  <Typography variant="h4" component={'div'} sx={{ py: 1, fontWeight: '600' }}>
                                    Select Buyer Organization Type {transformedPermissionsAdd['buyerOrganization']?.isRequired ? '*' : ''} :
                                    {/* {orgListById && orgListById?.data && orgListById?.data?.types
                                      ? ' ' + orgListById?.data?.types?.join(', ')
                                      : ''} */}
                                    <br />
                                  </Typography>
                                  {/* <FormControl fullWidth error={Boolean(errors.organizationType)} sx={{ ...theme.typography.customInput }}> */}
                                  <OrganizationTypeToggleButton
                                    /*organizationTypesArray={
                                      organizationTypesArray && Array.isArray(organizationTypesArray) && organizationTypesArray.length
                                        ? organizationTypesArray?.filter(
                                            (item) => item === 'Enterprise' || item === 'Retailer' || item === 'Recycler'
                                          )
                                        : []
                                    }*/
                                    organizationTypesArray={filteredOrganizationTypes}
                                    setFieldValue={setFieldValue}
                                    values={values}
                                  />
                                  {errors.organizationType && (
                                    <div
                                      style={{
                                        fontSize: '13.8px',
                                        marginLeft: 0,
                                        marginTop: '5px',
                                        paddingTop: '5px',
                                        color: '#f44336',
                                        position: 'absolute'
                                      }}
                                    >
                                      {errors.organizationType}
                                    </div>
                                  )}
                                  {/* </FormControl> */}
                                </>
                              ) : (
                                <>
                                  {/* <Typography variant="h5" component={'h5'}>
                                    Buyer :<Typography variant="h3"></Typography>
                                  </Typography> */}
                                  <StyledGridOverlay>
                                    <svg
                                      style={{ flexShrink: 0 }}
                                      width="240"
                                      height="200"
                                      viewBox="0 0 184 152"
                                      aria-hidden
                                      focusable="false"
                                    >
                                      <g fill="none" fillRule="evenodd">
                                        <g transform="translate(24 31.67)">
                                          <ellipse className="ant-empty-img-5" cx="67.797" cy="106.89" rx="67.797" ry="12.668" />
                                          <path
                                            className="ant-empty-img-1"
                                            d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                                          />
                                          <path
                                            className="ant-empty-img-2"
                                            d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                                          />
                                          <path
                                            className="ant-empty-img-3"
                                            d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                                          />
                                        </g>
                                        <path
                                          className="ant-empty-img-3"
                                          d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                                        />
                                        <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                                          <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                                          <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                                        </g>
                                      </g>
                                    </svg>
                                    <Box sx={{ mt: 1 }}>Please select buyer organization!</Box>
                                  </StyledGridOverlay>
                                </>
                              )}
                            </div>
                          </Box>
                        </>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      {transformedPermissionsAdd['sellerInfo']?.show && (
                        <Box>
                          <div className="view_ticket_addressbx view_ticket_addressbx-new3">
                            <Typography variant="h5" component={'h5'}>
                              {/* ShredBox Pvt. Ltd. */}
                              {transformedPermissionsAdd['sellerInfo']?.label} :
                              <Typography variant="h3">
                                {/* {userData?.organizationType === 'superadmin' ? userData?.superAdmin?.name : ''} */}
                                {userData?.organization?.name}
                              </Typography>
                            </Typography>
                            <Typography variant="h5" component={'h5'}>
                              Name: {userData?.prefix + ' ' + userData.firstName + ' ' + userData.lastName}
                            </Typography>
                            <Typography variant="h5" component={'h5'}>
                              Email: {userData.email}
                            </Typography>
                            <Typography variant="h5" component={'h5'}>
                              Phone: {userData.phone}
                            </Typography>
                          </div>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                  <Divider />
                </Grid>
              )}

              <Divider sx={{ mb: 2 }} />
              <Typography component="div" variant="h2" sx={{ mb: 2 }}>
                Machine Config
              </Typography>

              <Grid container spacing={gridSpacing}>
                {/* Machine Model */}
                {transformedPermissionsAdd['machineModel']?.show && (
                  <>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <FormControl fullWidth error={Boolean(touched.model && errors.model)} sx={{ ...theme.typography.customInput }}>
                        <InputLabel htmlFor="outlined-adornment-email-title" id="retailer-secondary-title">
                          {transformedPermissionsAdd['machineModel']?.label}{' '}
                          {transformedPermissionsAdd['machineModel']?.isRequired ? ' *' : ''}
                        </InputLabel>
                        <Select
                          label={transformedPermissionsAdd['machineModel']?.label}
                          value={values.model}
                          // onChange={handleChangeRecylerLocation}
                          onChange={handleChange}
                          name="model"
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                          labelId="outlined-adornment-states"
                          id="outlined-adornment-states"
                          // inputRef={(el) => (inputRefs.current['model'] = el)}
                          inputRef={(el) => setFieldRef('model', el)}
                          // disabled={!isLoadingRecycleDetails}
                        >
                          {/* {isLoadingRecycleDetails && <MenuItem value={''}>Loading..</MenuItem>} */}

                          {!!machineSettingsList &&
                            machineSettingsList?.length > 0 &&
                            machineSettingsList?.map((val, index) => (
                              <MenuItem value={val?.model} key={index}>
                                {val?.model}
                              </MenuItem>
                            ))}
                        </Select>

                        {touched.model && errors.model && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-email-title"
                            sx={{
                              fontSize: '13.8px'
                            }}
                          >
                            {errors.model}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </>
                )}
                {/* Software Version  */}
                {/* permissionsAddShow.includes('machineModel') && */}
                {transformedPermissionsAdd['machineSoftware']?.show && (
                  <>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <FormControl fullWidth error={Boolean(touched.software && errors.software)} sx={{ ...theme.typography.customInput }}>
                        <Autocomplete
                          id="autocomplete"
                          multiple
                          options={machineSoftware ?? []}
                          value={values?.software || []}
                          getOptionLabel={(option) => option} // Since machineSoftware is just an array of strings
                          // isOptionEqualToValue={(option, value) => option === value} // Compare strings directly
                          onChange={(event, value) => {
                            // Simply set the selected software list as the field value
                            setFieldValue('software', value);
                          }}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => {
                              return (
                                <CustomChip
                                  key={index}
                                  label={option} // Display the software name
                                  {...getTagProps({ index })}
                                  onClick={() => {
                                    // When a chip is clicked, the software name is toggled in the selected list
                                    const newValue = value.includes(option)
                                      ? value.filter((item) => item !== option) // Remove from selection
                                      : [...value, option]; // Add to selection
                                    setFieldValue('software', newValue);
                                  }}
                                />
                              );
                            })
                          }
                          renderInput={(params) => (
                            <TextField
                              className="enterprise-location"
                              id="notes-recycler-textarea"
                              name="software"
                              {...params}
                              label={`${transformedPermissionsAdd['machineSoftware']?.label} ${
                                transformedPermissionsAdd['machineSoftware']?.isRequired ? ' *' : ''
                              }`}
                              placeholder={`${transformedPermissionsAdd['machineSoftware']?.label} ${
                                transformedPermissionsAdd['machineSoftware']?.isRequired ? ' *' : ''
                              }`}
                              fullWidth
                              error={Boolean(touched.software && errors.software)}
                              // inputRef={(el) => (inputRefs.current['software'] = el)}
                              inputRef={(el) => setFieldRef('software', el)}
                            />
                          )}
                        />

                        {touched.software && errors.software && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-email-login"
                            sx={{
                              fontSize: '13.8px'
                            }}
                          >
                            {errors.software}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </>
                )}
                {/* Machine Type */}
                {/* permissionsAddShow.includes('machineModel') && */}
                {transformedPermissionsAdd['machineType']?.show && (
                  <>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <FormControl fullWidth error={Boolean(touched.type && errors.type)} sx={{ ...theme.typography.customInput }}>
                        <InputLabel htmlFor="outlined-adornment-email-title" id="retailer-secondary-title">
                          {transformedPermissionsAdd['machineType']?.label}{' '}
                          {transformedPermissionsAdd['machineType']?.isRequired ? ' *' : ''}
                        </InputLabel>
                        <Select
                          label={transformedPermissionsAdd['machineType']?.label}
                          value={values.type}
                          // onChange={handleChangeRecylerLocation}
                          onChange={handleChange}
                          // onChange={handelMachineTypeOnChange}
                          name="type"
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                          labelId="outlined-adornment-states"
                          id="outlined-adornment-states"
                          ref={inputMachineTypeRef}
                          // inputRef={(el) => (inputRefs.current['type'] = el)}
                          inputRef={(el) => setFieldRef('type', el)}
                          // disabled={!isLoadingRecycleDetails}
                        >
                          {/* {isLoadingRecycleDetails && <MenuItem value={''}>Loading..</MenuItem>} */}

                          {!!machineVersionType &&
                            machineVersionType?.length > 0 &&
                            machineVersionType?.map((val, index) => (
                              <MenuItem value={val} key={index}>
                                {val}
                              </MenuItem>
                            ))}
                        </Select>

                        {touched.type && errors.type && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-email-title"
                            sx={{
                              fontSize: '13.8px'
                            }}
                          >
                            {errors.type}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </>
                )}

                {/* Bins  */}
                {transformedPermissionsAdd['bins']?.show && (
                  <>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <FormControl fullWidth error={Boolean(touched.bins && errors.bins)} sx={{ ...theme.typography.customInput }}>
                        <InputLabel htmlFor="outlined-adornment-service-price">
                          {' '}
                          {transformedPermissionsAdd['bins']?.label} {transformedPermissionsAdd['bins']?.isRequired ? ' *' : ''}
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-service-price"
                          type="number"
                          value={values?.bins}
                          name="bins"
                          onBlur={handleBlur}
                          // onChange={handleChangePrice}
                          onChange={handleChange}
                          label={transformedPermissionsAdd['bins']?.label}
                          placeholder="0.00"
                          autoComplete="off"
                          // inputRef={(el) => (inputRefs.current['bins'] = el)}
                          inputRef={(el) => setFieldRef('bins', el)}
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          onCopy={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          onKeyDown={(e) => {
                            exceptThisSymbols.includes(e.key) && e.preventDefault();
                          }}
                        />
                        {touched.bins && errors.bins && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-email-login"
                            sx={{
                              fontSize: '13.8px'
                            }}
                          >
                            {errors.bins}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </>
                )}
              </Grid>

              {/* Choose Machine Model , Softwares , Type */}

              {/* Base Price & Per disk cost price */}
              <Grid container spacing={gridSpacing}>
                {/* Base Price */}
                {/* permissionsAddShow.includes('machineModel') && */}
                {transformedPermissionsAdd['basePrice']?.show && (
                  <>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.base_price && errors.base_price)}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-service-price">
                          {' '}
                          {transformedPermissionsAdd['basePrice']?.label} {transformedPermissionsAdd['basePrice']?.isRequired ? ' *' : ''}
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-service-price"
                          type="number"
                          value={values?.base_price}
                          name="base_price"
                          // inputRef={(el) => (inputRefs.current['base_price'] = el)}
                          inputRef={(el) => setFieldRef('base_price', el)}
                          onBlur={handleBlur}
                          // onChange={handleChangePrice}
                          onChange={handleChange}
                          label={transformedPermissionsAdd['basePrice']?.label}
                          placeholder="0.00"
                          autoComplete="off"
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          onCopy={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          onKeyDown={(e) => {
                            exceptThisSymbols.includes(e.key) && e.preventDefault();
                          }}
                          endAdornment={
                            <Tooltip title="An invoice will be generated based on the machine(s) base price.">
                              <IconButton edge="end">
                                <InfoIcon color="info" />
                              </IconButton>
                            </Tooltip>
                          }
                        />
                        {touched.base_price && errors.base_price && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-email-login"
                            sx={{
                              fontSize: '13.8px'
                            }}
                          >
                            {errors.base_price}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </>
                )}
                {/* Per Disk Cost Price */}
                {/* permissionsAddShow.includes('machineModel') && */}
                {transformedPermissionsAdd['perDriveCost']?.show && (
                  <>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <FormControl
                        fullWidth
                        error={Boolean(touched.pre_drive_cost_price && errors.pre_drive_cost_price)}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-service-price">
                          {' '}
                          {transformedPermissionsAdd['perDriveCost']?.label}{' '}
                          {transformedPermissionsAdd['perDriveCost']?.isRequired ? ' *' : ''}
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-service-price"
                          type="number"
                          value={values?.pre_drive_cost_price}
                          name="pre_drive_cost_price"
                          // inputRef={(el) => (inputRefs.current['pre_drive_cost_price'] = el)}
                          inputRef={(el) => setFieldRef('pre_drive_cost_price', el)}
                          onBlur={handleBlur}
                          // onChange={handleChangePrice}
                          onChange={handleChange}
                          label={transformedPermissionsAdd['perDriveCost']?.label}
                          placeholder="0.00"
                          autoComplete="off"
                          onPaste={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          onCopy={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                          onKeyDown={(e) => {
                            exceptThisSymbols.includes(e.key) && e.preventDefault();
                          }}
                        />
                        {touched.pre_drive_cost_price && errors.pre_drive_cost_price && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-email-login"
                            sx={{
                              fontSize: '13.8px'
                            }}
                          >
                            {errors.pre_drive_cost_price}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">
                <span style={{ color: 'red' }}>*</span> An invoice will be generated based on the machine(s) base price.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {errors.submit && (
                <Box sx={{ mt: 3 }}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
              <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                <Box sx={{ display: 'inline-block' }}>
                  <AnimateButton>
                    <Button
                      disableElevation
                      disabled={isLoadingMachine}
                      size="large"
                      type="submit"
                      variant="contained"
                      color="secondary"
                      style={{
                        color: 'white',
                        minWidth: '200px',
                        margin: '0px auto'
                      }}
                    >
                      {isLoadingMachine ? (
                        <CircularProgress
                          sx={{
                            color: 'white',
                            height: 20,
                            width: 20
                          }}
                        />
                      ) : (
                        `${btnName} Machine`
                      )}
                    </Button>
                  </AnimateButton>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Form>

        {/* {openConfirmationDialog && (
          <MachineTypeConfirmationDialog
            open={openConfirmationDialog}
            onClose={handleCloseConfirmationDialog}
            onChangeType={handleChangeType}
          />
        )} */}
      </>
      {/* </Formik> */}
      {/* </MainCard> */}
    </>
  );
};

export default MachineForm;
