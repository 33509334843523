import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, FieldArray } from 'formik';
import {
  Grid,
  Button,
  Divider,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  CardHeader,
  CardContent,
  Paper,
  CircularProgress
} from '@mui/material';
import * as Yup from 'yup';
import { useTheme } from '@emotion/react';
import { useSaveScreenData } from 'hooks/useControlPanelHooks';

function ModifyPermission({ screen, addScreenList, editScreenList, filtersScreenList, modalScreenList, isLoadingScreenList }) {
  const theme = useTheme();
  const { mutate, isLoading: isSaving } = useSaveScreenData();

  const initialValues = {
    add: addScreenList || [],
    edit: editScreenList || [],
    filters: filtersScreenList || [],
    modal: modalScreenList || []
  };

  const validationSchema = Yup.object({
    add: Yup.array()
      .of(
        Yup.object({
          id: Yup.string()
            .required('ID is required')
            .test('non-empty', 'ID cannot be empty', (value) => !!value?.trim()), // Ensure non-empty strings
          label: Yup.string()
            .required('Label is required')
            .test('non-empty', 'Label cannot be empty', (value) => !!value?.trim()) // Ensure non-empty strings
        }).test(
          'complete-object',
          'Both ID and Label are required if an object exists',
          (value) => !!value?.id?.trim() && !!value?.label?.trim() // Ensure both fields are valid
        )
      )
      .nullable(),
    edit: Yup.array()
      .of(
        Yup.object({
          id: Yup.string()
            .required('ID is required')
            .test('non-empty', 'ID cannot be empty', (value) => !!value?.trim()),
          label: Yup.string()
            .required('Label is required')
            .test('non-empty', 'Label cannot be empty', (value) => !!value?.trim())
        }).test(
          'complete-object',
          'Both ID and Label are required if an object exists',
          (value) => !!value?.id?.trim() && !!value?.label?.trim()
        )
      )
      .nullable(),
    filters: Yup.array()
      .of(
        Yup.object({
          id: Yup.string()
            .required('ID is required')
            .test('non-empty', 'ID cannot be empty', (value) => !!value?.trim()),
          label: Yup.string()
            .required('Label is required')
            .test('non-empty', 'Label cannot be empty', (value) => !!value?.trim())
        }).test(
          'complete-object',
          'Both ID and Label are required if an object exists',
          (value) => !!value?.id?.trim() && !!value?.label?.trim()
        )
      )
      .nullable(),
    modal: Yup.array()
      .of(
        Yup.object({
          id: Yup.string()
            .required('ID is required')
            .test('non-empty', 'ID cannot be empty', (value) => !!value?.trim()),
          label: Yup.string()
            .required('Label is required')
            .test('non-empty', 'Label cannot be empty', (value) => !!value?.trim())
        }).test(
          'complete-object',
          'Both ID and Label are required if an object exists',
          (value) => !!value?.id?.trim() && !!value?.label?.trim()
        )
      )
      .nullable()
  });

  const handleSubmit = (values) => {
    const _data = {
      screen: screen,
      ...values
    };
    mutate(_data);
  };

  const renderSection = (name, values, arrayHelpers, touched, errors) => (
    <Paper variant="outlined" sx={{ mb: 2 }}>
      <CardHeader title={`${name.charAt(0).toUpperCase() + name.slice(1)} Options`} />
      <Divider />
      <CardContent
        sx={{
          p: { xs: 1, sm: 2 }
        }}
      >
        <Grid container spacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 4 }}>
          {values[name].map((item, index) => (
            <Grid container item spacing={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 4 }} key={index} alignItems="center">
              <Grid item xs={12} sm={5} md={5}>
                <FormControl
                  fullWidth
                  sx={{ ...theme.typography.customInput }}
                  error={Boolean(touched[name]?.[index]?.id && errors[name]?.[index]?.id)}
                >
                  <InputLabel htmlFor={`${name}-${index}-id`}>ID *</InputLabel>
                  <OutlinedInput
                    id={`${name}-${index}-id`}
                    value={item.id}
                    onChange={(e) => {
                      const updatedItem = { ...item, id: e.target.value };
                      arrayHelpers.replace(index, updatedItem);
                    }}
                  />
                  {touched[name]?.[index]?.id && errors[name]?.[index]?.id && <FormHelperText>{errors[name]?.[index]?.id}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={5} md={5}>
                <FormControl
                  fullWidth
                  sx={{ ...theme.typography.customInput }}
                  error={Boolean(touched[name]?.[index]?.label && errors[name]?.[index]?.label)}
                >
                  <InputLabel htmlFor={`${name}-${index}-label`}>Label *</InputLabel>
                  <OutlinedInput
                    id={`${name}-${index}-label`}
                    value={item.label}
                    onChange={(e) => {
                      const updatedItem = { ...item, label: e.target.value };
                      arrayHelpers.replace(index, updatedItem);
                    }}
                  />
                  {touched[name]?.[index]?.label && errors[name]?.[index]?.label && (
                    <FormHelperText>{errors[name]?.[index]?.label}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <Button fullWidth variant="outlined" color="error" onClick={() => arrayHelpers.remove(index)} disabled={index === 0}>
                  Remove
                </Button>
              </Grid>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Button variant="outlined" onClick={() => arrayHelpers.push({ id: '', label: '' })}>
              Add More
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Paper>
  );

  if (isLoadingScreenList) return <CircularProgress />;

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ values, touched, errors }) => (
        <Form>
          {['add', 'edit', 'filters', 'modal'].map((section) => (
            <FieldArray
              key={section}
              name={section}
              render={(arrayHelpers) => renderSection(section, values, arrayHelpers, touched, errors)}
            />
          ))}
          <div style={{ textAlign: 'center', marginTop: '1rem' }}>
            <Button type="submit" variant="contained" color="primary" disabled={isSaving} sx={{ color: '#fff' }}>
              {isSaving ? 'Saving...' : 'Calibrate'}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

ModifyPermission.propTypes = {
  screen: PropTypes.string,
  addScreenList: PropTypes.array,
  editScreenList: PropTypes.array,
  filtersScreenList: PropTypes.array,
  modalScreenList: PropTypes.array,
  isLoadingScreenList: PropTypes.bool
};

export default ModifyPermission;
