/* eslint-disable */
import * as React from 'react';
// import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import OnlinePredictionOutlinedIcon from '@mui/icons-material/OnlinePredictionOutlined';
import FilterListOffOutlinedIcon from '@mui/icons-material/FilterListOffOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import DialogTitle from '@mui/material/DialogTitle';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SyncIcon from '@mui/icons-material/Sync';

import {
  Box,
  Button,
  Chip,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  Skeleton,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { gridSpacing } from 'store/constant';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import {
  useGetMachineById,
  useGetMachineSubscription,
  useGetMachineUrlPdf,
  useMachineBinThresold,
  useMachineStatus
} from 'hooks/useMachineHooks';
import styled from '@emotion/styled';

import { useSelector } from 'react-redux';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import BuildIcon from '@mui/icons-material/Build';
// import PersonAddIcon from '@mui/icons-material/PersonAdd'; // Or TransferWithinAStationIcon for client change
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import { addSpacesToCamelCase, CopyIconButton, machineStatusColors, maskData, setToast, toastConfig } from 'utils/commonUtil';
import { useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import MachineViewModalSkeleton from 'ui-component/cards/Skeleton/MachineViewModalSkeleton';
import AddIcon from '@mui/icons-material/Add';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import DeleteDialog from 'ui-component/delete-dialog';
import { useEffect } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
export default function MachineModalDialog({
  open,
  setOpen,
  openLeaseMachineModal,
  setOpenLeaseMachineModal,
  urlSingle,
  urlAddBatch,
  toggleBoughtRentedAssigned,
  details,
  callFrom
}) {
  const sectionRef = React.useRef(null);
  // console.log('🚀 ~ details:', details);
  const location = useLocation();
  const [openModal, setOpenModal] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = useState({ isSync: false, activeSubscription: {} });
  const queryParams = new URLSearchParams(location?.search);
  const viewMachineID = queryParams?.get('viewMachineID');
  const viewSub = queryParams?.get('viewSub');
  // console.log('viewSub', viewSub);

  details = {
    ...details,
    id: open.machineId ? open.machineId : viewMachineID
  };
  const { data: machineDetailsArr, isLoading: isLoadingMachine } = useGetMachineById({
    id: open.machineId
  });
  let syncData = { active: false, id: open.machineId };
  const {
    data: machineSyncSubscription,
    isLoading: isLoadingMachineSyncSubscription,
    refetch: refetchMachineSyncSubscription,
    isRefetching: isRefetchingMachineSyncSubscription
  } = useGetMachineSubscription(syncData);

  const handelSyncSubscription = () => {
    syncData = { ...syncData, active: true };
    setSubscriptionPlan((prev) => ({ ...prev, isSync: true }));
    refetchMachineSyncSubscription();
  };

  const handleDownload = () => {
    if (!jobUrl) return; // Prevent download if URL is not available
    setOpenModal(true); // Open the modal
  };
  const handleConfirmDownload = () => {
    // console.log('tytyuttu');
    setIsDownloading(true); // Show loader on "Yes"
    setTimeout(() => {
      window.open(jobUrl, '_blank', 'noopener,noreferrer'); // Open in new tab
      setOpenModal(false); // Close modal
      setIsDownloading(false); // Reset loader after action
    }, 1500); // Simulating a delay for better UX
  };
  const machineDetails = machineDetailsArr?.machine;
  // console.log('🚀 ~ machineDetails :', machineDetails);
  const machinePermission = machineDetailsArr?.permission;
  // console.log('🚀 ~ machinePermission:', machinePermission);
  // const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState('');
  // console.log('🚀 ~ value:', value);
  const {
    data: jobUrl,
    isLoading: isLoadingPdfUrl,
    isFetching: isFetchingPDF
  } = useGetMachineUrlPdf({
    id: open.machineId,
    apikey: machineDetails?.apiKey
  });
  // Update state when API data loads
  useEffect(() => {
    if (machineDetails) {
      // setValue(machineDetails.binThreshold ?? 'N.A.');
      setValue(machineDetails.binThreshold ?? 'N.A.');
    }
    if (machineDetails?.activeSubscription && !subscriptionPlan?.isSync) {
      setSubscriptionPlan((prev) => ({ ...prev, isSync: false, activeSubscription: machineDetails?.activeSubscription || {} }));
    }
  }, [machineDetails]);
  useEffect(() => {
    if (subscriptionPlan?.isSync) {
      setSubscriptionPlan((prev) => ({ ...prev, isSync: true, activeSubscription: machineSyncSubscription?.activeSubscription || {} }));
    }
  }, [isRefetchingMachineSyncSubscription, machineSyncSubscription]);
  // console.log('subscriptionPlan', subscriptionPlan, ' | machineSyncSubscription : ', machineSyncSubscription);
  // const handleChange = (event) => {
  //   setValue(event.target.value);
  // };

  // const handleToggleEdit = () => {
  //   setIsEditing((prev) => !prev);
  // };

  const [viewApi, setViewApi] = useState(false);

  const orgType = useSelector((state) => state?.customization?.orgType);

  const { mutate: updatedStatusMachine, isPending: isLoadingUpdateMachineStatus } = useMachineStatus();
  const [onlineStatus, setOnlineStatus] = useState(false);
  const [offlineStatus, setOfflineStatus] = useState(false);
  const [maintainStatus, setMaintainStatus] = useState(false);
  const { mutate: updateBinThreshold, isPending: isLoadingBinThreshold } = useMachineBinThresold();
  const handleClose = () => {
    setOpen((state) => ({ ...state, open: !state.open }));
  };
  const navigate = useNavigate();
  const theme = useTheme();
  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.h4,
    // textAlign: 'center',
    // color: theme.palette.text.secondary,
    // height: 60,
    // lineHeight: '60px',
    padding: '10px'
  }));

  const stepsLoading = ['Select master blaster campaign settings', 'Create an ad group', 'Create an ad'];

  const RecyclerToggleBoughtRentedAssignedCondition = orgType === 'Recycler' && toggleBoughtRentedAssigned === 'Assigned';
  // console.log(
  //   'toggleBoughtRentedAssigned | View Modal : ',
  //   toggleBoughtRentedAssigned,
  //   orgType,
  //   orgType === 'Recycler' && toggleBoughtRentedAssigned === 'Assigned',
  //   RecyclerToggleBoughtRentedAssignedCondition
  // );

  const statusColors = machineStatusColors;
  const [openThresold, setOpenThresold] = useState(false);
  const handleCloseThresold = () => {
    setOpenThresold(false);
  };
  // Border Green Color Code  : #6fd74b

  useEffect(() => {
    // console.log('sectionRef - useEffect', sectionRef, sectionRef.current);
    if (viewMachineID && Boolean(viewSub)) {
      setTimeout(() => {
        if (sectionRef.current) {
          sectionRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start' // 'center' or 'end' can be used as well
          });
        }
      }, 10); // Adjust the delay if necessary
    }
  }, [viewSub]);

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'xl'} // Set this to "xs" to make the dialog smaller
        onClose={handleClose}
        open={open.open}
      >
        <DialogTitle
          id="alert-dialog-title"
          variant="span"
          scroll={'papper'}
          style={{
            textTransform: 'capitalize',
            fontSize: '20px' // Adjust the font size for the title
          }}
        >
          <Grid
            container
            spacing={0}
            sx={{
              marginTop: '0px',
              padding: {
                xs: '10px 0 0 0', // xs and sm
                sm: '10px 0 0 0' // sm
              }
            }}
          >
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8} sx={{ wordWrap: 'break-word' }}>
              {isLoadingMachine ? (
                <Skeleton variant="rectangular" width={500} height={35} />
              ) : (
                `View Machine Details - ${machineDetails?.machineName}`
              )}
              {'  '}
              {machineDetails?.status && (
                <Chip
                  label={machineDetails?.status}
                  sx={{
                    borderRadius: '4px', // Customize the border radius here
                    backgroundColor: statusColors[machineDetails?.status],
                    color: '#fff',
                    fontWeight: '500'
                    // textTransform: 'capitalize'
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              {machineDetails && machineDetails?.apiKey !== null && machinePermission?.showMachineManual && (
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  spacing={2}
                  sx={{
                    marginTop: '0px',
                    padding: {
                      xs: '10px 28px 0 0', // xs and sm
                      sm: '10px 28px 0 0', // sm
                      md: '0 28px 0 0', // md, lg, xl
                      lg: '0 28px 0 0', // lg
                      xl: '0 28px 0 0' // xl
                    }
                  }}
                >
                  <Button
                    sx={{
                      // zIndex: 11,
                      // position: 'absolute',
                      // right: 50,
                      // top: 10,
                      color: '#fff'
                    }}
                    disableElevation
                    size="large"
                    type="button"
                    variant="contained"
                    color="primary"
                    // className="machineViewBtn"
                    onClick={handleConfirmDownload}
                    disabled={isLoadingPdfUrl || isFetchingPDF || isDownloading}
                    // style={{ backgroundColor: 'rgb(229 59 59)' }}
                  >
                    {isLoadingPdfUrl || isFetchingPDF || isDownloading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <SimCardDownloadIcon
                        sx={{
                          color: '#fff'
                        }}
                      />
                    )}

                    {isLoadingPdfUrl || isFetchingPDF || isDownloading ? `  Generating Manual` : ` Machine Manual`}
                  </Button>
                </Stack>
              )}
            </Grid>
          </Grid>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500]
          })}
        >
          <CloseIcon color="error" />
        </IconButton>
        <Divider />
        <DialogContent
          dividers
          className="choose_option_modal"
          style={{
            padding: '8px 16px', // Reduce padding to make the dialog more compact
            boxShadow: 'unset',
            border: 'unset'
          }}
        >
          {/* style={{ display: 'flex', alignItems: 'center' }} */}
          {/* style={{ fontWeight: 'bold' }} */}
          {isLoadingMachine ? (
            <>
              <MachineViewModalSkeleton />
            </>
          ) : (
            <>
              <Grid container spacing={gridSpacing} style={{ paddingTop: '15px' }}>
                <Grid item xs={12} sm={12} md={6} lg={4} className="machineModalView">
                  <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', height: '100%' }}>
                    <div className="address_locationmain" style={{ background: '#6fd74b', mb: 2, padding: '10px 15px' }}>
                      <Typography
                        component="h4"
                        variant="h3"
                        // sx={{ mr: 1, pb: 1.8 }}
                        sx={{ color: '#fff' }}
                      >
                        {/* {machineDetails?.buyer?.id === machineDetails?.client?.id ? 'Buyer & Client Organization' : 'Buyer Organization'} */}
                        {(machineDetails?.buyer?.id?.trim() || '') === (machineDetails?.client?.id?.trim() || '')
                          ? 'Buyer & Client Organization'
                          : 'Buyer Organization'}
                      </Typography>
                    </div>
                    {machineDetails?.buyer !== null ? (
                      <>
                        <div className="organization_machine_name">
                          <Typography variant="h4" component="p" style={{ fontWeight: 'bold' }}>
                            {/* Organization Name{' '} */}
                            {machineDetails?.buyer?.name || 'N.A.'}
                          </Typography>
                          <Typography variant="h4" component="p" style={{ fontWeight: 'unset', paddingTop: 10, paddingBottom: '10px' }}>
                            {machineDetails?.buyer?.website}
                          </Typography>
                          <Divider />
                          <Typography variant="h4" component="p" className="point_cntct_hd">
                            Point Of Contact{' '}
                          </Typography>
                          {/* <Typography variant="h4" component="p" style={{ fontWeight: 'unset', paddingTop: 10 }}>
                        {machineDetails?.buyer?.name}
                      </Typography> */}
                          <Grid container spacing={2} style={{ paddingTop: '10px', paddingBottom: '20px' }}>
                            <Grid item xs={12} sx={{ paddingBottom: '5px' }}>
                              <div style={{ border: '1px solid #fff', borderRadius: '6px' }}>
                                <div className="" style={{ display: 'inherit' }}>
                                  <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                                    Email :{' '}
                                  </Typography>
                                  <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                                    {machineDetails?.buyer?.mainPOC?.email ? (
                                      <Link
                                        href={`mailto:${machineDetails?.buyer?.mainPOC?.email}`}
                                        sx={{ textDecoration: 'underline', color: 'blue', wordBreak: 'break-word' }}
                                        // style={{ textDecoration: 'underline', textDecorationColor: 'blue', color: 'inherit' }}
                                      >
                                        {machineDetails?.buyer?.mainPOC?.email}
                                      </Link>
                                    ) : (
                                      'N.A.'
                                    )}
                                  </Typography>
                                </div>
                              </div>
                            </Grid>

                            <Grid item xs={12} sx={{ padding: 0 }}>
                              <div style={{ border: '1px solid #fff', borderRadius: '6px' }}>
                                <div className="" style={{ display: 'inherit' }}>
                                  <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                                    Phone :{' '}
                                  </Typography>
                                  <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                                    {machineDetails?.buyer?.mainPOC?.phone || 'N.A.'}
                                  </Typography>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                          <Divider />
                          {/* <Typography variant="h4" component="p" style={{ fontWeight: 'bold', paddingTop: 15 }}>
                            Organization Types{' '}
                          </Typography> */}
                          <Grid container spacing={2} style={{ paddingTop: '10px', paddingBottom: '20px' }}>
                            <Grid item xs={12} sx={{ padding: 0 }}>
                              <div style={{ border: '1px solid #fff', borderRadius: '6px' }}>
                                <div className="" style={{ display: 'inherit', paddingTop: '15px' }}>
                                  <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                                    Organization Type :{' '}
                                  </Typography>
                                  <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                                    {machineDetails?.buyerOrgType
                                      ? Array.isArray(machineDetails?.buyerOrgType) && machineDetails?.buyerOrgType?.length
                                        ? machineDetails?.buyerOrgType.join(', ')
                                        : addSpacesToCamelCase(machineDetails?.buyerOrgType)
                                      : 'N.A.'}
                                  </Typography>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </>
                    ) : (
                      <>
                        <div style={{ padding: '10px 15px 15px' }}>
                          <Typography variant="h4" component="p" style={{ fontWeight: 'bold' }}>
                            Not Assigned
                          </Typography>
                        </div>
                      </>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} className="machineModalView">
                  <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', height: '100%' }}>
                    <div className="address_locationmain" style={{ background: '#6fd74b', mb: 2, padding: '10px 15px' }}>
                      <Typography
                        component="h4"
                        variant="h3"
                        // sx={{ mr: 1, pb: 1.8 }}
                        sx={{ color: '#fff' }}
                      >
                        Seller Organization
                      </Typography>
                    </div>
                    {machineDetails?.seller !== null ? (
                      <>
                        <div className="organization_machine_name">
                          <Typography variant="h4" component="p" style={{ fontWeight: 'bold' }}>
                            {machineDetails?.seller?.name || 'N.A.'}
                          </Typography>
                          <Typography variant="h4" component="p" style={{ fontWeight: 'unset', paddingTop: 10, paddingBottom: '10px' }}>
                            {machineDetails?.seller?.website}
                          </Typography>
                          <Divider />
                          <Typography variant="h4" component="p" className="point_cntct_hd">
                            Point Of Contact{' '}
                          </Typography>
                          {/* <Typography variant="h4" component="p" style={{ fontWeight: 'unset', paddingTop: 10 }}>
                        {machineDetails?.seller?.name}
                      </Typography> */}
                          <Grid container spacing={2} style={{ paddingTop: '10px', paddingBottom: '20px' }}>
                            <Grid item xs={12} sx={{ paddingBottom: '5px' }}>
                              <div style={{ border: '1px solid #fff', borderRadius: '6px' }}>
                                <div style={{ display: 'inherit' }}>
                                  <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                                    Email :{' '}
                                  </Typography>
                                  <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                                    {machineDetails?.seller?.mainPOC?.email ? (
                                      <Link
                                        href={`mailto:${machineDetails?.seller?.mainPOC?.email}`}
                                        sx={{
                                          textDecoration: 'underline',
                                          color: 'blue',
                                          wordBreak: 'break-word'
                                        }}
                                        // style={{ textDecoration: 'underline', textDecorationColor: 'blue', color: 'inherit' }}
                                      >
                                        {machineDetails?.seller?.mainPOC?.email}
                                      </Link>
                                    ) : (
                                      'N.A.'
                                    )}
                                  </Typography>
                                </div>
                              </div>
                            </Grid>

                            <Grid item xs={12} sx={{ padding: 0 }}>
                              <div style={{ border: '1px solid #fff', borderRadius: '6px' }}>
                                <div className="" style={{ display: 'inherit' }}>
                                  <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                                    Phone :{' '}
                                  </Typography>
                                  <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                                    {machineDetails?.seller?.mainPOC?.phone || 'N.A.'}
                                  </Typography>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                          <Divider />
                          {/* <Typography variant="h4" component="p" style={{ fontWeight: 'bold', paddingTop: 15 }}>
                            Organization Types{' '}
                          </Typography> */}
                          <Grid container spacing={2} style={{ paddingTop: '10px', paddingBottom: '20px' }}>
                            <Grid item xs={12} sx={{ padding: 0 }}>
                              <div style={{ border: '1px solid #fff', borderRadius: '6px' }}>
                                <div className="" style={{ display: 'inherit', paddingTop: '15px' }}>
                                  <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                                    Organization Type :{' '}
                                  </Typography>
                                  <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                                    {machineDetails?.sellerOrgType
                                      ? Array.isArray(machineDetails?.sellerOrgType) && machineDetails?.sellerOrgType?.length
                                        ? machineDetails?.sellerOrgType.join(', ')
                                        : addSpacesToCamelCase(machineDetails?.sellerOrgType)
                                      : 'N.A.'}
                                  </Typography>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </>
                    ) : (
                      <>
                        <div style={{ padding: '10px 15px 15px' }}>
                          <Typography variant="h4" component="p" style={{ fontWeight: 'bold' }}>
                            Not Assigned
                          </Typography>
                        </div>
                      </>
                    )}
                  </div>
                </Grid>

                {/* Client Organization Card  */}
                {!RecyclerToggleBoughtRentedAssignedCondition && (
                  <>
                    {(machineDetails?.buyer?.id?.trim() || '') !== (machineDetails?.client?.id?.trim() || '') && (
                      <>
                        {/* <Grid item xs={12} sm={12} md={6} lg={4} className="machineModalView">
                        <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', height: '100%' }}>
                          <div className="address_locationmain" style={{ background: '#6fd74b', mb: 2, padding: '10px 15px' }}>
                            <Typography
                              component="h4"
                              variant="h3"
                              // sx={{ mr: 1, pb: 1.8 }}
                              sx={{ color: '#fff' }}
                            >
                              Client Organization
                            </Typography>
                          </div>
                          {machineDetails?.client !== null ? (
                            <>
                              <div className="organization_machine_name">
                                <Typography variant="h4" component="p" style={{ fontWeight: 'bold' }}>
                                  {machineDetails?.client?.name || 'N.A.'}
                                </Typography>
                                <Typography
                                  variant="h4"
                                  component="p"
                                  style={{ fontWeight: 'unset', paddingTop: 10, paddingBottom: '10px' }}
                                >
                                  {machineDetails?.client?.website}
                                </Typography>
                                <Divider />
                                <Typography variant="h4" component="p" className="point_cntct_hd">
                                  Point Of Contact{' '}
                                </Typography>
                                <Grid container spacing={2} style={{ paddingTop: '10px', paddingBottom: '20px' }}>
                                  <Grid item xs={12} sx={{ padding: 0 }}>
                                    <div style={{ border: '1px solid #fff', borderRadius: '6px' }}>
                                      <div className="address_locationmain" style={{ margin: '2px', padding: '5px', display: 'inherit' }}>
                                        <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                                          Email :{' '}
                                        </Typography>
                                        <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                                          {machineDetails?.client?.mainPOC?.email ? (
                                            <Link
                                              href={`mailto:${machineDetails?.client?.mainPOC?.email}`}
                                              sx={{ textDecoration: 'underline', color: 'blue' }}
                                              // style={{ textDecoration: 'underline', textDecorationColor: 'blue', color: 'inherit' }}
                                            >
                                              {machineDetails?.client?.mainPOC?.email}
                                            </Link>
                                          ) : (
                                            'N.A.'
                                          )}
                                        </Typography>
                                      </div>
                                    </div>
                                  </Grid>

                                  <Grid item xs={12} sx={{ padding: 0 }}>
                                    <div style={{ border: '1px solid #fff', borderRadius: '6px' }}>
                                      <div className="address_locationmain" style={{ margin: '2px', padding: '5px', display: 'inherit' }}>
                                        <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                                          Phone :{' '}
                                        </Typography>
                                        <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                                          {machineDetails?.client?.mainPOC?.phone || 'N.A.'}
                                        </Typography>
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </>
                          ) : (
                            <>
                              <div style={{ padding: '10px 15px 15px' }}>
                                <Typography variant="h4" component="p" style={{ fontWeight: 'bold' }}>
                                  Not Assigned
                                </Typography>
                              </div>
                            </>
                          )}
                        </div>
                      </Grid> */}

                        <Grid item xs={12} sm={12} md={6} lg={4} className="machineModalView">
                          <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', height: '100%' }}>
                            <div className="address_locationmain" style={{ background: '#6fd74b', mb: 2, padding: '10px 15px' }}>
                              <Typography
                                component="h4"
                                variant="h3"
                                // sx={{ mr: 1, pb: 1.8 }}
                                sx={{ color: '#fff' }}
                              >
                                Client Organization
                              </Typography>
                            </div>
                            {machineDetails?.client !== null ? (
                              <>
                                <div className="organization_machine_name">
                                  <Typography variant="h4" component="p" style={{ fontWeight: 'bold' }}>
                                    {machineDetails?.client?.name || 'N.A.'}
                                  </Typography>
                                  <Typography
                                    variant="h4"
                                    component="p"
                                    style={{ fontWeight: 'unset', paddingTop: 10, paddingBottom: '10px' }}
                                  >
                                    {machineDetails?.client?.website}
                                  </Typography>
                                  <Divider />
                                  <Typography variant="h4" component="p" className="point_cntct_hd">
                                    Point Of Contact{' '}
                                  </Typography>
                                  {/* <Typography variant="h4" component="p" style={{ fontWeight: 'unset', paddingTop: 10 }}>
                            {machineDetails?.client?.name}
                          </Typography> */}
                                  <Grid container spacing={2} style={{ paddingTop: '10px', paddingBottom: '20px' }}>
                                    <Grid item xs={12} sx={{ paddingBottom: '5px' }}>
                                      <div style={{ border: '1px solid #fff', borderRadius: '6px' }}>
                                        <div style={{ display: 'inherit' }}>
                                          <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                                            Email :{' '}
                                          </Typography>
                                          <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                                            {machineDetails?.client?.mainPOC?.email ? (
                                              <Link
                                                href={`mailto:${machineDetails?.client?.mainPOC?.email}`}
                                                sx={{ textDecoration: 'underline', color: 'blue', wordBreak: 'break-word' }}
                                                // style={{ textDecoration: 'underline', textDecorationColor: 'blue', color: 'inherit' }}
                                              >
                                                {machineDetails?.client?.mainPOC?.email}
                                              </Link>
                                            ) : (
                                              'N.A.'
                                            )}
                                          </Typography>
                                        </div>
                                      </div>
                                    </Grid>

                                    <Grid item xs={12} sx={{ padding: 0 }}>
                                      <div style={{ border: '1px solid #fff', borderRadius: '6px' }}>
                                        <div className="" style={{ display: 'inherit' }}>
                                          <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                                            Phone :{' '}
                                          </Typography>
                                          <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                                            {machineDetails?.client?.mainPOC?.phone || 'N.A.'}
                                          </Typography>
                                        </div>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </>
                            ) : (
                              <>
                                <div style={{ padding: '10px 15px 15px' }}>
                                  <Typography variant="h4" component="p" style={{ fontWeight: 'bold' }}>
                                    Not Assigned
                                  </Typography>
                                </div>
                              </>
                            )}
                          </div>
                        </Grid>
                      </>
                    )}

                    {/* Recycler Organization Card */}
                    <Grid item xs={12} sm={12} md={6} lg={4} className="machineModalView">
                      <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', height: '100%' }}>
                        <div className="address_locationmain" style={{ background: '#6fd74b', mb: 2, padding: '10px 15px' }}>
                          <Typography
                            component="h4"
                            variant="h3"
                            // sx={{ mr: 1, pb: 1.8 }}
                            sx={{ color: '#fff' }}
                          >
                            Recycler Organization
                          </Typography>
                        </div>
                        {machineDetails?.recycler !== null ? (
                          <>
                            <div className="organization_machine_name">
                              <Typography variant="h4" component="p" style={{ fontWeight: 'bold' }}>
                                {machineDetails?.recycler?.name || 'N.A.'}
                              </Typography>
                              <Typography variant="h4" component="p" style={{ fontWeight: 'unset', paddingTop: 10, paddingBottom: '10px' }}>
                                {machineDetails?.recycler?.website}
                              </Typography>
                              <Divider />
                              <Typography variant="h4" component="p" className="point_cntct_hd">
                                Point Of Contact{' '}
                              </Typography>
                              {/* <Typography variant="h4" component="p" style={{ fontWeight: 'unset', paddingTop: 10 }}>
                            {machineDetails?.recycler?.name}
                          </Typography> */}
                              <Grid container spacing={2} style={{ paddingTop: '10px', paddingBottom: '20px' }}>
                                <Grid item xs={12} sx={{ paddingBottom: '5px' }}>
                                  <div style={{ border: '1px solid #fff', borderRadius: '6px' }}>
                                    <div style={{ display: 'inherit' }}>
                                      <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                                        Email :{' '}
                                      </Typography>
                                      <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                                        {machineDetails?.recycler?.mainPOC?.email ? (
                                          <Link
                                            href={`mailto:${machineDetails?.recycler?.mainPOC?.email}`}
                                            sx={{ textDecoration: 'underline', color: 'blue', wordBreak: 'break-word' }}
                                            // style={{ textDecoration: 'underline', textDecorationColor: 'blue', color: 'inherit' }}
                                          >
                                            {machineDetails?.recycler?.mainPOC?.email}
                                          </Link>
                                        ) : (
                                          'N.A.'
                                        )}
                                      </Typography>
                                    </div>
                                  </div>
                                </Grid>

                                <Grid item xs={12} sx={{ padding: 0 }}>
                                  <div style={{ border: '1px solid #fff', borderRadius: '6px' }}>
                                    <div className="" style={{ display: 'inherit' }}>
                                      <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                                        Phone :{' '}
                                      </Typography>
                                      <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                                        {machineDetails?.recycler?.mainPOC?.phone || 'N.A.'}
                                      </Typography>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </>
                        ) : (
                          <>
                            <div style={{ padding: '10px 15px 15px' }}>
                              <Typography variant="h4" component="p" style={{ fontWeight: 'bold' }}>
                                Not Assigned
                              </Typography>
                            </div>
                          </>
                        )}
                      </div>
                    </Grid>

                    {/* Service Provider Organization Card  */}
                    {/* <Grid item xs={12} sm={12} md={6} lg={4}>
                      <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', height: '100%' }}>
                        <div className="address_locationmain" style={{ background: '#6fd74b', mb: 2, padding: '10px 15px' }}>
                          <Typography
                            component="h4"
                            variant="h3"
                            // sx={{ mr: 1, pb: 1.8 }}
                            sx={{ color: '#fff' }}
                          >
                            Service Provider Organization
                          </Typography>
                        </div>
                        {machineDetails?.serviceProvider !== null ? (
                          <>
                            <div style={{ padding: '10px 15px 15px' }}>
                              <Typography variant="h4" component="p" style={{ fontWeight: 'bold' }}>
                                {machineDetails?.serviceProvider?.name || 'N.A.'}
                              </Typography>
                              <Typography variant="h4" component="p" style={{ fontWeight: 'unset', paddingTop: 10, paddingBottom: '10px' }}>
                                {machineDetails?.serviceProvider?.website}
                              </Typography>
                              <Divider />
                              <Typography variant="h4" component="p" style={{ fontWeight: 'bold', paddingTop: 15 }}>
                                Point Of Contact{' '}
                              </Typography>
                              <Grid container spacing={2} style={{ paddingTop: '10px', paddingBottom: '20px' }}>
                                <Grid item xs={12} sx={{ padding: 0 }}>
                                  <div style={{ border: '1px solid #fff', borderRadius: '6px' }}>
                                    <div className="address_locationmain" style={{ margin: '2px', padding: '5px', display: 'inherit' }}>
                                      <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                                        Email :{' '}
                                      </Typography>
                                      <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                                        {machineDetails?.serviceProvider?.mainPOC?.email ? (
                                          <Link
                                            href={`mailto:${machineDetails?.serviceProvider?.mainPOC?.email}`}
                                            sx={{ textDecoration: 'underline', color: 'blue' }}
                                            // style={{ textDecoration: 'underline', textDecorationColor: 'blue', color: 'inherit' }}
                                          >
                                            {machineDetails?.serviceProvider?.mainPOC?.email}
                                          </Link>
                                        ) : (
                                          'N.A.'
                                        )}
                                      </Typography>
                                    </div>
                                  </div>
                                </Grid>

                                <Grid item xs={12} sx={{ padding: 0 }}>
                                  <div style={{ border: '1px solid #fff', borderRadius: '6px' }}>
                                    <div className="address_locationmain" style={{ margin: '2px', padding: '5px', display: 'inherit' }}>
                                      <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                                        Phone :{' '}
                                      </Typography>
                                      <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                                        {machineDetails?.serviceProvider?.mainPOC?.phone || 'N.A.'}
                                      </Typography>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </>
                        ) : (
                          <>
                            <div style={{ padding: '10px 15px 15px' }}>
                              <Typography variant="h4" component="p" style={{ fontWeight: 'bold' }}>
                                Not Assigned
                              </Typography>
                            </div>
                          </>
                        )}
                      </div>
                    </Grid> */}

                    <Grid item xs={12} sm={12} md={6} lg={4} className="machineModalView">
                      <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', height: '100%' }}>
                        <div className="address_locationmain" style={{ background: '#6fd74b', mb: 2, padding: '10px 15px' }}>
                          <Typography
                            component="h4"
                            variant="h3"
                            // sx={{ mr: 1, pb: 1.8 }}
                            sx={{ color: '#fff' }}
                          >
                            Service Provider Organization
                          </Typography>
                        </div>
                        {machineDetails?.serviceProvider !== null ? (
                          <>
                            <div className="organization_machine_name">
                              <Typography variant="h4" component="p" style={{ fontWeight: 'bold' }}>
                                {machineDetails?.serviceProvider?.name || 'N.A.'}
                              </Typography>
                              <Typography variant="h4" component="p" style={{ fontWeight: 'unset', paddingTop: 10, paddingBottom: '10px' }}>
                                {machineDetails?.serviceProvider?.website}
                              </Typography>
                              <Divider />
                              <Typography variant="h4" component="p" className="point_cntct_hd">
                                Point Of Contact{' '}
                              </Typography>
                              {/* <Typography variant="h4" component="p" style={{ fontWeight: 'unset', paddingTop: 10 }}>
                            {machineDetails?.serviceProvider?.name}
                          </Typography> */}
                              <Grid container spacing={2} style={{ paddingTop: '10px', paddingBottom: '20px' }}>
                                <Grid item xs={12} sx={{ paddingBottom: '5px' }}>
                                  <div style={{ border: '1px solid #fff', borderRadius: '6px' }}>
                                    <div style={{ display: 'inherit' }}>
                                      <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                                        Email :{' '}
                                      </Typography>
                                      <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                                        {machineDetails?.serviceProvider?.mainPOC?.email ? (
                                          <Link
                                            href={`mailto:${machineDetails?.serviceProvider?.mainPOC?.email}`}
                                            sx={{ textDecoration: 'underline', color: 'blue', wordBreak: 'break-word' }}
                                            // style={{ textDecoration: 'underline', textDecorationColor: 'blue', color: 'inherit' }}
                                          >
                                            {machineDetails?.serviceProvider?.mainPOC?.email}
                                          </Link>
                                        ) : (
                                          'N.A.'
                                        )}
                                      </Typography>
                                    </div>
                                  </div>
                                </Grid>

                                <Grid item xs={12} sx={{ padding: 0 }}>
                                  <div style={{ border: '1px solid #fff', borderRadius: '6px' }}>
                                    <div className="" style={{ display: 'inherit' }}>
                                      <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                                        Phone :{' '}
                                      </Typography>
                                      <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                                        {machineDetails?.serviceProvider?.mainPOC?.phone || 'N.A.'}
                                      </Typography>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </>
                        ) : (
                          <>
                            <div style={{ padding: '10px 15px 15px' }}>
                              <Typography variant="h4" component="p" style={{ fontWeight: 'bold' }}>
                                Not Assigned
                              </Typography>
                            </div>
                          </>
                        )}
                      </div>
                    </Grid>
                  </>
                )}

                {/* Rental Organization - Off */}

                {/* {machineDetails &&
                  loginUser &&
                  orgType &&
                  machineDetails?.buyerOrgType === 'Recycler' &&
                  machineDetails?.client?.id === loginUser?.organization?.id &&
                  orgType === 'Enterprise' && (
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', height: '100%' }}>
                        <div className="address_locationmain" style={{ background: '#6fd74b', mb: 2, padding: '10px 15px' }}>
                          <Typography
                            component="h4"
                            variant="h3"
                            // sx={{ mr: 1, pb: 1.8 }}
                            sx={{ color: '#fff' }}
                          >
                            Rental Organization
                          </Typography>
                        </div>
                        {machineDetails?.buyer !== null ? (
                          <>
                            <div style={{ padding: '10px 15px 15px' }}>
                              <Typography variant="h4" component="p" style={{ fontWeight: 'bold' }}>
                                {machineDetails?.buyer?.name || 'N.A.'}
                              </Typography>
                              <Typography variant="h4" component="p" style={{ fontWeight: 'unset', paddingTop: 10, paddingBottom: '10px' }}>
                                {machineDetails?.buyer?.website}
                              </Typography>
                              <Divider />
                              <Typography variant="h4" component="p" style={{ fontWeight: 'bold', paddingTop: 15 }}>
                                Point Of Contact{' '}
                              </Typography>
                              <Grid container spacing={2} style={{ paddingTop: '10px', paddingBottom: '20px' }}>
                                <Grid item xs={12} sx={{ padding: 0 }}>
                                  <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}>
                                    <div className="address_locationmain" style={{ margin: '2px', padding: '5px', display: 'inherit' }}>
                                      <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                                        Email :{' '}
                                      </Typography>
                                      <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                                        {machineDetails?.buyer?.mainPOC?.email ? (
                                          <Link
                                            href={`mailto:${machineDetails?.buyer?.mainPOC?.email}`}
                                            sx={{ textDecoration: 'underline', color: 'blue' }}
                                            // style={{ textDecoration: 'underline', textDecorationColor: 'blue', color: 'inherit' }}
                                          >
                                            {machineDetails?.buyer?.mainPOC?.email}
                                          </Link>
                                        ) : (
                                          'N.A.'
                                        )}
                                      </Typography>
                                    </div>
                                  </div>
                                </Grid>

                                <Grid item xs={12} sx={{ padding: 0 }}>
                                  <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}>
                                    <div className="address_locationmain" style={{ margin: '2px', padding: '5px', display: 'inherit' }}>
                                      <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                                        Phone :{' '}
                                      </Typography>
                                      <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                                        {machineDetails?.buyer?.mainPOC?.phone || 'N.A.'}
                                      </Typography>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </>
                        ) : (
                          <>
                            <div style={{ padding: '10px 15px 15px' }}>
                              <Typography variant="h4" component="p" style={{ fontWeight: 'bold' }}>
                                Not Assigned
                              </Typography>
                            </div>
                          </>
                        )}
                      </div>
                    </Grid>
                  )} */}
              </Grid>
              {/* Machine Bin Threshold */}
              {machinePermission?.showBinThresholdButton && (
                <Grid container spacing={gridSpacing} style={{ paddingTop: '30px' }}>
                  <Grid item xs={12}>
                    <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}>
                      <div className="address_locationmain" style={{ background: '#6fd74b', mb: 2, padding: '10px 15px' }}>
                        <Typography component="h4" variant="h3" sx={{ color: '#fff' }}>
                          Machine Bin Threshold
                        </Typography>
                      </div>

                      <Grid item xs={12} sm={12} md={12} lg={12} sx={{ padding: 0 }}>
                        <div
                          style={{
                            border: '1px solid #fff',
                            marginLeft: 15,
                            marginRight: 15,
                            padding: 10
                          }}
                        >
                          <div
                            className="address_locationmain address_locationmain_new2"
                            style={{
                              padding: 5,
                              display: 'flex',
                              alignItems: 'center',
                              gap: 10 // Adds space between items
                            }}
                          >
                            <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                              Notify Recycler on :
                            </Typography>
                            <TextField
                              value={value}
                              disabled={machineDetails?.binThreshold ?? 'N.A.'}
                              size="small"
                              InputProps={{ endAdornment: <span>%</span> }}
                              // sx={{ width: 80 }} // Adjust width as needed
                            />
                            <Typography variant="h5" component="span" sx={{ fontWeight: 'unset' }}>
                              Bin Full
                            </Typography>
                          </div>

                          <DialogActions>
                            <Box sx={{ m: 1, position: 'relative', justifyContent: 'flex-end', display: 'inline-block' }}>
                              <Button
                                disableElevation
                                variant="contained"
                                sx={{ color: '#fff' }}
                                onClick={() => {
                                  setOpenThresold(true);
                                }}
                              >
                                Update
                              </Button>
                            </Box>
                          </DialogActions>
                        </div>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              )}

              {/* Machine Subscription */}
              <Grid ref={sectionRef} container spacing={gridSpacing} style={{ paddingTop: '30px' }}>
                {subscriptionPlan?.activeSubscription?.plan ? (
                  <Grid item xs={12} className="machineModalView">
                    <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}>
                      {/* Container for header and Sync button */}
                      {/* <div style={{ position: 'relative' }}> */}
                      <div
                        className="address_locationmain"
                        style={{ background: '#6fd74b', marginBottom: 2, position: 'relative', padding: '10px 18px' }}
                      >
                        <Typography component="h4" variant="h3" sx={{ color: '#fff' }}>
                          Machine Subscription
                        </Typography>
                        {/* </div> */}

                        {/* Sync Button positioned at top-right */}
                        <Tooltip title="Sync Subscription">
                          <IconButton
                            onClick={() => handelSyncSubscription()}
                            // disabled={
                            //   values?.hasGlobalAccess ||
                            //   machineListIsLoading ||
                            //   isRefetchingAssignMachine ||
                            //   (isAdd ? false : transformedPermissionsAddEdit['empAllocateMachine']?.isStatic)
                            // }
                            style={{
                              position: 'absolute',
                              // top: '6.9px',
                              right: '15px',
                              backgroundColor: '#fff',
                              color: '#6fd74b',
                              padding: '1px'
                            }}
                            color="primary"
                          >
                            {isRefetchingMachineSyncSubscription ? <CircularProgress sx={{ padding: '3px' }} size={24} /> : <SyncIcon />}
                          </IconButton>
                        </Tooltip>
                      </div>
                      <Grid container spacing={gridSpacing} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                        <Grid item xs={12} sm={6} md={4} lg={4} sx={{ paddingTop: 0 }}>
                          <div style={{ border: '1px solid #fff', borderRadius: '6px', marginLeft: 15, marginRight: 15 }}>
                            <div className="address_locationmain" style={{ margin: 5, padding: '5px', display: 'inherit' }}>
                              <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                                Plan Name :{' '}
                              </Typography>
                              <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 10 }}>
                                {subscriptionPlan?.activeSubscription?.plan?.name || 'N.A.'}
                              </Typography>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} sx={{ padding: 0 }}>
                          <div style={{ border: '1px solid #fff', borderRadius: '6px', marginLeft: 15, marginRight: 15 }}>
                            <div className="address_locationmain" style={{ margin: 5, padding: '5px', display: 'inherit' }}>
                              <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                                Plan Price :{' '}
                              </Typography>
                              <Typography variant="h4" component="sapn" style={{ fontWeight: 'unset', paddingTop: 10 }}>
                                ({subscriptionPlan?.activeSubscription?.plan?.regularBillingCycle?.price?.currencyCode})&nbsp;
                                {subscriptionPlan?.activeSubscription?.plan?.regularBillingCycle?.price?.value || ''} /{' '}
                                {subscriptionPlan?.activeSubscription?.plan?.regularBillingCycle?.intervalCount &&
                                subscriptionPlan?.activeSubscription?.plan?.regularBillingCycle?.intervalCount <= 1
                                  ? ''
                                  : subscriptionPlan?.activeSubscription?.plan?.regularBillingCycle?.intervalCount}
                                &nbsp;
                                {subscriptionPlan?.activeSubscription?.plan?.regularBillingCycle?.intervalUnit || ''}
                              </Typography>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} sx={{ padding: 0 }}>
                          <div style={{ border: '1px solid #fff', borderRadius: '6px', marginLeft: 15, marginRight: 15 }}>
                            <div className="address_locationmain" style={{ margin: 5, padding: '5px', display: 'inherit' }}>
                              <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                                Trial Period :{' '}
                              </Typography>
                              <Typography variant="h4" component="sapn" style={{ fontWeight: 'unset', paddingTop: 10 }}>
                                {subscriptionPlan?.activeSubscription?.plan?.trialPeriod?.price?.value <= 0 ? (
                                  <>
                                    {subscriptionPlan?.activeSubscription?.plan?.trialPeriod?.intervalCount &&
                                    subscriptionPlan?.activeSubscription?.plan?.trialPeriod?.intervalCount <= 0
                                      ? ''
                                      : subscriptionPlan?.activeSubscription?.plan?.trialPeriod?.intervalCount}
                                    &nbsp;
                                    {subscriptionPlan?.activeSubscription?.plan?.trialPeriod?.intervalUnit || ''} - FREE
                                  </>
                                ) : (
                                  <>
                                    {subscriptionPlan?.activeSubscription?.plan?.trialPeriod?.intervalCount &&
                                    subscriptionPlan?.activeSubscription?.plan?.trialPeriod?.intervalCount <= 0
                                      ? ''
                                      : subscriptionPlan?.activeSubscription?.plan?.trialPeriod?.intervalCount}
                                    &nbsp;
                                    {subscriptionPlan?.activeSubscription?.plan?.trialPeriod?.intervalUnit || ''} - (
                                    {subscriptionPlan?.activeSubscription?.plan?.trialPeriod?.price?.currencyCode})&nbsp;
                                    {subscriptionPlan?.activeSubscription?.plan?.trialPeriod?.price?.value || ''}
                                  </>
                                )}
                              </Typography>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} sx={{ padding: 0 }}>
                          <div style={{ border: '1px solid #fff', borderRadius: '6px', marginLeft: 15, marginRight: 15 }}>
                            <div className="address_locationmain" style={{ margin: 5, padding: '5px', display: 'inherit' }}>
                              <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                                Global User Count :{' '}
                              </Typography>
                              <Typography variant="h4" component="sapn" style={{ fontWeight: 'unset', paddingTop: 10 }}>
                                {subscriptionPlan?.activeSubscription?.plan?.globalUserCount || 'N.A.'}
                              </Typography>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} sx={{ padding: 0 }}>
                          <div style={{ border: '1px solid #fff', borderRadius: '6px', marginLeft: 15, marginRight: 15 }}>
                            <div className="address_locationmain" style={{ margin: 5, padding: '5px', display: 'inherit' }}>
                              <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                                Machine Specific User Count :{' '}
                              </Typography>
                              <Typography variant="h4" component="sapn" style={{ fontWeight: 'unset', paddingTop: 10 }}>
                                {subscriptionPlan?.activeSubscription?.plan?.localUserCount || 'N.A.'}
                              </Typography>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} sx={{ padding: 0 }}>
                          <div style={{ border: '1px solid #fff', borderRadius: '6px', marginLeft: 15, marginRight: 15 }}>
                            <div className="address_locationmain" style={{ margin: 5, padding: '5px', display: 'inherit' }}>
                              <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                                Advertisement Count :{' '}
                              </Typography>
                              <Typography variant="h4" component="sapn" style={{ fontWeight: 'unset', paddingTop: 10 }}>
                                {subscriptionPlan?.activeSubscription?.plan?.adCount || 'N.A.'}
                              </Typography>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} sx={{ padding: 0 }}>
                          <div style={{ border: '1px solid #fff', borderRadius: '6px', marginLeft: 15, marginRight: 15 }}>
                            <div className="address_locationmain" style={{ margin: 5, padding: '5px', display: 'inherit' }}>
                              <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                                Subscription Tax :{' '}
                              </Typography>
                              <Typography variant="h4" component="sapn" style={{ fontWeight: 'unset', paddingTop: 10 }}>
                                {subscriptionPlan?.activeSubscription?.plan?.tax + '%' || 'N.A.'}
                              </Typography>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} sx={{ padding: 0 }}>
                          <div style={{ border: '1px solid #fff', borderRadius: '6px', marginLeft: 15, marginRight: 15 }}>
                            <div className="address_locationmain" style={{ margin: 5, padding: '5px', display: 'inherit' }}>
                              <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                                Subscription Status :{' '}
                              </Typography>
                              <Typography variant="h4" component="sapn" style={{ fontWeight: 'normal', paddingTop: 10 }}>
                                {/* {machineDetails?.activeSubscription?.subscription?.status &&
                                machineDetails?.activeSubscription?.subscription?.status === 'ACTIVE' ? (
                                  <span style={{ color: '#6fd74b' }}>ACTIVE</span>
                                ) : (
                                  machineDetails?.activeSubscription?.subscription?.status || 'N.A.'
                                )} */}
                                {subscriptionPlan?.activeSubscription?.subscription?.status && (
                                  <Chip
                                    // label={machineDetails?.activeSubscription?.subscription?.status}
                                    label={
                                      subscriptionPlan?.activeSubscription?.subscription?.status === 'Pending'
                                        ? 'Pending'
                                        : subscriptionPlan?.activeSubscription?.subscription?.status === 'ACTIVE'
                                        ? 'ACTIVE'
                                        : subscriptionPlan?.activeSubscription?.subscription?.status === 'APPROVAL_PENDING'
                                        ? 'APPROVAL PENDING'
                                        : 'CANCELLED'
                                    }
                                    // color={subStatusColor}
                                    sx={{
                                      // backgroundColor: subStatusColor,
                                      color: '#fff',
                                      backgroundColor:
                                        subscriptionPlan?.activeSubscription?.subscription?.status === 'Pending'
                                          ? '#ae30c2'
                                          : subscriptionPlan?.activeSubscription?.subscription?.status === 'ACTIVE'
                                          ? '#30c42b'
                                          : subscriptionPlan?.activeSubscription?.subscription?.status === 'APPROVAL_PENDING'
                                          ? '#dbc115'
                                          : '#d84315'
                                    }}
                                    size="small"
                                  />
                                )}
                              </Typography>
                            </div>
                          </div>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} className="subs_machine_view">
                          <div
                            style={{
                              border: '1px solid #6fd74b',
                              borderRadius: '6px',
                              marginLeft: 15,
                              marginRight: 15,
                              marginTop: '20px',
                              marginBottom: '10px',
                              minHeight: '200px'
                            }}
                          >
                            <div style={{ background: '#6fd74b', color: '#fff', padding: '10px', fontSize: '16px' }}>
                              Subscription Plan Description
                            </div>
                            <div style={{ padding: '10px' }}>
                              {/* <Typography variant="body2" component={'h6'} sx={{ margin: 0, fontWeight: 'bold' }}>
                              Subscription Features :{' '}
                            </Typography> */}
                              <Typography variant="body2" sx={{ marginTop: '4px' }}>
                                {subscriptionPlan?.activeSubscription?.plan?.description || 'N.A.'}
                              </Typography>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <div
                            className="subs_featre_bxnew"
                            style={{
                              border: '1px solid #6fd74b',
                              borderRadius: '6px',
                              marginLeft: 15,
                              marginRight: 15,
                              marginTop: '20px',
                              marginBottom: '10px',
                              minHeight: '200px'
                            }}
                          >
                            <div style={{ background: '#6fd74b', color: '#fff', padding: '10px', fontSize: '16px' }}>
                              Subscription Plan Features
                            </div>
                            <div style={{ padding: '10px' }}>
                              {/* <Typography variant="body2" component={'h6'} sx={{ margin: 0, fontWeight: 'bold' }}>
                              Subscription Features :{' '}
                            </Typography> */}
                              <Typography variant="body2" sx={{ marginTop: '4px' }}>
                                <ul>
                                  {subscriptionPlan?.activeSubscription?.plan?.features &&
                                    Array.isArray(subscriptionPlan?.activeSubscription?.plan?.features) &&
                                    subscriptionPlan?.activeSubscription?.plan?.features?.map((f, index) => {
                                      return <li key={index}>{f}</li>;
                                    })}
                                </ul>
                              </Typography>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                      {/* Action Buttons */}
                      <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ marginTop: '0px', padding: '0 15px 16px 15px' }}>
                        {/* <Button disableElevation variant="contained" sx={{ color: '#fff' }} startIcon={<AddIcon />}>
                          Add Subscription
                        </Button> */}
                        {subscriptionPlan?.activeSubscription?.subscription?.status === 'APPROVAL_PENDING' ? (
                          <>
                            <Button
                              disableElevation
                              variant="contained"
                              sx={{
                                color: '#fff',
                                backgroundColor: '#FF9800',
                                '&:hover': {
                                  backgroundColor: '#FFB74D' // Adjust this color as needed for hover effect
                                }
                              }}
                              startIcon={<OpenInNewIcon />}
                              onClick={() => {
                                window.open(subscriptionPlan?.activeSubscription?.subscription?.paymentLink, '_blank', 'noopener');
                              }}
                            >
                              Payment Link
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              disableElevation
                              variant="contained"
                              sx={{ color: '#fff' }}
                              startIcon={<AutorenewIcon />}
                              onClick={() => {
                                navigate('/dashboard/machines/subcription-view', {
                                  state: {
                                    machineId: open.machineId,
                                    type: 'change',
                                    subId: subscriptionPlan?.activeSubscription?.plan?.id
                                  }
                                });
                              }}
                            >
                              Change Subscription
                            </Button>
                          </>
                        )}

                        {/* <Button
                        disableElevation
                        className="machineViewBtn1"
                        variant="outlined"
                        sx={{ width: 'unset', flexGrow: 'unset' }}
                        color="error"
                      >
                        Cancel Subscription
                      </Button> */}
                      </Stack>
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}>
                      <div className="address_locationmain" style={{ background: '#6fd74b', mb: 2, padding: '10px 15px' }}>
                        <Typography component="h4" variant="h3" sx={{ color: '#fff' }}>
                          Machine Subscription
                        </Typography>
                      </div>
                      <Stack direction="column" alignItems="center" spacing={2} sx={{ p: 3 }}>
                        <Typography variant="h5">You currently have no subscription. Click the button below to add one.</Typography>
                        <Button
                          disableElevation
                          variant="contained"
                          sx={{ color: '#fff' }}
                          startIcon={<AddIcon />}
                          onClick={() => {
                            if (!callFrom) {
                              navigate('/dashboard/machines/subcription-view', {
                                state: { machineId: open.machineId, type: 'add', subId: null }
                              });
                            } else if (callFrom && callFrom === 'Employee') {
                              const machineSubFromEmployee = {
                                passedObject: { machineId: open.machineId, type: 'add', subId: null },
                                callFrom: callFrom,
                                setOpenViewModal: setOpen,
                                openViewModal: open
                              };
                              setOpen((state) => ({
                                ...state,
                                open: false,
                                openSubscription: true,
                                machineSubFromEmployee: machineSubFromEmployee
                              }));
                            }
                          }}
                        >
                          Add Subscription
                        </Button>
                      </Stack>
                    </div>
                  </Grid>
                )}
              </Grid>

              {/* Machine Location */}
              <Grid container spacing={gridSpacing} style={{ paddingTop: '30px' }}>
                <Grid item xs={12}>
                  <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}>
                    <div className="address_locationmain" style={{ background: '#6fd74b', mb: 2, padding: '10px 15px' }}>
                      <Typography component="h4" variant="h3" sx={{ color: '#fff' }}>
                        Machine Location
                      </Typography>
                    </div>
                    <Grid container spacing={gridSpacing} className="padding-address-new">
                      {/* <div style={{ padding: '10px 15px 15px' }}> */}
                      {/* <Typography component={'h3'} variant="h2" sx={{ fontWeight: '600', fontSize: '14px' }}>
                        Locations :
                      </Typography> */}
                      {/* machineDetails?.location?.name */}
                      {machineDetails?.locations?.map((item, i) => (
                        <Grid item lg={12} key={i}>
                          <div className="addrs-new-bx-strt addrs-new-bx-strt-new">
                            Address line {i + 1}
                            <div style={{ padding: '20px' }}>
                              <h5>{item?.details?.name}</h5>
                              <p>{item?.details?.address?.fullAddress}</p>
                            </div>
                          </div>
                        </Grid>
                      ))}
                      <Grid item lg={12} className="machineViewAddress">
                        <div style={{ padding: '10px', borderColor: '#fff' }} className="addrs-new-bx-strt addrs-new-bx-strt-new">
                          <Typography variant="h4" component="p" style={{ fontWeight: 'bold' }}>
                            {machineDetails?.location?.name || 'N.A.'}
                          </Typography>
                          <Typography variant="h4" component="p" style={{ fontWeight: 'unset', paddingTop: 2 }}>
                            {machineDetails?.location?.address?.fullAddress || 'N.A.'}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>

              {/* Machine Information */}
              <Grid container spacing={gridSpacing} style={{ paddingTop: '30px' }}>
                <Grid item xs={12} className="machineModalView">
                  <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}>
                    <div className="address_locationmain" style={{ background: '#6fd74b', mb: 2, padding: '10px 15px' }}>
                      <Typography
                        component="h4"
                        variant="h3"
                        // sx={{ mr: 1, pb: 1.8 }}
                        sx={{ color: '#fff' }}
                      >
                        Machine Information
                      </Typography>
                    </div>
                    <Grid container spacing={gridSpacing} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                      <Grid item xs={12} sm={6} md={4} lg={4} sx={{ padding: 0 }}>
                        <div style={{ border: '1px solid #fff', borderRadius: '6px', marginLeft: 15, marginRight: 15 }}>
                          <div className="address_locationmain" style={{ margin: 5, padding: '5px', display: 'inherit' }}>
                            <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                              Machine Type :{' '}
                            </Typography>
                            <Typography variant="h4" component="sapn" style={{ fontWeight: 'unset', paddingTop: 10 }}>
                              {machineDetails?.type || 'N.A.'}
                            </Typography>
                          </div>
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4} lg={4} sx={{ padding: 0 }}>
                        <div style={{ border: '1px solid #fff', borderRadius: '6px', marginLeft: 15, marginRight: 15 }}>
                          <div className="address_locationmain" style={{ margin: 5, padding: '5px', display: 'inherit' }}>
                            <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                              Nickname :{' '}
                            </Typography>
                            <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 10 }}>
                              {machineDetails?.nickName || 'N.A.'}
                            </Typography>
                          </div>
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4} lg={4} sx={{ padding: 0 }}>
                        <div style={{ border: '1px solid #fff', borderRadius: '6px', marginLeft: 15, marginRight: 15 }}>
                          <div className="address_locationmain" style={{ margin: 5, padding: '5px', display: 'inherit' }}>
                            <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                              Machine Model :{' '}
                            </Typography>
                            {machineDetails?.model ? (
                              <Typography
                                variant="h4"
                                // component={machineDetails?.model ? 'p' : 'span'}
                                component={'span'}
                                style={{ fontWeight: 'unset', paddingTop: 10 }}
                              >
                                {machineDetails?.model || 'N.A.'}
                              </Typography>
                            ) : (
                              'N.A.'
                            )}
                          </div>
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4} lg={4} sx={{ padding: 0 }}>
                        <div style={{ border: '1px solid #fff', borderRadius: '6px', marginLeft: 15, marginRight: 15 }}>
                          <div className="address_locationmain" style={{ margin: 5, padding: '5px', display: 'inherit' }}>
                            <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                              Machine Software :{' '}
                            </Typography>
                            {machineDetails?.softwareVersions && Array.isArray(machineDetails?.softwareVersions) ? (
                              <Typography
                                variant="h4"
                                // component={machineDetails?.softwareVersions ? 'p' : 'span'}
                                component={'span'}
                                style={{ fontWeight: 'unset', paddingTop: 10 }}
                              >
                                {machineDetails?.softwareVersions && Array.isArray(machineDetails?.softwareVersions)
                                  ? machineDetails?.softwareVersions.join(',')
                                  : ''}
                              </Typography>
                            ) : (
                              'N.A.'
                            )}
                          </div>
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4} lg={4} sx={{ padding: 0 }}>
                        <div style={{ border: '1px solid #fff', borderRadius: '6px', marginLeft: 15, marginRight: 15 }}>
                          <div className="address_locationmain" style={{ margin: 5, padding: '5px', display: 'inherit' }}>
                            <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                              Bins :{' '}
                            </Typography>
                            <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 10 }}>
                              {machineDetails?.bins || 'N.A.'}
                            </Typography>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>

              <Grid container spacing={gridSpacing} style={{ paddingTop: '30px' }}>
                <Grid item xs={12} className="machineModalView">
                  <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}>
                    <div className="address_locationmain" style={{ background: '#6fd74b', mb: 2, padding: '10px 15px' }}>
                      <Typography
                        component="h4"
                        variant="h3"
                        // sx={{ mr: 1, pb: 1.8 }}
                        sx={{ color: '#fff' }}
                      >
                        Machine Invoice
                      </Typography>
                    </div>
                    <Grid container spacing={gridSpacing} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                      <Grid item xs={12} sm={6} md={6} lg={6} sx={{ padding: 0 }}>
                        <div style={{ border: '1px solid #fff', borderRadius: '6px', marginLeft: 15, marginRight: 15 }}>
                          <div className="address_locationmain" style={{ margin: 5, padding: '5px', display: 'inherit' }}>
                            <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                              Machine Invoice Number :{' '}
                            </Typography>
                            {machineDetails?.purchaseInvoice?.invoiceNumber && (
                              <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 10 }}>
                                {machineDetails?.purchaseInvoice?.invoiceNumber ?? 'N.A.'}
                              </Typography>
                            )}
                          </div>
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6} lg={6} sx={{ padding: 0 }}>
                        <div style={{ border: '1px solid #fff', borderRadius: '6px', marginLeft: 15, marginRight: 15 }}>
                          <div className="address_locationmain" style={{ margin: 5, padding: '5px', display: 'inherit' }}>
                            <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                              Machine Invoice Status :{' '}
                            </Typography>
                            <Typography variant="h4" component="span" style={{ fontWeight: 'unset', paddingTop: 10 }}>
                              {machineDetails?.purchaseInvoice?.status && (
                                <>
                                  <Chip
                                    className="chip_small"
                                    // size="small"
                                    label={
                                      machineDetails?.purchaseInvoice?.status === 'Pending'
                                        ? 'Pending'
                                        : machineDetails?.purchaseInvoice?.status === 'Paid'
                                        ? 'Paid'
                                        : machineDetails?.purchaseInvoice?.status === 'AwaitingClearance'
                                        ? 'Awaiting Clearance'
                                        : 'Cancelled'
                                    }
                                    style={{
                                      color: '#fff',
                                      fontWeight: '400',
                                      backgroundColor:
                                        machineDetails?.purchaseInvoice?.status === 'Pending'
                                          ? '#ae30c2'
                                          : machineDetails?.purchaseInvoice?.status === 'Paid'
                                          ? '#30c42b'
                                          : machineDetails?.purchaseInvoice?.status === 'AwaitingClearance'
                                          ? '#dbc115'
                                          : '#d84315'
                                    }}
                                  />
                                </>
                              )}
                            </Typography>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
              {machinePermission?.showMachineConfig && (
                <>
                  <Grid container spacing={gridSpacing} style={{ paddingTop: '30px' }}>
                    <Grid item xs={12}>
                      <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}>
                        <div className="address_locationmain" style={{ background: '#6fd74b', mb: 2, padding: '10px 15px' }}>
                          <Typography
                            component="h4"
                            variant="h3"
                            // sx={{ mr: 1, pb: 1.8 }}
                            sx={{ color: '#fff' }}
                          >
                            Machine Configuration
                          </Typography>
                        </div>
                        <Grid container spacing={gridSpacing} style={{ paddingTop: '10px', paddingBottom: '20px' }}>
                          <Grid item sx={{ padding: 0 }}>
                            <div style={{ marginLeft: 15, marginRight: 15 }}>
                              <div className="address_locationmain" style={{ margin: 5, padding: '5px', display: 'inherit' }}>
                                <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                                  Machine API Key :{' '}
                                </Typography>
                                {machineDetails?.apiKey ? (
                                  <>
                                    <Typography
                                      variant="h5"
                                      component={'span'}
                                      sx={{ fontFamily: 'monospace', fontSize: 17, wordBreak: 'break-word', overflowWrap: 'break-word' }}
                                    >
                                      {viewApi ? machineDetails?.apiKey : maskData(machineDetails?.apiKey)} {/* Mask the key when hidden */}
                                    </Typography>
                                    <Typography variant="h5" component={'span'} sx={{ paddingTop: '5px' }}>
                                      <span
                                        onClick={() => setViewApi((prev) => !prev)}
                                        aria-label="toggle visibility"
                                        style={{ marginTop: 0, paddingRight: '6px', position: 'relative', top: '7px' }}
                                      >
                                        {viewApi ? <VisibilityOffIcon /> : <VisibilityIcon color="primary" />}
                                      </span>
                                      <CopyIconButton textToCopy={machineDetails?.apiKey} isIconDiv={true} />
                                    </Typography>
                                  </>
                                ) : (
                                  'N.A.'
                                )}
                              </div>
                            </div>
                          </Grid>

                          <Grid item sx={{ padding: 0 }}>
                            <div style={{ marginLeft: 15, marginRight: 15 }}>
                              <div className="address_locationmain" style={{ margin: 5, padding: '5px', display: 'inherit' }}>
                                <Typography
                                  variant="h4"
                                  // component={machineDetails?.serialNumber ? 'p' : 'span'}
                                  component={'span'}
                                  style={{ fontWeight: 'bold' }}
                                >
                                  Machine Serial Number :{' '}
                                </Typography>
                                <Typography
                                  variant="h4"
                                  // component={machineDetails?.serialNumber ? 'p' : 'span'}
                                  component={'span'}
                                  style={{ fontWeight: 'unset', paddingTop: 10 }}
                                >
                                  {machineDetails?.serialNumber || 'N.A.'}
                                </Typography>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </>
              )}

              {/* <Grid container spacing={2.5} style={{ paddingTop: '30px' }}>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                    Buyer Organization :{' '}
                  </Typography>
                  <Typography variant="h4" component="span" style={{ fontWeight: 'unset' }}>
                    {machineDetails?.buyer?.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                    Seller Organization :{' '}
                  </Typography>
                  <Typography variant="h4" component="span" style={{ fontWeight: 'unset' }}>
                    {machineDetails?.seller?.name}
                  </Typography>
                </Grid>

                {!RecyclerToggleBoughtRentedAssignedCondition && (
                  <>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                        Client Organization :{' '}
                      </Typography>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'unset' }}>
                        {machineDetails?.client?.name}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                        Recycler Organization :{' '}
                      </Typography>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'unset' }}>
                        {machineDetails?.recycler?.name}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                        Service Provider Organization :{' '}
                      </Typography>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'unset' }}>
                        {machineDetails?.serviceProvider?.name}
                      </Typography>
                    </Grid>
                  </>
                )}

                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                    Rental Organization :{' '}
                  </Typography>
                  <Typography variant="h4" component="span" style={{ fontWeight: 'unset' }}>
                    {machineDetails?.buyer?.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                    Location :{' '}
                  </Typography>
                  <Typography variant="h4" component="span" style={{ fontWeight: 'unset' }}>
                    {machineDetails?.location?.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                    Nickname :{' '}
                  </Typography>
                  <Typography variant="h4" component="span" style={{ fontWeight: 'unset' }}>
                    {machineDetails?.nickName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                    Machine Model :{' '}
                  </Typography>
                  <Typography variant="h4" component="span" style={{ fontWeight: 'unset' }}>
                    {machineDetails?.model}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                    Machine Software :{' '}
                  </Typography>
                  <Typography variant="h4" component="span" style={{ fontWeight: 'unset' }}>
                    {machineDetails?.softwareVersions && Array.isArray(machineDetails?.softwareVersions)
                      ? machineDetails?.softwareVersions.join(',')
                      : ''}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                  <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                    Bins :{' '}
                  </Typography>
                  <Typography variant="h4" component="span" style={{ fontWeight: 'unset' }}>
                    {machineDetails?.bins}
                  </Typography>
                </Grid>

                {!RecyclerToggleBoughtRentedAssignedCondition && (
                  <>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                        Base Price :{' '}
                      </Typography>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'unset' }}>
                        {machineDetails?.basePrice}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'bold' }}>
                        Per Drive Cost :{' '}
                      </Typography>
                      <Typography variant="h4" component="span" style={{ fontWeight: 'unset' }}>
                        {machineDetails?.perDriveCost}
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid> */}
              {/* <div style={{ paddingTop: '25px' }}>
                <Item elevation={0} style={{ border: '1px solid #b4b4b4', borderRadius: 18 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      <Typography variant="p" component="span" style={{ fontWeight: 'bold' }}>
                        Machine Type :{' '}
                      </Typography>
                      <Typography variant="p" component="span" style={{ fontWeight: 'bold' }}>
                        {machineDetails?.type}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      <Typography variant="p" component="span" style={{ fontWeight: 'bold' }}>
                        Invoice No. :{' '}
                      </Typography>
                      <Typography variant="p" component="span" style={{ fontWeight: 'bold' }}>
                        {machineDetails?.invoice?.invoiceNumber}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      <Typography variant="p" component="span" style={{ fontWeight: 'bold' }}>
                        API Key :{' '}
                      </Typography>
                      <Typography variant="p" component="span" style={{ fontWeight: 'bold' }}>
                        {machineDetails?.apiKey}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      <Typography variant="p" component="span" style={{ fontWeight: 'bold' }}>
                        Serial No. :{' '}
                      </Typography>
                      <Typography variant="p" component="span" style={{ fontWeight: 'bold' }}>
                        {machineDetails?.serialNumber}
                      </Typography>
                    </Grid>
                  </Grid>
                </Item>
              </div> */}

              {/* Machine Stepper  */}
              {/* <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <MachineStepper />
                </Grid>
              </Grid> */}
            </>
          )}

          {/* display: 'flex', alignItems: 'center', */}
          {/* style={{ textAlign: 'center' }} */}
          <Grid container spacing={2} className="machineViewBtnContainer">
            {/* {orgType !== 'Recycler' ? ( */}
            <>
              <div className="machineViewBtnWrap">
                {/* Grid item xs={6} sm={4} md={4} lg={3} */}
                {machinePermission?.showRaiseTicketButton && (
                  <Button
                    disableElevation
                    size="large"
                    type="button"
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      navigate(`/dashboard/tickets/add?machineId=${open?.machineId}`);
                    }}
                    className="machineViewBtn"
                    style={{ backgroundColor: 'rgb(229 59 59)' }}
                  >
                    <HelpOutlineIcon />
                    &nbsp; Raise Ticket
                  </Button>
                )}
                {machinePermission?.showViewTicketButton == true ? (
                  <Button
                    disableElevation
                    size="large"
                    type="button"
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      // navigate('/dashboard/tickets');
                      navigate(`/dashboard/tickets?machineId=${open?.machineId}`);
                    }}
                    className="machineViewBtn"
                    style={{ backgroundColor: '#0288d1' }} // Adjust button padding and font size
                  >
                    <RemoveRedEyeIcon />
                    &nbsp; View Ticket
                  </Button>
                ) : null}

                {machinePermission?.showRentButton && (
                  <Button
                    disableElevation
                    size="large"
                    type="button"
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      // setOpenLeaseMachineModal((state) => !state);
                      navigate('/dashboard/machines/rent-machine', { state: { machineDetails: machineDetails } });
                    }}
                    className="machineViewBtn"
                    style={{ backgroundColor: '#ff9800' }} // Adjust button padding and font size
                  >
                    <BuildIcon />
                    &nbsp; Rent Machine
                  </Button>
                )}
              </div>
              {/* <Grid item xs={6} sm={3} md={3} lg={3}></Grid> */}

              <Grid container spacing={gridSpacing} style={{ paddingTop: '30px' }}>
                <Grid item xs={12}>
                  <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}>
                    <div className="address_locationmain" style={{ background: '#6fd74b', mb: 2, padding: '10px 15px' }}>
                      <Typography component="h4" variant="h3" sx={{ color: '#fff' }}>
                        Machine Status
                      </Typography>
                    </div>
                    <Grid container spacing={gridSpacing} className="padding-address-new">
                      <Grid item lg={12} className="machineViewAddress">
                        <div style={{ padding: '10px', borderColor: '#fff' }} className="addrs-new-bx-strt addrs-new-bx-strt-new">
                          <div className="machineViewBtnWrap">
                            <Button
                              disableElevation
                              size="large"
                              type="button"
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                setOnlineStatus(!onlineStatus);
                              }}
                              disabled={isLoadingUpdateMachineStatus}
                              className="machineViewBtn"
                              // style={{ backgroundColor: '#006747' }} // Adjust button padding and font size
                              style={{ backgroundColor: machineStatusColors.Online }} // Adjust button padding and font size
                            >
                              <OnlinePredictionOutlinedIcon />
                              &nbsp; Set Online
                            </Button>
                            <Button
                              disableElevation
                              size="large"
                              type="button"
                              variant="contained"
                              color="error"
                              onClick={() => {
                                setOfflineStatus(!offlineStatus);
                              }}
                              disabled={isLoadingUpdateMachineStatus}
                              className="machineViewBtn"
                              // style={{ backgroundColor: 'rgb(175 116 255)' }} // Adjust button padding and font size
                              style={{ backgroundColor: machineStatusColors.Offline }}
                            >
                              <FilterListOffOutlinedIcon />
                              &nbsp; Set Offline
                            </Button>

                            <Button
                              disableElevation
                              size="large"
                              type="button"
                              variant="contained"
                              color="warning"
                              onClick={() => {
                                setMaintainStatus(!maintainStatus);
                              }}
                              disabled={isLoadingUpdateMachineStatus}
                              className="machineViewBtn"
                              // style={{ backgroundColor: '#F8BA40' }} // Adjust button padding and font size
                              style={{ backgroundColor: machineStatusColors.Maintenance }}
                            >
                              <EngineeringOutlinedIcon />
                              &nbsp; Set Maintenance
                            </Button>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>

              {/* <Grid item xs={6} sm={3} md={3} lg={3}></Grid> */}
            </>
            {/* ) : */}
            <>
              {/* {orgType === 'Recycler' && toggleBoughtRentedAssigned === 'Bought' && (
                  <>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      <Button
                        disableElevation
                        size="large"
                        type="button"
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          // setOpenLeaseMachineModal((state) => !state);
                          navigate('/dashboard/machines/rent-machine', { state: { machineDetails: machineDetails } });
                        }}
                        className="machineViewBtn"
                        style={{ backgroundColor: 'rgb(111, 186, 85)' }} // Adjust button padding and font size
                      >
                        <BuildIcon />
                        &nbsp; Rent Machine
                      </Button>
                    </Grid>
                  </>
                )} */}
              {/*
               */}

              {/*  */}
            </>
            {/* )} */}
          </Grid>
        </DialogContent>
      </Dialog>
      <DeleteDialog
        open={onlineStatus}
        setOpen={setOnlineStatus}
        title={'Confirmation !'}
        subTitle={'Are you sure to change the status of machine?'}
        handleSubmit={() => {
          updatedStatusMachine(
            {
              id: machineDetails?.id,
              status: 'Online'
            },
            {
              onSuccess: (res) => {
                if (res?.status == 200) {
                  toastConfig.type = 'success';
                  setToast(toastConfig, res?.data.message);
                  // queryClient.refetchQueries({
                  //   queryKey: ['getMachineDetailsById'],
                  //   type: 'active'
                  // });
                  setOnlineStatus(false);
                  setOpen(false);
                  queryClient.refetchQueries({
                    queryKey: ['getMachineNewData'],
                    type: 'active'
                  });
                } else {
                  toastConfig.type = 'error';
                  setToast(toastConfig, error?.response?.data?.error);
                  setOpen(false);
                  setOnlineStatus(false);
                }
              },
              onError: (error) => {
                toastConfig.type = 'error';
                setToast(toastConfig, error?.response?.data?.error);
                setOpen(false);
                setOnlineStatus(false);
              }
            }
          );
        }}
        isDeleteLoading={isLoadingUpdateMachineStatus}
      />

      <DeleteDialog
        open={offlineStatus}
        setOpen={setOfflineStatus}
        title={'Confirmation !'}
        subTitle={'Are you sure to change the status of machine?'}
        handleSubmit={() => {
          updatedStatusMachine(
            {
              id: machineDetails?.id,
              status: 'Offline'
            },
            {
              onSuccess: (res) => {
                if (res?.status == 200) {
                  toastConfig.type = 'success';
                  setToast(toastConfig, res?.data.message);
                  setOfflineStatus(false);
                  setOpen(false);
                  queryClient.refetchQueries({
                    queryKey: ['getMachineNewData'],
                    type: 'active'
                  });
                  // queryClient.refetchQueries({
                  //   queryKey: ['getMachineDetailsById'],
                  //   type: 'active'
                  // });
                } else {
                  toastConfig.type = 'error';
                  setToast(toastConfig, error?.response?.data?.error);
                  setOpen(false);
                  setOfflineStatus(false);
                }
              },
              onError: (error) => {
                toastConfig.type = 'error';
                setToast(toastConfig, error?.response?.data?.error);
                setOpen(false);
                setOfflineStatus(false);
              }
            }
          );
        }}
        isDeleteLoading={isLoadingUpdateMachineStatus}
      />
      <DeleteDialog
        open={maintainStatus}
        setOpen={setMaintainStatus}
        title={'Confirmation !'}
        subTitle={'Are you sure to change the status of machine?'}
        handleSubmit={() => {
          updatedStatusMachine(
            {
              id: machineDetails?.id,
              status: 'Maintenance'
            },
            {
              onSuccess: (res) => {
                if (res?.status == 200) {
                  toastConfig.type = 'success';
                  setToast(toastConfig, res?.data.message);
                  setMaintainStatus(false);
                  setOpen(false);
                  queryClient.refetchQueries({
                    queryKey: ['getMachineNewData'],
                    type: 'active'
                  });
                  // queryClient.refetchQueries({
                  //   queryKey: ['getMachineDetailsById'],
                  //   type: 'active'
                  // });
                } else {
                  toastConfig.type = 'error';
                  setToast(toastConfig, error?.response?.data?.error);
                  setOpen(false);
                  setMaintainStatus(false);
                }
              },
              onError: (error) => {
                toastConfig.type = 'error';
                setToast(toastConfig, error?.response?.data?.error);
                setOpen(false);
                setMaintainStatus(false);
              }
            }
          );
        }}
        isDeleteLoading={isLoadingUpdateMachineStatus}
      />

      {/* Machine Thresold */}

      <>
        {openThresold && (
          <Dialog
            open={open}
            fullWidth={true}
            fullScreen={false}
            onClose={handleCloseThresold}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            // maxWidth="sm"
          >
            <DialogTitle
              id="alert-dialog-title"
              variant="span"
              style={{
                textTransform: 'capitalize'
              }}
            >
              Machine Thresold Details
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleCloseThresold}
              sx={(theme) => ({
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme.palette.grey[500]
              })}
            >
              <CloseIcon color="error" />
            </IconButton>

            <DialogContent dividers>
              <Formik
                initialValues={{
                  binThreshold: ''
                }}
                // validationSchema={validationSchema}
                // validationSchema={Yup.object().shape({
                //   binThreshold: Yup.string()
                //     .trim()
                //     .required('Machine threshold cannot be empty')
                //     .matches(/^\d+$/, 'Only numeric values are allowed')
                // })}
                validationSchema={Yup.object().shape({
                  binThreshold: Yup.string()
                    .trim()
                    .required('Machine threshold cannot be empty')
                    .matches(/^\d+$/, 'Only numeric values are allowed')
                    .test('max-value', 'Value cannot exceed 100', (value) => !value || Number(value) <= 100)
                })}
                onSubmit={(values) => {
                  const submitData = {
                    binThreshold: values?.binThreshold || null,
                    id: machineDetails?.id
                  };
                  // console.log('🚀 ~ submitData:', submitData);
                  updateBinThreshold(submitData, {
                    onSuccess: (res) => {
                      if (res?.status == 200) {
                        setOpenThresold(false);
                        setOpen(false);
                      } else {
                        setOpenThresold(false);
                        setOpen(false);
                      }
                    },
                    onError: (error) => {
                      setOpenThresold(false);
                      setOpen(false);
                    }
                  });
                }}
              >
                {({ errors, touched, handleChange, values, handleSubmit, setFieldValue, handleBlur }) => (
                  <>
                    <Form noValidate onSubmit={handleSubmit}>
                      <Grid
                        container
                        spacing={gridSpacing}
                        sx={{
                          alignContent: 'center'
                        }}
                      >
                        <Grid item xs={12}>
                          <Grid container spacing={gridSpacing}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <FormControl
                                fullWidth
                                error={Boolean(touched.binThreshold && errors.binThreshold)}
                                sx={{ ...theme.typography.customInput }}
                              >
                                <TextField
                                  variant="outlined"
                                  type="text"
                                  fullWidth
                                  name="binThreshold"
                                  value={values?.binThreshold}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={Boolean(touched.binThreshold && errors.binThreshold)}
                                  InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                                  }}
                                />
                                {touched.binThreshold && errors.binThreshold && (
                                  <FormHelperText
                                    error
                                    id="standard-weight-helper-text-email-login"
                                    sx={{
                                      fontSize: '13.8px'
                                    }}
                                  >
                                    {errors.binThreshold}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <DialogActions>
                        {errors.submit && (
                          <Box sx={{ mt: 3 }}>
                            <FormHelperText error>{errors.submit}</FormHelperText>
                          </Box>
                        )}
                        <Box sx={{ m: 1, position: 'relative' }}>
                          <Button
                            fullWidth
                            variant="contained"
                            type="submit"
                            style={{
                              color: '#fff'
                            }}
                            color="primary"
                            disabled={isLoadingBinThreshold}
                          >
                            Save
                            {isLoadingBinThreshold && (
                              <CircularProgress
                                size={24}
                                sx={{
                                  color: '#16B1FF',
                                  position: 'absolute',
                                  top: '50%',
                                  left: '50%',
                                  marginTop: '-12px',
                                  marginLeft: '-12px'
                                }}
                              />
                            )}
                          </Button>
                        </Box>
                        <Button variant="outlined" type="reset" color="error" onClick={handleCloseThresold}>
                          Close
                        </Button>
                      </DialogActions>
                    </Form>
                  </>
                )}
              </Formik>
            </DialogContent>
          </Dialog>
        )}
      </>
    </>
  );
}
