/* eslint-disable react/prop-types */
/* eslint-disable */
import { Box, Button, Chip, Divider, Grid, Paper, TextField, Tooltip, Typography } from '@mui/material';
import { useGetAllMachineSubscriptionsList } from 'hooks/useMachineHooks';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { machineStatusColors } from 'utils/commonUtil';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import EmployeeMachineListSkeleton from 'ui-component/cards/Skeleton/EmployeeMachineListSkeleton';
// import { useNavigate } from 'react-router';
// import SubscriptionPlansCarousel from '../subcription/SubscriptionPlansCarousel';
// import { useGetSubcriptionForMachineList } from 'hooks/useMachineHooks';

const EmployeeSubcriptionForm = ({ setOpenMachineDetails }) => {
  // const navigate = useNavigate();
  /*const { data: subcriptionList, isLoading: isLoadingSubcriptionList } = useGetSubcriptionForMachineList({
      filters: { machineSubs: true }
    });
    const [openContactUs, setOpenContactUs] = useState(false);
    console.log('Employee ~ PricingTableDialog ~ openDialog ~ ', open);*/
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detects if the screen is small (mobile)
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md')); // Detects if the screen is medium (tablet)

  // Define maxHeight based on screen size
  const getMaxHeight = () => {
    if (isMobile) return '300px'; // Smaller height for mobile
    if (isTablet) return '460px'; // Medium height for tablets
    return '460px'; // Larger height for desktops
  };
  //type
  const callMachineDetailsScreen = (machineObj) => {
    // console.log('callMachineDetailsScreen ', machineObj, type);
    // navigate('/dashboard/employees/subscription-machine-details', {
    //   state: {
    //     machineObj,
    //     type,
    //     empObj: null
    //   }
    // });
    // setOpenMachineDetails((prev) => ({ ...prev, open: true, machineObj, isCurrentSubscription: type !== 'Add' }));
    window.open('/dashboard/machines?viewMachineID=' + machineObj?.id + '&viewSub=true', '_blank', 'noopener');
  };

  // useGetAllMachineSubscriptionsList
  // State for search query
  const [searchQuery, setSearchQuery] = useState('');
  const [machinesData, setMachinesData] = useState([]);
  const [totalGlobalUsers, setTotalGlobalUsers] = useState(0);
  let totalGlobalUsersCount = 0;
  /*useEffect(() => {
      searchMachineSubscription(searchQuery, {
        onSuccess: (res) => {
          console.log('res - searchMachineSubscription - Employee : ', res);
          if (res?.status == 200) {
            // toastConfig.type = 'success';
            // setToast(toastConfig, res?.data.message);
          } else {
            // toastConfig.type = 'error';
            // setToast(toastConfig, res?.response?.data?.error);
          }
        },
        onError: (error) => {
          // toastConfig.type = 'error';
          // setToast(toastConfig, error?.response?.data?.error);
          console.log('checkEmployeeAdd ~ error : ', error);
        }
      });
    }, [searchQuery]);*/
  useEffect(() => {
    if (searchQuery === '') searchMachineSubscriptionCall('');
  }, [searchQuery]);

  const { mutate: searchMachineSubscription, isPending: isSearchMachineSubscription } = useGetAllMachineSubscriptionsList(true);
  // const { machines, isLoading, isError, error } = useGetAllMachineSubscriptionsList(searchQuery);
  // console.log('subMachineData : ', machinesData, isSearchMachineSubscription);
  /*const machinesData = [
      {
        id: '679333c504cac87b6f4b2b99',
        nickname: 'AlphaX',
        location: 'Sector 34 Exhibition Ground, PQ99+JPH, Sub. City Center, Sector 34B, Sector 34, Chandigarh, 160034, India',
        subscription: {
          type: 'Premium Plan',
          price: '$49.99 / month',
          limits: {
            maxLocalUsers: 10,
            maxGlobalUsers: 5,
            maxAdvertisements: 2
          },
          current: {
            localUsersAdded: 5,
            globalUsersAdded: 2,
            advertisementsAdded: 1
          }
        }
      },
      {
        id: '6793348204cac87b6f4b2bdd',
        nickname: 'BetaZ',
        location: 'Sector 34 Exhibition Ground, PQ99+JPH, Sub. City Center, Sector 34B, Sector 34, Chandigarh, 160034, India',
        subscription: {
          type: 'Standard Plan',
          price: '$29.99 / month',
          limits: {
            maxLocalUsers: 7,
            maxGlobalUsers: 3,
            maxAdvertisements: 1
          },
          current: {
            localUsersAdded: 3,
            globalUsersAdded: 1,
            advertisementsAdded: 0
          }
        }
      },
      {
        id: '6793350e69728f585aa60d2a',
        nickname: 'GammaY',
        location: 'Sector 34 Exhibition Ground, PQ99+JPH, Sub. City Center, Sector 34B, Sector 34, Chandigarh, 160034, India',
        subscription: null
      }
    ];*/

  // Filter machines based on search query
  // const filteredMachines = machinesData.filter((machine) =>
  //   [machine.id.toLowerCase(), machine.nickname.toLowerCase(), machine.location.toLowerCase()].some((field) =>
  //     field.includes(searchQuery.toLowerCase())
  //   )
  // );
  const searchMachineSubscriptionCall = (query) => {
    searchMachineSubscription(query, {
      onSuccess: (response) => {
        // console.log('searchMachineSubscriptionCall ~ response', response);
        if (totalGlobalUsersCount === 0) setTotalGlobalUsers(response?.data?.data?.totalGlobalUsers || 0);
        // totalGlobalUsersCount = 1;
        // Transform the API response into the desired `machinesData` structure
        const transformedData =
          response?.data?.data &&
          response?.data?.data?.machines &&
          Array.isArray(response?.data?.data?.machines) &&
          response?.data?.data?.machines.length > 0 &&
          response?.data?.data?.machines?.map((machine) => ({
            id: machine?.id || '',
            machineName: machine?.machineName || '',
            nickname: machine?.nickName || 'N.A.',
            locationName: machine?.location?.name || 'N.A.',
            location: machine?.location?.address?.fullAddress || 'N.A.',
            serialNumber: machine?.serialNumber || 'N.A.',
            status: machine?.status || '',
            subscription:
              machine?.activeSubscription?.subscription && machine?.activeSubscription?.plan
                ? {
                    type: machine?.activeSubscription?.plan?.name || 'N.A.',
                    id: machine?.activeSubscription?.subscription?._id || null,
                    price: `${machine?.activeSubscription?.plan?.regularBillingCycle?.price?.currencyCode} ${
                      machine?.activeSubscription?.plan?.regularBillingCycle?.price?.value
                    } / 
                    ${
                      machine?.activeSubscription?.plan?.regularBillingCycle?.intervalCount >= 1
                        ? ''
                        : machine?.activeSubscription?.plan?.regularBillingCycle?.intervalCount
                    } ${machine?.activeSubscription?.plan?.regularBillingCycle?.intervalUnit}`,
                    amount: machine?.activeSubscription?.plan?.regularBillingCycle?.price?.value || 0,
                    limits: {
                      maxLocalUsers: machine?.activeSubscription?.plan?.localUserCount || 0,
                      maxGlobalUsers: machine?.activeSubscription?.plan?.globalUserCount || 0,
                      maxAdvertisements: machine?.activeSubscription?.plan?.adCount || 0 // Assuming no advertisements in the response
                    },
                    current: {
                      localUsersAdded: machine?.totalLocalUsers || 0,
                      // globalUsersAdded: machine?.totalGlobalUsers || 0,
                      advertisementsAdded: machine?.totalAdvertisements || 0
                    }
                  }
                : null,
            trialPeriod: machine?.activeSubscription?.plan?.trialPeriod || null,
            tax: machine?.activeSubscription?.plan?.tax || 0
          }));

        // Update the state with the transformed data
        setMachinesData(transformedData);
      },
      onError: (error) => {
        console.error('Error fetching machine subscriptions:', error);
      }
    });
  };
  // Handle search input change
  const handleSearch = (query) => {
    if (query.length >= 3) {
      // Trigger the API call using the mutate function
      searchMachineSubscriptionCall(query);
    } else {
      // Clear the machinesData if the search query is less than 3 characters
      // setMachinesData([]);
    }
  };
  // backgroundColor: '#f0fdf4',
  const statusColors = machineStatusColors;
  // console.log('statusColors', statusColors);
  return (
    <Box sx={{ padding: '18px', pt: 0 }} className="employeeSubscriptionModalContainer">
      <Grid container spacing={2} sx={{ pt: 0 }}>
        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ pt: 0 }}>
          <Typography variant="h3" sx={{ fontWeight: 'bold', pb: 2 }} className="totalGlobalUserText">
            Total Global Users Count : <span style={{ fontWeight: 'normal' }}>{totalGlobalUsers}</span>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mb: 2 }}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              // sx={{ border: '2px solid rgb(111, 215, 75)', padding: '0px 18px 0px 18px', borderRadius: '10px' }}
            >
              <Typography variant="h3" sx={{ fontWeight: 'bold', pb: 1 }} className="totalGlobalUserText">
                Machine Status Color Code
              </Typography>
            </Grid>
            {statusColors &&
              Object.entries(statusColors).map(([key, color]) => (
                <Grid
                  key={key}
                  item
                  xs={6}
                  sm={3}
                  md={3}
                  lg={3}
                  // sx={{ border: '2px solid rgb(111, 215, 75)', padding: '0px 18px 0px 18px', borderRadius: '10px' }}
                >
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <FiberManualRecordIcon sx={{ fontSize: 20, color: color }} />
                    <span style={{ marginLeft: 10 }}>{key}</span>
                  </span>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>

      {/* Search Bar */}
      <Box sx={{ marginBottom: '24px' }}>
        <TextField
          fullWidth
          placeholder="Search by Nickname, or Location"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => {
            const query = e.target.value;
            setSearchQuery(query);
            handleSearch(query); // Trigger the search logic
          }}
          InputProps={{
            sx: {
              borderRadius: '8px',
              borderColor: '#4ade80',
              '&:focus': {
                borderColor: '#22c55e',
                boxShadow: '0 0 0 2px rgba(34, 197, 94, 0.25)'
              }
            }
          }}
        />
      </Box>
      <Box
        sx={{
          maxHeight: getMaxHeight(), // Set a fixed height for the scrollable area
          overflowY: 'auto', // Enable vertical scrolling
          marginTop: '16px', // Add some spacing from the top
          padding: '8px', // Optional: Add padding for better UI
          border: '1px solid #e0e0e0', // Optional: Add a border for better visibility
          borderRadius: '8px' // Optional: Add rounded corners
        }}
      >
        {/* Machine List */}
        <Grid container spacing={4}>
          {isSearchMachineSubscription ? (
            <>
              {/* <Grid item xs={12} sm={12} lg={12} display={'flex'} justifyContent={'center'}>
                <CircularProgress sx={{ mt: 5 }} />
              </Grid> */}
              <EmployeeMachineListSkeleton />
            </>
          ) : (
            <>
              {machinesData && machinesData.length > 0 ? (
                machinesData.map((machine, index) => {
                  // console.log('machine - modal - employee ', machine);
                  return (
                    <Grid item xs={12} sm={6} lg={4} key={index}>
                      <Paper
                        elevation={3}
                        sx={{
                          padding: '24px',
                          height: '100%',
                          position: 'relative' // Set the Paper component to relative positioning
                        }}
                      >
                        <Tooltip title={machine?.status || ''} arrow>
                          <FiberManualRecordIcon
                            sx={{
                              fontSize: 20,
                              color: statusColors[machine?.status || ''], // Color based on machine status
                              position: 'absolute', // Set the icon to absolute positioning
                              top: 5, // Adjust the distance from the top
                              right: 5 // Adjust the distance from the right
                            }}
                          />
                          {/* <Chip
                            label={machine?.status}
                            size="small"
                            sx={{
                              backgroundColor: statusColors[machine?.status || ''],
                              color: '#fff',
                              position: 'absolute', // Set the icon to absolute positioning
                              top: 5, // Adjust the distance from the top
                              right: 5, // Adjust the distance from the right
                              height: 'initial',
                              fontSize: '0.75rem',
                              margin: 0
                            }}
                          /> */}
                        </Tooltip>
                        {/* Machine Header */}
                        <Box
                          sx={{
                            backgroundColor: machine?.subscription ? '#bafba4' : '#fecaca',
                            color: machine?.subscription ? '#009939' : '#b91c1c',
                            fontWeight: 'bold',
                            fontSize: '1rem',
                            padding: '8px 16px',
                            borderRadius: '8px',
                            marginBottom: '16px',
                            wordWrap: 'break-word'
                            // marginTop: '4px'
                          }}
                        >
                          Machine: {machine?.machineName}
                        </Box>

                        {/* Nickname */}
                        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                          Nickname: <span style={{ fontWeight: 'normal' }}>{machine?.nickname}</span>
                        </Typography>
                        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                          Serial No. : <span style={{ fontWeight: 'normal' }}>{machine?.serialNumber}</span>
                        </Typography>

                        {/* Location */}
                        <Typography variant="body2" sx={{ marginTop: '8px', fontWeight: 500 }}>
                          {machine?.locationName}
                        </Typography>
                        <Typography variant="body2" sx={{ marginTop: '0px' }}>
                          {machine?.location}
                        </Typography>

                        {/* Subscription Details */}
                        {machine?.subscription ? (
                          <>
                            <div style={{ height: '100%' }}>
                              {/* Subscription Badge & Price */}
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '8px',
                                  marginTop: '16px'
                                }}
                              >
                                <Chip
                                  label={machine?.subscription.type}
                                  sx={{
                                    backgroundColor: '#6fd74b',
                                    color: '#fff',
                                    fontWeight: 'bold'
                                  }}
                                />
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                  {machine?.subscription?.price}
                                </Typography>
                              </Box>

                              <Typography variant="h5" sx={{ fontWeight: 'bold', marginTop: 2 }}>
                                Trial Period:{' '}
                                <span style={{ fontWeight: 'normal' }}>
                                  {machine?.trialPeriod?.price?.value <= 0 ? (
                                    <>
                                      {machine?.trialPeriod?.intervalCount && machine?.trialPeriod?.intervalCount <= 0
                                        ? ''
                                        : machine?.trialPeriod?.intervalCount}
                                      &nbsp;
                                      {machine?.trialPeriod?.intervalUnit || ''} - FREE
                                    </>
                                  ) : (
                                    <>
                                      {machine?.trialPeriod?.intervalCount && machine?.trialPeriod?.intervalCount <= 0
                                        ? ''
                                        : machine?.trialPeriod?.intervalCount}
                                      &nbsp;
                                      {machine?.trialPeriod?.intervalUnit || ''} - ({machine?.trialPeriod?.price?.currencyCode})&nbsp;
                                      {machine?.trialPeriod?.price?.value || ''}
                                    </>
                                  )}
                                </span>
                              </Typography>
                              <Typography variant="h5" sx={{ fontWeight: 'bold', marginTop: '2px' }}>
                                Subscription Tax: <span style={{ fontWeight: 'normal' }}>{machine?.tax + '%' || 'N.A.'}</span>
                              </Typography>

                              {/* Subscription Limits */}
                              <Box sx={{ marginTop: '8px' }}>
                                <Typography variant="body2">
                                  <strong>Machine Specific User(s): </strong> {machine?.subscription?.limits?.maxLocalUsers}
                                </Typography>
                                <Typography variant="body2">
                                  <strong> Global User(s): </strong> {machine?.subscription?.limits?.maxGlobalUsers}
                                </Typography>
                                <Typography variant="body2">
                                  <strong>Advertisements:</strong> {machine?.subscription?.limits?.maxAdvertisements}
                                </Typography>
                              </Box>
                              <Divider sx={{ mt: '10px' }} />
                              {/* Currently Added */}
                              <Box sx={{ marginTop: '8px' }}>
                                <Typography variant="body2">
                                  ✅ <strong>Added Machine Specific User(s) :</strong> {machine?.subscription?.current?.localUsersAdded}
                                </Typography>
                                <Typography variant="body2">
                                  ✅ <strong>Added Advertisements:</strong> {machine?.subscription?.current?.advertisementsAdded}
                                </Typography>
                              </Box>

                              {/* Upgrade Button */}
                              <Button
                                fullWidth
                                variant="contained"
                                color={'primary'}
                                sx={{
                                  marginTop: '16px',
                                  color: 'white',
                                  '&:hover': { backgroundColor: '#16a34a' }
                                }}
                                onClick={() => callMachineDetailsScreen(machine, 'Upgrade')}
                              >
                                Upgrade Plan
                              </Button>
                            </div>
                          </>
                        ) : (
                          <div style={{ height: '100%' }}>
                            <Box
                              sx={{
                                backgroundColor: '#fee2e2',
                                padding: '16px',
                                borderRadius: '8px',
                                marginTop: '16px'
                              }}
                            >
                              <Typography variant="body1" sx={{ color: '#b91c1c', fontWeight: 'bold' }}>
                                No Active Subscription
                              </Typography>
                            </Box>
                            <Button
                              fullWidth
                              variant="contained"
                              sx={{
                                marginTop: '130px',
                                backgroundColor: '#ef4444',
                                color: 'white',
                                '&:hover': { backgroundColor: '#dc2626' }
                              }}
                              onClick={() => callMachineDetailsScreen(machine, 'Add')}
                            >
                              Add Subscription
                            </Button>
                          </div>
                        )}
                      </Paper>
                    </Grid>
                  );
                })
              ) : (
                <>
                  <Typography variant="h5" sx={{ textAlign: 'center', width: '100%', pt: '50px' }}>
                    {/* No machines found matching your search criteria. */}
                    No Subscription Machines found!
                  </Typography>
                </>
              )}
            </>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default EmployeeSubcriptionForm;
