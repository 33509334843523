// import { Menu } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  // CircularProgress,
  // DialogActions,
  FormControl,
  FormHelperText,
  Grid,
  // InputLabel,
  // InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from '@mui/material';
// import { useGetPermissionsOrganizationNewList } from 'hooks/useOrganizationNewHooks';
// import { textAlign } from '@mui/system';
import React from 'react';
import { useEffect } from 'react';
// import { useSelector } from 'react-redux';
import { gridSpacing } from 'store/constant';
import { addSpacesToCamelCase } from 'utils/commonUtil';

const OrganizationFilterForm = ({
  errors,
  handleBlur,
  handleChange,
  // handleSubmit,
  touched,
  values,
  setFieldValue,
  // setTouched,
  resetForm,
  filterObj,
  // handleCloseFilter,
  setFilterObj,
  // permissionsList,
  orgFilters
}) => {
  // console.log('🚀 ~ orgFilters:', orgFilters);
  useEffect(() => {
    if (filterObj) {
      // console.log('🚀 ~ useEffect ~ filterObj:', filterObj);
      setFieldValue('orgName', filterObj?.orgName ? filterObj?.orgName : '');
      setFieldValue('status', filterObj?.status ? filterObj?.status : '');
      setFieldValue('types', filterObj?.orgTypes ? filterObj?.orgTypes.map(addSpacesToCamelCase) : []);
    }
  }, [filterObj, setFieldValue]);

  // const organizationType = useSelector((state) => state.customization.orgType);
  // const usersType = useSelector((state) => state.customization.userType);
  // const { data: permissionsList } = useGetPermissionsOrganizationNewList({
  //   organizationType: organizationType !== 'Super Admin' ? organizationType : 'SuperAdmin',
  //   usersType: usersType === 'Main POC' ? 'POC' : usersType
  // });

  const sowField = (filter, index) => {
    if (filter?.show)
      if (filter?.id == 'orgName')
        return (
          <Grid
            key={index}
            container
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className="select-machine-id inv-number select-machine"
            alignItems={'center'}
          >
            <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
              Organization Name
            </Typography>
            <FormControl fullWidth variant="outlined">
              <TextField
                disabled={filter?.isStatic}
                placeholder="Search Organization Name"
                variant="outlined"
                type="text"
                fullWidth
                name="orgName"
                value={values?.orgName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched?.[filter?.id] && errors?.[filter?.id])}
              />
              {touched?.[filter?.id] && errors?.[filter?.id] && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text-email-login"
                  sx={{
                    fontSize: '13.8px'
                  }}
                >
                  {errors?.[filter?.id]}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        );
      else if (filter?.id == 'status')
        return (
          <Grid
            key={index}
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className="select-status-filter"

            // select-job-enterprise"
            // className="select-job-enterprise select_separate2"
            // className="select-job-enterprise "
          >
            <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
              Status
            </Typography>
            <FormControl
              fullWidth
              variant="outlined"
              placeholder="Select Status"
              // error={Boolean(touched.status && errors.status)}
            >
              {/* <InputLabel sx={{}}> Select Status</InputLabel> */}
              <Select
                disabled={filter?.isStatic}
                value={values?.status}
                onChange={handleChange}
                onBlur={handleBlur}
                name="status"
                inputProps={{
                  'aria-label': 'Without label'
                }}
              >
                <MenuItem value="All">All</MenuItem>
                {/* <MenuItem value="">Select Status</MenuItem> */}
                <MenuItem value="true">Active</MenuItem>
                <MenuItem value="false">Inactive</MenuItem>
              </Select>
            </FormControl>
            {touched?.[filter?.id] && errors?.[filter?.id] && (
              <FormHelperText
                error
                id="standard-weight-helper-text-email-login"
                sx={{
                  fontSize: '13.8px'
                }}
              >
                {errors?.[filter?.id]}
              </FormHelperText>
            )}
          </Grid>
        );
      else if (filter?.id == 'types')
        return (
          <Grid key={index} item xs={12} sm={12} md={4} lg={4} className="select-job-enterprise select-job-enterprise-type">
            <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
              Types
            </Typography>
            <Autocomplete
              disabled={filter?.isStatic}
              multiple
              options={['Enterprise', 'Recycler', 'Retailer', 'Reseller', 'ServiceProvider']}
              value={values?.types || []} // Ensure default is an array
              onChange={(event, newValue) => setFieldValue('types', newValue)} // Use Formik's setFieldValue
              // onBlur={() => handleBlur()} // Ensure Formik handles blur correctly
              onBlur={handleBlur}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  const { onDelete, ...tagProps } = getTagProps({ index });
                  return (
                    <span
                      key={option}
                      {...tagProps}
                      style={{
                        backgroundColor: '#6fd74b',
                        color: '#fff',
                        padding: '2px 8px',
                        borderRadius: '4px',
                        marginRight: '5px',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      {option}
                      <button
                        onClick={onDelete}
                        style={{
                          marginLeft: '5px',
                          background: 'none',
                          border: 'none',
                          color: '#fff',
                          cursor: 'pointer'
                        }}
                      >
                        ✖
                      </button>
                    </span>
                  );
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Types"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              )}
              PaperComponent={(props) => (
                <Paper
                  {...props}
                  style={{
                    overflowY: 'auto'
                  }}
                />
              )}
            />
            {touched?.types && errors?.types && (
              <FormHelperText
                error
                sx={{
                  fontSize: '13.8px'
                }}
              >
                {errors.types}
              </FormHelperText>
            )}
          </Grid>
        );
      else return null;
    else return null;
  };

  return (
    <>
      <Grid
        key={'organizationFilter'}
        container
        spacing={gridSpacing}
        sx={{ marginBottom: '20px', alignItems: 'center', padding: '0px 20px' }}
      >
        {orgFilters?.map((filter, index) => sowField(filter, index))}
        {/* {(permissionsList?.view?.filters?.includes('OrgName') || permissionsList?.view?.filters?.includes('OrgNames')) && (
          
        )} */}

        {/* {permissionsList?.view?.filters?.includes('Status') && ( 
          
        )}
        */}

        {/* {permissionsList?.view?.filters?.includes('Types') && (
          
        )} */}
      </Grid>

      <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'right', paddingBottom: '20px', paddingRight: '20px' }}>
        <Button
          variant="outlined"
          type="reset"
          color="error"
          onClick={() => {
            resetForm();
            setFilterObj({
              status: '',
              orgName: '',
              orgTypes: ''
            });
            // handleCloseFilter();
          }}
        >
          Clear all
        </Button>
        <div style={{ marginLeft: '10px' }}>
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box sx={{ m: 0, position: 'relative' }}>
            <Button
              // fullWidth
              variant="contained"
              type="submit"
              style={{
                color: '#fff'
              }}
              color="primary"
              //   disabled={isLoadingUpdateInvoiceStatus}
            >
              Search
            </Button>
          </Box>
        </div>
      </div>
    </>
  );
};

export default OrganizationFilterForm;
