import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  OutlinedInput,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import React from 'react';
import { gridSpacing } from 'store/constant';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useTheme } from '@mui/material/styles';
import { Form } from 'formik';
import { useEffect } from 'react';
import { addSpacesToCamelCase } from 'utils/commonUtil';
const TicketForm = ({
  errors,
  handleBlur,
  handleChange,
  handleSubmit,

  touched,
  values,

  setFieldValue,

  btnName,
  isLoadingTicket,

  ticketReasonDetails
}) => {
  useEffect(() => {
    if (ticketReasonDetails) {
      setFieldValue('reason', ticketReasonDetails?.category ? ticketReasonDetails?.category : '');
      setFieldValue('associations', ticketReasonDetails?.associations ? ticketReasonDetails?.associations : []);
      // if (ticketReasonDetails?.associations && Array.isArray(ticketReasonDetails?.associations) && ticketReasonDetails?.associations.length)
      setAssociations(ticketReasonDetails?.associations || []);
    }
  }, [ticketReasonDetails]);

  const theme = useTheme();

  const associationsArray = ['SuperAdmin', 'Recycler', 'ServiceProvider'];

  const [associations, setAssociations] = React.useState([associationsArray[2]]);

  const handleFormat = (event, newAssociations) => {
    if (Array.isArray(newAssociations) && newAssociations.length) {
      setAssociations(newAssociations);
      setFieldValue('associations', newAssociations);
    }
  };

  return (
    <>
      <Form noValidate onSubmit={handleSubmit}>
        <Grid
          container
          spacing={gridSpacing}
          sx={{
            alignContent: 'center'
          }}
        >
          <Grid item xs={12}>
            <Typography component="h4" variant="h2" sx={{ mb: 2 }}>
              Ticket Category Details
            </Typography>
            <Grid container spacing={gridSpacing} sx={{ pt: 5 }}>
              <Grid item lg={6} md={6} sm={12} xs={12} className="ticketCategory">
                <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
                  Ticket Category *
                </Typography>
                <FormControl fullWidth error={Boolean(touched.reason && errors.reason)} sx={{ ...theme.typography.customInput }}>
                  {/* <InputLabel htmlFor="outlined-adornment-email-login"> Category * </InputLabel> */}
                  <OutlinedInput
                    id="outlined-adornment-secondary-address"
                    type="text"
                    value={values.reason}
                    name="reason"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    // label="Category *"
                    placeholder="Enter your ticket category"
                    inputProps={{}}
                    autoComplete="off"
                  />
                  {touched.reason && errors.reason && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                      sx={{
                        fontSize: '13.8px'
                      }}
                    >
                      {errors.reason}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12} className="ticketCategory">
                <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
                  Send Ticket To *
                </Typography>
                <FormControl
                  fullWidth
                  error={Boolean(touched.associations && errors.associations)}
                  sx={{
                    ...theme.typography.customInput,
                    '& .MuiToggleButton-root.Mui-selected': {
                      backgroundColor: '#6fd74b', // Active background color
                      color: '#fff', // Active text color
                      fontWeight: 'bolder' // Active font
                    },
                    '& .MuiToggleButton-root.Mui-selected:hover': {
                      backgroundColor: '#5ac235', // Hover background color
                      color: '#fff' // Hover text color
                    },
                    '& .MuiToggleButton-root': {
                      border: '1px solid #4C9B3D',
                      borderRadius: '2px'
                    }
                  }}
                >
                  <ToggleButtonGroup value={associations} onChange={handleFormat} aria-label="text formatting">
                    {associationsArray &&
                      associationsArray.length &&
                      associationsArray.map((association) => (
                        <ToggleButton key={association} value={association}>
                          {addSpacesToCamelCase(association)}
                        </ToggleButton>
                      ))}
                  </ToggleButtonGroup>
                  {touched.associations && errors.associations && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                      sx={{
                        fontSize: '13.8px'
                      }}
                    >
                      {errors.associations}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
            <Box sx={{ mt: 5, display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
              <Box sx={{ display: 'inline-block' }}>
                <AnimateButton>
                  <Button
                    disableElevation
                    // disabled={isLoadingTicket}
                    size="large"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    style={{
                      color: 'white',
                      minWidth: '200px',
                      margin: '0px auto'
                    }}
                  >
                    {isLoadingTicket ? (
                      <>
                        <CircularProgress
                          sx={{
                            color: 'white',
                            height: 20,
                            width: 20
                          }}
                        />
                      </>
                    ) : (
                      `${btnName} Ticket Category`
                    )}
                  </Button>
                </AnimateButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Form>
    </>
  );
};

export default TicketForm;
