import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { endpoints } from 'endpoints/endpoints';
import axiosInstance from 'utils/axiosInstance';

// export const useGetNotificationList = ({ limit }) => {
//   return useInfiniteQuery({
//     queryKey: ['getNotificationData', limit],
//     queryFn: async ({ pageParam = 1 }) => {
//       // console.log('Fetching page:', pageParam);
//       return await axiosInstance
//         .get(`${endpoints.notifications}/${pageParam}/${limit}`)
//         .then((res) => {
//           console.log('🚀 ~ .then ~ res:', res);
//           if (res?.status == 200) {
//             return res?.data;
//           } else {
//             return [];
//           }
//         })
//         .catch((e) => {
//           console.log(e);
//         });
//       //   return response?.data || [];
//     },
//     getNextPageParam: (lastPage, allPages) => {
//       if (lastPage?.length === 0) return undefined; // Stop fetching if no more data
//       return allPages.length + 1; // Use array length as page count
//     },
//     cacheTime: 0,
//     staleTime: 0
//   });
// };

// export const useGetNotificationList = ({ limit }) => {
//   return useInfiniteQuery({
//     queryKey: ['getNotificationData', limit],
//     queryFn: async ({ pageParam = 1 }) => {
//       try {
//         const response = await axiosInstance.get(`${endpoints.notifications}/${pageParam}/${limit}`);
//         console.log('🚀 ~ Fetching data ~ response:', response);
//         return response?.status === 200 ? response.data : [];
//       } catch (error) {
//         console.error('Error fetching data:', error);
//         return [];
//       }
//     },
//     getNextPageParam: (lastPage, allPages) => {
//       return lastPage?.length === 0 ? undefined : allPages.length + 1;
//     },
//     cacheTime: 0,
//     staleTime: 0
//   });
// };

export const useGetNotificationList = ({ limit }) => {
  return useInfiniteQuery({
    queryKey: ['getNotificationData', limit], // Unique query key per `limit`
    queryFn: async ({ pageParam = 1 }) => {
      try {
        // Fetch notifications from the backend with `pageParam` and `limit`
        const response = await axiosInstance.get(`${endpoints.notifications}/${pageParam}/${limit}`);
        // console.log('🚀 ~ Fetching data ~ response:', response);

        // Return notifications if the response is valid, else return an empty array
        return response?.status === 200 ? response.data : [];
      } catch (error) {
        // console.error('Error fetching data:', error);
        return []; // Return empty array if there's an error
      }
    },
    getNextPageParam: (lastPage, allPages) => {
      // console.log('🚀 ~ useGetNotificationList ~ lastPage:', lastPage);
      // console.log('🚀 ~ useGetNotificationList ~ allPages:', allPages);
      // If the last page is empty, no next page exists
      const isLastPage = lastPage?.notifications?.length === 0;
      return isLastPage ? undefined : allPages.length + 1; // If no more data, return `undefined`
    },
    cacheTime: 0,
    staleTime: 0,
    enabled: Boolean(limit) && Boolean(localStorage.getItem('userJwtToken'))
  });
};

//// Without indefinite Query
export const useGetNotificationAllList = ({ limit, pageParam, open }) => {
  // console.log('🚀 ~ useGetNotificationAllList ~ limit, page:', limit, pageParam);
  return useQuery({
    queryKey: ['getNotificationAllData', limit, pageParam],
    queryFn: async () => {
      return await axiosInstance
        .get(`${endpoints.notifications}/${pageParam}/${limit}`)
        .then((res) => {
          if (res?.status == 200) {
            return res?.data;
          } else {
            return [];
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    cacheTime: 0, // This prevents React Query from caching the data
    staleTime: 0, // This ensures that the data is always considered "stale" and refetched on each request
    onSuccess: ({ data }) => {
      return data;
    },
    enabled: Boolean(limit) && Boolean(pageParam) && open
  });
};
