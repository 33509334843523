/* eslint-disable react/prop-types */
/* eslint-disable */
import {
  Autocomplete,
  Avatar,
  Badge,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { Form } from 'formik';
import React from 'react';
import { gridSpacing } from 'store/constant';
import { useTheme } from '@mui/material/styles';
import AnimateButton from 'ui-component/extended/AnimateButton';
import DocumentUploadEmployee from '../files/uploader';
import { useSelector } from 'react-redux';
import { Payment, People as PeopleIcon } from '@mui/icons-material';
// import UpgradeIcon from '@mui/icons-material/Upgrade';
import NextPlanIcon from '@mui/icons-material/NextPlan';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useState } from 'react';
import PricingTableDialog from '../subcription/PricingTableDialog';
import { useGetAllOrganizationList } from 'hooks/useOrganizationHooks';
import { useGetAllMachineAssignmentsList, useGetAllMachineNewList } from 'hooks/useMachineHooks';
import { useEffect } from 'react';
import { useGetProfileById } from 'hooks/useProfileHooks';
import { useGetGlobalUserCount } from 'hooks/useEmployeesHooks';
import FormBodyView from 'ui-component/cards/Skeleton/FormBodyView';
import MachineSubscription from '../subcription/MachineSubscription';
import { addSpacesToCamelCase, handlePhoneChange } from 'utils/commonUtil';
import { useFocusOnError } from 'hooks/useFocusOnError';
import SyncIcon from '@mui/icons-material/Sync';
import MachineModalDialog from 'ui-component/machine-add-dialog/MachineModalDialog';
import EmployeeSubscription from 'views/pages/machines/subcriptionModal/EmployeeSubscription';
import { useNavigate } from 'react-router';

// import { FileUpload } from '@mui/icons-material';
const EmployeeForm = ({
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  isSubmitting,
  touched,
  values,
  setFieldValue,
  permissionsAddEdit,
  isAdd = false,
  isLoadingAddEmployee,
  btnName,
  openDialog,
  setOpenDialog,
  employeeDetails,
  isLoadingEmployee
  // inputRefs
  //   setValues,
  //   setTouched
}) => {
  const theme = useTheme();
  const navigator = useNavigate();
  const { setFieldRef } = useFocusOnError(errors, touched, isSubmitting); // Initialize the custom hook

  const orgType = useSelector((state) => state.customization.orgType);

  const [allocationMachineValidCount, setAllocationMachineValidCount] = React.useState(0);

  const [openViewModal, setOpenViewModal] = useState({
    open: false,
    machineId: null,
    openSubscription: false,
    machineSubFromEmployee: null
  });

  useEffect(() => {
    if (!isLoadingEmployee && employeeDetails) {
      const finalOrgType = employeeDetails?.organizationTypes ? employeeDetails.organizationTypes.map(addSpacesToCamelCase) : [];
      const isAdmin =
        employeeDetails?.types &&
        Array.isArray(employeeDetails?.types) &&
        employeeDetails?.types.length &&
        employeeDetails?.types[0] === 'Admin'
          ? true
          : false;
      // console.log('Employee ~ Edit ~ employeeDetails - finalOrgType', finalOrgType, 'isAdmin', isAdmin);
      // Set basic fields
      setFieldValue('prefix', employeeDetails?.prefix || '');
      setFieldValue('firstName', employeeDetails?.firstName || '');
      setFieldValue('lastName', employeeDetails?.lastName || '');
      setFieldValue('title', employeeDetails?.title || '');
      setFieldValue('email', employeeDetails?.email || '');
      setFieldValue('phone', employeeDetails?.phone || '');
      setFieldValue('address', employeeDetails?.address || '');
      setFieldValue('isAdmin', isAdmin);

      // Set authentication info
      setFieldValue('rfid', employeeDetails?.authenticationInfo?.rfidCode || '');
      setFieldValue('authPin', employeeDetails?.authenticationInfo?.authPIN || '');

      // Set global access and organization types
      setFieldValue('hasGlobalAccess', employeeDetails?.hasGlobalAccess || false);
      setFieldValue('org_type', finalOrgType || []);

      // Set the createdByOrgType
      setFieldValue('createdByOrgType', employeeDetails?.createdByOrgType || '');

      // Set assigned organizations and machines
      setFieldValue('assign_org', employeeDetails?.assignedOrganizations || []);
      // setFieldValue('assign_machine', employeeDetails?.assignedMachines || []);
      setFieldValue('assign_machine', []);
      setFieldValue('allocate_machine', employeeDetails?.allocatedMachines || []);

      // Optional: If you need to set the profileImage, you can use:
      setFieldValue('profileImage', employeeDetails?.image || '');
    }
  }, [employeeDetails, isLoadingEmployee]);

  // const [openDialog, setOpenDialog] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const handleOpenDialog = (machine, machineSpecificUser) => {
    const transformedData = {
      id: machine?.id || '',
      machineName: machine?.machineName || 'N.A.',
      nickname: machine?.nickName || 'N.A.',
      locationName: machine?.location?.name || 'N.A.',
      location: machine?.machineLoc || 'N.A.',
      serialNumber: machine?.serialNumber || 'N.A.',
      status: machine?.status || '',
      subscription: machine?.subscription
        ? {
            type: machine?.subscription?.title || 'N.A.',
            id: machine?.subscription?.id || null,
            price: `$${machine?.subscription?.price?.amount} / ${machine.subscription.price.interval}`,
            amount: machine?.subscription?.price?.amount || 0,
            limits: {
              maxLocalUsers: machine?.subscription?.noUsers?.machineSpecificUser || 0,
              maxGlobalUsers: machine?.subscription?.noUsers?.globalUser || 0,
              maxAdvertisements: machine?.subscription?.noUsers?.noOfAdvertisements || 0 // Assuming no advertisements in the machineObj
            },
            current: {
              localUsersAdded: machine?.totalLocalUsers || 0,
              // globalUsersAdded: machine?.totalGlobalUsers || 0,
              advertisementsAdded: machine?.totalAdvertisements || 0
            }
          }
        : null
    };
    /*setOpenDialog((prev) => ({
      ...prev,
      show: true,
      status: machineSpecificUser === undefined ? 1 : 0,
      isLocalUser: true,
      machineObj: transformedData
    }));*/ // Previous Modal Code
    // setOpenViewModal((state) => ({ ...state, open: true, machineId: machine?.id })); // Machine Modal Component Code
    //http://localhost:3000/dashboard/machines?viewMachineID=67e0e801b2e3724b4f875ab7
    // navigator('/dashboard/machines?viewMachineID=' + machine?.id);
    window.open('/dashboard/machines?viewMachineID=' + machine?.id + '&viewSub=true', '_blank', 'noopener');
  };
  const handleCloseDialog = () => setOpenDialog((prev) => ({ ...prev, show: false, isLocalUser: false, machineObj: null }));

  const { data: assignOrgTypeData, isLoading: isLoadingProfile } = useGetProfileById();
  const { data: getGlobalUserCount, isLoading: isLoadingGlobalUserCount } = useGetGlobalUserCount(orgType === 'Enterprise' ? true : false);

  useEffect(() => {
    if (orgType) {
      if (!employeeDetails) {
        setFieldValue('org_type', [orgType]);
        setFieldValue('createdByOrgType', orgType);
      }
    }
  }, [orgType]);
  useEffect(() => {
    if (values?.hasGlobalAccess) {
      // When global access is true, set all permissions to true
      setFieldValue('assign_machine', []);
      setFieldValue('allocate_machine', []);
      setAllocationMachineValidCount(0);
    }
  }, [values?.hasGlobalAccess]);

  // const permissionsAddEditShow =
  //   permissionsAddEdit && Array.isArray(permissionsAddEdit) && permissionsAddEdit.length
  //     ? permissionsAddEdit?.filter((item) => item.show === true).map((item) => item.id)
  //     : [];

  /*const transformedPermissionsAddEdit =
    permissionsAddEdit && Array.isArray(permissionsAddEdit) && permissionsAddEdit.length
      ? permissionsAddEdit?.reduce((acc, item) => {
          if (item.show) {
            // Only add items where item.show is true
            acc[item.id] = item;
          }
          return acc;
        }, {})
      : [];*/

  // State to hold the transformed permissions
  const [transformedPermissionsAddEdit, setTransformedPermissionsAddEdit] = useState({});

  useEffect(() => {
    if (permissionsAddEdit && Array.isArray(permissionsAddEdit) && permissionsAddEdit.length) {
      // Perform the transformation
      const transformed = permissionsAddEdit.reduce((acc, item) => {
        if (item.show) {
          // Only add items where item.show is true
          acc[item.id] = item;
        }
        return acc;
      }, {});

      // Set the transformed permissions into state
      setTransformedPermissionsAddEdit(transformed);
    } else {
      // If permissionsAddEdit is not valid, clear the state
      setTransformedPermissionsAddEdit([]);
    }
  }, [permissionsAddEdit]); // Re-run effect when permissionsAddEdit changes

  // console.log('Employee permissionsAddShow - transformedPermissionsAdd : ', permissionsAddEdit, transformedPermissionsAddEdit, isAdd);

  // { filters: { onlyBuyers: true } }
  const { data: assignOrgData, isLoading: orgListIsLoading } = useGetAllOrganizationList(
    {},
    transformedPermissionsAddEdit['empAssignOrg'] ? true : false
  );
  // console.log('assignOrgData ~ Employee :', assignOrgData?.data);

  const {
    data: assignMachineData,
    isLoading: machineListIsLoading,
    refetch: refetchAssignMachine,
    isRefetching: isRefetchingAssignMachine
  } = useGetAllMachineAssignmentsList(
    transformedPermissionsAddEdit['empAssignMachines'] || transformedPermissionsAddEdit['empAllocateMachine'] ? true : false,
    searchTerm
  );

  const handleSearchChange = (event, newInputValue) => {
    setSearchTerm(newInputValue);
  };

  // console.log('assignMachineData_1 ~ Employee', assignMachineData);

  {
    /*const handleMachineChange = (event, newValue) => {
    const addMachineTypeKey = newValue.map((item) => ({
      ...item, // Spread the existing properties
      machineUserType: '' // Add the new key with its value
    }));
    // newValue && Array.isArray(newValue) && newValue.length > 0 ? { ...newValue[0], machineUserType: '' } : newValue;
    // console.log('handleMachineChange - newValue : ', newValue, addMachineTypeKey);
    // Update the assignMachine array
    // handleChange({ target: { name: 'assignMachine', value: { ...newValue } } });
    setFieldValue('assign_machine', addMachineTypeKey);
    // Ensure all machines have a user type
    const updatedMachineUserType = { ...values.machineUserType };
    newValue.forEach((machine) => {
      if (!updatedMachineUserType[machine.id]) {
        updatedMachineUserType[machine.id] = { isLocal: false, isGlobal: false, machineTypeUser: '' }; // Default to Local User
      }
    });
    // Iterate over all machineUserTypes and remove those that are no longer selected
    Object.keys(updatedMachineUserType).forEach((key) => {
      if (!newValue.find((machine) => machine.id === key)) {
        delete updatedMachineUserType[key];
      }
    });
    console.log('updatedMachineUserType OnChange : ', updatedMachineUserType);
    handleChange({ target: { name: 'machineUserType', value: updatedMachineUserType } });
  };*/
  }

  const onDeleteAssignMachine = (id, onDelete) => {
    onDelete();
    const updatedMachineUserType = { ...values.machineUserType };
    delete updatedMachineUserType[id];
    // console.log('updatedMachineUserType', updatedMachineUserType);
    // handleChange({ target: { name: 'assignMachine', value: updatedMachineUserType } });
    handleChange({ target: { name: 'machineUserType', value: updatedMachineUserType } });
  };

  const handelKeyUpMachine = (e) => {
    // console.log('handelKeyUpMachine', e, e.target.value);
    if (e.target.value) setSearchTerm(e.target.value);
  };

  const handleUserTypeChange = (machineId, userType) => {
    /* const updatedMachineUserType = values.assignMachine?.map((machine) => {
    if (machine.id === machineId) {
      machine.machineUserType = userType;
      // return true;
    }
    return machine;
  });*/
    values.machineUserType[machineId] = userType;
    // handleChange({ target: { name: 'assignMachine', value: updatedMachineUserType } });
    handleChange({ target: { name: 'machineUserType', value: values.machineUserType } });
  };
  let machineUserTypeObj = {};
  if (errors.machineUserType) {
    machineUserTypeObj = JSON.parse(errors.machineUserType);
  }
  // console.log('machineUserTypeObj', machineUserTypeObj);
  const [localUserList, setLocalUserList] = useState([]);
  // let localUserList = [];
  useEffect(() => {
    if (transformedPermissionsAddEdit) {
      /*if (
        transformedPermissionsAddEdit['empAssignMachines'] &&
        typeof transformedPermissionsAddEdit['empAssignMachines'] === 'object' &&
        transformedPermissionsAddEdit['empAssignMachines'] !== null &&
        Object.keys(transformedPermissionsAddEdit['empAssignMachines']).length > 0
      ) {
        // console.log('Employee ~ localUserList - Assign : ', values.assign_machine);
        setLocalUserList(values?.assign_machine);
        // localUserList = values.assign_machine;
      }*/
      if (
        transformedPermissionsAddEdit['empAllocateMachine'] &&
        typeof transformedPermissionsAddEdit['empAllocateMachine'] === 'object' &&
        transformedPermissionsAddEdit['empAllocateMachine'] !== null &&
        Object.keys(transformedPermissionsAddEdit['empAllocateMachine']).length > 0
      ) {
        // console.log('Employee ~ localUserList - allocate : ', values.allocate_machine);
        setLocalUserList(values?.allocate_machine);
        // localUserList = values.allocate_machine;
      }
    }

    if (values.allocate_machine && Array.isArray(values.allocate_machine) && values?.allocate_machine?.length) {
      const invalidMachinesSubscriptionCount = values?.allocate_machine?.filter(
        (item) => item.subStatus === null || (item.subStatus === 'ACTIVE' && item.remainingLocalCount === 0) || item.subStatus !== 'ACTIVE'
      ).length;
      setAllocationMachineValidCount(invalidMachinesSubscriptionCount);
    }
  }, [values.allocate_machine]);
  // }, [values.assign_machine, values.allocate_machine]);
  // console.log('allocationMachineValidCount', allocationMachineValidCount);
  useEffect(() => {
    // debugger;
    if (
      !isRefetchingAssignMachine &&
      assignMachineData &&
      assignMachineData?.data &&
      Array.isArray(assignMachineData.data) &&
      assignMachineData.data.length
    ) {
      // Step 1: Update localUserList based on matching machine IDs
      const updatedUserList = localUserList.map((userMachine) => {
        const updatedMachine = assignMachineData?.data?.find((machine) => machine?.id === userMachine?.id);

        // If machine is found in assignMachineData, update it
        if (updatedMachine) {
          return { ...userMachine, ...updatedMachine }; // Update with the new data
        }

        return userMachine; // Otherwise, keep the existing machine
      });

      // Step 2: Set the updated localUserList to the field value
      setFieldValue('allocate_machine', updatedUserList);
    }

    //
    if (employeeDetails && assignMachineData && !isLoadingEmployee) {
      if (employeeDetails?.allocatedMachines) {
        // Update the localUserList with the allocatedMachines
        const updatedUserList = employeeDetails?.allocatedMachines.map((userMachine) => {
          const updatedMachine = assignMachineData?.data?.find((machine) => machine.id === userMachine.id);
          // If machine is found in assignMachineData, update it
          if (updatedMachine) {
            return { ...userMachine, ...updatedMachine }; // Update with the new data
          }

          return userMachine; // Otherwise, keep the existing machine
        });

        // Step 2: Set the updated localUserList to the field value
        setFieldValue('allocate_machine', updatedUserList);
      }

      // if (values?.assign_machine && Array.isArray(values?.assign_machine) && values?.assign_machine.length) {
      if (
        employeeDetails?.assignedMachines &&
        Array.isArray(employeeDetails?.assignedMachines) &&
        employeeDetails?.assignedMachines.length
      ) {
        const updatedAssignMachineList = employeeDetails?.assignedMachines?.map((userMachine) => {
          const updatedMachine = assignMachineData?.data?.find((machine) => machine?.id === userMachine?.id);

          // If machine is found in assignMachineData, update it
          if (updatedMachine) {
            return { ...userMachine, ...updatedMachine }; // Update with the new data
          }
          // console.log('updatedMachine assignMachine : ', updatedMachine, ' | userMachine : ', userMachine);
          return userMachine; // Otherwise, keep the existing machine
        });
        setFieldValue('assign_machine', updatedAssignMachineList);
      }
    }
  }, [assignMachineData, employeeDetails]); // Trigger this effect when assignMachineData changes

  /*console.log(
    'Employee ~ localUserList',
    localUserList,
    ' | allocate_machine : ',
    values?.allocate_machine,
    ' | assign_machine : ',
    values?.assign_machine,
    '| empAllocateMachines :',
    transformedPermissionsAddEdit['empAllocateMachine'],
    ' | empAssignMachines : ',
    transformedPermissionsAddEdit['empAssignMachines'],
    ' | transformedPermissionsAddEdit : ',
    transformedPermissionsAddEdit
  );*/
  // console.log('transformedPermissionsAddEdit Employee Add : ', transformedPermissionsAddEdit);
  return (
    <>
      <Form
        noValidate
        onSubmit={handleSubmit}
        // onSubmit={(e) => {
        //   e.preventDefault();
        //   handleSubmit(e);
        // }}
      >
        <Grid container spacing={gridSpacing} sx={{ alignContent: 'center' }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid
              container
              spacing={gridSpacing}
              sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row', md: 'row', lg: 'row', xl: 'row' } }}
            >
              <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                <Typography variant="h2" component={'span'}>
                  Employee Details
                </Typography>
              </Grid>
              {/* Is Admin  */}
              {transformedPermissionsAddEdit['empIsAdmin'] && transformedPermissionsAddEdit['empIsAdmin']?.show && (
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <FormGroup row sx={{ border: '2px solid rgb(111, 215, 75)', paddingLeft: '18px', borderRadius: '10px' }}>
                    <FormControlLabel
                      control={
                        <Switch
                          name="isAdmin"
                          defaultChecked={values?.isAdmin}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={isAdd ? false : transformedPermissionsAddEdit['empIsAdmin']?.isStatic}
                        />
                      }
                      label={
                        <Typography variant="h5" component={'span'} sx={{ fontWeight: '600' }}>
                          {transformedPermissionsAddEdit['empIsAdmin']?.label}{' '}
                          {transformedPermissionsAddEdit['empIsAdmin']?.isRequired ? ' *' : ''}
                        </Typography>
                      }
                    />
                  </FormGroup>
                </Grid>
              )}
            </Grid>
          </Grid>
          {!transformedPermissionsAddEdit || Object.keys(transformedPermissionsAddEdit).length === 0 ? (
            <>
              <FormBodyView />
            </>
          ) : (
            <>
              <Grid item xs={12}>
                {/* <Typography component="h4" variant="h2" sx={{ mb: 2 }}>
              Employee Details
            </Typography> */}
                <Grid container spacing={gridSpacing}>
                  {/* Prefix  */}
                  {transformedPermissionsAddEdit['empPrefix'] && transformedPermissionsAddEdit['empPrefix']?.show && (
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <FormControl
                        fullWidth={true}
                        error={Boolean(touched.prefix && errors.prefix)}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-prefix" id="retailer-primary-title">
                          {' '}
                          Choose {transformedPermissionsAddEdit['empPrefix']?.label}{' '}
                          {transformedPermissionsAddEdit['empPrefix']?.isRequired ? ' *' : ''}
                        </InputLabel>

                        <Select
                          label={`Choose ${transformedPermissionsAddEdit['empPrefix']?.label} ${
                            transformedPermissionsAddEdit['empPrefix']?.isRequired ? ' *' : ''
                          }`}
                          placeholder={`Choose ${transformedPermissionsAddEdit['empPrefix']?.label} ${
                            transformedPermissionsAddEdit['empPrefix']?.isRequired ? ' *' : ''
                          }`}
                          value={values.prefix}
                          onChange={handleChange}
                          name="prefix"
                          inputProps={{ 'aria-label': 'Without label' }}
                          // inputRef={(el) => (inputRefs.current['prefix'] = el)}
                          inputRef={(el) => setFieldRef('prefix', el)}
                          labelId="retailer-primary-title-label"
                          disabled={isAdd ? false : transformedPermissionsAddEdit['empPrefix']?.isStatic}
                        >
                          <MenuItem value={'Mr.'}>Mr.</MenuItem>
                          <MenuItem value={'Mrs.'}>Mrs.</MenuItem>
                        </Select>

                        {touched.prefix && errors.prefix && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-email-login"
                            sx={{
                              fontSize: '13.8px'
                            }}
                          >
                            {errors.prefix}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  )}

                  {/* First Name  */}
                  {transformedPermissionsAddEdit['empFirstName'] && transformedPermissionsAddEdit['empFirstName']?.show && (
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <FormControl
                        fullWidth={true}
                        error={Boolean(touched.firstName && errors.firstName)}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-email-login">
                          {' '}
                          {transformedPermissionsAddEdit['empFirstName']?.label}{' '}
                          {transformedPermissionsAddEdit['empFirstName']?.isRequired ? ' *' : ''}
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-email-login"
                          type="text"
                          value={values.firstName}
                          name="firstName"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label={`${transformedPermissionsAddEdit['empFirstName']?.label} ${
                            transformedPermissionsAddEdit['empFirstName']?.isRequired ? ' *' : ''
                          }`}
                          placeholder={`${transformedPermissionsAddEdit['empFirstName']?.label} ${
                            transformedPermissionsAddEdit['empFirstName']?.isRequired ? ' *' : ''
                          }`}
                          inputProps={{}}
                          // inputRef={(el) => (inputRefs.current['firstName'] = el)}
                          inputRef={(el) => setFieldRef('firstName', el)}
                          autoComplete="off"
                          disabled={isAdd ? false : transformedPermissionsAddEdit['empFirstName']?.isStatic}
                        />
                        {touched.firstName && errors.firstName && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-email-login"
                            sx={{
                              fontSize: '13.8px'
                            }}
                          >
                            {errors.firstName}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  )}

                  {/* Last Name  */}
                  {transformedPermissionsAddEdit['empLastName'] && transformedPermissionsAddEdit['empLastName']?.show && (
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <FormControl
                        fullWidth={true}
                        error={Boolean(touched.lastName && errors.lastName)}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-email-login">
                          {' '}
                          {transformedPermissionsAddEdit['empLastName']?.label}{' '}
                          {transformedPermissionsAddEdit['empLastName']?.isRequired ? ' *' : ''}
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-email-login"
                          type="text"
                          value={values.lastName}
                          name="lastName"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label={`Choose ${transformedPermissionsAddEdit['empLastName']?.label} ${
                            transformedPermissionsAddEdit['empLastName']?.isRequired ? ' *' : ''
                          }`}
                          placeholder="Doe"
                          inputProps={{}}
                          // inputRef={(el) => (inputRefs.current['lastName'] = el)}
                          inputRef={(el) => setFieldRef('lastName', el)}
                          autoComplete="off"
                          disabled={isAdd ? false : transformedPermissionsAddEdit['empLastName']?.isStatic}
                        />
                        {touched.lastName && errors.lastName && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-email-login"
                            sx={{
                              fontSize: '13.8px'
                            }}
                          >
                            {errors.lastName}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  )}

                  {/* Title */}
                  {transformedPermissionsAddEdit['empTitle'] && transformedPermissionsAddEdit['empTitle']?.show && (
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <FormControl fullWidth={true} error={Boolean(touched.title && errors.title)} sx={{ ...theme.typography.customInput }}>
                        <InputLabel htmlFor="outlined-adornment-email-login">
                          {' '}
                          {transformedPermissionsAddEdit['empTitle']?.label}{' '}
                          {transformedPermissionsAddEdit['empTitle']?.isRequired ? ' *' : ''}
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-email-login"
                          type="text"
                          value={values.title}
                          name="title"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label={`${transformedPermissionsAddEdit['empTitle']?.label} ${
                            transformedPermissionsAddEdit['empTitle']?.isRequired ? ' *' : ''
                          }`}
                          placeholder="manager"
                          inputProps={{}}
                          // inputRef={(el) => (inputRefs.current['title'] = el)}
                          inputRef={(el) => setFieldRef('title', el)}
                          autoComplete="off"
                          disabled={isAdd ? false : transformedPermissionsAddEdit['empTitle']?.isStatic}
                        />
                        {touched.title && errors.title && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-email-login"
                            sx={{
                              fontSize: '13.8px'
                            }}
                          >
                            {errors.title}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  )}

                  {/* Email */}
                  {transformedPermissionsAddEdit['empEmail'] && transformedPermissionsAddEdit['empEmail']?.show && (
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <FormControl fullWidth={true} error={Boolean(touched.email && errors.email)} sx={{ ...theme.typography.customInput }}>
                        <InputLabel htmlFor="outlined-adornment-email-login">
                          {' '}
                          {transformedPermissionsAddEdit['empEmail']?.label}{' '}
                          {transformedPermissionsAddEdit['empEmail']?.isRequired ? ' *' : ''}
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-email-login"
                          type="text"
                          value={values.email}
                          name="email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label={`${transformedPermissionsAddEdit['empEmail']?.label} ${
                            transformedPermissionsAddEdit['empEmail']?.isRequired ? ' *' : ''
                          }`}
                          placeholder={`${transformedPermissionsAddEdit['empEmail']?.label} ${
                            transformedPermissionsAddEdit['empEmail']?.isRequired ? ' *' : ''
                          }`}
                          inputProps={{}}
                          // inputRef={(el) => (inputRefs.current['email'] = el)}
                          inputRef={(el) => setFieldRef('email', el)}
                          autoComplete="off"
                          disabled={isAdd ? false : transformedPermissionsAddEdit['empEmail']?.isStatic}
                        />
                        {touched.email && errors.email && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-email-login"
                            sx={{
                              fontSize: '13.8px'
                            }}
                          >
                            {errors.email}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  )}

                  {/* Phone */}
                  {transformedPermissionsAddEdit['empPhone'] && transformedPermissionsAddEdit['empPhone']?.show && (
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <FormControl fullWidth={true} error={Boolean(touched.phone && errors.phone)} sx={{ ...theme.typography.customInput }}>
                        <InputLabel htmlFor="outlined-adornment-email-login">
                          {' '}
                          {transformedPermissionsAddEdit['empPhone']?.label}{' '}
                          {/* {transformedPermissionsAddEdit['empPhone']?.isRequired ? ' *' : ''} */}
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-email-login"
                          type="text"
                          value={values.phone}
                          name="phone"
                          onBlur={handleBlur}
                          // onChange={handleChange}
                          onChange={(e) => handlePhoneChange(e, setFieldValue, 'phone')}
                          label={`${transformedPermissionsAddEdit['empPhone']?.label} ${
                            // transformedPermissionsAddEdit['empPhone']?.isRequired ? ' *' : ''
                            transformedPermissionsAddEdit['empPhone']?.isRequired && ''
                          }`}
                          placeholder={`${transformedPermissionsAddEdit['empPhone']?.label} (Eg. XXXX-XXX-XXXX) ${
                            // transformedPermissionsAddEdit['empPhone']?.isRequired ? ' *' : ''
                            transformedPermissionsAddEdit['empPhone']?.isRequired && ''
                          }`}
                          inputProps={{}}
                          // inputRef={(el) => (inputRefs.current['phone'] = el)}
                          inputRef={(el) => setFieldRef('phone', el)}
                          autoComplete="off"
                          disabled={isAdd ? false : transformedPermissionsAddEdit['empPhone']?.isStatic}
                        />
                        {touched.phone && errors.phone && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-email-login"
                            sx={{
                              fontSize: '13.8px'
                            }}
                          >
                            {errors.phone}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  )}

                  {/* Address */}
                  {transformedPermissionsAddEdit['empAddress'] && transformedPermissionsAddEdit['empAddress']?.show && (
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <FormControl
                        fullWidth={true}
                        error={Boolean(touched.address && errors.address)}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <InputLabel htmlFor="outlined-adornment-email-login">
                          {' '}
                          {transformedPermissionsAddEdit['empAddress']?.label}{' '}
                          {transformedPermissionsAddEdit['empAddress']?.isRequired ? ' *' : ''}{' '}
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-email-login"
                          type="text"
                          value={values.address}
                          name="address"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          label={`${transformedPermissionsAddEdit['empAddress']?.label} ${
                            transformedPermissionsAddEdit['empAddress']?.isRequired ? ' *' : ''
                          }`}
                          placeholder={`${transformedPermissionsAddEdit['empAddress']?.label} ${
                            transformedPermissionsAddEdit['empAddress']?.isRequired ? ' *' : ''
                          }`}
                          inputProps={{}}
                          // inputRef={(el) => (inputRefs.current['address'] = el)}
                          inputRef={(el) => setFieldRef('address', el)}
                          autoComplete="off"
                          disabled={isAdd ? false : transformedPermissionsAddEdit['empAddress']?.isStatic}
                        />
                        {touched.address && errors.address && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-email-login"
                            sx={{
                              fontSize: '13.8px'
                            }}
                          >
                            {errors.address}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  )}
                  {orgType && (orgType === 'Enterprise' || orgType === 'Super Admin') && (
                    <>
                      {orgType && orgType === 'Enterprise' && (
                        <>
                          {/* RFID  */}
                          {transformedPermissionsAddEdit['empRfid'] && transformedPermissionsAddEdit['empRfid']?.show && (
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                              <FormControl
                                fullWidth={true}
                                error={Boolean(touched.rfid && errors.rfid)}
                                sx={{ ...theme.typography.customInput }}
                              >
                                <InputLabel htmlFor="outlined-adornment-email-login">
                                  {' '}
                                  {transformedPermissionsAddEdit['empRfid']?.label}{' '}
                                  {transformedPermissionsAddEdit['empRfid']?.isRequired ? ' *' : ''}
                                </InputLabel>
                                <OutlinedInput
                                  id="outlined-adornment-email-login"
                                  type="text"
                                  value={values.rfid}
                                  name="rfid"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  label={`${transformedPermissionsAddEdit['empRfid']?.label} ${
                                    transformedPermissionsAddEdit['empRfid']?.isRequired ? ' *' : ''
                                  }`}
                                  placeholder={`${transformedPermissionsAddEdit['empRfid']?.label} ${
                                    transformedPermissionsAddEdit['empRfid']?.isRequired ? ' *' : ''
                                  }`}
                                  inputProps={{}}
                                  // inputRef={(el) => (inputRefs.current['rfid'] = el)}
                                  inputRef={(el) => setFieldRef('rfid', el)}
                                  autoComplete="off"
                                  disabled={isAdd ? false : transformedPermissionsAddEdit['empRfid']?.isStatic}
                                />
                                {touched.rfid && errors.rfid && (
                                  <FormHelperText
                                    error
                                    id="standard-weight-helper-text-email-login"
                                    sx={{
                                      fontSize: '13.8px'
                                    }}
                                  >
                                    {errors.rfid}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </Grid>
                          )}
                          {/* Auth Pin */}
                          {transformedPermissionsAddEdit['empAuthPin'] && transformedPermissionsAddEdit['empAuthPin'].show && (
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                              <FormControl
                                fullWidth={true}
                                error={Boolean(touched.authPin && errors.authPin)}
                                sx={{ ...theme.typography.customInput }}
                              >
                                <InputLabel htmlFor="outlined-adornment-email-login">
                                  {' '}
                                  {transformedPermissionsAddEdit['empAuthPin']?.label}{' '}
                                  {transformedPermissionsAddEdit['empAuthPin']?.isRequired ? ' *' : ''}
                                </InputLabel>
                                <OutlinedInput
                                  id="outlined-adornment-email-login"
                                  type="text"
                                  value={values.authPin}
                                  name="authPin"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  label={`${transformedPermissionsAddEdit['empAuthPin']?.label} ${
                                    transformedPermissionsAddEdit['empAuthPin']?.isRequired ? ' *' : ''
                                  }`}
                                  placeholder={`${transformedPermissionsAddEdit['empAuthPin']?.label} ${
                                    transformedPermissionsAddEdit['empAuthPin']?.isRequired ? ' *' : ''
                                  }`}
                                  inputProps={{}}
                                  // inputRef={(el) => (inputRefs.current['authPin'] = el)}
                                  inputRef={(el) => setFieldRef('authPin', el)}
                                  autoComplete="off"
                                  disabled={isAdd ? false : transformedPermissionsAddEdit['empAuthPin']?.isStatic}
                                />
                                {touched.authPin && errors.authPin && (
                                  <FormHelperText
                                    error
                                    id="standard-weight-helper-text-email-login"
                                    sx={{
                                      fontSize: '13.8px'
                                    }}
                                  >
                                    {errors.authPin}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </Grid>
                          )}
                        </>
                      )}
                      {orgType && orgType === 'Super Admin' && (
                        <>
                          {/* Installer RFID  */}
                          {transformedPermissionsAddEdit['empInstallerRfid'] && transformedPermissionsAddEdit['empInstallerRfid']?.show && (
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                              <FormControl
                                fullWidth={true}
                                error={Boolean(touched.installerRfid && errors.installerRfid)}
                                sx={{ ...theme.typography.customInput }}
                              >
                                <InputLabel htmlFor="outlined-adornment-installerRfid">
                                  {' '}
                                  {transformedPermissionsAddEdit['empInstallerRfid']?.label}{' '}
                                  {transformedPermissionsAddEdit['empInstallerRfid']?.isRequired ? ' *' : ''}
                                </InputLabel>
                                <OutlinedInput
                                  id="outlined-adornment-installerRfid"
                                  type="text"
                                  value={values.installerRfid}
                                  name="installerRfid"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  label={`${transformedPermissionsAddEdit['empInstallerRfid']?.label} ${
                                    transformedPermissionsAddEdit['empInstallerRfid']?.isRequired ? ' *' : ''
                                  }`}
                                  placeholder={`${transformedPermissionsAddEdit['empInstallerRfid']?.label} ${
                                    transformedPermissionsAddEdit['empInstallerRfid']?.isRequired ? ' *' : ''
                                  }`}
                                  inputProps={{}}
                                  // inputRef={(el) => (inputRefs.current['installerRfid'] = el)}
                                  inputRef={(el) => setFieldRef('installerRfid', el)}
                                  autoComplete="off"
                                  disabled={isAdd ? false : transformedPermissionsAddEdit['empInstallerRfid']?.isStatic}
                                />
                                {touched.installerRfid && errors.installerRfid && (
                                  <FormHelperText
                                    error
                                    id="standard-weight-helper-text-installerRfid"
                                    sx={{
                                      fontSize: '13.8px'
                                    }}
                                  >
                                    {errors.installerRfid}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </Grid>
                          )}
                          {/* Auth Pin */}
                          {transformedPermissionsAddEdit['empInstallerAuthPin'] &&
                            transformedPermissionsAddEdit['empInstallerAuthPin']?.show && (
                              <Grid item lg={4} md={4} sm={6} xs={12}>
                                <FormControl
                                  fullWidth={true}
                                  error={Boolean(touched.installerAuthPin && errors.installerAuthPin)}
                                  sx={{ ...theme.typography.customInput }}
                                >
                                  <InputLabel htmlFor="outlined-adornment-email-login">
                                    {' '}
                                    {transformedPermissionsAddEdit['empInstallerAuthPin']?.label}{' '}
                                    {transformedPermissionsAddEdit['empInstallerAuthPin']?.isRequired ? ' *' : ''}
                                  </InputLabel>
                                  <OutlinedInput
                                    id="outlined-adornment-email-login"
                                    type="text"
                                    value={values.installerAuthPin}
                                    name="installerAuthPin"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    label={`${transformedPermissionsAddEdit['empInstallerAuthPin']?.label} ${
                                      transformedPermissionsAddEdit['empInstallerAuthPin']?.isRequired ? ' *' : ''
                                    }`}
                                    placeholder={`${transformedPermissionsAddEdit['empInstallerAuthPin']?.label} ${
                                      transformedPermissionsAddEdit['empInstallerAuthPin']?.isRequired ? ' *' : ''
                                    }`}
                                    inputProps={{}}
                                    // inputRef={(el) => (inputRefs.current['installerAuthPin'] = el)}
                                    inputRef={(el) => setFieldRef('installerAuthPin', el)}
                                    autoComplete="off"
                                    disabled={isAdd ? false : transformedPermissionsAddEdit['empInstallerAuthPin']?.isStatic}
                                  />
                                  {touched.installerAuthPin && errors.installerAuthPin && (
                                    <FormHelperText
                                      error
                                      id="standard-weight-helper-text-email-login"
                                      sx={{
                                        fontSize: '13.8px'
                                      }}
                                    >
                                      {errors.installerAuthPin}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </Grid>
                            )}
                        </>
                      )}
                    </>
                  )}

                  {/* Profile Image  */}
                  {transformedPermissionsAddEdit['empProfilePic'] && transformedPermissionsAddEdit['empProfilePic']?.show && (
                    <Grid item xs={12} sm={12}>
                      <Grid>
                        <div style={{ padding: '10px 15px 15px' }} className="field-input-all">
                          <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
                            {transformedPermissionsAddEdit['empProfilePic']?.label}{' '}
                            {transformedPermissionsAddEdit['empProfilePic']?.isRequired ? ' *' : ''}
                          </Typography>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            style={{
                              marginTop: '15px'
                            }}
                          >
                            <DocumentUploadEmployee
                              {...{
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue
                                //   imageDetails
                              }}
                              disabled={isAdd ? false : transformedPermissionsAddEdit['empProfilePic']?.isStatic}
                            />
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>
                  )}

                  {/* Organization Type defaultChecked  */}
                  {transformedPermissionsAddEdit['empOrgType'] && transformedPermissionsAddEdit['empOrgType']?.show && (
                    <Grid item xs={12} sm={12} md={6} lg={6} className="select-job-enterprise select-job-enterprise-type">
                      <FormControl
                        fullWidth={true}
                        error={Boolean(touched.assign_org && errors.assign_org)}
                        sx={{ ...theme.typography.customInput }}
                      >
                        <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
                          {transformedPermissionsAddEdit['empOrgType']?.label}{' '}
                          {transformedPermissionsAddEdit['empOrgType']?.isRequired ? ' *' : ''}
                        </Typography>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            sx={{ border: '2px solid rgb(111, 215, 75)', padding: '0px 18px 0px 18px', borderRadius: '10px' }}
                          >
                            {!isLoadingProfile &&
                              assignOrgTypeData &&
                              Array.isArray(assignOrgTypeData.organizationTypes) &&
                              assignOrgTypeData?.organizationTypes?.length &&
                              assignOrgTypeData?.organizationTypes.map((oType, index) => (
                                <FormControlLabel
                                  key={index} // Consider using a unique ID if available instead of index
                                  control={
                                    <Checkbox
                                      name="org_type"
                                      checked={values.org_type.includes(oType)} // Check if oType is in the array
                                      value={oType} // Value to add/remove from array
                                      onChange={(e) => {
                                        const checked = e.target.checked;
                                        const newValue = checked
                                          ? [...values.org_type, oType] // Add to array
                                          : values.org_type.filter((item) => item !== oType); // Remove from array
                                        setFieldValue('org_type', newValue); // Use Formik's setFieldValue
                                      }}
                                      inputRef={(el) => {
                                        // Store ref for this specific checkbox
                                        // inputRefs.current[`org_type`] = el;
                                        // inputRefs.current[`org_type`] = el;
                                        setFieldRef('org_type', el);
                                      }}
                                    />
                                  }
                                  label={addSpacesToCamelCase(oType)}
                                />
                              ))}
                          </Grid>
                        </Grid>

                        {touched.org_type && errors.org_type && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-email-login"
                            sx={{
                              fontSize: '13.8px'
                            }}
                          >
                            {errors.org_type}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  )}

                  {/* Global User */}
                  {/* {orgType && orgType === 'Enterprise' && ( */}
                  {transformedPermissionsAddEdit['empGlobalUser'] && transformedPermissionsAddEdit['empGlobalUser']?.show && (
                    <>
                      <Grid item xs={12} sm={12} md={6} lg={6} className="select-job-enterprise select-job-enterprise-type">
                        <FormControl
                          fullWidth={true}
                          error={Boolean(touched.assign_org && errors.assign_org)}
                          sx={{ ...theme.typography.customInput }}
                        >
                          <Typography
                            variant="h6"
                            style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px', visibility: 'hidden' }}
                          >
                            {transformedPermissionsAddEdit['empAuthPin']?.label}{' '}
                            {transformedPermissionsAddEdit['empAuthPin']?.isRequired ? ' *' : ''}
                          </Typography>
                          <FormGroup sx={{ border: '2px solid rgb(111, 215, 75)', padding: '8px 18px 8px 18px', borderRadius: '10px' }}>
                            <FormControlLabel
                              required={false}
                              control={
                                <Switch
                                  name="hasGlobalAccess"
                                  checked={values?.hasGlobalAccess}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  disabled={isAdd ? false : transformedPermissionsAddEdit['empGlobalUser']?.isStatic}
                                />
                              }
                              label={
                                <div style={{ paddingTop: '5px' }}>
                                  <Typography
                                    variant="h6"
                                    component={'p'}
                                    sx={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}
                                  >
                                    Make This Employee "Global User"
                                  </Typography>
                                </div>
                              }
                            />
                          </FormGroup>
                          <Typography
                            variant="h6"
                            component={'span'}
                            sx={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px', paddingTop: '15px' }}
                          >
                            Available Global User Count :{' '}
                            {!isLoadingGlobalUserCount && getGlobalUserCount > 0 ? ' ' + getGlobalUserCount : '0'}
                            {/* <span>
                          <Tooltip title={`Global User : ${2}`} arrow>
                            <Badge
                              badgeContent={2} // Display user count, default to 0 if not available
                              color="secondary"
                              sx={{
                                marginLeft: 1,
                                '& .MuiBadge-dot': {
                                  width: 10,
                                  height: 10,
                                  borderRadius: '50%',
                                  backgroundColor: touched.hasGlobalAccess && errors.hasGlobalAccess ? 'red' : 'primary' // Change color based on your requirements
                                }
                              }}
                            >
                              <Avatar sx={{ width: 24, height: 24, backgroundColor: '#FCA311' }}>
                                <PeopleIcon sx={{ fontSize: 14, color: 'white' }} />
                              </Avatar>
                            </Badge>
                          </Tooltip>
                        </span> */}
                          </Typography>

                          <Typography
                            variant="h6"
                            component={'p'}
                            sx={{ fontSize: '12px', fontWeight: '600', paddingBottom: '5px', paddingTop: '5px', color: '#626262' }}
                          >
                            <span style={{ color: 'red' }}>**</span> Global User can use any machines from your Organization.
                          </Typography>
                          {touched.hasGlobalAccess && errors.hasGlobalAccess && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-email-login"
                              sx={{
                                fontSize: '13.8px'
                              }}
                            >
                              {errors.hasGlobalAccess}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </>
                  )}

                  {/* Assign Organization */}
                  {transformedPermissionsAddEdit['empAssignOrg'] && transformedPermissionsAddEdit['empAssignOrg']?.show && (
                    <>
                      <Grid item xs={12} sm={12} md={6} lg={6} className="select-job-enterprise select-job-enterprise-type">
                        <FormControl
                          fullWidth={true}
                          error={Boolean(touched.assign_org && errors.assign_org)}
                          sx={{ ...theme.typography.customInput }}
                        >
                          <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
                            {transformedPermissionsAddEdit['empAssignOrg']?.label}{' '}
                            {transformedPermissionsAddEdit['empAssignOrg']?.isRequired ? ' *' : ''}
                          </Typography>
                          <Autocomplete
                            multiple // Enable multiple selection
                            options={assignOrgData?.data || []} // Use static options
                            getOptionLabel={(option) => option.name ?? ''}
                            isOptionEqualToValue={(option, value) => option.id === value?.id}
                            value={values?.assign_org || []} // Ensure default is an array
                            onChange={(event, newValue) => handleChange({ target: { name: 'assign_org', value: newValue } })}
                            disabled={orgListIsLoading ? true : isAdd ? false : transformedPermissionsAddEdit['empAssignOrg']?.isStatic}
                            loading={orgListIsLoading}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => {
                                const { onDelete, ...tagProps } = getTagProps({ index });
                                return (
                                  <span
                                    key={option}
                                    {...tagProps}
                                    style={{
                                      backgroundColor: '#6fd74b',
                                      color: '#fff',
                                      padding: '2px 8px',
                                      borderRadius: '4px',
                                      marginRight: '5px',
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}
                                  >
                                    {option.name}
                                    <button
                                      type="button"
                                      onClick={onDelete}
                                      style={{
                                        marginLeft: '5px',
                                        background: 'none',
                                        border: 'none',
                                        color: '#fff',
                                        cursor: 'pointer'
                                      }}
                                    >
                                      ✖
                                    </button>
                                  </span>
                                );
                              })
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder={`Search and Select ${transformedPermissionsAddEdit['empAssignOrg']?.label?.replace(
                                  'Assign',
                                  ''
                                )} ${transformedPermissionsAddEdit['empAssignOrg']?.isRequired ? ' *' : ''}`}
                                disabled={isAdd ? false : transformedPermissionsAddEdit['empAssignOrg']?.isStatic}
                                // inputRef={(el) => (inputRefs.current['assign_org'] = el)}
                                inputRef={(el) => setFieldRef('assign_org', el)}
                                variant="outlined"
                                onBlur={handleBlur}
                                error={touched.assign_org && errors.assign_org}
                              />
                            )}
                          />
                          {touched.assign_org && errors.assign_org && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-email-login"
                              sx={{
                                fontSize: '13.8px'
                              }}
                            >
                              {errors.assign_org}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>

                      {/* assign_org List Table  */}
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TableContainer sx={{ maxHeight: 500, overflowY: 'auto' }}>
                          {values?.assign_org && Array.isArray(values?.assign_org) && values?.assign_org?.length > 0 && (
                            <Table
                              stickyHeader
                              aria-label="sticky table"
                              sx={{ ...theme.typography.customInput }}
                              className="new-table-classs new-table-class-employee"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell align="center" sx={{ width: '30%' }}>
                                    <TableSortLabel className="hover-color-white">Organization Name</TableSortLabel>
                                  </TableCell>

                                  <TableCell align="center">
                                    <TableSortLabel className="hover-color-white">Organization Location</TableSortLabel>
                                  </TableCell>
                                  <TableCell align="center" sx={{ width: '20%' }}>
                                    <TableSortLabel className="hover-color-white">Organization Type(s)</TableSortLabel>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {values?.assign_org?.map((row, i) => {
                                  // console.log('row - assign_machine', row);
                                  return (
                                    <TableRow
                                      hover
                                      role="checkbox"
                                      tabIndex={-1}
                                      key={row?.id || i}
                                      sx={{
                                        backgroundColor:
                                          machineUserTypeObj &&
                                          machineUserTypeObj[row?.id] &&
                                          touched.machineUserType &&
                                          touched?.machineUserType[row?.id]
                                            ? '#fbd8d8'
                                            : 'unset',
                                        '&:hover': {
                                          backgroundColor:
                                            machineUserTypeObj &&
                                            machineUserTypeObj[row?.id] &&
                                            touched.machineUserType &&
                                            touched?.machineUserType[row?.id]
                                              ? '#ffc8c8 !important'
                                              : 'initial'
                                        }
                                      }}
                                    >
                                      <TableCell align="left" sx={{ width: '20%' }}>
                                        <Typography variant="div" color="primary1">
                                          {row?.name || 'N.A.'}
                                        </Typography>
                                      </TableCell>
                                      <TableCell align="left" sx={{ paddingLeft: 2, paddingRight: 2 }}>
                                        {row.locations.map((r, index) => {
                                          const isLast = index === row.locations.length - 1;
                                          return (
                                            <>
                                              <div key={r?.id || index}>
                                                <p style={{ margin: 0 }}>
                                                  {/* <b>{r?.details?.name || (r?.details?.address?.fullAddress ? '' : 'N.A.')}</b> */}
                                                </p>
                                                {r?.details?.address?.fullAddress || 'N.A.'}{' '}
                                                {r?.isPrimary && (
                                                  <>
                                                    &nbsp;
                                                    <Chip
                                                      label={'Primary'}
                                                      sx={{ backgroundColor: '#6fd74b', color: '#fff' }}
                                                      size={'small'}
                                                    />
                                                  </>
                                                )}
                                                {/* Render Divider only if it's not the last element */}
                                                {!isLast && <Divider sx={{ marginBottom: 1, marginTop: 1 }} />}
                                              </div>
                                            </>
                                          );
                                        })}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        sx={{
                                          paddingLeft: 2,
                                          paddingRight: 2,
                                          width: '12%',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <Typography variant="div" color="primary1">
                                          {row?.types && Array.isArray(row?.types) && row?.types.length
                                            ? row?.types.map(addSpacesToCamelCase).join(', ')
                                            : ''}
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          )}
                        </TableContainer>
                      </Grid>
                    </>
                  )}

                  {/* Assign Machine */}
                  {transformedPermissionsAddEdit['empAssignMachines'] && transformedPermissionsAddEdit['empAssignMachines']?.show && (
                    <>
                      <Grid item xs={12} sm={12} md={6} lg={6} className="select-job-enterprise select-job-enterprise-type">
                        <FormControl
                          fullWidth={true}
                          error={Boolean(touched.assign_machine && errors.assign_machine)}
                          sx={{ ...theme.typography.customInput }}
                        >
                          <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
                            {`${transformedPermissionsAddEdit['empAssignMachines']?.label} ${
                              transformedPermissionsAddEdit['empAssignMachines']?.isRequired ? ' *' : ''
                            }`}
                          </Typography>

                          <Autocomplete
                            // freeSolo
                            multiple
                            options={assignMachineData?.data || []} // Use fetched data for options
                            // getOptionLabel={(option) => option.nickName || option.location.name || option.id}
                            getOptionLabel={(option) => {
                              // Combine location name, nickname, and model to make the label more descriptive
                              // return `${option.location?.name || ''} - ${option.nickName || ''} - ${option.id || ''}`;
                              return `${option.machineName || ''} - ${option.id || ''}`;
                            }}
                            isOptionEqualToValue={(option, value) => option.id === value?.id}
                            value={values?.assign_machine || []}
                            onChange={(event, newValue) => handleChange({ target: { name: 'assign_machine', value: newValue } })}
                            disabled={
                              values?.hasGlobalAccess ? true : isAdd ? false : transformedPermissionsAddEdit['empAssignMachines']?.isStatic
                            }
                            onInputChange={handleSearchChange} // Update search term as the user types
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => {
                                const { onDelete, ...tagProps } = getTagProps({ index });
                                return (
                                  <span
                                    key={option}
                                    {...tagProps}
                                    style={{
                                      backgroundColor: '#6fd74b',
                                      color: '#fff',
                                      padding: '2px 8px',
                                      borderRadius: '4px',
                                      marginRight: '5px',
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}
                                  >
                                    {/* {option.id} */}
                                    {option?.machineName}
                                    <button
                                      type="button"
                                      onClick={() => onDeleteAssignMachine(option?.id, onDelete)}
                                      style={{
                                        marginLeft: '5px',
                                        background: 'none',
                                        border: 'none',
                                        color: '#fff',
                                        cursor: 'pointer'
                                      }}
                                    >
                                      ✖
                                    </button>
                                  </span>
                                );
                              })
                            }
                            renderInput={(params) => (
                              <TextField
                                multiline
                                {...params}
                                placeholder={`Search and Select ${transformedPermissionsAddEdit['empAssignMachines']?.label?.replace(
                                  'Assign',
                                  ''
                                )} ${transformedPermissionsAddEdit['empAssignMachines']?.isRequired ? ' *' : ''}`}
                                disabled={
                                  values?.hasGlobalAccess
                                    ? true
                                    : isAdd
                                    ? false
                                    : transformedPermissionsAddEdit['empAssignMachines']?.isStatic
                                }
                                // inputRef={(el) => (inputRefs.current['assign_machine'] = el)}
                                inputRef={(el) => setFieldRef('assign_machine', el)}
                                variant="outlined"
                                onBlur={handleBlur}
                                onKeyUp={handelKeyUpMachine}
                                error={touched.assign_machine && errors.assign_machine}
                              />
                            )}
                            loading={machineListIsLoading} // Show loading spinner when fetching data
                            renderOption={(props, option) => (
                              <li {...props}>
                                {/* {option.id} {option?.nickName ? <b>{'(' + option.nickName + ')'}</b> : ''} */}
                                {/* {option.id} {option?.nickName ? ' (' + option.nickName + ')' : ''}{' '}
                              {option?.serialNumber ? (
                                <Chip
                                  label={option.serialNumber}
                                  color="primary"
                                  variant="outlined"
                                  size="small"
                                  sx={{ ml: 1, padding: '2px', fontWeight: 600 }}
                                />
                              ) : (
                                ''
                              )} */}
                                {option.machineName}
                              </li>
                            )}
                          />

                          {machineListIsLoading && (
                            <CircularProgress
                              size={24}
                              sx={{ position: 'absolute', top: '50%', left: '50%', marginTop: '-12px', marginLeft: '-12px' }}
                            />
                          )}

                          {touched.assign_machine && errors.assign_machine && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-email-login"
                              sx={{
                                fontSize: '13.8px'
                              }}
                            >
                              {errors.assign_machine}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>

                      {/* assign_machine List Table  */}
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TableContainer sx={{ maxHeight: 500, overflowY: 'auto' }}>
                          {values?.assign_machine && Array.isArray(values?.assign_machine) && values?.assign_machine?.length > 0 && (
                            <Table
                              stickyHeader
                              aria-label="sticky table"
                              sx={{ ...theme.typography.customInput }}
                              className="new-table-classs new-table-class-employee"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell align="center" sx={{ width: '20%' }}>
                                    <TableSortLabel className="hover-color-white">Machine Name</TableSortLabel>
                                  </TableCell>
                                  {orgType !== 'Enterprise' && (
                                    <TableCell align="center" sx={{ width: '20%' }}>
                                      <TableSortLabel className="hover-color-white">Client Name</TableSortLabel>
                                    </TableCell>
                                  )}

                                  <TableCell align="center">
                                    <TableSortLabel className="hover-color-white">Machine Location</TableSortLabel>
                                  </TableCell>
                                  <TableCell align="center" sx={{ width: '12%' }}>
                                    <TableSortLabel className="hover-color-white"> Machine Type</TableSortLabel>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {values?.assign_machine?.map((row, i) => {
                                  // console.log('row - assign_machine', row);
                                  return (
                                    <TableRow
                                      hover
                                      role="checkbox"
                                      tabIndex={-1}
                                      key={row?.id || i}
                                      sx={{
                                        backgroundColor:
                                          machineUserTypeObj &&
                                          machineUserTypeObj[row?.id] &&
                                          touched.machineUserType &&
                                          touched?.machineUserType[row?.id]
                                            ? '#fbd8d8'
                                            : 'unset',
                                        '&:hover': {
                                          backgroundColor:
                                            machineUserTypeObj &&
                                            machineUserTypeObj[row?.id] &&
                                            touched.machineUserType &&
                                            touched?.machineUserType[row?.id]
                                              ? '#ffc8c8 !important'
                                              : 'initial'
                                        }
                                      }}
                                    >
                                      <TableCell align="left" sx={{ width: '20%' }}>
                                        <Typography variant="div" color="primary1">
                                          {row?.machineName || 'N.A.'}
                                        </Typography>
                                      </TableCell>
                                      {orgType !== 'Enterprise' && (
                                        <TableCell align="left" sx={{ width: '20%' }}>
                                          <Typography variant="div" color="primary1">
                                            {row?.clientName || 'N.A.'}
                                          </Typography>
                                        </TableCell>
                                      )}
                                      <TableCell align="left" sx={{ paddingLeft: 2, paddingRight: 2 }}>
                                        <p style={{ margin: 0 }}>
                                          {/* <b>{row?.location?.name || (row?.location?.address?.fullAddress ? '' : 'N.A.')}</b> */}
                                          {row?.machineLoc || 'N.A.'}
                                        </p>
                                        {/* {row?.location?.address?.fullAddress || 'N.A.'} */}
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        sx={{
                                          paddingLeft: 2,
                                          paddingRight: 2,
                                          width: '12%',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <Typography variant="div" color="primary1">
                                          {row?.type || 'N.A.'}
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          )}
                        </TableContainer>
                      </Grid>
                    </>
                  )}

                  {/* Allocate Machine */}
                  {transformedPermissionsAddEdit['empAllocateMachine'] && transformedPermissionsAddEdit['empAllocateMachine']?.show && (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        className={`select-job-enterprise ${
                          values?.allocate_machine && values?.allocate_machine.length >= 2
                            ? 'select-employee-enterprise-allocation'
                            : 'select-job-enterprise-type'
                        }`}
                      >
                        <FormControl
                          fullWidth={true}
                          error={Boolean(touched.allocate_machine && errors.allocate_machine)}
                          sx={{ ...theme.typography.customInput }}
                        >
                          <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
                            {`${transformedPermissionsAddEdit['empAllocateMachine']?.label} ${
                              transformedPermissionsAddEdit['empAllocateMachine']?.isRequired ? ' *' : ''
                            }`}
                          </Typography>

                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Autocomplete
                              multiple
                              options={assignMachineData?.data || []}
                              getOptionLabel={(option) => `${option?.machineName || ''} - ${option?.id || ''}`}
                              isOptionEqualToValue={(option, value) => option.id === value?.id}
                              value={values?.allocate_machine || []}
                              onChange={(event, newValue) => handleChange({ target: { name: 'allocate_machine', value: newValue } })}
                              disabled={
                                values?.hasGlobalAccess
                                  ? true
                                  : isAdd
                                  ? false
                                  : transformedPermissionsAddEdit['empAllocateMachine']?.isStatic
                              }
                              onInputChange={handleSearchChange}
                              renderTags={(value, getTagProps) =>
                                value.map((option, index) => {
                                  const { onDelete, ...tagProps } = getTagProps({ index });
                                  return (
                                    <span
                                      key={option}
                                      {...tagProps}
                                      style={{
                                        backgroundColor: '#6fd74b',
                                        color: '#fff',
                                        padding: '2px 8px',
                                        borderRadius: '4px',
                                        marginRight: '5px',
                                        display: 'flex',
                                        alignItems: 'center'
                                      }}
                                    >
                                      {option?.machineName}
                                      <button
                                        type="button"
                                        onClick={() => onDeleteAssignMachine(option?.id, onDelete)}
                                        style={{
                                          marginLeft: '5px',
                                          background: 'none',
                                          border: 'none',
                                          color: '#fff',
                                          cursor: 'pointer'
                                        }}
                                      >
                                        ✖
                                      </button>
                                    </span>
                                  );
                                })
                              }
                              renderInput={(params) => (
                                <TextField
                                  multiline
                                  {...params}
                                  placeholder={`Search and Select ${transformedPermissionsAddEdit['empAllocateMachine']?.label?.replace(
                                    'Allocate',
                                    ''
                                  )} ${transformedPermissionsAddEdit['empAllocateMachine']?.isRequired ? ' *' : ''}`}
                                  disabled={
                                    values?.hasGlobalAccess
                                      ? true
                                      : isAdd
                                      ? false
                                      : transformedPermissionsAddEdit['empAllocateMachine']?.isStatic
                                  }
                                  variant="outlined"
                                  inputRef={(el) => setFieldRef('allocate_machine', el)}
                                  onBlur={handleBlur}
                                  onKeyUp={handelKeyUpMachine}
                                  error={touched.allocate_machine && errors.allocate_machine}
                                />
                              )}
                              loading={machineListIsLoading}
                              renderOption={(props, option) => <li {...props}>{option?.machineName}</li>}
                              sx={{ width: '100%' }}
                            />

                            {/* Sync Button */}
                            <IconButton
                              onClick={() => refetchAssignMachine()}
                              disabled={
                                values?.hasGlobalAccess ||
                                machineListIsLoading ||
                                isRefetchingAssignMachine ||
                                (isAdd ? false : transformedPermissionsAddEdit['empAllocateMachine']?.isStatic)
                              }
                              style={{ marginLeft: '5px' }}
                              color="primary"
                            >
                              {isRefetchingAssignMachine ? (
                                <CircularProgress size={24} />
                              ) : (
                                <>
                                  <SyncIcon />
                                </>
                              )}
                            </IconButton>
                          </div>

                          {machineListIsLoading && (
                            <CircularProgress
                              size={24}
                              sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px'
                              }}
                            />
                          )}

                          {touched.allocate_machine && errors.allocate_machine && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-email-login"
                              sx={{
                                fontSize: '13.8px'
                              }}
                            >
                              {errors.allocate_machine}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>

                      {/* Local User List Table  */}
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TableContainer sx={{ maxHeight: 440 }}>
                          {localUserList && Array.isArray(localUserList) && localUserList?.length > 0 && (
                            <Table
                              stickyHeader
                              aria-label="sticky table"
                              sx={{ ...theme.typography.customInput }}
                              className="new-table-classs new-table-class-employee"
                              // key={'employeeTableSubscription'}
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell align="center" sx={{ width: '20%' }}>
                                    <TableSortLabel className="hover-color-white">Machine Name</TableSortLabel>
                                  </TableCell>
                                  <TableCell align="center">
                                    <TableSortLabel className="hover-color-white">Machine Location</TableSortLabel>
                                  </TableCell>
                                  <TableCell align="center" sx={{ width: '15%' }}>
                                    <TableSortLabel className="hover-color-white">Subscription</TableSortLabel>
                                  </TableCell>
                                  <TableCell align="center" sx={{ width: '20%' }}>
                                    <TableSortLabel className="hover-color-white"> Machine Information</TableSortLabel>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {localUserList?.map((row, i) => {
                                  // row.remainingLocalCount = 0;
                                  //setAllocationMachineValidCount
                                  const subStatusColor =
                                    row?.subStatus === 'ACTIVE' ? '#6fd74b' : row?.subStatus === 'INACTIVE' ? 'error' : 'gray';
                                  //Check allocate machine subscription have plan or aviable local plan is 0
                                  return (
                                    <>
                                      <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.id || i}
                                        sx={{
                                          backgroundColor:
                                            // isMobile &&
                                            machineUserTypeObj &&
                                            machineUserTypeObj[row?.id] &&
                                            touched.machineUserType &&
                                            touched?.machineUserType[row?.id]
                                              ? '#fbd8d8' // When condition is true
                                              : 'unset', // When condition is false
                                          '&:hover': {
                                            backgroundColor:
                                              // isMobile &&
                                              machineUserTypeObj &&
                                              machineUserTypeObj[row?.id] &&
                                              touched.machineUserType &&
                                              touched?.machineUserType[row?.id]
                                                ? '#ffc8c8 !important' // Hover color on mobile
                                                : 'initial' // Default hover color (when condition is false)
                                          }
                                        }}
                                      >
                                        <TableCell align="left" sx={{ width: '25%' }}>
                                          <Typography variant="h4" component={'div'} color="primary1">
                                            {row?.machineName}
                                          </Typography>
                                        </TableCell>

                                        <TableCell
                                          align="left"
                                          sx={{
                                            paddingLeft: 2,
                                            paddingRight: 2
                                            // alignItems: 'center'
                                          }}
                                        >
                                          <p style={{ margin: 0 }}>{row?.machineLoc || 'N.A.'}</p>
                                        </TableCell>
                                        {/* 6a78df   8995ee */}
                                        <TableCell align="left" sx={{ width: '15%' }}>
                                          <Typography variant="div" component={'h4'} color="primary1" fontWeight={'normal'}>
                                            {row?.remainingLocalCount == 0 ? (
                                              <>
                                                {/* <Button
                                                  type="button"
                                                  variant="contained" // You can use "outlined" if you prefer
                                                  color="primary" // Color of the button, can be 'primary', 'secondary', etc.
                                                  // startIcon={<UpgradeIcon />} // Icon for the button
                                                  startIcon={
                                                    row?.localCount == undefined || row?.localCount == 0 ? (
                                                      <AddCircleIcon />
                                                    ) : (
                                                      <NextPlanIcon />
                                                    )
                                                  }
                                                  sx={{
                                                    color: '#fff',
                                                    backgroundColor:
                                                      row?.localCount == undefined || row?.localCount == 0 ? '#4590d7' : '#6a78df',
                                                    '&:hover': {
                                                      backgroundColor:
                                                        row?.localCount == undefined || row?.localCount == 0 ? '#65a9e9' : '#8995ee'
                                                    },
                                                    marginTop: '15px'
                                                  }} // Custom button color
                                                  onClick={() => handleOpenDialog(row, row?.localCount)}
                                                >
                                                  {row?.localCount == undefined || row?.localCount == 0 ? 'Add Plan' : 'Upgrade Plan'}
                                                </Button> */}
                                              </>
                                            ) : (
                                              <>
                                                <p style={{ margin: '5px' }}>{row?.planName}</p>
                                                {row?.subStatus && (
                                                  <Chip
                                                    // label={row?.subStatus}
                                                    label={
                                                      row?.subStatus === 'Pending'
                                                        ? 'Pending'
                                                        : row?.subStatus === 'ACTIVE'
                                                        ? 'ACTIVE'
                                                        : row?.subStatus === 'APPROVAL_PENDING'
                                                        ? 'APPROVAL PENDING'
                                                        : 'CANCELLED'
                                                    }
                                                    // color={subStatusColor}
                                                    sx={{
                                                      // backgroundColor: subStatusColor,
                                                      color: '#fff',
                                                      backgroundColor:
                                                        row?.subStatus === 'Pending'
                                                          ? '#ae30c2'
                                                          : row?.subStatus === 'ACTIVE'
                                                          ? '#30c42b'
                                                          : row?.subStatus === 'APPROVAL_PENDING'
                                                          ? '#dbc115'
                                                          : '#d84315'
                                                    }}
                                                    size="small"
                                                  />
                                                )}
                                              </>
                                            )}
                                            {/* Subscription Add / Upgrade Button */}
                                            {row?.subStatus === null ? (
                                              <>
                                                <Button
                                                  disableElevation
                                                  type="button"
                                                  variant="contained" // You can use "outlined" if you prefer
                                                  color="primary" // Color of the button, can be 'primary', 'secondary', etc.
                                                  // startIcon={<UpgradeIcon />} // Icon for the button
                                                  startIcon={<AddCircleIcon />}
                                                  sx={{
                                                    color: '#fff',
                                                    backgroundColor: '#4590d7',
                                                    '&:hover': {
                                                      backgroundColor: '#65a9e9'
                                                    },
                                                    marginTop: '15px'
                                                  }} // Custom button color
                                                  onClick={() => handleOpenDialog(row, row?.localCount)}
                                                >
                                                  {'Add Plan'}
                                                </Button>
                                              </>
                                            ) : (
                                              <>
                                                {row.subStatus === 'ACTIVE' ? (
                                                  <>
                                                    {row?.remainingLocalCount == 0 && (
                                                      <Button
                                                        disableElevation
                                                        type="button"
                                                        variant="contained" // You can use "outlined" if you prefer
                                                        color="primary" // Color of the button, can be 'primary', 'secondary', etc.
                                                        // startIcon={<UpgradeIcon />} // Icon for the button
                                                        startIcon={<NextPlanIcon />}
                                                        sx={{
                                                          color: '#fff',
                                                          backgroundColor: '#6a78df',
                                                          '&:hover': {
                                                            backgroundColor: '#8995ee'
                                                          },
                                                          marginTop: '15px'
                                                        }} // Custom button color
                                                        onClick={() => handleOpenDialog(row, row?.localCount)}
                                                      >
                                                        {'Upgrade Plan'}
                                                      </Button>
                                                    )}
                                                  </>
                                                ) : (
                                                  <>
                                                    {row.subStatus === 'APPROVAL_PENDING' ? (
                                                      <>
                                                        <Button
                                                          disableElevation
                                                          type="button"
                                                          variant="contained" // You can use "outlined" if you prefer
                                                          color="primary" // Color of the button, can be 'primary', 'secondary', etc.
                                                          // startIcon={<UpgradeIcon />} // Icon for the button
                                                          startIcon={<Payment />}
                                                          sx={{
                                                            color: '#fff',
                                                            backgroundColor: '#FF9800',
                                                            '&:hover': {
                                                              backgroundColor: '#FFB74D'
                                                            },
                                                            marginTop: '15px'
                                                          }} // Custom button color
                                                          onClick={() => handleOpenDialog(row, row?.localCount)}
                                                        >
                                                          {'Pay Now'}
                                                        </Button>
                                                      </>
                                                    ) : (
                                                      <>
                                                        <Button
                                                          disableElevation
                                                          type="button"
                                                          variant="contained" // You can use "outlined" if you prefer
                                                          color="primary" // Color of the button, can be 'primary', 'secondary', etc.
                                                          // startIcon={<UpgradeIcon />} // Icon for the button
                                                          startIcon={<NextPlanIcon />}
                                                          sx={{
                                                            color: '#fff',
                                                            backgroundColor: '#6a78df',
                                                            '&:hover': {
                                                              backgroundColor: '#8995ee'
                                                            },
                                                            marginTop: '15px'
                                                          }} // Custom button color
                                                          onClick={() => handleOpenDialog(row, row?.localCount)}
                                                        >
                                                          {'Upgrade Plan'}
                                                        </Button>
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            )}
                                            {/* {row?.subStatus === null || row.subStatus !== 'ACTIVE' || row?.remainingLocalCount > 0 ? (
                                              <Button
                                                type="button"
                                                variant="contained"
                                                color="primary"
                                                startIcon={row?.subStatus === null ? <AddCircleIcon /> : <NextPlanIcon />}
                                                sx={{
                                                  color: '#fff',
                                                  backgroundColor: row?.subStatus === null ? '#4590d7' : '#6a78df',
                                                  '&:hover': {
                                                    backgroundColor: row?.subStatus === null ? '#65a9e9' : '#8995ee'
                                                  },
                                                  marginTop: '15px'
                                                }}
                                                onClick={() => handleOpenDialog(row, row?.localCount)}
                                              >
                                                {row?.subStatus === null ? 'Add Plan' : 'Upgrade Plan'}
                                              </Button>
                                            ) : null} */}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          sx={{
                                            paddingLeft: 2,
                                            paddingRight: 2,
                                            width: '20%',
                                            alignItems: 'center'
                                          }}
                                        >
                                          {/* <Typography variant="h4" component={'div'} sx={{ color: '#2196F3' }}>
                                        {row?.subscription?.noUsers?.machineSpecificUser && (
                                          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                                            Plan : <span style={{ fontWeight: 'normal' }}>{row?.subscription?.title ?? ''}</span>
                                          </Typography>
                                        )} */}

                                          <Typography variant="h5" component={'div'} sx={{ fontWeight: 'bold' }}>
                                            Global User Count : <span style={{ fontWeight: 'normal' }}>{row?.globalCount ?? 0}</span>
                                          </Typography>

                                          <Typography variant="h5" component={'div'} sx={{ fontWeight: 'bold', py: 1 }}>
                                            Local User Count : <span style={{ fontWeight: 'normal' }}>{row?.localCount ?? 0}</span>
                                          </Typography>
                                          <Divider />
                                          <Typography variant="h5" component={'div'} sx={{ fontWeight: 'bold', py: 1 }}>
                                            Available User Count :{' '}
                                            <span style={{ fontWeight: 'normal' }}>{row?.remainingLocalCount ?? 0}</span>
                                          </Typography>
                                          {/* </Typography> */}
                                          {/* <FormControl>
                                <UserRadioBox
                                  id={row?.id}
                                  machineUserType={values.machineUserType}
                                  handleUserTypeChange={handleUserTypeChange}
                                />
                                {machineUserTypeObj &&
                                  machineUserTypeObj[row?.id] &&
                                  touched.machineUserType &&
                                  touched?.machineUserType[row?.id] && (
                                    <FormHelperText
                                      error
                                      id="standard-weight-helper-text-email-login"
                                      sx={{
                                        fontSize: '14px',
                                        paddingBottom: '10px',
                                        fontWeight: '500'
                                      }}
                                    >
                                      {machineUserTypeObj[row?.id].machineTypeUser}
                                    </FormHelperText>
                                  )}
                              </FormControl> */}
                                        </TableCell>
                                      </TableRow>
                                    </>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          )}
                          {/* </>
                  )} */}
                        </TableContainer>
                      </Grid>
                    </>
                  )}

                  {/* <Grid item xs={12}>
                <FormControlLabel control={<Switch defaultChecked />} label="Make this employee admin" />
              </Grid> */}
                </Grid>
              </Grid>
            </>
          )}

          {/* submit */}

          <Grid item xs={12}>
            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
            <Box sx={{ mt: 5, display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
              <Box sx={{ display: 'inline-block' }}>
                <AnimateButton>
                  <Button
                    disableElevation
                    disabled={allocationMachineValidCount > 0 ? true : isLoadingAddEmployee}
                    size="large"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    style={{
                      color: 'white',
                      minWidth: '200px',
                      margin: '0px auto'
                    }}
                  >
                    {isLoadingAddEmployee ? (
                      <>
                        <CircularProgress
                          sx={{
                            color: 'white',
                            height: 20,
                            width: 20
                          }}
                        />
                      </>
                    ) : (
                      `${btnName} Employee`
                    )}
                  </Button>
                </AnimateButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
        {/* {openDialog.show && <PricingTableDialog open={openDialog} onClose={handleCloseDialog} />} */}
        {/* {console.log('openDialog employee nb ~ : ', openDialog)} */}
        {openDialog.show && <MachineSubscription open={openDialog} onClose={handleCloseDialog} setOpenDialog={setOpenDialog} />}

        {/* Subscription - Machine View Modal */}
        {openViewModal.open && (
          <MachineModalDialog open={openViewModal} setOpen={setOpenViewModal} toggleBoughtRentedAssigned={''} callFrom={'Employee'} />
        )}
        {/* {console.log('employee - openViewModal', openViewModal)} */}
        {openViewModal.openSubscription && (
          <EmployeeSubscription
            passedObject={openViewModal?.machineSubFromEmployee?.passedObject}
            callFrom={openViewModal?.machineSubFromEmployee?.callFrom}
            setOpenViewModal={openViewModal?.machineSubFromEmployee?.setOpenViewModal}
            openViewModal={openViewModal?.openSubscription}
          />
        )}
      </Form>
    </>
  );
};

export default EmployeeForm;

// eslint-disable-next-line
function UserRadioBox({ id, machineUserType, handleUserTypeChange }) {
  // const defaultCheck = !machineUserType && !machineUserType?.isLocal && !machineUserType?.isGlobal ? true : false;
  return (
    <div>
      <FormControl component="fieldset">
        <FormLabel component="legend">Select User Type</FormLabel>

        <RadioGroup
          aria-label="user-type"
          name={`userType-${id}`}
          // value={defaultCheck ? '' : machineUserType?.isLocal ? 'Local' : machineUserType?.isGlobal ? 'Global' : ''}
          // onChange={(e) => handleUserTypeChange(id, e.target.value)}
          onChange={(e) =>
            handleUserTypeChange(id, {
              isLocal: e.target.value === 'Local',
              isGlobal: e.target.value === 'Global',
              machineTypeUser: e.target.value
            })
          }
          sx={{ flexDirection: 'row' }}
        >
          {/* Local User Radio Button */}
          <FormControlLabel
            value="Local"
            control={<Radio />}
            label={
              <Typography variant="h4" sx={{ color: '#2196F3' }}>
                Local User
              </Typography>
            }
          />

          {/* Global User Radio Button */}
          <FormControlLabel
            value="Global"
            control={<Radio />}
            label={
              <Typography variant="h4" sx={{ color: '#FCA311' }}>
                Global User
              </Typography>
            }
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
}
