import { useMutation } from '@tanstack/react-query';
import axiosInstance from 'utils/axiosInstance';
import { setToast, toastConfig } from 'utils/commonUtil';
import { endpoints } from 'endpoints/endpoints';
import { useNavigate } from 'react-router';
export const useCreatePassword = () => {
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async (data) => {
      return await axiosInstance
        .put(`${endpoints.users}/password/save`, data)
        .then((res) => {
          // console.log('Create Password');

          if (res?.status === 200 || res?.status === true) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data?.message);
            //setToast(toastConfig, 'Successfully login!');
            navigate(`/`);
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          console.log('Error to create password', error);
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },

    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};

// Forgot Password

export const useForgotPassword = () => {
  // const navigate = useNavigate();

  return useMutation({
    mutationFn: async (data) => {
      return await axiosInstance
        .post(`${endpoints.users}/forgot-password`, data)
        .then((res) => {
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data.message);
            //   navigate(`/`);
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },

    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};

/// Resend Confirmation Email
// export const useGetResendConfirmationEmail = ({ id }) => {
//   return useMutation({
//     queryKey: ['getResendConfirmationEmail', id],
//     queryFn: async () => {
//       return await axiosInstance
//         .get(`${endpoints.users}/${id}/resend-confirmation`)
//         .then((res) => {
//           if (res?.status == 200) {
//             return res?.data?.data;
//           } else {
//             return [];
//           }
//         })
//         .catch((e) => {
//           console.log(e);
//         });
//     },
//     enabled: Boolean(id),
//     onSuccess: ({ data }) => {
//       return data;
//     }
//   });
// };
export const useGetResendConfirmationEmail = () => {
  return useMutation({
    mutationFn: async (data) => {
      return await axiosInstance
        // .patch(`${endpoints.machineticket}/${data?.id}`, {
        //   status: data?.status
        // })
        .get(`${endpoints.users}/${data?.id}/resend-confirmation`)
        .then((res) => {
          if (res?.status == 200) {
            toastConfig.type = 'success';
            setToast(toastConfig, res?.data.message);
          } else {
            toastConfig.type = 'error';
            setToast(toastConfig, error?.response?.data?.error);
          }

          return res;
        })
        .catch((error) => {
          toastConfig.type = 'error';
          setToast(toastConfig, error?.response?.data?.error);
        });
    },
    onSuccess: ({ data }) => {
      // queryClient.refetchQueries('getTicketReasonData');
      return data;
    },
    onError: ({ error }) => {
      return error;
    },
    onSettled: ({ data }) => {
      return data;
    }
  });
};
