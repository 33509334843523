import { Formik } from 'formik';
import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import BreadcrumbsForPage from 'ui-component/extended/BreadcrumbsForPage';
import 'yup-phone';
import SubscriptionForm from '../subscription-form';
import { useState, useEffect } from 'react';
import FormView from 'ui-component/cards/Skeleton/FormView';
import * as Yup from 'yup';
import { useSubscriptionsAdd } from 'hooks/useSubscriptionsHooks';
import { useMutation } from '@tanstack/react-query';
import { setToast, toastConfig } from 'utils/commonUtil';
import { useNavigate } from 'react-router-dom';
const SubscriptionAdd = () => {
  const [isLoading, setLoading] = useState(true);

  const navigate = useNavigate();

  const { mutate: addSubscription, isPending: isSubscriptionsLoading } = useMutation({
    mutationFn: useSubscriptionsAdd
  });

  const initials = {
    name: '',
    description: '',
    currencyCode: '',
    localUser: null, // Integer
    tax: null, // Positive number
    globalUser: null, // Integer
    trialIntervalUnit: '',
    trialIntervalCount: null, // Integer
    trialPrice: null, // Positive number
    regularIntervalUnit: '',
    regularIntervalCount: null, // Integer
    regularPrice: null, // Positive number
    features: [''],
    isPublic: false,
    planColor: {
      hex: '#3f10a1',
      rgb: {
        r: 62.905096778835826,
        g: 16.019502074688795,
        b: 160.8625,
        a: 1
      },
      hsv: {
        h: 259.4219653179191,
        s: 90.04149377593362,
        v: 63.083333333333336,
        a: 1
      }
    },
    noOfAds: ''
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    description: Yup.string().required('Description is required'),
    currencyCode: Yup.string().required('Currency Code is required'),
    tax: Yup.number()
      .typeError('Tax must be a valid number')
      .positive('Tax must be a non-zero positive number')
      .moreThan(0, 'Tax must be greater than zero')
      .required('Tax is required'),
    localUser: Yup.number()
      .typeError('Local User Count must be an integer')
      .integer('Local User Count must be an integer')
      .moreThan(0, 'Local User Count must be greater than zero')
      .required('Local User Count is required'),
    globalUser: Yup.number()
      .typeError('Global User Count must be an integer')
      .integer('Global User Count must be an integer')
      .moreThan(0, 'Global User Count must be greater than zero')
      .required('Global User Count is required'),
    features: Yup.array().of(Yup.string().required('Feature cannot be empty')).min(1, 'At least one feature is required'),

    // Regular Interval Validation
    regularIntervalUnit: Yup.string()
      .oneOf(['day', 'week', 'month', 'year'], 'Invalid Regular Interval Unit')
      .required('Interval Unit is required'),
    regularIntervalCount: Yup.number()
      .nullable()
      .required('Interval Count is required')
      .typeError('Interval Count must be a number')
      .integer('Interval Count must be an integer')
      .moreThan(0, 'Regular Interval Count must be greater than zero')
      .test('regular-interval-range', 'Invalid Regular Interval Count', function (value) {
        const { regularIntervalUnit } = this.parent;
        return (
          (regularIntervalUnit === 'day' && value > 0 && value <= 365) ||
          (regularIntervalUnit === 'week' && value > 0 && value <= 52) ||
          (regularIntervalUnit === 'month' && value > 0 && value <= 12) ||
          (regularIntervalUnit === 'year' && value === 1)
        );
      }),
    regularPrice: Yup.number()
      .nullable()
      .required('Price is required')
      .typeError('Price must be a number')
      .positive('Price must be a non-zero positive number')
      .moreThan(0, 'Price must be greater than zero'),
    planColor: Yup.object().shape({
      hex: Yup.string().required('Plan color hex is required')
    }),

    // Trial Fields: Only required if any of them is filled
    trialIntervalUnit: Yup.string()
      .oneOf(['day', 'week', 'month', 'year'], 'Invalid Trial Interval Unit')
      .nullable()
      .test('trial-required', 'Interval Unit is required', function (value) {
        const { trialIntervalCount, trialPrice } = this.parent;
        if (!value && !trialIntervalCount && !trialPrice) return true; // No error if all are empty
        return !!value; // Error if at least one is filled but this is missing
      }),

    trialIntervalCount: Yup.number()
      .nullable()
      .test('trial-required', 'Interval Count is required', function (value) {
        const { trialIntervalUnit, trialPrice } = this.parent;
        if (!trialIntervalUnit && !value && !trialPrice) return true; // No error if all are empty
        return value > 0; // Error if at least one is filled but this is missing
      })
      .typeError('Interval Count must be a number')
      .integer('Interval Count must be an integer')
      .moreThan(0, 'Interval Count must be greater than zero')
      .test('trial-interval-range', 'Invalid Interval Count', function (value) {
        const { trialIntervalUnit } = this.parent;
        if (!trialIntervalUnit || !value) return true; // No validation if empty
        return (
          (trialIntervalUnit === 'day' && value > 0 && value <= 365) ||
          (trialIntervalUnit === 'week' && value > 0 && value <= 52) ||
          (trialIntervalUnit === 'month' && value > 0 && value <= 12) ||
          (trialIntervalUnit === 'year' && value === 1)
        );
      }),

    trialPrice: Yup.number()
      .nullable()
      .test('trial-required', 'Price is required', function (value) {
        const { trialIntervalUnit, trialIntervalCount } = this.parent;
        if (!trialIntervalUnit && !trialIntervalCount && !value) return true; // No error if all are empty
        return value > 0; // Error if at least one is filled but this is missing
      })
      .typeError('Price must be a number')
      .positive('Price must be a non-zero positive number')
      .moreThan(0, 'Price must be greater than zero'),

    noOfAds: Yup.string().required('Number of Ads is required')
  });

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      {isLoading ? (
        <FormView />
      ) : (
        <>
          <BreadcrumbsForPage
            name="Add Subscription"
            obj={{ title: 'Add Subscription', title2: 'Add Subscription', url: '/dashboard/subscriptions' }}
          />
          <MainCard>
            <Formik
              initialValues={initials}
              validationSchema={validationSchema}
              onSubmit={(value, { resetForm }) => {
                const submitData = {
                  name: value?.name,
                  description: value?.description,
                  localUserCount: value?.localUser,
                  globalUserCount: value?.globalUser,
                  adCount: value?.noOfAds,
                  features: value?.features,
                  colorCode: value?.planColor,
                  trialPeriod: {
                    intervalUnit: value?.trialIntervalUnit?.toUpperCase(),
                    intervalCount: value?.trialIntervalCount,
                    price: {
                      currencyCode: value?.currencyCode,
                      value: value?.trialPrice
                    }
                  },
                  regularBillingCycle: {
                    intervalUnit: value?.regularIntervalUnit?.toUpperCase(),
                    intervalCount: value?.regularIntervalCount,
                    price: {
                      currencyCode: value?.currencyCode,
                      value: value?.regularPrice
                    }
                  },
                  tax: value?.tax
                };

                addSubscription(submitData, {
                  onSuccess: (res) => {
                    if (res?.status == 200) {
                      toastConfig.type = 'success';
                      setToast(toastConfig, res?.data.message);
                      // queryClient.refetchQueries('getEnterpriseData');
                      resetForm({});
                      navigate(-1);
                    } else {
                      toastConfig.type = 'error';
                      setToast(toastConfig, error?.response?.data?.error);
                    }
                  },
                  onError: (error) => {
                    toastConfig.type = 'error';
                    setToast(toastConfig, error?.response?.data?.error);
                  }
                });
              }}
            >
              {(args) => <SubscriptionForm {...args} isSubscriptionsLoading={isSubscriptionsLoading} />}
            </Formik>
          </MainCard>
        </>
      )}
    </>
  );
};

export default SubscriptionAdd;
