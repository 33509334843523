// import PropTypes from 'prop-types';
// import React from 'react';
// // import Box from '@mui/material/Box';
// // import TextField from '@mui/material/TextField';
// // import Autocomplete from '@mui/material/Autocomplete';
// // import LocationOnIcon from '@mui/icons-material/LocationOn';
// // import Grid from '@mui/material/Grid';
// // import Typography from '@mui/material/Typography';
// // import parse from 'autosuggest-highlight/parse';
// // import { debounce } from '@mui/material/utils';
// import { usePlacesWidget } from 'react-google-autocomplete';
// import { OutlinedInput } from '@mui/material';
// // import { useEffect } from 'react';
// // import { useTheme } from '@mui/material/styles';

// // This key was created specifically for the demo in mui.com.
// // You need to create a new one for your application.

// // function loadScript(src, position, id) {
// //   if (!position) {
// //     return;
// //   }

// //   const script = document.createElement('script');
// //   script.setAttribute('async', '');
// //   script.setAttribute('id', id);
// //   script.src = src;
// //   position.appendChild(script);
// // }

// // const autocompleteService = { current: null };

// // export default function GoogleMaps({
// //   name,
// //   location,
// //   // setValues
// //   // onChange,
// //   // onBlur,
// //   setFieldValue,
// //   values
// // }) {
// //   const [inputValue, setInputValue] = useState('');
// //   const [options, setOptions] = useState([]);
// //   const loaded = useRef(false);

// //   if (typeof window !== 'undefined' && !loaded.current) {
// //     if (!document.querySelector('#google-maps')) {
// //       loadScript(
// //         `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`,
// //         document.querySelector('head'),
// //         'google-maps'
// //       );
// //     }

// //     loaded.current = true;
// //   }

// //   const fetch = useMemo(
// //     () =>
// //       debounce((request, callback) => {
// //         autocompleteService.current.getPlacePredictions(request, callback);
// //       }, 400),
// //     []
// //   );

// //   useEffect(() => {
// //     let active = true;

// //     if (!autocompleteService.current && window.google) {
// //       autocompleteService.current = new window.google.maps.places.AutocompleteService();
// //     }
// //     if (!autocompleteService.current) {
// //       return undefined;
// //     }

// //     // if (inputValue === '') {
// //     //   setOptions([]);
// //     //   return undefined;
// //     // }

// //     fetch({ input: inputValue }, (results) => {
// //       if (active) {
// //         // let newOptions = [];

// //         // if (location[0]) {
// //         //   newOptions = [location[0]];
// //         // }

// //         // if (results) {
// //         //   newOptions = [...newOptions, ...results];
// //         // }

// //         setOptions(results);
// //       }
// //     });

// //     return () => {
// //       active = false;
// //     };
// //   }, [location, inputValue, fetch]);

// //   return (
// //     <Autocomplete
// //       name={name}
// //       id="google-map-demo"
// //       sx={{ width: '100%' }}
// //       getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
// //       filterOptions={(x) => x}
// //       options={options}
// //       autoComplete
// //       includeInputInList
// //       disabled={values?.organization !== '' ? true : false}
// //       filterSelectedOptions
// //       value={location?.description}
// //       noOptionsText="No locations"
// //       onChange={(event, newValue) => {
// //         // setOptions(newValue ? [newValue, ...options] : options);
// //         // setValues((prev) => ({ ...prev, location: newValue.description }));
// //         if (newValue) {
// //           setFieldValue('location', newValue.description);
// //         } else {
// //           setFieldValue('location', '');
// //         }
// //       }}
// //       onInputChange={(event, newInputValue) => {
// //         setInputValue(newInputValue);
// //       }}
// //       renderInput={(params) => <TextField {...params} label="Machine Location" fullWidth />}
// //       renderOption={(props, option) => {
// //         const matches = option.structured_formatting.main_text_matched_substrings || [];

// //         const parts = parse(
// //           option.structured_formatting.main_text,
// //           matches.map((match) => [match.offset, match.offset + match.length])
// //         );

// //         return (
// //           <Box {...props}>
// //             <Grid container alignItems="center">
// //               <Grid item sx={{ display: 'flex', width: 44 }}>
// //                 <LocationOnIcon sx={{ color: 'text.secondary' }} />
// //               </Grid>
// //               <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
// //                 {parts.map((part, index) => (
// //                   <Box key={index} component="span" sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}>
// //                     {part.text}
// //                   </Box>
// //                 ))}
// //                 <Typography variant="body2" color="text.secondary">
// //                   {option.structured_formatting.secondary_text}
// //                 </Typography>
// //               </Grid>
// //             </Grid>
// //           </Box>
// //         );
// //       }}
// //     />
// //   );
// // }

// export default function GoogleMaps({
//   //   // setValues
//   handleChange,
//   onBlur,
//   setFieldValue,

//   values,
//   error,
//   setLocationLatLng,
//   disabled = false
//   // locationDetails
//   // machineDetails
// }) {
//   // const [pickLocation, setPickLocation] = useState('');
//   // const theme = useTheme();

//   const { ref: materialRef } = usePlacesWidget({
//     apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
//     language: 'en',
//     onPlaceSelected: (place) => {
//       if (place) {
//         console.log('🚀 ~ place:', place);
//         const countryComponent = place.address_components.find((component) => component.types.includes('country'));
//         const countryCode = countryComponent ? countryComponent.short_name : '';
//         const locationComponent = place.address_components.find((component) => component.types.includes('political'));

//         console.log('LKJK', locationComponent);

//         setFieldValue('countryCode', countryCode);

//         setFieldValue('location', place?.name + ' ' + place?.formatted_address);
//         // setPickLocation(place?.name + ' ' + place?.formatted_address);
//         setFieldValue('placeId', place?.place_id);
//         setFieldValue('latitude', place?.geometry?.location.lat());
//         setFieldValue('longitude', place?.geometry?.location.lng());
//         setFieldValue('countryCode', countryCode);
//         setLocationLatLng((prev) => {
//           return {
//             ...prev,
//             lat: place?.geometry?.location.lat(),
//             lng: place?.geometry?.location.lng(),
//             label: place?.name + ' ' + place?.formatted_address
//           };
//         });
//       } else {
//         setFieldValue('location', '');
//       }
//     },
//     // inputAutocompleteValue: 'country',
//     options: {
//       types: ['establishment'],
//       fields: ['formatted_address', 'geometry.location', 'name', 'place_id', 'address_components']
//     }
//   });

//   return (
//     <>
//       {' '}
//       {/* <TextField
//         sx={{ ...theme.typography.customInput }}
//         name={'location'}
//         fullWidth
//         color="secondary"
//         variant="outlined"
//         inputRef={materialRef}
//         value={values?.location}
//         defaultValue={values?.location}
//         label="Address *"
//         onBlur={onBlur}
//         onChange={handleChange}
//         error={error}
//         placeholder="Enter Your Address"
//       />{' '} */}
//       {/* <FormControl fullWidth error={Boolean(touched.location && errors.location)} sx={{ ...theme.typography.customInput }}>
//         <InputLabel htmlFor="outlined-adornment-email-login"> Location Name </InputLabel> */}
//       <OutlinedInput
//         disabled={disabled}
//         inputRef={materialRef}
//         id="outlined-adornment-email-login"
//         type="text"
//         value={values.location}
//         name="location"
//         onBlur={onBlur}
//         onChange={handleChange}
//         label="Address *"
//         // inputProps={{}}
//         // autoComplete="off"
//         defaultValue={values?.location}
//         placeholder="Enter Your Address"
//         error={error}
//       />
//     </>
//   );
// }
// GoogleMaps.propTypes = {
//   name: PropTypes.string,
//   location: PropTypes.any,
//   setValues: PropTypes.func,
//   onChange: PropTypes.func,
//   onBlur: PropTypes.func,
//   selectedLocationValue: PropTypes.object,
//   setSelectedLocationValue: PropTypes.func,
//   setSelectedOrganization: PropTypes.func,
//   setFieldValue: PropTypes.func,
//   values: PropTypes.object
// };

import PropTypes from 'prop-types';
import React from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { OutlinedInput } from '@mui/material';

export default function GoogleMaps({
  handleChange,
  onBlur,
  setFieldValue,
  values,
  error,
  setLocationLatLng,
  disabled = false,
  // setTouched,
  validateForm,
  // setTouched
  // setFieldError,
  // setFieldTouched,
  // validateField,
  setTouched,
  setErrors
}) {
  // Google Places Autocomplete Setup
  const { ref: materialRef } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    language: 'en',
    onPlaceSelected: (place) => {
      if (place) {
        // Extract city, state, country, postal code
        const { address_components: addressComponents, formatted_address: formatted_address, place_id: place_id } = place;
        setFieldValue('location', formatted_address);
        setFieldValue('placeId', place_id);
        setFieldValue('latitude', place?.geometry?.location.lat());
        setFieldValue('longitude', place?.geometry?.location.lng());

        for (const component of addressComponents) {
          console.log('🚀 ~ component:', component);
          if (component.types.includes('locality')) {
            // addressDetails.city = component.long_name; // City
            setFieldValue('city', component.long_name || '');
          }
          if (component.types.includes('administrative_area_level_1')) {
            // addressDetails.state = component.long_name; // State
            setFieldValue('state', component.long_name || '');
          }
          if (component.types.includes('administrative_area_level_2')) {
            // addressDetails.state = component.long_name; // State
            setFieldValue('state', component.long_name || '');
          }
          if (component.types.includes('country')) {
            // addressDetails.country = component.long_name; // Country
            // addressDetails.countryCode = component.short_name; // Country Code
            setFieldValue('country', component.long_name || '');
            setFieldValue('countryCode', component.short_name || '');
          }
          if (component.types.includes('postal_code')) {
            // addressDetails.postalCode = component.long_name; // Postal Code
            setFieldValue('postalCode', component.long_name || '');
          }
        }
        setLocationLatLng((prev) => ({
          ...prev,
          lat: place?.geometry?.location.lat(),
          lng: place?.geometry?.location.lng(),
          label: place?.name + ' ' + place?.formatted_address
        }));

        if (values?.location?.length > 0) {
          console.log('here');

          setTouched({ ...touched, city: true });
          setErrors({ ...error, city: '' });
        }
      }
      // else {

      //   setFieldValue('location', '');
      //   setFieldValue('city', '');
      //   setFieldValue('state', '');
      //   setFieldValue('country', '');
      //   setFieldValue('countryCode', '');
      //   setFieldValue('postalCode', '');
      //   // setTouched({});
      // }
      validateForm();
    },

    options: {
      types: ['establishment'],
      fields: ['formatted_address', 'geometry.location', 'name', 'place_id', 'address_components']
    }
  });

  return (
    <>
      <OutlinedInput
        disabled={disabled}
        inputRef={materialRef}
        id="outlined-adornment-email-login"
        type="text"
        value={values?.location}
        name="location"
        onBlur={onBlur}
        onChange={handleChange}
        label="Address *"
        placeholder="Enter Your Address"
        error={error}
      />
    </>
  );
}

GoogleMaps.propTypes = {
  handleChange: PropTypes.func,
  onBlur: PropTypes.func,
  setFieldValue: PropTypes.func,
  setTouched: PropTypes.func,
  setFieldTouched: PropTypes.func,
  values: PropTypes.object,
  error: PropTypes.bool,
  setLocationLatLng: PropTypes.func,
  disabled: PropTypes.bool,
  validateForm: PropTypes.func,
  setFieldError: PropTypes.func
};
