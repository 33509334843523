import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
    // 'x-user-type': 'POC',
    // 'x-org-type': 'SuperAdmin'
  }
});

axiosInstance.interceptors.request.use(
  (config) => {
    if (window.localStorage) {
      const token = localStorage.getItem('userJwtToken');
      const organizationType = localStorage.getItem('orgType');
      const usersType = localStorage.getItem('userType');

      if (token) {
        config.headers.Authorization = 'Bearer ' + token;
        // Access Redux state

        // Add custom headers
        // config.headers['x-user-type'] = usersType === 'Main POC' ? 'POC' : 'POC';
        config.headers['x-user-type'] = usersType === 'Main POC' || usersType === 'Secondary POC' ? 'POC' : usersType.replace(' ', '');
        config.headers['x-org-type'] =
          organizationType === 'Service Provider'
            ? 'ServiceProvider'
            : organizationType !== 'Super Admin'
            ? organizationType
            : 'SuperAdmin';
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);
export default axiosInstance;
