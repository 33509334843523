/* eslint-disable no-unused-vars */
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  CardActions,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Transitions from 'ui-component/extended/Transitions';
import MainCard from 'ui-component/cards/MainCard';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import NotificationList from 'layout/MainLayout/Header/NotificationSection/NotificationList';
import NotificationListAll from './NotificationListAll';
import { useState } from 'react';
import OrganizationSection from 'layout/MainLayout/Header/OrganizationSection';
import { useGetProfileById } from 'hooks/useProfileHooks';
import NotificationTypeDropDown from './NotificationTypeDropDown';
import { useDispatch, useSelector } from 'react-redux';
import { setNotificationIsOpen } from 'store/actions/notificationModalAction';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

export default function NotificationDialog({ open, setOpen, details }) {
  // const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const notificationSelecter = useSelector((state) => state.notificationModal);

  const notificationIsOpen = notificationSelecter?.isOpen;

  const [value, setValue] = useState('');

  const theme = useTheme();
  const handleClickOpen = () => {
    // setOpen(true);
    dispatch(setNotificationIsOpen(true));
  };

  const handleClose = () => {
    // setOpen(false);
    dispatch(setNotificationIsOpen(false));
  };

  // notification status options
  const status = [
    {
      value: 'all',
      label: 'All Notification'
    },
    {
      value: 'new',
      label: 'New'
    },
    {
      value: 'unread',
      label: 'Unread'
    },
    {
      value: 'other',
      label: 'Other'
    }
  ];

  const handleChange = (event) => {
    if (event?.target.value) setValue(event?.target.value);
  };

  const { data: profileDetails, isLoading: isLoadingProfile } = useGetProfileById();

  return (
    <React.Fragment>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Slide in alert dialog
      </Button> */}
      <Dialog
        // open={open}
        open={notificationIsOpen}
        TransitionComponent={Transition}
        // keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullScreen={true}
        fullWidth={true}
        className="notification-new-modal"
        sx={{
          marginLeft: {
            xs: theme.spacing(0), // 16px on smaller screens
            sm: theme.spacing(0), // 40px on medium screens
            md: theme.spacing(33) // 64px on larger screens 33
          },
          marginRight: {
            xs: theme.spacing(0), // 16px on smaller screens
            sm: theme.spacing(0), // 40px on medium screens
            md: theme.spacing(80) // 64px on larger screens 33
          }, // Ensure it stays aligned to the left
          // marginTop: theme.spacing(10),
          // marginBottom: theme.spacing(10)
          padding: theme.spacing(3)
        }}
        PaperProps={{
          sx: {
            borderRadius: '16px', // Add border radius
            boxShadow: '13px 16px 37px 0px rgba(0,0,0,0.51)' // Add box shadow
          }
        }}
      >
        {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
        <DialogTitle id="alert-dialog-slide-title">
          <Grid container spacing={5} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              {/* <Skeleton variant="text" width={300} /> */}
              <Grid container alignItems="center" justifyContent="space-between" sx={{ pt: 2, px: 2 }}>
                <Grid item>
                  <Stack direction="row" spacing={2}>
                    <Typography variant="h2">All Notification</Typography>
                    {/* <Chip
                      size="small"
                      label="01"
                      sx={{
                        color: theme.palette.background.default,
                        bgcolor: theme.palette.warning.dark
                      }}
                    /> */}
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <IconButton onClick={handleClose} color="error" className="ticket_closebtn">
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-slide-description">
            Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are running.
          </DialogContentText> */}
          {/* {({ TransitionProps }) => ( */}
          {/* // <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}> */}
          <Paper>
            {/* <ClickAwayListener onClickAway={handleClose}> */}
            {/* boxShadow shadow={theme.shadows[16]} */}
            <MainCard border={true} elevation={0} content={false}>
              <Grid container direction="column" spacing={0}>
                <Grid item xs={12}>
                  <Grid container alignItems="center" justifyContent="space-between" sx={{ pt: 2, px: 2 }}>
                    <Grid item>
                      {/* <Stack direction="row" spacing={2}>
                        <Typography variant="subtitle1">All Notification</Typography>
                        <Chip
                          size="small"
                          label="01"
                          sx={{
                            color: theme.palette.background.default,
                            bgcolor: theme.palette.warning.dark
                          }}
                        />
                      </Stack> */}
                      <NotificationTypeDropDown data={profileDetails?.organizationTypes} isLoading={isLoadingProfile} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 205px)', overflowX: 'hidden' }}>
                    <Grid container direction="column" spacing={2}>
                      <Grid item xs={12}>
                        <Box sx={{ px: 2, pt: 0.25 }}>
                          {/* <TextField
                            id="outlined-select-currency-native"
                            select
                            fullWidth
                            value={value}
                            onChange={handleChange}
                            SelectProps={{
                              native: true
                            }}
                          >
                            {status.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </TextField> */}
                          {/* <NotificationTypeDropDown data={profileDetails?.organizationTypes} isLoading={isLoadingProfile} /> */}
                        </Box>
                      </Grid>
                      <Grid item xs={12} p={0}>
                        <Divider sx={{ my: 0 }} />
                      </Grid>
                    </Grid>
                    {/* <NotificationList /> */}
                    <NotificationListAll />
                  </PerfectScrollbar>
                </Grid>
              </Grid>
              <Divider />
              {/* <CardActions sx={{ p: 1.25, justifyContent: 'center' }}>
                <Button size="small" disableElevation>
                  View All
                </Button>
              </CardActions> */}
            </MainCard>
            {/* </ClickAwayListener> */}
          </Paper>
          {/* </Transitions> */}
          {/* // )} */}
        </DialogContent>
        {/* <Divider />
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose}>Agree</Button>
        </DialogActions> */}
      </Dialog>
    </React.Fragment>
  );
}
