import {
  Button,
  // DialogActions,
  // DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  // InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
// import { useEffect } from 'react';
import { gridSpacing } from 'store/constant';

const TicketReasonFilterForm = ({
  errors,
  handleBlur,
  handleChange,
  // handleSubmit,
  touched,
  values,
  // setFieldValue,
  // setTouched,
  resetForm,
  // filterObj,
  // handleCloseFilter,
  setFilterObj
}) => {
  // useEffect(() => {
  //   if (filterObj) {
  //     // console.log('🚀 ~ useEffect ~ filterObj:', filterObj);
  //     setFieldValue('ticketreason', filterObj?.ticketreason);
  //     setFieldValue('ticketstatus', filterObj?.ticketstatus);
  //   }
  // }, [filterObj, setFieldValue]);
  return (
    <>
      <Grid
        key={'organizationFilter'}
        container
        spacing={gridSpacing}
        sx={{ marginBottom: '20px', alignItems: 'center', padding: '0px 20px' }}
      >
        {/* <Grid container spacing={gridSpacing} style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}> */}
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="select-machine-id inv-number select-machine" alignItems={'center'}>
          <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
            Ticket Category
          </Typography>
          <FormControl
            fullWidth
            variant="outlined"
            // error={Boolean(touched.status && errors.status)}
          >
            <TextField
              placeholder="Search Ticket Category"
              variant="outlined"
              type="text"
              fullWidth
              name="ticketreason"
              value={values?.ticketreason}
              onChange={handleChange}
              error={Boolean(touched.ticketreason && errors.ticketreason)}
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e) => {
                e.preventDefault();
                return false;
              }}
              // onKeyDown={(e) => {
              //   exceptThisSymbols.includes(e.key) && e.preventDefault();
              // }}
            />
          </FormControl>
        </Grid>

        <Grid
          // key={index}
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          className="select-status-filter"

          // select-job-enterprise"
          // className="select-job-enterprise select_separate2"
          // className="select-job-enterprise "
        >
          <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
            Status
          </Typography>
          <FormControl
            fullWidth
            variant="outlined"
            placeholder="Select Status"
            // error={Boolean(touched.status && errors.status)}
          >
            {/* <InputLabel sx={{}}> Select Status</InputLabel> */}
            <Select
              //  disabled={filter?.isStatic}
              value={values?.ticketstatus}
              onChange={handleChange}
              onBlur={handleBlur}
              name="ticketstatus"
              inputProps={{
                'aria-label': 'Without label'
              }}
            >
              <MenuItem value="All">All</MenuItem>
              {/* <MenuItem value="">Select Status</MenuItem> */}
              <MenuItem value="true">Active</MenuItem>
              <MenuItem value="false">Inactive</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {/* </Grid> */}
      </Grid>

      <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'right', paddingBottom: '20px', paddingRight: '20px' }}>
        <Button
          variant="outlined"
          type="reset"
          color="error"
          onClick={() => {
            resetForm();
            setFilterObj({
              ticketreason: '',
              ticketstatus: ''
            });
          }}
        >
          Clear all
        </Button>
        <div style={{ marginLeft: '10px' }}>
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box sx={{ m: 0, position: 'relative' }}>
            <Button
              variant="contained"
              type="submit"
              style={{
                color: '#fff'
              }}
              color="primary"
            >
              Search
            </Button>
          </Box>
        </div>
      </div>
    </>
  );
};

export default TicketReasonFilterForm;
