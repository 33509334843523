import { useEffect } from 'react';
import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  // MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  // Select,
  Typography,
  List,
  ListItem,
  InputAdornment
  // Paper
} from '@mui/material';
import { Box, styled } from '@mui/system';
import { Form } from 'formik';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { gridSpacing } from 'store/constant';
// import { useEffect } from 'react';
// import { useState } from 'react';
// import { useGetLocationAllLists } from 'hooks/useCommonHooks';
// import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';
// import LocationDialog from 'ui-component/LocationDialog';

import StoreIcon from '@mui/icons-material/Store';

import { addSpacesToCamelCase, handlePhoneChange } from 'utils/commonUtil';
import GoogleMaps from 'utils/google-place-api-autocomplete';
import { useGetLocationAllNewList } from 'hooks/useLocationHooks';

const CustomChip = styled(Chip)(({ theme, isPrimary }) => ({
  margin: theme.spacing(0.5),
  backgroundColor: isPrimary ? 'blue' : '#6fd74b',
  color: '#fff',
  '& .MuiChip-deleteIcon': {
    color: '#fff'
  }
}));

function EnhancedTableHead() {
  const columns = [
    { id: 'location_name', label: 'Location Name', minWidth: 150, align: 'center' },
    {
      id: 'address',
      label: 'Address',
      minWidth: 150,
      align: 'center',
      format: (value) => value.toLocaleString('en-US')
    }
  ];
  return (
    <TableHead>
      <TableRow>
        {columns?.map((headCell) => (
          <TableCell key={headCell.id} align="center">
            <TableSortLabel className="hover-color-white">{headCell.label}</TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const OrganizationsNewForm = ({
  errors,
  handleBlur,
  handleChange,
  handleSubmit,

  touched,
  values,

  setFieldValue,
  setFieldTouched,
  // retailerDetails,
  btnName,
  setTouched,
  isLoadingOrganizationNew,
  organizationNewDetails,
  // setValues,
  // id,
  buttonPermissions,
  mainPOPermissions,
  secondPOPermissions,
  otherPOPermissions,
  isEditable = false,
  // locationLatLng,
  setLocationLatLng,
  setFieldError,
  validateField,

  setErrors
}) => {
  const theme = useTheme();

  const { data: allLocationAllLists, isPending: isAllLocationLoading } = useGetLocationAllNewList();

  const exceptThisSymbols = ['e', 'E', '+', '#', '%', '^', '-'];
  useEffect(() => {
    if (organizationNewDetails) {
      setFieldValue('OrgName', organizationNewDetails?.name ? organizationNewDetails?.name : '');
      setFieldValue('OrgWeb', organizationNewDetails?.website ? organizationNewDetails?.website : '');
      setFieldValue('title', organizationNewDetails?.mainPOC?.prefix ? organizationNewDetails?.mainPOC?.prefix : '');
      setFieldValue('firstName', organizationNewDetails?.mainPOC?.firstName ? organizationNewDetails?.mainPOC?.firstName : '');

      setFieldValue('lastName', organizationNewDetails?.mainPOC?.lastName ? organizationNewDetails?.mainPOC?.lastName : '');
      setFieldValue('designation', organizationNewDetails?.mainPOC?.title ? organizationNewDetails?.mainPOC?.title : '');
      setFieldValue('associateEmpEmail', organizationNewDetails?.mainPOC?.email ? organizationNewDetails?.mainPOC?.email : '');
      setFieldValue('associateEmpPhone', organizationNewDetails?.mainPOC?.phone ? organizationNewDetails?.mainPOC?.phone : '');
      setFieldValue('associateEmpAdd', organizationNewDetails?.mainPOC?.address ? organizationNewDetails?.mainPOC?.address : '');
      setFieldValue('primaryId', organizationNewDetails?.mainPOC?.id ? organizationNewDetails?.mainPOC?.id : '');

      setFieldValue('isChecked', organizationNewDetails?.isBothPOCsSame && organizationNewDetails?.isBothPOCsSame);

      setFieldValue('secondaryTitle', organizationNewDetails?.secondaryPOC?.prefix ? organizationNewDetails?.secondaryPOC?.prefix : '');
      setFieldValue(
        'secondaryFirstName',
        organizationNewDetails?.secondaryPOC?.firstName ? organizationNewDetails?.secondaryPOC?.firstName : ''
      );

      setFieldValue(
        'secondaryLastName',
        organizationNewDetails?.secondaryPOC?.lastName ? organizationNewDetails?.secondaryPOC?.lastName : ''
      );
      setFieldValue('secondaryDesignation', organizationNewDetails?.secondaryPOC?.title ? organizationNewDetails?.secondaryPOC?.title : '');
      setFieldValue('SecondaryEmpEmail', organizationNewDetails?.secondaryPOC?.email ? organizationNewDetails?.secondaryPOC?.email : '');
      setFieldValue('SecondaryEmpPhone', organizationNewDetails?.secondaryPOC?.phone ? organizationNewDetails?.secondaryPOC?.phone : '');
      setFieldValue('SecondaryEmpAdd', organizationNewDetails?.secondaryPOC?.address ? organizationNewDetails?.secondaryPOC?.address : '');
      setFieldValue(
        'serviceRadiusUnit',
        organizationNewDetails?.serviceRadius?.unit ? organizationNewDetails?.serviceRadius?.unit : 'Miles'
      );
      // if (organizationNewDetails?.serviceRadius?.value)
      //   setFieldValue(
      //     'service_radius',
      //     organizationNewDetails?.serviceRadius?.value !== '' && organizationNewDetails?.serviceRadius?.value !== undefined
      //       ? Number(organizationNewDetails?.serviceRadius?.value)
      //       : null
      //   );
      setFieldValue('service_radius', organizationNewDetails?.serviceRadius?.value ? organizationNewDetails?.serviceRadius?.value : '');
      let data = organizationNewDetails?.locations?.map((item) => {
        return { ...item.details, isPrimary: item.isPrimary };
      });
      setFieldValue('locationPickUp', data);
      const finalOrgType = organizationNewDetails?.types ? organizationNewDetails?.types.map(addSpacesToCamelCase) : [];
      setFieldValue('orgSelection', finalOrgType || []);
      setFieldValue('orgPreviousSelection', organizationNewDetails?.types || []);
      setFieldValue(
        'rfIdCodePrimary',
        organizationNewDetails?.mainPOC?.authenticationInfo?.rfidCode ? organizationNewDetails?.mainPOC?.authenticationInfo?.rfidCode : ''
      );
      setFieldValue(
        'rfIdCodeSecondary',
        organizationNewDetails?.secondaryPOC?.authenticationInfo?.rfidCode
          ? organizationNewDetails?.secondaryPOC?.authenticationInfo?.rfidCode
          : ''
      );

      setFieldValue(
        'authPinPrimary',
        organizationNewDetails?.mainPOC?.authenticationInfo?.authPIN ? organizationNewDetails?.mainPOC?.authenticationInfo?.authPIN : ''
      );

      setFieldValue(
        'authPinSecondary',
        organizationNewDetails?.secondaryPOC?.authenticationInfo?.authPIN
          ? organizationNewDetails?.secondaryPOC?.authenticationInfo?.authPIN
          : ''
      );

      setFieldValue('mainPocId', organizationNewDetails?.mainPOC?.id);
      setFieldValue('SecondarPocId', organizationNewDetails?.secondaryPOC?.id);
    }
  }, [organizationNewDetails, setFieldValue]);

  const getButtonUI = (button, index) => {
    if (!button.show) return null;
    const isActive = values?.orgSelection?.includes(button?.label);
    return (
      <ListItem key={index} sx={{ display: 'inline-flex', padding: 0, justifyContent: 'flex-end' }} onBlur={handleBlur}>
        <Chip
          disabled={isEditable ? button?.isStatic : false}
          icon={
            <StoreIcon
              style={{
                color: isActive ? '#fff' : '#000',
                width: '20px',
                height: '20px'
              }}
            /> // Dynamic icon color
          }
          label={button?.label}
          onClick={() => {
            const safeSelection = Array.isArray(values.orgSelection) ? values.orgSelection : [];
            setFieldValue(
              'orgSelection',
              safeSelection.includes(button?.label)
                ? safeSelection.filter((chip) => chip !== button?.label)
                : [...safeSelection, button?.label]
            );
          }}
          sx={{
            margin: '5px',
            backgroundColor: isActive ? '#07bc0c' : '#e0e0e0!important',
            color: isActive ? '#fff' : '#000!important',
            border: touched.orgSelection && errors.orgSelection ? '1px solid red' : 'none', // Add border error
            borderRadius: '7px'
          }}
        />
      </ListItem>
    );
  };

  const getMainPOUI = (main, index) => {
    if (!main.show) return null;
    if (main.id === 'mainPOCPrefix') {
      return (
        <FormControl key={index} fullWidth error={Boolean(touched.title && errors.title)} sx={{ ...theme.typography.customInput }}>
          {/* Prefix */}
          <InputLabel htmlFor="outlined-adornment-prefix" id="retailer-primary-title">
            Choose Prefix *
          </InputLabel>
          <Select
            disabled={isEditable ? main?.isStatic : false}
            label="Choose  Prefix *"
            placeholder="e.g. MR. / Mrs."
            value={values.title}
            onChange={handleChange}
            name="title"
            inputProps={{ 'aria-label': 'Without label' }}
            labelId="retailer-primary-title-label"
          >
            <MenuItem value={'Mr.'}>Mr.</MenuItem>
            <MenuItem value={'Mrs.'}>Mrs.</MenuItem>
          </Select>
          {touched.title && errors.title && (
            <FormHelperText
              error
              id="standard-weight-helper-text-email-login"
              sx={{
                fontSize: '13.8px'
              }}
            >
              {errors.title}
            </FormHelperText>
          )}
        </FormControl>
      );
    } else if (main.id === 'mainPOCTitle') {
      return (
        <FormControl
          key={index}
          fullWidth
          error={Boolean(touched.designation && errors.designation)}
          sx={{ ...theme.typography.customInput }}
        >
          <InputLabel htmlFor="outlined-adornment-designation" id="retailer-primary-designation">
            {' '}
            Employee Title *
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-designation"
            type="text"
            value={values.designation}
            name="designation"
            onBlur={handleBlur}
            onChange={handleChange}
            label="Employee Title"
            placeholder="e.g. Manager"
            inputProps={{}}
            autoComplete="off"
          />

          {touched.designation && errors.designation && (
            <FormHelperText
              error
              id="standard-weight-helper-text-email-login"
              sx={{
                fontSize: '13.8px'
              }}
            >
              {errors.designation}
            </FormHelperText>
          )}
        </FormControl>
      );
    } else if (main.id === 'mainPOCFirstName') {
      return (
        <FormControl key={index} fullWidth error={Boolean(touched.firstName && errors.firstName)} sx={{ ...theme.typography.customInput }}>
          {/* Associate Employee First name */}
          <InputLabel htmlFor="outlined-adornment-first-name"> Employee First Name *</InputLabel>
          <OutlinedInput
            id="outlined-adornment-first-name"
            type="text"
            value={values.firstName}
            name="firstName"
            onBlur={handleBlur}
            onChange={handleChange}
            label=" Employee First Name"
            placeholder="e.g John"
            inputProps={{}}
            autoComplete="off"
          />
          {touched.firstName && errors.firstName && (
            <FormHelperText
              error
              id="standard-weight-helper-text-first-name"
              sx={{
                fontSize: '13.8px'
              }}
            >
              {errors.firstName}
            </FormHelperText>
          )}
        </FormControl>
      );
    } else if (main.id === 'mainPOCLastName') {
      return (
        <FormControl key={index} fullWidth error={Boolean(touched.lastName && errors.lastName)} sx={{ ...theme.typography.customInput }}>
          <InputLabel htmlFor="outlined-adornment-last-name"> Employee Last Name *</InputLabel>
          <OutlinedInput
            id="outlined-adornment-last-name"
            type="text"
            value={values.lastName}
            name="lastName"
            onBlur={handleBlur}
            onChange={handleChange}
            label=" Employee Last Name"
            placeholder="e.g Doe"
            inputProps={{}}
            autoComplete="off"
          />
          {touched.lastName && errors.lastName && (
            <FormHelperText
              error
              id="standard-weight-helper-text-email-login"
              sx={{
                fontSize: '13.8px'
              }}
            >
              {errors.lastName}
            </FormHelperText>
          )}
        </FormControl>
      );
    } else if (main.id === 'mainPOCEmail') {
      return (
        <FormControl
          key={index}
          fullWidth
          error={Boolean(touched.associateEmpEmail && errors.associateEmpEmail)}
          sx={{ ...theme.typography.customInput }}
        >
          {/* Associate Employee Email */}
          <InputLabel htmlFor="outlined-adornment-email"> Employee Email *</InputLabel>
          <OutlinedInput
            id="outlined-adornment-email"
            type="email"
            value={values.associateEmpEmail}
            name="associateEmpEmail"
            onBlur={handleBlur}
            onChange={handleChange}
            label="Employee Email *"
            placeholder="e.g example@email.com"
            inputProps={{}}
            autoComplete="off"
            // disabled={Boolean(id)}
          />
          {touched.associateEmpEmail && errors.associateEmpEmail && (
            <FormHelperText
              error
              id="standard-weight-helper-text-email-login"
              sx={{
                fontSize: '13.8px'
              }}
            >
              {errors.associateEmpEmail}
            </FormHelperText>
          )}
        </FormControl>
      );
    } else if (main.id === 'mainPOCPhone') {
      return (
        <FormControl
          key={index}
          fullWidth
          error={Boolean(touched.associateEmpPhone && errors.associateEmpPhone)}
          sx={{ ...theme.typography.customInput }}
        >
          <InputLabel htmlFor="outlined-adornment-employee-phone">Employee Phone</InputLabel>
          <OutlinedInput
            id="outlined-adornment-employee-phone"
            type="text"
            value={values.associateEmpPhone || ''} // Ensure a default empty string
            name="associateEmpPhone"
            onBlur={handleBlur}
            // onChange={handleChange}
            onChange={(e) => handlePhoneChange(e, setFieldValue, 'associateEmpPhone')}
            label="Employee Phone"
            placeholder="e.g. 212-456-8907"
            inputProps={{
              maxLength: 15 // Limit input to 15 characters
            }}
            autoComplete="off"
            onKeyDown={(e) => {
              exceptThisSymbols.includes(e.key) && e.preventDefault();
            }}
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
          />
          {touched.associateEmpPhone && errors.associateEmpPhone && (
            <FormHelperText
              error
              id="standard-weight-helper-text-employee-phone"
              sx={{
                fontSize: '13.8px'
              }}
            >
              {errors.associateEmpPhone}
            </FormHelperText>
          )}
        </FormControl>
      );
    }
    // else if (main.id === 'mainPOCAddress') {
    //   return (
    //     <FormControl
    //       key={index}
    //       fullWidth
    //       error={Boolean(touched.associateEmpAdd && errors.associateEmpAdd)}
    //       sx={{ ...theme.typography.customInput }}
    //     >
    //       <InputLabel htmlFor="outlined-adornment-address-primary">Employee Address *</InputLabel>
    //       <OutlinedInput
    //         id="outlined-adornment-address-primary"
    //         type="text"
    //         value={values.associateEmpAdd}
    //         name="associateEmpAdd"
    //         onBlur={handleBlur}
    //         onChange={handleChange}
    //         label="Address Details *"
    //         placeholder="e.g. Street, City, State, Country etc."
    //         inputProps={{}}
    //         autoComplete="off"
    //       />
    //       {touched.associateEmpAdd && errors.associateEmpAdd && (
    //         <FormHelperText
    //           error
    //           id="standard-weight-helper-text-email-login"
    //           sx={{
    //             fontSize: '13.8px'
    //           }}
    //         >
    //           {errors.associateEmpAdd}
    //         </FormHelperText>
    //       )}
    //     </FormControl>
    //   );
    // }
    else if (main.id === 'mainPOCRFID' && values?.orgSelection?.includes('Enterprise')) {
      return (
        <FormControl
          key={index}
          fullWidth
          error={Boolean(touched.rfIdCodePrimary && errors.rfIdCodePrimary)}
          sx={{ ...theme.typography.customInput }}
        >
          <InputLabel htmlFor="outlined-adornment-address-primary">RFID Code </InputLabel>
          <OutlinedInput
            id="outlined-adornment-address-primary"
            type="text"
            value={values.rfIdCodePrimary}
            name="rfIdCodePrimary"
            onBlur={handleBlur}
            onChange={handleChange}
            label="RFID Code"
            placeholder="e.g. 2334AT"
            inputProps={{}}
            autoComplete="off"
          />
          {touched.rfIdCodePrimary && errors.rfIdCodePrimary && (
            <FormHelperText
              error
              id="standard-weight-helper-text-email-login"
              sx={{
                fontSize: '13.8px'
              }}
            >
              {errors.rfIdCodePrimary}
            </FormHelperText>
          )}
        </FormControl>
      );
    } else if (main.id === 'mainPOCAuthPIN' && values?.orgSelection?.includes('Enterprise')) {
      return (
        <FormControl
          key={index}
          fullWidth
          error={Boolean(touched?.authPinPrimary && errors?.authPinPrimary)}
          sx={{ ...theme.typography.customInput }}
        >
          <InputLabel htmlFor="outlined-adornment-auth-pin-primary">Auth Pin</InputLabel>
          <OutlinedInput
            id="outlined-adornment-auth-pin-primary"
            type="text" // Use text to enforce custom numeric handling
            value={values?.authPinPrimary}
            name="authPinPrimary"
            onBlur={handleBlur}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*$/.test(value)) {
                handleChange(e); // Only allow numbers
              }
            }}
            label="Auth Pin"
            placeholder="Enter Auth Pin"
            inputProps={{
              maxLength: 10 // Example: Limit to 6 digits if required
            }}
            autoComplete="off"
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            onKeyDown={(e) => {
              if (
                ['e', 'E', '+', '-', '.'].includes(e.key) || // Block invalid keys
                e.keyCode === 32 // Block space
              ) {
                e.preventDefault();
              }
            }}
          />
          {touched?.authPinPrimary && errors?.authPinPrimary && (
            <FormHelperText
              error
              id="standard-weight-helper-text-auth-pin-primary"
              sx={{
                fontSize: '13.8px'
              }}
            >
              {errors?.authPinPrimary}
            </FormHelperText>
          )}
        </FormControl>
      );
    }
  };

  const getSecondUI = (second, index) => {
    if (!second?.show) return null;

    if (second.id === 'secondaryPOCPrefix') {
      return (
        <FormControl
          key={index}
          fullWidth
          error={Boolean(touched.secondaryTitle && errors.secondaryTitle)}
          sx={{ ...theme.typography.customInput }}
        >
          {/* Associate Employee name */}
          <InputLabel htmlFor="outlined-adornment-email-title" id="retailer-secondary-title">
            Choose Prefix *
          </InputLabel>
          <Select
            disabled={isEditable ? second?.isStatic : false}
            label="Choose  Prefix *"
            placeholder="e.g. MR. / Mrs."
            value={values.secondaryTitle}
            onChange={handleChange}
            name="secondaryTitle"
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            labelId="outlined-adornment-state"
            id="outlined-adornment-state"
          >
            <MenuItem value={'Mr.'}>Mr.</MenuItem>
            <MenuItem value={'Mrs.'}>Mrs.</MenuItem>
          </Select>

          {touched.secondaryTitle && errors.secondaryTitle && (
            <FormHelperText
              error
              id="standard-weight-helper-text-email-title"
              sx={{
                fontSize: '13.8px'
              }}
            >
              {errors.secondaryTitle}
            </FormHelperText>
          )}
        </FormControl>
      );
    } else if (second.id === 'secondaryPOCTitle') {
      return (
        <FormControl
          fullWidth
          error={Boolean(touched.secondaryDesignation && errors.secondaryDesignation)}
          sx={{ ...theme.typography.customInput }}
        >
          <InputLabel htmlFor="outlined-adornment-secondary-designation" id="retailer-secondary-designation">
            {' '}
            Employee Title *{' '}
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-secondary-designation"
            type="text"
            value={values.secondaryDesignation}
            name="secondaryDesignation"
            onBlur={handleBlur}
            onChange={handleChange}
            label="Employee Title *"
            placeholder="e.g. Manager"
            inputProps={{}}
            autoComplete="off"
          />

          {/* <Select
                          label="Choose  Title"
                          value={values.secondaryDesignation}
                          onChange={handleChange}
                          name="secondaryDesignation"
                          inputProps={{ 'aria-label': 'Without label' }}
                          labelId="retailer-primary-secondary-designation-label"
                          // id="outlined-adornment-email-secondary-designation"
                        > */}
          {/* {allRecyclerList?.recyclers?.map((item, i) => (
                        <MenuItem value={item?.id} key={i}>
                          {item.name}
                        </MenuItem>
                      ))} */}
          {/* <MenuItem value={''}>none</MenuItem> */}
          {/* <MenuItem value={'Ceo'}>Ceo</MenuItem>
                          <MenuItem value={'Manager'}>Manager</MenuItem>
                        </Select> */}
          {touched.secondaryDesignation && errors.secondaryDesignation && (
            <FormHelperText
              error
              id="standard-weight-helper-text-email-login"
              sx={{
                fontSize: '13.8px'
              }}
            >
              {errors.secondaryDesignation}
            </FormHelperText>
          )}
        </FormControl>
      );
    } else if (second.id === 'secondaryPOCFirstName') {
      return (
        <FormControl
          key={index}
          fullWidth
          error={Boolean(touched.secondaryFirstName && errors.secondaryFirstName)}
          sx={{ ...theme.typography.customInput }}
        >
          <InputLabel htmlFor="outlined-adornment-firstname"> Employee First Name *</InputLabel>
          <OutlinedInput
            id="outlined-adornment-firstname"
            type="text"
            value={values.secondaryFirstName}
            name="secondaryFirstName"
            onBlur={handleBlur}
            onChange={handleChange}
            label=" Employee First Name *"
            placeholder="e.g John"
            inputProps={{}}
            autoComplete="off"
          />
          {touched.secondaryFirstName && errors.secondaryFirstName && (
            <FormHelperText
              error
              id="standard-weight-helper-text-email-login"
              sx={{
                fontSize: '13.8px'
              }}
            >
              {errors.secondaryFirstName}
            </FormHelperText>
          )}
        </FormControl>
      );
    } else if (second.id === 'secondaryPOCLastName') {
      return (
        <FormControl
          key={index}
          fullWidth
          error={Boolean(touched.secondaryLastName && errors.secondaryLastName)}
          sx={{ ...theme.typography.customInput }}
        >
          <InputLabel htmlFor="outlined-adornment-lastname"> Employee Last Name *</InputLabel>
          <OutlinedInput
            id="outlined-adornment-lastname"
            type="text"
            value={values.secondaryLastName}
            name="secondaryLastName"
            onBlur={handleBlur}
            onChange={handleChange}
            label=" Employee Last Name *"
            placeholder="e.g Doe"
            inputProps={{}}
            autoComplete="off"
          />
          {touched.secondaryLastName && errors.secondaryLastName && (
            <FormHelperText
              error
              id="standard-weight-helper-text-email-login"
              sx={{
                fontSize: '13.8px'
              }}
            >
              {errors.secondaryLastName}
            </FormHelperText>
          )}
        </FormControl>
      );
    } else if (second.id === 'secondaryPOCEmail') {
      return (
        <FormControl
          key={index}
          fullWidth
          error={Boolean(touched.SecondaryEmpEmail && errors.SecondaryEmpEmail)}
          sx={{ ...theme.typography.customInput }}
        >
          {/* Associate Employee Email */}
          <InputLabel htmlFor="outlined-adornment-secondary-email"> Employee Email *</InputLabel>
          <OutlinedInput
            id="outlined-adornment-secondary-email"
            type="email"
            value={values.SecondaryEmpEmail}
            // value={values.isChecked ? values.associateEmpEmail : values.SecondaryEmpEmail}
            name="SecondaryEmpEmail"
            onBlur={handleBlur}
            onChange={handleChange}
            label="Employee Email *"
            placeholder="e.g example@email.com"
            inputProps={{}}
            autoComplete="off"
            // disabled={Boolean(id)}
          />
          {touched.SecondaryEmpEmail && errors.SecondaryEmpEmail && (
            <FormHelperText
              error
              id="standard-weight-helper-text-email-login"
              sx={{
                fontSize: '13.8px'
              }}
            >
              {errors.SecondaryEmpEmail}
            </FormHelperText>
          )}
        </FormControl>
      );
    } else if (second.id === 'secondaryPOCPhone') {
      return (
        <FormControl
          key={index}
          fullWidth
          error={Boolean(touched.SecondaryEmpPhone && errors.SecondaryEmpPhone)}
          sx={{ ...theme.typography.customInput }}
        >
          <InputLabel htmlFor="outlined-adornment-phone-login">Employee Phone</InputLabel>
          <OutlinedInput
            id="outlined-adornment-phone-login"
            type="text" // Allows custom numeric validation
            value={values.SecondaryEmpPhone || ''} // Ensure default value is an empty string
            name="SecondaryEmpPhone"
            onBlur={handleBlur}
            // onChange={handleChange}
            onChange={(e) => handlePhoneChange(e, setFieldValue, 'SecondaryEmpPhone')}
            label="Employee Phone"
            placeholder="e.g. 212-456-8907"
            inputProps={{
              maxLength: 15 // Restrict input length directly
            }}
            autoComplete="off"
            onKeyDown={(e) => {
              exceptThisSymbols.includes(e.key) && e.preventDefault();
            }}
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
          />
          {touched.SecondaryEmpPhone && errors.SecondaryEmpPhone && (
            <FormHelperText
              error
              id="standard-weight-helper-text-phone-login"
              sx={{
                fontSize: '13.8px'
              }}
            >
              {errors.SecondaryEmpPhone}
            </FormHelperText>
          )}
        </FormControl>
      );
    }
    // else if (second.id === 'secondaryPOCAddress') {
    //   return (
    //     <FormControl
    //       key={index}
    //       fullWidth
    //       error={Boolean(touched.SecondaryEmpAdd && errors.SecondaryEmpAdd)}
    //       sx={{ ...theme.typography.customInput }}
    //     >
    //       <InputLabel htmlFor="outlined-adornment-secondary-address"> Employee Address * </InputLabel>
    //       <OutlinedInput
    //         id="outlined-adornment-secondary-address"
    //         type="text"
    //         value={values.SecondaryEmpAdd}
    //         name="SecondaryEmpAdd"
    //         onBlur={handleBlur}
    //         onChange={handleChange}
    //         label="Employee Address *"
    //         placeholder="e.g. Street, City, State, Country etc."
    //         inputProps={{}}
    //         autoComplete="off"
    //       />
    //       {touched.SecondaryEmpAdd && errors.SecondaryEmpAdd && (
    //         <FormHelperText
    //           error
    //           id="standard-weight-helper-text-email-login"
    //           sx={{
    //             fontSize: '13.8px'
    //           }}
    //         >
    //           {errors.SecondaryEmpAdd}
    //         </FormHelperText>
    //       )}
    //     </FormControl>
    //   );
    // }
    else if (second.id === 'secondaryPOCRFID' && values?.orgSelection?.includes('Enterprise')) {
      return (
        <FormControl
          key={index}
          fullWidth
          error={Boolean(touched.rfIdCodeSecondary && errors.rfIdCodeSecondary)}
          sx={{ ...theme.typography.customInput }}
        >
          <InputLabel htmlFor="outlined-adornment-address-primary">RFID Code </InputLabel>
          <OutlinedInput
            id="outlined-adornment-address-primary"
            type="text"
            value={values.rfIdCodeSecondary}
            name="rfIdCodeSecondary"
            onBlur={handleBlur}
            onChange={handleChange}
            label="RFID Code"
            placeholder="e.g. 2334AT"
            inputProps={{}}
            autoComplete="off"
          />
          {touched.rfIdCodeSecondary && errors.rfIdCodeSecondary && (
            <FormHelperText
              error
              id="standard-weight-helper-text-email-login"
              sx={{
                fontSize: '13.8px'
              }}
            >
              {errors.rfIdCodeSecondary}
            </FormHelperText>
          )}
        </FormControl>
      );
    } else if (second.id === 'secondaryPOCAuthPIN' && values?.orgSelection?.includes('Enterprise')) {
      return (
        <FormControl
          key={index}
          fullWidth
          error={Boolean(touched?.authPinSecondary && errors?.authPinSecondary)}
          sx={{ ...theme.typography.customInput }}
        >
          <InputLabel htmlFor="outlined-adornment-auth-pin-secondary">Auth Pin</InputLabel>
          <OutlinedInput
            id="outlined-adornment-auth-pin-secondary"
            type="text" // Use text for custom numeric handling
            value={values?.authPinSecondary}
            name="authPinSecondary"
            onBlur={handleBlur}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*$/.test(value)) {
                handleChange(e); // Allow only numeric input
              }
            }}
            label="Auth Pin"
            placeholder="Enter Auth Pin"
            inputProps={{
              maxLength: 10 // Optional: Limit to 6 digits
            }}
            autoComplete="off"
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            onKeyDown={(e) => {
              if (
                ['e', 'E', '+', '-', '.'].includes(e.key) || // Block invalid keys
                e.keyCode === 32 // Block space
              ) {
                e.preventDefault();
              }
            }}
          />
          {touched?.authPinSecondary && errors?.authPinSecondary && (
            <FormHelperText
              error
              id="standard-weight-helper-text-auth-pin-secondary"
              sx={{
                fontSize: '13.8px'
              }}
            >
              {errors?.authPinSecondary}
            </FormHelperText>
          )}
        </FormControl>
      );
    }
  };

  useEffect(() => {
    setErrors((prev) => {
      let updatedErrors = { ...prev };

      if (values.city && prev.city) {
        updatedErrors.city = '';
      }
      if (values.state && prev.state) {
        updatedErrors.state = '';
      }
      if (values.country && prev.country) {
        updatedErrors.country = '';
      }
      if (values.tax && prev.tax) {
        updatedErrors.tax = '';
      }
      if (values.postalCode && prev.postalCode) {
        updatedErrors.postalCode = '';
      }
      return updatedErrors;
    });
  }, [values.city, values.state, values.country, values.tax, values?.postalCode]);

  return (
    <>
      <>
        <Form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={gridSpacing} sx={{ alignContent: 'center' }}>
            <Grid item xs={12}>
              <Grid container alignItems="center" justifyContent="space-between" sx={{ mb: 2 }} className="scroll-list">
                <Typography component="h4" variant="h2">
                  Organization Details
                </Typography>
                <List
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: 0,
                    width: '600px',
                    marginLeft: 'auto'
                    // Remove extra padding from List
                  }}
                >
                  {buttonPermissions?.map((button, index) => getButtonUI(button, index))}
                  {touched.orgSelection && errors.orgSelection && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-name "
                      className="org-selct-error-txt"
                      sx={{
                        fontSize: '13.8px'
                      }}
                    >
                      {errors.orgSelection}
                    </FormHelperText>
                  )}
                </List>
              </Grid>
              <Grid container spacing={gridSpacing} className="org-first-frm">
                {otherPOPermissions?.map((other, index) => {
                  if (!other.show) return null;
                  if (other.id === 'orgName')
                    return (
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <FormControl fullWidth error={Boolean(touched.OrgName && errors.OrgName)} sx={{ ...theme.typography.customInput }}>
                          <InputLabel htmlFor="outlined-adornment-name">Organization Name *</InputLabel>
                          <OutlinedInput
                            disabled={isEditable ? other?.isStatic : false}
                            id="outlined-adornment-name"
                            type="text"
                            value={values.OrgName}
                            name="OrgName"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              setFieldValue('OrgName', e.target.value);
                              setFieldValue('locationName', `${e.target.value} - primary location`);
                            }}
                            label=" Organization Name"
                            placeholder="e.g. Example Pvt Ltd"
                            inputProps={{}}
                            autoComplete="off"
                          />
                          {touched.OrgName && errors.OrgName && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-name"
                              sx={{
                                fontSize: '13.8px'
                              }}
                            >
                              {errors.OrgName}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    );
                  else if (other.id === 'website') {
                    return (
                      <Grid key={index} item lg={6} md={6} sm={6} xs={12}>
                        <FormControl fullWidth error={Boolean(touched.OrgWeb && errors.OrgWeb)} sx={{ ...theme.typography.customInput }}>
                          <InputLabel htmlFor="outlined-adornment-website">Organization Website Address</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-website"
                            type="text"
                            value={values.OrgWeb}
                            name="OrgWeb"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label=" Retailer Web"
                            inputProps={{}}
                            autoComplete="off"
                            placeholder="e.g. https://www.fosub.org"
                          />
                          {touched.OrgWeb && errors.OrgWeb && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-email-login"
                              sx={{
                                fontSize: '13.8px'
                              }}
                            >
                              {errors.OrgWeb}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    );
                  } else if (
                    other.id === 'serviceRadius' &&
                    (values?.orgSelection?.includes('Recycler') || values?.orgSelection?.includes('Service Provider'))
                  ) {
                    return (
                      <Grid key={index} item lg={6} md={6} sm={6} xs={12}>
                        <FormControl
                          fullWidth
                          error={Boolean(touched.service_radius && errors.service_radius)}
                          sx={{ ...theme.typography.customInput }}
                        >
                          <InputLabel htmlFor="outlined-adornment-service-radius">Service Radius *</InputLabel>
                          <OutlinedInput
                            disabled={isEditable ? other?.isStatic : false}
                            id="outlined-adornment-service-radius"
                            type="number"
                            name="service_radius"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label="Service Radius *"
                            placeholder="e.g. 123"
                            value={values?.service_radius}
                            endAdornment={
                              <InputAdornment
                                position="end"
                                sx={{
                                  fontSize: '10px',
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                <FormControl size="small">
                                  <Select
                                    value={values?.serviceRadiusUnit}
                                    onChange={(e) => setFieldValue('serviceRadiusUnit', e.target.value)}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Radius Unit' }}
                                    sx={{
                                      fontSize: '10px'
                                    }}
                                  >
                                    <MenuItem value="Miles">Miles</MenuItem>
                                    <MenuItem value="Km">Km</MenuItem>
                                  </Select>
                                </FormControl>
                              </InputAdornment>
                            }
                            autoComplete="off"
                            onKeyDown={(e) => {
                              ['e', 'E', '+', '-', '#', '%', '^'].includes(e.key) && e.preventDefault();
                            }}
                          />
                          {touched?.service_radius && errors.service_radius && (
                            <FormHelperText
                              error
                              id="standard-weight-helper-text-email-login"
                              sx={{
                                fontSize: '13.8px'
                              }}
                            >
                              {errors.service_radius}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    );
                  }
                })}

                {/* service-worker */}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={gridSpacing}>
                <Grid item xs={12} md={6}>
                  <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}>
                    <FormControl sx={{ width: '100%', display: 'block', padding: '18px 15px 20px', background: '#6fd74b' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '-5px',
                          color: '#fff',
                          fontWeight: '500',
                          fontSize: '24px',
                          width: '100%'
                        }}
                      >
                        <Typography
                          component="h4"
                          variant="h3"
                          // sx={{ mr: 1, pb: 1.8 }}
                          sx={{ color: '#fff' }}
                        >
                          Primary Contact
                        </Typography>
                      </Box>
                    </FormControl>

                    <div style={{ padding: '10px 15px 15px' }}>{mainPOPermissions?.map((main, index) => getMainPOUI(main, index))}</div>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}>
                    <FormControl sx={{ width: '100%', display: 'block', padding: '10px 15px', background: '#6fd74b' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          marginBottom: '-5px',
                          color: '#fff',
                          fontWeight: '500',
                          fontSize: '24px',
                          width: '100%'
                        }}
                      >
                        <Checkbox
                          sx={{ color: '#fff!important', borderColor: '#fff' }}
                          // defaultChecked={false}
                          checked={values.isChecked}
                          name="isChecked"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            setFieldValue('isChecked', e.target.checked);

                            if (e.target.checked) {
                              setFieldValue('secondaryTitle', values.title);
                              setFieldValue('secondaryFirstName', values.firstName);
                              setFieldValue('secondaryLastName', values.lastName);
                              setFieldValue('secondaryDesignation', values.designation),
                                setFieldValue('SecondaryEmpEmail', values.associateEmpEmail),
                                setFieldValue('SecondaryEmpPhone', values.associateEmpPhone);
                              setFieldValue('SecondaryEmpAdd', values.associateEmpAdd);
                              setFieldValue('rfIdCodeSecondary', values.rfIdCodePrimary);
                              setFieldValue('authPinSecondary', values.authPinPrimary);
                              setTouched({});
                            } else {
                              setFieldValue('secondaryTitle', '');
                              setFieldValue('secondaryFirstName', '');
                              setFieldValue('secondaryLastName', '');
                              setFieldValue('secondaryDesignation', ''),
                                setFieldValue('SecondaryEmpEmail', ''),
                                setFieldValue('SecondaryEmpPhone', '');
                              setFieldValue('SecondaryEmpAdd', '');
                              setFieldValue('rfIdCodeSecondary', '');
                              setFieldValue('authPinSecondary', '');
                              setTouched({});
                            }
                          }}
                          value={values.isChecked}
                        />
                        <Typography component="h4" variant="h3" sx={{ color: '#fff' }}>
                          Secondary Contact
                        </Typography>
                      </Box>
                    </FormControl>
                    <div style={{ padding: '10px 15px 15px' }}>
                      {secondPOPermissions?.map((second, index) => getSecondUI(second, index))}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>

            {/* Open the above code for enterprise user later */}

            <Grid item xs={12}>
              <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}>
                <div className="address_locationmain" style={{ background: '#6fd74b', mb: 2, padding: '10px 15px' }}>
                  <Typography component="h4" variant="h2" sx={{ color: '#fff', fontWeight: '500', fontSize: '24px' }}>
                    Organization Locations
                  </Typography>
                  {/* <IconButton>
                    <AddLocationAltOutlinedIcon
                      variant="contained"
                      color="secondary"
                      sx={{
                        // display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' },
                        color: '#fff'
                        // height: 20
                      }}
                      className="add-location"
                      fullWidth
                      // size="small"
                      onClick={() => {
                        setOpenLocationModal(!openLocationModal);
                      }}
                    />
                  </IconButton> */}
                </div>
                <div style={{ padding: '10px 15px 15px' }}>
                  <Grid container spacing={gridSpacing}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      {otherPOPermissions?.map((other, index) => {
                        if (!other.show) return null;
                        if (other.id === 'address') {
                          return (
                            <FormControl key={index} fullWidth error={Boolean(touched.locationPickUp && errors.locationPickUp)}>
                              {/* <InputLabel htmlFor="autocomplete">Select Location</InputLabel> */}
                              <Autocomplete
                                disabled={isEditable ? other?.isStatic : false}
                                // {...props}
                                id="autocomplete"
                                multiple
                                // limitTags={2}
                                options={allLocationAllLists?.data ?? []}
                                // value={selectedOptions}
                                value={values?.locationPickUp || []}
                                getOptionLabel={(option) => option?.name}
                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                // onChange={(event, value) => {
                                //   if (value?.length === 1) {
                                //     setFieldValue('locationPickUp', [{ ...value[0], isDefault: true }]);
                                //   }

                                //    else {
                                //     setFieldValue('locationPickUp', value);
                                //   }
                                // }}
                                onChange={(event, value) => {
                                  if (value.length === 1) {
                                    setFieldValue('locationPickUp', [{ ...value[0], isPrimary: true }]);
                                  } else {
                                    let newVal = value.filter((item) => item.isPrimary == true);
                                    if (newVal.length == 0) {
                                      newVal = value.map((item, index) => (index == 0 ? { ...item, isPrimary: true } : item));
                                      setFieldValue('locationPickUp', newVal);
                                    } else {
                                      setFieldValue('locationPickUp', value);
                                    }
                                  }
                                }}
                                renderTags={(value, getTagProps) =>
                                  value.map((option, index) => {
                                    return (
                                      <CustomChip
                                        key={index}
                                        label={option?.name}
                                        isPrimary={option?.isPrimary}
                                        {...getTagProps({ index })}
                                        onClick={() => {
                                          let data = values?.locationPickUp?.map((location) =>
                                            location?.id === option?.id
                                              ? { ...location, isPrimary: true }
                                              : { ...location, isPrimary: false }
                                          );

                                          setFieldValue('locationPickUp', data);
                                        }}
                                      />
                                    );
                                  })
                                }
                                // value={values?.locationPickUp}

                                renderInput={(params) => (
                                  <TextField
                                    className="enterprise-location"
                                    id="notes-recycler-textarea"
                                    name="locationPickUp"
                                    {...params}
                                    htmlFor="autocomplete"
                                    label="Select Locations"
                                    placeholder="e.g. Street, City, State, Country etc."
                                    // variant="outlined"
                                    // margin="normal"
                                    fullWidth
                                    error={Boolean(touched.locationPickUp && errors.locationPickUp)}
                                    //value={values?.locationPickUp}

                                    // error={Boolean(form.errors[name] && form.touched[name])}
                                    // helperText={form.errors[name] && form.touched[name] && String(form.errors[name])}
                                  />
                                )}
                              />

                              {touched.locationPickUp && errors.locationPickUp && (
                                <FormHelperText
                                  error
                                  id="standard-weight-helper-text-email-login"
                                  sx={{
                                    fontSize: '13.8px'
                                  }}
                                >
                                  {errors.locationPickUp}
                                </FormHelperText>
                              )}
                            </FormControl>
                          );
                        }
                      })}
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      {/* <Divider /> */}
                      <TableContainer sx={{ maxHeight: 440 }}>
                        {isAllLocationLoading ? (
                          <TableBody>
                            {[1, 2, 3, 4].map((i) => (
                              <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                                <TableCell align="center">
                                  <Skeleton variant="text" width={200} />
                                </TableCell>
                                <TableCell align="center">
                                  <Skeleton variant="text" width={200} />
                                </TableCell>
                                <TableCell align="center">
                                  <Skeleton variant="text" width={200} />
                                </TableCell>
                                <TableCell align="center">
                                  <Skeleton variant="text" width={200} />
                                </TableCell>
                                <TableCell align="center">
                                  <Skeleton variant="text" width={200} />
                                </TableCell>
                                <TableCell align="center">
                                  <Skeleton variant="text" width={200} />
                                </TableCell>
                                <TableCell align="center">
                                  <IconButton>
                                    <Skeleton variant="circular" width={40} height={40} />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        ) : (
                          <>
                            {values?.locationPickUp?.length > 0 && (
                              <Table
                                stickyHeader
                                aria-label="sticky table"
                                fullWidth
                                sx={{ ...theme.typography.customInput }}
                                className="new-table-classs"
                              >
                                <EnhancedTableHead />
                                <TableBody>
                                  {values?.locationPickUp?.map((row, i) => (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                                      <TableCell align="center">{row?.name}</TableCell>
                                      <TableCell
                                        align="center"
                                        sx={{
                                          paddingLeft: 0,
                                          paddingRight: 2,
                                          alignItems: 'center'
                                        }}
                                      >
                                        {row?.address?.fullAddress}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            )}
                          </>
                        )}
                      </TableContainer>

                      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="row">
                        <Box
                          sx={{
                            border: '1px dashed',
                            width: '100px',
                            borderColor: '#CCCCCC'
                          }}
                        />
                        <Typography sx={{ mx: 1, color: '#a8a5a5' }}>OR</Typography>
                        <Box
                          sx={{
                            border: '1px dashed',
                            width: '100px',
                            borderColor: '#CCCCCC'
                          }}
                        />
                      </Box>
                      <Grid
                        container
                        spacing={gridSpacing}
                        className="org-first-frm"
                        style={{
                          marginTop: '10px'
                        }}
                      >
                        {/* Location Name */}

                        <Grid container item lg={6} md={6} sm={6} xs={12}>
                          <FormControl
                            fullWidth
                            // error={Boolean(touched.locationName && errors.locationName)}
                            error={Boolean(touched.locationName && errors.locationName)}
                            sx={{ ...theme.typography.customInput }}
                          >
                            <InputLabel htmlFor="outlined-adornment-name">Location Name</InputLabel>

                            <OutlinedInput
                              // disabled={isEditable ? other?.isStatic : false}
                              id="outlined-adornment-name"
                              type="text"
                              value={values.locationName}
                              name="locationName"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              label=" Location Name"
                              inputProps={{}}
                              autoComplete="off"
                            />

                            {errors?.locationName && touched?.locationName && (
                              <FormHelperText
                                error
                                id="standard-weight-helper-text-name"
                                sx={{
                                  fontSize: '13.8px'
                                }}
                              >
                                {errors.locationName}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        {/* Tax */}

                        <Grid container item lg={6} md={6} sm={6} xs={12}>
                          <FormControl fullWidth error={Boolean(touched.tax && errors.tax)} sx={{ ...theme.typography.customInput }}>
                            <InputLabel htmlFor="outlined-adornment-service-tax"> Tax % </InputLabel>
                            <OutlinedInput
                              // disabled={isEditable ? permission?.isStatic : false}
                              id="outlined-adornment-service-tax"
                              type="number"
                              value={values.tax}
                              name="tax"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              label="Tax %"
                              placeholder="0.00"
                              autoComplete="off"
                              onPaste={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                              onCopy={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                              onKeyDown={(e) => {
                                exceptThisSymbols.includes(e.key) && e.preventDefault();
                              }}
                            />

                            {errors?.tax && touched?.tax && (
                              <FormHelperText
                                error
                                id="standard-weight-helper-text-name"
                                sx={{
                                  fontSize: '13.8px'
                                }}
                              >
                                {errors.tax}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        {/*Location Address */}
                        <Grid container item lg={12} md={12} sm={12} xs={12}>
                          <FormControl
                            fullWidth
                            error={Boolean(touched?.location && errors?.location)}
                            sx={{ ...theme.typography.customInput }}
                          >
                            <InputLabel htmlFor="outlined-adornment-name">Address</InputLabel>

                            <GoogleMaps
                              // validateForm={validateForm}
                              onBlur={handleBlur}
                              handleChange={handleChange}
                              setFieldValue={setFieldValue}
                              values={values}
                              setLocationLatLng={setLocationLatLng}
                              setFieldError={setFieldError}
                              setFieldTouched={setFieldTouched}
                              validateField={validateField}
                              setTouched={setTouched}
                              setErrors={setErrors}
                            />
                            {errors?.location && touched?.location && (
                              <FormHelperText
                                error
                                id="standard-weight-helper-text-name"
                                sx={{
                                  fontSize: '13.8px'
                                }}
                              >
                                {errors.location}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        {/* City */}
                        <Grid container item lg={6} md={6} sm={6} xs={12}>
                          <FormControl fullWidth error={Boolean(touched.city && errors.city)} sx={{ ...theme.typography.customInput }}>
                            <InputLabel htmlFor="outlined-adornment-name">City</InputLabel>
                            <OutlinedInput
                              // disabled={isEditable ? other?.isStatic : false}
                              id="outlined-adornment-name"
                              type="text"
                              value={values.city}
                              name="city"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              label="city"
                              // placeholder="e.g. Example Pvt Ltd"
                              inputProps={{}}
                              autoComplete="off"
                            />
                            {touched.city && errors.city && (
                              <FormHelperText
                                error
                                id="standard-weight-helper-text-name"
                                sx={{
                                  fontSize: '13.8px'
                                }}
                              >
                                {errors.city}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        {/* State/Province */}
                        <Grid container item lg={6} md={6} sm={6} xs={12}>
                          <FormControl fullWidth error={Boolean(touched.state && errors.state)} sx={{ ...theme.typography.customInput }}>
                            <InputLabel htmlFor="outlined-adornment-name">State / Province</InputLabel>
                            <OutlinedInput
                              // disabled={isEditable ? other?.isStatic : false}
                              id="outlined-adornment-name"
                              type="text"
                              value={values.state}
                              name="state"
                              onChange={handleChange}
                              label="State / Province"
                              // placeholder="e.g. Example Pvt Ltd"
                              inputProps={{}}
                              autoComplete="off"
                            />
                            {touched.state && errors.state && (
                              <FormHelperText
                                error
                                id="standard-weight-helper-text-name"
                                sx={{
                                  fontSize: '13.8px'
                                }}
                              >
                                {errors.state}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        {/* Country */}
                        <Grid container item lg={6} md={6} sm={6} xs={12}>
                          <FormControl
                            fullWidth
                            error={Boolean(touched.country && errors.country)}
                            sx={{ ...theme.typography.customInput }}
                          >
                            <InputLabel htmlFor="outlined-adornment-name">Country</InputLabel>
                            <OutlinedInput
                              // disabled={isEditable ? other?.isStatic : false}
                              id="outlined-adornment-name"
                              type="text"
                              value={values.country}
                              name="country"
                              onChange={handleChange}
                              label="Country"
                              // placeholder="e.g. Example Pvt Ltd"
                              inputProps={{}}
                              autoComplete="off"
                            />
                            {touched.country && errors.country && (
                              <FormHelperText
                                error
                                id="standard-weight-helper-text-name"
                                sx={{
                                  fontSize: '13.8px'
                                }}
                              >
                                {errors.country}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        {/* Postal Code */}
                        <Grid container item lg={6} md={6} sm={6} xs={12}>
                          <FormControl
                            fullWidth
                            error={Boolean(touched.postalCode && errors.postalCode)}
                            sx={{ ...theme.typography.customInput }}
                          >
                            <InputLabel htmlFor="outlined-adornment-name">Postal Code</InputLabel>
                            <OutlinedInput
                              // disabled={isEditable ? other?.isStatic : false}
                              id="outlined-adornment-name"
                              type="text"
                              value={values.postalCode}
                              name="postalCode"
                              onChange={handleChange}
                              label="Postal Code"
                              // placeholder="e.g. Example Pvt Ltd"
                              inputProps={{}}
                              autoComplete="off"
                            />
                            {touched.postalCode && errors.postalCode && (
                              <FormHelperText
                                error
                                id="standard-weight-helper-text-name"
                                sx={{
                                  fontSize: '13.8px'
                                }}
                              >
                                {errors.postalCode}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>

            <Grid item xs={12}>
              {errors.submit && (
                <Box sx={{ mt: 3 }}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
              <Box sx={{ mt: 5, display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                <Box sx={{ display: 'inline-block' }}>
                  <AnimateButton>
                    <Button
                      disableElevation
                      disabled={isLoadingOrganizationNew}
                      size="large"
                      type="submit"
                      variant="contained"
                      color="secondary"
                      style={{
                        color: 'white',
                        minWidth: '200px',
                        margin: '0px auto'
                      }}
                    >
                      {isLoadingOrganizationNew ? (
                        <>
                          <CircularProgress
                            sx={{
                              color: 'white',
                              height: 20,
                              width: 20
                            }}
                          />
                        </>
                      ) : (
                        `${btnName}`
                      )}
                    </Button>
                  </AnimateButton>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Form>
        {/* <LocationDialog open={openLocationModal} handleClose={() => setOpenLocationModal(false)} /> */}
      </>
      {/* </Formik> */}
      {/* </MainCard> */}
    </>
  );
};

export default OrganizationsNewForm;
