import { Form, Formik } from 'formik';
import React from 'react';
import SupportTicketFilterForm from './SupportTicketFilterForm';
// import GetSupportFilterForm from './GetSupportFilterForm';

const SupportTicketFilter = ({ setFilterObj, filterObj, isExistingFilter, permissionsList }) => {
  //   const getSupportFilters = permissionsList?.view?.filters ?? [];
  return (
    <>
      <Formik
        initialValues={{
          status: 'All',
          supportType: 'Select Support Type',
          subject: ''
          //   organizeName: 'Search Organization Name'
        }}
        onSubmit={(values) => {
          setFilterObj({
            status: values?.status === 'All' ? '' : values?.status,
            subject: values?.subject || null,
            // organizeName: values?.organizeName === 'Search Organization Name' ? '' : values?.organizeName,
            supportType: values?.supportType === 'Select Support Type' ? '' : values?.supportType
          });
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue, setTouched, resetForm }) => (
          <Form>
            <SupportTicketFilterForm
              errors={errors}
              handleBlur={handleBlur}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              touched={touched}
              values={values}
              setFieldValue={setFieldValue}
              setTouched={setTouched}
              resetForm={resetForm}
              filterObj={filterObj}
              setFilterObj={setFilterObj}
              isExistingFilter={isExistingFilter}
              permissionsList={permissionsList}
              //   getSupportFilters={getSupportFilters}
            />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SupportTicketFilter;
