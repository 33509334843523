import { Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import MainCard from 'ui-component/cards/MainCard';
// import FormView from 'ui-component/cards/Skeleton/FormView';
import BreadcrumbsForPage from 'ui-component/extended/BreadcrumbsForPage';
// import RetailerForm from '../retailer-form';
import * as Yup from 'yup';
import 'yup-phone';
// import { useRetailerAdd } from 'hooks/useRetailerHooks';
import { useMutation } from '@tanstack/react-query';
// import { setToast, toastConfig } from 'utils/commonUtil';
import OrganizationFormView from 'ui-component/cards/Skeleton/OrganizationFormView';
import OrganizationsNewForm from '../form';
import { useGetPermissionsOrganizationNewList, UseOrganizationNewAdd } from 'hooks/useOrganizationNewHooks';
import { setToast, toastConfig } from 'utils/commonUtil';
// import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { Alert, AlertTitle } from '@mui/material';
import { Lock } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
const OrganizationNewAdd = () => {
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const organizationType = useSelector((state) => state.customization.orgType);
  const usersType = useSelector((state) => state.customization.userType);
  const {
    data: permissionsList

    // isLoading: isLoadingPermissionsList
  } = useGetPermissionsOrganizationNewList({
    // organizationType:
    //   organizationType !== 'Service Provider' ? 'ServiceProvider' : organizationType !== 'Super Admin' ? organizationType : 'SuperAdmin',
    // usersType: usersType === 'Main POC' ? 'POC' : 'POC'
    organizationType:
      organizationType === 'Service Provider' ? 'ServiceProvider' : organizationType === 'Super Admin' ? 'SuperAdmin' : organizationType,
    // usersType: usersType === 'Main POC' ? 'POC' : 'POC'
    usersType: usersType === 'Main POC' || usersType === 'Secondary POC' ? 'POC' : usersType
  });

  // Access the state object
  const addPermissionsList = permissionsList?.add || [];

  const permissionsShowAdd = permissionsList?.showAdd;

  const [locationLatLng, setLocationLatLng] = useState({
    lat: '',
    lng: '',
    label: ''
  });
  let buttonPermissions = [];
  let mainPOPermissions = [];
  let secondPOPermissions = [];
  let otherPOPermissions = [];
  addPermissionsList?.forEach((permission) => {
    if (permission?.id?.indexOf('Option') != -1) {
      buttonPermissions.push(permission);
    } else if (permission?.id?.indexOf('main') != -1) {
      mainPOPermissions.push(permission);
    } else if (permission?.id?.indexOf('secondary') != -1) {
      secondPOPermissions.push(permission);
    } else {
      otherPOPermissions.push(permission);
    }
  });

  // const userData = JSON.parse(localStorage.getItem('userData'));
  // const [checked, setChecked] = useState(false);
  // const [asMainPOC, setAsMainPOC] = useState(false);
  // const [asSecPOC, setSecPOC] = useState(false);

  // const { mutate: addRetailer, isPending: isLoadingRetailer } = useRetailerAdd();
  const { mutate: addOrganizationNew, isPending: isLoadingOrganizationNew } = useMutation({
    mutationFn: UseOrganizationNewAdd
  });
  // const phoneRegex = RegExp(/^((\+1)|1)? ?\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})( ?(ext\.? ?|x)(\d*))?$/);
  // const queryClient = useQueryClient();
  const authPinRegex = /^[0-9]+$/;

  // const phoneRegexMain = RegExp(/^((\+1)|1)? ?\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})( ?(ext\.? ?|x)(\d*))?$/);
  const phoneRegexMain = RegExp(/^\d{3}-\d{3}-\d{4}$/);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const initials = {
    associateEmpEmail: '',
    associateEmpPhone: '',
    associateEmpAdd: '',
    EmpFullAddress: '',
    EmpCity: '',
    EmpState: '',
    EmpCountry: '',
    EmpPostal_Code: '',
    SecondaryEmpEmail: '',
    SecondaryEmpPhone: '',
    SecondaryEmpAdd: '',
    OrgName: '',
    OrgWeb: '',
    isChecked: false,
    submit: null,
    title: '',
    firstName: '',
    lastName: '',
    designation: '',

    secondaryTitle: '',
    secondaryFirstName: '',
    secondaryLastName: '',
    secondaryDesignation: '',
    placeId: '',
    latitude: '',
    longitude: '',
    orgSelection: [],
    ///recycler//
    service_radius: '',
    serviceRadiusUnit: 'Miles',
    rfIdCodePrimary: '',
    // authPinPrimary: null,
    // authPinSecondary: null,
    authPinPrimary: '',
    authPinSecondary: '',
    rfIdCodeSecondary: '',
    // newly added
    location: '',
    city: '',
    state: '',
    country: '',
    postalCode: '',
    tax: '',
    locationName: '',
    locationPickUp: [],

    countryCode: ''
  };
  const dynamicalidationSchema = Yup.object(
    addPermissionsList.slice(5, 27).reduce((schema, filter) => {
      if (filter?.show) {
        switch (filter.id) {
          case 'mainPOCPrefix':
            schema['title'] = Yup.string().trim();
            if (filter.isRequired) schema['title'] = schema['title'].required('Prefix is required');
            break;
          case 'mainPOCTitle':
            schema['designation'] = Yup.string().max(50, 'Employee Title must be of 50 characters').trim();
            if (filter.isRequired) schema['designation'] = schema['designation'].required('Employee Title is required');
            break;
          case 'mainPOCFirstName':
            schema['firstName'] = Yup.string()
              .max(50, 'Employee First Name must be at most 50 characters')
              .trim()
              .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')
              .matches(/^[^!@#$%^&*()_+\-=\\/[\]{}:';"\\|,.<>\\?]*$/, 'Employee First Name should not include special characters');
            if (filter.isRequired) schema['firstName'] = schema['firstName'].required('Employee First Name is required');
            break;
          case 'mainPOCLastName':
            schema['lastName'] = Yup.string()
              .max(50, 'Employee Last Name must be at most 50 characters')
              .trim()
              .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')
              .matches(/^[^!@#$%^&*()_+\-=\\/[\]{}:';"\\|,.<>\\?]*$/, 'Employee Last Name should not include special characters');
            if (filter.isRequired) schema['lastName'] = schema['lastName'].required('Employee Last Name is required');
            break;
          case 'mainPOCEmail':
            schema['associateEmpEmail'] = Yup.string().email('Invalid email address');
            if (filter.isRequired) schema['associateEmpEmail'] = schema['associateEmpEmail'].required('Employee Email is required');
            break;
          case 'mainPOCPhone':
            schema['associateEmpPhone'] =
              // Yup.number();
              // .min(0)

              // .positive('Employee Phone should be positive number')
              // .integer('Employee Phone should be integer number');
              Yup.string().matches(phoneRegexMain, 'Employee Phone is not valid').trim();

            // if (filter.isRequired) schema['associateEmpPhone'] = schema['associateEmpPhone']
            //   .required('Employee Phone is required')
            //   ;
            break;
          // case 'mainPOCAddress':
          //   schema['associateEmpAdd'] = Yup.string().max(150, 'Must be at most 150 characters').trim();
          //   if (filter.isRequired) schema['associateEmpAdd'] = schema['associateEmpAdd'].required('Employee Address is required');
          //   break;
          case 'secondaryPOCPrefix':
            schema['secondaryTitle'] = Yup.string().trim();
            if (filter.isRequired) schema['secondaryTitle'] = schema['secondaryTitle'].required('Prefix is required');
            break;
          case 'secondaryPOCTitle':
            schema['secondaryDesignation'] = Yup.string().max(50, 'Employee Title must be at most 50 characters').trim();
            if (filter.isRequired) schema['secondaryDesignation'] = schema['secondaryDesignation'].required('Employee Title is required');
            break;
          case 'secondaryPOCFirstName':
            schema['secondaryFirstName'] = Yup.string()
              .max(50, 'Employee First Name must be at most 50 characters')
              .trim()
              .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')
              .matches(/^[^!@#$%^&*()_+\-=\\/[\]{}:';"\\|,.<>\\?]*$/, 'Employee First Name should not include special characters');
            if (filter.isRequired) schema['secondaryFirstName'] = schema['secondaryFirstName'].required('Employee First Name is required');
            break;
          case 'secondaryPOCLastName':
            schema['secondaryLastName'] = Yup.string()
              .max(50, 'Employee Last Name must be at most 50 characters')
              .trim()
              .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')
              .matches(/^[^!@#$%^&*()_+\-=\\/[\]{}:';"\\|,.<>\\?]*$/, 'Employee Last Name should not include special characters');
            if (filter.isRequired) schema['secondaryLastName'] = schema['secondaryLastName'].required('Employee Last Name is required');
            break;
          case 'secondaryPOCEmail':
            schema['SecondaryEmpEmail'] = Yup.string().email('Invalid email address');
            if (filter.isRequired) schema['SecondaryEmpEmail'] = schema['SecondaryEmpEmail'].required('Employee Email is required');
            break;
          case 'secondaryPOCPhone':
            schema['SecondaryEmpPhone'] =
              // Yup.string().matches(phoneRegex, 'Employee Phone is not valid').trim();
              Yup.string().matches(phoneRegexMain, 'Employee Phone is not valid').trim();
            // if (filter.isRequired) schema['SecondaryEmpPhone'] = schema['SecondaryEmpPhone'].required('Employee Phone is required');
            break;
          // case 'secondaryPOCAddress':
          //   schema['SecondaryEmpAdd'] = Yup.string().max(150, 'Employee Address must be at most 150 characters').trim();
          //   if (filter.isRequired) schema['SecondaryEmpAdd'] = schema['SecondaryEmpAdd'].required('Employee Address is required');
          //   break;
          case 'mainPOCRFID':
            schema['rfIdCodePrimary'] = Yup.string().when('orgSelection', {
              is: (orgSelection) => orgSelection.includes('Enterprise') && filter.isRequired,
              then: Yup.string().required('RFID Code is required'),
              otherwise: Yup.string().nullable()
            });
            break;
          case 'mainPOCAuthPIN':
            schema['authPinPrimary'] =
              // Yup.number()
              // .integer('Auth PIN must be an integer')
              // .min(0, 'Auth PIN cannot be negative')
              // .typeError('Auth PIN must be a valid number');
              Yup.string().matches(authPinRegex, 'Auth PIN must be a valid number').trim();
            if (filter.isRequired) {
              schema['authPinPrimary'] = schema['authPinPrimary'].required('Auth PIN is required');
            }

            break;
          case 'secondaryPOCRFID':
            schema['rfIdCodeSecondary'] = Yup.string().when('orgSelection', {
              is: (orgSelection) => orgSelection.includes('Enterprise') && filter.isRequired,
              then: Yup.string().required('RFID Code is required'),
              otherwise: Yup.string().nullable()
            });
            break;
          case 'secondaryPOCAuthPIN':
            schema['authPinSecondary'] =
              // Yup.number()
              // .integer('Auth PIN must be an integer')
              // .min(0, 'Auth PIN cannot be negative')
              // .typeError('Auth PIN must be a valid number');
              Yup.string().matches(authPinRegex, 'Auth PIN must be a valid number').trim();
            if (filter.isRequired) {
              schema['authPinSecondary'] = schema['authPinSecondary'].required('Auth PIN is required');
            }

            break;
          case 'serviceRadius':
            schema['service_radius'] = Yup.string().when('orgSelection', {
              is: (orgSelection) => (orgSelection.includes('Recycler') || orgSelection.includes('Service Provider')) && filter.isRequired,
              then: Yup.string().required('Service Radius is required'),
              otherwise: Yup.string().nullable()
            });
            break;
          case 'orgName':
            schema['OrgName'] = Yup.string()
              .max(50, 'Organization Name must be at most 50 characters')
              .trim()
              .matches(/^(?!\s+$).*/, 'Spaces should not be allowed!')
              .matches(/^[a-zA-Z0-9 .]*$/, 'Organization Name can only include letters, numbers, spaces, and dots');
            if (filter.isRequired) schema['OrgName'] = schema['OrgName'].required('Organization Name is required');
            break;
          case 'website':
            schema['OrgWeb'] = Yup.string().url('Please enter a valid Organization Website Address');
            if (filter.isRequired) schema['OrgWeb'] = schema['OrgWeb'].required('Website Address is required');
            break;
          case 'address':
            // schema['locationPickUp'] = Yup.array().min(1, 'At least one location is required');
            schema['locationPickUp'] = Yup.array();
            if (filter.isRequired) schema['locationPickUp'] = schema['locationPickUp'].required('Select Location is required');
            break;
          default:
            break;
        }
      }
      return schema;
    }, {})
  );

  const staticValidationSchema_old = Yup.object().shape(
    {
      orgSelection: Yup.array().min(1, 'At least one organization type must be selected').required('Organization selection is required'),

      location: Yup.string().when(['city', 'state', 'country', 'postalCode', 'tax', 'locationName'], {
        is: (dep_1, dep_2, dep_3, dep_4, dep_5, dep_6) => dep_1 || dep_2 || dep_3 || dep_4 || dep_5 || dep_6,
        then: Yup.string().required('Location Address is required'),
        otherwise: Yup.string().notRequired()
      }),
      city: Yup.string().when(['location', 'state', 'country', 'postalCode', 'tax', 'locationName'], {
        is: (dep_1, dep_2, dep_3, dep_4, dep_5, dep_6) => dep_1 || dep_2 || dep_3 || dep_4 || dep_5 || dep_6,
        then: Yup.string().required('City is required'),
        otherwise: Yup.string().notRequired()
      }),
      state: Yup.string().when(['location', 'city', 'country', 'postalCode', 'tax', 'locationName'], {
        is: (dep_1, dep_2, dep_3, dep_4, dep_5, dep_6) => dep_1 || dep_2 || dep_3 || dep_4 || dep_5 || dep_6,
        then: Yup.string().required('State is required'),
        otherwise: Yup.string().notRequired()
      }),
      country: Yup.string().when(['location', 'city', 'state', 'postalCode', 'tax', 'locationName'], {
        is: (dep_1, dep_2, dep_3, dep_4, dep_5, dep_6) => dep_1 || dep_2 || dep_3 || dep_4 || dep_5 || dep_6,
        then: Yup.string().required('Country is required'),
        otherwise: Yup.string().notRequired()
      }),
      postalCode: Yup.string().when(['location', 'city', 'state', 'country', 'tax', 'locationName'], {
        is: (dep_1, dep_2, dep_3, dep_4, dep_5, dep_6) => dep_1 || dep_2 || dep_3 || dep_4 || dep_5 || dep_6,
        then: Yup.string().max(10, 'Must be of 10 characters').required('Postal Code is required'),
        otherwise: Yup.string().notRequired()
      }),
      // tax: Yup.number().when(['location', 'city', 'state', 'country', 'postalCode', 'locationName'], {
      //   is: (dep_1, dep_2, dep_3, dep_4, dep_5, dep_6) => dep_1 || dep_2 || dep_3 || dep_4 || dep_5 || dep_6,
      //   then: Yup.number().required('Tax is required').min(0, 'Tax cannot be negative').max(100, 'Tax cannot exceed 100'),
      //   otherwise: Yup.number().notRequired()
      // }),
      tax: Yup.number().when(['location', 'city', 'state', 'country', 'postalCode', 'locationName'], {
        is: (...deps) => deps.some((dep) => Boolean(dep)), // Ensures at least one dependency is truthy
        then: Yup.number()
          .required('Tax is required')
          .min(0, 'Tax cannot be negative')
          .max(100, 'Tax cannot exceed 100')
          .test(
            'is-decimal',
            'Tax must have at most two decimal places',
            (value) => value === undefined || value === null || /^\d+(\.\d{1,2})?$/.test(value)
          )
          .typeError('Tax must be a number'),
        otherwise: Yup.number()
          .notRequired()
          .positive('Tax must contain a positive number')
          .max(100, 'Maximum allowed value is 100')
          .test(
            'is-decimal',
            'Tax must have at most two decimal places',
            (value) => value === undefined || value === null || /^\d+(\.\d{1,2})?$/.test(value)
          )
          .typeError('Tax must be a number')
      }),

      locationName: Yup.string().when(['location', 'city', 'state', 'country', 'postalCode', 'tax'], {
        is: (dep_1, dep_2, dep_3, dep_4, dep_5, dep_6) => dep_1 || dep_2 || dep_3 || dep_4 || dep_5 || dep_6,
        then: Yup.string()
          .required('Location Name is required')
          // .matches(/^[a-zA-Z0-9 ]*$/, 'Location Name must not contain special characters')
          .max(50, 'Location Name must be at least 50 characters long'),
        otherwise: Yup.string()
          // .matches(/^[a-zA-Z0-9 ]*$/, 'Location Name must not contain special characters')
          .max(50, 'Location Name must be at least 50 characters long')
          .notRequired()
      })
    },
    [
      ['location', 'city'],
      ['location', 'state'],
      ['location', 'country'],
      ['location', 'postalCode'],
      ['location', 'tax'],
      ['location', 'locationName'],
      ['city', 'state'],
      ['city', 'country'],
      ['city', 'postalCode'],
      ['city', 'tax'],
      ['city', 'locationName'],
      ['state', 'country'],
      ['state', 'postalCode'],
      ['state', 'tax'],
      ['state', 'locationName'],
      ['country', 'postalCode'],
      ['country', 'tax'],
      ['country', 'locationName'],
      ['postalCode', 'tax'],
      ['postalCode', 'locationName'],
      ['tax', 'locationName']
    ]
  );

  // Merge static and dynamic validation schemas
  const validationSchema = staticValidationSchema_old?.concat(dynamicalidationSchema);
  return (
    <>
      {isLoading ? (
        <>
          <OrganizationFormView />
        </>
      ) : (
        <>
          <BreadcrumbsForPage
            name="Add Organization"
            obj={{ title: 'All Organizations', title2: 'Add Organizations', url: '/dashboard/organizations' }}
          />
          <MainCard>
            <Formik
              initialValues={initials}
              validationSchema={validationSchema}
              onSubmit={async (value) => {
                const _location = value?.locationPickUp
                  .filter((item) => item?.id !== '')
                  .map((item) => ({
                    details: item.id,
                    isPrimary: item?.isPrimary ? item?.isPrimary : false
                  }));

                const requiredFields = ['locationName', 'location', 'city', 'state', 'country', 'postalCode', 'tax'];
                const isLocationFieldsEmpty = requiredFields.every((field) => !value[field]?.trim());
                const isLocationEmpty = _location.length === 0 && isLocationFieldsEmpty;

                if (isLocationEmpty) {
                  toast.warning('Either select an organization location or create a new one');
                  // setIsLocationError(false);
                  return;
                }

                const submitData = {
                  isBothPOCsSame: value?.isChecked,
                  name: value?.OrgName,
                  website: value?.OrgWeb,

                  serviceRadius:
                    value?.orgSelection?.includes('Recycler') || value?.orgSelection?.includes('Service Provider')
                      ? {
                          unit: value?.serviceRadiusUnit,
                          value: value?.service_radius
                        }
                      : null,

                  mainPOC: {
                    prefix: value?.title,
                    title: value?.designation,
                    firstName: value?.firstName,
                    lastName: value?.lastName,
                    email: value?.associateEmpEmail,
                    phone: value?.associateEmpPhone,
                    address: value?.associateEmpAdd,
                    authenticationInfo: {
                      rfidCode: value?.orgSelection?.includes('Enterprise') ? value?.rfIdCodePrimary : '',
                      authPIN: value?.orgSelection?.includes('Enterprise') ? value?.authPinPrimary : ''
                    },
                    // types: ['Main POC'],
                    types: value?.isChecked ? ['Main POC', 'Secondary POC'] : ['Main POC'],
                    organizationTypes: value?.orgSelection
                  },
                  secondaryPOC: {
                    prefix: value?.secondaryTitle,
                    title: value?.secondaryDesignation,
                    firstName: value?.secondaryFirstName,
                    lastName: value?.secondaryLastName,
                    email: value?.SecondaryEmpEmail,
                    phone: value?.SecondaryEmpPhone,
                    address: value?.SecondaryEmpAdd,
                    authenticationInfo: {
                      rfidCode: value?.orgSelection?.includes('Enterprise') ? value?.rfIdCodeSecondary : '',
                      authPIN: value?.orgSelection?.includes('Enterprise') ? value?.authPinSecondary : ''
                    },
                    types: ['Secondary POC'],
                    organizationTypes: value?.orgSelection
                  },

                  types: value?.orgSelection,

                  locations: _location,
                  addedLocation: value?.locationName
                    ? {
                        name: value?.locationName || '',
                        // name: finalLocationName,
                        taxAmount: value?.tax || null,
                        address: {
                          fullAddress: value?.location || '',
                          placeId: value?.placeId || '',
                          coOrdinates: {
                            latitude: value?.latitude || '',
                            longitude: value?.longitude || ''
                          },
                          countryCode: value?.countryCode || '',
                          countryName: value?.country || '',
                          city: value?.city || '',
                          postalCode: value?.postalCode || '',
                          state: value?.state || ''
                        }
                      }
                    : null
                };
                addOrganizationNew(submitData, {
                  onSuccess: (res) => {
                    if (res?.status == 200) {
                      toastConfig.type = 'success';
                      setToast(toastConfig, res?.data.message);

                      navigate('/dashboard/organizations');
                    } else {
                      toastConfig.type = 'error';
                      setToast(toastConfig, error?.response?.data?.error);
                    }
                  },
                  onError: (error) => {
                    toastConfig.type = 'error';
                    setToast(toastConfig, error?.response?.data?.error);
                  }
                });
              }}
              validateOnBlur={true}
              validateOnChange={true}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue,
                setTouched,
                setValues,
                setFieldTouched,
                validateForm,
                setFieldError,
                validateField,
                setErrors
              }) => (
                <>
                  {permissionsShowAdd ? (
                    <>
                      <OrganizationsNewForm
                        btnName="Add Organization"
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        isSubmitting={isSubmitting}
                        touched={touched}
                        values={values}
                        setFieldValue={setFieldValue}
                        id={''}
                        setTouched={setTouched}
                        setValues={setValues}
                        isLoadingOrganizationNew={isLoadingOrganizationNew}
                        buttonPermissions={buttonPermissions}
                        mainPOPermissions={mainPOPermissions}
                        secondPOPermissions={secondPOPermissions}
                        otherPOPermissions={otherPOPermissions}
                        locationLatLng={locationLatLng}
                        setLocationLatLng={setLocationLatLng}
                        setFieldTouched={setFieldTouched}
                        validateForm={validateForm}
                        setFieldError={setFieldError}
                        validateField={validateField}
                        setErrors={setErrors}
                      />
                    </>
                  ) : permissionsShowAdd === undefined ? (
                    <>
                      <OrganizationFormView />
                    </>
                  ) : (
                    <>
                      <Alert variant="outlined" severity="error" icon={<Lock />}>
                        <AlertTitle>Permission Denied</AlertTitle>
                        Creating new Organization(s) is not allowed based on your access level.
                      </Alert>
                    </>
                  )}
                </>
              )}
            </Formik>
          </MainCard>
        </>
      )}
    </>
  );
};

export default OrganizationNewAdd;
