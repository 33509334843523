import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Skeleton,
  Typography,
  Link,
  Button,
  DialogActions,
  Chip
  // CircularProgress
} from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { gridSpacing } from 'store/constant';
import { useLocation } from 'react-router';
import { useGetMarkAsResolved, useGetSupportTicketDetailsId } from 'hooks/useGetSupportTicket';
import ViewTicketSkeleton from 'ui-component/cards/Skeleton/ViewTicketSkeleton';
import { styled } from '@mui/material/styles';

import ReportTicketModal from './report-ticket-modal';
import { useQueryClient } from '@tanstack/react-query';
import { toastConfig } from 'utils/commonUtil';
import DeleteDialog from 'ui-component/delete-dialog';

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  margin: '0 auto',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626'
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959'
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343'
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c'
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff'
  }
}));
const ViewSupportTicket = ({ open, setOpen, details }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const viewSupportID = queryParams?.get('viewSupportID');

  details = {
    ...details,
    id: details?.id ? details?.id : viewSupportID
  };

  const {
    data: supportDetailsData,
    isPending: isLoadingSupportDetails,
    refetch: refetchSupportDetails
  } = useGetSupportTicketDetailsId({
    id: details?.id,
    enabled: open
  });

  const supportDetails = supportDetailsData?.foundTicket;
  console.log('🚀 ~ ViewSupportTicket ~ supportDetails:', supportDetails);
  const supportPermission = supportDetailsData?.permission;
  console.log('🚀 ~ ViewSupportTicket ~ supportPermission:', supportPermission);

  const handleClose = () => {
    setOpen(false);
  };
  const queryClient = useQueryClient();
  const { mutate: markAsResolved, isPending: isLoadingMarkAsResolved } = useGetMarkAsResolved();

  const [openReportModal, setOpenReportModal] = useState(false);
  const [markresolved, setMarkResolved] = useState(false);
  // console.log('jfghhj', moment().diff(moment(supportDetails?.createdAt), 'days'));
  return (
    <>
      <Dialog
        open={open}
        fullWidth={false}
        fullScreen={false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        className="view-support-fullwidth"
      >
        <DialogTitle
          id="alert-dialog-title"
          variant="span"
          style={{
            textTransform: 'capitalize'
          }}
        >
          {isLoadingSupportDetails ? (
            <Skeleton variant="text" width={200} />
          ) : (
            <>
              {`View Support Ticket: ${supportDetails?.number}`}
              {'  '} {'  '}
              <Chip
                size="small"
                label={supportDetails?.status === 'Reopened' ? 'Reopened' : supportDetails?.status === 'Pending' ? 'Pending' : 'Resolved'}
                style={{
                  color: '#fff',
                  fontWeight: '400',
                  backgroundColor:
                    supportDetails?.status === 'Reopened' ? '#2196f3' : supportDetails?.status === 'Pending' ? '#ae30c2' : '#6fd74b'
                }}
              />
            </>
          )}
        </DialogTitle>

        {!isLoadingSupportDetails && (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={(theme) => ({
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme.palette.grey[500]
            })}
          >
            <CloseIcon color="error" />
          </IconButton>
        )}

        <DialogContent dividers>
          {isLoadingSupportDetails ? (
            <ViewTicketSkeleton />
          ) : (
            <Grid container spacing={gridSpacing} sx={{ alignContent: 'center' }}>
              <Grid item xs={12}>
                <div style={{ border: '1px solid #6fd74b', borderRadius: '6px' }}>
                  <div className="address_locationmain" style={{ background: '#6fd74b', marginBottom: '16px', padding: '10px 15px' }}>
                    <Typography component="h4" variant="h3" sx={{ color: '#fff' }}>
                      Support Services Details
                    </Typography>
                  </div>
                  <div style={{ padding: '0px 15px 15px' }}>
                    <Grid container spacing={gridSpacing}>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="support_ticket_view_new">
                        <div style={{ display: 'flex', marginBottom: '10px' }}>
                          {' '}
                          <Typography component="p" variant="h4" sx={{ fontWeight: '600', marginRight: '4px', minWidth: '200px' }}>
                            Support Type :
                          </Typography>
                          <span
                            style={{
                              fontSize: '15px'
                            }}
                          >
                            {supportDetails?.supportType || 'N.A.'}
                          </span>
                        </div>

                        <div style={{ display: 'flex', marginBottom: '10px' }}>
                          {' '}
                          <Typography component={'p'} variant="h4" sx={{ fontWeight: '600', marginRight: '4px', minWidth: '200px' }}>
                            Subject :
                          </Typography>
                          <span
                            style={{
                              fontSize: '15px'
                            }}
                          >
                            {supportDetails?.subject || 'N.A.'}
                          </span>
                        </div>

                        <div style={{ display: 'flex', marginBottom: '10px' }}>
                          {' '}
                          <Typography component={'p'} variant="h4" sx={{ fontWeight: '600', marginRight: '4px', minWidth: '200px' }}>
                            Message :
                          </Typography>
                          <span
                            style={{
                              fontSize: '15px'
                            }}
                          >
                            {supportDetails?.message || 'N.A.'}
                          </span>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>

                <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginTop: '12px' }}>
                  <div className="address_locationmain" style={{ background: '#6fd74b', marginBottom: '16px', padding: '10px 15px' }}>
                    <Typography component="h4" variant="h3" sx={{ color: '#fff' }}>
                      Submitted By
                    </Typography>
                  </div>
                  <div style={{ padding: '0px 15px 15px' }}>
                    <Grid container spacing={gridSpacing}>
                      <Grid item lg={12} md={12} sm={12} xs={12} className="support_ticket_view_new">
                        <div style={{ display: 'flex', marginBottom: '10px' }}>
                          {' '}
                          <Typography component="p" variant="h4" sx={{ fontWeight: '600', marginRight: '4px', minWidth: '200px' }}>
                            Name :
                          </Typography>
                          <span
                            style={{
                              fontSize: '15px'
                            }}
                          >
                            {supportDetails?.createdBy
                              ? `${supportDetails?.createdBy?.prefix || ''} ${supportDetails?.createdBy?.firstName || ''} ${
                                  supportDetails?.createdBy?.lastName || ''
                                }`
                              : 'N.A.'}
                          </span>
                        </div>

                        <div style={{ display: 'flex', marginBottom: '10px' }}>
                          <Typography component={'p'} variant="h4" sx={{ fontWeight: '600', marginRight: '4px', minWidth: '200px' }}>
                            Email :
                          </Typography>
                          <span style={{ fontSize: '15px' }}>
                            {supportDetails?.createdBy?.email ? (
                              <a href={`mailto:${supportDetails?.createdBy?.email}`}>{supportDetails?.createdBy?.email}</a>
                            ) : (
                              'N.A.'
                            )}
                          </span>
                        </div>

                        <div style={{ display: 'flex', marginBottom: '10px' }}>
                          {' '}
                          <Typography component={'p'} variant="h4" sx={{ fontWeight: '600', marginRight: '4px', minWidth: '200px' }}>
                            Phone :
                          </Typography>
                          <span
                            style={{
                              fontSize: '15px'
                            }}
                          >
                            {supportDetails?.createdBy?.phone || 'N.A.'}
                          </span>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>

                <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginTop: '12px' }}>
                  <div>
                    <div className="address_locationmain" style={{ background: '#6fd74b', mb: 2, padding: '10px 15px' }}>
                      <Typography component="h4" variant="h3" sx={{ color: '#fff' }}>
                        Related Files/Documents
                      </Typography>
                    </div>

                    <Grid
                      spacing={gridSpacing}
                      container
                      item
                      xs={12}
                      sm={12}
                      style={{
                        // marginTop: '10px',
                        padding: '10px 15px 15px'
                      }}
                    >
                      {supportDetails?.files?.length > 0 ? (
                        supportDetails?.files.map((item, i) => (
                          <Grid item xs={12} md={4} lg={4} sm={4} key={i}>
                            <div className="chse-prefix-newbx">
                              <Typography variant="span" component="h3">
                                <Link
                                  href={item?.url}
                                  target="_blank"
                                  sx={{
                                    color: 'white',
                                    textDecoration: 'none',
                                    width: '100%',
                                    height: '100%'
                                  }}
                                >
                                  View
                                </Link>
                              </Typography>

                              {!!item?.mimetype && item?.mimetype?.startsWith('application/pdf') ? (
                                <iframe
                                  src={`${item?.url}#toolbar=0`}
                                  height="500px"
                                  width="100%"
                                  title={item?.name || 'PDF File'}
                                  style={{ border: 'none', overflow: 'hidden', cursor: 'pointer' }}
                                ></iframe>
                              ) : (
                                <img src={item?.url} alt={item?.name || 'Uploaded Image'} style={{ width: '100%', cursor: 'pointer' }} />
                              )}
                              <div className="chse-prefix-captionbx">
                                <div className="chse-prefix-caption-first">
                                  <h4>{item?.name}</h4>
                                </div>
                                <div></div>
                              </div>
                            </div>
                          </Grid>
                        ))
                      ) : (
                        <StyledGridOverlay>
                          <svg
                            style={{ flexShrink: 0 }}
                            width="240"
                            height="240"
                            viewBox="0 0 184 152"
                            aria-hidden="true"
                            focusable="false"
                          >
                            <g fill="none" fillRule="evenodd">
                              <g transform="translate(24 31.67)">
                                <ellipse className="ant-empty-img-5" cx="67.797" cy="106.89" rx="67.797" ry="12.668" />
                                <path
                                  className="ant-empty-img-1"
                                  d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                                />
                                <path
                                  className="ant-empty-img-2"
                                  d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                                />
                                <path
                                  className="ant-empty-img-3"
                                  d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                                />
                              </g>
                              <path
                                className="ant-empty-img-3"
                                d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                              />
                              <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                                <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                                <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                              </g>
                            </g>
                          </svg>
                          <Box sx={{ mt: 2 }}>No data found!</Box>
                        </StyledGridOverlay>
                      )}
                    </Grid>
                  </div>
                </div>

                {supportDetails?.status === 'Reopened' && (
                  <Grid item xs={12} className="support_ticket_view_new">
                    <>
                      <div style={{ border: '1px solid #2196f3', borderRadius: '6px', marginTop: '12px' }}>
                        <Grid item xs={12}>
                          <div className="address_locationmain" style={{ background: '#2196f3', mb: 2, padding: '10px 15px' }}>
                            <Typography component="h4" variant="h3" sx={{ color: '#fff' }}>
                              Reopen Reason
                            </Typography>
                          </div>
                          <Grid container spacing={gridSpacing}>
                            <Grid item lg={6} md={6} sm={6} xs={12} className="select-job-enterprise select-job-enterprise-type">
                              <div style={{ padding: '0px 15px 10px' }}>
                                <div style={{ display: 'flex', marginBottom: '10px' }}>
                                  <Typography
                                    component={'p'}
                                    variant="h4"
                                    sx={{ fontWeight: '600', marginRight: '4px', minWidth: '200px' }}
                                  >
                                    Reason :
                                  </Typography>
                                  <span
                                    style={{
                                      fontSize: '15px',
                                      fontWeight: '600'
                                    }}
                                  >
                                    {supportDetails?.reopenReason ? supportDetails?.reopenReason : 'N.A.'}
                                  </span>
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    </>
                  </Grid>
                )}

                <DialogActions className="support_ticket_threebtn">
                  <>
                    {supportDetails?.status === 'Pending' && (
                      <>
                        {moment().diff(moment(supportDetails?.createdAt), 'days') > 30 && (
                          <>
                            <Box sx={{ m: 1, position: 'relative' }}>
                              <Button
                                fullWidth
                                variant="contained"
                                type="submit"
                                onClick={() => {
                                  setOpenReportModal(true);
                                }}
                                style={{
                                  color: '#fff',
                                  backgroundColor: '#2196f3'
                                }}
                              >
                                Reopen
                              </Button>
                            </Box>
                          </>
                        )}

                        <Box sx={{ m: 1, position: 'relative' }}>
                          {supportPermission?.showResolveButton && (
                            <Button
                              fullWidth
                              variant="contained"
                              type="submit"
                              onClick={() => {
                                setMarkResolved(!markresolved);
                              }}
                              // onClick={() => {
                              //   const payload = {
                              //     ...supportDetails,
                              //     id: supportDetails?.id,
                              //     status: 'Resolved'
                              //   };

                              //   markAsResolved(payload, {
                              //     onSuccess: (res) => {
                              //       if (res?.status == 200) {
                              //         toastConfig.type = 'success';
                              //         // refetchSupportDetails();
                              //         setOpen(false);
                              //         queryClient.refetchQueries({ queryKey: ['getSupportTicketListData'], type: 'active' });
                              //       } else {
                              //         toastConfig.type = 'error';
                              //         setOpen(false);
                              //       }
                              //     },
                              //     onError: (error) => {
                              //       toastConfig.type = toastConfig.type = 'error';
                              //       setToast(toastConfig, error?.response?.data?.error);
                              //     }
                              //   });
                              // }}
                              sx={{
                                color: '#fff',
                                textTransform: 'capitalize'
                              }}
                              color="primary"
                              disabled={isLoadingMarkAsResolved}
                            >
                              Mark as resolved
                            </Button>
                          )}
                        </Box>
                      </>
                    )}

                    {supportDetails?.status === 'Reopened' && (
                      <>
                        <Box sx={{ m: 1, position: 'relative' }}>
                          {supportPermission?.showResolveButton && (
                            <Button
                              fullWidth
                              variant="contained"
                              type="submit"
                              onClick={() => {
                                setMarkResolved(!markresolved);
                              }}
                              sx={{
                                color: '#fff',
                                textTransform: 'capitalize'
                              }}
                              color="primary"
                              disabled={isLoadingMarkAsResolved}
                            >
                              Mark as resolved
                            </Button>
                          )}
                        </Box>
                      </>
                    )}
                  </>
                  <Box sx={{ m: 1, position: 'relative' }}>
                    <Button variant="contained" className="Support_Ticket_closebtn" type="reset" color="error" onClick={handleClose}>
                      Close
                    </Button>
                  </Box>
                </DialogActions>
              </Grid>
            </Grid>
          )}

          <DeleteDialog
            open={markresolved}
            setOpen={setMarkResolved}
            title={'Confirmation !'}
            subTitle={'Are you sure to mark the ticket as resolved ?'}
            handleSubmit={() => {
              markAsResolved(
                {
                  ...supportDetails,
                  id: supportDetails?.id,
                  status: 'Resolved'
                },
                {
                  onSuccess: (res) => {
                    if (res?.status == 200) {
                      toastConfig.type = 'success';
                      // setToast(toastConfig, res?.data.message);
                      // refetchSupportDetails();
                      // setOpen(false);
                      queryClient.refetchQueries({ queryKey: ['getSupportTicketListData'], type: 'active' });
                    } else {
                      toastConfig.type = 'error';
                      // setToast(toastConfig, error?.response?.data?.error);
                      // setOpen(false);
                    }
                  },
                  onError: (error) => {
                    console.log('🚀 ~ ViewSupportTicket ~ error:', error);
                    toastConfig.type = 'error';
                    // setToast(toastConfig, error?.response?.data?.error);
                    // setOpen(false);
                  }
                }
              );
            }}
            isDeleteLoading={isLoadingMarkAsResolved}
          />

          {openReportModal && (
            <ReportTicketModal
              open={openReportModal}
              setOpen={setOpenReportModal}
              supportDetailsId={details?.id}
              supportDetails={supportDetails}
              refetchSupportDetails={refetchSupportDetails}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ViewSupportTicket;
