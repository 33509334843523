/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { Grid, CardContent, Typography, FormHelperText, Avatar, CircularProgress, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import AppReactDropzone from 'ui-component/app-react-dropzone';
import { useFileUpload } from 'hooks/useCommonHooks';
import { useEffect } from 'react';
import { setToast, toastConfig } from 'utils/commonUtil';
// import { Close as CloseIcon } from '@mui/icons-material';

// Styled component for the heading inside the dropzone area
const HeadingTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(5),
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(4)
  }
}));

const ProfileImageUpload = ({ values, file, setFieldValue, disabled }) => {
  const [image, setImage] = useState(file); // Store the uploaded image here
  // const [isUploading, setIsUploading] = useState(false);
  useEffect(() => {
    if (file && Array.isArray(file) && file.length) {
      setImage(file[0]);
    }
  }, [file]);

  const { mutate: uploadMutate, isPending: isUploadingFile } = useFileUpload();

  const uploadFiles = (acceptedFiles) => {
    let fileData = new FormData();

    acceptedFiles.forEach((file, i) => {
      fileData.append(`file_${i}`, file);
    });

    fileData?.append('event', 'employees_profile_img');
    uploadMutate(fileData, {
      onSuccess: (resp) => {
        // setIsUploading(true);

        const data1 = values?.profileImage?.concat(resp?.data?.data);
        const data = resp?.data?.data ? resp?.data?.data : null;
        /*console.log(
          '🚀 ~ Employee ~ uploadFiles ~ resp:',
          resp,
          ' | data : ',
          data,
          ' | data1 : ',
          data1,
          ' | acceptedFiles : ',
          acceptedFiles
        );*/
        setFieldValue('profileImage', data);
        // setIsUploading(false);
      },
      onError: (error) => {
        console.error('Profile Image failed:', error);
      }
    });
  };

  // MUI Dropzone configuration
  const config = {
    accept: {
      'image/jpeg': [],
      'image/png': []
    }, // Only allow image files
    maxFiles: 1, // Limit to one file only
    maxSize: 2097152, // 2MB max file size
    onDrop: (acceptedFiles) => {
      // console.log('acceptedFiles ~ Employee : ', acceptedFiles);
      if (acceptedFiles && Array.isArray(acceptedFiles) && acceptedFiles.length) {
        uploadFiles(acceptedFiles);
      }
      // setIsUploading(true);

      // Set the first file as the profile image
      // const file = acceptedFiles[0];
      // setImage(file);

      // setFieldValue('profileImage', file); // Update form state
      // setIsUploading(false);
    },
    onDropRejected: (errors) => {
      errors.forEach((error) => {
        const { file, errors } = error;
        errors.forEach((err) => {
          /*toast.error(err.code === 'file-invalid-type' ? err.message : `${file.path} file Size should be less than 2MB.`, {
            autoClose: 3000
          });*/
          toastConfig.type = 'error';
          setToast(toastConfig, err.code === 'file-invalid-type' ? err.message : `${file.name} file Size should be less than 2MB.`);
        });
      });
    }
  };

  const { getRootProps, getInputProps } = useDropzone(config);

  return (
    <Box sx={{ m: 1, position: 'relative' }}>
      <CardContent sx={{ p: 0 }}>
        <Grid container spacing={2}>
          {/* Image Upload Section */}
          {!disabled && (
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              sx={{
                padding: 0,
                paddingRight: {
                  xs: 0, // No padding for xs
                  sm: 0, // No padding for sm
                  md: 12 // Padding for medium screens and up
                }
              }}
            >
              <AppReactDropzone>
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />
                  <div className="flex items-center flex-column text-center">
                    <img
                      alt="Upload img"
                      src="https://demos.themeselection.com/marketplace/materio-mui-nextjs-admin-template/documentation/images/misc/file-upload.png"
                      style={{
                        height: '100px',
                        width: '100px',
                        margin: '0 auto 15px',
                        display: 'block'
                      }}
                    />
                    <HeadingTypography variant="h5">Drop files here or click to upload.</HeadingTypography>
                  </div>
                </div>
                {/* {isUploading && ( */}
                {isUploadingFile && (
                  <CircularProgress
                    size={40}
                    color="primary"
                    sx={{
                      // color: '#16B1FF',
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px'
                    }}
                  />
                )}
              </AppReactDropzone>
            </Grid>
          )}

          {/* Image Preview Section */}
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {image ? (
                <Avatar
                  alt="Profile Image"
                  src={image?.url}
                  sx={{ width: 250, height: 250, border: '1px solid rgba(93, 89, 98, 0.22)', backgroundColor: '#f8fafc !important' }}
                />
              ) : (
                <Avatar
                  src='src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"'
                  sx={{ width: 250, height: 250, border: '1px solid rgba(93, 89, 98, 0.22)', backgroundColor: '#f8fafc !important' }}
                >
                  {/* <Typography variant="h5">No Image</Typography> */}
                </Avatar>
              )}
            </div>
            <Typography
              variant="h6"
              component={'span'}
              style={{
                fontSize: '15px',
                fontWeight: '600',
                // paddingBottom: '5px',
                display: 'flex',
                justifyContent: 'center',
                paddingTop: '32px'
              }}
            >
              Profile Image Preview
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Box>
  );
};

const DocumentUploadEmployee = ({ values, setFieldValue, touched, errors, disabled }) => {
  return (
    <Box sx={{ m: 1, position: 'relative' }}>
      <CardContent sx={{ padding: 0 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <ProfileImageUpload
              values={values}
              file={values.profileImage} // Pass the initial profile image if any
              setFieldValue={setFieldValue}
              disabled={disabled}
            />
            <FormHelperText sx={{ marginLeft: 4 }}>
              {touched.profileImage && errors.profileImage ? <span style={{ color: 'red' }}>{errors.profileImage}</span> : null}
            </FormHelperText>
          </Grid>
        </Grid>
      </CardContent>
    </Box>
  );
};

export default DocumentUploadEmployee;
