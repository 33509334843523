import React from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Grid, Skeleton, Typography } from '@mui/material';
import { Formik, Form } from 'formik';
// import { gridSpacing } from 'store/constant';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useSaveLeftPanelData } from 'hooks/useControlPanelHooks';

function ModifyPermission({ routingList, isLoadingRouting }) {
  const { user, org, routeList } = useSelector((state) => state.controlPanelReducer);

  const { mutate } = useSaveLeftPanelData({
    // user: user === 'Main POC' ? 'POC' : 'POC',
    // org: org === 'Service_Provider' ? 'ServiceProvider' : org !== 'Super_Admin' ? org : 'SuperAdmin'
    org: org ? org.replaceAll(' ', '') : org,
    user: user ? user.replaceAll(' ', '') : user
  });

  // Initial form values
  const initialValues = {
    selected: routingList?.data?.data?.data || []
  };

  // Validation schema
  const validationSchema = Yup.object().shape({
    selected: Yup.array().min(1, 'Please select at least one permission')
  });

  // Handle permission toggle
  const handleToggle = (item, values, setFieldValue) => {
    const updatedSelected = values?.selected?.includes(item)
      ? values.selected.filter((selectedItem) => selectedItem !== item) // Deselect item
      : [...values.selected, item]; // Select item

    setFieldValue('selected', updatedSelected);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        mutate(values.selected); // Submit form data
      }}
    >
      {({ values, setFieldValue, errors, touched }) => (
        <Form>
          <Grid container spacing={2} sx={{ py: { xs: 1, sm: 1, md: 2, lg: 4, xl: 4 } }}>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                <TipsAndUpdatesIcon color="warning" sx={{ mr: 1 }} />
                {`Tips: Tap the items to select or deselect, and press the 'Calibrate' button to save the modifications.`}
              </Typography>
            </Grid>
            {routeList?.map((item, index) => {
              const isSelected = values?.selected?.includes(item);
              return (
                <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={2}>
                  {isLoadingRouting ? (
                    <Skeleton height={50} animation="wave" />
                  ) : (
                    <Button
                      fullWidth
                      variant="contained"
                      color={isSelected ? 'info' : 'warning'}
                      sx={{ color: isSelected ? '#fff' : '#000' }}
                      endIcon={isSelected ? <CheckCircleIcon /> : null}
                      onClick={() => handleToggle(item, values, setFieldValue)}
                    >
                      {item === 'Tickets' ? 'Machine Tickets' : item.replaceAll('_', ' ')}
                    </Button>
                  )}
                </Grid>
              );
            })}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {touched.selected && errors.selected && (
              <Grid item xs={12}>
                <Typography variant="body2" color="error" align="center" sx={{ mt: 2 }}>
                  {errors.selected}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} sx={{ textAlign: 'center', mt: 2 }}>
              <Button type="submit" variant="contained" color="primary" sx={{ color: '#fff' }}>
                Calibrate
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

ModifyPermission.propTypes = {
  routingList: PropTypes.shape({
    data: PropTypes.shape({
      data: PropTypes.shape({
        data: PropTypes.array
      })
    })
  }),
  isLoadingRouting: PropTypes.bool
};

export default ModifyPermission;
