import {
  Box,
  Chip,
  Divider,
  // Box,
  // Chip,
  IconButton,
  Skeleton,
  // Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MainCard from 'ui-component/cards/MainCard';
import PaletteIcon from '@mui/icons-material/Palette';
import BreadcrumbsForPage from 'ui-component/extended/BreadcrumbsForPage';
import { styled } from '@mui/material/styles';
import CommentIcon from '@mui/icons-material/Comment';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useGetMachineTicketList, useGetPermissionMachineTicket } from 'hooks/useMachineTicketHooks';
import { useEffect } from 'react';
import SkeletonTableView from 'ui-component/cards/Skeleton/TableView';
import moment from 'moment';
import ViewTicket from './ticket-view';
import { useLocation } from 'react-router-dom';
import DialogsChats from './ticket-chats';
import { useSelector } from 'react-redux';
import { TooltipStatus } from 'ui-component/fields/tooltip.status';
import { getValueFromPath, truncatedText } from 'utils/commonUtil';
// eslint-disable-next-line no-restricted-imports

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  margin: '0 auto',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626'
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959'
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343'
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c'
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff'
  }
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

// const columns = [
//   { id: 'id', label: 'Ticket No.', minWidth: 150, align: 'center' },
//   { id: 'machine', label: 'Machine', minWidth: 150, align: 'center' },
//   { id: 'subject', label: 'Subject', minWidth: 150, align: 'center' },
//   { id: 'ticketDate', label: 'Ticket Date', minWidth: 150, align: 'center' },
//   { id: 'resolvedDate', label: 'Resolved Date', minWidth: 150, align: 'center' },
//   { id: 'status', label: 'Status', minWidth: 150, align: 'center' },
//   { id: 'action', label: 'Action', minWidth: 150, align: 'center' }
// ];

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip enterTouchDelay={0} {...props} classes={{ popper: className }} arrow />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9'
    }
  })
);

function EnhancedTableHead({
  // classes,
  order,
  orderBy,
  onRequestSort,
  headersObj
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headersObj?.map((headCell) => (
          <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false} align="center">
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              disabled={orderBy !== headCell.id}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
function CollapsibleRow({
  row,
  isOpenComment,
  setIsOpenComment,
  setViewDetails,
  openViewTicket,
  setOpenViewTicket,
  setAllTicketDetails,
  permissionsList,
  headersObj
}) {
  // console.log('🚀 ~ rowgggh:', row);
  const userData = JSON.parse(localStorage.getItem('userData'));

  const orgType = useSelector((state) => state.customization.orgType);

  const isCreatedByOrg = row.createdByOrg?._id?.toString() === userData?.organization?.id && row.createdByOrgType === orgType;

  const isRaisedToOrg = row.raisedTo?.some(({ org, orgType }) => org === userData?.organization?.id && orgType === orgType);
  // console.log('🚀 ~ row.raisedTo:', row.raisedTo);

  const ticketCategory = isCreatedByOrg ? 'Raised' : isRaisedToOrg ? 'Received' : '';
  // console.log('🚀 ~ isCreatedByOrg:', isCreatedByOrg);
  let dynamicValue = '';
  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      style={{
        backgroundColor: ticketCategory === 'Raised' ? '#FFEDE4' : ticketCategory === 'Received' ? '#EBFFCC' : '#fff'
      }}
    >
      {headersObj &&
        headersObj.length > 0 &&
        headersObj?.map((header) => {
          dynamicValue = getValueFromPath(row, header.id);
          return (
            <React.Fragment key={header.id}>
              {header.id === 'actionUpdate' ? (
                <TableCell align="center">
                  <HtmlTooltip title="Comments">
                    <IconButton
                      onClick={() => {
                        setIsOpenComment(!isOpenComment); // time complexity
                        setViewDetails(row);
                      }}
                    >
                      <CommentIcon color="action" />
                    </IconButton>
                  </HtmlTooltip>
                  {permissionsList?.showView && (
                    <>
                      <HtmlTooltip title={'View' + '  ' + row?.number}>
                        <IconButton
                          onClick={() => {
                            setOpenViewTicket(!openViewTicket);
                            setAllTicketDetails(row);
                          }}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </HtmlTooltip>
                    </>
                  )}
                </TableCell>
              ) : header?.id === 'status' ? (
                <TableCell align="center">
                  {header?.changeState ? (
                    <TooltipStatus
                      //  title={`Are you sure want to ${row?.status ? 'deactivate' : 'activate'} the ${row?.id}?`}
                      // label={row?.status === 'Pending' ? 'Pending' : row?.status === 'Resolved' ? 'Resolved' : 'Reported'}
                      label={row?.status}
                      backgroundColor={
                        row?.status === 'Pending'
                          ? '#ae30c2'
                          : row?.status === 'InReview'
                          ? '#dbc115'
                          : row?.status === 'Cancelled'
                          ? '#d84315'
                          : row?.status === 'Rejected'
                          ? '#f44336'
                          : row?.status === 'Accepted'
                          ? '#3d5afe'
                          : '#6fd74b'
                      }
                    />
                  ) : (
                    <Chip
                      label={
                        row?.status === 'Pending'
                          ? 'Pending'
                          : row?.status === 'InReview'
                          ? 'In Review'
                          : row?.status === 'Cancelled'
                          ? 'Cancelled'
                          : row?.status === 'Rejected'
                          ? 'Rejected'
                          : row?.status === 'Accepted'
                          ? 'Accepted'
                          : 'Resolved'
                      }
                      style={{
                        color: '#fff',
                        backgroundColor:
                          row?.status === 'Pending'
                            ? '#ae30c2'
                            : row?.status === 'InReview'
                            ? '#dbc115'
                            : row?.status === 'Cancelled'
                            ? '#d84315'
                            : row?.status === 'Rejected'
                            ? '#f44336'
                            : row?.status === 'Accepted'
                            ? '#3d5afe'
                            : '#6fd74b'
                      }}
                      size="small"
                    />
                  )}
                </TableCell>
              ) : header.id === 'createdAt' ? (
                <TableCell align="center">{dynamicValue ? moment(dynamicValue).format('Do MMMM YYYY') : 'N.A.'}</TableCell>
              ) : header.id === 'resolvedDate' ? (
                <TableCell align="center">{dynamicValue ? moment(dynamicValue).format('Do MMMM YYYY') : 'N.A.'}</TableCell>
              ) : (
                <TableCell align="center">
                  <Tooltip title={dynamicValue || ''} disableInteractive>
                    <span>{truncatedText(dynamicValue) || 'N.A.'}</span>
                  </Tooltip>
                </TableCell>
              )}
            </React.Fragment>
          );
        })}
    </TableRow>
  );
}

CollapsibleRow.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string,
    machine: PropTypes.string,
    reason: PropTypes.string,
    ticketDate: PropTypes.string,
    resolvedDate: PropTypes.string,
    status: PropTypes.string
  })
};

const TicketList = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('createdAt');

  const [isLoading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(0);
  const [openViewTicket, setOpenViewTicket] = useState(false);
  const [isOpenComment, setIsOpenComment] = useState(false);
  const [viewDetails, setViewDetails] = useState({});
  const [allTicketDetails, setAllTicketDetails] = useState({});

  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const viewTicketID = queryParams?.get('viewTicketID');
  const chatTicketID = queryParams?.get('chatTicketID');
  const machineId = queryParams?.get('machineId');
  const [filterObj, setFilterObj] = useState({
    status: '',
    subject: '',
    ticketNo: '',
    fromDate: '',
    toDate: '',
    machineId: machineId ?? '',
    ticketType: ''
  });
  const [openFilterModal, setOpenFilterModal] = useState(true);
  const organizationType = useSelector((state) => state.customization.orgType);

  const usersType = useSelector((state) => state.customization.userType);
  const { data: permissionsList, isLoading: isLoadingPermissionsList } = useGetPermissionMachineTicket({
    organizationType: organizationType !== 'Super Admin' ? organizationType : 'SuperAdmin',
    // organizationType: organizationType === 'Super Admin' ? 'SuperAdmin' : organizationType,

    // usersType: usersType === 'Main POC' ? 'POC' : 'POC'
    usersType: usersType === 'Main POC' || usersType === 'Secondary POC' ? 'POC' : usersType
  });

  const { data: allMachineTicketList, isLoading: isAllMachineTicketLoading } = useGetMachineTicketList({
    limit: rowsPerPage,
    sortOrder: order,
    sortField: orderBy,
    page: currentPage ? currentPage : previousPage,
    subject: filterObj?.subject || '',
    ticketNo: filterObj?.ticketNo || '',
    fromDate: filterObj?.fromDate || '',
    toDate: filterObj?.toDate || '',
    status: filterObj?.status || '',
    machineId: filterObj?.machineId || '',
    ticketType: filterObj?.ticketType || ''
  });

  const [headers, setHeaders] = useState([]);

  useEffect(() => {
    if (permissionsList) {
      let tableHeaders = permissionsList?.view?.tableHeaders;
      const actionObj = {
        id: 'actionUpdate',
        label: 'Action'
      };
      if (tableHeaders && Array.isArray(tableHeaders) && tableHeaders.length) setHeaders([...tableHeaders, actionObj]);
      // setHeaders([...tableHeaders, actionObj]);
    }
  }, [permissionsList]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    setCurrentPage(newPage + 1);
    setPreviousPage(currentPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (viewTicketID) {
      setOpenViewTicket(true);
    }
    if (chatTicketID) {
      setIsOpenComment(true);
    }
  }, [viewTicketID, chatTicketID]);

  return (
    <>
      {isLoading ? (
        <>
          <SkeletonTableView />
        </>
      ) : (
        <>
          <BreadcrumbsForPage name="All Machine Tickets" obj={{ title: 'All Machine Tickets', title2: '', url: '' }} />
          <MainCard
            title=""
            isButton={true}
            isEnable={true}
            // isFilter={true}
            url="/dashboard/tickets/add"
            openFilterAllModal={openFilterModal}
            setOpenFilterModal={setOpenFilterModal}
            setFilterObj={setFilterObj}
            // isTicketsFilter={true}
            filterObj={filterObj}
            isLoadingPermissionsList={isLoadingPermissionsList}
            permissionsList={permissionsList}
            isFilter={
              permissionsList === null
                ? false
                : permissionsList?.view?.filters === undefined
                ? true
                : permissionsList?.view?.filters?.length > 0
                ? true
                : false
            }
            isTicketsFilter={
              permissionsList === null
                ? false
                : permissionsList?.view?.filters === undefined
                ? true
                : permissionsList?.view?.filters?.length > 0
                ? true
                : false
            }
          >
            <TableContainer>
              <>
                <Typography variant="h5" component={'h4'}>
                  Note <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginBottom: '20px' }}>
                  <Typography sx={{ display: 'flex', alignItems: 'center', color: 'black' }}>
                    Raised : <PaletteIcon style={{ color: '#FFE3D6', marginLeft: 4 }} />
                  </Typography>
                  <Typography sx={{ display: 'flex', alignItems: 'center', color: 'black' }}>
                    Received : <PaletteIcon style={{ color: '#DFFCB0', marginLeft: 4 }} />
                  </Typography>
                </Box>
                <Divider />
              </>
              {isAllMachineTicketLoading ? (
                <TableBody>
                  {[1, 2, 3, 4].map((i) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                      <TableCell align="center">
                        {' '}
                        <Skeleton variant="text" width={200} />
                      </TableCell>
                      <TableCell align="center">
                        {' '}
                        <Skeleton variant="text" width={200} />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton variant="text" width={200} />
                      </TableCell>
                      <TableCell align="center">
                        {' '}
                        <Skeleton variant="text" width={200} />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton variant="text" width={200} />
                      </TableCell>{' '}
                      <TableCell align="center">
                        <Skeleton variant="text" width={200} />
                      </TableCell>{' '}
                      <TableCell align="center">
                        <Skeleton variant="text" width={200} />
                      </TableCell>
                      <TableCell align="center">
                        <IconButton>
                          <Skeleton variant="circular" width={40} height={40} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : allMachineTicketList?.tickets === undefined || allMachineTicketList?.tickets?.length == 0 ? (
                <StyledGridOverlay>
                  <svg style={{ flexShrink: 0 }} width="240" height="200" viewBox="0 0 184 152" aria-hidden focusable="false">
                    <g fill="none" fillRule="evenodd">
                      <g transform="translate(24 31.67)">
                        <ellipse className="ant-empty-img-5" cx="67.797" cy="106.89" rx="67.797" ry="12.668" />
                        <path
                          className="ant-empty-img-1"
                          d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                        />
                        <path
                          className="ant-empty-img-2"
                          d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                        />
                        <path
                          className="ant-empty-img-3"
                          d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                        />
                      </g>
                      <path
                        className="ant-empty-img-3"
                        d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                      />
                      <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                        <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                        <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                      </g>
                    </g>
                  </svg>
                  <Box sx={{ mt: 1 }}>No data found!</Box>
                </StyledGridOverlay>
              ) : (
                <Table stickyHeader aria-label="sticky table" size="small">
                  <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} headersObj={headers} />
                  <TableBody>
                    {stableSort(allMachineTicketList?.tickets, getComparator(order, orderBy))?.map((row, i) => {
                      return (
                        <CollapsibleRow
                          row={row}
                          key={i}
                          setIsOpenComment={setIsOpenComment}
                          isOpenComment={isOpenComment}
                          setViewDetails={setViewDetails}
                          setOpenViewTicket={setOpenViewTicket}
                          openViewTicket={openViewTicket}
                          setAllTicketDetails={setAllTicketDetails}
                          permissionsList={permissionsList}
                          headersObj={headers}
                        />
                      );
                    })}
                  </TableBody>
                </Table>
              )}
              {/* <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} align="center">
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <CollapsibleRow
                  key={index}
                  row={row}
                  setIsOpenComment={setIsOpenComment}
                  isOpenComment={isOpenComment}
                  setViewDetails={setViewDetails}
                  setOpenViewTicket={setOpenViewTicket}
                  openViewTicket={openViewTicket}
                  setAllTicketDetails={setAllTicketDetails}
                />
              ))}
             
            </TableBody>
          </Table> */}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={allMachineTicketList?.rowPerPage}
              component="div"
              count={allMachineTicketList?.totalCount ? allMachineTicketList?.totalCount : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <DialogsChats open={isOpenComment} setOpen={setIsOpenComment} details={viewDetails} />
            <ViewTicket open={openViewTicket} setOpen={setOpenViewTicket} details={allTicketDetails} />
          </MainCard>
        </>
      )}
    </>
  );
};

export default TicketList;
