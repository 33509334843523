/* eslint-disable */
import React, { useRef, useEffect, useState } from 'react';
import Slider from 'react-slick';
import {
  Grid,
  Paper,
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  IconButton,
  ListItemText,
  CircularProgress,
  Link
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import AddIcon from '@mui/icons-material/Add';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { margin } from '@mui/system';

const SubscriptionPlansCarousel = ({ subcriptionList, callInvoiceScreen, setOpenContactUs, openContactUs, value }) => {
  const sliderRef = useRef(null);

  const [isSubscriptionBtn, setIsSubscriptionBtn] = React.useState({ show: false, id: null });

  const [maxHeight, setMaxHeight] = useState(555);
  const [filterSubcriptionList, setFilterSubcriptionList] = useState(subcriptionList);
  // console.log('filterSubcriptionList', filterSubcriptionList, value, value?.subId);
  //   const [itemHeights, setItemHeights] = useState([]); // To track individual item heights

  // Measure the heights of each item after the component is mounted
  /*useEffect(() => {
    const heights = [];
    const items = document.querySelectorAll('.slick-slide');

    items.forEach((item) => {
      heights.push(item.offsetHeight);
    });

    if (heights.length > 0) {
      setMaxHeight(Math.max(...heights)); // Get the maximum height
    }
  }, [subcriptionList]); // Re-run whenever the subscription list changes
*/
  useEffect(() => {
    if (subcriptionList && value?.type === 'change' && value?.subId)
      setFilterSubcriptionList(subcriptionList.filter((plan) => plan.id !== value?.subId));
  }, [subcriptionList]);
  // console.log('subcriptionList.length', subcriptionList.length, filterSubcriptionList.length, filterSubcriptionList);
  // Slick Carousel settings
  // console.log('Slick Carousel settings autoplay : ', openContactUs, !openContactUs);
  const settings = {
    dots: true,
    slidesToShow: 3,
    infinite: filterSubcriptionList && filterSubcriptionList.length ? true : false,
    slidesToScroll: 1,
    autoplay: !openContactUs,
    /*dots: filterSubcriptionList && filterSubcriptionList.length > 3,
    slidesToShow: filterSubcriptionList && filterSubcriptionList.length >= 2 ? 3 : filterSubcriptionList.length, // Show all items if 3 or less
    infinite: filterSubcriptionList && filterSubcriptionList.length > 3,
    slidesToScroll: 1,
    autoplay: filterSubcriptionList && filterSubcriptionList.length > 3,*/
    speed: 200,
    autoplaySpeed: 3500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
    init: () => {
      // console.log('Slick Slider initialized and ready!');
      // You can also perform actions here, like updating state or triggering animations
    }
  };

  const onClickSubscriptionBtn = (id) => {
    setIsSubscriptionBtn((prev) => ({ show: true, id }));
    setTimeout(() => {
      callInvoiceScreen(id, setIsSubscriptionBtn);
      // setIsSubscriptionBtn((prev) => ({ show: false, id: null }));
    }, 1000);
  };

  return (
    <div className="slider-container slide-subscribe-plan" style={{ position: 'relative', paddingBottom: 60 }}>
      {/* Left Arrow */}
      <IconButton
        onClick={() => sliderRef.current.slickPrev()}
        style={{
          position: 'absolute',
          left: 0,
          top: '50%',
          transform: 'translateY(-100%)',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          color: '#fff',
          zIndex: 10
          // display: filterSubcriptionList && filterSubcriptionList.length >= 2 ? 'none' : 'block'
        }}
        className="carousel-arrow-left"
      >
        <ArrowCircleLeftIcon style={{ fontSize: '40px' }} />
      </IconButton>

      {/* Right Arrow */}
      <IconButton
        onClick={() => sliderRef.current.slickNext()}
        style={{
          position: 'absolute',
          right: 0,
          top: '50%',
          transform: 'translateY(-100%)',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          color: '#fff',
          zIndex: 10
          // display: filterSubcriptionList && filterSubcriptionList.length >= 2 ? 'none' : 'block'
        }}
        className="carousel-arrow-right"
      >
        <ArrowCircleRightIcon style={{ fontSize: '40px' }} />
      </IconButton>

      <Slider
        {...settings}
        ref={sliderRef}
        className="slick-slider"
        // onMouseEnter={() => {
        //   document.querySelector('.carousel-arrow-left').style.display = 'block';
        //   document.querySelector('.carousel-arrow-right').style.display = 'block';
        // }}
        // onMouseLeave={() => {
        //   document.querySelector('.carousel-arrow-left').style.display = 'none';
        //   document.querySelector('.carousel-arrow-right').style.display = 'none';
        // }}
      >
        {filterSubcriptionList &&
          filterSubcriptionList.length > 0 &&
          filterSubcriptionList.map((sub, index) => (
            <div key={index}>
              <Grid item xs={12} sm={6} md={4} className="subscription-plan-wrapper">
                <Paper sx={{ p: 6, borderRadius: 2, boxShadow: 3, height: '100%' }}>
                  <div className="subcription-content">
                    <Typography variant="h2" align="left" sx={{ fontWeight: '600' }}>
                      {sub?.name}
                    </Typography>
                    <Typography variant="body2" align="left" sx={{ mb: 2, pt: 2 }}>
                      {sub?.description}
                    </Typography>
                    <Typography variant="h1" align="left" sx={{ fontWeight: '700', mb: 2 }}>
                      {sub?.regularBillingCycle?.price?.currencyCode} {sub?.regularBillingCycle?.price?.value}{' '}
                      <span style={{ fontSize: '12.5px', color: '#a4a4a4', fontWeight: '500' }}>
                        / {sub?.regularBillingCycle?.intervalUnit}
                      </span>
                    </Typography>
                    <Typography variant="h4" align="left" sx={{ fontWeight: '600' }}>
                      {sub?.localUserCount || 0} Machine Specific User(s) and {sub?.globalUserCount || 0} Global User(s) with{' '}
                      {sub?.adCount ? sub?.adCount + ' Advertisements' : 'No Advertisements'}
                    </Typography>
                    <Typography variant="h4" align="left" color="primary" sx={{ pt: 2, fontWeight: '600' }}>
                      Trial Period :{' '}
                      {sub?.trialPeriod?.price?.value <= 0 ? (
                        <>
                          {sub?.trialPeriod?.intervalCount && sub?.trialPeriod?.intervalCount <= 0 ? '' : sub?.trialPeriod?.intervalCount}
                          &nbsp;
                          {sub?.trialPeriod?.intervalUnit || ''} - FREE
                        </>
                      ) : (
                        <>
                          {sub?.trialPeriod?.intervalCount && sub?.trialPeriod?.intervalCount <= 0 ? '' : sub?.trialPeriod?.intervalCount}
                          &nbsp;
                          {sub?.trialPeriod?.intervalUnit || ''} - ({sub?.trialPeriod?.price?.currencyCode})&nbsp;
                          {sub?.trialPeriod?.price?.value || ''}
                        </>
                      )}
                    </Typography>
                    <Typography variant="h4" align="left" sx={{ pt: 1, fontWeight: '600' }}>
                      Tax : {sub?.tax}%
                    </Typography>

                    <List sx={{ paddingTop: 3 }}>
                      {sub?.features &&
                        sub?.features.length &&
                        sub?.features.map((feature, index) => (
                          <ListItem key={index} sx={{ paddingLeft: 0 }}>
                            <ListItemIcon>
                              <CheckIcon sx={{ color: sub?.colorCode?.hex }} />
                            </ListItemIcon>
                            <Typography variant="h5" component={'span'} style={{ fontWeight: '600' }}>
                              {feature?.trim()}
                            </Typography>
                          </ListItem>
                        ))}
                    </List>
                  </div>

                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{
                      mt: 4,
                      p: 2,
                      color: '#FFF',
                      backgroundColor: sub?.colorCode?.hex,
                      '&:hover': {
                        backgroundColor: sub?.colorCode?.hex
                      },
                      fontSize: '1rem',
                      boxShadow: 'none'
                    }}
                    disabled={isSubscriptionBtn.show}
                    onClick={() => onClickSubscriptionBtn(sub?.id)}
                  >
                    {isSubscriptionBtn.show && isSubscriptionBtn.id === sub?.id && <CircularProgress size={24} color="inherit" />}&nbsp;
                    Select Plan
                  </Button>
                </Paper>
              </Grid>
            </div>
          ))}
        <Grid item xs={12} sm={6} md={4} className="subscription-plan-wrapper">
          <Paper sx={{ p: 6, borderRadius: 2, boxShadow: 3, height: '100%' }}>
            <div className="subcription-content">
              <Typography variant="h2" align="left" sx={{ fontWeight: '600' }}>
                Custom Plan
              </Typography>
              <Typography variant="body2" align="left" sx={{ mb: 2, pt: 2 }}>
                For big organizations with large number of drive crush capacity
              </Typography>
              <Typography variant="h1" align="left" sx={{ fontWeight: '700', mb: 2 }}>
                {/* $22 <span style={{ fontSize: '12.5px', color: '#a4a4a4', fontWeight: '500' }}>/month</span> */}
              </Typography>
              <Typography variant="h4" align="left" sx={{ fontWeight: '600' }}>
                {/* Per Each User */}
              </Typography>

              <List sx={{ paddingTop: 5 }}>
                {/* <ListItem sx={{ paddingLeft: 0 }}>
                <ListItemIcon>
                  <CheckIcon sx={{ color: '#6FBA55' }} />
                </ListItemIcon>
                <ListItemText primary={<Typography sx={{ fontWeight: 'bolder' }}>Everything in Basic plan</Typography>} />
              </ListItem> */}
                <ListItem sx={{ paddingLeft: 0 }}>
                  <ListItemIcon>
                    <AddIcon sx={{ color: '#6FBA55' }} />
                  </ListItemIcon>
                  <ListItemText primary="Customised users capacity" />
                </ListItem>
                <ListItem sx={{ paddingLeft: 0 }}>
                  <ListItemIcon>
                    <AddIcon sx={{ color: '#6FBA55' }} />
                  </ListItemIcon>
                  <ListItemText primary="More than 5 advertisement options" />
                </ListItem>
                {/* <ListItem sx={{ paddingLeft: 0 }}>
                <ListItemIcon>
                  <AddIcon sx={{ color: '#6FBA55' }} />
                </ListItemIcon>
                <ListItemText primary="Unlimited file versions" />
              </ListItem>
              <ListItem sx={{ paddingLeft: 0 }}>
                <ListItemIcon>
                  <AddIcon sx={{ color: '#6FBA55' }} />
                </ListItemIcon>
                <ListItemText primary="Unlimited free Guest users" />
              </ListItem>
              <ListItem sx={{ paddingLeft: 0 }}>
                <ListItemIcon>
                  <AddIcon sx={{ color: '#6FBA55' }} />
                </ListItemIcon>
                <ListItemText primary="Integrations" />
              </ListItem>
              <ListItem sx={{ paddingLeft: 0 }}>
                <ListItemIcon>
                  <AddIcon sx={{ color: '#6FBA55' }} />
                </ListItemIcon>
                <ListItemText primary="Private share links" />
              </ListItem> */}
              </List>
            </div>
            <Link
              href={`mailto:support@shredbox.com?subject=${encodeURIComponent('Inquiry Regarding Custom Subscription.')}`}
              // sx={{ textDecoration: 'underline', color: 'blue', wordBreak: 'break-word' }}
              // style={{ textDecoration: 'underline', textDecorationColor: 'blue', color: 'inherit' }}
            >
              <Button
                type="button"
                variant="contained"
                color="success"
                fullWidth
                sx={{ mt: 4, p: 2, color: '#FFF', backgroundColor: '#6FBA55', fontSize: '1rem', boxShadow: 'none' }}
                disabled={isSubscriptionBtn.show}
                // onClick={() => setOpenContactUs(true)}
              >
                Contact Us
              </Button>
            </Link>
          </Paper>
        </Grid>
      </Slider>
    </div>
  );
};

export default SubscriptionPlansCarousel;
