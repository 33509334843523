// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

// material-ui
import { styled } from '@mui/material/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  IconButton,
  Skeleton,
  Chip,
  CircularProgress,
  Button,
  Stack,
  Typography
  // ToggleButton,
  // ToggleButtonGroup,
  // Grid,
  // Divider
} from '@mui/material';
// import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import MainCard from 'ui-component/cards/MainCard';

// routeing
// import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import moment from 'moment';
import BreadcrumbsForPage from 'ui-component/extended/BreadcrumbsForPage';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import SkeletonTableView from 'ui-component/cards/Skeleton/TableView';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { useGetNewLocationList, useGetPermissionsLocationsList, useLocationStatusUpdate } from 'hooks/useLocationHooks';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { useSelector } from 'react-redux';
import React from 'react';
import { getValueFromPath, truncatedText } from 'utils/commonUtil';
const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  margin: '0 auto',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626'
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959'
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343'
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c'
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff'
  }
}));
// const columns = [
//   { id: 'name', label: 'Location Name', minWidth: 150, align: 'center' },
//   {
//     id: 'address',
//     label: 'Address',
//     minWidth: 150,
//     align: 'center',
//     format: (value) => value.toLocaleString('en-US')
//   },
//   {
//     id: 'createdAt',
//     label: 'Created At',
//     minWidth: 150,
//     align: 'center',
//     format: (value) => value.toLocaleString('en-US')
//   },
//   {
//     id: 'status',
//     label: 'Status',
//     minWidth: 150,
//     align: 'center'
//     // format: (value) => value.toFixed(2)
//   },
//   {
//     id: 'update',
//     label: 'Action',
//     minWidth: 150,
//     align: 'center'
//     // format: (value) => value.toFixed(2)
//   }
// ];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} arrow />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9'
  }
}));
/*function EnhancedTableHead({
  // classes,
  order,
  orderBy,
  onRequestSort,
  permissionsList,
  showEdit
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {permissionsList?.map((headCell, index) => (
          <TableCell key={index} sortDirection={orderBy === headCell.id ? order : false} align="center">
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              disabled={orderBy !== headCell.id}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        {showEdit && (
          <TableCell key={Math.random} sortDirection={orderBy === 'update' ? order : false} align="center">
            <TableSortLabel
              active={orderBy === 'update'}
              direction={orderBy === 'update' ? order : 'asc'}
              onClick={createSortHandler('update')}
              disabled={orderBy !== 'update'}
            >
              Action
            </TableSortLabel>
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}*/

function EnhancedTableHead({
  // classes,
  order,
  orderBy,
  onRequestSort,
  headersObj
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* {columns?.map((headCell) => ( */}
        {headersObj?.map((headCell) => {
          return (
            <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false} align="center">
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                disabled={orderBy !== headCell.id}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

function CollapsibleRow({ row, headersObj, permissionsList }) {
  // const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  // const userData = JSON.parse(localStorage.getItem('userData'));

  // const { mutate: updatedStatus, isPending: isLoadingOrganizationNewUpdateStatus } = useOrganizationNewStatusUpdate();

  // const binColors = machinebinThresoldColors;
  const { mutate: updatedLocationStatus, isPending: isLoadingUpdateLocationStatus } = useLocationStatusUpdate();
  let dynamicValue = '';

  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={row?.id}>
      {headersObj &&
        headersObj.length > 0 &&
        headersObj?.map((header) => {
          dynamicValue = getValueFromPath(row, header.id);

          return (
            <React.Fragment key={header.id}>
              {header.id === 'actionUpdate' ? (
                <TableCell align="center">
                  {permissionsList?.showEdit && (
                    <>
                      <HtmlTooltip title={'Edit' + ' ' + row?.name}>
                        <IconButton onClick={() => navigate(`/dashboard/location/edit-${row?.id}`, { state: permissionsList })}>
                          <ModeEditOutlinedIcon />
                        </IconButton>
                      </HtmlTooltip>
                    </>
                  )}

                  {/* {permissionsList?.showView && (
                    <>
                      <HtmlTooltip title={'View' + ' ' + row?.id}>
                        <IconButton
                          onClick={() => {
                            setOpenViewModal((state) => ({ ...state, open: !state.open, machineId: row?.id }));
                          }}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </HtmlTooltip>
                    </>
                  )} */}
                </TableCell>
              ) : header.id === 'isPrimary' ? (
                <TableCell align="center">
                  <Chip
                    label={dynamicValue ? 'Yes' : 'No'}
                    color={dynamicValue === true ? 'success' : 'error'}
                    style={{
                      color: '#fff'
                      // backgroundColor: dynamicValue === true ? '#6fd74b' : '#f44336'
                    }}
                    size="small"
                  />
                </TableCell>
              ) : header.id === 'createdAt' ? (
                <TableCell align="center">
                  <Tooltip title={moment(dynamicValue).format('Do MMMM YYYY')} disableInteractive>
                    <span>{moment(dynamicValue).format('Do MMMM YYYY')}</span>
                  </Tooltip>
                </TableCell>
              ) : header?.changeState ? (
                <>
                  <TableCell align="center">
                    <HtmlTooltip
                      title={
                        <>
                          <Typography
                            subtitle1="h2"
                            component={'span'}
                            sx={{
                              alignItems: 'center'
                            }}
                          >
                            <p style={{ textAlign: 'center' }}>
                              Are you sure want to {!row?.enabled === true ? 'activate' : 'deactivate'} the {row?.name}?
                            </p>
                          </Typography>
                          <Stack
                            direction="row"
                            spacing={2}
                            style={{ padding: '2px 10px' }}
                            sx={{
                              mt: 1,
                              mb: 1,
                              justifyContent: 'center'
                            }}
                          >
                            <Button
                              variant="outlined"
                              onClick={() => {
                                updatedLocationStatus({
                                  id: row?.id,
                                  status: !row?.enabled
                                });
                              }}
                            >
                              {isLoadingUpdateLocationStatus ? (
                                <>
                                  <CircularProgress
                                    sx={{
                                      color: '#6fd74b',
                                      height: 5,
                                      width: 5
                                    }}
                                  />
                                </>
                              ) : (
                                <>
                                  <DoneOutlinedIcon /> Yes
                                </>
                              )}
                            </Button>
                          </Stack>
                        </>
                      }
                    >
                      <Chip
                        // label={row.status === 'on' ? 'Active' : 'Inactive'}
                        // color={row.status === 'on' ? 'success' : 'error'}
                        label={row?.enabled === true ? 'Active' : 'Inactive'}
                        style={{
                          color: '#fff',
                          backgroundColor: row?.enabled === true ? '#6fd74b' : '#f44336'
                        }}
                        size="small"
                      />
                    </HtmlTooltip>
                  </TableCell>
                </>
              ) : (
                <TableCell align="center">
                  <Tooltip title={dynamicValue || ''} disableInteractive>
                    <span>{truncatedText(dynamicValue) || 'N.A.'}</span>
                  </Tooltip>
                </TableCell>
              )}
            </React.Fragment>
          );
        })}
    </TableRow>
  );
}

const Location = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('createdAt');
  const [isLoading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(0);
  // const [firstPageVal, setFirstPageVal] = useState('');
  // const [lastPageVal, setLastPageVal] = useState('');

  // const navigate = useNavigate();

  const [openFilterModal, setOpenFilterModal] = useState(true);
  const [filterObj, setFilterObj] = useState({
    location_name: '',
    location_status: ''
  });
  // const [locationToggledButton, setLocationToggledButton] = useState(null);

  // const handleToggleLocationButton = (event, toggledButton) => {
  //   setLocationToggledButton(toggledButton);
  // };
  const organizationType = useSelector((state) => state.customization.orgType);
  const usersType = useSelector((state) => state.customization.userType);
  const {
    data: permissionsList,

    isLoading: isLoadingPermissionsList
  } = useGetPermissionsLocationsList({
    organizationType:
      organizationType === 'Service Provider' ? 'ServiceProvider' : organizationType !== 'Super Admin' ? organizationType : 'SuperAdmin',
    // usersType: usersType === 'Main POC' ? 'POC' : 'POC'
    usersType: usersType === 'Main POC' || usersType === 'Secondary POC' ? 'POC' : usersType
    // setLocationToggledButton: setLocationToggledButton,
    // updateToggleButton: true
  });

  // console.log('permissionsList', permissionsList, 'isLoadingPermissionsList', isLoadingPermissionsList);
  const { data: allLocationList, isLoading: isAllLocationLoading } = useGetNewLocationList({
    limit: rowsPerPage,
    sortOrder: order,
    sortField: orderBy,
    // previous: previousPage,
    // current: currentPage,
    // first: firstPageVal,
    // last: lastPageVal,
    page: currentPage ? currentPage : previousPage,
    location_name: filterObj?.location_name || '',
    location_status: filterObj?.location_status || ''
    // type: locationToggledButton == 'Own Location' ? 'Own' : locationToggledButton == 'Other Location' ? 'Other' : null
  });

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // setFirstPageVal(allLocationList?.data?.first);
    // setLastPageVal(allLocationList?.data?.last);
    setCurrentPage(newPage + 1);
    setPreviousPage(currentPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [headers, setHeaders] = useState([]);
  useEffect(() => {
    if (permissionsList) {
      let tableHeaders = permissionsList?.view?.tableHeaders;
      const actionObj = {
        id: 'actionUpdate',
        label: 'Action'
      };
      if (tableHeaders && Array.isArray(tableHeaders) && tableHeaders.length) setHeaders([...tableHeaders, actionObj]);
      // console.log('[...tableHeaders, actionObj] ', [...tableHeaders, actionObj]);
    }
  }, [permissionsList]);
  // console.log('Location - headers', headers);
  useEffect(() => {
    // Find the first headCell where sortField is true and set the orderBy
    if (headers) {
      // const firstSortableHeadCell = headers.find((headCell) => headCell.sortField);
      // const firstStateChangeableHeadCell = headers.find((headCell) => headCell.changeState);
      /*if (firstSortableHeadCell) {
          setOrderBy(firstSortableHeadCell.id || 'createdAt');
        }*/ //setStateChangeTbl
      // if (firstStateChangeableHeadCell) {
      //   setStateChangeTbl(firstStateChangeableHeadCell.id);
      // }
      // First, check if 'createdAt' is present
      const createdAtField = headers.find((headCell) => headCell.id === 'createdAt');

      if (!createdAtField) {
        // Otherwise, find the first headCell without a sortField
        const firstNonSortableHeadCell = headers.find((headCell) => headCell.sortField);
        if (firstNonSortableHeadCell) {
          setOrderBy(firstNonSortableHeadCell?.id || 'createdAt');
        }
      }
    }
  }, [headers, setOrderBy]);

  return (
    <>
      {isLoading ? (
        <>
          <SkeletonTableView />
        </>
      ) : (
        <>
          <BreadcrumbsForPage name="All Locations" obj={{ title: 'All Locations', title2: '', url: '' }} />

          <MainCard
            title=""
            isButton={true}
            url="/dashboard/location/add"
            isEnable={true}
            isFilter={true}
            isLocationFilter={true}
            openFilterAllModal={openFilterModal}
            setOpenFilterModal={setOpenFilterModal}
            setFilterObj={setFilterObj}
            permissionsList={permissionsList}
            isLoadingPermissionsList={isLoadingPermissionsList}
            // locationToggledButton={locationToggledButton}
            locationToggledButton={null}
          >
            <TableContainer>
              {isAllLocationLoading ? (
                <TableBody>
                  {[1, 2, 3].map((i) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                      <TableCell align="center">
                        {' '}
                        <Skeleton variant="text" width={200} />
                      </TableCell>
                      <TableCell align="center">
                        {' '}
                        <Skeleton variant="text" width={200} />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton variant="text" width={200} />
                      </TableCell>
                      <TableCell align="center">
                        {' '}
                        <Skeleton variant="text" width={200} />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton variant="text" width={200} />
                      </TableCell>{' '}
                      <TableCell align="center">
                        <Skeleton variant="text" width={200} />
                      </TableCell>{' '}
                      <TableCell align="center">
                        <Skeleton variant="text" width={200} />
                      </TableCell>
                      <TableCell align="center">
                        <IconButton>
                          <Skeleton variant="circular" width={40} height={40} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : allLocationList?.data?.locations === undefined || allLocationList?.data?.locations?.length == 0 ? (
                <StyledGridOverlay>
                  <svg style={{ flexShrink: 0 }} width="240" height="200" viewBox="0 0 184 152" aria-hidden focusable="false">
                    <g fill="none" fillRule="evenodd">
                      <g transform="translate(24 31.67)">
                        <ellipse className="ant-empty-img-5" cx="67.797" cy="106.89" rx="67.797" ry="12.668" />
                        <path
                          className="ant-empty-img-1"
                          d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                        />
                        <path
                          className="ant-empty-img-2"
                          d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                        />
                        <path
                          className="ant-empty-img-3"
                          d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                        />
                      </g>
                      <path
                        className="ant-empty-img-3"
                        d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                      />
                      <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                        <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                        <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                      </g>
                    </g>
                  </svg>
                  <Box sx={{ mt: 1 }}>No data found!</Box>
                </StyledGridOverlay>
              ) : (
                <>
                  <Table stickyHeader aria-label="sticky table" size="small">
                    {/* <EnhancedTableHead
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      permissionsList={permissionsList?.view?.tableHeaders}
                      showEdit={permissionsList?.showEdit}
                    /> */}
                    <EnhancedTableHead headersObj={headers} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
                    <TableBody>
                      {stableSort(allLocationList?.data?.locations, getComparator(order, orderBy)).map((row, i) => {
                        return (
                          <>
                            <CollapsibleRow
                              headersObj={headers}
                              row={row}
                              key={i}
                              // setOpenViewModal={setOpenViewModal}
                              // openViewModal={openViewModal}
                              permissionsList={permissionsList}
                              // stateChangeTbl={stateChangeTbl}
                              // setAllMachineDetails={setAllMachineDetails}
                            />
                          </>
                        );
                        /*return (
                          <TableRow hover key={i}>
                            {permissionsList?.view?.tableHeaders.map((item, index) => {
                              if (item.id == 'name')
                                return (
                                  <TableCell key={index} align="center">
                                    {row?.name}
                                  </TableCell>
                                );
                              else if (item.id == 'address')
                                return (
                                  <TableCell
                                    key={index}
                                    align="center"
                                    // sx={{
                                    //   paddingLeft: 0,
                                    //   paddingRight: 2,
                                    //   alignItems: 'center'
                                    // }}
                                  >
                                    <HtmlTooltip title={row?.address?.fullAddress}>
                                      <span>{row?.address?.fullAddress?.slice(0, 28)}...</span>
                                    </HtmlTooltip>
                                  </TableCell>
                                );
                              else if (item.id == 'tax')
                                return (
                                  <TableCell key={index} align="center">
                                    {row?.taxAmount}
                                  </TableCell>
                                );
                              else if (item.id == 'status')
                                return (
                                  <TableCell
                                    key={index}
                                    align="center"
                                    // sx={{
                                    //   paddingLeft: 0,
                                    //   // paddingRight: 2,
                                    //   alignItems: 'center'
                                    // }}
                                  >
                                    {item?.changeState ? (
                                      <>
                                        <HtmlTooltip
                                          title={
                                            <>
                                              <Typography
                                                subtitle1="h2"
                                                component={'span'}
                                                sx={{
                                                  alignItems: 'center'
                                                }}
                                              >
                                                <p style={{ textAlign: 'center' }}>
                                                  Are you sure want to {!row?.enabled === true ? 'activate' : 'deactivate'} the {row?.name}?
                                                </p>
                                              </Typography>
                                              <Stack
                                                direction="row"
                                                spacing={2}
                                                style={{ padding: '2px 10px' }}
                                                sx={{
                                                  mt: 1,
                                                  mb: 1,
                                                  justifyContent: 'center'
                                                }}
                                              >
                                                <Button
                                                  variant="outlined"
                                                  onClick={() => {
                                                    updatedLocationStatus({
                                                      id: row?.id,
                                                      status: !row?.enabled
                                                    });
                                                  }}
                                                >
                                                  {isLoadingUpdateLocationStatus ? (
                                                    <>
                                                      <CircularProgress
                                                        sx={{
                                                          color: '#6fd74b',
                                                          height: 5,
                                                          width: 5
                                                        }}
                                                      />
                                                    </>
                                                  ) : (
                                                    <>
                                                      <DoneOutlinedIcon /> Yes
                                                    </>
                                                  )}
                                                </Button>
                                              </Stack>
                                            </>
                                          }
                                        >
                                          <Chip
                                            // label={row.status === 'on' ? 'Active' : 'Inactive'}
                                            // color={row.status === 'on' ? 'success' : 'error'}
                                            label={row?.enabled === true ? 'Active' : 'Inactive'}
                                            style={{
                                              color: '#fff',
                                              backgroundColor: row?.enabled === true ? '#6fd74b' : '#f44336'
                                            }}
                                            size="small"
                                          />
                                        </HtmlTooltip>
                                      </>
                                    ) : (
                                      <Chip
                                        // label={row.status === 'on' ? 'Active' : 'Inactive'}
                                        // color={row.status === 'on' ? 'success' : 'error'}
                                        label={row?.enabled === true ? 'Active' : 'Inactive'}
                                        // color={row?.enabled === true ? 'success' : 'error'}
                                        style={{
                                          color: '#fff',
                                          backgroundColor: row?.enabled === true ? '#6fd74b' : '#f44336'
                                        }}
                                        size="small"
                                      />
                                    )}
                                  </TableCell>
                                );
                              else if (item.id == 'createdAt')
                                return (
                                  <TableCell key={index} align="center">
                                    {moment(row?.createdAt).format('Do MMMM YYYY')
                                      ? moment(row?.createdAt).format('Do MMMM YYYY')
                                      : row?.createdAt}
                                  </TableCell>
                                );
                              else null;
                            })}
                            {permissionsList?.showEdit && (
                              <TableCell align="center">
                                <HtmlTooltip title={'Edit' + ' ' + row?.name}>
                                  <IconButton
                                    onClick={() => {
                                      navigate(`/dashboard/location/edit-${row?.id}`, {
                                        state: {
                                          // permissionsList: permissionsList?.add,
                                          // locationToggledButton: locationToggledButton
                                          locationToggledButton: null
                                        }
                                      });
                                    }}
                                  >
                                    <ModeEditOutlinedIcon />
                                  </IconButton>
                                </HtmlTooltip>
                              </TableCell>
                            )}
                          </TableRow>
                        );*/
                      })}
                    </TableBody>
                  </Table>
                </>
              )}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={allLocationList?.data?.rowsPerPage}
              component="div"
              count={allLocationList?.data?.totalCount ? allLocationList?.data?.totalCount : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </MainCard>
        </>
      )}
    </>
  );
};

EnhancedTableHead.propTypes = {
  order: PropTypes.string,
  orderBy: PropTypes.string,
  onRequestSort: PropTypes.any
};

export default Location;
