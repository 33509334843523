import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  //   InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import React from 'react';
import { gridSpacing } from 'store/constant';
import { getSupportType } from 'utils/commonUtil';
import { useTheme } from '@mui/system';

// import { useGetOrganizationNewAllList } from 'hooks/useOrganizationNewHooks';
const SupportTicketFilterForm = ({ errors, handleChange, values, resetForm, setFilterObj, handleBlur, touched }) => {
  const theme = useTheme();

  return (
    <>
      <Grid
        key={'organizationFilter'}
        container
        spacing={gridSpacing}
        sx={{ marginBottom: '20px', alignItems: 'center', padding: '0px 20px' }}
      >
        <Grid
          //   key={index}
          container
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          className="select-status-filter"
          alignItems={'center'}
        >
          <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
            Status
          </Typography>
          <FormControl
            fullWidth
            variant="outlined"
            placeholder="Select Status"
            // error={Boolean(touched.status && errors.status)}
          >
            {/* <InputLabel sx={{}}> Select Status</InputLabel> */}
            <Select
              //   disabled={filter?.isStatic}
              value={values?.status}
              onChange={handleChange}
              onBlur={handleBlur}
              name="status"
              inputProps={{
                'aria-label': 'Without label'
              }}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="Resolved">Resolved</MenuItem>
              <MenuItem value="Reported">Reported</MenuItem>
            </Select>
          </FormControl>
          {touched?.status && errors?.status && (
            <FormHelperText
              error
              id="standard-weight-helper-text-email-login"
              sx={{
                fontSize: '13.8px'
              }}
            >
              {errors?.status}
            </FormHelperText>
          )}
        </Grid>

        <Grid
          //   key={index}
          container
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          className="select-machine-id inv-number select-machine"
          alignItems={'center'}
        >
          <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '5px' }}>
            Subject
          </Typography>
          <FormControl fullWidth variant="outlined">
            <TextField
              placeholder="Search Subject"
              variant="outlined"
              type="text"
              fullWidth
              name="subject"
              value={values?.subject}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched?.subject && errors?.subject)}
              //   error={Boolean(touched?.[filter?.id] && errors?.[filter?.id])}
            />
            {/* {touched?.[filter?.id] && errors?.[filter?.id] && ( */}
            {touched?.subject && errors?.subject && (
              <FormHelperText
                error
                id="standard-weight-helper-text-email-login"
                sx={{
                  fontSize: '13.8px'
                }}
              >
                {errors?.subject}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} className="select-status-filter">
          <Typography variant="h6" style={{ fontSize: '15px', fontWeight: '600', paddingBottom: '0px' }}>
            Select Support Type
          </Typography>
          <FormControl fullWidth sx={{ ...theme.typography.customInput }} error={Boolean(touched.supportType && errors.supportType)}>
            <Select
              labelId="support-type-label"
              value={values?.supportType || ''}
              onChange={handleChange}
              label="Select Support Type *"
              name="supportType"
            >
              <MenuItem value="Select Support Type" disabled>
                Select Support Type
              </MenuItem>
              {getSupportType.map((type) => (
                <MenuItem key={type.id} value={type.value}>
                  {type.supportType}
                </MenuItem>
              ))}
            </Select>
            {touched.supportType && errors.supportType && (
              <FormHelperText
                error
                id="standard-weight-helper-text-email-login"
                sx={{
                  fontSize: '13.8px'
                }}
              >
                {errors.supportType}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
      <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'right', paddingBottom: '20px', paddingRight: '20px' }}>
        <Button
          variant="outlined"
          type="reset"
          color="error"
          onClick={() => {
            resetForm();
            setFilterObj({
              status: '',

              supportType: '',
              subject: ''
            });
            // handleCloseFilter();
          }}
        >
          Clear all
        </Button>
        <div style={{ marginLeft: '10px' }}>
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box sx={{ m: 0, position: 'relative' }}>
            <Button
              // fullWidth
              variant="contained"
              type="submit"
              style={{
                color: '#fff'
              }}
              color="primary"
              //   disabled={isLoadingUpdateInvoiceStatus}
            >
              Search
            </Button>
          </Box>
        </div>
      </div>
    </>
  );
};

export default SupportTicketFilterForm;
