import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import TicketForm from '../ticket-form';
import MainCard from 'ui-component/cards/MainCard';
import BreadcrumbsForPage from 'ui-component/extended/BreadcrumbsForPage';
import { useState } from 'react';
import { useEffect } from 'react';
// import FormView from 'ui-component/cards/Skeleton/FormView';
import { useMutation } from '@tanstack/react-query';
import { useTicketReasonAdd } from 'hooks/useTicketReasonHooks';
import { setToast, toastConfig } from 'utils/commonUtil';
import OrganizationFormView from 'ui-component/cards/Skeleton/OrganizationFormView';
import { useNavigate } from 'react-router-dom';
const TicketReasonAdd = () => {
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { mutate: addTicketReason, isPending: isLoadingTicketreason } = useMutation({
    mutationFn: useTicketReasonAdd
  });

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <>
      {isLoading ? (
        <OrganizationFormView />
      ) : (
        <>
          <BreadcrumbsForPage
            name="Add Ticket Categories"
            obj={{ title: 'All Ticket Categories', title2: 'Add Ticket Category', url: '/dashboard/ticket-reason' }}
          />
          <MainCard>
            <Formik
              initialValues={{
                reason: '',
                associations: []
              }}
              validationSchema={Yup.object().shape({
                reason: Yup.string().required('Ticket Category is required'),
                associations: Yup.array().of(Yup.string()).min(1, 'At least one Send to is required').required('Send to are required')
              })}
              onSubmit={async (value) => {
                const submitData = {
                  category: value.reason.trim(),
                  associations: value?.associations || []
                };

                addTicketReason(submitData, {
                  onSuccess: (res) => {
                    // console.log('res addTicketReason : ', res);
                    if (res?.status == 200) {
                      toastConfig.type = 'success';

                      setToast(toastConfig, res?.data?.message);
                      navigate('/dashboard/ticket-reason');
                      resetForm({});
                    } else {
                      toastConfig.type = 'error';
                      setToast(toastConfig, error?.response?.data?.error);
                    }
                  },
                  onError: (error) => {
                    toastConfig.type = 'error';
                    setToast(toastConfig, error?.response?.data?.error);
                  }
                });
              }}
            >
              {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, values, touched }) => (
                <>
                  <TicketForm
                    btnName="Add"
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    setFieldValue={setFieldValue}
                    values={values}
                    isLoadingTicket={isLoadingTicketreason}
                    touched={touched}
                    id={''}
                  />
                </>
              )}
            </Formik>
          </MainCard>
        </>
      )}
    </>
  );
};

export default TicketReasonAdd;
