import { Formik } from 'formik';
import React from 'react';

import MainCard from 'ui-component/cards/MainCard';

import BreadcrumbsForPage from 'ui-component/extended/BreadcrumbsForPage';
import * as Yup from 'yup';
import SuperAdminSettingsForm from './form';

// import MachineModelForm from './machine-model-form';
import MachineVersionForm from './machine-version-form';
import {
  // useGetAllMachinePrice,
  useMachinePriceUpdate,
  useMachineSettingsAdd,
  // useMachineModelAdd,
  useMachineVersionAdd,
  useRowSettingAdd
} from 'hooks/useSettingsHooks';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { setToast, toastConfig } from 'utils/commonUtil';
import MachineBasePriceForm from './machine-base-price-form';
import MachineAllSettings from './machine-all';
// import { useParams } from 'react-router-dom';
const SuperAdminSettings = () => {
  // const { mutate: addMachineModel, isPending: isLoadingMachineModel } = useMutation({
  //   mutationFn: useMachineModelAdd
  // });
  const { mutate: addMachineSettings, isPending: isLoadingMachineSettings } = useMutation({
    mutationFn: useMachineSettingsAdd
  });
  const { mutate: addMachineVersion, isPending: isLoadingMachineVersion } = useMutation({
    mutationFn: useMachineVersionAdd
  });

  const { mutate: addRow, isPending: isLoadingRow } = useMutation({
    mutationFn: useRowSettingAdd
  });

  ///static id

  const { mutate: updateMachinePrice, isPending: isLoadingMachinePrice } = useMutation({
    mutationFn: useMachinePriceUpdate
  });
  // console.log('🚀 ~ addMachinePrice:', addMachinePriceDetails);
  const queryClient = useQueryClient();

  const SettingsSchema = Yup.object().shape({
    ranges: Yup.array()
      .of(
        Yup.object().shape({
          rowValue: Yup.number()
            .required('Value is required')
            .positive('Value must be positive')
            .integer('Value must be an integer')
            .max(9999, 'Value must not exceed 9999') // Maximum value constraint
        })
      )
      .test('unique-values', 'Each value must be unique', (rangesArray) => {
        if (!Array.isArray(rangesArray)) return false;
        const values = rangesArray.map((item) => item.rowValue).filter(Boolean); // Ignore empty or undefined
        return new Set(values).size === values.length;
      })
      .test('increasing-values', 'Values must be in an increasing sequence, like [2, 5, 8]', (rangesArray) => {
        if (!Array.isArray(rangesArray) || rangesArray.length <= 1) return true; // Valid if array is empty or has one element
        const values = rangesArray.map((item) => item.rowValue).filter(Boolean); // Ignore empty or undefined
        for (let i = 1; i < values.length; i++) {
          if (values[i] <= values[i - 1]) return false;
        }
        return true;
      })
      .test('not-empty', 'Ranges cannot be empty', (rangesArray) => {
        return Array.isArray(rangesArray) && rangesArray.length > 0;
      })
      .nullable(true) // Allow for null or undefined for flexibility
  });

  const MachineVersionSchema = Yup.object().shape({
    machineVersion: Yup.array()
      .of(
        Yup.object().shape({
          version: Yup.string()
            // .transform((value) => (typeof value === 'string' ? value.trim() : value)) // Trim spaces before validation
            .required('Machine version  is required') // Ensures the title is not empty
            .matches(/.*\S.*/, 'Machine version  cannot be blank or only spaces')
          // Checks for non-whitespace characters
          // .matches(/^[a-zA-Z0-9 ]+$/, 'Invalid characters in machine version ') // Allows alphanumeric and spaces
        })
      )
      .required('At least one machine version is required') // Array-level validation
      .min(1, 'At least one machine version is required') // Ensure the array is not empty
      .test('not-empty', 'Machine version cannot be empty', (machineVersionArray) => {
        return (
          Array.isArray(machineVersionArray) && machineVersionArray.every((val) => Boolean(val.version.trim())) // Check that all values are truthy
        );
      })
  });

  const MachineBasePriceSchema = Yup.object().shape({
    enterpriseMachinePrice: Yup.number()
      .typeError('Enterprise Machine Price must be a number')
      .required('Enterprise Machine Price is required')
      .positive('Enterprise Machine Price must be greater than zero')
      .max(5000000000, 'Enterprise Machine Price must not exceed 5,000,000,000'),

    retailerMachinePrice: Yup.number()
      .typeError('Retailer Machine Price must be a number')
      .required('Retailer Machine Price is required')
      .positive('Retailer Machine Price must be greater than zero')
      .max(5000000000, 'Retailer Machine Price must not exceed 5,000,000,000'),

    perDriveCost: Yup.number()
      .typeError('Per Drive Cost must be a number')
      .required('Per Drive Cost is required')
      .positive('Per Drive Cost must be greater than zero')
      .max(5000000000, 'Per Drive Cost must not exceed 5,000,000,000')
  });

  const MachineAllSettingsSchema = Yup.object().shape({
    rows: Yup.array().of(
      Yup.object().shape({
        // locationPickUp: Yup.array().min(1, 'At least one location is required').required('Select Location is required ')
        model: Yup.string().required('Model is required !'),
        version: Yup.array()
          // .of(Yup.string().required('Each version must be a non-empty string!'))
          .min(1, 'At least one version is required!')
          .required('Version is required!'),
        basePrice: Yup.number()
          .required('Base Price is required')
          .min(0, 'Base Price must be a positive value')
          .test('is-decimal', 'Base Price must have up to 2 decimal places', (value) => /^\d+(\.\d{1,2})?$/.test(value)),
        perDriveCost: Yup.number()
          .required('Per Drive Cost is required')
          .min(0, 'Per Drive Cost must be a positive value')
          .test('is-decimal', 'Per Drive Cost must have up to 2 decimal places', (value) => /^\d+(\.\d{1,2})?$/.test(value)),

        machineType: Yup.array().min(1, 'At least one machine type is required').required('Machine Type is required')
      })
    )
  });
  return (
    <>
      <BreadcrumbsForPage name="Settings" obj={{ title: 'Settings', title2: '', url: '' }} />
      <MainCard title="Settings">
        <Formik
          initialValues={{
            //ranges: [20, 50]
            ranges: [
              {
                rowValue: 20
              },
              {
                rowValue: 50
              }
            ]
          }}
          validationSchema={SettingsSchema}
          onSubmit={async (value) => {
            addRow(value?.ranges, {
              onSuccess: (res) => {
                if (res?.status == 200) {
                  toastConfig.type = 'success';
                  setToast(toastConfig, res?.data.message);
                  queryClient.refetchQueries({
                    queryKey: ['getallRow'],
                    type: 'active'
                  });
                } else {
                  toastConfig.type = 'error';
                  setToast(toastConfig, error?.response?.data?.error);
                }
              },
              onError: (error) => {
                toastConfig.type = 'error';
                setToast(toastConfig, error?.response?.data?.error);
              }
            });
          }}
        >
          {({ errors, setFieldValue, handleSubmit, handleBlur, handleChange, values, touched }) => (
            <>
              <SuperAdminSettingsForm
                values={values}
                touched={touched}
                errors={errors}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                handleSubmit={handleSubmit}
                isLoadingRow={isLoadingRow}

                //   handleBlur={handleBlur}
              />
            </>
          )}
        </Formik>
      </MainCard>

      <MainCard
        sx={{
          marginTop: '12px'
        }}
      >
        <Formik
          initialValues={{
            enterpriseMachinePrice: '',
            retailerMachinePrice: '',
            perDriveCost: ''
          }}
          validationSchema={MachineBasePriceSchema}
          // onSubmit={async (value) => {
          //   console.log('hghjg', value);
          // }}
          onSubmit={async (value) => {
            const submitData = {
              id: '6751558ae312a8253a026097',
              enterpriseBasePrice: value?.enterpriseMachinePrice,
              retailerBasePrice: value?.retailerMachinePrice,
              perDriveCost: value?.perDriveCost
            };

            updateMachinePrice(submitData, {
              onSuccess: (res) => {
                if (res?.status == 200) {
                  toastConfig.type = 'success';
                  setToast(toastConfig, res?.data.message);
                  queryClient.refetchQueries({
                    queryKey: ['getallMachinePrice'],
                    type: 'active'
                  });
                } else {
                  toastConfig.type = 'error';
                  setToast(toastConfig, error?.response?.data?.error);
                }
              },
              onError: (error) => {
                toastConfig.type = 'error';
                setToast(toastConfig, error?.response?.data?.error);
              }
            });
          }}
        >
          {({ errors, setFieldValue, handleSubmit, handleBlur, handleChange, values, touched }) => (
            <MachineBasePriceForm
              values={values}
              setFieldValue={setFieldValue}
              handleChange={handleChange}
              handleBlur={handleBlur}
              handleSubmit={handleSubmit}
              touched={touched}
              errors={errors}
              isLoadingMachinePrice={isLoadingMachinePrice}
            />
          )}
        </Formik>
      </MainCard>
      {/* <MainCard
        sx={{
          marginTop: '12px'
        }}
      >
        <Formik
          initialValues={{
            machineModel: [
              {
                title: ''
              }
            ]
          }}
          validationSchema={MachineModelSchema}
          onSubmit={async (value) => {
            addMachineModel(value?.machineModel, {
              onSuccess: (res) => {
                if (res?.status == 200) {
                  toastConfig.type = 'success';
                  setToast(toastConfig, res?.data.message);
                  queryClient.refetchQueries({
                    queryKey: ['getallMachineModel'],
                    type: 'active'
                  });
                } else {
                  toastConfig.type = 'error';
                  setToast(toastConfig, error?.response?.data?.error);
                }
              },
              onError: (error) => {
                toastConfig.type = 'error';
                setToast(toastConfig, error?.response?.data?.error);
              }
            });
          }}
        >
          {({ errors, setFieldValue, handleSubmit, handleBlur, handleChange, values, touched }) => (
            <MachineModelForm
              errors={errors}
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              values={values}
              touched={touched}
              isLoadingMachineModel={isLoadingMachineModel}
            />
          )}
        </Formik>
      </MainCard> */}

      <MainCard
        sx={{
          marginTop: '12px'
        }}
      >
        <Formik
          initialValues={{
            machineVersion: [
              {
                version: ''
              }
            ]
          }}
          validationSchema={MachineVersionSchema}
          // onSubmit={async (value) => {
          //   // const submittedValues = {};

          //   // let version = [];

          //   // for (let i = 0; i < value?.machineVersion?.length; i++) {
          //   //   console.log(value?.machineVersion[i]);

          //   //   version.push({
          //   //     version: value?.machineVersion[i]?.trim()
          //   //   });
          //   // }
          //   // for (let i = 0; i < value?.machineVersion?.length; i++) {
          //   //   if (value?.machineVersion[i]) {
          //   //     console.log(value?.machineVersion[i]);
          //   //     version.push({
          //   //       version: value?.machineVersion[i]?.trim()
          //   //     });
          //   //   }
          //   // }

          //   addMachineVersion(value?.machineVersion, {
          //     onSuccess: (res) => {
          //       if (res?.status == 200) {
          //         toastConfig.type = 'success';
          //         setToast(toastConfig, res?.data.message);
          //         queryClient.refetchQueries({
          //           queryKey: ['getallMachineVersion'],
          //           type: 'active'
          //         });
          //       } else {
          //         toastConfig.type = 'error';
          //         setToast(toastConfig, error?.response?.data?.error);
          //       }
          //     },
          //     onError: (error) => {
          //       toastConfig.type = 'error';
          //       setToast(toastConfig, error?.response?.data?.error);
          //     }
          //   });
          //   console.log('🚀 ~ onSubmit={ ~ value?.machineVersion:', value?.machineVersion);
          // }}
          onSubmit={async (value) => {
            let callAPI = true;
            value?.machineVersion.forEach((_v) => {
              if (/\s/.test(_v.version.trim())) {
                callAPI = false;
                setToast(toastConfig, 'Version can not contains space');
              }
            });

            if (callAPI) {
              const trimmedVersions = value?.machineVersion?.map((item) => ({
                id: item?.id || null,
                version: item?.version?.trim() || '' // trim and ensure empty string if undefined
              }));

              // Call addMachineVersion with the trimmed versions
              addMachineVersion(trimmedVersions, {
                onSuccess: (res) => {
                  if (res?.status == 200) {
                    toastConfig.type = 'success';
                    setToast(toastConfig, res?.data.message);
                    queryClient.refetchQueries({
                      queryKey: ['getallMachineVersion'],
                      type: 'active'
                    });
                  } else {
                    toastConfig.type = 'error';
                    setToast(toastConfig, error?.response?.data?.error);
                  }
                },
                onError: (error) => {
                  toastConfig.type = 'error';
                  setToast(toastConfig, error?.response?.data?.error);
                }
              });

              console.log('Trimmed machineVersion:', trimmedVersions);
            }
          }}
        >
          {({ errors, setFieldValue, handleSubmit, handleBlur, handleChange, values, touched }) => (
            <MachineVersionForm
              values={values}
              setFieldValue={setFieldValue}
              handleChange={handleChange}
              handleBlur={handleBlur}
              handleSubmit={handleSubmit}
              touched={touched}
              errors={errors}
              isLoadingMachineVersion={isLoadingMachineVersion}
            />
          )}
        </Formik>
      </MainCard>

      <MainCard
        sx={{
          marginTop: '12px'
        }}
      >
        <Formik
          initialValues={{
            rows: [
              {
                // id: 1,
                item: '1',
                model: '',
                version: [],
                basePrice: 0,
                machineType: [],
                perDriveCost: 0
              }
            ]
          }}
          validationSchema={MachineAllSettingsSchema}
          onSubmit={async (value) => {
            // console.log('🚀 ~ onSubmit={ ~ value:', value);
            const submitData = value.rows.map((row) => {
              const isNewRow = !row.id; // Check if the row is new (no `id` key)
              return {
                ...(isNewRow ? {} : { id: row.id }), // Include `id` only if it exists (update scenario)
                model: row.model,
                versions: row.version.length ? row.version : ['', ''], // Default versions
                types: row.machineType.length ? row.machineType : ['E', 'R'], // Default types
                basePrice: row.basePrice || 0, // Default base price
                perDriveCost: row.perDriveCost || 0 // Default per-drive cost
              };
            });

            addMachineSettings(submitData, {
              onSuccess: (res) => {
                if (res?.status == 200) {
                  toastConfig.type = 'success';
                  setToast(toastConfig, res?.data.message);
                  queryClient.refetchQueries({
                    queryKey: ['getallMachineSettings'],
                    type: 'active'
                  });
                } else {
                  toastConfig.type = 'error';
                  setToast(toastConfig, error?.response?.data?.error);
                }
              },
              onError: (error) => {
                toastConfig.type = 'error';
                setToast(toastConfig, error?.response?.data?.error);
              }
            });
          }}
        >
          {({ errors, setFieldValue, handleSubmit, handleBlur, handleChange, values, touched }) => (
            <MachineAllSettings
              values={values}
              setFieldValue={setFieldValue}
              handleChange={handleChange}
              handleBlur={handleBlur}
              handleSubmit={handleSubmit}
              touched={touched}
              errors={errors}
              isLoadingMachineSettings={isLoadingMachineSettings}
              // isLoadingMachineVersion={isLoadingMachineVersion}
            />
          )}
        </Formik>
      </MainCard>
    </>
  );
};

export default SuperAdminSettings;
