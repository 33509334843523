import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  Skeleton,
  DialogActions,
  Box,
  Button,
  CircularProgress,
  Chip
} from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { gridSpacing } from 'store/constant';
import { useLocation } from 'react-router';
import ViewTicketSkeleton from 'ui-component/cards/Skeleton/ViewTicketSkeleton';
import {
  /* The code snippet appears to be a comment block in JavaScript. It mentions a function or
variable called "useGetOrganizationNewModal". However, without the actual implementation of
this function or variable, it is not possible to determine what it does. The code seems to
be incomplete or missing context. */
  useGetOrganizationNewModal
} from 'hooks/useOrganizationNewHooks';
import { useGetResendConfirmationEmail } from 'hooks/useAuthHooks';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { getDomainName } from 'utils/commonUtil';
import { useState } from 'react';
// import DeleteDialog from 'ui-component/delete-dialog';
// import { useEffect } from 'react';
import AssignedMachineDialog from '../organizations-new-assignedMachine-modal';
// import { useQueryClient } from '@tanstack/react-query';

// import { useSelector } from 'react-redux';

const ViewOrganization = ({ open, setOpen, details, type }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const viewOrgID = queryParams?.get('viewOrgID');

  details = {
    ...details,
    id: details?.id ? details?.id : viewOrgID
  };

  const {
    data: orgDetails,
    isPending: isLoadingOrgDetails,
    refetch: refetchDetails
  } = useGetOrganizationNewModal({
    id: details?.id,
    type: type || null
  });

  // const userData = JSON.parse(localStorage.getItem('userData'));

  const orgPermission = orgDetails?.permission;

  const orgAllDetails = orgDetails?.org;
  // console.log('🚀 ~ ViewOrganization ~ orgAllDetails:', orgAllDetails);

  const handleClose = () => {
    setOpen(false);
  };
  const { mutate: resendmail, isPending: isLoadingResendEmail } = useGetResendConfirmationEmail();

  const [secondStopDialog, setSecondStopDialog] = useState(false);
  const [secondStopDetails, setSecondStopDetails] = useState({});

  return (
    <>
      <Dialog
        open={open}
        fullWidth={false}
        fullScreen={false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title" variant="span" sx={{ textTransform: 'capitalize', position: 'relative' }}>
          {isLoadingOrgDetails ? <Skeleton variant="text" width={200} /> : `View Organization: ${orgAllDetails?.name}`}
          {orgAllDetails && (
            <Chip
              // label={orgAllDetails?.isConfirmed ? 'Verified' : 'Unverified'}
              label={`Status- ${orgAllDetails?.isConfirmed ? 'Verified' : 'Unverified'}`}
              sx={{
                borderRadius: '4px', // Customize the border radius here
                backgroundColor: orgAllDetails?.isConfirmed === true ? '#006747' : '#FCA311',
                color: '#fff',
                fontWeight: '500',
                marginLeft: '20px'
              }}
            />
          )}
          {isLoadingOrgDetails ? (
            <>
              <Skeleton
                variant="circular"
                width={20}
                height={20}
                sx={(theme) => ({
                  position: 'absolute',
                  right: 10,
                  top: 15,
                  color: theme.palette.grey[500]
                })}
              />
            </>
          ) : (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={(theme) => ({
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme.palette.grey[500]
              })}
            >
              <CloseIcon color="error" />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent dividers>
          {isLoadingOrgDetails ? (
            <ViewTicketSkeleton />
          ) : (
            <Grid container spacing={gridSpacing}>
              <Grid item xs={12} className="support_ticket_view_new">
                <Section
                  title="Organization Details"
                  content={[
                    { label: 'Organization Name', value: orgAllDetails?.name || 'N.A.' },
                    // { label: 'Organization Website', value: orgAllDetails?.website || 'N.A.' },
                    {
                      label: 'Organization Website',
                      value: orgAllDetails?.website ? (
                        <a href={orgAllDetails.website} target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>
                          {getDomainName(orgAllDetails.website)}
                        </a>
                      ) : (
                        'N.A.'
                      )
                    },
                    orgAllDetails?.serviceRadius && {
                      label: 'Service Radius',
                      // value: orgAllDetails?.serviceRadius?.value
                      //   ? `${orgAllDetails?.serviceRadius?.value} ${orgAllDetails?.serviceRadius?.unit}`
                      //   : 'N.A.'
                      value: orgAllDetails?.serviceRadius ? orgAllDetails?.serviceRadius : 'N.A.'
                    },
                    {
                      label: 'Organization Types',
                      // value: orgAllDetails?.types && orgAllDetails?.types.length > 0 ? orgAllDetails?.types.join(', ') : 'N.A.'
                      value: orgAllDetails?.types || 'N.A.'
                    }
                  ]}
                />
                {orgAllDetails?.isBothPOCsSame == true ? (
                  <>
                    <Section
                      title="Primary Contact & Secondary Contact"
                      content={[
                        {
                          label: 'Name',
                          // value:
                          //   orgDetails?.mainPOC?.firstName && orgDetails?.mainPOC?.lastName
                          //     ? `${orgDetails?.mainPOC?.prefix} ${orgDetails?.mainPOC?.firstName} ${orgDetails?.mainPOC?.lastName}`
                          //     : 'N.A.'
                          value: orgAllDetails?.mainPOCName || 'N.A.'
                        },
                        // { label: 'Designation', value: orgDetails?.mainPOC?.title || 'N.A.' },
                        // { label: 'Email', value: orgDetails?.mainPOC?.email || 'N.A.' },
                        {
                          label: 'Email',
                          value: orgAllDetails?.mainPOCEmail ? (
                            <span
                              style={{
                                fontSize: '15px',
                                fontWeight: 'unset',
                                paddingTop: 2
                              }}
                            >
                              <a href={`mailto:${orgAllDetails?.mainPOCEmail}`}>{orgAllDetails?.mainPOCEmail}</a>
                            </span>
                          ) : (
                            'N.A.'
                          )
                        },
                        { label: 'Phone', value: orgAllDetails?.mainPOCPhone || 'N.A.' }
                        // { label: 'Address', value: orgDetails?.mainPOC?.address || 'N.A.' },
                        // { label: 'RFID Code', value: orgDetails?.mainPOC?.authenticationInfo?.rfidCode || 'N.A.' },
                        // { label: 'RFID Pin', value: orgDetails?.mainPOC?.authenticationInfo?.rfidPIN || 'N.A.' }
                      ]}
                    />
                  </>
                ) : (
                  <>
                    <Section
                      title="Primary Contact"
                      content={[
                        {
                          label: 'Name',
                          // value:
                          //   orgDetails?.mainPOC?.firstName && orgDetails?.mainPOC?.lastName
                          //     ? `${orgDetails?.mainPOC?.prefix} ${orgDetails?.mainPOC?.firstName} ${orgDetails?.mainPOC?.lastName}`
                          //     : 'N.A.'
                          value: orgAllDetails?.mainPOCName || 'N.A.'
                        },
                        // { label: 'Designation', value: orgDetails?.mainPOC?.title || 'N.A.' },

                        {
                          label: 'Email',
                          value: orgAllDetails?.mainPOCEmail ? (
                            <span
                              style={{
                                fontSize: '15px',
                                fontWeight: 'unset',
                                paddingTop: 2
                              }}
                            >
                              <a href={`mailto:${orgAllDetails?.mainPOCEmail}`}>{orgAllDetails?.mainPOCEmail}</a>
                            </span>
                          ) : (
                            'N.A.'
                          )
                        },
                        { label: 'Phone', value: orgAllDetails?.mainPOCPhone || 'N.A.' }
                        // { label: 'Address', value: orgDetails?.mainPOC?.address || 'N.A.' },
                        // { label: 'RFID Code', value: orgDetails?.mainPOC?.authenticationInfo?.rfidCode || 'N.A.' },
                        // { label: 'RFID Pin', value: orgDetails?.mainPOC?.authenticationInfo?.rfidPIN || 'N.A.' }
                      ]}
                    />
                    <Section
                      title="Secondary Contact"
                      content={[
                        {
                          label: 'Name',
                          // value:
                          //   orgDetails?.secondaryPOC?.firstName && orgDetails?.secondaryPOC?.lastName
                          //     ? `${orgDetails?.secondaryPOC?.prefix} ${orgDetails?.secondaryPOC?.firstName} ${orgDetails?.secondaryPOC?.lastName}`
                          //     : 'N.A.'
                          value: orgAllDetails?.secondaryPOCName || 'N.A.'
                        },
                        // { label: 'Designation', value: orgDetails?.secondaryPOC?.title || 'N.A.' },
                        // { label: 'Email', value: orgDetails?.secondaryPOC?.email || 'N.A.' },
                        {
                          label: 'Email',
                          value: orgAllDetails?.secondaryPOCEmail ? (
                            <span
                              style={{
                                fontSize: '15px',
                                fontWeight: 'unset',
                                paddingTop: 2
                              }}
                            >
                              <a href={`mailto:${orgAllDetails?.secondaryPOCEmail}`}>{orgAllDetails?.secondaryPOCEmail}</a>
                            </span>
                          ) : (
                            'N.A.'
                          )
                        },
                        { label: 'Phone', value: orgAllDetails?.secondaryPOCPhone || 'N.A.' }
                        // { label: 'Address', value: orgDetails?.secondaryPOC?.address || 'N.A.' },
                        // { label: 'RFID Code', value: orgDetails?.secondaryPOC?.authenticationInfo?.rfidCode || 'N.A.' },
                        // { label: 'RFID Pin', value: orgDetails?.secondaryPOC?.authenticationInfo?.rfidPIN || 'N.A.' }
                      ]}
                    />
                  </>
                )}

                <AddressSection title="Locations" addresses={orgAllDetails?.locations || 'N.A.'} />
                {/* {(Array.isArray(orgDetails?.types)
                ? orgDetails.types.includes('ServiceProvider') || orgDetails.types.includes('Recycler')
                : orgDetails?.types === 'ServiceProvider' || orgDetails?.types === 'Recycler') && (
                <>
                  <ServiceLocationSection
                    title="Service Locations"
                    // addresses={orgDetails?.locations || 'N.A.'}
                  />
                </>
              )} */}

                {orgPermission?.showServiceLocations && orgAllDetails?.serviceLocations?.length > 0 && (
                  <ServiceLocationSection title="Service Locations" service_locations={orgAllDetails?.serviceLocations || 'N.A.'} />
                )}
                {orgPermission?.showBoughtMachines && orgAllDetails?.boughtMachines?.length > 0 && (
                  <BoughtMachineSection title="Bought Machines" machineDetails={orgAllDetails?.boughtMachines} />
                )}

                {orgPermission?.showRentedMachines && orgAllDetails?.rentedMachines?.length > 0 && (
                  <RentedMachineSection title="Rented Machines" rentedmachineDetails={orgAllDetails?.rentedMachines} />
                )}
                {orgPermission?.showAssignedMachines && orgAllDetails?.assignedMachines?.length > 0 && (
                  <AssignedMachineSection title="Assigned Machines" assignedmachineDetails={orgAllDetails?.assignedMachines} />
                )}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                    // justifyContent: orgPermission?.showStopService ? 'space-between' : 'flex-end'
                  }}
                >
                  {orgPermission?.showStopService && (
                    <DialogActions>
                      <Box sx={{ m: 0, position: 'relative' }}>
                        <AnimateButton>
                          <Button
                            variant="contained"
                            type="submit"
                            sx={{
                              backgroundColor: '#d84315',
                              color: '#fff',
                              '&:hover': {
                                backgroundColor: '#d84315'
                              },
                              minWidth: '200px',
                              margin: '0px auto'
                            }}
                            // disabled={isLoadingStopServicing}
                            onClick={() => {
                              setSecondStopDialog(true);
                              setOpen(false);
                              setSecondStopDetails(orgAllDetails);
                            }}
                          >
                            Stop Servicing
                          </Button>
                        </AnimateButton>
                      </Box>
                    </DialogActions>
                  )}

                  {orgPermission?.showResendEmailButton && (
                    <DialogActions>
                      <Box sx={{ m: 0, position: 'relative' }}>
                        <AnimateButton>
                          <Button
                            variant="contained"
                            type="submit"
                            sx={{
                              backgroundColor: '#6fd74b',
                              color: '#fff',
                              '&:hover': {
                                backgroundColor: '#6fd74b'
                              },
                              minWidth: '200px',
                              margin: '0px auto'
                            }}
                            disabled={isLoadingResendEmail}
                            onClick={() => {
                              resendmail(
                                { id: orgAllDetails?.mainPOCId },
                                {
                                  onSuccess: () => {
                                    setOpen(false);
                                  }
                                }
                              );
                            }}
                          >
                            {isLoadingResendEmail ? (
                              <CircularProgress size={20} sx={{ color: 'white', width: 20, height: 20 }} />
                            ) : (
                              <>Resend Confirmation Email</>
                            )}
                          </Button>
                        </AnimateButton>
                      </Box>
                    </DialogActions>
                  )}
                </div>
              </Grid>
            </Grid>
          )}
        </DialogContent>
      </Dialog>

      <AssignedMachineDialog
        openmodal={secondStopDialog}
        setOpenModal={setSecondStopDialog}
        details={secondStopDetails}
        refetchDetails={refetchDetails}
        // isLoadingStopServicing={isLoadingStopServicing}
      />
    </>
  );
};

// Reusable Section Component
const Section = ({ title, content }) => (
  <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginBottom: '12px' }}>
    <div style={{ background: '#6fd74b', padding: '10px 15px' }}>
      <Typography component="h4" variant="h3" sx={{ color: '#fff' }}>
        {title}
      </Typography>
    </div>
    <div style={{ padding: '10px 15px' }}>
      {content?.filter(Boolean).map(({ label, value }, index) => (
        <div key={index} style={{ display: 'flex', marginBottom: '10px' }}>
          <Typography component="p" variant="h4" sx={{ fontWeight: '600', marginRight: '4px', minWidth: '200px' }}>
            {label}:
          </Typography>
          <span style={{ fontSize: '15px' }}>{value}</span>
        </div>
      ))}
    </div>
  </div>
);

// Reusable Address Section
const AddressSection = ({ title, addresses }) => (
  <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginBottom: '12px' }}>
    <div style={{ background: '#6fd74b', padding: '10px 15px' }}>
      <Typography component="h4" variant="h3" sx={{ color: '#fff' }}>
        {title}
      </Typography>
    </div>
    <Grid container spacing={gridSpacing} sx={{ padding: '10px 15px' }}>
      {addresses?.map((item, index) => (
        <Grid item lg={6} key={index}>
          <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginBottom: '10px' }}>
            <div style={{ background: '#6fd74b', color: '#fff', padding: '10px', fontSize: '16px' }}>
              {/* Location {index + 1} */}
              <Typography component="p" variant="h4" sx={{ fontWeight: '600', marginRight: '4px', minWidth: '200px', color: '#fff' }}>
                {item?.name}
              </Typography>
            </div>
            <div style={{ padding: '10px' }}>
              {/* <Typography variant="h6" sx={{ margin: 0 }}> */}
              {/* <Typography component="p" variant="h4" sx={{ fontWeight: '600', marginRight: '4px', minWidth: '200px' }}>
                {item?.details?.name}
              </Typography> */}
              <Typography sx={{ marginTop: '4px', fontSize: '15px' }}>{item?.address}</Typography>
            </div>
          </div>
        </Grid>
      ))}
    </Grid>
  </div>
);

const ServiceLocationSection = ({ title, service_locations }) => (
  <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginBottom: '12px' }}>
    <div style={{ background: '#6fd74b', padding: '10px 15px' }}>
      <Typography component="h4" variant="h3" sx={{ color: '#fff' }}>
        {title}
      </Typography>
    </div>
    <Grid container spacing={2} sx={{ padding: '10px 15px' }}>
      {service_locations?.map((item, index) => (
        <Grid item lg={12} sm={12} md={12} key={index}>
          <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginBottom: '10px' }}>
            <div style={{ background: '#6fd74b', color: '#fff', padding: '10px', fontSize: '16px' }}>
              {/* Service Location {index + 1} */}
              <Typography component="p" variant="h4" sx={{ fontWeight: '600', marginRight: '4px', minWidth: '200px', color: '#fff' }}>
                {item?.machineName}
              </Typography>
            </div>
            <div style={{ padding: '10px 15px' }}>
              {[
                { label: 'Assigned Machine', value: item?.machineName || 'N.A' },
                { label: 'Client Name', value: item?.clientName || 'N.A' },
                { label: 'Machine Type', value: item?.machineType || 'N.A' },
                { label: 'Machine Serial Number', value: item?.machineSerialNo || 'N.A' },
                { label: 'Machine Nickname', value: item?.machineNickname || 'N.A' },
                { label: 'Machine Status', value: item?.machineStatus || 'N.A' },
                { label: 'Machine Location', value: item?.machineLocation || 'N.A' }
              ].map(({ label, value }, i) => (
                <div
                  key={i}
                  style={{
                    marginBottom: '10px',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                  className="dflex_srvc_location"
                >
                  <Typography component="p" variant="h4" sx={{ fontWeight: '600', marginRight: '4px', minWidth: '200px' }}>
                    {label}:
                  </Typography>
                  <span style={{ fontSize: '15px' }}>{value}</span>
                </div>
              ))}
            </div>
          </div>
        </Grid>
      ))}
    </Grid>
  </div>
);

const BoughtMachineSection = ({ title, machineDetails }) => (
  <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginBottom: '12px' }}>
    <div style={{ background: '#6fd74b', padding: '10px 15px' }}>
      <Typography component="h4" variant="h3" sx={{ color: '#fff' }}>
        {title}
      </Typography>
    </div>
    <Grid container spacing={2} sx={{ padding: '10px 15px' }}>
      {machineDetails?.map((item, index) => (
        <Grid item lg={12} sm={12} md={12} key={index}>
          <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginBottom: '10px' }}>
            <div style={{ background: '#6fd74b', color: '#fff', padding: '10px', fontSize: '16px' }}>
              {/* Bought Machine {index + 1} */}
              <Typography component="p" variant="h4" sx={{ fontWeight: '600', marginRight: '4px', minWidth: '200px', color: '#fff' }}>
                {item?.machineName}
              </Typography>
            </div>
            <div style={{ padding: '10px 15px' }}>
              {[
                { label: 'Machine Name', value: item?.machineName },
                { label: 'Machine Type', value: item?.machineType },
                { label: 'Machine Status', value: item?.machineStatus },
                { label: 'Machine Location', value: item?.machineLocation }
              ].map(({ label, value }, i) => (
                <div
                  key={i}
                  style={{
                    marginBottom: '10px',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                  className="dflex_srvc_location"
                >
                  <Typography component="p" variant="h4" sx={{ fontWeight: '600', marginRight: '4px', minWidth: '200px' }}>
                    {label}:
                  </Typography>
                  <span style={{ fontSize: '15px' }}>{value}</span>
                </div>
              ))}
            </div>
          </div>
        </Grid>
      ))}
    </Grid>
  </div>
);

const RentedMachineSection = ({ title, rentedmachineDetails }) => (
  <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginBottom: '12px' }}>
    <div style={{ background: '#6fd74b', padding: '10px 15px' }}>
      <Typography component="h4" variant="h3" sx={{ color: '#fff' }}>
        {title}
      </Typography>
    </div>
    <Grid container spacing={2} sx={{ padding: '10px 15px' }}>
      {rentedmachineDetails?.map((item, index) => (
        <Grid item lg={12} sm={12} md={12} key={index}>
          <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginBottom: '10px' }}>
            <div style={{ background: '#6fd74b', color: '#fff', padding: '10px', fontSize: '16px' }}>
              {/* Rented Machine {index + 1} */}
              <Typography component="p" variant="h4" sx={{ fontWeight: '600', marginRight: '4px', minWidth: '200px', color: '#fff' }}>
                {item?.machineName}
              </Typography>
            </div>
            <div style={{ padding: '10px 15px' }}>
              {[
                { label: 'Machine Name', value: item?.machineName },
                { label: 'Machine Type', value: item?.machineType },
                { label: 'Machine Status', value: item?.machineStatus },
                { label: 'Machine Location', value: item?.machineLocation }
              ].map(({ label, value }, i) => (
                <div
                  key={i}
                  style={{
                    marginBottom: '10px',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                  className="dflex_srvc_location"
                >
                  <Typography component="p" variant="h4" sx={{ fontWeight: '600', marginRight: '4px', minWidth: '200px' }}>
                    {label}:
                  </Typography>
                  <span style={{ fontSize: '15px' }}>{value}</span>
                </div>
              ))}
            </div>
          </div>
        </Grid>
      ))}
    </Grid>
  </div>
);

const AssignedMachineSection = ({ title, assignedmachineDetails }) => (
  <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginBottom: '12px' }}>
    <div style={{ background: '#6fd74b', padding: '10px 15px' }}>
      <Typography component="h4" variant="h3" sx={{ color: '#fff' }}>
        {title}
      </Typography>
    </div>
    <Grid container spacing={2} sx={{ padding: '10px 15px' }}>
      {assignedmachineDetails?.map((item, index) => (
        <Grid item lg={12} sm={12} md={12} key={index}>
          <div style={{ border: '1px solid #6fd74b', borderRadius: '6px', marginBottom: '10px' }}>
            <div style={{ background: '#6fd74b', color: '#fff', padding: '10px', fontSize: '16px' }}>
              {/* Rented Machine {index + 1} */}
              <Typography component="p" variant="h4" sx={{ fontWeight: '600', marginRight: '4px', minWidth: '200px', color: '#fff' }}>
                {item?.machineName}
              </Typography>
            </div>
            <div style={{ padding: '10px 15px' }}>
              {[
                { label: 'Machine Name', value: item?.machineName },
                { label: 'Machine Type', value: item?.machineType },
                { label: 'Machine Status', value: item?.machineStatus },
                { label: 'Machine Location', value: item?.machineLocation }
              ].map(({ label, value }, i) => (
                <div
                  key={i}
                  style={{
                    marginBottom: '10px',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                  className="dflex_srvc_location"
                >
                  <Typography component="p" variant="h4" sx={{ fontWeight: '600', marginRight: '4px', minWidth: '200px' }}>
                    {label}:
                  </Typography>
                  <span style={{ fontSize: '15px' }}>{value}</span>
                </div>
              ))}
            </div>
          </div>
        </Grid>
      ))}
    </Grid>
  </div>
);

export default ViewOrganization;
