const initialState = {
  invoiceType: 'MachinePurchase'
};

const invoiceTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_INVOICE_TYPE':
      return {
        ...state,
        invoiceType: action.payload // Expect boolean payload
      };
    default:
      return state;
  }
};

export default invoiceTypeReducer;
